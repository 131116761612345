import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  panel: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(5),
    minHeight: '90px',
    marginTop: theme.spacing(3),
    backgroundColor: '#fff',
    borderRadius: '14px',
    border: '1px solid #ddd',
  },
  panelTitle: {
    borderBottom: '1px solid #ddd',
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  orderDeliveryParentGrid: {
    display: 'flex',
    flexDirection: 'row',
    margin: 0,
    width: '100%',
    '& > div:last-child': {
      marginLeft: 'auto',
      width: '40%',
      height: 'min-content',
    },
  },
  orderPayment: {
    minWidth: 460,
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    '& > div': {
      display: 'flex',
      width: '100%',
      padding: '18px 0',
    },
  },
  orderPaymentRow: {
    flexDirection: 'row',
    '&> *': {
      margin: '0 auto 0 0',
    },
    '&> *:last-child': {
      margin: '0 0 0 auto',
      textAlign: 'right',
      color: '#555',
      fontSize: '0.85rem',
    },
    '&[data-size="lg"] > *': {
      fontSize: 16,
      fontWeight: 600,
    },
  },
  orderSavings: {
    border: '2px solid #2ED3B7',
    padding: '1rem !important',
    borderRadius: '8px',
    background: '#F0FDF9',
    color: '#067647',
    whiteSpace: 'pre-wrap',
    alignItems: 'center',
  },
  savingsPopupModal: {
    width: '450px',
    height: '200px',
    margin: '10% auto',
    background: 'white',
    padding: '1rem',
    borderRadius: '8px',
    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
  },
  savingsText: {
    color: '#097647',
  },
  viewMore: {
    display: 'flex',
    width: '100%',
    marginBottom: '100px',
    minHeight: '121px',
  },
  savePayment: {
    height: '55px',
    width: '100%',
    backgroundColor: 'rgb(18,87,77)',
    color: '#fff',
    margin: '25px 0',
    marginTop: 'auto',
    '&:disabled': {
      color: '#aaa',
    },
    '&:hover': {
      backgroundColor: 'rgb(49,108,97)',
    },
  },
}));
