import React from 'react';
import { Box, Typography } from '@material-ui/core';
// eslint-disable-next-line max-len
import { ProductCountBadgeStyles as useStyles } from 'src/components/DashboardPractitioner/Tabs/OrderingTab/inventoryOrder.styles';

type ColorProp = 'light' | 'dark' | 'active';
type SizeProp = 'default' | 'small';

export const ProductCountBadge = ({
  total,
  border = false,
  size = 'default',
  color = 'light',
}: {
  total: number;
  border?: boolean;
  size?: SizeProp;
  color?: ColorProp;
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.box} data-color={color} data-border={border} data-size={size}>
      <Typography className={classes.typography}>{total}</Typography>
    </Box>
  );
};

export default ProductCountBadge;
