// eslint-disable-next-line no-shadow
export enum SERVICE_VISIT_STATUS {
  UNSUBMITTED = 'unsubmitted',
  SUBMITTED = 'submitted',
}

export const ASSET_LABEL: any = {
  consultation: 'Consultation',
  dilutions: 'Dilutions',
  fee: 'Fee',
  followUp: 'Follow Up',
  injection: 'Injection',
  lumiere: 'Lumiere',
  peel: 'Peel',
  pouch: 'Pouch',
  region: 'Region',
  syringe: 'Syringe',
  tube: 'Tube',
  vial: 'Vial',
};

export const DATAMATRIX_PER_PAGE: number = 80 / 2; // 80 items A + B

export const ACTIVITY_LOGS_PER_PAGE: number = 4;

export const VALID_SCANNER_DIGITS = [6, 16];

export const INITIAL_PRACTITIONER_FILTER = 'all';
export const RN_PRACTITIONER_LICENSE = 'RN';

// eslint-disable-next-line no-shadow
export enum PURCHASE_ORDER_STATUS {
  COMPLETE = 'complete',
  WAITING_TO_RECEIVE = 'waiting_to_receive',
  DRAFT = 'draft',
}

// eslint-disable-next-line no-shadow
export enum SUPPLY_RECEIPT_STATUS {
  CLOSED = 'closed',
  PENDING = 'pending',
  DRAFT = 'draft',
}

export const DEFAULT_LIMIT = 15;
export const AMOUNT_FOR_REFERRER = 50;
export const TOTAL_AMOUNT_REDEEMABLE = 250;
export const LIMIT_INITIAL_CUSTOMER_NOTE = 2;
export const DEBOUNCE_SEARCH_TIME = 2000;

export const METABASE_ADMIN_NURSE_SALES =
  'https://portraitspa.metabaseapp.com/public/dashboard/99823484-c221-4cab-91ce-6135fc605f55';
export const METABASE_ADMIN_NURSE_STATISTICS =
  'https://portraitspa.metabaseapp.com/public/dashboard/c9c429a2-cf67-4aea-9ba6-48092cc275be';

export const REFER_A_PROVIDER_LINK = 'https://education.joinportrait.com/portrait-ambassador-referral';
export const SUBMIT_SUPPORT_LINK = 'https://support.joinportrait.com/knowledge/kb-tickets/new';
export const PORTRAIT_CIRCLE_LINK = 'https://portrait.circle.so/';
export const PORTRAIT_RESOURCES_LINK =
  'https://sustaining-tire-b70.notion.site/Portrait-Resources-e94e1655153b4c8a9ba8732f82535c6e';
export const DEFAULT_AVATAR_IMAGE = '/static/images/avatar/1.jpg';
export const BG_COLOR = '#e7eeed';
export const PRIMARY_COLOR = '#207567';
export const PORTRAIT_EVANGELIST_PROGRAM_LINK =
  'https://support.joinportrait.com/knowledge/poweredbyportrait-evangelist-program';

export const FEMALE = 'female';
export const PREFERRED_PARTNER_DIRECTORY_DOCUMENT =
  'https://docs.google.com/document/d/1n0GBTu02hYqQYlNihmQ_WVW6aQXPIvBguUBBocq4mDc/edit?usp=sharing';

// DASHBOARD KEYS
export const ADMIN_DASHBOARD_KEY = 'administrator';
export const PHYSICIAN_DASHBOARD_KEY = 'physician';
export const PRACTITIONER_DASHBOARD_KEY = 'practitioner';

/** system roles */
export const PHYSICIAN_ROLE = 'Physician';
export const ADMIN_ROLE = 'Administrator';
export const PRACTITIONER_ROLE = 'Practitioner';
export const CUSTOMER_ROLE = 'Customer';
export const MEDSPA_ADMIN_ROLE = 'Medspa Admin';

export const USER_ROLES = [PHYSICIAN_ROLE, ADMIN_ROLE, PRACTITIONER_ROLE, CUSTOMER_ROLE, MEDSPA_ADMIN_ROLE];

/* patient statuses */
export const ALL_STATE = 'all';
export const CLEARED_STATE = 'cleared';
export const INCOMPLETE_STATE = 'incomplete';
export const NEW_STATE = 'new';
export const NOT_CLEARED_STATE = 'notCleared';
export const PENDING_RECLEAR_STATE = 'pendingReclear';
export const TO_RECLEAR_STATE = 'toReclear';
export const LEAD_STATE = 'lead';
export const ALL_PATIENTS_STATE = 'allPatients';
export const UPCOMING_PATIENTS_STATE = 'upcoming';
export const STARRED_STATE = 'starred';
export const PATIENT_IGNORED_STATE = 'ignored';
export const TO_CLEAR_STATE = 'toClear';
export const REJECTED_STATE = 'rejected';
export const PROFILE_COMPLETED_STATE = 'profileCompleted';
export const NO_SHOW_STATE = 'noShow';
export const TO_LIVE_CLEAR_STATE = 'toLiveClear';

export enum MedspaCalendarGFEStatusEnum {
  TO_CLEAR = TO_CLEAR_STATE,
  TO_RECLEAR = TO_RECLEAR_STATE,
  INCOMPLETE = INCOMPLETE_STATE,
  CLEARED = CLEARED_STATE,
  LEAD = LEAD_STATE,
  PENDING_RECLEAR = PENDING_RECLEAR_STATE,
  REJECTED = REJECTED_STATE,
  PROFILE_COMPLETED = PROFILE_COMPLETED_STATE,
  NO_SHOW = NO_SHOW_STATE,
  TO_LIVE_CLEAR = TO_LIVE_CLEAR_STATE,
}

/* variables carousel limits */
export const MIN_ITEMS_PHOTOS_LIMIT_MOBILE = 5;
export const MAX_ITEMS_PHOTOS_LIMIT_MOBILE = 7;
export const ITEMS_PHOTOS_LIMIT_DESKTOP = 10;
export const ITEMS_PHOTOS_ANNOTATIONS_LIMIT_MOBILE = 3;
export const ITEMS_PHOTOS_ANNOTATIONS_LIMIT_DESKTOP = 6;
export const WIDTH_MAX_DESKTOP = 1280;
export const MIN_PATIENT_PHOTOS = 1;

export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const longMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
/* variable to display hiluronidase warning */
export const LIMIT_TO_SHOW_HYALURONIDASE_WARNING = 8;

/* A string that is used in the new service visit modal. */
export const GFE_AND_NEW_SERVICE_VISIT_MODAL_CONFIRMATION =
  "I have confirmed and updated the patient's allergies, medications and medical history. " +
  'I have also completed a physical examination of the patient. No new contraindications have ' +
  'been identified, or if they were identified, I have alerted the overseeing medical director. ';

export const DEFAULT_ROWS_PER_PAGE = 10;

export const USER_TYPES = {
  ADMINISTRATOR: 'Administrator',
  PHYSICIAN: 'Physician',
  PRACTITIONER: 'Practitioner',
};

// default values needed for reset service form after submission
export const SERVICE_FORM_DEFAULT_VALUES = {
  annotatable: false,
  assetLabel: '',
  color: '',
  currentCost: '',
  daysToExpireAfterOpened: '',
  description: '',
  externalNote: '',
  followUpIntervalDays: '',
  internalNote: '',
  isTracked: false,
  name: '',
  prefix: '',
  price: '',
  serviceGroupId: '',
  showInEmr: false,
  startingUnits: 1,
  supplierId: null,
  unitLabel: '',
  galderma_sku: [],
};

export const SUPPLIER_GALDERMA_ID = 1;
export const UPDATING_SERVICE_VISIT = 'Updating service visit, please wait a moment...';
export const ERROR_OCCURRED = 'An error occurred. Please refresh the service visit and try again.';
export const PROGRESS_SAVE_ERROR =
  'An error occurred and you’ll need to refresh the page. If you repeatedly get ' +
  'this popup, press the back button to exit.';
export const SERVICE_GROUP_NAME_FOR_PHOTOS = 'Photo';
export const MISC_SERVICE_GROUP_ID = 12;
export const MISC_SERVICE_GROUP_NAME = 'Misc';
export const CLERICAL_SERVICE_GROUP_ID = 33;
export const CLERICAL_SERVICE_GROUP_NAME = 'Clerical';
export const NEUROTOXINS_SERVICE_GROUP_ID = 1;
export const FLEX_SERVICE_GROUP_ID = 35;
export const AESTHETICIAN_SERVICERS_GROUP_NAME = 'Aesthetician';

export const SNACKBAR_SUCCESS = 'success';
export const SNACKBAR_ERROR = 'error';
export const SNACKBAR_WARNING = 'warning';
export const SNACKBAR_INFO = 'info';

export const PORTRAIT_FLEX = 'PortraitFlex';
export const PORTRAIT_LAUNCH = 'PortraitLaunch';
export const PORTRAIT_ASCEND = 'PortraitAscend';
export const PORTRAIT_LEGACY = 'PortraitLegacy';
export const PORTRAIT_LEGACY_PLUS = 'PortraitLegacyPlus';

export const PRODUCT_TYPE_NAME_MAP: Record<string, string> = {
  [PORTRAIT_FLEX]: 'FLEX',
  [PORTRAIT_LAUNCH]: 'LAUNCH',
  [PORTRAIT_ASCEND]: 'ASCEND',
  [PORTRAIT_LEGACY]: 'LEGACY',
  [PORTRAIT_LEGACY_PLUS]: 'LEGACY+',
};

// GFE setting constants
export const NORMAL_REQUEST = 0;
export const NORMAL_REQUEST_NAME = 'normal';
export const URGENT_REQUEST = 1;
export const URGENT_REQUEST_NAME = 'urgent';

export const GFE_SETTING_NO_PROVIDER_NAME = 'non_legacy_no_provider';
export const GFE_SETTING_NO_PROVIDER_VALUE = 3;
export const GFE_SETTING_OWN_PROVIDER_NAME = 'non_legacy_assigned_provider_only';
export const GFE_SETTING_OWN_PROVIDER_VALUE = 2;
export const GFE_SETTING_PORTRAIT_PROVIDER_NAME = 'non_legacy_clearing_provider_only';
export const GFE_SETTING_PORTRAIT_PROVIDER_VALUE = 1;
export const GFE_SETTING_MAP: Record<string, number> = {
  [GFE_SETTING_NO_PROVIDER_NAME]: GFE_SETTING_NO_PROVIDER_VALUE,
  [GFE_SETTING_OWN_PROVIDER_NAME]: GFE_SETTING_OWN_PROVIDER_VALUE,
  [GFE_SETTING_PORTRAIT_PROVIDER_NAME]: GFE_SETTING_PORTRAIT_PROVIDER_VALUE,
};

export const NO_FEE_VALUE = 0;
export const CANCELLATION_FEE_VALUE = 1;
export const DEPOSIT_FEE_VALUE = 2;

export const feeTypeMap = {
  no_fee: NO_FEE_VALUE,
  cancellation_fee: CANCELLATION_FEE_VALUE,
  deposit_fee: DEPOSIT_FEE_VALUE,
};

export const NAIVE = 'Naive';
export const FAKE = 'Zotox';

export const usStates = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const SALES_ROUND_ROBIN = 'https://meetings.hubspot.com/marcus-yco/portraitintro';
export const PORTRAIT_SUPPORT_CENTER = 'https://support.joinportrait.com/knowledge/kb-tickets/new';
