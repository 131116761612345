import React from 'react';
import moment from 'moment';
import { Box, Tooltip } from '@material-ui/core';
import { FlipCameraIosOutlined, InsertPhotoOutlined, MessageOutlined, Update } from '@material-ui/icons';
import { ROUTES } from '../routes.constants';
import { CustomLink } from '../../components/common/Custom/CustomLink';
import { IPatientView } from '../../types/Patient';
import { getHumanReadableInternalSerialNumber } from '../../utils/inventory.utils';
import { SerialNumberButton } from '../../components/common/Button';
import { Pill } from '../../components/common/Pill';
import { EDIT_PRACTITIONER } from '../../routes/administratorRoutes';

export const NewMessagesIcon = ({ count }: { count: number }) => (
  <Tooltip title={`${count} new messages`}>
    <MessageOutlined />
  </Tooltip>
);

export const UnseenPhotosIcon = ({ count }: { count: number }) => (
  <Tooltip title={`${count} new photos`}>
    <InsertPhotoOutlined />
  </Tooltip>
);

export const MedicalProfileUpdatedIcon = () => (
  <Tooltip title="medical profile updated since last view">
    <Update />
  </Tooltip>
);
export const PhotoRequestedIcon = () => (
  <Tooltip title="new photos were requested">
    <FlipCameraIosOutlined />
  </Tooltip>
);

export const ALL_PATIENT_COLUMNS = [
  { field: 'id', title: 'id' },
  {
    field: 'firstName',
    title: 'First name',
    sortable: true,
    sortField: 'first_name',
    render: (value: string, row: IPatientView) => (
      <CustomLink href={ROUTES.PATIENT_ID(String(row.id))}>{value}</CustomLink>
    ),
  },
  {
    field: 'lastName',
    title: 'Last name',
    sortable: true,
    sortField: 'last_name',
    render: (value: string, row: IPatientView) => (
      <CustomLink href={ROUTES.PATIENT_ID(String(row.id))}>{value}</CustomLink>
    ),
  },
  {
    field: 'providerLabeling',
    width: '200px',
    title: 'Provider',
    sortable: true,
    sortField: 'provider_labeling',
    render: (value: string, row: IPatientView) => (
      <>
        <CustomLink href={EDIT_PRACTITIONER(String(row.practitionerId))}>{value}</CustomLink>
        {!!row?.practitionerAssigned?.length && (
          <>
            <Tooltip title={row.practitionerAssigned.join(', ')}>
              <Box style={{ color: 'rgb(33, 114, 187)', cursor: 'pointer', textDecoration: 'underline' }}>
                and more...
              </Box>
            </Tooltip>
          </>
        )}
      </>
    ),
  },
  { field: 'email', title: 'Email' },
  {
    field: '',
    title: 'Updates',
    render: (v: string, row: IPatientView) => (
      <CustomLink href={ROUTES.PATIENT_ID(String(row.id))}>
        {row.unreadMessagesCount > 0 && <NewMessagesIcon count={row.unreadMessagesCount} />}
        {row.unseenPhotosCount > 0 && <UnseenPhotosIcon count={row.unseenPhotosCount} />}
        {row.needsDoctorReview && <MedicalProfileUpdatedIcon />}
        {row.hasRequestedPhotos && <PhotoRequestedIcon />}
      </CustomLink>
    ),
  },
  {
    field: 'nextAppointment',
    title: 'Next appointment',
    sortable: true,
    sortField: 'next_appointment',
    render: (value: string) => (value ? moment(value).format('MM/DD/YYYY HH:mm') : ''),
  },
  { field: 'visits', title: 'Visits', sortable: true, sortField: 'visits' },
  { field: 'status', title: 'Status', sortable: true, sortField: 'status' },
  { field: 'phone', title: 'Phone' },
  {
    field: 'createdAt',
    title: 'Created at',
    width: '150px',
    sortable: true,
    sortField: 'created_at',
    render: (value: string) => moment(value).format('MM/DD/YYYY HH:mm'),
  },
];

export const PRACTITIONER_PATIENT_COLUMNS = [
  { field: 'id', title: 'id' },
  {
    field: 'firstName',
    title: 'Name',
    sortable: true,
    sortField: 'first_name',
    render: (value: string, row: IPatientView) => (
      <CustomLink href={ROUTES.PATIENT_ID(String(row.id))}>
        {value} {row.lastName}
      </CustomLink>
    ),
  },
  { field: 'email', title: 'Email' },
  {
    field: '',
    title: 'Updates',
    render: (v: string, row: IPatientView) => (
      <CustomLink href={ROUTES.PATIENT_ID(String(row.id))}>
        {row.unreadMessagesCount > 0 && <NewMessagesIcon count={row.unreadMessagesCount} />}
        {row.unseenPhotosCount > 0 && <UnseenPhotosIcon count={row.unseenPhotosCount} />}
        {row.needsDoctorReview && <MedicalProfileUpdatedIcon />}
        {row.hasRequestedPhotos && <PhotoRequestedIcon />}
      </CustomLink>
    ),
  },
  {
    field: 'nextAppointment',
    title: 'Next appointment',
    sortable: true,
    sortField: 'next_appointment',
    render: (value: string) => (value ? moment(value).format('MM/DD/YYYY HH:mm') : ''),
  },
  { field: 'visits', title: 'Visits', sortable: true, sortField: 'visits' },
  { field: 'status', title: 'Status', sortable: true, sortField: 'status' },
  { field: 'phone', title: 'Phone' },
];

export const SERVICE_VISIT_ASSETS_COLUMNS = [
  { field: 'id', title: 'Asset id' },
  { field: 'serviceId', title: 'Service id' },
  { field: 'warehouseId', title: 'Warehouse id' },
  { field: 'name', title: 'Name', sortable: true, width: '200px' },
  {
    field: 'internalSerialNumber',
    title: 'Internal serial number',
    render: (value: string) => <SerialNumberButton title={getHumanReadableInternalSerialNumber(value)} value={value} />,
  },
  {
    field: 'markedAsEmpty',
    title: 'Marked as empty',
    render: (value: boolean) => (value ? 'Yes' : 'No'),
  },
  { field: 'warehouseLabel', title: 'Warehouse', sortable: true },
  { field: 'initialNumberOfUnits', title: 'Initial number of units' },
  {
    field: 'unitCost',
    title: 'Unit cost',
    sortable: true,
    sortField: 'unit_cost',
    render: (value: number) => value.toFixed(2),
  },
  {
    field: 'assetCost',
    title: 'Asset cost',
    sortable: true,
    sortField: 'asset_cost',
    render: (value: number) => value.toFixed(2),
  },
  {
    field: 'unitsSold',
    title: 'Units sold',
    sortable: true,
    sortField: 'units_sold',
    render: (value: number) => value.toFixed(2),
  },
  {
    field: 'serviceVisits',
    title: 'Checkouts',
    width: '400px',
    render: (values: string[]) =>
      values.map((url) => (
        <Tooltip key={url} title={url}>
          <a href={url} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
            <Pill title="EHR" color="gray" style={{ cursor: 'pointer' }} />{' '}
          </a>
        </Tooltip>
      )),
  },
];

export const SERVICES_COLUMNS = [
  { field: 'id', title: 'ID' },
  { field: 'name', title: 'Name', width: '200px' },
  { field: 'serviceGroupName', title: 'Service group', width: '200px' },
  { field: 'supplierServiceName', title: 'Supplier', width: '200px' },
  { field: 'listPrice', title: 'List Price' },
  { field: 'currentCost', title: 'COGS Invoice' },
  { field: 'currentCostWithGuaranteedRebates', title: 'COGS w/ GuaranteedRebates' },
  { field: 'currentCostWithAllRebates', title: 'COGS w/ AllRebates' },
  { field: 'price', title: 'Floor Price' },
  { field: 'legacyPrice', title: 'Legacy / % Savings' },
  { field: 'legacyPlusPrice', title: 'LegacyPlus / % Savings' },
  { field: 'flexPrice', title: 'Flex / % Savings' },
  { field: 'launchPrice', title: 'Launch / % Savings' },
  { field: 'ascendPrice', title: 'Ascend / % Savings' },
];

export const PRACTITIONER_SERVICES_COLUMNS = [
  { field: 'name', title: 'Name', width: '200px' },
  { field: 'serviceGroupName', title: 'Service group', width: '200px' },
  { field: 'supplierServiceName', title: 'Supplier', width: '200px' },
  { field: 'listPrice', title: 'List Price' },
  { field: 'rolePrice', title: 'Portrait Price / % Savings' },
];
