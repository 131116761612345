import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import EarningsCalculator from 'src/components/DashboardPractitioner/Tabs/PractitionerDashboardTab/EarningsCalculator';
import { useSelector } from 'react-redux';
import { MonthlyBlockers } from 'src/components/DashboardPractitioner/Tabs/PractitionerDashboardTab/MonthlyBlockers';
import Adjustments from 'src/components/DashboardPractitioner/Tabs/PractitionerDashboardTab/Adjustments';
import ChartsDashboard from 'src/components/DashboardPractitioner/Tabs/PractitionerDashboardTab/ChartsDashboard';
import moment, { Moment } from 'moment';
import { IMedspaLocation } from 'src/interfaces/ILocation';
import { FlexTransactions } from 'src/components/DashboardPractitioner/Tabs/PractitionerDashboardTab/FlexTransactions';
import Transactions from 'src/components/DashboardPractitioner/Tabs/PractitionerDashboardTab/Transactions';
import {
  useMedspaEarningsCalculations,
  useMedspaFlexTransactions,
  useMedspaPractitionerYearStatistics,
  useMedspaPractitioners,
  useMedspaRole,
} from 'src/hooks/queries/useMedspaAdmins';
import {
  MEDSPA_ADMIN_ROLE,
  PORTRAIT_FLEX,
  PORTRAIT_LEGACY,
  PORTRAIT_LEGACY_PLUS,
  PORTRAIT_LAUNCH,
  PORTRAIT_ASCEND,
} from 'src/constants/general.constants';
import { IPractitionerDashboardGraphs } from 'src/services/PractitionersDashboard';
import { InfiniteData } from 'react-query';
import { PossibleTransactionResponse } from 'src/hooks/queries/usePractitionersDashboard';
import { months } from '../../../../constants/general.constants';

const FORMAT_TO_SHOW_INPUT = 'MMM YYYY';
const FORMAT_TO_SEND_TO_API = 'MM-YYYY';

const initialSerieValues = Array(12).fill(0);

export type SelectedLocation = IMedspaLocation | 'all' | 'no' | string;

const earningsInitialData = {
  earningsTiers: { services: [], retail: [], tips: [], tier5Percent: [], tier10Percent: [] },
  totals: {
    services: 0,
    retail: 0,
    tips: 0,
    totalEarnings: 0,
    tier5Percent: 0,
    tier10Percent: 0,
    amountBelowPriceFloor: 0,
  },
  baseCalculations: {
    totalServicesWithDiscount: 0,
  },
  firstReport: moment().format('YYYY-MM-DD'),
};

const initialData: IPractitionerDashboardGraphs = {
  revenue: {
    xAxisLabels: [...months],
    series: [
      {
        name: 'Revenue',
        data: [...initialSerieValues],
      },
    ],
  },
  earnings: {
    xAxisLabels: [...months],
    series: [
      {
        name: 'Earnings',
        data: [...initialSerieValues],
      },
    ],
  },
  appointments: {
    xAxisLabels: [...months],
    series: [
      {
        name: 'Appointments',
        data: [...initialSerieValues],
      },
    ],
  },
  appointmentsAverage: {
    xAxisLabels: [...months],
    series: [
      {
        name: 'Appointments Average',
        data: [...initialSerieValues],
      },
    ],
  },
};

const initialFilter = {
  search: '',
  page: 1,
  limit: 20,
  month: '',
  year: '',
  sort: '',
  sortDirection: '',
  status: '',
  practitionerId: '',
  location: 'all',
};

const oldestRecordFilter = {
  search: '',
  page: 1,
  per: 1,
  month: '',
  year: '',
  sort: 'checkouts.created_at',
  sortDirection: 'asc',
};

const MedspaAdminEarningsCalculator = () => {
  const {
    roleName,
    user: { userGroupId },
  } = useSelector(({ auth }: any) => auth);
  const { data: medspaRoleName } = useMedspaRole(roleName === MEDSPA_ADMIN_ROLE);
  const [totalServicesWithDiscount, setTotalServicesWithDiscount] = useState(0);
  const [practitionerId, setPractitionerId] = useState<string>('all');
  const [selectedDate, setSelectedDate] = useState<Moment>(moment());
  const [searchQuery, setSearchQuery] =
    useState<Record<string, Moment | string | number | null | SelectedLocation>>(initialFilter);
  const { data: practitioners = [], isLoading: isLoadingPractitioners } = useMedspaPractitioners(
    roleName === MEDSPA_ADMIN_ROLE
  );

  const isFlexOrLite =
    medspaRoleName === PORTRAIT_FLEX || medspaRoleName === PORTRAIT_LAUNCH || medspaRoleName === PORTRAIT_ASCEND;
  const isLegacyMedspa = medspaRoleName === PORTRAIT_LEGACY || medspaRoleName === PORTRAIT_LEGACY_PLUS;

  const setSelectedMedspaLocation = (location: SelectedLocation | null) => {
    setSearchQuery({ ...searchQuery, location });
  };

  const selectedMedspaLocation = searchQuery.location as SelectedLocation;

  const { data: chartDataMedspaPract = initialData, isLoading: isLoadingMedspaPractData } =
    useMedspaPractitionerYearStatistics(
      userGroupId as string,
      practitionerId as string,
      selectedDate.format(FORMAT_TO_SEND_TO_API),
      !!userGroupId
    );
  const {
    data: earningsCalculator = earningsInitialData,
    isLoading: isLoadingEarningsCalculators,
    isError: earningsCalculatorError,
  } = useMedspaEarningsCalculations(
    selectedDate.format(FORMAT_TO_SEND_TO_API),
    userGroupId as string,
    practitionerId as string,
    isLegacyMedspa && !isLoadingPractitioners
  );

  const {
    results: medspaPractResults,
    isLoading: isLoadingMedspaPractResults,
    isFetching: isFetchingMedspaPractResults,
    isError: isTransactionError,
    hasNextPage,
    fetchNextPage,
  } = useMedspaFlexTransactions(
    userGroupId,
    practitionerId as string,
    searchQuery,
    isFlexOrLite && !isLoadingPractitioners
  );

  const { results: oldestRecords, isLoading: isLoadingOldestRecords } = useMedspaFlexTransactions(
    userGroupId,
    practitionerId as string,
    oldestRecordFilter,
    isFlexOrLite && !isLoadingPractitioners
  );

  const [minDate] = useState(earningsCalculator?.firstReport ? moment(earningsCalculator?.firstReport) : null);

  const handlePractitionerSelect = (selectedPractitionerId: string) => {
    setPractitionerId(selectedPractitionerId);
  };

  return (
    <Box width="100%">
      <div style={{ width: '100%', marginTop: '20px' }}>
        {isLegacyMedspa && <MonthlyBlockers practitionerId={practitionerId} />}

        {isLegacyMedspa && <Adjustments showPriceFloorAdjustments />}
        <ChartsDashboard
          chartData={chartDataMedspaPract as IPractitionerDashboardGraphs}
          isLoadingChartData={isLoadingMedspaPractData}
          selectedDate={selectedDate}
          onChangeDate={setSelectedDate}
          formatToShow={FORMAT_TO_SHOW_INPUT}
          isFlexOrLite={isFlexOrLite}
          minDate={isLegacyMedspa ? minDate : null}
          medspaPractitioners={practitioners}
          selectedPractitioner={practitionerId}
          handlePractitionerSelect={handlePractitionerSelect}
        />
        {isLegacyMedspa && (
          <EarningsCalculator
            earnings={earningsCalculator || earningsInitialData}
            isLoading={isLoadingEarningsCalculators}
            isError={earningsCalculatorError}
            minDate={minDate}
            errorMessage="There was an error fetching earnings."
            selectedDate={selectedDate}
            onChangeDate={setSelectedDate}
            onChangeTotalServicesWithDiscount={setTotalServicesWithDiscount}
            formatToShow={FORMAT_TO_SHOW_INPUT}
          />
        )}
        {isFlexOrLite ? (
          <FlexTransactions
            transactions={medspaPractResults as InfiniteData<PossibleTransactionResponse>}
            oldestTransactionRecords={oldestRecords as InfiniteData<PossibleTransactionResponse>}
            selectedMedspaLocation={selectedMedspaLocation}
            setSelectedMedspaLocation={setSelectedMedspaLocation}
            setSearchQuery={(search) => setSearchQuery(search)}
            searchQuery={searchQuery}
            isLoading={isLoadingMedspaPractResults}
            isLoadingOldestRecord={isLoadingOldestRecords}
            isFetching={isFetchingMedspaPractResults}
            isError={isTransactionError}
            hasNextPage={hasNextPage as boolean}
            fetchNextPage={fetchNextPage}
            selectedDate={selectedDate}
          />
        ) : (
          <Transactions
            selectedDate={selectedDate}
            formatToShow={FORMAT_TO_SHOW_INPUT}
            formatToQuery={FORMAT_TO_SEND_TO_API}
            totalServicesWithDiscount={totalServicesWithDiscount}
            practitionerId={practitionerId}
          />
        )}
      </div>
    </Box>
  );
};

export default MedspaAdminEarningsCalculator;
