import { InfiniteData, useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { useEffect, useState } from 'react';
import Support from 'src/services/Support';
import { toast } from 'react-toastify';
import { dispatch } from 'src/rematch';

import {
  SERVICE_VISIT,
  SERVICE_VISITS_FULL_HISTORY,
  SERVICE_VISIT_OPENED,
  PHYSICIAN_SERVICE_VISITS_BY_PROVIDER,
} from '../../constants/reactQuery.keys';
import ServiceVisit from '../../services/ServiceVisit';
import { showSnackbar, useReactQueryOnErrorHandler } from '../../utils/global';
import compile from '../../utils/toastMessagesCompiler';
import { SNACKBAR_SUCCESS } from '../../constants/general.constants';
import IServiceVisit from '../../interfaces/IServiceVisits';
import { IPhysicianServiceVisitResponse } from '../../interfaces/ICustomer';

/* Get customer info
 * Params: id (service visit id), lastClosed? (if only want the lastest closed SV)
 */
export function useFullServiceHistory(id: number, params?: { lastClosed?: boolean; visitType?: string }) {
  const response = useQuery([SERVICE_VISITS_FULL_HISTORY, id], () => ServiceVisit.getFullServiceHistory(id, params), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    onError: () =>
      useReactQueryOnErrorHandler(
        compile('generic.error_message', { action: 'fetching', element: 'full service history' })
      ),
  });

  return {
    ...response,
    data: response.data || [],
  };
}

export const useServiceVisit = (id: number) => {
  const response = useQuery([SERVICE_VISIT, id], () => ServiceVisit.getServiceVisit(id), {
    refetchOnWindowFocus: true, // For multi-tab purposes
    keepPreviousData: true,
    onError: () =>
      useReactQueryOnErrorHandler(compile('generic.error_message', { action: 'fetching', element: 'service visit' })),
  });
  return {
    ...response,
    data: response.data || ({} as IServiceVisit),
  };
};

export const useSubmitServiceVisitMutation = (id: number, patientId: number) => {
  const queryClient = useQueryClient();

  return useMutation(() => ServiceVisit.submitServiceVisit(id), {
    onSuccess: ({ success }: { success: boolean }) => {
      if (success) {
        showSnackbar(
          compile('generic.success_message', {
            element: 'Service visit',
            action: 'submitted',
          }),
          SNACKBAR_SUCCESS
        );

        queryClient.invalidateQueries([SERVICE_VISIT_OPENED, patientId]);
        queryClient.invalidateQueries([SERVICE_VISIT, id]);
      } else {
        showSnackbar(compile('generic.error_message', { action: 'updating', element: 'service visit' }));
      }
    },
    onError: useReactQueryOnErrorHandler,
  });
};

export const useGFEDiagnosisAcceptedServiceVisitMutation = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation(() => ServiceVisit.setGFEDiagnosisAcceptedServiceVisit(id), {
    onSuccess: ({ success }: { success: boolean }) => {
      if (success) {
        queryClient.invalidateQueries([SERVICE_VISIT, id]);
      } else {
        showSnackbar(compile('generic.error_message', { action: 'updating', element: 'service visit' }));
      }
    },
    onError: useReactQueryOnErrorHandler,
  });
};

export const useDeleteServiceVisitMutation = (id: number, patientId: number) => {
  const queryClient = useQueryClient();

  return useMutation(() => ServiceVisit.deleteServiceVisit(id), {
    onSuccess: ({ success }: { success: boolean }) => {
      if (success) {
        queryClient.invalidateQueries([SERVICE_VISIT_OPENED, patientId]);
        queryClient.invalidateQueries([SERVICE_VISIT, id]);
      } else {
        showSnackbar(compile('generic.error_message', { action: 'updating', element: 'service visit' }));
      }
    },
    onError: useReactQueryOnErrorHandler,
  });
};

export const useForceOpenCloseServiceVisit = (patientId: number, serviceVisitId: number) => {
  const { mutate: forceCloseOpenServiceVisit } = useMutation({
    mutationFn: () => Support.forceCloseOpenServiceVisit({ patientId, serviceVisitId }),
    onSuccess: () => {
      toast.success('Service visit updated successfully');
      dispatch.fullServiceHistory.getFullServiceHistory({ patientId });
    },
    onError: () => {
      toast.error('Error updating service visit');
    },
  });

  return forceCloseOpenServiceVisit;
};

export const useAddServiceVisitNoteMutation = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: { customerNote: string; internalNote: string }) => ServiceVisit.addServiceVisitNote(id, params),
    {
      onSuccess: ({ success }: { success: boolean }) => {
        if (success) {
          queryClient.invalidateQueries([SERVICE_VISIT, id]);
        } else {
          showSnackbar(compile('generic.error_message', { action: 'adding', element: 'service visit note' }));
        }
      },
      onError: useReactQueryOnErrorHandler,
    }
  );
};

export const useSetMedspaLocationMutation = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation((params: { medspaLocationId: string | number }) => ServiceVisit.setMedspaLocation(id, params), {
    onSuccess: ({ success }: { success: boolean }) => {
      if (success) {
        queryClient.invalidateQueries([SERVICE_VISIT, id]);
      }
    },
  });
};

export const useAddMedicalDirectorNoteMutation = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation((params: { medicalDirectorNote: string }) => ServiceVisit.addMedicalDirectorNote(id, params), {
    onSuccess: ({ success }: { success: boolean }) => {
      if (success) {
        queryClient.invalidateQueries([SERVICE_VISIT, id]);
        queryClient.invalidateQueries([PHYSICIAN_SERVICE_VISITS_BY_PROVIDER]);
      } else {
        showSnackbar(compile('generic.error_message', { action: 'adding', element: 'medical director note' }));
      }
    },
    onError: useReactQueryOnErrorHandler,
  });
};

export interface PhysicianServiceVisitQueryParams {
  /* eslint-disable camelcase */
  practitioner_id: number;
  page?: number;
  sortBy?: string;
  search?: string;
  appointmentType?: string;
}

export const usePhysicianServiceVisitsByProvider = (paramsQuery: PhysicianServiceVisitQueryParams) => {
  const [results, setResults] = useState<InfiniteData<IPhysicianServiceVisitResponse>>();
  const { data, isLoading, fetchNextPage, hasNextPage, isFetching, isError, refetch } = useInfiniteQuery(
    [PHYSICIAN_SERVICE_VISITS_BY_PROVIDER, JSON.stringify(paramsQuery)],
    ({ pageParam = 1 }) => ServiceVisit.getPhysicianServiceVisitsByProvider({ ...paramsQuery, ...{ page: pageParam } }),
    {
      staleTime: 60000,
      getNextPageParam: (lastPage: IPhysicianServiceVisitResponse) => {
        if (lastPage?.meta?.currentPage < lastPage?.meta?.totalPages) {
          return lastPage.meta.currentPage + 1;
        }
        return false;
      },
    }
  );

  useEffect(() => {
    if (!isLoading && data) {
      setResults(data);
    }
  }, [data, isLoading]);

  return {
    refetch,
    results,
    isLoading,
    isFetching,
    isError,
    fetchNextPage,
    hasNextPage,
  };
};
