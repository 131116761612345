import React, { useState, useEffect } from 'react';
import { Box, Button, Dialog, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import { capitalize } from 'lodash';
import CreditCardIcon from 'src/components/PatientProfile/CreditCards/CreditCardIcon';
import { IPaymentCard } from 'src/interfaces/IMedspaAdminList';
import classNames from 'classnames';
import { CARD_FEE_PCT } from 'src/constants/inventory.constants';
import AddCreditCard from './AddCreditCard';
import { useStyles } from './CardOnFIle.styles';

type Props = {
  creditCards: IPaymentCard[];
  value?: string | null;
  isLoading: boolean;
  onChange: (value: string) => void;
};
export const CardList = ({ isLoading, creditCards = [], value, onChange }: Props): React.ReactElement => {
  const classes = useStyles();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!value && !!creditCards.length) {
      const defaultCardId = creditCards.find(({ defaultCard }) => defaultCard)?.id;
      onChange(defaultCardId ?? creditCards[0].id);
    }
  }, [creditCards, value]);

  return (
    <Box className={classes.outerBox}>
      <Box className={classes.title}>
        <Typography>
          Card <span>{CARD_FEE_PCT}% Fee</span>
        </Typography>
        <Box margin="auto 0 auto auto" maxWidth={100}>
          {!!creditCards.length && (
            <Button
              color="default"
              data-testid="orderPayment.addNewCard"
              onClick={() => setDialogOpen(true)}
              disabled={isLoading}
              className={classes.capitalize}
            >
              Add New
            </Button>
          )}
        </Box>
      </Box>
      <Box className={classes.innerBox}>
        <RadioGroup
          className={classes.radioGroup}
          value={value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
        >
          {!creditCards.length ? (
            <Box className={classNames(classes.radioItem, classes.radioItemEmpty)}>
              <Box margin="auto 0">
                <Typography className={classes.cardName}>No cards added yet</Typography>
              </Box>
              <Button
                variant="outlined"
                data-testid="orderPayment.addNewCard"
                onClick={() => setDialogOpen(true)}
                disabled={isLoading}
                className={classes.addCardButton}
              >
                Add New Card
              </Button>
            </Box>
          ) : (
            creditCards.map(
              ({ cardBrand, last4, id }: IPaymentCard): React.ReactElement => (
                <FormControlLabel
                  key={last4}
                  className={classes.radioItem}
                  value={id}
                  disabled={isLoading}
                  control={<Radio color="primary" />}
                  label={
                    <Box
                      className={classes.cardRow}
                      data-testid="orderCards"
                      width="100%"
                      display="flex"
                      flexDirection="row"
                    >
                      <Typography className={classes.cardName}>
                        {capitalize(cardBrand?.split('_').join(' '))} card <span>ending in {last4}</span>
                      </Typography>
                      <CreditCardIcon cardBrand={cardBrand} noFormat />
                    </Box>
                  }
                />
              )
            )
          )}
        </RadioGroup>
        <Dialog open={dialogOpen} PaperProps={{ className: classes.addCardDialogPaper }}>
          <AddCreditCard closeDialog={() => setDialogOpen(false)} />
        </Dialog>
      </Box>
    </Box>
  );
};
