import React from 'react';
import { Box, TableContainer, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import TableHeader, { ColumnType } from '../../../common/Table/TableHeader';
import { MultipleSkeleton } from '../../../common/LoadingSkeleton';
import EarningsDashboardTableRow from './EarningsDashboardTableRow';
import { IEarningCalculator } from '../../../../interfaces/IEarningCalcultator';
import { IEarningResponse } from '../../../../services/PractitionersDashboard';
import { ReactComponent as GreenCheck } from '../../../../assets/images/green-check.svg';

type TableEarningsProps = {
  data: IEarningResponse;
  errorMessage: string;
  isLoading: boolean;
  isError: boolean;
  earningsLabel: string;
  enabledPriceFloor: boolean;
};

type TableEarningResultsProps = {
  data: IEarningCalculator[] | undefined;
  errorMessage: string;
  isError: boolean;
};

const TABLE_COLUMNS: ColumnType[] = [
  { id: 'before_dummy_col', title: '', align: 'start' },
  { id: 'amount', title: 'Amount', align: 'start' },
  { id: 'rate', title: 'Rate', align: 'start' },
  { id: 'base', title: 'Base Amount', align: 'end' },
  { id: 'month_to_date', title: 'Earnings Before Adjustments', align: 'end' },
  { id: 'after_dummy_col', title: '', align: 'start' },
];

const EARNINGS_ADJUSTMENT_FROM_PRICE_FLOORS_LABEL = 'Earnings Adjustment From Suggested Retail Prices and BeautiBank';
const NET_PAY_LABEL = 'Net Pay';

const ShowMessage = ({ text }: { text: string }) => (
  <tr>
    <td colSpan={TABLE_COLUMNS.length}>
      <Box textAlign="center">
        <p>{text}</p>
      </Box>
    </td>
  </tr>
);

const TableResults = ({ data, isError, errorMessage }: TableEarningResultsProps) => {
  if (isError) {
    return <ShowMessage text={errorMessage} />;
  }

  if (!data) {
    return <></>;
  }

  return data.length > 0 ? (
    <>
      {data.map(({ id, label, rate, base, amount, isTotal, bold, italicized }) => (
        <EarningsDashboardTableRow
          key={id}
          category={label}
          rate={rate}
          baseAmount={base}
          amount={amount}
          isTotal={isTotal}
          bold={bold}
          italicized={italicized}
        />
      ))}
    </>
  ) : (
    <ShowMessage text="No data to show" />
  );
};

const EarningsDashboardTable = ({
  isLoading,
  isError,
  errorMessage,
  data,
  earningsLabel,
  enabledPriceFloor,
}: TableEarningsProps) => {
  const { earningsTiers, totals } = data;

  const { retail = [], services = [], tips = [] } = earningsTiers || {};

  const emptyRow = { id: -2, label: '' };
  const totalDeduction = (totals?.amountBelowPriceFloor || 0) + (totals?.bbDiscountedWholesaleTotal || 0);
  const totalRow = {
    id: -3,
    label: earningsLabel,
    isTotal: true,
    bold: true,
    amount: totals?.totalEarnings || 0,
  };
  const earningAdjustmentFromPriceFloorsRow = {
    id: -4,
    label: EARNINGS_ADJUSTMENT_FROM_PRICE_FLOORS_LABEL,
    isTotal: false,
    bold: true,
    italicized: false,
    amount: totalDeduction < 0 ? totalDeduction : <GreenCheck />,
  };
  const netPayRow = {
    id: -4,
    label: NET_PAY_LABEL,
    isTotal: true,
    bold: true,
    italicized: false,
    amount: totalDeduction < 0 ? (totals?.totalEarnings || 0) + totalDeduction : totals?.totalEarnings || 0,
  };

  const tableRows = [
    ...services.map((el) => ({ ...el, bold: true })),
    ...retail.map((el) => ({ ...el, bold: true })),
    ...tips.map((el) => ({ ...el, bold: true })),
    emptyRow,
    totalRow,
  ];

  if (enabledPriceFloor) {
    tableRows.push(earningAdjustmentFromPriceFloorsRow);
    tableRows.push(netPayRow);
  }

  return (
    <TableContainer style={{ position: 'sticky', minHeight: '350px', display: 'flex', justifyContent: 'center' }}>
      <Table>
        <TableHeader columns={TABLE_COLUMNS} />
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={6}>
                <MultipleSkeleton addPosition={false} />
              </TableCell>
            </TableRow>
          ) : (
            <>
              <TableResults data={tableRows} isError={isError} errorMessage={errorMessage} />
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EarningsDashboardTable;
