import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  '@global': {
    html: {
      height: '100%',
    },
    body: {
      height: '100%',
      backgroundColor: '#fff',
      '& > div#root': {
        height: '100%',
        overflowX: 'hidden',
      },
    },
  },
  waitingText: {
    fontSize: '25px',
    fontFamily: 'Messina sans-serif',
    textAlign: 'center',
    margin: 'auto',
  },
  signupContainer: {
    display: 'flex',
    minWidth: '100vw',
    minHeight: '100vh',
  },
  left: {
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.75rem',
    '@media (max-width: 868px)': {
      display: 'none', // Hide on mobile and tablet
    },
  },
  leftInside: {
    width: '100%',
    height: '100%',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: '#EEE7E4',

    '& > div > p': {
      fontSize: '24px',
      lineHeight: '32px',
      color: '#421231',
      fontFamily: 'Messina Sans',
      fontWeight: 'lighter',
    },
  },
  right: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media (max-width: 868px)': {
      width: '100%',
    },
  },
  mobileOnly: {
    display: 'none',
    '@media (max-width: 868px)': {
      display: 'inline',
    },
  },
  btnContainer: {
    display: 'flex',
    width: '80%',
    justifyContent: 'end',
    alignItems: 'center',
    gap: '1rem',

    '& > p': {
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
      fontFamily: 'SohneBuch',
      color: '#000000',
    },
  },
  btn: {
    width: '150px',
    height: '39px',
    padding: '14px 21px 14px 21px',
    borderRadius: '6px',
    background: '#421231',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'SohneBuch',
    color: '#ffffff',
    textTransform: 'none',

    '&:hover': {
      opacity: '0.8',
      color: '#421231',
      border: '1px solid #421231',
    },
  },
  sidebarContainer: {
    width: '300px',
    background: '#EDE6E4',
    height: '100%',
    padding: '2rem',
  },
  signupStepsContainer: {
    margin: '2rem 0 0 1rem',
    display: 'flex',
    flexDirection: 'column',
  },
  signupStep: {
    display: 'flex',
    fontWeight: 'bold',
    alignItems: 'center',
    fontSize: '18px',
    marginBottom: '1rem',
    '& > svg': {
      marginRight: '10px',
    },
  },
  cleared: {
    opacity: '50%',
  },
  signupStepCompleted: {
    color: '#F16C00',
    '& > svg > circle': {
      stroke: '#F16C00',
    },
    '& > svg > path': {
      stroke: '#F16C00',
    },
  },
  signupStepIncomplete: {
    color: '#75665A',
  },
  signupDetails: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    flexDirection: 'column',
    padding: '1rem',
    height: '100%',
  },
  signUpDetailsInBeige: {
    '@media (max-width: 868px)': {
      backgroundColor: '#EEE7E4', // Hide on mobile and tablet
    },
  },
  signupComplete: {
    maxWidth: '400px',
    padding: '1.5rem',
    paddingTop: '0',

    '& > p': {
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px',
      fontFamily: 'SohneBuch',
      color: '#000000',
    },
  },
  signupCompleteHeader: {
    maxWidth: '400px',
  },
  signupForm: {
    width: '80%',
    maxWidth: '500px',
    '& h2': {
      fontWeight: 'normal',
    },
  },
  signupNextStepContainer: {
    width: '600px',
  },
  loginLink: {
    textAlign: 'center',
  },
  signupButton: {
    background: '#F16C00',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    fontSize: '16px',
    width: '100%',
    padding: '1rem',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:disabled': {
      backgroundColor: '#979797',
      color: 'white',
    },
  },
  signupField: {
    marginBottom: '2rem',
    '& .MuiInputBase-input::placeholder': {
      fontStyle: 'italic',
    },
  },
  bold: {
    fontFamily: 'Messina Sans Bold',
  },
  subSectionContainer: {
    background: '#F6F3F2',
    borderRadius: '4px',
    padding: '1rem',
  },
  subSectionButton: {
    background: '#12574d',
    color: 'white',
    fontFamily: 'Messina Sans Bold',
    borderRadius: '8px',
    padding: '8px 16px',
    border: 'none',
    cursor: 'pointer',
  },
  stepsContainer: {
    display: 'flex',
    marginBottom: '2rem',
    alignItems: 'end',
    padding: '0 8px',
  },
  stepContainer: {
    marginRight: '1rem',
    backgroundColor: 'red',
  },
  stepBarActive: {
    width: '100%',
    background: '##FF5A26',
    border: 'none',
    height: '4px',
    borderRadius: '12px',
  },
  stepBarInactive: {
    width: '100%',
    border: 'none',
    height: '4px',
    background: '#EEE7E4',
    borderRadius: '12px',
  },
  radio: {
    color: '#12574D',
    '&$checked': {
      color: '#12574D',
    },
  },
  checked: {},
  radioItem: {
    borderRadius: '10px',
    marginTop: '5px',
    marginBottom: '5px',
    marginLeft: '0',
    marginRight: '0',
    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)',
    padding: '5px',
  },
  radioGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
  },
  h2: {
    fontFamily: 'RegisterLight',
    fontSize: '32px',
    margin: '0',
    marginBottom: '1rem',
    fontWeight: 'lighter',
  },
  confirmationContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    height: '100%',
    padding: '0.75rem',
    width: '100%',
    fontFamily: 'RegisterLight',
    fontSize: '2rem',
  },
}));

export const useNewStyles = makeStyles(() => ({
  lookingForContainer: {
    '& > p': {
      fontFamily: 'SohneBuch',
      fontSize: '16px',
    },
  },
  lookingForOptionsContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
    width: '100%',
  },
  lookingForOption: {
    fontFamily: 'SohneBuch',
    fontSize: '15px',
    display: 'flex',
    justifyContent: 'center',
    width: '50%',
    height: '60px',
    cursor: 'pointer',
    border: '1px solid #c0c0c0',
    padding: '16px',
    borderRadius: '4px',
    alignItems: 'center',
  },
  selectedStyles: {
    backgroundColor: '#421231',
    color: 'white',
  },
  unselectedStyles: {
    backgroundColor: 'white',
    color: 'black',
  },
  interestOptionsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '12px',
    marginBottom: '2rem',
  },
  interestSelection: {
    fontFamily: 'SohneBuch',
    fontSize: '15px',
    display: 'flex',
    justifyContent: 'center',
    height: '60px',
    cursor: 'pointer',
    border: '1px solid #c0c0c0',
    padding: '16px',
    borderRadius: '4px',
    alignItems: 'center',
    textAlign: 'center',
  },
}));

export const useOperatingProviderStyles = makeStyles(() => ({
  operatingProviderCheckbox: {
    display: 'flex',
    height: '100% !important',
    padding: '0rem 0.75rem 0.75rem !important',
    '& .MuiFormControlLabel-label': {
      color: '#000 !important',
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      fontWeight: 400,
    },
  },
  operatingProviderSubtext: {
    fontSize: '14px',
    opacity: 0.4,
    marginLeft: '32px',
    textWrap: 'pretty',
  },

  termsOfServiceCheckbox: {
    border: 'none',
    textWrap: 'auto',
    height: '100%',
  },
}));

export const usePasswordStyles = makeStyles(() => ({
  h2: {
    fontFamily: 'Messina Sans Bold',
    fontSize: '16px',
    marginTop: '0',
    marginBottom: '1rem',
  },
  listItem: {
    padding: '2px 0',
  },
  listItemIcon: {
    marginRight: '0',
    minWidth: '35px',
  },
  listItemText: {
    fontFamily: 'Messina Sans Regular',
    fontSize: '14px',
  },
}));

export const leftSideClasses = makeStyles(() => ({
  portraitContainer: {
    padding: '3rem',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    zIndex: 2,
  },
  portraitTitle: {
    fontFamily: 'RegisterLight',
  },
  portraitDescription: {
    fontFamily: 'SohneBuch',
    fontSize: '16px',
  },
  portraitFooter: {
    marginTop: '2rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  portraitAvatarSection: {
    display: 'flex',
  },
  portraitAvatar: {
    width: '56px',
    height: '56px',
  },
  portraitAvatarInfo: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '20px',
  },
  portraitAvatarTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  portraitAvatarLine: {
    display: 'inline-block',
    width: '10px',
    height: '1px',
    backgroundColor: 'black',
    marginRight: '4px',
    marginLeft: '4px',
  },
  portraitProvider: {
    fontFamily: 'SohneBuch',
    fontSize: '18px',
    color: '#421231',
  },
  portraitPoweredBy: {
    fontFamily: 'RegisterLightItalic',
    color: '#421231',
    fontSize: '16px',
    fontWeight: 'lighter',
    marginTop: '4px',
  },
  portraitStars: {
    width: '108px',
    height: '20px',
  },
  portraitBackgroundImage: {
    width: '48%',
    height: '72%',
    bottom: '30px',
    position: 'absolute',
    zIndex: 1,
  },
  title: {
    fontSize: '60px',
    lineHeight: '72px',
    fontWeight: 'lighter',
    '& > span': {
      color: '#FF5A26',
      fontStyle: 'italic',
    },
  },
}));
