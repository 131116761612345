import React, { useContext, useEffect, useState } from 'react';
import { TableContainer, Table, TableBody, withStyles, TableHead, TableRow, Box, Button } from '@material-ui/core';

import { OrderContext } from 'src/components/DashboardPractitioner/Tabs/OrderingTab';
import useProducts from 'src/hooks/queries/useProducts';
import { InventoryOrderFull, InventoryOrderItem, ProductOrderDetails } from 'src/interfaces/IInventoryOrder';
import { ClassNameMap } from 'src/types/Dom';
import {
  DEFAULT_SUPPLIERS,
  MARKET_PRICE_TOOLTIP,
  PINNED_SUPPLIERS,
  PRICE_TOOLTIP,
  YOU_SAVE_TOOLTIP,
} from 'src/constants/inventory.constants';
import { groupBy, sortBy, sumBy } from 'lodash';
import OrderLoading from 'src/components/DashboardPractitioner/Tabs/OrderingTab/OrderLoading';
import { useSpecialOffers } from 'src/hooks/queries/useSpecialOffers';
import { useDeleteInventoryOrderSpecialItem } from 'src/hooks/queries/useInventoryOrders';
import { ISpecialOffer } from 'src/services/SpecialOffers';
import { IOrderProduct } from 'src/services/Products';
import useSuppliers from 'src/hooks/queries/useSuppliers';
import { InventoryOrderCustomItem } from 'src/services/InventoryOrderCustomItems';
import ProductSelectionSupplier from './ProductSelectionSupplier';
import { TableCellHeader, useStyle } from '../inventoryOrder.styles';
import InventorySpecialOfferRow from '../InventorySpecialOfferRow';
import OrderingTooltip from '../OrderingTooltip';
import { PORTRAIT_ASCEND, PORTRAIT_FLEX, PORTRAIT_LAUNCH } from '../../../../../constants/general.constants';

type SpecialOfferProps = {
  orderData: InventoryOrderFull | undefined;
  specials: ISpecialOffer[];
  inventoryOrderId: number;
  edit?: boolean;
};

export const SpecialOfferContainer = ({ orderData, specials, inventoryOrderId, edit }: SpecialOfferProps) => {
  const classes = useStyle();
  const { mutateAsync: deleteSpecial } = useDeleteInventoryOrderSpecialItem();
  const orderSpecials = orderData?.specials || [];
  const roleName = orderData?.roleName;
  const findOrderSpecialId = (specialId: number) => {
    const orderSpecial = orderSpecials.find((spec) => spec.specialOfferId === specialId);
    return orderSpecial ? orderSpecial.id : null;
  };
  const getProductListFromOrderSpecial = (specialId: number): string | null => {
    const orderSpecial = orderSpecials.find((spec) => spec.specialOfferId === specialId);
    return orderSpecial ? orderSpecial.productList || null : null;
  };

  useEffect(() => {
    const removeDisabledSpecials = async () => {
      if (!orderData?.specials?.length) {
        return;
      }

      try {
        await Promise.all(
          orderData?.specials
            .filter((orderSpecial) => !orderSpecial.specialOffer.enabled)
            .map((orderSpecial) => deleteSpecial(orderSpecial.id))
        );
      } catch (error) {
        console.error('Error removing disabled specials from cart:', error);
      }
    };

    removeDisabledSpecials();
  }, [orderData?.specials]);

  const specialsColumn = [
    {
      className: '',
      content: 'Special Description',
    },
    {
      className: classes?.smaller,
      content: (
        <div className={classes.toolTipContainer}>
          Market price
          <OrderingTooltip title={MARKET_PRICE_TOOLTIP} />
        </div>
      ),
    },
    {
      className: classes?.smaller,
      content: (
        <div className={classes.toolTipContainer}>
          You Save
          <OrderingTooltip title={YOU_SAVE_TOOLTIP} />
        </div>
      ),
    },
    {
      className: classes?.centerCell,
      content: (
        <div className={classes.toolTipContainer}>
          You Pay
          <OrderingTooltip title={PRICE_TOOLTIP} />
        </div>
      ),
    },
    {
      className: classes?.centerCell,
      content: 'Offer Expires on',
    },
  ];

  return (
    <>
      <Box margin={2} flexDirection="row" width="100%">
        <Box className={classes.innerTableCellHeader}>
          Special Offers
          <Box fontSize={13} marginLeft={5} component="span" className={classes.chip}>
            Limited Time
          </Box>
        </Box>
      </Box>
      {inventoryOrderId &&
        specials.map((special) => (
          <TableContainer data-testid="special offer" className={classes.tableContainer}>
            <Box padding={2} fontSize={16}>
              {special.name} - {special.supplierName}
            </Box>
            <Table className={classes.innerTable}>
              <TableHead>
                <TableRow />
                <TableRow>
                  <>
                    {specialsColumn.map((cell) => (
                      <TableCellHeader
                        data-large="true"
                        className={cell.className}
                        style={{ textTransform: 'capitalize' }}
                      >
                        {cell.content}
                      </TableCellHeader>
                    ))}
                  </>
                </TableRow>
              </TableHead>

              <TableBody>
                <InventorySpecialOfferRow
                  key={special.id}
                  specialOfferId={special.id}
                  specialName={special.name}
                  inventoryOrderId={inventoryOrderId}
                  inventoryOrderSpecialId={findOrderSpecialId(special.id) || null}
                  inventoryOrderSpecialProductList={getProductListFromOrderSpecial(special.id) || ''}
                  specialDescription={special.specialDescription as string}
                  marketPriceLabel={getSpecialOfferPrice(special, roleName, 'market') as number}
                  enabled={special.enabled}
                  youSaveLabel={getSpecialOfferPrice(special, roleName, 'discount') as number}
                  youPayLabel={getSpecialOfferPrice(special, roleName, 'final') as number}
                  expirationDate={special.expirationDate as string}
                  edit={edit}
                />
              </TableBody>
            </Table>
          </TableContainer>
        ))}
    </>
  );
};

type PriceType = 'market' | 'discount' | 'final';

const getSpecialOfferPrice = (special: ISpecialOffer, roleName: any, priceType: PriceType): number | null => {
  const isAscend = roleName === PORTRAIT_ASCEND;
  const isLaunch = roleName === PORTRAIT_LAUNCH;
  const isFlex = roleName === PORTRAIT_FLEX;

  if (!isAscend && !isLaunch && !isFlex) {
    return null;
  }

  const productTypePriceMap: Record<string, Record<string, number>> = {
    market: {
      [PORTRAIT_ASCEND]: special.ascendMarketPrice,
      [PORTRAIT_LAUNCH]: special.launchMarketPrice,
      [PORTRAIT_FLEX]: special.flexMarketPrice,
    },
    discount: {
      [PORTRAIT_ASCEND]: special.ascendDiscount,
      [PORTRAIT_LAUNCH]: special.launchDiscount,
      [PORTRAIT_FLEX]: special.flexDiscount,
    },
    final: {
      [PORTRAIT_ASCEND]: special.ascendFinalPrice,
      [PORTRAIT_LAUNCH]: special.launchFinalPrice,
      [PORTRAIT_FLEX]: special.flexFinalPrice,
    },
  };

  switch (priceType) {
    case 'market':
      return productTypePriceMap.market[roleName];
    case 'discount':
      return productTypePriceMap.discount[roleName];
    case 'final':
      return productTypePriceMap.final[roleName];
    default:
      return null;
  }
};

export const ProductSelection = ({ classes }: ClassNameMap) => {
  const [showAllSuppliers, setShowAllSuppliers] = useState<boolean>(false);

  const { orderData, isLoading: isLoadingOrder } = useContext(OrderContext) || {};

  const roleName = orderData?.roleName;
  const { data: { data: products } = {}, isLoading: isLoadingProducts } = useProducts({ limit: 1000 });
  const { data: suppliers = [], isLoading: isLoadingSuppliers } = useSuppliers({ kind: DEFAULT_SUPPLIERS });
  const { data: specials, isLoading: isLoadingSpecial } = useSpecialOffers(true, roleName);

  const isLoading = isLoadingOrder || isLoadingProducts || isLoadingSpecial || isLoadingSuppliers;

  const productsBySupplier: { [supplierName: string]: IOrderProduct[] } = groupBy(products, 'supplierName');
  const customItemsBySupplier: { [supplierName: string]: InventoryOrderCustomItem[] } = groupBy(
    orderData?.customItems,
    'supplierName'
  );
  const filteredSuppliers = suppliers.filter((supplier) => supplier.name in productsBySupplier);

  const sortedSuppliers = sortBy(filteredSuppliers, ({ name }) => {
    const supplierIndex = PINNED_SUPPLIERS.indexOf(name);
    return supplierIndex < 0 ? name.toLowerCase().charCodeAt(0) + (PINNED_SUPPLIERS.length + 1) * 2 : supplierIndex;
  });

  const orderItems: ProductOrderDetails = Object.fromEntries(
    orderData?.services?.map((item: InventoryOrderItem) => [
      item.orderProductId,
      {
        quantity: item.quantity,
        notes: item.notes,
      },
    ]) ?? []
  );

  const inventoryOrderId = orderData?.id ? orderData.id : undefined;

  const getSupplierUsage = (supplierName: string) =>
    sumBy(productsBySupplier[supplierName], (item) => orderItems[item.id]?.quantity ?? 0);

  return isLoading ? (
    <OrderLoading />
  ) : (
    <>
      {inventoryOrderId && (
        <SpecialOfferContainer specials={specials} orderData={orderData} inventoryOrderId={inventoryOrderId} />
      )}
      {!inventoryOrderId
        ? null
        : !!products &&
          sortedSuppliers.map(
            (supplier) =>
              (showAllSuppliers ||
                PINNED_SUPPLIERS.indexOf(supplier.name) > -1 ||
                getSupplierUsage(supplier.name) > 0) && (
                <ProductSelectionSupplier
                  key={supplier.id}
                  classes={classes}
                  inventoryOrderId={inventoryOrderId}
                  supplier={supplier}
                  items={productsBySupplier[supplier.name]}
                  customItems={customItemsBySupplier[supplier.name]}
                  RowProps={{ inventoryOrderId, orderItems }}
                />
              )
          )}
      <Box className={classes?.viewMore}>
        {!!products && !showAllSuppliers && (
          <Button
            data-cy="view"
            onClick={() => setShowAllSuppliers(true)}
            variant="outlined"
            data-showmore
            className={classes?.showMoreSuppliers}
          >
            Show More Suppliers
          </Button>
        )}
      </Box>
      <Box height={100} />
    </>
  );
};

export default withStyles({
  root: {
    border: '1px solid #E1E1E1',
    backgroundColor: '#fff',
    borderRadius: '10px',
    marginBottom: '12px',
  },
  viewMore: {
    display: 'flex',
    width: '100%',
    marginBottom: '100px',
    minHeight: '121px',
  },
  showMoreSuppliers: {
    textTransform: 'none',
    fontSize: 14,
    minWidth: 132,
    height: 'auto',
    margin: 'auto',
    marginTop: '25px',
    marginBottom: '50px',
    width: '400px',
    color: '#222',
    borderColor: '#eee',
    backgroundColor: '#fff',
    '&:hover': {
      color: '#333',
      borderColor: '#dadada',
      backgroundColor: '#f8f8f8',
    },
  },
})(ProductSelection);
