import { Box, Button, Tooltip, Typography } from '@material-ui/core';
import React, { useState, useContext } from 'react';
import { formatCurrency } from 'src/utils/formatNumber';
import { CARD_FEE_PCT, SHIPPING_DISCLAIMER } from 'src/constants/inventory.constants';
import { ReactComponent as Savings } from 'src/assets/images/party-popper.svg';
import { useStyles } from './OrderConfirm.styles';
import { OrderContext } from '..';
import OrderConfirmDialog from './OrderConfirmDialog';
import OrderConfirmPanel from './OrderConfirmPanel';
import OrderAlert from '../OrderAlert';

const OrderSubmit: React.FC = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [buttonTooltipOpen, setButtonTooltipOpen] = useState<boolean>(false);
  const { orderData: { savingsTotal = 0, subtotal = 0, paymentSourceId = null, desiredDeliveryDate = null } = {} } =
    useContext(OrderContext) || {};

  const transactionFee = subtotal * (CARD_FEE_PCT / 100);
  const total = subtotal + transactionFee;
  const totalBeforeSavings = total + savingsTotal;

  const handleSubmit = async (): Promise<void> => {
    setDialogOpen(true);
  };

  const classes = useStyles();

  return (
    <OrderConfirmPanel title="Summary">
      <Box className={classes.orderPayment} data-testid="orderPayment">
        <Box className={classes.orderPaymentRow}>
          <Typography>Subtotal</Typography>
          <Typography>{formatCurrency(subtotal)}</Typography>
        </Box>
        <Box className={classes.orderPaymentRow}>
          <Typography>Delivery and Sales Tax</Typography>
          <Typography>Subject to additional charges</Typography>
        </Box>
        <Box>
          <OrderAlert text={SHIPPING_DISCLAIMER} />
        </Box>
        <Box borderTop="1px solid #eee" className={classes.orderPaymentRow}>
          <Typography>{`Service & Convenience Charge (${CARD_FEE_PCT}%)`}</Typography>
          <Typography>{formatCurrency(transactionFee)}</Typography>
        </Box>

        <Box className={classes.orderPaymentRow} data-size="lg">
          <Typography>Total</Typography>
          {savingsTotal > 0 ? (
            <Box>
              <Typography>
                <s>{formatCurrency(totalBeforeSavings)}</s>
              </Typography>
              <Typography variant="h6">{formatCurrency(total)}</Typography>
            </Box>
          ) : (
            <Typography>{formatCurrency(total)}</Typography>
          )}
        </Box>
        {savingsTotal > 0 && (
          <Box className={classes.orderSavings}>
            <Savings style={{ marginRight: '0.5rem', width: '20px', height: '20px' }} />
            You are saving <b>{formatCurrency(savingsTotal)}</b> by shopping with us!
          </Box>
        )}
        <Tooltip
          title="Please select a payment method and delivery date in order to proceed"
          open={(!paymentSourceId || !desiredDeliveryDate) && buttonTooltipOpen}
          placement="top"
          arrow
        >
          <Box
            width="100%"
            onMouseEnter={() => setButtonTooltipOpen(true)}
            onMouseLeave={() => setButtonTooltipOpen(false)}
          >
            <Button
              onClick={handleSubmit}
              variant="outlined"
              className={classes.savePayment}
              data-testid="orderPayment.savePayment"
              disabled={!paymentSourceId || !desiredDeliveryDate}
            >
              {' '}
              Place Order{' '}
            </Button>
          </Box>
        </Tooltip>
        <OrderConfirmDialog open={dialogOpen} closeDialog={() => setDialogOpen(false)} />
      </Box>
    </OrderConfirmPanel>
  );
};

export { OrderSubmit, OrderSubmit as default };
