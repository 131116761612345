import { BookingTypeEnum } from 'src/components/DashboardMedspaAdmin/Calendar/MedspaCalendar/EnumBookingType';
import { ExtendedAppointmentModel } from 'src/components/DashboardMedspaAdmin/Calendar/MedspaCalendar/grouping';

export const filterMedspaAppointments = ({
  dummyData,
  selectedGFEStatus = '',
  selectedStatus = '',
  selectedAppTypeId = '',
}: {
  dummyData: any;
  selectedGFEStatus: string;
  selectedStatus: string;
  selectedAppTypeId: string | number;
}) => {
  if (selectedGFEStatus === '' && selectedStatus === '' && selectedAppTypeId === '') {
    return dummyData;
  }

  const newFilteredData = dummyData.filter((appointment: ExtendedAppointmentModel) => {
    if (appointment.bookingType !== BookingTypeEnum.APPOINTMENT) {
      return false;
    }

    const isInStatusSelected = selectedStatus ? appointment.status === selectedStatus : true;
    return (
      isInStatusSelected &&
      (selectedGFEStatus === '' || (selectedGFEStatus !== '' && appointment.gfeStatus === selectedGFEStatus)) &&
      (selectedAppTypeId === '' || (selectedAppTypeId !== '' && appointment.appointmentTypeId === selectedAppTypeId))
    );
  });

  return newFilteredData;
};
