/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useMemo, useEffect } from 'react';
import { AppBar, Typography, Box, makeStyles } from '@material-ui/core';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { debounce } from 'lodash';

import { RootState } from 'src/rematch';
import { CustomerTab } from 'src/interfaces/ICustomer';
import { copyToClipboard } from 'src/utils/clipboard.utils';
import { SIGN_UP_ROUTES } from 'src/constants/routes.constants';
import TableCustomer from './TableCustomer';
import InputSearch from '../InputSearch';
import tabIdentifier from '../../../utils/tabIdentifier';
import TabItem from '../../common/TabItem';
import GreenTabs from '../../common/GreenTabs';
import GreenTab from '../../common/GreenTab';
import { PatientStateType } from '../../../types/Patient';
import { CustomerParams } from '../../../interfaces/CustomerParams';
import { useCustomPatients } from '../../../hooks/queries/useCustomPatients';
import compile from '../../../utils/toastMessagesCompiler';

import {
  CLEARED_STATE,
  INCOMPLETE_STATE,
  PENDING_RECLEAR_STATE,
  NOT_CLEARED_STATE,
  TO_RECLEAR_STATE,
  LEAD_STATE,
  PRACTITIONER_ROLE,
  ALL_STATE,
} from '../../../constants/general.constants';

import { hasAccessTo, hasFeatureEnabled } from '../../../utils/auth.utils';
import {
  PRACTITIONER_HOME_PATH,
  PRACTITIONER_NEW_PATIENT_PATH,
  PRACTITIONER_PATIENT_ALL_PATH,
  PRACTITIONER_PATIENT_CLEARED_PATH,
  PRACTITIONER_PATIENT_INCOMPLETE_PATH,
  PRACTITIONER_PATIENT_LEAD_PATH,
  PRACTITIONER_PATIENT_NOT_CLEARED_PATH,
  PRACTITIONER_PATIENT_PENDING_RECLEAR_PATH,
  PRACTITIONER_PATIENT_TO_RECLEAR_PATH,
} from '../../../routes/practitionerRoutes';
import SortDirection from '../../../types/SortDirection';
import ButtonAhref from '../../common/ButtonAhref';
import { CREATE_CUSTOMER, GFE_REQUEST } from '../../../constants/actions.constants';

const ERROR_MESSAGE = compile('generic.error_message', {
  action: 'fetching',
  element: 'patients',
});

const useStyles = makeStyles(() => ({
  boxClass: {
    padding: '15px 20px 10px 15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  typographyClass: {
    fontFamily: 'Messina Sans SemiBold',
    fontSize: '18px',
    fontWeight: 600,
    color: '#949494',
    paddingLeft: 5,
    paddingTop: 8,
  },
  tabGroupClass: {
    display: 'inline-block',
    width: '15%',
    fontSize: '12.5px !important',
  },
  tabClass: {
    paddingInline: '8px',
    marginLeft: '4px',
  },
  tabHighlighted: {
    backgroundColor: '#f16c00',
    paddingInline: '8px',
    borderRadius: '12px',
    marginLeft: '4px',
  },
}));

const EXCLUDE =
  // eslint-disable-next-line max-len
  '&exclude=photos,messages,service-requests,subscription,meta,has-requested-services,starred,visits,service-visits-photos';
const INCLUDE_PERMISSION = 'INCLUDE_PERMISSION';
const EXCLUDE_PERMISSION = 'EXCLUDE_PERMISSION';

const ROUTES = {
  [ALL_STATE]: PRACTITIONER_PATIENT_ALL_PATH,
  [CLEARED_STATE]: PRACTITIONER_PATIENT_CLEARED_PATH,
  [INCOMPLETE_STATE]: PRACTITIONER_PATIENT_INCOMPLETE_PATH,
  [NOT_CLEARED_STATE]: PRACTITIONER_PATIENT_NOT_CLEARED_PATH,
  [PENDING_RECLEAR_STATE]: PRACTITIONER_PATIENT_PENDING_RECLEAR_PATH,
  [TO_RECLEAR_STATE]: PRACTITIONER_PATIENT_TO_RECLEAR_PATH,
  [LEAD_STATE]: PRACTITIONER_PATIENT_LEAD_PATH,
};

const tabs: CustomerTab[] = [
  {
    title: 'All Patients',
    index: ALL_STATE,
    dataCy: 'requiresNoTitle',
    greenTabDataCy: 'practAllTab',
    countResultsKey: 'allCount',
    checkEnabled: true,
    permissionName: GFE_REQUEST,
    permissionCondition: EXCLUDE_PERMISSION,
  },
  {
    title: 'Cleared',
    index: CLEARED_STATE,
    dataCy: 'requiresClearedTitle',
    greenTabDataCy: 'practClearedTab',
    countResultsKey: 'clearedCount',
    checkEnabled: false,
    permissionName: GFE_REQUEST,
    permissionCondition: INCLUDE_PERMISSION,
  },
  {
    title: 'Requires update',
    index: PENDING_RECLEAR_STATE,
    dataCy: 'requiresUpdatesTitle',
    greenTabDataCy: 'practRequiresUpdateTab',
    countResultsKey: 'pendingReclearCount',
    checkEnabled: false,
    permissionName: GFE_REQUEST,
    permissionCondition: INCLUDE_PERMISSION,
  },
  {
    title: 'To reclear',
    index: TO_RECLEAR_STATE,
    dataCy: 'requiresToReclearTitle',
    greenTabDataCy: 'practToReclearTab',
    countResultsKey: 'toReclearCount',
    checkEnabled: false,
    permissionName: GFE_REQUEST,
    permissionCondition: INCLUDE_PERMISSION,
  },
  {
    title: 'Not cleared',
    index: NOT_CLEARED_STATE,
    dataCy: 'requiresNotClearedTitle',
    greenTabDataCy: 'practNotClearedTab',
    countResultsKey: 'toClearCount',
    checkEnabled: false,
    permissionName: GFE_REQUEST,
    permissionCondition: INCLUDE_PERMISSION,
  },
  {
    title: 'Incomplete',
    index: INCOMPLETE_STATE,
    dataCy: 'requiresIncompleteTitle',
    greenTabDataCy: 'practIncompleteTab',
    countResultsKey: 'incompleteCount',
    checkEnabled: false,
    permissionName: GFE_REQUEST,
    permissionCondition: INCLUDE_PERMISSION,
  },
];

const initialParamsWithGFE: CustomerParams = {
  dashboard: 'practitioner',
  group: CLEARED_STATE,
  view: 'allPatients',
  filter: 'all',
  page: 1,
  search: '',
  exclude: EXCLUDE,
  sortBy: 'created_at DESC',
};

const initialParamsWithoutGFE: CustomerParams = {
  dashboard: 'practitioner',
  group: ALL_STATE,
  view: 'allPatients',
  filter: 'all',
  page: 1,
  search: '',
  exclude: EXCLUDE,
  sortBy: 'created_at DESC',
};

const CustomersTab = ({ scrollerMain }: any) => {
  const {
    permissions,
    userType,
    user: { slug, activeForScheduling },
  } = useSelector(({ auth }: any) => auth);

  const { state } = useParams<{ state: PatientStateType }>();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const { featureList } = useSelector(({ auth }: RootState) => auth);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [tabManualChange, setTabManualChange] = useState(false);
  const [highlightedTabs, setHighlightedTabs] = useState<Set<string>>(new Set());
  const [paramsQuery, setParamsQuery] = useState<CustomerParams>(
    hasAccessTo(GFE_REQUEST, permissions) ? initialParamsWithGFE : initialParamsWithoutGFE
  );
  const { results, countResults, isLoading, isFetching, isError, fetchNextPage, hasNextPage } = useCustomPatients(
    'practitioner',
    paramsQuery
  );

  useEffect(() => {
    if (permissions?.length && location.pathname === PRACTITIONER_HOME_PATH) {
      history.push(
        hasAccessTo(GFE_REQUEST, permissions) ? PRACTITIONER_PATIENT_CLEARED_PATH : PRACTITIONER_PATIENT_ALL_PATH
      );
      setParamsQuery({
        ...paramsQuery,
        group: hasAccessTo(GFE_REQUEST, permissions) ? CLEARED_STATE : ALL_STATE,
      });
    }
  }, [location.pathname, permissions]);

  useEffect(() => {
    if (searchQuery && !isSearching) {
      const countTabs = tabs
        .map((tab) => ({
          index: tab.index,
          count: tab.index !== 'all' && countResults?.[tab.countResultsKey],
        }))
        .filter((tab) => Number(tab.count) > 0);

      if (countTabs.length > 0) {
        const activeTabs = new Set(countTabs.map((tab) => tab.index));
        setHighlightedTabs(activeTabs);

        const mostRelevantTab = countTabs.reduce((max, tab) => (Number(tab.count) > Number(max.count) ? tab : max), {
          index: '',
          count: 0,
        });

        if (mostRelevantTab.index && mostRelevantTab.index !== paramsQuery.group && !tabManualChange) {
          handleChangeTab(null, mostRelevantTab.index as PatientStateType);
        }
      }
    } else {
      setHighlightedTabs(new Set());
    }

    setTabManualChange(true);
  }, [countResults, paramsQuery.group, tabs, isSearching]);

  const handleChangeTab = (event: any, newTab: PatientStateType) => {
    history.push(ROUTES[newTab]);
    setParamsQuery({
      ...paramsQuery,
      group: newTab,
    });
  };

  const doSearch = useMemo(
    () =>
      debounce((query: string) => {
        setParamsQuery({
          ...paramsQuery,
          search: query,
        });
        setIsSearching(false);
        setTabManualChange(false);
      }, 2000),
    [paramsQuery.group]
  );

  const onInputChange = (event: any) => {
    setSearchQuery(event.target.value);
    doSearch(event.target.value);
  };

  const doSortBy = (column: string, direction: string) => {
    setParamsQuery((prev) => ({
      ...prev,
      sortBy: `${column} ${direction}`,
    }));
  };

  const showRegistrationLink = userType === PRACTITIONER_ROLE && !!slug && activeForScheduling;

  return (
    <>
      <AppBar position="static">
        <GreenTabs
          value={paramsQuery.group}
          onChange={handleChangeTab}
          aria-label="simple tabs example"
          style={{ fontSize: '13px' }}
          scrollButtons="auto"
        >
          {tabs.map((tab) => {
            const titleCount = (
              <>
                {tab.title}
                {countResults?.[tab.countResultsKey] !== undefined && (
                  <>
                    <span className={highlightedTabs.has(tab.index) ? classes.tabHighlighted : classes.tabClass}>
                      {countResults[tab.countResultsKey]}
                    </span>
                  </>
                )}
              </>
            );
            const greenTab = (
              <GreenTab
                value={tab.index}
                label={titleCount}
                {...tabIdentifier(CLEARED_STATE)}
                className={classes.tabGroupClass}
                data-cy={tab.greenTabDataCy}
              />
            );
            if (tab.checkEnabled) {
              if (tab.featureName && tab.permissionName && hasFeatureEnabled(tab.featureName, featureList)) {
                if (
                  tab.permissionName &&
                  tab.permissionCondition === INCLUDE_PERMISSION &&
                  hasAccessTo(tab.permissionName, permissions)
                ) {
                  return greenTab;
                }
                if (
                  tab.permissionName &&
                  tab.permissionCondition === EXCLUDE_PERMISSION &&
                  !hasAccessTo(tab.permissionName, permissions)
                ) {
                  return greenTab;
                }
              }
              if (tab.featureName && hasFeatureEnabled(tab.featureName, featureList)) {
                return greenTab;
              }
              if (
                tab.permissionName &&
                tab.permissionCondition === INCLUDE_PERMISSION &&
                hasAccessTo(tab.permissionName, permissions)
              ) {
                return greenTab;
              }
              if (
                tab.permissionName &&
                tab.permissionCondition === EXCLUDE_PERMISSION &&
                !hasAccessTo(tab.permissionName, permissions)
              ) {
                return greenTab;
              }
              return null;
            }
            return greenTab;
          })}
        </GreenTabs>
      </AppBar>
      <Box className={classes.boxClass} style={{ justifyContent: 'end', float: 'right' }}>
        <InputSearch onChangeValue={onInputChange} value={searchQuery} isLoading={isLoading} />
        {showRegistrationLink && (
          <Box style={{ marginLeft: '18px' }}>
            <ButtonAhref
              buttonStyle="big"
              text="Registration Link"
              onClick={() => copyToClipboard(`${SIGN_UP_ROUTES.registration}/${slug}`)}
            />
          </Box>
        )}
        <Box style={{ marginLeft: '18px' }}>
          {userType === PRACTITIONER_ROLE && hasAccessTo(CREATE_CUSTOMER, permissions) && (
            <ButtonAhref buttonStyle="big" text="NEW PATIENT" href={PRACTITIONER_NEW_PATIENT_PATH} />
          )}
        </Box>
      </Box>

      {tabs.map((tab) => {
        const sortBy = paramsQuery.sortBy!.split(' ');
        const tabItem = (
          <TabItem value={paramsQuery.group} index={tab.index}>
            <Box style={{ backgroundColor: 'white' }}>
              <Box className={classes.boxClass}>
                <Typography className={classes.typographyClass} data-cy={tab.dataCy}>
                  {tab.title}
                </Typography>
              </Box>
              <TableCustomer
                isLoading={isLoading}
                isFetching={isFetching}
                isError={isError}
                errorMessage={ERROR_MESSAGE}
                data={results}
                sortBy={sortBy[0]}
                sortDirection={sortBy[1] as SortDirection}
                onChangeSortBy={doSortBy}
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
                scrollerMain={scrollerMain}
                state={state}
              />
            </Box>
          </TabItem>
        );

        if (tab.checkEnabled) {
          if (
            tab.permissionName &&
            tab.permissionCondition === INCLUDE_PERMISSION &&
            hasAccessTo(tab.permissionName, permissions)
          ) {
            return tabItem;
          }
          if (
            tab.permissionName &&
            tab.permissionCondition === EXCLUDE_PERMISSION &&
            !hasAccessTo(tab.permissionName, permissions)
          ) {
            return tabItem;
          }
          return null;
        }
        return tabItem;
      })}
    </>
  );
};

export default CustomersTab;
