import React from 'react';
import { makeStyles } from '@material-ui/core';
import RangeSlider from './RangeSlider';

const useStyles = makeStyles(() => ({
  button: {
    color: '#ffffffad',
    width: '3.5rem',
    height: '3.5rem',
    borderRadius: '3.5rem',
    border: '0px',
    backgroundColor: '#0000008f',
    padding: '1rem',
    cursor: 'pointer',
    boxShadow: '0px 2px 8px 2px #00000054',
    outline: 'none',
    position: 'absolute',
    touchAction: 'manipulation',
  },
  buttonActive: {
    color: '#00efae',
  },
}));

type Props = {
  onToolChange: Function;
  fullscreen?: Boolean;
  bottomButtons: React.ReactNode;
  showOnly?: boolean;
};

type toolDefs = {
  tool: string;
  value?: string | number;
};

const Toolbar = ({ onToolChange, fullscreen, bottomButtons, showOnly }: Props) => {
  const classes = useStyles();
  const [mode, setMode] = React.useState('pen');

  const handleToolChange = ({ tool, value }: toolDefs) => {
    if (onToolChange) {
      onToolChange(tool, value);
    }
  };

  return (
    <>
      {!showOnly && (
        <button
          style={{
            top: 20,
            left: fullscreen ? 80 : 20,
          }}
          className={`${classes.button} ${mode === 'pen' ? classes.buttonActive : ''}`}
          type="button"
          disabled={mode === 'pen'}
          onClick={() => {
            setMode('pen');
            handleToolChange({ tool: 'drawing_mode', value: 'pen' });
          }}
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="pen"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              // eslint-disable-next-line max-len
              d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z"
            />
          </svg>
        </button>
      )}

      {!showOnly && (
        <button
          style={{
            position: 'absolute',
            top: 100,
            left: fullscreen ? 80 : 20,
          }}
          className={`${classes.button} ${mode === 'eraser' ? classes.buttonActive : ''}`}
          type="button"
          disabled={mode === 'eraser'}
          onClick={() => {
            setMode('eraser');
            handleToolChange({ tool: 'drawing_mode', value: 'eraser' });
          }}
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="eraser"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              // eslint-disable-next-line max-len
              d="M497.941 273.941c18.745-18.745 18.745-49.137 0-67.882l-160-160c-18.745-18.745-49.136-18.746-67.883 0l-256 256c-18.745 18.745-18.745 49.137 0 67.882l96 96A48.004 48.004 0 0 0 144 480h356c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12H355.883l142.058-142.059zm-302.627-62.627l137.373 137.373L265.373 416H150.628l-80-80 124.686-124.686z"
            />
          </svg>
        </button>
      )}
      {!showOnly && (
        <div
          style={{
            position: 'absolute',
            bottom: 40,
            left: fullscreen ? 100 : 40,
          }}
        >
          <RangeSlider
            onChange={(value: string | number) =>
              handleToolChange({
                tool: 'drawing_stroke_width',
                value,
              })
            }
          />
        </div>
      )}
      {!showOnly && fullscreen && <div style={{ position: 'absolute', bottom: '27px' }}>{bottomButtons}</div>}
      {!showOnly && (
        <button
          style={{
            position: 'absolute',
            bottom: 20,
            right: fullscreen ? 160 : 100,
          }}
          className={classes.button}
          type="button"
          onClick={() => handleToolChange({ tool: 'undo' })}
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              // eslint-disable-next-line max-len
              d="M212.333 224.333H12c-6.627 0-12-5.373-12-12V12C0 5.373 5.373 0 12 0h48c6.627 0 12 5.373 12 12v78.112C117.773 39.279 184.26 7.47 258.175 8.007c136.906.994 246.448 111.623 246.157 248.532C504.041 393.258 393.12 504 256.333 504c-64.089 0-122.496-24.313-166.51-64.215-5.099-4.622-5.334-12.554-.467-17.42l33.967-33.967c4.474-4.474 11.662-4.717 16.401-.525C170.76 415.336 211.58 432 256.333 432c97.268 0 176-78.716 176-176 0-97.267-78.716-176-176-176-58.496 0-110.28 28.476-142.274 72.333h98.274c6.627 0 12 5.373 12 12v48c0 6.627-5.373 12-12 12z"
            />
          </svg>
        </button>
      )}
      {!showOnly && (
        <button
          style={{
            bottom: 20,
            right: fullscreen ? 80 : 20,
          }}
          className={classes.button}
          type="button"
          onClick={() => handleToolChange({ tool: 'redo' })}
        >
          <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
              fill="currentColor"
              // eslint-disable-next-line max-len
              d="M500.33 0h-47.41a12 12 0 0 0-12 12.57l4 82.76A247.42 247.42 0 0 0 256 8C119.34 8 7.9 119.53 8 256.19 8.1 393.07 119.1 504 256 504a247.1 247.1 0 0 0 166.18-63.91 12 12 0 0 0 .48-17.43l-34-34a12 12 0 0 0-16.38-.55A176 176 0 1 1 402.1 157.8l-101.53-4.87a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12h200.33a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12z"
            />
          </svg>
        </button>
      )}

      {!fullscreen ? (
        <button
          style={{
            top: 20,
            right: fullscreen ? 80 : 20,
          }}
          className={classes.button}
          type="button"
          onClick={() => handleToolChange({ tool: 'full_screen' })}
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="expand"
            className="svg-inline--fa fa-expand fa-w-14"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              // eslint-disable-next-line max-len
              d="M0 180V56c0-13.3 10.7-24 24-24h124c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H64v84c0 6.6-5.4 12-12 12H12c-6.6 0-12-5.4-12-12zM288 44v40c0 6.6 5.4 12 12 12h84v84c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12V56c0-13.3-10.7-24-24-24H300c-6.6 0-12 5.4-12 12zm148 276h-40c-6.6 0-12 5.4-12 12v84h-84c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h124c13.3 0 24-10.7 24-24V332c0-6.6-5.4-12-12-12zM160 468v-40c0-6.6-5.4-12-12-12H64v-84c0-6.6-5.4-12-12-12H12c-6.6 0-12 5.4-12 12v124c0 13.3 10.7 24 24 24h124c6.6 0 12-5.4 12-12z"
            />
          </svg>
        </button>
      ) : (
        <button
          style={{
            top: 20,
            right: fullscreen ? 80 : 20,
          }}
          className={classes.button}
          type="button"
          onClick={() => handleToolChange({ tool: 'normal_screen' })}
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="compress"
            className="svg-inline--fa fa-compress fa-w-14"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              // eslint-disable-next-line max-len
              d="M436 192H312c-13.3 0-24-10.7-24-24V44c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v84h84c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-276-24V44c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v84H12c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h124c13.3 0 24-10.7 24-24zm0 300V344c0-13.3-10.7-24-24-24H12c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h84v84c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-84h84c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12H312c-13.3 0-24 10.7-24 24v124c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12z"
            />
          </svg>
        </button>
      )}
    </>
  );
};

Toolbar.defaultProps = {
  fullscreen: false,
};

export default Toolbar;
