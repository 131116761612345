import { Skeleton } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import { CardContent, Checkbox, CircularProgress, FormControlLabel } from '@material-ui/core';
import { Card, SmallTitle } from '../../common/card';
import { ActiveStepWrapper } from '../ActiveStepWrapper';
import { RootState, dispatch } from '../../../rematch';
import { Button } from '../../common/Button';
import { useStyles } from './PhotosConsent.styles';
import { PhotosConsentModal } from './PhotosConsentModal';
import { PhotoConsentAgreementModal } from './PhotoConsentAgreementModal';
import { useCreateCustomerPhotoConsent } from '../../../hooks/mutations/useCreateCustomerPhotoConsent';
import compile from '../../../utils/toastMessagesCompiler';

export const PhotosConsent = ({ fromHistory }: { fromHistory?: boolean }) => {
  const newServiceVisit = useSelector(({ newServiceVisit: serviceVisit }: any) => serviceVisit);
  const { opened } = newServiceVisit.serviceVisit;
  const {
    afterPhotos: { deidentifiedConsent, signature, generalPhotoConsent },
  } = newServiceVisit;
  const { isLoadingGetServiceVisitFromPatient } = useSelector((store: any) => store.newServiceVisit);
  const { id, firstName, lastName } = useSelector(({ patient }: any) => patient);
  const classes = useStyles();
  const [consentModalOpen, setConsentModalOpen] = useState<boolean>(false);
  const [openAgreementModal, setOpenAgreementModal] = useState(false);
  const createCustomerPhotoConsentMutation = useCreateCustomerPhotoConsent();
  const { isLoading: isLoadingCreateCustomerPhotoConsent } = createCustomerPhotoConsentMutation;

  const {
    contactInfo: { practitioner },
  } = useSelector(({ patient }: RootState) => patient);

  const selectStep = () => {
    dispatch({ type: 'newServiceVisit/updateCurrentStep', payload: 8 });
  };

  const handleOnSign = async (signatureImage: string, patientName: string) => {
    try {
      const data = await createCustomerPhotoConsentMutation.mutateAsync({
        signatureImage,
        patientName,
        serviceVisitId: newServiceVisit.serviceVisit.id,
        patientId: id,
      });

      dispatch({
        type: 'newServiceVisit/updateAfterPhotosConsent',
        payload: {
          signature: data.signatureImageUrl,
          serviceVisitId: newServiceVisit.serviceVisit.id,
          generalPhotoConsent: true,
          createdAt: data.createdAt,
        },
      });

      dispatch({
        type: 'newServiceVisit/updateCustomerPhotoConsent',
        payload: { customerPhotoConsent: data.photoConsentPdfUrl },
      });
    } catch (error) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'creating',
            element: 'customer photo consent',
          }),
          type: 'error',
        },
      });
    }
  };

  const onChangeConsent = (consentType: string) => {
    dispatch({
      type: 'newServiceVisit/updateAfterPhotosConsent',
      payload: {
        [consentType]: !newServiceVisit.afterPhotos[consentType],
      },
    });
  };

  const openSignedPhotoConsent = () => {
    window.open(newServiceVisit.customerPhotoConsent, '_blank');
  };

  return (
    <ActiveStepWrapper step={8} onClick={selectStep} fromHistory={fromHistory}>
      {/* eslint-disable-next-line no-nested-ternary */}
      {isLoadingGetServiceVisitFromPatient ? (
        <Skeleton height={150} />
      ) : signature ? (
        <Card style={{ marginBottom: '0', minHeight: '148px' }}>
          <CardContent style={{ padding: '11px 20px 20px 20px' }}>
            <SmallTitle title="Photos Consent (optional)" style={{ marginTop: '0', marginBottom: '15px' }} />
            <div>
              <FormControlLabel
                label="My photos can be used for marketing, training and education"
                control={
                  <Checkbox
                    disabled={!opened}
                    color="primary"
                    checked={generalPhotoConsent ?? false}
                    onChange={() => onChangeConsent('generalPhotoConsent')}
                  />
                }
              />
            </div>
            <div>
              <FormControlLabel
                label="Please use de-identified versions of my image only"
                control={
                  <Checkbox
                    disabled={!opened}
                    color="primary"
                    checked={deidentifiedConsent ?? false}
                    onChange={() => onChangeConsent('deidentifiedConsent')}
                  />
                }
              />
            </div>
            {isLoadingCreateCustomerPhotoConsent ? (
              <CircularProgress className={classes.circularProgress} size={40} />
            ) : (
              <div>
                <div className={classes.editSignatureContainer}>
                  <img className={classes.signatureImage} src={signature} alt="Patient's Signature" />
                  {opened && (
                    <Button
                      title="Edit"
                      className={classes.editSignatureButton}
                      onClick={() => setConsentModalOpen(true)}
                    />
                  )}
                </div>
                <div>
                  {newServiceVisit.customerPhotoConsent && (
                    <Button
                      className={classes.button}
                      title="VIEW PHOTO CONSENT AGREEMENT"
                      onClick={openSignedPhotoConsent}
                    />
                  )}
                </div>
              </div>
            )}
          </CardContent>
        </Card>
      ) : (
        <Card style={{ marginBottom: '0', minHeight: '148px' }}>
          <CardContent style={{ padding: '11px 20px 20px 20px' }}>
            <SmallTitle title="Photos Consent (optional)" style={{ marginTop: '0', marginBottom: '15px' }} />
            <Button
              className={classes.button}
              title="SIGN CONSENT"
              disabled={!opened}
              onClick={() => setConsentModalOpen(true)}
            />
          </CardContent>
        </Card>
      )}
      <PhotosConsentModal
        isOpen={consentModalOpen}
        onClose={() => setConsentModalOpen(false)}
        onSign={handleOnSign}
        signature={signature}
        patientNameProp={`${firstName} ${lastName}`}
        deidentifiedConsent={deidentifiedConsent}
        onChangeConsent={onChangeConsent}
        handleOpenAgreementModal={() => setOpenAgreementModal(true)}
      />
      <PhotoConsentAgreementModal
        open={openAgreementModal}
        practitioner={practitioner}
        onClose={() => setOpenAgreementModal(false)}
      />
    </ActiveStepWrapper>
  );
};
