import React, { useState, useEffect, useMemo } from 'react';
import {
  Accordion,
  AccordionDetails,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Input,
  InputLabel,
  Paper,
  Slide,
  Switch,
  Typography,
} from '@material-ui/core';
import IconCross from 'src/components/common/IconCross';
import { ExpandIconParams } from 'src/interfaces/IClientLead';
import {
  useCreateAdCampaign,
  useDestroyAdCampaign,
  useImportAdCampaigns,
  useUpdateAdCampaign,
  useAdCampaignsLive as useAdCampaigns,
} from 'src/hooks/queries/useAdCampaigns';
import { Settings as IconSettings } from '@material-ui/icons';
import { IAdCampaign, ICreateCampaignProps, ICampaignRowProps, IEditCampaignProps } from 'src/interfaces/IAdCampaign';
import { max, round } from 'lodash';
import { formatCurrency } from 'src/utils/formatNumber';
import { MultipleSkeleton } from 'src/components/common/LoadingSkeleton';
import { useMedspaPractitioners } from 'src/hooks/queries/usePractitioners';
import { IPractitioner } from 'src/interfaces/IPractitioner';
import { SelectList } from 'src/components/common/SelectList';
import { PROVIDER_FILTER_TITLE } from 'src/constants/clientLead.constants';
import { Skeleton } from '@material-ui/lab';
import Page from 'src/components/common/Page';
import { useStyles } from './reports.styles';
import { ProviderList } from './TableLeads';
import { IconLeftAccordionSummary } from '../OrderingTab/inventoryOrder.styles';

const ExpandIcon: React.FC<ExpandIconParams> = ({ open, setOpen }): React.ReactElement => (
  <IconButton size="small" onClick={() => setOpen(!open)}>
    <IconCross open={open} />
  </IconButton>
);

const CreateCampaign: React.FC<ICreateCampaignProps & IEditCampaignProps> = ({
  id,
  userId,
  metaKey,
  name,
  active,
  open,
  toggleView,
}): React.ReactElement => {
  const [campaignKey, setCampaignKey] = useState<string | undefined>(metaKey ?? '');
  const [practitionerId, setPractitionerId] = useState<string | undefined>(userId?.toString() ?? '');
  const [campaignName, setCampaignName] = useState<string | undefined>(name ?? '');
  const [isActive, setIsActive] = useState<boolean>(active ?? false);

  const { data: providerData, isLoading } = useMedspaPractitioners({}, true, () => 'v3/practitioners');

  const { mutateAsync: createCampaign } = useCreateAdCampaign();
  const { mutateAsync: updateCampaign } = useUpdateAdCampaign(id);
  const { mutateAsync: destroyCampaign } = useDestroyAdCampaign(id);

  const editMode = !!userId;
  const requiresSave =
    editMode &&
    practitionerId === `${userId}` &&
    metaKey === campaignKey &&
    campaignName === name &&
    isActive === active;
  const inputValid = campaignName && practitionerId && campaignKey && campaignKey.indexOf(' ') < 0;

  const saveCampaign = async (): Promise<void> => {
    if (inputValid) {
      const mutateParams = {
        name: campaignName,
        practitionerId: +practitionerId,
        key: campaignKey,
        active: isActive,
      };
      try {
        if (editMode) {
          await updateCampaign(mutateParams);
        } else {
          await createCampaign(mutateParams);
        }
      } finally {
        toggleView();
      }
    }
  };

  const deleteCampaign = async (): Promise<void> => {
    try {
      await destroyCampaign();
    } finally {
      toggleView();
    }
  };

  const classes = useStyles();

  const providerList = !providerData
    ? {}
    : Object.fromEntries(
      providerData.map((provider: IPractitioner) => [
        provider.id.toString(),
        `${provider.firstName} ${provider.lastName}`,
      ])
    );

  useEffect(() => {
    if (!practitionerId && providerData) {
      setPractitionerId(Object.keys(providerList)[0]);
    }
  }, [practitionerId, providerList]);

  useEffect(() => {
    setCampaignKey(metaKey || '');
    setPractitionerId(userId?.toString() || '');
    setCampaignName(name || '');
    setIsActive(active ?? false);
  }, [metaKey, userId, name, active]);

  return (
    <Slide direction="left" enter in={open} exit timeout={{ enter: 500, exit: 100 }}>
      <Paper className={classes.editInsert} variant="elevation" elevation={2} style={{ top: 0 }}>
        <Typography variant="h4" className={classes.newCampaignTitle}>
          {editMode ? 'Edit Campaign' : 'New Campaign'}
        </Typography>
        <Box padding={2}>
          <Box padding={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={isActive}
                  onChange={() => setIsActive(!isActive)}
                  name="active"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              }
              label="Active"
            />
          </Box>
          <Box padding={2}>
            {isLoading ? (
              <Skeleton className={classes.channelSelectLarge} />
            ) : (
              <SelectList
                title={PROVIDER_FILTER_TITLE}
                listItems={providerList}
                selected={`${practitionerId}`}
                setSelected={(newVal: string | null) => {
                  setPractitionerId(newVal ?? undefined);
                }}
                className={classes.channelSelectLarge}
                loadSelected
              />
            )}
          </Box>
          <Box padding={2}>
            <InputLabel shrink htmlFor="name">
              Campaign Name
            </InputLabel>
            <Input
              value={campaignName}
              onChange={(event: React.ChangeEvent) => setCampaignName((event.currentTarget as HTMLInputElement)?.value)}
              className={classes.campaignInput}
              name="name"
            />
          </Box>
          <Box padding={2}>
            <InputLabel shrink htmlFor="name">
              Campaign Key
            </InputLabel>
            <Input
              value={campaignKey}
              onChange={(event: React.ChangeEvent) =>
                setCampaignKey((event.currentTarget as HTMLInputElement)?.value?.split(' ')?.join('_'))
              }
              className={classes.campaignInput}
              name="name"
            />
          </Box>
          <Button
            disabled={!inputValid || requiresSave}
            variant="outlined"
            className={classes.saveCampaignButton}
            onClick={saveCampaign}
          >
            {' '}
            Save
          </Button>
          <Button variant="outlined" className={classes.saveCampaignButton} onClick={toggleView}>
            Cancel
          </Button>
          {editMode && (
            <div>
              <Button variant="outlined" className={classes.destroyCampaignButton} onClick={deleteCampaign}>
                Delete
              </Button>
            </div>
          )}
        </Box>
      </Paper>
    </Slide>
  );
};

const RowItemCampaigns: React.FC<ICampaignRowProps> = ({ row, startExpanded, setEditParams }): React.ReactElement => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const classes = useStyles();

  const cpc = !row.clicks ? 0 : round(row.spend / row.clicks, 2);

  const conversionValue = max([row.conversions, row.customerCount]) || 0;

  const cpv = !conversionValue ? 0 : round(row.spend / conversionValue, 2);

  const editCampaign = (): void =>
    setEditParams({
      userId: row.practitionerId,
      metaKey: row.key,
      name: row.name,
      active: row.active,
      id: row.id,
    });

  const activeChip = row.failedToSync ? (
    <Chip color="primary" variant="outlined" label="Error" style={{ width: 75 }} />
  ) : (
    <Chip color="secondary" variant="outlined" label="Synced" style={{ width: 75 }} />
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    startExpanded && setExpanded(true);
  }, []);

  return (
    <Accordion className={classes.accordion} expanded={expanded}>
      <IconLeftAccordionSummary expandIcon={<ExpandIcon open={expanded} setOpen={setExpanded} />}>
        <Box className={classes.accordionRow}>
          <Box>{row.name.split('_').join(' ')}</Box>
          <Box>
            <Chip className={classes.longChip} color="primary" variant="outlined" label={row.practitioner} />
          </Box>
          <Box>
            <Chip color="primary" variant="outlined" label={row.marketingChannel} />
          </Box>
          <Box>
            {!row.active ? (
              <Chip color="default" variant="outlined" label="Inactive" style={{ width: 75 }} />
            ) : (
              activeChip
            )}
          </Box>
          <Box>
            <IconButton onClick={editCampaign}>
              <IconSettings />
            </IconButton>
          </Box>
        </Box>
      </IconLeftAccordionSummary>
      <AccordionDetails>
        <Box className={classes.campaignDetails}>
          <Box>
            <Box>
              <Typography>Lifetime Spend:</Typography>
              <Typography>{formatCurrency(row.spend ?? 0)}</Typography>
            </Box>
            <Box>
              <Typography>Impressions:</Typography>
              <Typography>{row.impressions ?? 0}</Typography>
            </Box>
          </Box>
          {!!row.clicks && (
            <Box>
              <Box>
                <Typography>Clicks:</Typography>
                <Typography>{row.clicks ?? 0}</Typography>
              </Box>
              <Box>
                <Typography>Cost per Click:</Typography>
                <Typography>{formatCurrency(cpc)}</Typography>
              </Box>
            </Box>
          )}
          <Box>
            <Box>
              <Typography>Total Leads:</Typography>
              <Typography>{row.clientLeadCount ?? 0}</Typography>
            </Box>
            <Box>
              <Typography>Cost per Conversion:</Typography>
              <Typography>{formatCurrency(cpv)}</Typography>
            </Box>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

const TableCampaigns: React.FC<{
  setEditParams: (params: IEditCampaignProps) => void;
}> = ({ setEditParams }): React.ReactElement => {
  const [practitionerId, setPractitionerId] = useState<string | undefined>();

  const { data: adCampaigns, isLoading } = useAdCampaigns({
    practitionerId: !practitionerId ? undefined : +practitionerId,
  });

  const classes = useStyles();

  return (
    <>
      <Box padding={2}>
        <Box padding={2}>
          <ProviderList
            selected={`${practitionerId}`}
            className={classes.channelSelect}
            setSelected={(newVal: string | null) => {
              setPractitionerId(newVal || undefined);
            }}
          />
        </Box>
      </Box>
      <Box className={classes.noShadowShort}>
        {isLoading ? (
          <MultipleSkeleton />
        ) : (
          adCampaigns?.map(
            (row: IAdCampaign): React.ReactElement => (
              <RowItemCampaigns
                key={`${row.id}${row.practitionerId}${row.name}`}
                row={row}
                setEditParams={setEditParams}
              />
            )
          )
        )}
      </Box>
    </>
  );
};

const LeadCampaigns: React.FC = (): React.ReactElement => {
  const classes = useStyles();

  const [editCampaign, setEditCampaign] = useState<boolean>(false);
  const [editParams, setEditParams] = useState<IEditCampaignProps>({});
  const [isImporting, setIsImporting] = useState<boolean>(false);

  const { mutateAsync: importAdCampaigns } = useImportAdCampaigns();

  const toggleView = (): void => {
    setEditCampaign(!editCampaign);
    setEditParams({});
  };

  const openEditPanel = (params: IEditCampaignProps): void => {
    setEditParams(params);
    setEditCampaign(true);
  };

  const importCampaigns = async (): Promise<void> => {
    setIsImporting(true);
    try {
      await importAdCampaigns();
    } finally {
      setTimeout(() => setIsImporting(false), 2500);
    }
  };

  const editPanel = useMemo(
    () => <CreateCampaign open={editCampaign} toggleView={toggleView} {...editParams} />,
    [editCampaign, editParams]
  );

  return (
    <Page title="Ad Campaigns">
      <Box className={classes.reportsMain}>
        <Box className={classes.reportsHeader} style={{ backgroundColor: 'transparent' }}>
          <Typography className={classes.adCampaignsTitle} variant="h3">
            Ad Campaigns
          </Typography>
          <Button
            variant="outlined"
            className={classes.newCampaignButton}
            onClick={importCampaigns}
            disabled={isImporting}
          >
            {isImporting ? <CircularProgress size={20} /> : 'Import Campaigns'}
          </Button>
          <Button
            variant="outlined"
            className={classes.newCampaignButton}
            onClick={toggleView}
            disabled={editCampaign}
            style={{ marginLeft: 30 }}
          >
            New Campaign
          </Button>
        </Box>
        <Paper data-obscured={editCampaign} className={classes.flexColumns} variant="elevation" elevation={2}>
          <TableCampaigns setEditParams={openEditPanel} />
        </Paper>
        {editPanel}
      </Box>
    </Page>
  );
};

export { LeadCampaigns, LeadCampaigns as default };
