import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { ReactComponent as PortraitLogo } from 'src/assets/images/portrait-logo.svg';

const AccessDenied: React.FC = () => (
  <Grid
    container
    style={{
      minHeight: '100vh',
      padding: '2rem',
      backgroundColor: '#f5f5f5',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Grid item xs={12} style={{ marginBottom: '2rem' }}>
      <Box width="300px" margin="auto">
        <PortraitLogo fontSize="large" />
      </Box>
    </Grid>
    <Grid item xs={12} sm={8} md={6} style={{ textAlign: 'center' }}>
      <Box bgcolor="white" borderRadius={8} boxShadow="0 2px 8px rgba(0,0,0,0.1)" p={4}>
        <h2 style={{ color: '#d32f2f', marginBottom: '1rem' }}>Access Denied</h2>
        <p style={{ color: '#666' }}>
            The authentication token is invalid or missing. Please ensure you are using a valid link or contact support
            for assistance.
        </p>
      </Box>
    </Grid>
  </Grid>
);

export default AccessDenied;
