import { identity, pickBy } from 'lodash';
import { useQuery, useMutation, useQueryClient, UseQueryResult } from 'react-query';
import { AD_CAMPAIGNS } from 'src/constants/reactQuery.keys';
import { IAdCampaign, IAdCampaignLookup } from 'src/interfaces/IAdCampaign';
import AdCampaign from 'src/services/AdCampaign';
import { handleQueryError } from 'src/utils/inventoryOrdering';

const useAdCampaigns = ({
  disabled,
  queryParams = {},
  ...apiParams
}: IAdCampaignLookup): UseQueryResult<IAdCampaign[] | undefined> =>
  useQuery<IAdCampaign[] | undefined>({
    queryKey: [AD_CAMPAIGNS, apiParams.practitionerId, apiParams.page],
    queryFn: (): Promise<IAdCampaign[] | undefined> => AdCampaign.index(pickBy(apiParams, identity)),
    enabled: !disabled,
    onError: handleQueryError('fetching ad campaigns'),
    ...queryParams,
  });

const useAdCampaignsLive = ({ disabled, ...apiParams }: IAdCampaignLookup) =>
  useAdCampaigns({
    disabled,
    ...apiParams,
    queryParams: {
      refetchInterval: 5000,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      staleTime: 2000,
    },
  });

const useCreateAdCampaign = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (adCampaign: Partial<IAdCampaign>): Promise<void> => {
      await AdCampaign.create(adCampaign);
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [AD_CAMPAIGNS] });
    },
    onError: handleQueryError('creating ad campaign'),
  });
};

const useUpdateAdCampaign = (id?: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (adCampaign: Partial<IAdCampaign>): Promise<void> => {
      if (id) {
        await AdCampaign.update({ ...adCampaign, id });
      }
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [AD_CAMPAIGNS] });
    },
    onError: handleQueryError('updating ad campaign'),
  });
};

const useDestroyAdCampaign = (id?: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (): Promise<void> => {
      if (id) {
        await AdCampaign.destroy({ id });
      }
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [AD_CAMPAIGNS] });
    },
    onError: handleQueryError('deleting ad campaign'),
  });
};

const useImportAdCampaigns = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (): Promise<void> => {
      await AdCampaign.import();
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [AD_CAMPAIGNS] });
    },
    onError: handleQueryError('importing ad campaigns'),
  });
};

export {
  useCreateAdCampaign,
  useUpdateAdCampaign,
  useDestroyAdCampaign,
  useImportAdCampaigns,
  useAdCampaigns,
  useAdCampaignsLive,
  useAdCampaigns as default,
};
