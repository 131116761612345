/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppBar, Box, Button } from '@material-ui/core';
import {
  AttachMoney as MoneyIcon,
  SupervisorAccount as SupervisorAccountIcon,
  MonetizationOn as MonetizationOnIcon,
  ListAlt as ListAltIcon,
  ShowChart as ShowChartIcon,
  ThumbUp as ThumbsUpIcon,
  BarChart as BarChartIcon,
  DateRange as DateRangeIcon,
  Store as StoreIcon,
} from '@material-ui/icons';

import { FEATURE_INVENTORY_ORDERING_FLEX_LITE } from 'src/constants/features.constants';
import { ITodo } from '../../../interfaces/ITodo';

import MainTabs from '../../common/MainTabs';
import MainTab from '../../common/MainTab';
import TabItem from '../../common/TabItem';
import tabIdentifier from '../../../utils/tabIdentifier';
import CustomersTab from './CustomersTab';
import TodoTab from './TodoListTabs/AllTab';
import InventoryTab from './InventoryTab';
import AppointmentsCalendar from '../../common/Appointments/AppointmentsCalendar';
import { RootState, dispatch } from '../../../rematch';
import PractitionerPricingTab from './PractitionerPricingTab/PractitionerPricingTab';
import PractitionerDashboardTab from './PractitionerDashboardTab/index';
import { useStyles } from './tabs.styles';
import { useInventoryNotifications } from '../../../hooks/queries/useInventoryNotifications';
import { hasAccessTo, hasFeatureEnabled } from '../../../utils/auth.utils';
import InsightsTab from './InsightsTab';
import FeedbackTab from './FeedbackTab';
import {
  NEW_EARNINGS_DASHBOARD_HIDDEN_ROUTE_PATH,
  PRACTITIONER_PRICING_PRICES_PATH,
  PRACTITIONER_BASE_PATH,
  PRACTITIONER_TODO_ALL_PATH,
  PRACTITIONER_PRICING_FEES_PATH,
} from '../../../routes/practitionerRoutes';
import {
  CUSTOM_SERVICES,
  READ_FEEDBACK,
  READ_INVENTORY,
  READ_MARKETING,
  READ_TODO_LIST,
} from '../../../constants/actions.constants';
import { ReactComponent as GearIcon } from '../../../assets/images/gear.svg';
import { ReactComponent as AlertIcon } from '../../../assets/images/alert.svg';
import { SUPPORT_LINK } from '../../../constants/routes.constants';
import {
  PORTRAIT_ASCEND,
  PORTRAIT_FLEX,
  PORTRAIT_LAUNCH,
  SUBMIT_SUPPORT_LINK,
} from '../../../constants/general.constants';
import ServicesManagementComponent from '../../../pages/AccountSetting/ServicesManagement/ServicesManagmentComponent';
import { useUpdateEffect } from '../../../hooks/useUpdateEffect';
import InventoryTabNonLegacy from './InventoryTabNonLegacy';

type Props = {
  scrollerMain: any;
  tabs: string;
  state: string;
};

const PractitionerTabs = ({ tabs, state, scrollerMain }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const { userId, featureList, permissions } = useSelector(({ auth }: RootState) => auth);
  const [appointmentStatus, setAppointmentStatus] = React.useState<string>('all');

  const classes = useStyles();

  const tabValues = [
    'dashboard',
    'marketing',
    'patients',
    'todo',
    'inventory',
    'calendar',
    'pricing',
    'feedback',
    'ordering',
  ];

  const [initialTab] = React.useState<any>(tabs);
  const tabIndex = tabValues.findIndex((val) => val === location.pathname.split('/')[2]);
  const [value, setValue] = React.useState<any>(tabIndex > 0 ? tabIndex : 1);

  const { hasProductsAtRisk, hasPendingReconciliationReports, hasPendingSupplyReceipts, hasLessThan8Hyaluronidase } =
    useInventoryNotifications(permissions?.length > 0 && hasAccessTo(READ_INVENTORY, permissions));

  const showInventoryNotification =
    hasProductsAtRisk || hasPendingReconciliationReports || hasPendingSupplyReceipts || hasLessThan8Hyaluronidase;

  const { todos = [] }: { todos: ITodo[] } = useSelector(({ todo }: any) => todo);
  const { roleName } = useSelector(({ auth }: RootState) => auth);
  const isPortraitFlexOrLite = [PORTRAIT_FLEX, PORTRAIT_LAUNCH, PORTRAIT_ASCEND].includes(roleName);

  const customServicesAccess = permissions?.length > 0 && hasAccessTo(CUSTOM_SERVICES, permissions);

  const showTodoNotification = todos.some(({ showYellowFlag, showRedFlag }) => showYellowFlag || showRedFlag);

  const showNonLegacyOrdering = hasFeatureEnabled(FEATURE_INVENTORY_ORDERING_FLEX_LITE, featureList);

  useEffect(() => {
    // Fix reload page tab state
    const tab = location.pathname.split('/')[2];
    const newValue = tabValues.findIndex((val) => val === tab);
    if (newValue !== value) {
      setValue(newValue);
    }
  }, [location.pathname, value]);

  // Check dashboard tab after practitioner profile is loaded
  useEffect(() => {
    if (location.pathname === NEW_EARNINGS_DASHBOARD_HIDDEN_ROUTE_PATH) {
      setValue(0);
      history.push(NEW_EARNINGS_DASHBOARD_HIDDEN_ROUTE_PATH);
      return;
    }

    const indexTab = tabValues.findIndex((val) => val === initialTab);
    handleChange(indexTab > -1 ? indexTab : 1);
  }, []);

  useUpdateEffect(() => {
    if (userId) {
      dispatch({ type: 'todo/loadTodos' });
    }
  }, [userId]);

  const handleChange = (newValue: number) => {
    /* eslint-disable no-unused-expressions */
    if (tabValues[newValue] === 'todo') {
      history.push(PRACTITIONER_TODO_ALL_PATH);
    } else if (tabValues[newValue] === 'pricing') {
      customServicesAccess
        ? history.push(PRACTITIONER_PRICING_FEES_PATH)
        : history.push(PRACTITIONER_PRICING_PRICES_PATH);
    } else if (window.location.pathname !== `${PRACTITIONER_BASE_PATH}/${tabValues[newValue]}`) {
      // Skip refresh state for custom services access to avoid issues with modal form.

      history.push(`${PRACTITIONER_BASE_PATH}/${tabValues[newValue]}`);
    }
    setValue(tabValues.findIndex((val) => val === tabValues[newValue]));
  };

  const dashboardTabToDisplay = () => (
    <div>
      <MoneyIcon className={classes.iconColor} viewBox="4 -4.5 25 25" style={{ verticalAlign: 'sub' }} />
      Earnings{' '}
    </div>
  );

  const goToHelpCenter = (URI: string) => {
    window.open(URI, '_blank')?.focus();
  };

  return (
    <>
      <AppBar data-testid="practitioners-tab" position="static">
        <div>
          <MainTabs value={value} onChange={(e, newValue) => handleChange(newValue)} aria-label="simple tabs example">
            <MainTab value={0} label={dashboardTabToDisplay()} {...tabIdentifier(tabValues[0])} />
            {hasAccessTo(READ_MARKETING, permissions) && (
              <MainTab
                value={1}
                className={classes.tab}
                label={
                  <div data-cy="practMetricsTab">
                    <BarChartIcon
                      className={classes.iconColor}
                      viewBox="4 -4.5 25 25"
                      style={{ verticalAlign: 'sub' }}
                    />
                    Marketing
                  </div>
                }
                {...tabIdentifier(tabValues[1])}
              />
            )}
            <MainTab
              value={2}
              className={classes.tab}
              label={
                <div data-cy="practPatientsTab">
                  <SupervisorAccountIcon
                    className={classes.iconColor}
                    viewBox="4 -4.5 25 25"
                    style={{ verticalAlign: 'sub' }}
                  />
                  Patients
                </div>
              }
              {...tabIdentifier(tabValues[2])}
            />
            <MainTab
              value={3}
              label={
                <div style={{ display: 'flex' }} data-cy="practTodoListTab">
                  <ListAltIcon
                    className={classes.iconColor}
                    viewBox="3.5 -4.5 25 25"
                    style={{ marginTop: '-2px', verticalAlign: 'sub' }}
                  />
                  <span style={{ margin: '3px 0 0' }}>
                    {hasAccessTo(READ_TODO_LIST, permissions) ? 'To-do list' : 'Opened Service Visits'}
                  </span>
                  {showTodoNotification && <div className={classes.pendingNotification} />}
                </div>
              }
              {...tabIdentifier(tabValues[3])}
            />
            {isPortraitFlexOrLite && (
              <MainTab
                value={8}
                style={{ maxWidth: '200px' }}
                label={
                  <div style={{ display: 'flex', alignItems: 'center' }} data-cy="practInventoryTab2">
                    <StoreIcon
                      className={classes.iconColor}
                      viewBox="3.5 -4.5 25 25"
                      style={{ marginTop: '-2px', verticalAlign: 'sub' }}
                    />
                    <div data-cy="inventoryNotification" style={{ margin: '3px 0 0' }}>
                      {showNonLegacyOrdering ? 'Inventory Ordering' : 'Inventory Pricing'}
                    </div>
                  </div>
                }
                {...tabIdentifier(tabValues[8])}
              />
            )}
            <MainTab
              value={4}
              style={customServicesAccess ? { maxWidth: '300px' } : {}}
              label={
                <div style={{ display: 'flex', alignItems: 'center' }} data-cy="practInventoryTab">
                  <ShowChartIcon
                    className={classes.iconColor}
                    viewBox="3.5 -4.5 25 25"
                    style={{ marginTop: '-2px', verticalAlign: 'sub' }}
                  />
                  <div data-cy="inventoryNotification" style={{ margin: '3px 0 0' }}>
                    {customServicesAccess ? 'Services/Products Management' : 'Inventory'}
                  </div>
                  {showInventoryNotification && <div className={classes.pendingNotification} />}
                </div>
              }
              {...tabIdentifier(tabValues[4])}
            />
            <MainTab
              value={5}
              className={classes.tab}
              label={
                <div data-cy="practCalendarTab">
                  <DateRangeIcon
                    className={classes.iconColor}
                    viewBox="4 -4.5 25 25"
                    style={{ verticalAlign: 'sub' }}
                  />
                  Calendar
                </div>
              }
              {...tabIdentifier(tabValues[5])}
            />
            <MainTab
              value={6}
              className={classes.tab}
              label={
                <div data-cy="practPricingTab">
                  <MonetizationOnIcon
                    className={classes.iconColor}
                    viewBox="4 -4.5 25 25"
                    style={{ verticalAlign: 'sub' }}
                  />
                  {customServicesAccess ? 'Fees' : 'Pricing'}
                </div>
              }
              {...tabIdentifier(tabValues[6])}
            />
            {hasAccessTo(READ_FEEDBACK, permissions) && (
              <MainTab
                value={7}
                className={classes.tab}
                label={
                  <div data-cy="practFeedbackTab">
                    <ThumbsUpIcon
                      className={classes.iconColor}
                      viewBox="4 -4.5 25 25"
                      style={{ verticalAlign: 'sub' }}
                    />
                    Feedback
                  </div>
                }
                {...tabIdentifier(tabValues[7])}
              />
            )}
            <Box mr={2} className={classes.helpCenterButtonContainer}>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={() => goToHelpCenter(SUBMIT_SUPPORT_LINK)}
                data-cy="referAProviderButton"
              >
                <div className={classes.helpCenterButton}>
                  <AlertIcon />
                  Submit a Ticket
                </div>
              </Button>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={() => goToHelpCenter(SUPPORT_LINK)}
                data-cy="referAProviderButton"
              >
                <div className={classes.helpCenterButton}>
                  <GearIcon />
                  Support Center
                </div>
              </Button>
            </Box>
          </MainTabs>
        </div>
      </AppBar>
      <TabItem value={value} index={0}>
        <PractitionerDashboardTab />
      </TabItem>
      {hasAccessTo(READ_MARKETING, permissions) && (
        <TabItem value={value} index={1}>
          <InsightsTab />
        </TabItem>
      )}
      <TabItem value={value} index={2}>
        <CustomersTab scrollerMain={scrollerMain} />
      </TabItem>
      <TabItem value={value} index={3}>
        <TodoTab />
      </TabItem>
      <TabItem value={value} index={4}>
        {customServicesAccess ? (
          <ServicesManagementComponent renderLocation="tabs" />
        ) : (
          <InventoryTab
            state={state}
            hasProductsAtRisk={hasProductsAtRisk || hasLessThan8Hyaluronidase}
            hasPendingReconciliation={hasPendingReconciliationReports}
            hasPendingSupplyReceipt={hasPendingSupplyReceipts}
          />
        )}
      </TabItem>
      <TabItem value={value} index={5}>
        <AppointmentsCalendar appointmentStatus={appointmentStatus} setAppointmentStatus={setAppointmentStatus} />
      </TabItem>
      <TabItem value={value} index={6}>
        <PractitionerPricingTab />
      </TabItem>
      {hasAccessTo(READ_FEEDBACK, permissions) && (
        <TabItem value={value} index={7}>
          <FeedbackTab />
        </TabItem>
      )}
      <TabItem value={value} index={8}>
        <InventoryTabNonLegacy state={state} />
      </TabItem>
    </>
  );
};

export default PractitionerTabs;
