import React from 'react';
import { ColumnType } from 'src/types/TablePaginator';
import { Pill } from '../../components/common/Pill';

export const MEDSPA_LOCATION_DETAILS_COLUMNS: any[] = [
  { id: 'fullName', title: 'Full name' },
  { id: 'licenses', title: 'Credentials' },
  { id: 'email', title: 'Email' },
  { id: 'activeForScheduling', title: 'Active For Scheduling' },
];

export const MEDSPA_LOCATION_COLUMNS: ColumnType[] = [
  { field: 'name', title: 'Location Name' },
  {
    field: 'city',
    title: 'City/State',
    render: (value: string, row: any) => `${row.city}/${row.state}`,
  },
  { field: 'address', title: 'Address' },
  { field: 'providers', title: 'Providers', render: (value: string) => value?.length },
  {
    field: 'active',
    title: 'Status',
    render: (value: any) => (
      <Pill title={value ? 'Active' : 'Inactive'} color={value ? 'green' : 'gray'} style={{ cursor: 'pointer' }} />
    ),
  },
];
