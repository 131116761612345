import React, { useState } from 'react';
import { Container, withStyles } from '@material-ui/core';
import { useInfiniteProducts } from 'src/hooks/queries/useProducts';
import { IOrderProductAdmin, ProductResponse } from 'src/services/Products';
import { ClassNameMap } from 'src/types/Dom';
import TablePaginator from 'src/components/common/Table/TablePaginator';
import { PRODUCT_COLUMNS, PRODUCTS_TABLE_PAGE_SIZE } from './OrderProducts.constants';

interface ProductsTableProps extends ClassNameMap {
  editProduct?: (product: IOrderProductAdmin) => void;
  searchQuery: string;
  supplierId: number | 'all';
  sortQuery: (sortBy: string, sortDirection: string) => void;
}

export const ProductsTable: React.FC<ProductsTableProps> = ({
  classes,
  editProduct,
  searchQuery,
  supplierId,
  sortQuery,
}) => {
  const [sort, setSort] = useState<{ sortBy: string; sortDirection: string }>({ sortBy: '', sortDirection: '' });
  const { data, isLoading, isError, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteProducts({
    searchQuery,
    supplierId: supplierId === 'all' ? undefined : supplierId,
    sort,
    limit: PRODUCTS_TABLE_PAGE_SIZE,
  });

  const handleFetchNextPage = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  const onChangeSortBy = (sortBy: string, sortDirection: string) => {
    setSort({ sortBy, sortDirection });
    sortQuery(sortBy, sortDirection);
  };

  return (
    <Container classes={classes} data-testid="orderProducts" data-cy="products-table-container">
      <TablePaginator
        columns={PRODUCT_COLUMNS}
        data={data?.pages?.flatMap(({ data: pageData }: ProductResponse) => pageData) ?? []}
        total={data?.pages ? data.pages[0].meta.totalCount : 0}
        isLoading={isLoading}
        isError={isError}
        fetchNextPage={handleFetchNextPage}
        onClickRow={editProduct}
        onChangeSortBy={onChangeSortBy}
      />
    </Container>
  );
};

export default withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  maxWidthLg: {
    maxWidth: 'unset',
  },
})(ProductsTable);
