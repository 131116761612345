import React from 'react';
import { MenuItem } from '@material-ui/core';
import { ALL_MEDICAL_CREDENTIALS, MEDICAL_CREDENTIALS } from 'src/pages/AccountSetting/MoreDetails';
import { isArray } from 'lodash';
import { EHRSelect } from '../ui/v1';

export interface CredentialsDropdownProps {
  value?: string | string[];
  onChange: (event: any) => void;
  hasErrors?: boolean;
  withOldLicenses?: boolean;
  asFilter?: boolean;
  disabled?: boolean;
  multiple?: boolean;
}

const CredentialsDropdown = (props: CredentialsDropdownProps) => {
  const {
    value,
    onChange,
    withOldLicenses = false,
    hasErrors = false,
    asFilter = false,
    disabled = false,
    multiple = false,
  } = props;

  const getCredentialsList = () => {
    const credentials = withOldLicenses ? ALL_MEDICAL_CREDENTIALS : MEDICAL_CREDENTIALS;
    return value && !MEDICAL_CREDENTIALS.includes(value) && !multiple ? [value, ...credentials] : credentials;
  };

  const handleValue = (credential: string) => {
    if (!credential) {
      return 'None of these';
    }
    return credential;
  };

  const handleOnChange = (newValue: any, selectedValue: any) => {
    if (selectedValue === undefined) {
      onChange([]);
      return;
    }
    onChange(newValue);
  };

  return (
    <EHRSelect
      dataCy="select-licenses"
      data-testid="select-licenses"
      name="Credentials"
      value={isArray(value) ? value : [value]}
      onChange={handleOnChange}
      label="Credentials"
      fullWidth
      multiple={multiple}
      error={hasErrors}
      helperText={hasErrors ? 'Error updating credentials' : ''}
      disabled={disabled}
    >
      {asFilter && (
        <MenuItem value={undefined} onClick={() => onChange([])}>
          All
        </MenuItem>
      )}
      {getCredentialsList().map((credential) => (
        <MenuItem value={handleValue(credential as string)} key={credential as string}>
          {credential}
        </MenuItem>
      ))}
    </EHRSelect>
  );
};

export default CredentialsDropdown;
