import React from 'react';
import { useHistory } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@material-ui/icons';
import classNames from 'classnames';
import { ClassNameMap } from 'src/types/Dom';
import { Props as RecursiveMenuItemProps } from './RecursiveMenuItem';

type Props = ClassNameMap & Omit<RecursiveMenuItemProps, 'permissions'> & { isActive: boolean };

export const RecursiveMenuSubOption = ({
  id,
  subMenus,
  setSubMenus,
  option,
  list = [],
  classes,
  setCollapsed,
  collapsed,
  isActive,
}: Props) => {
  const history = useHistory();

  const handleSubMenuClick = (values: string[], href?: string) => {
    setSubMenus(subMenus.includes(values[values.length - 1]) ? values.slice(0, -1) : values);

    if (!!href && !collapsed) {
      history.push(href);
      setCollapsed?.(true);
    } else {
      collapsed && setCollapsed?.(false);
    }
  };

  return (
    <ListItem
      button
      onClick={() => handleSubMenuClick([...list, `${id}-${option.name}`], option.url)}
      className={classNames(classes?.listItem, classes?.[list.length], {
        [classes?.[`selected-${list.length}`] ?? '']: isActive,
      })}
      disabled={option.disabled}
    >
      <ListItemIcon className={classes?.iconList}>{option.icon?.(isActive)}</ListItemIcon>
      <ListItemText>
        <Typography variant="body1" className={isActive ? classes?.textActive : classes?.text}>
          {option.name}
        </Typography>
      </ListItemText>
      {option.options && (
        <>
          {isActive ? (
            <ExpandLessIcon
              className={classNames(classes?.arrowIcon, classes?.text, { [classes?.expandActive ?? '']: isActive })}
            />
          ) : (
            <ExpandMoreIcon className={classNames(classes?.arrowIcon, classes?.iconColor)} />
          )}
        </>
      )}
    </ListItem>
  );
};

export default RecursiveMenuSubOption;
