import React from 'react';
import classnames from 'classnames';
import { Box, Grid } from '@material-ui/core';
import { LogoAlt } from 'src/components/LogoAlt';
import { useStyles } from './index.styles';
import { PortraitMockUp } from './PortraitMockUp';

export const SignUpWait = () => {
  const classes = useStyles();

  return (
    <div className={classes.signupContainer}>
      <div className={classes.left}>
        <div className={classes.leftInside}>
          <PortraitMockUp />
        </div>
      </div>
      <div className={classes.right}>
        <div className={classnames(classes.signupDetails, classes.signUpDetailsInBeige)}>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={4}>
              <Box className={classes.mobileOnly}>
                <LogoAlt />
              </Box>
            </Grid>
          </Grid>
          <Box className={classes.waitingText}>
            Great! Your account is now under review. You will receive an email activation within 24 hours once your
            account is live.
          </Box>
        </div>
      </div>
    </div>
  );
};
