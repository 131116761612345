import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import FeeTypesForm from 'src/components/FeeTypes/FeeTypesForm';
import { useStyles } from './PractitionerFeeTab.styles';
import { Button } from '../../../common/Button';
import { usePractitionerInfo } from '../../../../hooks/queries/usePractitioners';
import { MultipleSkeleton } from '../../../common/LoadingSkeleton';
import { Practitioners } from '../../../../services/Practitioners';
import { ReactComponent as GreenCheck } from '../../../../assets/images/green-check.svg';
import { NO_FEE_VALUE, CANCELLATION_FEE_VALUE, DEPOSIT_FEE_VALUE } from '../../../../constants/general.constants';

function getFeeByValues(cancellationFee: number | undefined, depositFee: number | undefined) {
  if (cancellationFee && depositFee) {
    return [CANCELLATION_FEE_VALUE, DEPOSIT_FEE_VALUE];
  }

  if (cancellationFee) {
    return [CANCELLATION_FEE_VALUE];
  }

  if (depositFee) {
    return [DEPOSIT_FEE_VALUE];
  }

  return [NO_FEE_VALUE];
}

export const PractitionerFeeTab = () => {
  const classes = useStyles();
  const { mutate: updatePractitioner } = useMutation(Practitioners.editPractitioner);
  const [selectedFeeOptions, setSelectedFeeOptions] = useState<number[]>([NO_FEE_VALUE]);
  const { userId: practitionerId } = useSelector(({ auth }: any) => auth);
  const [processing, setProcessing] = useState<boolean>(false);
  const [showSaved, setShowSaved] = useState<boolean>(false);
  const [cancellationFee, setCancellationFee] = useState(0);
  const [depositFee, setDepositFee] = useState(0);
  const { practitionerInfo, isLoading } = usePractitionerInfo(practitionerId);

  useEffect(() => {
    if (practitionerInfo?.id) {
      setCancellationFee(practitionerInfo.cancelationFee || 0);
      setDepositFee(practitionerInfo.depositFee || 0);

      setSelectedFeeOptions(getFeeByValues(practitionerInfo.cancelationFee, practitionerInfo.depositFee));
    }
  }, [practitionerInfo]);

  function getValidFeeAmount() {
    if (selectedFeeOptions.includes(NO_FEE_VALUE)) {return true;}

    if (selectedFeeOptions.includes(CANCELLATION_FEE_VALUE) && selectedFeeOptions.includes(DEPOSIT_FEE_VALUE)) {
      return cancellationFee > 0 && depositFee > 0;
    }

    if (selectedFeeOptions.includes(CANCELLATION_FEE_VALUE)) {
      return cancellationFee > 0;
    }

    if (selectedFeeOptions.includes(DEPOSIT_FEE_VALUE)) {
      return depositFee > 0;
    }

    return false;
  }

  const validFeeAmount = getValidFeeAmount();

  const handleUpdateFeePreference = async () => {
    setProcessing(true);
    await updatePractitioner({
      id: practitionerId,
      skipUpdateInfoParams: true,
      requestCreditCard: !!cancellationFee,
      requestDeposit: !!depositFee,
      cancelationFee: cancellationFee,
      depositFee,
    });
    setProcessing(false);
    setShowSaved(true);
    setTimeout(() => setShowSaved(false), 3000);
  };

  if (isLoading || !practitionerInfo.id) {
    return <MultipleSkeleton />;
  }

  return (
    <div className={classes.feesContainer}>
      <Typography variant="h5" className={classes.title}>
        Deposits and Fees
      </Typography>
      <p className={classes.feeBoldText}>
        Choose whether you want to charge clients a fee for canceling or rescheduling without at least 24 hours notice:
      </p>
      <div>
        <FeeTypesForm
          data={{
            cancellationFee: practitionerInfo.cancelationFee,
            depositFee: practitionerInfo.depositFee,
          }}
          onChange={(data) => {
            const depositFeeValue = data.depositFee ? +data.depositFee : 0;
            const cancelationFeeValue = data.cancellationFee ? +data.cancellationFee : 0;

            setDepositFee(depositFeeValue);
            setCancellationFee(cancelationFeeValue);
            setSelectedFeeOptions(
              data.selectedFeeOptions?.length
                ? data.selectedFeeOptions
                : getFeeByValues(cancelationFeeValue, depositFeeValue)
            );
          }}
        />
      </div>
      <div>
        <Button
          title={processing ? 'Saving...' : 'Save'}
          onClick={handleUpdateFeePreference}
          className={classes.button}
          disabled={!validFeeAmount}
        />
      </div>
      {showSaved && (
        <div className={classes.savedMessage}>
          <GreenCheck className={classes.savedIcon} />
          Settings saved
        </div>
      )}
    </div>
  );
};
