import React, { useMemo, useState } from 'react';
import { Box, CircularProgress, FormControl, MenuItem } from '@material-ui/core';
import moment from 'moment';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { usePatientMedspaStyles } from './PatientMedspaStyles';
import { IPatientView } from '../../../types/Patient';
import { CustomLink } from '../../common/Custom/CustomLink';
import { ROUTES } from '../../../constants/routes.constants';
import {
  MedicalProfileUpdatedIcon,
  NewMessagesIcon,
  PhotoRequestedIcon,
  UnseenPhotosIcon,
} from '../../../constants/patients/columns.constants';
import { useMedspaPracPatients } from '../../../hooks/queries/useMedspaPatients';
import { ICustomerViewParams } from '../../../interfaces/CustomerParams';
import Page from '../../common/Page';
import InputSearch from '../../common/InputSearch';
import { EHRButton, EHRCheckbox, EHRSelect } from '../../ui/v1';
import { PATIENT_STATUS_OPTS } from './PatientsMedspaPage';
import { useUpdateEffect } from '../../../hooks/useUpdateEffect';
import TablePaginator from '../../common/Table/TablePaginator';
import NewPatientForm from './NewPatientForm';

const initialParams = {
  status: '',
  page: 1,
  search: '',
  hasRequestedPhotos: false,
  createdAsLead: false,
  sort: 'next_appointment',
  sortDirection: 'ASC',
  practitionerIds: [],
};

const ALL_PATIENT_COLUMNS = [
  { field: 'id', title: 'id' },
  {
    field: 'firstName',
    title: 'First name',
    sortable: true,
    sortField: 'first_name',
    render: (value: string, row: IPatientView) => (
      <CustomLink href={ROUTES.PATIENT_ID(String(row.id))}>{value}</CustomLink>
    ),
  },
  {
    field: 'lastName',
    title: 'Last name',
    sortable: true,
    sortField: 'last_name',
    render: (value: string, row: IPatientView) => (
      <CustomLink href={ROUTES.PATIENT_ID(String(row.id))}>{value}</CustomLink>
    ),
  },
  { field: 'email', title: 'Email' },
  {
    field: '',
    title: 'Updates',
    render: (v: string, row: IPatientView) => (
      <CustomLink href={ROUTES.PATIENT_ID(String(row.id))}>
        {row.unreadMessagesCount > 0 && <NewMessagesIcon count={row.unreadMessagesCount} />}
        {row.unseenPhotosCount > 0 && <UnseenPhotosIcon count={row.unseenPhotosCount} />}
        {row.needsDoctorReview && <MedicalProfileUpdatedIcon />}
        {row.hasRequestedPhotos && <PhotoRequestedIcon />}
      </CustomLink>
    ),
  },
  {
    field: 'nextAppointment',
    title: 'Next appointment',
    sortable: true,
    sortField: 'next_appointment',
    render: (value: string) => (value ? moment(value).format('MM/DD/YYYY HH:mm') : ''),
  },
  { field: 'visits', title: 'Visits', sortable: true, sortField: 'visits' },
  {
    field: 'status',
    title: 'Status',
    sortable: true,
    sortField: 'status',
    render: (value: string) => PATIENT_STATUS_OPTS[value],
  },
  { field: 'phone', title: 'Phone' },
  {
    field: 'createdAt',
    title: 'Created at',
    width: '150px',
    sortable: true,
    sortField: 'created_at',
    render: (value: string) => moment(value).format('MM/DD/YYYY HH:mm'),
  },
];

export const PatientsMedspaProviderPage = () => {
  const classes = usePatientMedspaStyles();
  const { userId } = useSelector(({ auth }: any) => auth);
  const [searchQuery, setSearchQuery] = useState('');
  const [newPatientModalOpen, setNewPatientModalOpen] = useState(false);
  const [queryParams, setQueryParams] = useState<ICustomerViewParams>({
    ...initialParams,
    ...{ practitionerIds: [userId] },
  });
  const [withPhotoRequestChecked, setWithPhotoRequestChecked] = useState<boolean>(initialParams.hasRequestedPhotos);
  const {
    isLoading,
    isFetching,
    isError,
    data: { patients, totalCount },
    fetchNextPage,
  } = useMedspaPracPatients(userId, queryParams);

  const onChangeSortBy = (column: string, direction: string) => {
    setQueryParams({ ...queryParams, sort: column, sortDirection: direction });
  };

  const onChangeStatus = (status: string) => {
    const newStatus = status === 'all_status' ? '' : status;

    setQueryParams({
      ...queryParams,
      status: newStatus,
      hasRequestedPhotos: withPhotoRequestChecked,
    });
  };

  useUpdateEffect(() => {
    setQueryParams({
      ...queryParams,
      hasRequestedPhotos: withPhotoRequestChecked,
    });
  }, [withPhotoRequestChecked]);

  const debounceCall = (query: string, params: ICustomerViewParams) => {
    setQueryParams({
      ...params,
      search: query,
    });
  };

  const doSearch = useMemo(
    () => debounce((query: string, params: ICustomerViewParams) => debounceCall(query, params), 1000),
    []
  );

  const onInputChange = (event: any) => {
    const newSearchQuery = event.target.value;
    setSearchQuery(newSearchQuery);
    doSearch(newSearchQuery, queryParams);
  };

  if (isLoading) {
    return (
      <Page title="Patients">
        <Box textAlign="center" width="100%" padding="100px 0">
          <CircularProgress />
        </Box>
      </Page>
    );
  }

  return (
    <Page title="Patients">
      <Box display="flex" justifyContent="space-between" flexWrap="wrap" gridGap="1rem">
        <Box display="flex" flexWrap="wrap" gridGap="1rem">
          <Box className={classes.patientHeaderFormControlContainer} style={{ minWidth: '296px' }}>
            <InputSearch
              placeholder="Search..."
              onChangeValue={onInputChange}
              value={searchQuery}
              isLoading={isLoading}
            />
          </Box>
          <Box data-cy="stateSelect" style={{ width: '150px' }} className={classes.patientHeaderFormControlContainer}>
            <FormControl fullWidth variant="outlined">
              <EHRSelect
                dataCy="select-status"
                // @ts-ignore
                onChange={onChangeStatus}
                id="state"
                value={queryParams.status === '' ? 'all_status' : queryParams.status}
                fullWidth
              >
                {Object.entries(PATIENT_STATUS_OPTS).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </EHRSelect>
            </FormControl>
          </Box>
          <Box className={classes.patientHeaderFormControlContainer}>
            <EHRCheckbox
              dataCy="checkbox-with-photo-request"
              label="With photo request"
              checked={withPhotoRequestChecked}
              onChange={() => setWithPhotoRequestChecked(!withPhotoRequestChecked)}
              value="order"
            />
          </Box>
        </Box>
        <Box display="flex" gridGap="1rem" flexWrap="wrap" className={classes.patientHeaderActionButtonsContainer}>
          <Box>
            <EHRButton
              dataCy="btn-new-patient"
              color="primary"
              text="New Patient"
              onClick={() => setNewPatientModalOpen(true)}
            />
          </Box>
        </Box>
        <Box className={classes.infiScrollContainer}>
          <TablePaginator
            isLoading={isLoading}
            isFetching={isFetching}
            isError={isError}
            data={patients}
            columns={ALL_PATIENT_COLUMNS}
            onChangeSortBy={onChangeSortBy}
            total={totalCount}
            fetchNextPage={fetchNextPage}
          />
        </Box>
      </Box>
      <NewPatientForm
        setShowNewPatientModal={setNewPatientModalOpen}
        showNewPatientModal={newPatientModalOpen}
        currentProviderId={userId}
      />
    </Page>
  );
};
