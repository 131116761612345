import React from 'react';
import {
  GFE_SETTING_NO_PROVIDER_NAME,
  GFE_SETTING_OWN_PROVIDER_NAME,
  GFE_SETTING_PORTRAIT_PROVIDER_NAME,
} from '../../../constants/general.constants';
import { GFEAutoRoutingCards } from './GFEAutoRoutingCards';

interface IGFESettingTextMap {
  title: string;
  text: string;
  subContent?: React.FC;
}

export const GFE_SETTING_CONTENT_MAP: Record<string, IGFESettingTextMap> = {
  [GFE_SETTING_PORTRAIT_PROVIDER_NAME]: {
    title: 'Portrait Clearing Services',
    text: `Portrait will take care of performing your GFEs. The patient will be required to schedule a
      clearance with our team upon registration. You will be charged $40 per Live GFE.`,
    subContent: GFEAutoRoutingCards,
  },
  [GFE_SETTING_NO_PROVIDER_NAME]: {
    title: 'Do Not Route',
    text: 'You will have to request each GFE manually.',
  },
  [GFE_SETTING_OWN_PROVIDER_NAME]: {
    title: 'Own Clearing Provider',
    text: `All GFE requests will be automatically routed to your Clearing Provider. You will still have the
      ability to request an Urgent GFE from Portrait at the standard fee in the event your Clearing Provider cannot
      complete a GFE in time.`,
  },
};
