import React, { useState } from 'react';
import { Box, Tab, Tabs } from '@material-ui/core';
import Page from 'src/components/common/Page';
import TabItem from 'src/components/common/TabItem';
import { EHRButton } from 'src/components/ui/v1';
import ResourcesTab from './ResourcesTab';
import { useResourcesStyles } from './resources.styles';
import TutorialsTab from './TutorialsTab';

export const ResourcesToolbar = ({ isLoading, onClick }: { isLoading: boolean; onClick: () => void }) => {
  const classes = useResourcesStyles();
  return (
    <Box className={classes.resourcesToolbar}>
      <Box>
        <EHRButton dataCy="btn-add-content" color="primary" text="Add Content" onClick={onClick} disabled={isLoading} />
      </Box>
    </Box>
  );
};

const Resources = () => {
  const [tab, setTab] = useState(0);

  const handleTabChange = (_e: any, newTab: number) => setTab(newTab);

  return (
    <Page title="Resources">
      <Tabs
        TabIndicatorProps={{ style: { backgroundColor: 'black' } }}
        value={tab}
        onChange={handleTabChange}
        style={{ borderBottom: '1px solid lightgrey', width: '100%' }}
      >
        <Tab label="Resources" id="resources-resources-tab" />
        <Tab label="Tutorials" id="resources-tutorials-tab" />
      </Tabs>

      <TabItem value={tab} index={0}>
        <ResourcesTab />
      </TabItem>

      <TabItem value={tab} index={1}>
        <TutorialsTab />
      </TabItem>

      <Box marginBottom={2} />
    </Page>
  );
};

export default Resources;
