import { StepsCompleted } from 'src/services/MedspaAdmins';
import { ONBOARDING_STEPS, ONBOARDING_STEPS_ORDER } from './constants';

export const stepMapping: Record<string, keyof StepsCompleted> = {
  [ONBOARDING_STEPS.ACCOUNT_SETTINGS_NAME]: 'accountSettings',
  [ONBOARDING_STEPS.PROVIDERS_MANAGEMENT_NAME]: 'providersManagement',
  [ONBOARDING_STEPS.LOCATION_MANAGEMENT_NAME]: 'locationsManagement',
  [ONBOARDING_STEPS.SERVICE_MANAGEMENT_NAME]: 'servicesManagement',
  [ONBOARDING_STEPS.INVENTORY_ORDER_NAME]: 'makeYourFirstOrder',
  [ONBOARDING_STEPS.PATIENTS_MANAGEMENT_NAME]: 'patientsManagement',
  [ONBOARDING_STEPS.APPOINTMENT_TYPES_NAME]: 'appointmentTypes',
  [ONBOARDING_STEPS.CALENDAR_SET_UP_NAME]: 'calendarSetUp',
};

export const getNextStep = (currentStep: string): keyof StepsCompleted | null => {
  if (!currentStep) {
    return null;
  }
  const currentIndex = ONBOARDING_STEPS_ORDER.indexOf(currentStep);
  if (currentIndex === -1 || currentIndex === ONBOARDING_STEPS_ORDER.length - 1) {
    return null;
  }
  const nextStep = ONBOARDING_STEPS_ORDER[currentIndex + 1];

  return stepMapping[nextStep] || null;
};
