import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Avatar, Box, TableCell, TableRow } from '@material-ui/core';
import { EHRButton } from 'src/components/ui/v1';
import { Practitioners } from 'src/services/Practitioners';
import { RootState, dispatch } from '../../../rematch';
import { usePractitionerBadgeStyles, useRowStyles } from './Practitioner.styles';
import { TableCellCustom } from '../../common/TableStyles/TableCellCustom';
import { hasAccessTo } from '../../../utils/auth.utils';
import { CREATE_PRACTITIONER, READ_PRACTITIONER_LOGIN } from '../../../constants/actions.constants';
import { EDIT_PRACTITIONER } from '../../../routes/administratorRoutes';
import { IRoles } from '../../../interfaces/IRoles.interfaces';
import {
  PORTRAIT_FLEX,
  PORTRAIT_LEGACY,
  PORTRAIT_LEGACY_PLUS,
  PORTRAIT_LAUNCH,
  PORTRAIT_ASCEND,
} from '../../../constants/general.constants';
import { IFeature } from '../../../interfaces/IFeature';
import EditPractitionerFeaturesColumn from './EditPractitionerFeaturesColumn';

export interface PractitionerRowProps {
  id: number;
  email: string;
  fullName: string;
  licenses?: string;
  role?: IRoles;
  features: IFeature[];
  enabledFeatures: IFeature[];
  refetch: () => void;
  userGroupId?: number | null;
  profileImageUrl?: string;
}

export const PractitionerBadge = ({ role }: { role: string }) => {
  if (![PORTRAIT_LAUNCH, PORTRAIT_ASCEND, PORTRAIT_FLEX, PORTRAIT_LEGACY, PORTRAIT_LEGACY_PLUS].includes(role)) {
    return null;
  }

  const styles = usePractitionerBadgeStyles();
  let badgeStyle;
  switch (role) {
    case PORTRAIT_LEGACY:
      badgeStyle = styles.legacy;
      break;
    case PORTRAIT_LEGACY_PLUS:
      badgeStyle = styles.legacy;
      break;
    case PORTRAIT_LAUNCH:
      badgeStyle = styles.launch;
      break;
    case PORTRAIT_ASCEND:
      badgeStyle = styles.ascend;
      break;
    default:
      badgeStyle = styles.flex;
  }
  return <span className={badgeStyle}>{role}</span>;
};

const PractitionerRow: React.FC<PractitionerRowProps> = ({
  id: practitionerId,
  email,
  fullName,
  licenses,
  userGroupId,
  role,
  enabledFeatures,
  features,
  refetch,
  profileImageUrl,
}) => {
  const history = useHistory();
  const classes = useRowStyles();
  const permissions = useSelector(({ auth }: RootState) => auth.permissions);

  const masqueradeUser = () => {
    dispatch.masquerade.masqueradeUser({
      id: practitionerId,
      callback: Practitioners.useMasqueradeCallback(history),
    });

    return () => {
      dispatch.masquerade.setReset();
    };
  };

  const [canCreatePractitioner, setCanCreatePractitioner] = useState<boolean>(
    hasAccessTo(CREATE_PRACTITIONER, permissions)
  );
  const [canReadPractitionerLogin, setCanReadPractitionerLogin] = useState<boolean>(
    hasAccessTo(READ_PRACTITIONER_LOGIN, permissions)
  );

  useEffect(() => {
    setCanCreatePractitioner(hasAccessTo(CREATE_PRACTITIONER, permissions));
    setCanReadPractitionerLogin(hasAccessTo(READ_PRACTITIONER_LOGIN, permissions));
  }, [permissions]);

  return (
    <>
      <TableRow data-cy="row" className={classes.root}>
        <TableCellCustom data-cy="fullName">
          <Box display="flex" gridGap="1rem" alignItems="center" justifyContent="start">
            <Avatar src={profileImageUrl} imgProps={{ loading: 'lazy' }} />
            <Box>{fullName}</Box>
          </Box>
        </TableCellCustom>

        <TableCellCustom>{practitionerId}</TableCellCustom>

        <TableCellCustom data-cy="offering">{role && <PractitionerBadge role={role.name} />}</TableCellCustom>

        <TableCellCustom data-cy="licenses">{licenses}</TableCellCustom>

        <TableCellCustom data-cy="email" className={classes.email}>
          {email}
        </TableCellCustom>

        <TableCellCustom data-cy="features" className={classes.email}>
          {canCreatePractitioner && (
            <EditPractitionerFeaturesColumn
              role={role?.name}
              features={features}
              enabledFeatures={enabledFeatures}
              id={practitionerId}
              refetch={refetch}
              userGroupId={userGroupId}
            />
          )}
        </TableCellCustom>

        <TableCell>
          <Box display="flex" gridGap="1rem" justifyContent="flex-end">
            {canReadPractitionerLogin && (
              <EHRButton
                dataCy="loginBtn"
                style={{ width: 'max-content' }}
                color="text"
                variant="outlined"
                onClick={() => masqueradeUser()}
                text="Log in"
              />
            )}
            {canCreatePractitioner && (
              <EHRButton
                dataCy="editBtn"
                color="text"
                variant="outlined"
                href={EDIT_PRACTITIONER(String(practitionerId))}
                text="Edit"
              />
            )}
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

export default PractitionerRow;
