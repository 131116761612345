import * as yup from 'yup';
import { FIRST_NAME, LAST_NAME, PHONE, VALID_PHONE, VALID_PHONE_REGEX } from 'src/constants/schemaForm.constants';

export const schemaSignUp = yup.object().shape({
  firstName: yup.string().required(FIRST_NAME),
  lastName: yup.string().required(LAST_NAME),
  phone: yup.string().trim().required(PHONE).matches(VALID_PHONE_REGEX, VALID_PHONE),
  email: yup.string().when('$invitationCodeEmail', {
    is: (invitationCodeEmail: string) => !invitationCodeEmail,
    then: yup.string().email().required('Email is required'),
    otherwise: yup.string().email(),
  }),
  termsOfService: yup
    .boolean()
    .oneOf([true], 'Terms of Service must be accepted')
    .required('Terms of Service is required'),
});
