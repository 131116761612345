import { yupResolver } from '@hookform/resolvers';
import { Avatar, Box, Divider, FormHelperText, makeStyles } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { EHRButton, EHRModal, EHRTypography } from 'src/components/ui/v1';
import EHRTimePickerSelector from 'src/components/ui/v1/EHRTimePickerSelector';
import {
  useCreateOverrideHoursMutation,
  useDeleteOverrideHoursMutation,
} from 'src/hooks/medspaCalendar/useOverrideHours';
import { IMedspaLocation } from 'src/interfaces/ILocation';
import { IPractitioner } from 'src/interfaces/IPractitioner';
import { ICreateOverrideHoursParams, IWorkingHour } from 'src/services/medspaAdmins/MedspaCalendar';
import * as yup from 'yup';

const overrideHoursSchema = yup.object().shape({
  practitionerId: yup.number().required('You must select a provider'),
  medspaLocationId: yup.number().required('You must select a medspa location'),
  startDate: yup.string().required('Pick a start time'),
  endDate: yup.string().required('Pick an end time'),
});

const useStyles = makeStyles(() => ({
  buttonsContainer: {
    padding: '2rem 2rem 0',
    display: 'flex',
    justifyContent: 'flex-end',
    gridGap: '1rem',
  },
  modalBody: {
    padding: '2rem 0',
  },
  modalContent: {
    minWidth: '530px',
    padding: '0 2rem 2rem',
  },
}));

interface OverrideHoursModalProps {
  provider: IPractitioner | null;
  onClose: () => void;
  open: boolean;
  date: Date;
  selectedMedspaLocation: IMedspaLocation | undefined;
  existingOverrideHour: IWorkingHour | null;
}

const OverrideHoursModal = (props: OverrideHoursModalProps) => {
  const classes = useStyles();
  const { open, onClose, provider, date, selectedMedspaLocation, existingOverrideHour } = props;
  const { mutate: mutateCreateOverrideHours, isLoading: isCreatingOverrideHours } = useCreateOverrideHoursMutation(() =>
    onClose()
  );
  const { mutate: mutateDeleteOverrideHours, isLoading: isDeletingOverrideHours } = useDeleteOverrideHoursMutation(() =>
    onClose()
  );

  const isMutating = isCreatingOverrideHours || isDeletingOverrideHours;

  const overrideHoursDefaultValues = {
    practitionerId: provider?.id,
    medspaLocationId: selectedMedspaLocation?.id,
    startDate: '',
    endDate: '',
  };

  const { handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(overrideHoursSchema),
    defaultValues: overrideHoursDefaultValues,
  });

  const onSubmit = (data: FieldValues) => {
    if (!data) {
      return;
    }

    const mutationParams: ICreateOverrideHoursParams = {
      practitionerId: data.practitionerId,
      medspaLocationId: data.medspaLocationId,
      startDate: moment(data.startDate).format('YYYY-MM-DDTHH:mm'),
      endDate: moment(data.endDate).format('YYYY-MM-DDTHH:mm'),
    };

    mutateCreateOverrideHours(mutationParams);
  };

  const handleDelete = () => {
    if (!existingOverrideHour) {
      return;
    }
    mutateDeleteOverrideHours({ overrideWorkingHourId: existingOverrideHour.id });
  };

  useEffect(() => {
    if (!provider) {
      return;
    }
    setValue('practitionerId', provider.id);
  }, [provider]);

  useEffect(() => {
    if (!selectedMedspaLocation) {
      return;
    }
    setValue('medspaLocationId', selectedMedspaLocation.id);
  }, [selectedMedspaLocation]);

  useEffect(() => {
    if (existingOverrideHour) {
      setValue(
        'startDate',
        moment(`${moment(existingOverrideHour.date).format('YYYY-MM-DD')} ${existingOverrideHour.start}`).toISOString()
      );
      setValue(
        'endDate',
        moment(`${moment(existingOverrideHour.date).format('YYYY-MM-DD')} ${existingOverrideHour.end}`).toISOString()
      );
    }
  }, [existingOverrideHour]);

  if (!provider) {
    return <></>;
  }

  return (
    <EHRModal dataCy="modal-new-provider-override-hours" open={open} onClose={onClose}>
      <Box className={classes.modalBody}>
        <Box className={classes.modalContent} display="flex" gridGap="2rem" flexDirection="column">
          <Box>
            <EHRTypography dataCy="label-practitioner-name" variant="h5">
              Override hours for
            </EHRTypography>
          </Box>

          <Box display="flex" gridGap="1rem" alignItems="center">
            <Box>
              <Avatar src={provider.profileImageUrl} />
            </Box>
            <EHRTypography dataCy="label-practitioner-name" variant="h5">
              {provider.firstName} {provider.lastName}
            </EHRTypography>
          </Box>

          <Box width="100%" maxWidth="40rem">
            <EHRTypography variant="body2" dataCy="text-override-hours-description">
              These changes apply only to this date. To update weekly recurring hours, please visit Calendar
              Configuration in the Scheduling tab.
            </EHRTypography>
          </Box>

          <Box>Set new override hours</Box>

          <Box display="flex" flexDirection="row" alignItems="center" height="2.5rem">
            <Box flex={0.25} height="100%" lineHeight="3rem">
              <Box>{moment(date).format('dddd')}</Box>
              <FormHelperText error>&nbsp;</FormHelperText>
            </Box>
            <Box display="flex" flexDirection="row" flex={1} gridGap="1rem" height="100%" lineHeight="2.5rem">
              <Box flex={0.5}>
                <EHRTimePickerSelector
                  dataCy="input-override-start"
                  containerFloating
                  onChange={(e) => {
                    const dateString = `${moment(date).format('YYYY-MM-DD')} ${e.target.value}`;
                    const startDate = moment(new Date(dateString)).toISOString();
                    setValue('startDate', startDate);
                  }}
                  value={existingOverrideHour?.start}
                  error={!!errors.startDate}
                  helperText={errors.startDate?.message || ' '}
                  disabled={isMutating}
                />
              </Box>
              <Box alignContent="center">
                <Box>to</Box>
                <FormHelperText error>&nbsp;</FormHelperText>
              </Box>
              <Box flex={0.5}>
                <EHRTimePickerSelector
                  dataCy="input-override-end"
                  containerFloating
                  onChange={(e) => {
                    const dateString = `${moment(date).format('YYYY-MM-DD')} ${e.target.value}`;
                    const endDate = moment(new Date(dateString)).toISOString();
                    setValue('endDate', endDate);
                  }}
                  value={existingOverrideHour?.end}
                  error={!!errors.endDate}
                  helperText={errors.endDate?.message || ' '}
                  disabled={isMutating}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Divider />

        <Box className={classes.buttonsContainer}>
          <EHRButton
            dataCy="btn-cancel-override-hour"
            color="default"
            onClick={onClose}
            disabled={isMutating}
            text="Cancel"
          />
          {existingOverrideHour && (
            <EHRButton
              dataCy="btn-delete-override-hour"
              color={isMutating ? 'default' : 'danger'}
              variant="outlined"
              onClick={handleDelete}
              disabled={isMutating}
              text="Delete"
            />
          )}
          <EHRButton
            dataCy="btn-save-override-hour"
            color="primary"
            onClick={handleSubmit(onSubmit)}
            disabled={isMutating}
            text="Save"
          />
        </Box>
      </Box>
    </EHRModal>
  );
};

export default OverrideHoursModal;
