import { AxiosError } from 'axios';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import { CURRENT_USER_GROUP, MEDSPA_ADMIN_ACCOUNT_SETTINGS, MEDSPA_ADMIN_INFO } from 'src/constants/reactQuery.keys';
import {
  CommercialShippingAddress,
  IAccountSettings,
  MedicalDirectorInfo,
  MedicalLicenseDetails,
} from 'src/interfaces/onboarding.interface';
import { dispatch } from 'src/rematch';
import AccountSettings from 'src/services/onboarding/AccountSettings';
import compile from 'src/utils/toastMessagesCompiler';

const useAccountSettings = (enabled = true) =>
  useQuery([MEDSPA_ADMIN_ACCOUNT_SETTINGS], (): Promise<IAccountSettings> => AccountSettings.getAccountSettings(), {
    enabled,
    retry: false,
    refetchOnWindowFocus: false,
  });

// Headshot queries

const useCreateHeadshot = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ headshot }: { headshot: string }) => AccountSettings.createAccountSettings({ profileImage: headshot }),
    {
      onSuccess: async (): Promise<void> => {
        await queryClient.invalidateQueries({ queryKey: [MEDSPA_ADMIN_ACCOUNT_SETTINGS] });
      },
    }
  );
};

// Photo ID queries

const useCreatePhotoID = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ photoId }: { photoId: string }) => AccountSettings.createAccountSettings({ governmentIssuePhotoId: photoId }),
    {
      onSuccess: async (): Promise<void> => {
        await queryClient.invalidateQueries({ queryKey: [MEDSPA_ADMIN_ACCOUNT_SETTINGS] });
      },
    }
  );
};
// Licenses queries

const useCreateLicenses = () => {
  const queryClient = useQueryClient();

  return useMutation(({ licenses }: { licenses: string }) => AccountSettings.createAccountSettings({ licenses }), {
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [MEDSPA_ADMIN_ACCOUNT_SETTINGS] });
    },
  });
};
// Positions queries

const useCreatePositions = () => {
  const queryClient = useQueryClient();
  return useMutation(({ position }: { position: string }) => AccountSettings.createAccountSettings({ position }), {
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [MEDSPA_ADMIN_ACCOUNT_SETTINGS] });
    },
  });
};

// Positions queries

const useAcknowledgeInventoryDisclaimer = () => {
  const queryClient = useQueryClient();
  return useMutation(() => AccountSettings.createAccountSettings({ hasAcknowledgedInventoryDisclaimer: true }), {
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [MEDSPA_ADMIN_ACCOUNT_SETTINGS] });
    },
    onError: (error: AxiosError | unknown) => {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        message: compile('generic.error_message', {
          action: 'saving',
          element: `acknowledgement ${(error as AxiosError).response?.data?.errors ?? error}`,
        }),
      });
    },
  });
};

// ProviderEntity queries

const useCreateProviderEntity = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ entityName }: { entityName: string }) => AccountSettings.createAccountSettings({ entityName }),
    {
      onSuccess: async (): Promise<void> => {
        await queryClient.invalidateQueries({ queryKey: [MEDSPA_ADMIN_ACCOUNT_SETTINGS] });
      },
    }
  );
};

// MedicalDirectorInfo queries
const useCreateMedicalDirectorInfo = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      medicalDirectorInfo,
      medicalLicenseDetails,
    }: {
      medicalDirectorInfo: MedicalDirectorInfo;
      medicalLicenseDetails?: MedicalLicenseDetails;
    }) => AccountSettings.createAccountSettings({ medicalDirector: medicalDirectorInfo, medicalLicenseDetails }),
    {
      onSuccess: async (): Promise<void> => {
        await queryClient.invalidateQueries({ queryKey: [MEDSPA_ADMIN_ACCOUNT_SETTINGS] });
      },
    }
  );
};

// commercialShippingAddress queries

const useCreateShippingAddress = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (shippingAddress: CommercialShippingAddress) =>
      AccountSettings.createAccountSettings({
        commercialShippingAddress: shippingAddress,
      }),
    {
      onSuccess: async (): Promise<void> => {
        await queryClient.invalidateQueries({ queryKey: [MEDSPA_ADMIN_ACCOUNT_SETTINGS] });
      },
    }
  );
};
// Signature queries

const useCreateSignature = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ signature }: { signature: string }) => AccountSettings.createAccountSettings({ signatureImage: signature }),
    {
      onSuccess: async (): Promise<void> => {
        await queryClient.invalidateQueries({ queryKey: [MEDSPA_ADMIN_ACCOUNT_SETTINGS] });
      },
    }
  );
};

const useUpsertShippingAddress = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      shippingAddress,
      commercialShippingAddressId,
    }: {
      shippingAddress: CommercialShippingAddress;
      commercialShippingAddressId?: number;
    }) => AccountSettings.upsertCommercialShippingAddress(commercialShippingAddressId, shippingAddress),
    {
      onSuccess: async (): Promise<void> => {
        await queryClient.invalidateQueries({ queryKey: [CURRENT_USER_GROUP] });
        await queryClient.invalidateQueries({ queryKey: [MEDSPA_ADMIN_INFO] });
      },
    }
  );
};

const useRemoveCommercialShippingAddress = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (commercialShippingAddressId: number) =>
      AccountSettings.deleteCommercialShippingAddress(commercialShippingAddressId),
    {
      onSuccess: async (): Promise<void> => {
        await queryClient.invalidateQueries({ queryKey: [CURRENT_USER_GROUP] });
        await queryClient.invalidateQueries({ queryKey: [MEDSPA_ADMIN_INFO] });
      },
    }
  );
};

export {
  useAccountSettings,
  useCreateHeadshot,
  useCreatePhotoID,
  useCreateLicenses,
  useCreatePositions,
  useCreateProviderEntity,
  useCreateMedicalDirectorInfo,
  useCreateShippingAddress,
  useCreateSignature,
  useAcknowledgeInventoryDisclaimer,
  useUpsertShippingAddress,
  useRemoveCommercialShippingAddress,
};
