import React, { useEffect, useState } from 'react';
import { Box, Button, FormHelperText, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { snakeCase } from 'lodash';
import DragAndDropComponent from 'src/components/DragAndDrop';
import { IPractitioner } from 'src/interfaces/IPractitioner';
import { ReactComponent as UploadProfileIcon } from 'src/assets/images/upload-profile-image.svg';
import { IMedspaAdminListEntry } from 'src/interfaces/IMedspaAdminList';
import {
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  LICENSE,
  POSITION,
  PHONE,
  PROFILE_IMAGE,
  SIGNATURE_IMAGE,
  VALID_EMAIL,
  VALID_PHONE,
  VALID_PHONE_REGEX,
} from 'src/constants/schemaForm.constants';
import { useUpdateMedspaAdmin } from 'src/hooks/queries/useMedspaAdmins';
import { useEditMedspaProviderMutation } from 'src/hooks/mutations/useEditMedspaProvider';
import { EHRButton, EHRCRUDModal, EHRCheckbox, EHRTextField, EHRTypography } from 'src/components/ui/v1';
import CredentialsDropdown from 'src/components/common/CredentialsDropdown';
import { useStyles } from './MedspaModal.styles';

export const medspaUserSchema = yup.object().shape({
  firstName: yup.string().required(FIRST_NAME),
  lastName: yup.string().required(LAST_NAME),
  email: yup.string().required(EMAIL).email(VALID_EMAIL),
  phone: yup.string().required(PHONE).matches(VALID_PHONE_REGEX, VALID_PHONE),
  licenses: yup.string().when('isOperatingProvider', (value: any) => {
    if (value) {
      return yup.string().required(LICENSE).nullable();
    }
    return yup.string().nullable();
  }),
  signatureImage: yup.string().when('isOperatingProvider', (value: any) => {
    if (value) {
      return yup.string().required(SIGNATURE_IMAGE).nullable();
    }
    return yup.string().nullable();
  }),
  profileImage: yup.string().required(PROFILE_IMAGE),
  position: yup.string().when('isOperatingProvider', (value: any) => {
    if (value) {
      return yup.string().required(POSITION).nullable();
    }
    return yup.string().nullable();
  }),
});

export const EditMedspaUserModal = ({
  open,
  onClose,
  user,
  userType,
  operatingUser,
}: {
  open: boolean;
  onClose: () => void;
  user?: IPractitioner | IMedspaAdminListEntry;
  userType?: string;
  operatingUser?: IPractitioner | undefined;
}) => {
  const classes = useStyles();
  const [profilePreview, setProfilePreview] = useState<string>('');
  const [signaturePreview, setSignaturePreview] = useState<string>('');
  const [profileImageFile, setProfileImageFile] = useState<File>();
  const [signatureImageFile, setSignatureImageFile] = useState<File>();
  const [isOperatingProvider, setIsOperatingProvider] = useState<boolean>(false);
  const [userActive, setUserActive] = useState<boolean>(true);
  const isAdmin = userType === 'Admin';
  const { mutateAsync: updateMedspaAdmin, isLoading: isUpdatingAdmin } = useUpdateMedspaAdmin();
  const { mutateAsync: updateMedspaProvider, isLoading: isUpdatingProvider } = useEditMedspaProviderMutation();
  const isUpdating = isUpdatingAdmin || isUpdatingProvider;

  const { control, errors, setValue, handleSubmit, getValues } = useForm({
    resolver: yupResolver(medspaUserSchema),
    defaultValues: {
      id: 0,
      active: true,
      firstName: '',
      lastName: '',
      profileImage: '',
      signatureImage: '',
      email: '',
      phone: '',
      position: '',
      licenses: '',
      isOperatingProvider: false,
    },
  });

  useEffect(() => {
    if (user && user.id !== getValues('id')) {
      setValue('firstName', user.firstName);
      setValue('lastName', user.lastName);
      setValue('email', user.email);
      setValue('phone', user.phone);
      setValue('licenses', (isAdmin ? operatingUser?.licenses : (user as IPractitioner).licenses) || '');
      setValue(
        'position',
        (isAdmin
          ? operatingUser?.practitionerInformation?.position
          : (user as IPractitioner).practitionerInformation?.position) || ''
      );
      setValue(
        'signatureImage',
        isAdmin ? operatingUser?.signatureImageUrl : (user as IPractitioner).signatureImageUrl
      );
      setValue('profileImage', user.profileImageUrl || '');
      setValue('active', isAdmin ? (user as IMedspaAdminListEntry).active : !(user as IPractitioner).inactive);
      setValue('isOperatingProvider', !!operatingUser || !isAdmin);
      setUserActive(isAdmin ? (user as IMedspaAdminListEntry).active : !(user as IPractitioner).inactive);
      setIsOperatingProvider(!!operatingUser);
      setSignaturePreview(
        isAdmin ? operatingUser?.signatureImageUrl || '' : (user as IPractitioner).signatureImageUrl || ''
      );
      setProfilePreview(user.profileImageUrl || '');
    }
  }, [user, isAdmin, operatingUser]);
  const handleClose = () => {
    onClose();
  };

  const handleProfilePicChange = (fileData: File[]) => {
    const file = fileData?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePreview((reader.result || '').toString());
        // @ts-ignore
        setValue('profileImage', file, { shouldValidate: true });
        setProfileImageFile(file);
      };
      reader.readAsDataURL(file);
    } else {
      setValue('profileImage', '', { shouldValidate: true });
    }
  };

  const handleSignatureChange = (fileData: File[]) => {
    const file = fileData?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSignaturePreview((reader.result || '').toString());
        // @ts-ignore
        setValue('signatureImage', file, { shouldValidate: true });
        setSignatureImageFile(file);
      };
      reader.readAsDataURL(file);
    } else {
      setValue('signatureImage', '', { shouldValidate: true });
    }
  };

  const onSubmit = async (formData: any) => {
    const data = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: formData.phone,
      licenses: formData.licenses,
      position: formData.position,
    };
    const updateData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      updateData.append(snakeCase(key), value);
    });

    if (isAdmin) {
      // @ts-ignore
      updateData.append('active', userActive);
    } else {
      // @ts-ignore
      updateData.append('inactive', !userActive);
    }

    if (isAdmin && isOperatingProvider && !operatingUser) {
      // @ts-ignore
      updateData.append('provision_provider_account', true);
    }

    if (signatureImageFile) {
      updateData.append('signature_image', signatureImageFile);
    }
    if (profileImageFile) {
      updateData.append('profile_image', profileImageFile);
    }

    if (user) {
      if (isAdmin) {
        await updateMedspaAdmin({
          userId: user.id,
          params: updateData,
          // @ts-ignore
          userGroupId: user.userGroupId,
        });
      } else {
        await updateMedspaProvider({
          userId: user.id,
          params: updateData,
          // @ts-ignore
          userGroupId: user.userGroupId,
        });
      }
    }
    handleClose();
  };

  return (
    <EHRCRUDModal dataCy="modal-edit-medspa-user" open={open} onClose={handleClose}>
      <EHRCRUDModal.Title
        dataCy="modal-new-medspa-user-modal-title"
        title={`Edit ${isAdmin ? 'Admin' : 'Provider'}`}
        handleClose={handleClose}
      />

      <EHRCRUDModal.Body dataCy="modal-new-medspa-modal-body">
        <Box display="flex" justifyContent="space-between" flexDirection="column" gridGap="0.25rem" width="100%">
          <Box
            className={classNames(classes.headShotContainer, errors?.profileImage ? 'ehrUploadContainerError' : null)}
          >
            <EHRTypography
              dataCy="label-upload-headsot"
              variant="label"
              error={!!errors?.profileImage}
              style={{ marginBottom: '0.5rem' }}
            >
              Upload Headshot
            </EHRTypography>
            <input
              accept="image/*"
              id="profile-photo-upload"
              type="file"
              name="profilePicture"
              style={{ display: 'none' }}
            />
            <label htmlFor="profile-photo-upload" className={classes.uploadProfileImageContainer}>
              {profilePreview ? (
                <Box display="flex" alignItems="center">
                  <img src={profilePreview} alt="Profile preview" className={classes.profileImage} />
                </Box>
              ) : (
                <UploadProfileIcon className={classes.profileImage} />
              )}
              <DragAndDropComponent
                hideDocumentIcon
                height="80px"
                hideRemoveFile
                acceptedFileType="image/png,image/jpg,image/jpeg"
                onFileChange={handleProfilePicChange}
                error={!!errors?.profileImage}
              />
            </label>
            <FormHelperText error>{errors?.profileImage?.message || ' '}</FormHelperText>
          </Box>

          <Box display="flex" flexDirection="row" gridGap="1rem">
            <Box flex={1}>
              <Controller
                control={control}
                name="firstName"
                rules={{ required: 'First name is required' }}
                render={({ onChange, value }) => (
                  <EHRTextField
                    dataCy="input-firstName"
                    data-cy="firstName"
                    name="firstName"
                    onChange={onChange}
                    value={value}
                    label="First Name"
                    error={!!errors?.firstName}
                    helperText={errors?.firstName?.message || ' '}
                  />
                )}
              />
            </Box>

            <Box flex={1}>
              <Controller
                control={control}
                name="lastName"
                rules={{ required: 'Last name is required' }}
                render={({ onChange, value }) => (
                  <EHRTextField
                    dataCy="input-lastName"
                    data-cy="lastName"
                    name="lastName"
                    onChange={onChange}
                    value={value}
                    label="Last Name"
                    error={!!errors?.lastName}
                    helperText={errors?.lastName?.message || ' '}
                  />
                )}
              />
            </Box>
          </Box>

          <Box>
            <Controller
              control={control}
              name="email"
              rules={{ required: 'Email is required' }}
              render={({ onChange, value }) => (
                <EHRTextField
                  dataCy="input-email"
                  data-cy="email"
                  name="email"
                  onChange={onChange}
                  value={value}
                  label="Email"
                  error={!!errors?.email}
                  helperText={errors?.email?.message || ' '}
                />
              )}
            />
          </Box>

          <Box>
            <Controller
              control={control}
              name="phone"
              rules={{ required: 'Phone number is required' }}
              render={({ onChange, value }) => (
                <EHRTextField
                  dataCy="input-phone"
                  data-cy="phone"
                  name="phone"
                  onChange={onChange}
                  value={value}
                  label="Phone Number"
                  error={!!errors?.phone}
                  helperText={errors?.phone?.message || ' '}
                />
              )}
            />
          </Box>

          <Box className={classes.medspaInfoInput}>
            <EHRTypography dataCy="label-upload-signature" variant="label">
              Status
            </EHRTypography>
            <Box className={classes.medspaActiveStatusContainer}>
              <Button
                className={userActive ? classes.medspaActiveButton : classes.medspaInactiveButton}
                type="button"
                onClick={() => setUserActive(true)}
              >
                Active
              </Button>
              <Button
                className={userActive ? classes.medspaInactiveButton : classes.medspaActiveButton}
                type="button"
                onClick={() => setUserActive(false)}
              >
                Inactive
              </Button>
            </Box>
          </Box>

          {isAdmin && (
            <Box className={classes.operatingProviderContainer}>
              <Controller
                name="isOperatingProvider"
                control={control}
                defaultValue={false}
                render={() => (
                  <EHRCheckbox
                    dataCy="label-is-operating-provider"
                    label="Operating Provider"
                    onChange={(e) => {
                      setIsOperatingProvider(e.target.checked);
                      setValue('isOperatingProvider', e.target.checked);
                    }}
                    checked={isOperatingProvider}
                    className={classes.operatingProviderCheckbox}
                    disabled={!!operatingUser}
                  >
                    <Typography variant="body2" className={classes.operatingProviderSubtext}>
                      If this is checked the MedSpa Admin account will be created with a Provider dashboard experienced
                      linked to it
                    </Typography>
                  </EHRCheckbox>
                )}
              />
            </Box>
          )}
          {(!isAdmin || isOperatingProvider) && (
            <>
              <Box display="flex" flexDirection="row" gridGap="1rem">
                <Box flex={1}>
                  <Controller
                    control={control}
                    name="licenses"
                    render={({ onChange, value }) => (
                      <CredentialsDropdown value={value} onChange={onChange} hasErrors={!!errors?.licenses} />
                    )}
                  />
                </Box>
              </Box>

              <Box flex={1}>
                <Controller
                  control={control}
                  name="position"
                  render={({ onChange, value }) => (
                    <EHRTextField
                      dataCy="input-position"
                      name="position"
                      onChange={onChange}
                      value={value}
                      label="Position"
                      error={!!errors?.position}
                      helperText={errors?.position?.message || ' '}
                    />
                  )}
                />
              </Box>

              <Box
                className={classNames(
                  classes.signatureImageContainer,
                  errors?.signatureImage ? 'ehrUploadContainerError' : null
                )}
              >
                <Box className={classes.signatureImageHeader}>
                  <EHRTypography
                    dataCy="label-upload-signature"
                    variant="label"
                    error={!!errors?.signatureImage}
                    style={{ marginBottom: '0.5rem' }}
                  >
                    Upload Signature
                  </EHRTypography>

                  {signaturePreview && (
                    <Button
                      type="button"
                      onClick={() => {
                        setSignaturePreview('');
                        setValue('signatureImage', undefined, { shouldValidate: true });
                      }}
                      className={classes.signatureUpdate}
                    >
                      Update
                    </Button>
                  )}
                </Box>

                {signaturePreview ? (
                  <img src={signaturePreview} alt="Signature preview" className={classes.signatureImage} />
                ) : (
                  <DragAndDropComponent
                    hideDocumentIcon
                    height="80px"
                    hideRemoveFile
                    acceptedFileType="image/png,image/jpg,image/jpeg"
                    onFileChange={handleSignatureChange}
                    error={!!errors?.signatureImage}
                  />
                )}
                <FormHelperText error>{errors?.signatureImage?.message || ' '}</FormHelperText>
              </Box>
            </>
          )}
        </Box>
      </EHRCRUDModal.Body>

      <EHRCRUDModal.Footer dataCy="modal-footer-new-medspa">
        <Box display="flex" width="100%">
          <EHRButton
            dataCy="btn-add-modal-new-medspa-user"
            onClick={handleSubmit(onSubmit)}
            disabled={isUpdating}
            color="primary"
            text={isUpdating ? 'Updating...' : 'Update'}
            fullWidth
          />
        </Box>
      </EHRCRUDModal.Footer>
    </EHRCRUDModal>
  );
};
