import { Box } from '@material-ui/core';
import React from 'react';
import { useStyles } from 'src/pages/Practitioners/GFEManagement.styles';
import { Options } from './Options';

export const GFEAutoRoutingCards = () => {
  const classes = useStyles();

  return (
    <Box className={classes.cardsContainer}>
      <span className={classes.cardsTitle}>Card to be charged</span>

      <Box className={classes.cardsWrapper}>
        <Options />
      </Box>
    </Box>
  );
};
