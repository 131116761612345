import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@material-ui/core';
import { StepStatus } from 'src/interfaces/onboarding.interface';
import { STEP_COMPLETED, STEP_EMPTY, STEP_LOADING } from 'src/components/StepsTracker';
import { useCreateLicenses } from 'src/hooks/queries/useAccountSettings';
import { useUpdateMedspaAdminOnboardingProgress } from 'src/hooks/queries/useMedspaAdmins';
import { ONBOARDING_STEPS } from 'src/pages/MedspaAdmin/Onboarding/constants';
import CredentialsDropdown from 'src/components/common/CredentialsDropdown';

interface LicensesProps {
  stepId: number;
  onStepUpdate: (stepId: number, status: StepStatus) => void;
  license?: string;
  progressPercentage?: number;
  disabled?: boolean;
}

const Licenses = ({ stepId, onStepUpdate, license, progressPercentage, disabled = false }: LicensesProps) => {
  const [value, setValue] = useState<string>('');
  const prevState = useRef(license);

  const { mutateAsync: createLicenses, isLoading, isError, isSuccess } = useCreateLicenses();
  const { mutateAsync: updateProgress } = useUpdateMedspaAdminOnboardingProgress();

  const handleLicenses = (licenses: string) => {
    setValue(licenses);
    createLicenses({ licenses });
  };

  useEffect(() => {
    if (license) {
      setValue(license);
      onStepUpdate(stepId, STEP_COMPLETED);
    }
  }, [license, isSuccess]);

  useEffect(() => {
    if (prevState.current === null && license) {
      updateProgress({
        stepName: ONBOARDING_STEPS.ACCOUNT_SETTINGS_NAME,
        percentage: progressPercentage as number,
      });
    }

    prevState.current = license;
  }, [license]);

  useEffect(() => {
    if (isLoading) {
      onStepUpdate(stepId, STEP_LOADING);
    } else if (isError) {
      onStepUpdate(stepId, STEP_EMPTY);
    }
  }, [isLoading, isError]);

  return (
    <Box>
      <CredentialsDropdown
        onChange={(e: string | number) => handleLicenses(e.toString())}
        value={value}
        hasErrors={isError}
        disabled={disabled}
      />
    </Box>
  );
};

export default Licenses;
