import React from 'react';
import { Box, CircularProgress, Typography, makeStyles } from '@material-ui/core';
import { InventoryOrderStatus } from 'src/interfaces/IInventoryOrder';
import classNames from 'classnames';

export const ResultCountBadge = ({
  isLoading,
  total,
  status,
}: {
  isLoading?: boolean;
  total?: number;
  status: InventoryOrderStatus;
}) => {
  const classes = useStyle();
  return isLoading ? (
    <Box className={classes.box}>
      <CircularProgress className={classes.progress} size={20} />
    </Box>
  ) : (
    <Box className={classNames(classes.box, classes.coloredBox)} data-status={status}>
      <Typography>{total}</Typography>
    </Box>
  );
};

const useStyle = makeStyles(() => ({
  box: {
    display: 'flex',
    width: '26px',
    height: '26px',
    margin: 'auto 5px auto 0',
    '& > p': {
      margin: 'auto',
      fontSize: '14px',
    },
  },
  coloredBox: {
    color: 'rgb(209,241,189)',
    borderColor: 'rgb(209,241,189)',
    backgroundColor: 'rgb(49,79,31)',
    border: '1px solid',
    borderRadius: '6px',
    '&[data-status="approved"]': {
      backgroundColor: 'rgb(189,235,241)',
      color: 'rgb(21,83,88)',
      borderColor: 'rgb(21,83,88)',
    },
    '&[data-status="requested"]': {
      color: 'rgb(83,61,110)',
      backgroundColor: 'rgb(230,211,255)',
      borderColor: 'rgb(83,61,110)',
    },
    '&[data-status="placed"] , &[data-status="partially_placed"] , &[data-status="paid"]': {
      color: 'rgb(49,79,31)',
      backgroundColor: 'rgb(209,241,189)',
      borderColor: 'rgb(49,79,31)',
    },
    '&[data-status="rejected"] , &[data-status="canceled"] , &[data-status="failed"]': {
      color: 'rgb(109,59,59)',
      backgroundColor: 'rgb(255,203,203)',
      borderColor: 'rgb(109,59,59)',
    },
  },
  progress: { margin: 'auto' },
}));

export default ResultCountBadge;
