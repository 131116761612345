import { Box, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useStyles } from './OrderConfirm.styles';

export const OrderConfirmPanel = ({ title, children }: { title: string; children: ReactElement }): ReactElement => {
  const classes = useStyles();

  return (
    <Box className={classes.panel}>
      <Box className={classes.panelTitle}>
        <Typography variant="h6">{title}</Typography>
      </Box>
      {children}
    </Box>
  );
};

export default OrderConfirmPanel;
