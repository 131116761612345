import { Models } from '@rematch/core';
import { auth } from './auth';
import { snackbar } from './snackbar.model';
import { patient } from './patient.model';
import { practitioner } from './practitioner.model';
import { todo } from './todo.model';
import { square } from './square.model';
import { serviceVisitNotes } from './service.notes.model';
import newServiceVisit from './newServiceVisit.model';
import { coverImage } from './cover.image.model';
import { chatModel } from './chat.model';
import { inventoryReconciliation } from './inventory.model';
import { reconciliationReport } from './reconciliationReport.model';
import { purchaseOrders } from './purchaseOrder.model';
import { supplyReceipts } from './supplyReceipts.model';
import { newSupplyReceipt } from './newSupplyReceipt.model';
import { scanner } from './scanner.model';
import { viewReport } from './viewReport.model';
import { inventorySupplyReceipt } from './inventorySupplyReceipts.model';
import { inventoryPractitioner } from './inventory.practitioner.model';
import { productIdentifiers } from './productIdentifiers.model';
import { inventoryDefault } from './inventoryDefault.model';
import { newPurchaseOrder } from './newPurchaseOrder.model';
import { common } from './common.model';
import { assets } from './asset.model';
import { fullServiceHistory } from './fullServiceHistory.model';
import { masquerade } from './masquerade.model';
import { schedulers } from './schedulers.model';
import { productboard } from './productboard.model';
import { overview } from './overview.model';
import { referrals } from './referrals.model';
import { serviceCredits } from './serviceCredits.model';
import { patientPointCertificates } from './patientPointCertificates.model';
import { loyaltyPrograms } from './loyaltyPrograms.model';
import { transaction } from './transaction.model';
import { loyaltyProgramsAccount } from './loyaltyProgramsAccount.model';
import { annotations } from './annotations.model';
import { physician } from './physician.model';
import { newPhotoRequest } from './newPhotoRequest.model';
import { page } from './page.model';
import { health } from './health.actions';

export interface RootModel extends Models<RootModel> {
  auth: typeof auth;
  snackbar: typeof snackbar;
  scanner: typeof scanner;
  patient: typeof patient;
  practitioner: typeof practitioner;
  todo: typeof todo;
  square: typeof square;
  serviceVisitNotes: typeof serviceVisitNotes;
  newServiceVisit: typeof newServiceVisit;
  coverImage: typeof coverImage;
  chatModel: typeof chatModel;
  inventoryReconciliation: typeof inventoryReconciliation;
  reconciliationReport: typeof reconciliationReport;
  supplyReceipts: typeof supplyReceipts;
  newSupplyReceipt: typeof newSupplyReceipt;
  viewReport: typeof viewReport;
  inventorySupplyReceipt: typeof inventorySupplyReceipt;
  inventoryPractitioner: typeof inventoryPractitioner;
  inventoryDefault: typeof inventoryDefault;
  productIdentifiers: typeof productIdentifiers;
  newPurchaseOrder: typeof newPurchaseOrder;
  fullServiceHistory: typeof fullServiceHistory;
  masquerade: typeof masquerade;
  schedulers: typeof schedulers;
  productboard: typeof productboard;
  overview: typeof overview;
  referrals: typeof referrals;
  serviceCredits: typeof serviceCredits;
  patientPointCertificates: typeof patientPointCertificates;
  transaction: typeof transaction;
  loyaltyPrograms: typeof loyaltyPrograms;
  loyaltyProgramsAccount: typeof loyaltyProgramsAccount;
  annotations: typeof annotations;
  physician: typeof physician;
  newPhotoRequest: typeof newPhotoRequest;
  page: typeof page;
  health: typeof health;
}

export const models: RootModel = {
  auth,
  snackbar,
  scanner,
  patient,
  practitioner,
  todo,
  square,
  serviceVisitNotes,
  newServiceVisit,
  coverImage,
  chatModel,
  inventoryReconciliation,
  reconciliationReport,
  purchaseOrders,
  supplyReceipts,
  newSupplyReceipt,
  viewReport,
  inventorySupplyReceipt,
  inventoryPractitioner,
  inventoryDefault,
  productIdentifiers,
  newPurchaseOrder,
  common,
  fullServiceHistory,
  assets,
  masquerade,
  schedulers,
  productboard,
  overview,
  referrals,
  serviceCredits,
  patientPointCertificates,
  transaction,
  loyaltyPrograms,
  loyaltyProgramsAccount,
  annotations,
  physician,
  newPhotoRequest,
  page,
  health,
};
