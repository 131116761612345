import React, { useState } from 'react';

import { useStyle } from 'src/components/DashboardPractitioner/Tabs/OrderingTab/inventoryOrder.styles';
import { AccordionDetails, Typography, Box, IconButton } from '@material-ui/core';
import { formatCurrency } from 'src/utils/formatNumber';
import IconCross from 'src/components/common/IconCross';
import OrderStatus from 'src/components/DashboardPractitioner/Tabs/OrderingTab/OrderStatus';
import {
  SupplierAccordion,
  SupplierAccordionSummary,
} from '../../../../DashboardPractitioner/Tabs/OrderingTab/inventoryOrder.styles';

type Props = {
  status: string;
  name: string;
  price: number;
  productList: string;
  specialOffer: any;
};

export const OrderApprovalSpecialsRow = ({ productList, name, price, status, specialOffer }: Props) => {
  const classes = useStyle();
  const [expanded, setExpanded] = useState(false);

  return (
    <SupplierAccordion expanded={expanded}>
      <SupplierAccordionSummary
        expandIcon={
          <IconButton size="small" onClick={() => setExpanded(!expanded)} data-testid={`order expand specials ${name}`}>
            <IconCross open={expanded} />
          </IconButton>
        }
      >
        <Box
          style={{
            display: 'grid',
            gridTemplateColumns: '5fr 1fr 1fr',
            gap: '10px',
            '& > div': {
              display: 'flex',
            },
            '& span > span': {
              color: '#888',
            },
          }}
          data-testid="order history special"
        >
          <Box display="flex" flexDirection="row">
            <Typography>{specialOffer.supplierName}</Typography>
            <Box fontSize={13} marginLeft={5} component="span" className={classes.chip}>
              Special Offer
            </Box>
          </Box>
          <Box paddingLeft="12px">
            <Typography component="span">
              <span>Total:</span> {formatCurrency(price)}
            </Typography>
          </Box>
          <Box>
            <OrderStatus status={status} />
          </Box>
        </Box>
      </SupplierAccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>
        <Typography>{name}</Typography>
        <Typography>{productList}</Typography>
      </AccordionDetails>
    </SupplierAccordion>
  );
};

export default OrderApprovalSpecialsRow;
