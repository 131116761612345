import React from 'react';
import { useLocation } from 'react-router-dom';
import { RootState } from 'src/rematch';
import { useSelector } from 'react-redux';
import { EHRButton } from 'src/components/ui/v1';
import { copyToClipboard } from 'src/utils/clipboard.utils';
import { usePractitionerInfo } from 'src/hooks/queries/usePractitioners';
import { useMedspaLocationsByRole } from 'src/hooks/queries/medspaAdmins/useMedspaLocations';
import { ADMIN_ROLE, MEDSPA_ADMIN_ROLE, PRACTITIONER_ROLE } from 'src/constants/general.constants';
import { SIGN_UP_ROUTES } from 'src/constants/routes.constants';

const BookingLinkButton = () => {
  const { userType, userId } = useSelector(({ auth }: RootState) => auth);
  const location = useLocation();

  const permittedRoles = [ADMIN_ROLE, MEDSPA_ADMIN_ROLE, PRACTITIONER_ROLE];
  const isRolePermitted = permittedRoles.includes(userType as string);
  const isRoutePermitted =
    location.pathname.startsWith('/medspa-admin/') || location.pathname.startsWith('/medspa-provider/');

  const isValidPractitioner = userType === PRACTITIONER_ROLE;
  const { practitionerInfo } = usePractitionerInfo(Number(userId), undefined, isValidPractitioner);
  const {
    data: { userGroup },
  } = useMedspaLocationsByRole(null, isRolePermitted);

  if (!isRoutePermitted && !isRolePermitted && !userGroup?.active) {
    return null;
  }

  const getClipboardLink = () => {
    if (userType === PRACTITIONER_ROLE && practitionerInfo?.slug) {
      return `${SIGN_UP_ROUTES.booking}/${practitionerInfo.slug}`;
    }
    return `${SIGN_UP_ROUTES.userGroupBooking}/${userGroup.slug}`;
  };

  return (
    <EHRButton
      dataCy="btn-registration-link"
      color="secondary"
      text="Booking Link"
      onClick={() => copyToClipboard(getClipboardLink())}
    />
  );
};

export default BookingLinkButton;
