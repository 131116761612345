import React from 'react';
import { Star as StarIcon, StarOutline } from '@material-ui/icons';
import { range } from 'lodash';
import { MAX_REVIEW_STARS } from 'src/constants/leadLandingPage.constants';
import { ReviewStarAlign, ReviewStarParams } from 'src/interfaces/ILeadLandingPage';
import { Box, Typography } from '@material-ui/core';

export const ReviewStars: React.FC<ReviewStarParams> = ({
  score,
  caption,
  align,
  classes,
}: ReviewStarParams): React.ReactElement => {
  const alignment: ReviewStarAlign = align ?? 'center';

  const filledStars = (score || 0) < MAX_REVIEW_STARS ? score || 0 : MAX_REVIEW_STARS;

  return (
    <Box className={classes?.starReview} data-align={alignment}>
      {range(0, filledStars).map(
        (value: number): React.ReactElement => (
          <StarIcon key={`filledStar${value}`} className={classes?.starIcon} />
        )
      )}
      {range(0, MAX_REVIEW_STARS - filledStars).map(
        (value: number): React.ReactElement => (
          <StarOutline key={`emptyStar${value}`} className={classes?.starIcon} />
        )
      )}
      {!caption ? null : <Typography component="span">{caption}</Typography>}
    </Box>
  );
};
