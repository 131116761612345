import React, { useEffect, useRef } from 'react';
import { Typography } from '@material-ui/core';
import {
  Email as EmailIcon,
  Phone as PhoneIcon,
  LocationOn as LocationOnIcon,
  Note as NoteIcon,
  Star as StarIcon,
} from '@material-ui/icons';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { USER_TYPES } from 'src/constants/general.constants';
import ButtonAhref from '../ButtonAhref';
import { useCardStyles } from './Appointments.styles';
import { hasAccessTo } from '../../../utils/auth.utils';
import { PRACTITIONER_BOOK_APPOINTMENT_UPDATE } from '../../../constants/actions.constants';
import { dispatch } from '../../../rematch';

interface Props {
  appointmentData: any;
  refetch: boolean;
}

const AppointmentsCard = (props: Props) => {
  const cardRef: any = useRef();
  const { userType } = useSelector(({ auth }: any) => auth);
  const { appointmentData, refetch } = props;

  const { permissions } = useSelector(({ auth }: any) => auth);
  const { currentAppointment } = useSelector(({ schedulers }: any) => schedulers);

  const classes = useCardStyles(appointmentData);
  const startDateStr = moment(appointmentData.startDate).format('dddd, MMMM DD, YYYY / hh:mm a');
  const endDateStr = moment(appointmentData.endDate).format('hh:mm a');

  const showPhoneNumber = ['incomplete', 'cleared'].includes(appointmentData.statusId) && appointmentData.phone;

  const getPractitionerLink = () => {
    if (appointmentData.customerId) {
      return `/patient/${appointmentData.customerId}`;
    }
    return '';
  };

  useEffect(() => {
    if (JSON.stringify(appointmentData || {}) !== JSON.stringify(currentAppointment || {})) {
      dispatch.schedulers.setCurrentAppointment(appointmentData);
    }
  }, []);

  useEffect(() => {
    if (refetch) {
      // Close modal if is refetching...
      cardRef?.current?.parentNode?.remove?.();
    }
  }, [refetch]);

  const cardTitle =
    userType === USER_TYPES.ADMINISTRATOR
      ? `[${appointmentData.practitionerName}] ${appointmentData.patient || ''}`
      : appointmentData.patient;

  return (
    <div className={classes.cardContainer} ref={cardRef}>
      <div className={classes.cardContent}>
        <ButtonAhref
          buttonStyle="notStyle"
          className={classes.cardTitle}
          text={cardTitle}
          href={appointmentData.customerId ? `/patient/${appointmentData.customerId}` : null}
        />

        {hasAccessTo(PRACTITIONER_BOOK_APPOINTMENT_UPDATE, permissions) && appointmentData.isFirstAppointment ? (
          <StarIcon style={{ width: '15px' }} />
        ) : null}

        <Typography className={classes.cardSubtitle}>{appointmentData.type}</Typography>

        <p role="presentation" onClick={getPractitionerLink} className={classes.cardDate} data-testid="date-range">
          {startDateStr} - {endDateStr}
        </p>

        {appointmentData?.location && (
          <Typography className={classes.cardLocation}>
            <LocationOnIcon className={classes.cardContactIcon} style={{ background: 'transparent' }} />
            {appointmentData.location}
          </Typography>
        )}

        {appointmentData?.notes && (
          <div className={classes.cardContact}>
            <NoteIcon className={classes.cardContactIcon} />
            Note
            <Typography className={classes.noteText}>{appointmentData.notes}</Typography>
          </div>
        )}

        {appointmentData.email && (
          <Typography className={classes.cardContact}>
            <EmailIcon className={classes.cardContactIcon} />
            {appointmentData.email}
          </Typography>
        )}

        {showPhoneNumber && (
          <Typography className={classes.cardContact}>
            <PhoneIcon className={classes.cardContactIcon} />
            {appointmentData.phone}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default AppointmentsCard;
