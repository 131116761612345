import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  pageContent: {
    width: '100%',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
    },
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));
