import { filterUnusedParams } from 'src/utils/global';
import { ROWS_PER_PAGE } from 'src/constants/clientLead.constants';
import Api from './Api';
import { ILeadUpdateParams, ILeadContactDetails } from '../interfaces/ILeadLandingPage';
import API_ROUTES, { PUBLIC_CONTACT_DETAILS, PUBLIC_LEAD_SUBMIT } from '../constants/apiRoutes.constants';
import {
  IClientLeadApi,
  IClientLeadLookup,
  IClientLeadProviders,
  ILeadReportParams,
  ILeadReportData,
  ILeadInternalUpdateParams,
} from '../interfaces/IClientLead';

const ClientLeads = {
  async getClientLeads({
    practitionerId,
    status,
    searchTerms,
    page,
    limit,
    sortBy,
    sortType,
  }: IClientLeadLookup): Promise<IClientLeadApi | null> {
    const { data, meta } = await Api.get(
      API_ROUTES.CLIENT_LEADS(),
      filterUnusedParams({
        practitionerId,
        searchTerms,
        sortBy,
        sortType,
        status,
        page: page + 1,
        limit: limit || ROWS_PER_PAGE,
      })
    );
    return { data, meta };
  },

  async getClientLeadProviders(): Promise<IClientLeadProviders | null> {
    const { data } = await Api.get(API_ROUTES.CLIENT_LEAD_PROVIDERS);
    return data;
  },

  async getClientLeadReports(params: ILeadReportParams): Promise<ILeadReportData | null> {
    const { data }: { success: boolean; data: ILeadReportData | null } = await Api.get(
      API_ROUTES.CLIENT_LEAD_REPORTS,
      filterUnusedParams<ILeadReportParams, string | number | undefined>(params)
    );
    return data;
  },

  async updateClientLead({ id, ...params }: ILeadInternalUpdateParams): Promise<void> {
    await Api.put(API_ROUTES.CLIENT_LEADS(id), params);
  },

  async updateExternalClientLead(token: string, params: ILeadUpdateParams): Promise<void> {
    await Api.post(PUBLIC_LEAD_SUBMIT, { ...params, token });
  },

  async getContactDetails(adCampaignKey: string): Promise<ILeadContactDetails | null> {
    const { data } = await Api.get(PUBLIC_CONTACT_DETAILS, { adCampaignKey });
    return data;
  },
};

export { ClientLeads, ClientLeads as default };
