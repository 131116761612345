import React from 'react';
import { Box, TableCell, TableRow } from '@material-ui/core/';
import { useSelector } from 'react-redux';
import { MEDSPA_ADMIN_ROLE } from 'src/constants/general.constants';
import { useRowStyles } from './AdminUser.styles';
import { TableCellCustom } from '../../common/TableStyles/TableCellCustom';
import { ButtonStatus } from '../../common/TableStyles/ButtonStatus';
import ButtonAhref from '../../common/ButtonAhref';
import { hasAccessTo } from '../../../utils/auth.utils';
import { CREATE_ADMIN_USER } from '../../../constants/actions.constants';
import { IRolesMap } from './AdministratorsTable';

export interface AdminUserRowProps {
  id: number;
  email: string;
  fullname: string;
  active: boolean;
  roleId: number;
  rolesMap: IRolesMap;
}

const AdminUserRow: React.FC<AdminUserRowProps> = (props) => {
  const { id: userAdminId, email, fullname, active, roleId, rolesMap } = props;
  const classes = useRowStyles();
  const permissions = useSelector(({ auth }: any) => auth.permissions);

  return (
    <>
      <TableRow data-cy="row" className={classes.root}>
        <TableCellCustom>{userAdminId}</TableCellCustom>
        <TableCellCustom data-cy="fullName" className={classes.fullName}>
          {fullname}
        </TableCellCustom>
        <TableCellCustom data-cy="email" className={classes.email}>
          {email}
        </TableCellCustom>
        <TableCellCustom data-cy="role" className={classes.role}>
          {rolesMap[roleId]?.name}
        </TableCellCustom>
        <TableCell className={classes.status}>
          <Box data-cy="adminStatus" display="flex" justifyContent="space-between">
            {active ? (
              <ButtonStatus style={{ background: '#d1f1bd', height: 'auto' }}>Active</ButtonStatus>
            ) : (
              <ButtonStatus style={{ background: '#fa8086', height: 'auto' }}>Inactive</ButtonStatus>
            )}
          </Box>
        </TableCell>
        <TableCell className={classes.actions}>
          {hasAccessTo(CREATE_ADMIN_USER, permissions) && rolesMap[roleId]?.name !== MEDSPA_ADMIN_ROLE && (
            <ButtonAhref text="EDIT USER" href={`/administrator/new-user/${userAdminId}`} />
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default AdminUserRow;
