import React, { useState } from 'react';
import { Box, MenuItem, Tooltip, makeStyles, withStyles } from '@material-ui/core';
import { Edit as EditIcon, Check as CheckIcon, KeyboardArrowDown } from '@material-ui/icons';
import moment from 'moment';
import classnames from 'classnames';
import { EHRTextFieldProps } from './EHRTextField';
import { EHRSelect as Select } from './EHRSelect';

export const EHRSelectIcon = () => (
  <KeyboardArrowDown
    className="MuiSelect-icon"
    viewBox="6 6 12 12"
    style={{
      width: '1.35rem',
      height: '0.9rem',
      transform: 'translateY(50%)',
    }}
  />
);

const EHRSelect = withStyles(() => ({
  root: {
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem !important',
    width: '2rem',
  },
}))(Select);

const HOUR_OPTIONS = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
const getMinutes = () => {
  const options = [];
  for (let i = 0; i < 60; i += 5) {
    options.push(i < 10 ? `0${i}` : i);
  }
  return options;
};
const MINUTE_OPTIONS = getMinutes();

const useStyles = makeStyles(() => ({
  containerSelected: {
    color: '#545252',
    border: 'solid 1.5px #c8c8c8',
    height: '2.5rem',
    lineHeight: '2.5rem',
    borderRadius: '5px',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    display: 'flex',
    margin: 'auto',
    width: '5.7rem',
    justifyContent: 'center',
    fontFamily: 'Messina Sans Regular',
    fontSize: '94%',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '68px',
  },
  containerFloating: {
    border: 'solid 1px #12574d',
    borderRadius: '5px',
    backgroundColor: 'white',
    padding: '2rem 6rem',
    justifyContent: 'center',
    height: '4rem',
    width: '12rem',
    zIndex: 9,
    boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
    position: 'relative',
    top: '-0.1rem',
    left: '-2rem',
    '&:hover': {
      backgroundColor: '#faf8f8',
    },
  },
  disabled: {
    background: '#eeeeee',
  },
  ehrTimePicker: {
    width: '46px',
    height: '40px',
  },
  ehrTimePickerCheckError: {
    border: 'solid 1px red',
  },
  amPmPicker: {
    width: '3.1rem',
    paddingLeft: '1px',
    height: '40px',
  },
  timeSeparator: {
    paddingLeft: '2px',
    paddingRight: '2px',
  },
  iconButton: {
    cursor: 'pointer',
    fontSize: '120%',
    paddingLeft: '0.2rem',
    paddingTop: '0.1rem',
  },
  confirmHelperText: {
    position: 'fixed',
    paddingLeft: '5.9rem',
  },
}));

export const TIME_PICKER_FORMAT = 'LT';

interface EHRTimePickerProps extends EHRTextFieldProps {
  onEditInput?: (value: boolean) => void;
  edit?: boolean;
  containerFloating?: boolean;
}

export default function EHRTimePickerSelector(props: EHRTimePickerProps) {
  const classes = useStyles();
  const [hour, setHour] = useState('');
  const [minute, setMinute] = useState('');
  const [amPm, setAmPm] = useState('AM');

  const [defaultEditInput, setDefaultEditInput] = useState(false);

  const {
    dataCy,
    value,
    onEditInput: onEditInputProps,
    edit: propsEdit,
    onChange,
    disabled,
    error,
    containerFloating = false,
    ...restProps
  } = props;
  // Initialize onEditInput and edit based on props or default component behavior
  const onEditInput = onEditInputProps || (() => setDefaultEditInput(!defaultEditInput));
  const edit = onEditInputProps ? propsEdit : defaultEditInput;

  const onChangeHour = (newValue: string) => {
    setHour(newValue);
  };
  const onChangeMinutes = (newValue: string) => {
    setMinute(newValue);
  };
  const onChangeAmPm = (newValue: string) => {
    setAmPm(newValue);
  };

  React.useEffect(() => {
    const componentValue = moment(`${hour}:${minute} ${amPm}`, 'hh:mm A').format(TIME_PICKER_FORMAT);

    if (value && value !== componentValue) {
      const newValue = moment(value, 'hh:mm A');
      setHour(newValue.format('hh'));
      setMinute(newValue.format('mm'));
      setAmPm(newValue.format('A'));
    }
  }, [value]);

  const handleOnChangeValue = () => {
    const newValue = moment(`${hour}:${minute} ${amPm}`, 'hh:mm A').format(TIME_PICKER_FORMAT);

    if (newValue !== 'Invalid date' && newValue !== value) {
      onChange?.({ target: { value: newValue } } as React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>);
    }
  };

  const onCheck = () => {
    handleOnChangeValue();
    onEditInput?.(false);
  };

  React.useEffect(() => {
    const isManualConfirmMode = onEditInput && !edit;
    const isAutoConfirmMode = !onEditInput && edit;

    const shouldUpdateValue = !disabled && (isManualConfirmMode || isAutoConfirmMode);

    if (shouldUpdateValue) {
      handleOnChangeValue();
    }
  }, [edit, hour, minute, amPm, onEditInput, disabled]);

  if (!edit) {
    return (
      <Box className={classnames(classes.containerSelected, { [classes.disabled]: disabled })}>
        {hour || '--'}:{minute || '--'}
        {amPm}
        {!disabled && onEditInput && (
          <Box className={classes.iconButton} onClick={() => onEditInput?.(true)}>
            <EditIcon fontSize="inherit" />
          </Box>
        )}
      </Box>
    );
  }
  return (
    <Box className={classes.containerSelected} display="contents">
      <Box className={classnames(classes.container, { [classes.containerFloating]: containerFloating })}>
        <Box className={classes.ehrTimePicker}>
          <EHRSelect
            {...restProps}
            helperText=""
            dataCy={dataCy}
            fullWidth
            value={hour}
            onChange={onChangeHour}
            IconComponent={EHRSelectIcon}
          >
            {HOUR_OPTIONS.map((option) => (
              <MenuItem value={option}>{option}</MenuItem>
            ))}
          </EHRSelect>
        </Box>
        <Box className={classes.timeSeparator}>:</Box>
        <Box className={classes.ehrTimePicker}>
          <EHRSelect
            {...restProps}
            helperText=""
            dataCy={dataCy}
            fullWidth
            value={minute}
            onChange={onChangeMinutes}
            IconComponent={EHRSelectIcon}
          >
            {MINUTE_OPTIONS.map((min) => (
              <MenuItem value={min}>{min}</MenuItem>
            ))}
          </EHRSelect>
        </Box>
        <Box className={classes.amPmPicker}>
          <EHRSelect
            {...restProps}
            helperText=""
            dataCy={dataCy}
            fullWidth
            value={amPm}
            onChange={onChangeAmPm}
            IconComponent={EHRSelectIcon}
          >
            {['AM', 'PM'].map((type) => (
              <MenuItem value={type}>{type}</MenuItem>
            ))}
          </EHRSelect>
        </Box>
        <Box className={classes.iconButton} onClick={onCheck} style={{ marginTop: '0.4rem' }}>
          <Tooltip title="Confirm">
            <CheckIcon
              color="primary"
              style={{ background: '#eeeeee', borderRadius: '5px', border: error ? 'solid 1px red' : '' }}
            />
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
}
