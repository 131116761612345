import React, { ReactElement } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { useDestroyConfirmationNumber } from 'src/hooks/queries/useInventoryOrders';
import TrashCan from 'src/components/common/icons/TrashCan';
import { OrderConfirmationNumber } from 'src/services/OrderConfirmationNumbers';
import { useStyle, TableCellHeader } from './inventoryOrder.styles';

type Props = {
  confirmationNumbers: OrderConfirmationNumber[];
  colFill?: number;
  readOnly?: boolean;
};

type RowProps = Omit<Props, 'confirmationNumbers'> & {
  confirmationNumber: OrderConfirmationNumber;
  colFill?: number;
  children?: ReactElement | null;
  isLoading?: boolean;
};

const ConfirmationNumberRow: React.FC<RowProps> = ({ children, confirmationNumber, isLoading, colFill = 0 }) => {
  const classes = useStyle();

  return (
    <TableRow className={classes.pulseOnly} data-loading={isLoading}>
      <TableCell className={classes.approvalRowName}>{confirmationNumber.supplierName}</TableCell>
      {!!colFill && <TableCell colSpan={colFill - (children ? 1 : 0)} />}
      <TableCell className={colFill ? classes.approvalRowPrice : undefined}>
        {confirmationNumber.confirmationNumber}
      </TableCell>
      {!!children && <TableCell className={classes.removeItem}>{children}</TableCell>}
    </TableRow>
  );
};

const ConfirmationNumberRowEdit: React.FC<Omit<RowProps, 'children' | 'isLoading'>> = ({
  confirmationNumber,
  colFill = 0,
}) => {
  const { mutateAsync: removeConfirmationNumber, isLoading } = useDestroyConfirmationNumber(confirmationNumber.id);

  return (
    <ConfirmationNumberRow confirmationNumber={confirmationNumber} isLoading={isLoading} colFill={colFill}>
      {isLoading ? null : (
        <Box
          onClick={async () => {
            await removeConfirmationNumber();
          }}
        >
          <TrashCan />
          Remove
        </Box>
      )}
    </ConfirmationNumberRow>
  );
};

export const OrderConfirmationNumbers: React.FC<Props> = ({
  confirmationNumbers = [],
  colFill = 0,
  readOnly = false,
}) => {
  const classes = useStyle();

  return !confirmationNumbers.length ? null : (
    <Box>
      <Box padding="1rem">
        <Typography variant="h6" className={classes.supplierSubsectionHeader}>
          Order Confirmation #
        </Typography>
      </Box>
      <TableContainer data-testid="orderSummary.tracking">
        <Table>
          <TableHead>
            <TableRow>
              <TableCellHeader>Supplier</TableCellHeader>
              {!!colFill && <TableCellHeader colSpan={colFill - (!readOnly ? 1 : 0)} />}
              <TableCellHeader>Confirmation Number</TableCellHeader>
              {!readOnly && <TableCellHeader className={classes.functional} />}
            </TableRow>
          </TableHead>
          <TableBody>
            {confirmationNumbers.map((confirmationNumber) =>
              !readOnly ? (
                <ConfirmationNumberRowEdit
                  confirmationNumber={confirmationNumber}
                  key={confirmationNumber.id}
                  colFill={colFill}
                />
              ) : (
                <ConfirmationNumberRow
                  confirmationNumber={confirmationNumber}
                  key={confirmationNumber.id}
                  colFill={colFill}
                />
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default OrderConfirmationNumbers;
