import React, { createContext, useMemo, useState } from 'react';
import { Box } from '@material-ui/core';
import BudgetPanel from 'src/components/common/BudgetPanel';
import { dashboardListValue } from 'src/utils/inventory.utils';
import { IDashboardItem } from 'src/interfaces/reconciliation.interfaces';
import { useSelector } from 'react-redux';
import { RootState } from 'src/rematch';
import { useInventoryBudget } from 'src/hooks/queries/useInventoryBudget';
import { InventoryOrderContext, IOrderStep, OrderNotifier } from 'src/interfaces/IInventoryOrder';
import { useCurrentInventoryOrder, useInventoryOrders } from 'src/hooks/queries/useInventoryOrders';
import { MEDSPA_ADMIN_ROLE, PORTRAIT_LEGACY, PORTRAIT_LEGACY_PLUS } from 'src/constants/general.constants';
import useMedspaAdminOnboarding from 'src/hooks/useMedspaAdminOnboarding';
import PendingStepsBanner from 'src/components/DashboardMedspaAdmin/Inventory/pendingStepsBanner';
import ProductSelection from './ProductSelection';
import { useStyle } from './inventoryOrder.styles';
import ServiceSelection from './ServiceSelection';
import OrderDelivery from './OrderConfirm';
import { InventoryFooter } from './InventoryFooter';
import OrderStatusBar from './OrderStatusBar';
import OrderConfirmDialog from './OrderConfirm/OrderConfirmDialog';
import OrderLoading from './OrderLoading';
import PortraitEvangelistBanner from './PortraitEvangelistBanner';

const getOrderSteps = (roleName: string): IOrderStep[] => {
  const isLegacy = roleName === PORTRAIT_LEGACY || roleName === PORTRAIT_LEGACY_PLUS;

  let finalStepLabel = 'Pay';
  if (isLegacy) {
    finalStepLabel = 'Place order';
  }

  return [
    {
      label: 'Select Products',
      target: isLegacy ? <ServiceSelection /> : <ProductSelection />,
      orderSequence: 1,
    },
    {
      label: finalStepLabel,
      target: <OrderDelivery />,
      orderSequence: 2,
    },
  ];
};

const OrderNotifyContext = createContext<OrderNotifier | undefined>(undefined);
const OrderContext = createContext<InventoryOrderContext | undefined>(undefined);

const OrderDashboard = () => {
  const classes = useStyle();
  const [step, setStep] = useState<number>(0);
  const [blockProgress, setBlockProgress] = useState<boolean>(false);
  const [dialogueOpen, setDialogueOpen] = useState<boolean>(false);
  const [notification, setNotification] = useState<string | undefined>();
  const { missingFields, onboardingEnabled, onboardingCompleted } = useMedspaAdminOnboarding();
  const { data: orderData, isLoading, isFetching } = useCurrentInventoryOrder();
  const { data: orderHistory, isLoading: isLoadingOrderHistory } = useInventoryOrders();

  const { roleName: userRoleName, isLoading: isLoadingUserRoleName } = useSelector(({ auth }: RootState) => auth);
  const { dashboardList } = useSelector(({ inventoryDefault }: RootState) => ({ ...inventoryDefault }));

  const isAdmin = userRoleName === MEDSPA_ADMIN_ROLE;
  const isLegacy = orderData?.roleName === PORTRAIT_LEGACY || orderData?.roleName === PORTRAIT_LEGACY_PLUS;

  const { data: totalBudget, isLoading: isLoadingTotalBudget } = useInventoryBudget({
    disabled: !orderData || isLoadingUserRoleName || !isAdmin || !isLegacy,
  });

  const totalInventoryValue =
    isLoadingUserRoleName || isAdmin ? 0 : dashboardListValue(dashboardList as IDashboardItem[]);

  const mainOrder = useMemo(
    () => ({
      isLoading: isLoading || isLoadingTotalBudget || isLoadingOrderHistory,
      isFetching,
      orderData: orderData ?? undefined,
      orderHistory: orderHistory ?? [],
      budgetUsed: totalInventoryValue + (orderData?.subtotal ?? 0),
      blockProgress: setBlockProgress,
      totalBudget: totalBudget ?? 0,
      isAdmin,
      isLegacy,
    }),
    [orderData, isAdmin, isLoading, isFetching, isLoadingTotalBudget]
  );

  if (isLoading && !orderData) {
    return <OrderLoading />;
  }

  if (!orderData?.roleName) {
    return null;
  }

  const orderSteps = getOrderSteps(orderData.roleName);
  const currentStep = orderSteps[step];

  return (
    <Box className={classes.orderDashboard} data-testid="order dashboard">
      {!isLegacy && <PortraitEvangelistBanner />}

      {!!missingFields.length && (
        <PendingStepsBanner
          missingFields={missingFields}
          redirectToOnboarding={onboardingEnabled && !onboardingCompleted}
        />
      )}
      <OrderNotifyContext.Provider value={setNotification}>
        <OrderContext.Provider value={mainOrder}>
          <Box className={classes.headerContainer}>
            <OrderStatusBar currentStep={currentStep.orderSequence} steps={orderSteps} />
            {!isAdmin && <BudgetPanel totalInventoryValue={totalInventoryValue + (orderData?.subtotal ?? 0)} />}
          </Box>
          {currentStep.target}
          <OrderConfirmDialog open={dialogueOpen} closeDialog={() => setDialogueOpen(false)} />
        </OrderContext.Provider>
        {!dialogueOpen && (
          <InventoryFooter
            step={step}
            notification={notification}
            setStep={setStep}
            setDialogueOpen={setDialogueOpen}
            currentStep={currentStep}
            setNotification={setNotification}
            orderData={orderData}
            totalOrderValue={orderData.subtotal ?? 0}
            totalSaveValue={orderData.savingsTotal ?? 0}
            blockProgress={blockProgress || !!missingFields.length}
          />
        )}
      </OrderNotifyContext.Provider>
    </Box>
  );
};

export { OrderDashboard, OrderContext, OrderNotifyContext, OrderDashboard as default };
