/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import Page from 'src/components/common/Page';
import { ICustomerCount } from 'src/interfaces/ICustomer';
import { useSelector } from 'react-redux';
import { useStyles } from '../PhysicianPatientsList.styles';
import { USER_TYPES } from '../../../constants/general.constants';
import { CUSTOMERS_LIST, useGetCountResultByRole } from '../../../hooks/queries/useCustomPatients';
import { PatientFilters } from '../PhysicianPatientsList';
import { usePractitionerForPhysicians } from '../../../hooks/practitioner/usePractitioner';
import { CustomerParams } from '../../../interfaces/CustomerParams';
import { useCustomRoles } from '../../../hooks/queries/useCustomRoles';
import { PENDING_RECLEAR } from '../../../constants/scheduler.constants';
import { LiveGFEList } from './LiveGFEList';
import { DefaultGFEsList } from './DefaultGFEsList';
import { allFilters, SCHEDULED_LIVE_GFE_TAB, sharedInitialFilters, URGENT_GFE_TAB } from './filters';
import { usePhysicianInfo } from '../../../hooks/queries/usePhysicians';
import { STATES_REQUIRING_LIVE_GFE } from '../../../constants/medicalProfile.constants';

export const PhysicianPendingGFEList = () => {
  const isLiveGfeEnabled = process.env.REACT_APP_PUBLIC_LIVE_GFE_ENABLED === 'true';

  const { userId } = useSelector(({ auth }: any) => auth);
  const { physicianInfo: physician, isLoading: isLoadingPhysician } = usePhysicianInfo(userId);

  const workingInLiveGfeState = physician?.workingStates.some((state: string) =>
    STATES_REQUIRING_LIVE_GFE.includes(state)
  );

  const classes = useStyles();
  const queryClient = useQueryClient();
  const [search, setSearch] = useState<string>('');
  const [providerId, setProviderId] = useState<string | number>('');
  const [roleId, setRoleId] = useState<string | number>('');
  const [patientState, setPatientState] = useState<string>('');
  const [license, setLicense] = useState<string>('');
  const [filters, setFilters] = useState<CustomerParams>(sharedInitialFilters);
  const [sortBy, setSortBy] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<'desc' | 'asc' | undefined>();
  const [patientType, setPatientType] = useState<string>('');
  const [removeRequiresUpdate, setRemoveRequiresUpdate] = useState<boolean>(false);
  const [tab, setTab] = useState(isLiveGfeEnabled ? SCHEDULED_LIVE_GFE_TAB : URGENT_GFE_TAB);
  const { data: practitioners = [], isLoading: isLoadingProviders } = usePractitionerForPhysicians({});
  const { data: roles, isLoading: isLoadingRoles } = useCustomRoles({ userType: USER_TYPES.PRACTITIONER });
  const { data: countResults, isLoading: isLoadingCountResults } = useGetCountResultByRole('physician', filters, true);

  useEffect(() => {
    queryClient.invalidateQueries([CUSTOMERS_LIST]);
  }, []);

  useEffect(() => {
    if (!isLoadingPhysician && isLiveGfeEnabled && !workingInLiveGfeState) {
      setTab(URGENT_GFE_TAB);
    }
  }, [workingInLiveGfeState]);

  useEffect(() => {
    const storedFilters = JSON.parse(localStorage.getItem('gfeFilters') || '{}');
    setSearch(storedFilters.search || '');
    setProviderId(storedFilters.practitionerId || '');
    setRoleId(storedFilters.roleId || '');
    setLicense(storedFilters.license || '');
    setRemoveRequiresUpdate(storedFilters.excludedStatus === PENDING_RECLEAR);
    setPatientType(storedFilters.marked ? 'important' : '');
    setPatientState(storedFilters.state || '');
    setSortDirection(storedFilters.sortDirection || '');
    setSortBy(storedFilters.sortBy || '');
  }, []);

  const doSortBy = (column: string, direction: 'desc' | 'asc' | undefined) => {
    setSortBy(column);
    setSortDirection(direction);
  };

  const currentTab = allFilters[tab];
  const currentTabFilters = currentTab.filters;

  useEffect(() => {
    const newSearch = {
      search,
      practitionerId: providerId,
      state: patientState,
      roleId,
      license,
      marked: patientType ? true : '',
      excludedStatus: removeRequiresUpdate ? PENDING_RECLEAR : '',
      sortDirection,
      sortBy,
    };

    const newFilters = {
      ...currentTabFilters,
      ...newSearch,
    };

    setFilters(newFilters);
    localStorage.setItem('gfeFilters', JSON.stringify(newFilters));
  }, [
    search,
    providerId,
    patientState,
    roleId,
    license,
    patientType,
    removeRequiresUpdate,
    currentTab,
    sortDirection,
    sortBy,
  ]);

  const liveGfeTabs = Object.keys(allFilters).filter((tabItem) => allFilters[tabItem].isLive);
  const gfeTabs = Object.keys(allFilters).filter((tabItem) => !allFilters[tabItem].isLive);

  return (
    <Page title="Pending GFEs">
      <div className={classes.physicianPage}>
        <div className={classes.patientsTabs}>
          {Object.keys(allFilters).map((tabItem) => {
            const { label, isLive } = allFilters[tabItem];

            if (isLive && (!isLiveGfeEnabled || !workingInLiveGfeState)) {
              return null;
            }

            const countName = `${tabItem}Count` as keyof ICustomerCount;
            const count = countResults?.[countName];

            return (
              <p
                key={tabItem}
                role="button"
                className={`${tab === tabItem ? classes.activeTabItem : classes.tabItem} ${classes.mr3}`}
                onClick={() => setTab(tabItem)}
              >
                {label} ({Number.isNaN(count) || isLoadingCountResults ? '...' : count})
              </p>
            );
          })}
        </div>

        {!isLoadingProviders && !isLoadingRoles && !isLoadingPhysician && (
          <PatientFilters
            states={physician.workingStates || []}
            providers={practitioners}
            search={search}
            setSearch={setSearch}
            patientState={patientState}
            setPatientState={setPatientState}
            providerId={providerId}
            setProviderId={setProviderId}
            isFetching={false}
            roles={roles}
            roleId={roleId}
            setRoleId={setRoleId}
            license={license}
            setLicense={setLicense}
            patientType={patientType}
            setPatientType={setPatientType}
            removeRequiresUpdate={removeRequiresUpdate}
            setRemoveRequiresUpdate={setRemoveRequiresUpdate}
            isLiveGfe={liveGfeTabs.includes(tab)}
          />
        )}

        {isLiveGfeEnabled && workingInLiveGfeState && liveGfeTabs.includes(tab) && (
          <LiveGFEList defaultFilters={filters} tab={tab} currentTabFilters={currentTabFilters} doSortBy={doSortBy} />
        )}

        {gfeTabs.includes(tab) && (
          <DefaultGFEsList defaultFilters={filters} currentTabFilters={currentTabFilters} doSortBy={doSortBy} />
        )}
      </div>
    </Page>
  );
};
