import React from 'react';
import moment from 'moment';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { LandingPageProps } from 'src/interfaces/ILeadLandingPage';
import { XL_SCREEN, DATEMONTH } from 'src/constants/leadLandingPage.constants';
import { ReviewStars } from './ReviewStars';

export const Hero: React.FC<LandingPageProps> = ({ classes, mobile }: LandingPageProps): React.ReactElement => {
  const xlScreen = useMediaQuery(XL_SCREEN);

  const currentMonth = moment().format(DATEMONTH);
  const nextMonth = moment().add(1, 'month').format(DATEMONTH);

  return (
    <Box className={classes?.hero} data-mobile={mobile} data-xl={xlScreen}>
      <Box className={classes?.heroContent} data-mobile={mobile}>
        <Typography className={classes?.heroTitle} data-mobile={mobile}>
          Dream care.
        </Typography>
        <Typography className={classes?.heroTitle} data-mobile={mobile}>
          Best-in-class work.
        </Typography>
        <Typography className={classes?.heroText} data-mobile={mobile}>
          New specials for {` ${currentMonth} and ${nextMonth} `}
          at our 60 full-service medical aesthetics boutiques from coast-to-coast.
        </Typography>
        <Typography className={[classes?.heroText, classes?.regular].join(' ')} data-mobile={mobile}>
          500+ 5 star reviews and counting
        </Typography>
        <ReviewStars score={5} align="left" caption="(4.8)" classes={classes} />
      </Box>
    </Box>
  );
};
