import { Box, Typography, makeStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { Error as ErrorIcon } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  box: {
    margin: 0,
    padding: 15,
    backgroundColor: 'rgb(254,242,226)',
    color: 'rgb(133,76,0)',
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '8px',
    border: '1px solid rgb(237,225,209)',
    '& p': {
      margin: 'auto auto auto 15px',
      fontSize: '0.6rem',
      fontFamily: 'Messina Sans Regular',
    },
    '& p > span': {
      fontFamily: 'Roboto',
    },
    '& svg': {
      fontSize: 22,
      color: 'rgb(247,147,13)',
    },
  },
}));

export const OrderAlert = ({ text }: { text: string }): ReactElement => {
  const classes = useStyles();
  return (
    <Box className={classes.box}>
      <ErrorIcon />
      <Typography component="p">{text}</Typography>
    </Box>
  );
};

export default OrderAlert;
