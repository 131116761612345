import React, { useEffect, useState } from 'react';
import { Box, FormControlLabel, Radio } from '@material-ui/core';
import { useStyles } from 'src/pages/Practitioners/GFEManagement.styles';
import Banner from 'src/components/common/Banner';
import { PENDING_STEPS_FOR_GFE_WARNING } from 'src/constants/onboarding.constants';
import { useSelector } from 'react-redux';
import { IUserGroup } from '../../../interfaces/IUserGroup';
import { GFE_SETTING_MAP } from '../../../constants/general.constants';
import { dispatch, RootState } from '../../../rematch';
import compile from '../../../utils/toastMessagesCompiler';
import { useUpdateUserGroup } from '../../../hooks/queries/useUserGroups';
import { GFE_SETTING_CONTENT_MAP } from './content';
import { ReactComponent as Alert } from '../../../assets/images/alert.svg';

export const MedspaAdminGfeManagementSetting = ({
  userGroup,
  refetchUserGroup,
  pendingMedicalDirector = false,
}: {
  userGroup: IUserGroup;
  refetchUserGroup: () => void;
  pendingMedicalDirector?: boolean;
}) => {
  const classes = useStyles();
  const { userId } = useSelector(({ auth }: RootState) => auth);
  const isOwner = userGroup?.ownerId === userId;
  const [selectedGFESetting, setSelectedGFESetting] = useState<number>();
  const { mutateAsync: updateMedspaUser } = useUpdateUserGroup();

  useEffect(() => {
    if (userGroup) {
      setSelectedGFESetting(GFE_SETTING_MAP[userGroup.gfeSetting] || 0);
    }
  }, [userGroup]);

  const handleUpdateGFESetting = (setting: number) => {
    updateMedspaUser(
      {
        id: userGroup.id,
        gfeSetting: setting,
        gfeCardId: null,
      },
      {
        onSuccess: refetchUserGroup,
        onError: () => {
          dispatch({
            type: 'snackbar/enqueueSnackBar',
            payload: {
              message: `Something went wrong, failed to update settings.`,
              duration: 5000,
              type: 'error',
            },
          });
        },
      }
    );
    setSelectedGFESetting(setting);
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.success_message', {
          element: `Setting`,
          action: 'saved',
        }),
      },
      duration: 3000,
    });
  };

  return (
    <Box className={classes.gfeManagementPage}>
      {!isOwner && (
        <Box className={classes.ownerOnlyAlert}>
          <Alert className={classes.warning} />
          Only the MedSpa creator can modify this option. Please refer to them for changes or payment updates.
        </Box>
      )}
      <Box className={classes.settingsContainer}>
        <Box className={classes.settingsDescription}>
          <Box className={classes.title}>GFE Auto-Routing to</Box>
          <Box className={classes.subTitle}>
            Choose where do you want to automatically send a patient profile for GFE.
          </Box>
        </Box>
        <Box className={classes.settingsOptionsContainer}>
          {pendingMedicalDirector && (
            <Box style={{ marginBottom: '1.5rem' }}>
              <Banner content={PENDING_STEPS_FOR_GFE_WARNING} type="warning" />
            </Box>
          )}
          {Object.keys(GFE_SETTING_CONTENT_MAP).map((key) => {
            const textHash = GFE_SETTING_CONTENT_MAP[key];
            const value = GFE_SETTING_MAP[key];
            const Subcontent = isOwner ? textHash.subContent : null;
            const isSelected = selectedGFESetting === value;

            return (
              <Box className={selectedGFESetting === value ? classes.selectedSettingsOption : classes.settingsOption}>
                <FormControlLabel
                  value={value}
                  control={
                    <Radio
                      className={classes.radioButton}
                      color="primary"
                      checked={isSelected}
                      disabled={!isOwner}
                      onClick={() => handleUpdateGFESetting(+value)}
                    />
                  }
                  label={
                    <>
                      <Box>{textHash.title}</Box>
                      <Box className={classes.subTitle}>{textHash.text}</Box>
                      {isSelected && Subcontent && <Subcontent />}
                    </>
                  }
                />
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
