import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { ClassNameMap } from 'src/types/Dom';
import { IOrderProduct } from 'src/services/Products';
import { ProductOrderDetails } from 'src/interfaces/IInventoryOrder';
import ISuppliers from 'src/interfaces/ISuppliers';
import { InventoryOrderCustomItem } from 'src/services/InventoryOrderCustomItems';
import { sortProductsBySupplier } from 'src/utils/inventoryOrdering';
import ProductSelectionRow from './ProductSelectionRow';
import { OrderSupplierProductsStyles } from '../inventoryOrder.styles';
import OrderSupplierTable from '../OrderSupplierTable';
import { SavingsPopup } from './SavingsPopup';

type Props = ClassNameMap & {
  showAll?: boolean;
  supplier: ISuppliers;
  inventoryOrderId: number;
  items: IOrderProduct[];
  customItems?: InventoryOrderCustomItem[];
  RowProps: { inventoryOrderId: number; orderItems: ProductOrderDetails };
};

export const ProductSelectionSuppliers = ({
  classes,
  showAll,
  supplier,
  inventoryOrderId,
  items,
  customItems = [],
  RowProps,
}: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [openSavingsPopup, setOpenSavingsPopup] = useState<boolean>(false);
  const [hasShownSavingsPopup, setHasShownSavingsPopup] = useState<boolean>(false);

  const showSavingsPopup = () => {
    setOpenSavingsPopup(true);
    setHasShownSavingsPopup(true);
    setTimeout(() => setOpenSavingsPopup(false), 8000);
  };

  const { products, hasMore, noSavings } = sortProductsBySupplier({
    products: items,
    supplierName: supplier.name,
    orderItems: RowProps.orderItems,
    expanded,
  });

  useEffect(() => {
    showAll && setExpanded(true);
  }, []);

  return (
    <OrderSupplierTable
      classes={classes}
      supplier={supplier}
      inventoryOrderId={inventoryOrderId}
      showMore={hasMore}
      setExpanded={() => setExpanded(true)}
      testid="inventoryOrder.productSelection"
    >
      <>
        {customItems.map((customItem) => (
          <ProductSelectionRow
            key={customItem.name}
            classes={classes}
            inventoryOrderId={RowProps.inventoryOrderId}
            customItem={customItem}
            noSavings={noSavings}
            showSavingsPopup={showSavingsPopup}
            hasShownSavingsPopup={hasShownSavingsPopup}
          />
        ))}
        {products.map((product) => (
          <ProductSelectionRow
            key={product.name}
            classes={classes}
            inventoryOrderId={RowProps.inventoryOrderId}
            product={product}
            item={RowProps.orderItems[product.id]}
            noSavings={noSavings}
            showSavingsPopup={showSavingsPopup}
            hasShownSavingsPopup={hasShownSavingsPopup}
          />
        ))}
        <SavingsPopup open={openSavingsPopup} onClose={() => setOpenSavingsPopup(false)} />
      </>
    </OrderSupplierTable>
  );
};

export default withStyles(OrderSupplierProductsStyles)(ProductSelectionSuppliers);
