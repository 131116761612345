import { Box, CircularProgress, Dialog, Radio } from '@material-ui/core';
import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { cardOnFileStyles } from 'src/components/common/CreditCardDetails';
import AddCreditCard from 'src/components/DashboardPractitioner/Tabs/OrderingTab/OrderConfirm/CardOnFile/AddCreditCard';
import CreditCardIcon from 'src/components/PatientProfile/CreditCards/CreditCardIcon';
import { EHRButton, EHRTypography } from 'src/components/ui/v1';
import useGetCards from 'src/hooks/queries/medspaAdmins/useMedspaPaymentMethods';
import { IPaymentCard } from 'src/interfaces/IMedspaAdminList';
import { RootState } from 'src/rematch';
import classNames from 'classnames';
import useCurrentUserGroup, { useUpdateUserGroup } from 'src/hooks/queries/useUserGroups';
import { useStyles } from './Options.styles';

export const Options = () => {
  const ccStyles = cardOnFileStyles();
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const {
    user: { userGroupId },
  } = useSelector(({ auth }: RootState) => auth);

  const { data: userGroupData, isLoading: isLoadingUserGroup, refetch: refetchUserGroup } = useCurrentUserGroup();
  const { mutateAsync: updateGroup, isLoading: isUpdating } = useUpdateUserGroup({
    successCallback: () => {
      refetchUserGroup();
    },
  });
  const { data: creditCards = [], isLoading: isLoadingCards, refetch: refetchCards } = useGetCards(userGroupId ?? 0);

  const setGfeCard = (cardId: string) => {
    updateGroup({ id: userGroupId ?? 0, gfeCardId: cardId });
  };

  const isLoading = isLoadingUserGroup || isLoadingCards || isLoadingUserGroup || isUpdating;

  let BodyComponent = <CircularProgress />;

  if (!isLoadingCards && creditCards.length < 1) {
    BodyComponent = (
      <Box className={classes.emptyState}>
        <h3>Welcome to your Payment Methods</h3>
        <p>It looks like you haven&apos;t added your first payment method yet. </p>
        <p>
          Click on <strong>Add Card</strong> button above to add your first card.
        </p>
      </Box>
    );
  } else if (!isLoadingCards && creditCards.length > 0) {
    BodyComponent = (
      <Box className={classes.paymentMethodsListContainer}>
        {creditCards.map((card: IPaymentCard) => {
          const { cardBrand, last4, id, expMonth, expYear } = card;
          const isGfeCard = id === userGroupData?.gfeCardId;
          const expiresIn = `${expMonth}/${expYear.toString().slice(-2)}`;

          return (
            // eslint-disable-next-line max-len
            // eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
            <label
              className={classNames(classes.paymentMethodsListItem, {
                [classes.paymentMethodsListItemSelected]: isGfeCard,
              })}
              key={id}
              onClick={() => setGfeCard(id)}
            >
              <Box className={ccStyles.paymentMethodInfo}>
                <Box className={ccStyles.ccBrand}>
                  <CreditCardIcon cardBrand={cardBrand} noFormat />
                </Box>

                <Box>
                  <Box>
                    {capitalize(cardBrand?.split('_').join(' '))} ending in {last4}
                  </Box>
                  <Box>
                    <EHRTypography
                      dataCy="caption-card-expires-in"
                      variant="caption"
                      style={{ color: 'rgba(0,0,0,0.5)' }}
                    >
                      Expires in {expiresIn}
                    </EHRTypography>
                  </Box>
                </Box>
              </Box>

              <Box className={classes.ccActions}>
                <Radio color="primary" checked={isGfeCard} onClick={() => setGfeCard(id)} />
              </Box>
            </label>
          );
        })}
        {isLoading && (
          <Box
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
            bgcolor="rgba(0,0,0,.5)"
            width="100%"
            height="100%"
            style={{
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Box className={classes.paymentSettingsContainer}>
      {BodyComponent}

      <Box className={classes.paymentSettingsActions}>
        <EHRButton
          dataCy="btn-add-card"
          color="primary"
          text="Add Card"
          onClick={() => setDialogOpen(true)}
          disabled={isLoadingCards}
        />
      </Box>

      <Dialog open={dialogOpen}>
        <AddCreditCard onSave={refetchCards} closeDialog={() => setDialogOpen(false)} />
      </Dialog>
    </Box>
  );
};
