import { useQuery, useMutation, useQueryClient, UseQueryResult, UseMutationResult } from 'react-query';
import compile from 'src/utils/toastMessagesCompiler';
import { filterUnusedParams, showSnackbar } from 'src/utils/global';
import { SNACKBAR_SUCCESS } from 'src/constants/general.constants';
import InventoryOrders from 'src/services/InventoryOrders';
import {
  InventoryOrderFull,
  IOrderingAllowed,
  IOrderUpdate,
  IInventoryOrderPeriod,
  InventoryOrderWithId,
  OrderLookupParams,
  IDeliveryBlackoutDates,
  IDeliveryAddress,
  InventoryOrderService,
  InventoryOrderItem,
} from 'src/interfaces/IInventoryOrder';
import {
  INVENTORY_ORDER,
  INVENTORY_ORDER_BLACKOUT_DATES,
  INVENTORY_ORDER_PERIOD,
  INVENTORY_ORDER_STATUS,
  INVENTORY_ORDERS,
  PAYMENT_STATUS,
  VALID_TRACKING_NUMBER,
} from 'src/constants/reactQuery.keys';
import TrackingNumbers, { ShipmentTrackingNumber } from 'src/services/ShipmentTrackingNumbers';
import InventoryOrderCustomItems, { InventoryOrderCustomItem } from 'src/services/InventoryOrderCustomItems';
import InventoryOrderItems from 'src/services/InventoryOrderItems';
import { PAYMENT_AUTH_TIMEOUT } from 'src/constants/inventory.constants';
import OrderConfirmationNumbers, { OrderConfirmationNumber } from 'src/services/OrderConfirmationNumbers';
import PurchaseOrderItems, { PurchaseOrderItem } from 'src/services/PurchaseOrderItems';
import { handleQueryError } from 'src/utils/inventoryOrdering';
import InventoryOrderCharges, { InventoryOrderCharge } from 'src/services/InventoryOrderCharges';
import { IPaymentCardUpdate } from 'src/interfaces/IMedspaAdminList';

interface InventoryOrdersResponse {
  data: InventoryOrderWithId[];
  meta: {
    total: number;
    page: number;
    limit: number;
    totalPages: number;
  };
}

export const useInventoryOrderPeriod = (): UseQueryResult<IInventoryOrderPeriod | null> =>
  useQuery<IInventoryOrderPeriod | null>({
    queryKey: [INVENTORY_ORDER_PERIOD],
    queryFn: (): Promise<IInventoryOrderPeriod | null> => InventoryOrders.getPeriod(),
  });

export const useUpdateInventoryOrderPeriod = (): UseMutationResult<void, unknown, IInventoryOrderPeriod, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ startDate, endDate }: IInventoryOrderPeriod): Promise<void> => {
      await InventoryOrders.updatePeriod({ startDate, endDate });
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDER_PERIOD] });
    },
  });
};

export const useBlackoutDates = (): UseQueryResult<IDeliveryBlackoutDates[] | null> =>
  useQuery<IDeliveryBlackoutDates[] | null>({
    queryKey: [INVENTORY_ORDER_BLACKOUT_DATES],
    queryFn: () => InventoryOrders.getBlackoutDates(),
    onError: handleQueryError('fetching blackout date'),
    refetchOnWindowFocus: false,
  });

export const useCreateBlackoutDate = (): UseMutationResult<void, unknown, string, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (blackoutDate: string): Promise<void> => {
      await InventoryOrders.createBlackoutDate(blackoutDate);
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDER_BLACKOUT_DATES] });
    },
    onError: handleQueryError('creating blackout date'),
  });
};

export const useDestroyBlackoutDate = (id: number): UseMutationResult<void, unknown, void, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (): Promise<void> => {
      await InventoryOrders.destroyBlackoutDate(id);
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDER_BLACKOUT_DATES] });
    },
    onError: handleQueryError('removing blackout date'),
  });
};

export const useCurrentInventoryOrder = (): UseQueryResult<InventoryOrderFull | null> =>
  useQuery<InventoryOrderFull | null>({
    queryKey: [INVENTORY_ORDER],
    queryFn: (): Promise<InventoryOrderFull | null> => InventoryOrders.getCurrentOrder(),
  });

export const useGetOrderingStatus = (enabled: boolean = true): UseQueryResult<IOrderingAllowed | null> =>
  useQuery<IOrderingAllowed | null>({
    queryKey: [INVENTORY_ORDER_STATUS],
    queryFn: (): Promise<IOrderingAllowed | null> => InventoryOrders.getStatus(),
    enabled,
  });

export const useInventoryOrders = ({
  disabled = false,
  ...params
}: { disabled?: boolean } & OrderLookupParams = {}): UseQueryResult<InventoryOrderWithId[] | null> =>
  useQuery<InventoryOrderWithId[] | null>({
    refetchOnWindowFocus: false,
    queryKey: [INVENTORY_ORDERS],
    queryFn: (): Promise<InventoryOrderWithId[] | null> => InventoryOrders.getOrders(params),
    enabled: !disabled,
  });

export const useInventoryOrdersIndex = ({
  disabled = false,
  ...params
}: { disabled?: boolean } & OrderLookupParams = {}) => {
  const queryKey = [INVENTORY_ORDERS, params.page, params.limit, JSON.stringify(params)];

  return useQuery<InventoryOrdersResponse>({
    queryKey,
    queryFn: () => InventoryOrders.getOrders(params),
    staleTime: 60000,
    enabled: !disabled,
    keepPreviousData: true,
  });
};

export const useUpdateInventoryOrderItem = ({
  inventoryOrderId,
  serviceId,
  orderProductId,
}: Partial<InventoryOrderService> & { inventoryOrderId: number }): UseMutationResult<
void,
unknown,
Partial<InventoryOrderItem>,
unknown
> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ quantity, notes }: Partial<InventoryOrderItem>): Promise<void> => {
      await InventoryOrderItems.update(
        filterUnusedParams<Partial<InventoryOrderItem>, string | number | undefined | null>({
          [orderProductId ? 'orderProductId' : 'serviceId']: orderProductId || serviceId,
          notes,
          quantity,
          inventoryOrderId,
        })
      );
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDER] });
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDERS] });
    },
    onError: handleQueryError('adding product'),
  });
};

export const useCreateInventoryOrderCustomItem = ({
  supplierId,
  inventoryOrderId,
}: {
  supplierId: number;
  inventoryOrderId: number;
}): UseMutationResult<void, unknown, Partial<InventoryOrderCustomItem>, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: Partial<InventoryOrderCustomItem>): Promise<void> => {
      await InventoryOrderCustomItems.create({ supplierId, inventoryOrderId, ...params });
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDER] });
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDERS] });
    },
    onError: handleQueryError('adding custom item'),
  });
};

export const useUpdateInventoryOrderCustomItem = (
  id: number
): UseMutationResult<void, unknown, Partial<InventoryOrderCustomItem>, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: Partial<InventoryOrderCustomItem>): Promise<void> => {
      await InventoryOrderCustomItems.update({ id, ...params });
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDER] });
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDERS] });
    },
  });
};

export const useDestroyInventoryOrderCustomItem = (id: number): UseMutationResult<void, unknown, void, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (): Promise<void> => {
      await InventoryOrderCustomItems.destroy(id);
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDER] });
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDERS] });
    },
  });
};

export const useUpdateInventoryOrderSpecialItem = ({
  inventoryOrderId,
  specialOfferId,
}: {
  inventoryOrderId: number;
  specialOfferId: number;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      discount,
      price,
      productList,
    }: {
      discount: number;
      price: number;
      productList?: string;
    }): Promise<void> => {
      await InventoryOrders.updateOrderSpecialOfferItem(
        filterUnusedParams<Partial<InventoryOrderItem> & { price: number }, string | number | undefined | null>({
          specialOfferId,
          discount,
          price,
          inventoryOrderId,
          productList,
        })
      );
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDER] });
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDERS] });
    },
  });
};

export const useUpdateAdminNote = (id: number): UseMutationResult<void, unknown, string, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (adminNote: string): Promise<void> => {
      await InventoryOrders.update({
        id,
        adminNote,
      });
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDERS] });
    },
  });
};

export const useAddCharges = ({ inventoryOrderId, supplierId }: Partial<InventoryOrderCharge>) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ description, amount }: Partial<InventoryOrderCharge>) => {
      await InventoryOrderCharges.create({
        inventoryOrderId,
        supplierId,
        description,
        amount,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDERS] });
    },
    onError: handleQueryError('adding charge'),
  });
};

export const useAddTrackingNumber = (inventoryOrderId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (props: Partial<ShipmentTrackingNumber>) => {
      await TrackingNumbers.create({ ...props, inventoryOrderId });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDERS] });
    },
    onError: handleQueryError('adding tracking number'),
  });
};

export const useAddConfirmationNumber = (inventoryOrderId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (props: Partial<OrderConfirmationNumber>) => {
      await OrderConfirmationNumbers.create({ ...props, inventoryOrderId });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDERS] });
    },
    onError: handleQueryError('adding confirmation number'),
  });
};

export const useDestroyConfirmationNumber = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      await OrderConfirmationNumbers.destroy(id);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDERS] });
    },
    onError: handleQueryError('removing confirmation number'),
  });
};

export const useValidateTrackingNumber = (trackingNumber: string, enabled = true): UseQueryResult<string | null> =>
  useQuery<string | null>({
    queryKey: [VALID_TRACKING_NUMBER, trackingNumber],
    queryFn: () => TrackingNumbers.validateTrackingNumber(trackingNumber),
    onError: handleQueryError('validating tracking number'),
    refetchOnWindowFocus: false,
    enabled,
  });

export const useDestroyTrackingNumber = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      await TrackingNumbers.destroy(id);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDERS] });
    },
    onError: handleQueryError('removing tracking number'),
  });
};

export const useUpdateDeliveryDate = (id: number): UseMutationResult<void, unknown, string | null, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (desiredDeliveryDate: string | null): Promise<void> => {
      await InventoryOrders.update({ id, desiredDeliveryDate });
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDER] });
    },
  });
};

export const useUpdatePaymentSource = (id: number): UseMutationResult<void, unknown, IPaymentCardUpdate, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (cardDetails: IPaymentCardUpdate): Promise<void> => {
      await InventoryOrders.update({ id, ...cardDetails });
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDER] });
    },
  });
};

export const useUpdateAddress = (): UseMutationResult<void, unknown, IDeliveryAddress, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ deliveryAddress, portraitAddress }): Promise<void> => {
      await InventoryOrders.updateOrder({
        deliveryAddress,
        portraitAddress,
      } as IOrderUpdate);
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDER] });
    },
    onError: handleQueryError('updating delivery address'),
  });
};

export const usePollPaymentStatus = ({
  id,
  onSuccess,
  enabled = true,
}: {
  id: number;
  onSuccess?: () => void;
  enabled?: boolean;
}): UseQueryResult<string | undefined> =>
  useQuery<string | undefined>({
    enabled,
    onSuccess,
    queryKey: [PAYMENT_STATUS, id],
    queryFn: () => InventoryOrders.validate_payment(id),
    onError: handleQueryError('processing payment'),
    refetchOnWindowFocus: false,
    refetchInterval: PAYMENT_AUTH_TIMEOUT,
    refetchIntervalInBackground: true,
    keepPreviousData: false,
  });

export const useVoidPayment = (id: number): UseMutationResult<void, unknown, void, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (): Promise<void> => {
      await InventoryOrders.void_payment(id);
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDER] });
      await queryClient.invalidateQueries({ queryKey: [PAYMENT_STATUS] });
    },
  });
};

export const useFinalizeOrder = (
  id: number
): UseMutationResult<InventoryOrderWithId | undefined, unknown, boolean | undefined, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (finalized: boolean = true) => InventoryOrders.update({ id, finalized }),
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDERS] });
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDER_STATUS] });
    },
    onError: handleQueryError('submitting order'),
  });
};

export const useResetOrder = (): UseMutationResult<void, unknown, void, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (): Promise<void> => {
      await InventoryOrders.resetOrder();
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDER] });
    },
  });
};

export const useCancelOrder = (id: number): UseMutationResult<void, unknown, void, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (): Promise<void> => {
      await InventoryOrders.update({
        id,
        canceled: true,
      });
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDERS] });
    },
  });
};

export const useAdminNote = (id: number): UseMutationResult<void, unknown, string, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (adminNote): Promise<void> => {
      await InventoryOrders.update({ id, adminNote });
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDERS] });
    },
  });
};

export const useRejectOrder = (
  id: number,
  errorCallback?: (error: Error) => void
): UseMutationResult<InventoryOrderWithId | undefined, unknown, string, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (adminNote: string) =>
      InventoryOrders.update({
        id,
        adminNote,
        rejected: true,
      }),
    onSuccess: async (): Promise<void> => {
      showSnackbar(
        compile('generic.success_message', {
          element: 'Order',
          action: 'rejected',
        }),
        SNACKBAR_SUCCESS
      );

      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDERS] });
    },
    onError: handleQueryError('rejecting order', errorCallback),
  });
};

export const useApproveOrder = (
  id: number,
  errorCallback?: (error: Error) => void
): UseMutationResult<InventoryOrderWithId | undefined, unknown, void, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () =>
      InventoryOrders.update({
        id,
        approved: true,
      }),
    onSuccess: async (): Promise<void> => {
      showSnackbar(
        compile('generic.success_message', {
          element: 'Order',
          action: 'approved',
        }),
        SNACKBAR_SUCCESS
      );

      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDERS] });
    },
    onError: handleQueryError('approving order', errorCallback),
  });
};

export const useApproveSupplier = (
  id: number,
  errorCallback?: (error: Error) => void
): UseMutationResult<InventoryOrderWithId | undefined, unknown, number, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (supplierId: number) => InventoryOrders.update({ id, approvedSupplierId: supplierId }),
    onSuccess: async (): Promise<void> => {
      showSnackbar(
        compile('generic.success_message', {
          element: 'Supplier Order',
          action: 'approved',
        }),
        SNACKBAR_SUCCESS
      );

      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDERS] });
    },
    onError: handleQueryError('approving supplier order', errorCallback),
  });
};

export const useRemoveInventoryOrderItem = (id: number): UseMutationResult<void, unknown, void, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (): Promise<void> => {
      await InventoryOrderItems.destroy(id);
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDERS] });
    },
  });
};

export const useRemoveCharge = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      await InventoryOrderCharges.destroy(id);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDERS] });
    },
    onError: handleQueryError('removing charge'),
  });
};

export const useConfirmDelivery = (
  id: number
): UseMutationResult<void, unknown, Partial<PurchaseOrderItem>, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ lot, expireAt }: Partial<PurchaseOrderItem>): Promise<void> => {
      await PurchaseOrderItems.update({ id, lot, expireAt });
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDERS] });
    },
    onError: handleQueryError('confirming delivery'),
  });
};

export const useShipOrder = (id: number): UseMutationResult<void, unknown, void, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (): Promise<void> => {
      await InventoryOrders.ship(id);
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDERS] });
    },
  });
};

export const useDeleteInventoryOrderSpecialItem = (initialInventoryOrderSpecialId?: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (inventoryOrderSpecialId?: number): Promise<void> => {
      const idToDelete = inventoryOrderSpecialId ?? initialInventoryOrderSpecialId;
      if (!idToDelete) {
        throw new Error('inventoryOrderSpecialId is required for deletion');
      }
      await InventoryOrders.deleteInventoryOrderSpecial(idToDelete);
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDER] });
      await queryClient.invalidateQueries({ queryKey: [INVENTORY_ORDERS] });
    },
  });
};

export default useInventoryOrders;
