import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  paymentSettingsContainer: {
    paddingTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  paymentMethodsListContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.25rem',
    position: 'relative',
  },
  paymentMethodsListItem: {
    backgroundColor: '#FFFFFF',
    borderRadius: '0.375rem',
    border: '1px solid #D5D5D5',
    padding: '0.75rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    margin: 0,
  },
  paymentMethodsListItemSelected: {
    border: '1px solid #29564D',
  },
  ccActions: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  ccDefault: {
    backgroundColor: 'rgba(18, 87, 77, 0.15)',
    padding: '0.125rem 0.5rem',
    color: 'rgba(18, 87, 77, 1)',
    borderRadius: '0.25rem',
  },
  ccNotDefault: {
    color: 'rgba(18, 87, 77, 1)',
    cursor: 'pointer',
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.75rem',
    width: '100%',
    '& *': {
      margin: 0,
    },
  },
  paymentSettingsActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
