import API_ROUTES from 'src/constants/apiRoutes.constants';
import { filterUnusedParams, showSnackbar } from 'src/utils/global';
import { SNACKBAR_ERROR } from 'src/constants/general.constants';
import {
  IOrderingAllowed,
  InventoryOrderFull,
  IOrderUpdate,
  IInventoryOrderPeriod,
  InventoryOrderWithId,
  OrderLookupParams,
  IDeliveryBlackoutDates,
  InventoryOrderItem,
  PossibleInventoryOrderResponse,
  InventoryOrderEditable,
} from '../interfaces/IInventoryOrder';
import Api from './Api';
import compile from '../utils/toastMessagesCompiler';

type ApiResponse<T = string> = { data?: T; errors?: string[] };

const InventoryOrders = {
  async getPeriod(): Promise<IInventoryOrderPeriod | null> {
    const { success, data }: { success: boolean; data: IInventoryOrderPeriod | string } = await Api.get(
      API_ROUTES.INVENTORY_ORDER_PERIOD
    );
    return success && typeof data !== 'string' ? data : null;
  },

  async updatePeriod(params: IInventoryOrderPeriod): Promise<void> {
    await Api.post(API_ROUTES.INVENTORY_ORDER_PERIOD, params);
  },

  async getStatus(): Promise<IOrderingAllowed | null> {
    const { success, data }: { success: boolean; data: IOrderingAllowed | string } = await Api.get(
      API_ROUTES.INVENTORY_ORDER_BANNER
    );
    return success && typeof data !== 'string' ? data : null;
  },

  async getOrders(params: any) {
    try {
      return await Api.get(API_ROUTES.INVENTORY_ORDER_HISTORY, filterUnusedParams(params));
    } catch {
      showSnackbar(
        compile('generic.error_message', {
          action: 'fetching',
          element: 'transactions',
        }),
        SNACKBAR_ERROR
      );
      return null;
    }
  },

  async getInfiniteOrders(params: OrderLookupParams): Promise<PossibleInventoryOrderResponse | null> {
    try {
      return await Api.get(API_ROUTES.INVENTORY_ORDER_HISTORY, params);
    } catch {
      showSnackbar(
        compile('generic.error_message', {
          action: 'fetching',
          element: 'transactions',
        }),
        SNACKBAR_ERROR
      );
      return null;
    }
  },

  async getCurrentOrder(): Promise<InventoryOrderFull | null> {
    const { data }: { data: InventoryOrderFull } = await Api.post(API_ROUTES.INVENTORY_ORDER_HISTORY, {});
    return data;
  },

  async getBlackoutDates(): Promise<IDeliveryBlackoutDates[] | null> {
    const { data }: { success: boolean; data: IDeliveryBlackoutDates[] } = await Api.get(
      API_ROUTES.INVENTORY_ORDER_BLACKOUT_DATES()
    );
    return data;
  },

  async createBlackoutDate(blackoutDate: string): Promise<IDeliveryBlackoutDates | null> {
    const { data }: { success: boolean; data: IDeliveryBlackoutDates } = await Api.post(
      API_ROUTES.INVENTORY_ORDER_BLACKOUT_DATES(),
      { blackoutDate }
    );
    return data;
  },

  async destroyBlackoutDate(id: number): Promise<IDeliveryBlackoutDates | null> {
    const { data }: { success: boolean; data: IDeliveryBlackoutDates } = await Api.delete(
      API_ROUTES.INVENTORY_ORDER_BLACKOUT_DATES(id)
    );
    return data;
  },

  async resetOrder(): Promise<void> {
    await Api.post(API_ROUTES.INVENTORY_ORDER_RESET, {});
  },

  async updateOrder(params: IOrderUpdate): Promise<{ success: boolean; data: any[]; error: string }> {
    return Api.post(API_ROUTES.INVENTORY_ORDER_CURRENT, params);
  },

  async updateOrderSpecialOfferItem(params: Partial<InventoryOrderItem>): Promise<void> {
    await Api.post(API_ROUTES.INVENTORY_ORDER_SPECIAL_ITEM_UPSERT, params);
  },

  async removeOrderItem(params: IOrderUpdate): Promise<void> {
    await Api.post(API_ROUTES.INVENTORY_ORDER_REMOVE, params);
  },

  async confirmDelivery(params: IOrderUpdate): Promise<void> {
    await Api.post(API_ROUTES.INVENTORY_ORDER_CONFIRM_DELIVERY, params);
  },

  async ship(id: number): Promise<InventoryOrderWithId> {
    return Api.put(API_ROUTES.INVENTORY_ORDER(id, 'ship'), {});
  },

  async update({
    id,
    ...params
  }: Omit<InventoryOrderEditable, 'id'> & { id: number }): Promise<InventoryOrderWithId | undefined> {
    const { data } = (await Api.put(API_ROUTES.INVENTORY_ORDER(id), params)) as ApiResponse<InventoryOrderWithId>;
    return data;
  },

  async validate_payment(id: number): Promise<string | undefined> {
    const { data } = (await Api.get(API_ROUTES.INVENTORY_ORDER(id, 'validate_payment'))) as ApiResponse;
    return data;
  },

  async void_payment(id: number): Promise<string | undefined> {
    const { data } = (await Api.get(API_ROUTES.INVENTORY_ORDER(id, 'void_payment'))) as ApiResponse;
    return data;
  },

  async deleteInventoryOrderSpecial(inventoryOrderSpecialId: number): Promise<void> {
    await Api.delete(`/v4/inventory_orders/inventory_order_specials/${inventoryOrderSpecialId}`);
  },
};

export { InventoryOrders, InventoryOrders as default };
