import React, { useEffect, PropsWithChildren } from 'react';
import { useSelector, connect } from 'react-redux';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';

import { isArray, isString } from 'lodash';
import { ADMIN_ROLE, MEDSPA_ADMIN_ROLE } from 'src/constants/general.constants';
import { hasAccessTo } from './auth.utils';
import { RootState, dispatch } from '../rematch';
import compile from './toastMessagesCompiler';

export interface NewPrivateRouteProps extends PropsWithChildren<RouteProps> {
  permission: string | string[];
  roles: string | string[];
}

export type NewPrivateRouteWithoutRolesProps = Omit<NewPrivateRouteProps, 'roles'>;

const mapState = () => ({});

const NewPrivateRoute = (props: NewPrivateRouteProps) => {
  const location = useLocation();

  const { permission, roles: roleOrRoles } = props;
  const permissions = useSelector(({ auth }: RootState) => auth.permissions);
  const isAuthenticated = useSelector(({ auth }: RootState) => auth.isAuthenticate);
  const userType = useSelector(({ auth }: RootState) => auth.userType);
  const isLoading = useSelector(({ auth }: RootState) => auth.isLoading);

  useEffect(() => {
    if (!isAuthenticated) {
      let params = '';
      const ehrLoggedOut = sessionStorage.getItem('ehr_loggedOut');
      if (ehrLoggedOut) {
        sessionStorage.removeItem('ehr_loggedOut');
      } else {
        params = `?nextURL=${encodeURIComponent(`/${window.location.href.split('/').slice(3, 100).join('/')}`)}`;
      }
      window.location.href = `/login${params}`;
    }
  }, [isAuthenticated]);

  if (isLoading) {
    return <h1>LOADING USER</h1>;
  }

  let isValidRole = false;

  if (isString(roleOrRoles)) {
    const isMedspaAdmin = userType === ADMIN_ROLE && roleOrRoles === MEDSPA_ADMIN_ROLE;
    isValidRole = userType === roleOrRoles || isMedspaAdmin;
  }

  if (isArray(roleOrRoles) && userType) {
    isValidRole = roleOrRoles.includes(userType) || roleOrRoles.includes(MEDSPA_ADMIN_ROLE);
  }

  let hasPermission = false;

  if (permission) {
    if (isArray(permission)) {
      hasPermission = permission.every((perm) => hasAccessTo(perm, permissions));
    } else {
      hasPermission = hasAccessTo(permission, permissions);
    }
  }

  const canAccess = isValidRole && hasPermission;

  if (!canAccess) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: compile('generic.no_permission_to_access'),
        type: 'error',
        duration: 3000,
      },
    });
    return <Redirect to={{ pathname: '/', state: { from: location } }} />;
  }

  return <Route {...props} />;
};

export default connect(mapState)(NewPrivateRoute);
