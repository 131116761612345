import React from 'react';
import { Box, Typography } from '@material-ui/core';
// eslint-disable-next-line max-len
import { OrderDetailsStlyes as useStyles } from 'src/components/DashboardPractitioner/Tabs/OrderingTab/inventoryOrder.styles';
import { DISPLAY_DATE_FORMAT_VARIANT } from 'src/constants/inventory.constants';
import moment from 'moment';
import DeliveryAddress from 'src/components/DashboardPractitioner/Tabs/OrderingTab/DeliveryAddress';
import CreditCardIcon from 'src/components/PatientProfile/CreditCards/CreditCardIcon';
import { CardBrand } from 'src/interfaces/IMedspaAdminList';
import { InventoryOrder } from 'src/interfaces/IInventoryOrder';

export const OrderDetails = ({
  inventoryOrder: { desiredDeliveryDate, referenceNumber, deliveryLocation, roleName, last4, cardBrand },
}: {
  inventoryOrder: InventoryOrder;
}) => {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column" margin="0 10px">
      <Typography className={classes.title}>Order Details</Typography>
      <Box display="flex" flexDirection="row">
        <Box className={classes.box}>
          <Box>
            <Typography>Order</Typography>
            <Typography>#{referenceNumber}</Typography>
          </Box>
          <Box>
            <Typography>Delivery Address</Typography>
            <Typography>
              <DeliveryAddress className={classes.address} location={deliveryLocation} singleLine />
            </Typography>
          </Box>
          <Box>
            <Typography>Desired Delivery</Typography>
            <Typography>{moment.utc(desiredDeliveryDate).format(DISPLAY_DATE_FORMAT_VARIANT)}</Typography>
          </Box>
        </Box>
        <Box className={classes.box}>
          {!!last4 && !!cardBrand && (
            <Box>
              <Typography>Payment Method</Typography>
              <Box className={classes.cardBox}>
                <Typography>*{last4}</Typography>
                <CreditCardIcon cardBrand={cardBrand as CardBrand} />
              </Box>
            </Box>
          )}
          <Box>
            <Typography>Product Type</Typography>
            <Typography>{roleName}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OrderDetails;
