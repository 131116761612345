/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { CheckCircle as CheckCircleIcon, Cancel as CancelIcon } from '@material-ui/icons';
import { useMutation, useQueryClient } from 'react-query';
import { IFeature } from '../../../interfaces/IFeature';
import { useStyles } from './EditPractitionerFeaturesColumn.styles';
import { Practitioners } from '../../../services/Practitioners';
import { PRACTITIONERS } from '../../../constants/reactQuery.keys';
import { dispatch } from '../../../rematch';
import { PORTRAIT_LEGACY, PORTRAIT_LEGACY_PLUS } from '../../../constants/general.constants';
import {
  FEATURE_SERVICE_VISIT_V3,
  EDITABLE_FLEX_LUXE_FEATURES,
  FEATURE_NEW_PHYSICIAN_DASHBOARD,
  FEATURE_MEDSPA_INTERFACE,
} from '../../../constants/features.constants';

const EditPractitionerFeaturesColumn = ({
  features,
  enabledFeatures,
  id,
  refetch,
  role,
  userGroupId,
}: {
  features: IFeature[];
  enabledFeatures: IFeature[];
  id: number;
  refetch: () => void;
  role: string | undefined;
  userGroupId: number | undefined | null;
}) => {
  const styles = useStyles();
  const [edited, setEdited] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [selectedFeatures, setSelectedFeatures] = useState<IFeature[]>(enabledFeatures);
  const queryClient = useQueryClient();
  const { mutate: updatePractitioner } = useMutation(Practitioners.editPractitioner, {
    onSuccess: () => {
      queryClient.invalidateQueries([PRACTITIONERS]);
      refetch();
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: `Practitioner ${id} features updated!`,
          duration: 5000,
        },
      });
      setSaving(false);
      setEdited(false);
    },
    onError: () => {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: `Something went wrong, failed to update practitioner ${id} features.`,
          duration: 5000,
          type: 'error',
        },
      });
    },
  });

  const handleToggleFeature = (featureKey: string) => {
    setEdited(true);
    const selectedFeature = features.find((feature) => feature.key === featureKey);
    if (selectedFeature) {
      if (selectedFeatures.find((feature) => feature.key === featureKey)) {
        setSelectedFeatures(selectedFeatures.filter((feature) => feature.key !== featureKey));
      } else {
        setSelectedFeatures([...selectedFeatures, selectedFeature]);
      }
    }
  };

  const handleSaveFeatures = async () => {
    await updatePractitioner({ id, featureSettings: selectedFeatures, skipUpdateInfoParams: true });
  };

  const handleCancelFeatures = () => {
    setSelectedFeatures(enabledFeatures);
    setEdited(false);
  };

  const isLegacyPractitioner = role === PORTRAIT_LEGACY || role === PORTRAIT_LEGACY_PLUS;
  const isMedspaProvider = !!userGroupId && !isLegacyPractitioner;

  return (
    <div>
      {features
        .filter((feature) => {
          if (!isLegacyPractitioner) {
            return EDITABLE_FLEX_LUXE_FEATURES.includes(feature.key);
          }
          return feature.key !== FEATURE_NEW_PHYSICIAN_DASHBOARD;
        })
        .map((feature) => {
          if (feature.key === FEATURE_SERVICE_VISIT_V3 || feature.key === FEATURE_MEDSPA_INTERFACE) {
            return null;
          }
          return (
            <span className={styles.feature}>
              <span className={styles.icon} onClick={() => !isMedspaProvider && handleToggleFeature(feature.key)}>
                {selectedFeatures.find((selectedFeature) => selectedFeature.key === feature.key) ? (
                  <CheckCircleIcon style={{ color: '#3A8F3E' }} />
                ) : (
                  <CancelIcon style={{ color: 'red' }} />
                )}
              </span>
              {feature.name}
            </span>
          );
        })}
      {edited && (
        <div className={styles.buttons}>
          <button type="button" className={styles.cancelButton} onClick={handleCancelFeatures}>
            Cancel
          </button>
          <button type="button" className={styles.saveButton} onClick={handleSaveFeatures}>
            {saving ? 'Saving...' : 'Save'}
          </button>
        </div>
      )}
    </div>
  );
};

export default EditPractitionerFeaturesColumn;
