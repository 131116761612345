import React, { useState } from 'react';
import { Box, Dialog, IconButton, MenuItem, Menu } from '@material-ui/core';
import { useStyle } from 'src/components/DashboardPractitioner/Tabs/OrderingTab/inventoryOrder.styles';
import { MoreVert as MoreVertIcon, Close as CloseIcon } from '@material-ui/icons';
import { modalStyles } from '../../OrderApprovalRow/OrderApprovalRow.styles';
import BlackoutDates from './BlackoutDates';
import { BannerDates } from './BannerDates';

type Props = {
  disabled: boolean;
};

export const OrderApprovalDateModal = ({ disabled }: Props) => {
  const classes = useStyle();
  const modalClasses = modalStyles();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLegacyMode, setIsLegacyMode] = useState<boolean>(false);
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setMenuAnchor(menuAnchor ? null : event.currentTarget);

  const handleMenuItemClick = (legacy = false) => {
    setIsOpen(true);
    setIsLegacyMode(legacy);
    setMenuAnchor(null);
  };

  return (
    <>
      <Box width={40} height={40} margin="auto 2px 0 auto">
        <IconButton
          onClick={handleMenuClick}
          className={classes.orderMenu}
          data-size="large"
          data-testid="order-approval-dates-menu"
        >
          {menuAnchor ? <CloseIcon /> : <MoreVertIcon />}
        </IconButton>
      </Box>
      <Menu
        open={Boolean(menuAnchor)}
        anchorEl={menuAnchor}
        onClose={handleMenuClick}
        PaperProps={{ className: classes.orderMenuOpen }}
      >
        <MenuItem className={classes.orderMenuItem} disabled={disabled} onClick={() => handleMenuItemClick()}>
          Holiday Dates
        </MenuItem>
        <MenuItem className={classes.orderMenuItem} disabled={disabled} onClick={() => handleMenuItemClick(true)}>
          Legacy Banner Dates
        </MenuItem>
      </Menu>
      <Dialog open={isOpen} onBackdropClick={() => setIsOpen(false)} maxWidth="md">
        <Box className={modalClasses.confirmDialog} data-testid="addTracking">
          <Box className={modalClasses.confirmContent}>
            {isLegacyMode ? (
              <BannerDates closeDialog={() => setIsOpen(false)} />
            ) : (
              <BlackoutDates closeDialog={() => setIsOpen(false)} />
            )}
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default OrderApprovalDateModal;
