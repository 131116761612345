import React, { useMemo, useState } from 'react';
import {
  CircularProgress,
  Table,
  TableContainer,
  TableRow,
  TableBody,
  Box,
  MenuItem,
  Checkbox,
  FormControl,
  InputLabel,
  Button,
} from '@material-ui/core';
import { Cancelable, debounce } from 'lodash';
import { useDocuments } from 'src/hooks/queries/useDocuments';
import { IDocument } from 'src/interfaces/documents.interface';
import { formatLocalDateTime } from 'src/utils/formatDate';
import { useMedspaPractitioners } from 'src/hooks/queries/usePractitioners';
import { IPractitioner } from 'src/interfaces/IPractitioner';
import InputSearch from 'src/components/common/InputSearch';
import TablePaginatorHeader from 'src/components/common/Table/TablePaginatorHeader';
import DocumentsModal from 'src/components/Documents/DocumentsModal';
import Page from 'src/components/common/Page';
import { SelectCustom, useStyles, TableCellCustom } from './documents.styles';

const DOCUMENT_COLUMNS = [
  {
    field: 'documentName',
    title: 'Document Name',
    sortable: true,
    sortField: 'name',
  },
  {
    field: 'uploadedOn',
    title: 'Uploaded on',
    sortable: true,
    sortField: 'created_at',
  },
  {
    field: 'practitionerId',
    title: 'Practitioner ID',
    sortable: false,
  },
];

const INITIAL_FILTERS = {
  month: -1,
  showAll: false,
  practitionerId: 'all',
  documentName: '',
  sort: `name ASC`,
};

const INITIAL_PRACTITIONER_FILTER = {
  includeAll: true,
};

const Documents = () => {
  const classes = useStyles();
  const [filters, setFilters] = useState(INITIAL_FILTERS);
  const [search, setSearch] = useState('');
  const [selectedDocument, setSelectedDocument] = useState<IDocument | null>(null);
  const [openModal, setOpenModal] = useState<boolean & Boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean & Boolean>(false);
  const [sortBy, setSortBy] = useState(filters.sort.split(' ')[0]);
  const [sortDir, setSortDir] = useState(filters.sort.split(' ')[1]);
  const { data: practitioners = [], isLoading: isLoadingPractitioners } = useMedspaPractitioners(
    INITIAL_PRACTITIONER_FILTER,
    true,
    () => 'v3/practitioners'
  );
  const { data: documents = [], isFetching: isFetchingDocuments } = useDocuments(filters);

  const handleCellClick = (document: IDocument) => {
    setSelectedDocument(document);
    setIsEditMode(true);
    setOpenModal(!openModal);
  };

  const doSearch: ((inputSearch: string) => void) & Cancelable = useMemo(
    () =>
      debounce((inputSearch: string) => {
        setFilters({
          ...filters,
          documentName: inputSearch,
        });
      }, 1500),
    [filters]
  );

  const handleSearchInput = (searchInput: string) => {
    if (typeof searchInput === 'string' && !isFetchingDocuments) {
      setSearch(searchInput);
      doSearch(searchInput);
    }
  };

  const handlePractitionerChange = (practitionerId: string) => {
    setFilters({
      ...filters,
      practitionerId,
    });
  };

  const onChangeSortBy = (column: string, direction: string) => {
    setSortBy(column);
    setSortDir(direction.toUpperCase());
    setFilters({
      ...filters,
      sort: `${column} ${direction.toUpperCase()}`,
    });
  };

  const ALL_DOCUMENT_COLUMNS = [
    ...DOCUMENT_COLUMNS,
    {
      field: 'deletedAt',
      title: 'Deleted date',
      sortable: false,
    },
  ];

  return (
    <Page title="Documents">
      <Box style={{ width: '100%' }}>
        <Box className={classes.filters}>
          <Box>
            <FormControl size="small" className={classes.formControl} variant="outlined">
              <InputLabel style={{ fontFamily: 'Messina Sans Regular' }}>Practitioners</InputLabel>
              <SelectCustom
                disabled={isLoadingPractitioners}
                fullWidth
                label="Practitioners"
                name="practitionerId"
                onChange={(e) => handlePractitionerChange(e.target.value as string)}
                value={filters.practitionerId}
                displayEmpty
              >
                <MenuItem key="all-providers" value="all">
                  All
                </MenuItem>
                {(practitioners as IPractitioner[]).map(({ id, firstName, lastName }: IPractitioner) => (
                  <MenuItem key={id} value={id}>
                    {`${firstName} ${lastName}`}
                  </MenuItem>
                ))}
              </SelectCustom>
            </FormControl>
          </Box>
          <Box style={{ display: 'flex', gap: '10px' }}>
            <Box>
              <Checkbox
                disabled={isFetchingDocuments}
                onChange={() => {
                  setFilters({
                    ...filters,
                    showAll: !filters.showAll,
                  });
                }}
                checked={filters.showAll}
                className={classes.checkbox}
                color="default"
              />
              Show all
            </Box>
            <Box style={{ width: '150px' }}>
              <InputSearch
                placeholder="Document name"
                onChangeValue={(event) => handleSearchInput(event.target.value)}
                value={search}
                isLoading={isFetchingDocuments}
              />
            </Box>
            <Button
              data-cy="uploadBtn"
              title="Upload"
              className={classes.applyButton}
              onClick={() => {
                setSelectedDocument(null);
                setIsEditMode(false);
                setOpenModal(true);
              }}
            >
              Upload
            </Button>
          </Box>
        </Box>

        <TableContainer>
          {isFetchingDocuments || isLoadingPractitioners ? (
            <div className={classes.loading}>
              <CircularProgress style={{ color: 'black' }} size={30} />
              <p>Fetching documents...</p>
            </div>
          ) : (
            <Table>
              <TablePaginatorHeader
                columns={!filters.showAll ? DOCUMENT_COLUMNS : ALL_DOCUMENT_COLUMNS}
                sortBy={sortBy}
                sortDirection={sortDir.toLowerCase() as any}
                onSortChange={onChangeSortBy}
              />
              <TableBody>
                {(documents as IDocument[]).map((document: IDocument) => {
                  if (filters.practitionerId === '') {
                    return <></>;
                  }
                  const {
                    day: uploadedDay,
                    month: uploadedMonth,
                    year: uploadedYear,
                  } = formatLocalDateTime(document.createdAt);
                  const {
                    day: deletedDay,
                    month: deletedMonth,
                    year: deletedYear,
                  } = formatLocalDateTime(document.deletedAt as string);

                  return (
                    <TableRow className={classes.tableRow} key={document.id} onClick={() => handleCellClick(document)}>
                      <TableCellCustom>{document.name}</TableCellCustom>
                      <TableCellCustom>{` ${uploadedMonth} ${uploadedDay}, ${uploadedYear}`}</TableCellCustom>
                      <TableCellCustom>{document.practitionerId}</TableCellCustom>
                      {filters.showAll && (
                        <TableCellCustom>
                          {document.deletedAt ? ` ${deletedMonth} ${deletedDay}, ${deletedYear}` : ''}
                        </TableCellCustom>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <DocumentsModal
          open={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
          document={selectedDocument as IDocument}
          editMode={isEditMode}
          practitioners={practitioners}
        />
      </Box>
    </Page>
  );
};

export default Documents;
