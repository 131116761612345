import { Box } from '@material-ui/core';
import React from 'react';
import { ErrorOutline as ErrorIcon } from '@material-ui/icons';
import { EHRButton, EHRModal } from 'src/components/ui/v1';
import { useCancelMedspaAppointment } from 'src/hooks/medspaCalendar/useAppointments';

interface MedspaCalendarAppointmentCancelModalProps {
  open: boolean;
  appointment: any;
  onClose: () => void;
  setAction: (value: string | null) => void;
}

export const MedspaCalendarAppointmentCancelModal = ({
  open,
  setAction,
  onClose,
  appointment,
}: MedspaCalendarAppointmentCancelModalProps) => {
  const { mutate: cancelAppointmentMutation, isLoading: isCancellingAppointment } = useCancelMedspaAppointment(
    appointment?.id as number,
    () => onClose()
  );

  const onCancelAppointment = () => {
    cancelAppointmentMutation();
  };

  return (
    <EHRModal open={open} dataCy="modal-cancel-appointment">
      <EHRModal.Body dataCy="modal-cancel-body">
        <Box display="flex" gridGap="1rem" flexDirection="column">
          <Box display="flex" width="100%" justifyContent="center">
            <ErrorIcon
              style={{
                fill: '#EC403E',
                width: '6rem',
                height: '6rem',
              }}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            gridGap="2rem"
            style={{
              fontSize: '1rem',
              lineHeight: '1.5rem',
            }}
          >
            Are you sure you want to cancel this appointment?
          </Box>
        </Box>
      </EHRModal.Body>

      <EHRModal.Footer dataCy="modal-cancel-footer">
        <Box display="flex" width="100%" justifyContent="center" gridGap="1rem">
          <EHRButton
            dataCy="btn-cancel-cancel-appointment"
            color="default"
            variant="outlined"
            onClick={() => {
              setAction(null);
            }}
            text="No"
            disabled={isCancellingAppointment}
          />

          <EHRButton
            dataCy="btn-confirm-cancel-appointment"
            color="danger"
            onClick={onCancelAppointment}
            text="Yes"
            disabled={isCancellingAppointment}
          />
        </Box>
      </EHRModal.Footer>
    </EHRModal>
  );
};
