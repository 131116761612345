/* eslint-disable react/destructuring-assignment */
import { Box, Switch, SwitchProps, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';

const useStyles = makeStyles({
  ehrSwitch: {
    '&.MuiSwitch-root': {
      height: '3.25rem',
      width: '4.5rem',
      '& .MuiSwitch-switchBase': {
        padding: '0.87rem',
        '& + .MuiSwitch-track': {
          backgroundColor: '#A6A6A6',
          opacity: 1,
        },
        '&.Mui-checked': {
          transform: 'translateX(1.25rem)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            backgroundColor: '#12574d',
            opacity: 1,
          },
        },
        '& .MuiSwitch-thumb': {
          width: '1.5rem',
          height: '1.5rem',
          boxShadow: 'none',
        },
      },
      '& .MuiSwitch-track': {
        borderRadius: '100px',
      },
    },
  },
  ehrSwitchTextContainer: {
    border: '1px solid #C8C8C8',
    borderRadius: '0.375rem',
    padding: '0.725rem',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      borderColor: '#3a3b3f',
    },
  },
  ehrSwitchTextBody: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '98%',
    paddingTop: '0.5rem',
  },
  ehrSwitchTextHeader: {
    fontWeight: 'bold',
    color: '#757575',
    fontSize: '101%',
  },
  ehrSwitchText: {
    opacity: 0.6,
    marginRight: '1rem',
  },
});

interface EHRSwitchProps extends SwitchProps {
  dataCy: string;
}

const EHRSwitch = (props: EHRSwitchProps) => {
  const { onChange, checked, className, color } = props;
  const classes = useStyles();

  return (
    <Switch
      {...props}
      data-cy={props.dataCy}
      onChange={onChange}
      checked={checked}
      color={color}
      className={classNames(classes.ehrSwitch, className)}
    />
  );
};

interface EHRSwitchWithTextProps extends EHRSwitchProps {
  text: string;
  label: string;
}
const EHRSwitchWithText = (props: EHRSwitchWithTextProps) => {
  const { text, label, onChange, checked, className, color } = props;
  const classes = useStyles();

  return (
    <Box className={classes.ehrSwitchTextContainer}>
      <Box className={classes.ehrSwitchTextHeader}>{label}</Box>
      <Box className={classes.ehrSwitchTextBody}>
        <Box className={classes.ehrSwitchText}>{text}</Box>
        <Box>
          <Switch
            {...props}
            onChange={onChange}
            checked={checked}
            color={color}
            className={classNames(classes.ehrSwitch, className)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export { EHRSwitch, EHRSwitchWithText };
