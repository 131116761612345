import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { ReactComponent as UploadProfileIcon } from 'src/assets/images/upload-profile-image.svg';
import { Box, Button, FormHelperText, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { snakeCase } from 'lodash';
import { useStyles } from 'src/components/DashboardAdministrator/Medspas/MedspaModal.styles';
import { EHRButton, EHRCRUDModal, EHRTextField, EHRTypography } from 'src/components/ui/v1';
import DragAndDropComponent from 'src/components/DragAndDrop';
import { RootState } from 'src/rematch';
import { LinkedAccount } from 'src/types/AuthState';
import CredentialsDropdown from 'src/components/common/CredentialsDropdown';
import {
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  PHONE,
  PROFILE_IMAGE,
  SIGNATURE_IMAGE,
  VALID_EMAIL,
  VALID_PHONE,
  VALID_PHONE_REGEX,
} from '../../../constants/schemaForm.constants';
import { IMedspaAdminListEntry } from '../../../interfaces/IMedspaAdminList';
import useCreateMedspaAdmin, { useUpdateMedspaAdmin } from '../../../hooks/queries/useMedspaAdmins';
import { EHRCheckbox } from '../../../components/ui/v1';

const ProviderSchema = yup.object().shape({
  firstName: yup.string().required(FIRST_NAME),
  lastName: yup.string().required(LAST_NAME),
  email: yup.string().required(EMAIL).email(VALID_EMAIL),
  phone: yup.string().required(PHONE).matches(VALID_PHONE_REGEX, VALID_PHONE),
  credentials: yup.string(),
  signatureImage: yup.string().when('isOperatingProvider', (value: any) => {
    if (value) {
      return yup.string().required(SIGNATURE_IMAGE).nullable();
    }
    return yup.string().nullable();
  }),
  profileImage: yup.string().required(PROFILE_IMAGE),
  position: yup.string(),
});

export const UpsertMedspaAdminModal = ({
  open,
  onClose,
  userGroupAdmin,
  linkedPractitioner,
}: {
  open: boolean;
  onClose: () => void;
  userGroupAdmin?: IMedspaAdminListEntry;
  linkedPractitioner?: LinkedAccount;
}) => {
  const classes = useStyles();
  const { userGroupId } = useSelector(({ auth }: RootState) => auth);
  const [userActive, setUserActive] = useState<boolean>(true);
  const [profileImageFile, setProfileImageFile] = useState<File>();
  const [signatureImageFile, setSignatureImageFile] = useState<File>();
  const [signaturePreview, setSignaturePreview] = useState<string | null>(null);
  const [profilePreview, setProfilePreview] = useState<string | null>(null);
  const [isOperatingProvider, setIsOperatingProvider] = useState<boolean>(false);
  const { mutateAsync: createMedspaAdmin, isLoading: isCreatingMedspaAdmin } = useCreateMedspaAdmin();
  const { mutateAsync: updateMedspaAdmin, isLoading: isUpdatingMedspaAdmin } = useUpdateMedspaAdmin();

  const isLoading = isCreatingMedspaAdmin || isUpdatingMedspaAdmin;

  const { control, handleSubmit, errors, setValue, getValues, watch, reset } = useForm({
    resolver: yupResolver(ProviderSchema),
    defaultValues: {
      id: 0,
      active: true,
      firstName: '',
      lastName: '',
      profileImage: '',
      signatureImage: '',
      email: '',
      phone: '',
      position: '',
      credentials: '',
      isOperatingProvider: false,
    },
  });

  const profileImage = watch('profileImage');

  useEffect(() => {
    if (userGroupAdmin && userGroupAdmin.id !== getValues('id')) {
      setValue('id', userGroupAdmin.id);
      setValue('firstName', userGroupAdmin.firstName);
      setValue('lastName', userGroupAdmin.lastName);
      setValue('email', userGroupAdmin.email);
      setValue('phone', userGroupAdmin.phone);
      setValue('profileImage', userGroupAdmin.profileImageUrl || '');
      setValue('active', userGroupAdmin.active);
      setProfilePreview(userGroupAdmin.profileImageUrl || '');
      setUserActive(userGroupAdmin.active);
    } else if (!userGroupAdmin) {
      setValue('id', 0);
      setValue('firstName', '');
      setValue('lastName', '');
      setValue('email', '');
      setValue('phone', '');
      setValue('profileImage', '');
      setValue('active', true);
      setProfilePreview('');
      setUserActive(true);
    }
  }, [userGroupAdmin]);

  useEffect(() => {
    if (linkedPractitioner) {
      setValue('credentials', linkedPractitioner?.licenses);
      setValue('position', linkedPractitioner?.position);
      setValue('signatureImage', linkedPractitioner.signatureUrl || '');
      setValue('isOperatingProvider', !!linkedPractitioner);
      setIsOperatingProvider(!!linkedPractitioner);
      setSignaturePreview(linkedPractitioner?.signatureUrl || '');
    } else {
      setValue('credentials', '');
      setValue('position', '');
      setValue('signatureImage', '');
      setValue('isOperatingProvider', false);
      setIsOperatingProvider(false);
      setSignaturePreview('');
    }
  }, [linkedPractitioner?.id]);

  const handleProfilePicChange = (fileData: File[]) => {
    const file = fileData?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePreview((reader.result || '').toString());
        // @ts-ignore
        setValue('profileImage', file, { shouldValidate: true });
        setProfileImageFile(file);
      };
      reader.readAsDataURL(file);
    } else {
      setValue('profileImage', '', { shouldValidate: true });
    }
  };

  const handleSignatureChange = (fileData: File[]) => {
    const file = fileData?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSignaturePreview((reader.result || '').toString());
        // @ts-ignore
        setValue('signatureImage', file, { shouldValidate: true });
        setSignatureImageFile(file);
      };
      reader.readAsDataURL(file);
    } else {
      setValue('signatureImage', '', { shouldValidate: true });
    }
  };

  const buttonText = userGroupAdmin ? 'Edit' : 'Add';

  const onSubmit = async (formData: any) => {
    if (!userGroupId) {
      return;
    }

    const data = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: formData.phone,
      position: formData.position,
      licenses: formData.credentials,
      active: userActive,
    };

    const userFormData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      userFormData.append(snakeCase(key), value);
    });

    if (isOperatingProvider && !linkedPractitioner) {
      // @ts-ignore
      userFormData.append('provision_provider_account', true);
    }

    if (signatureImageFile) {
      userFormData.append('signature_image', signatureImageFile);
    }

    if (profileImageFile && typeof profileImage === 'object') {
      userFormData.append('profile_image', profileImageFile);
    }

    if (userGroupAdmin?.id) {
      await updateMedspaAdmin({
        params: userFormData,
        userId: userGroupAdmin.id,
        userGroupId,
      });
    } else {
      await createMedspaAdmin({
        params: {
          ...data,
          ...{
            signatureImage: signatureImageFile,
            profileImage: profileImageFile,
            provisionProviderAccount: isOperatingProvider && !linkedPractitioner,
          },
        },
        userGroupId,
      });
      reset();
      setProfilePreview('');
      setIsOperatingProvider(false);
      setSignaturePreview('');
    }
    onClose();
  };

  return (
    <EHRCRUDModal dataCy="modal-edit-medspa-user" open={open} onClose={onClose}>
      <EHRCRUDModal.Title
        dataCy="modal-new-medspa-user-modal-title"
        title={`${userGroupAdmin ? 'Edit' : 'New'} Admin`}
        handleClose={onClose}
      />

      <EHRCRUDModal.Body dataCy="modal-new-medspa-modal-body">
        <Box display="flex" justifyContent="space-between" flexDirection="column" gridGap="0.25rem" width="100%">
          <Box
            className={classNames(classes.headShotContainer, errors?.profileImage ? 'ehrUploadContainerError' : null)}
          >
            <EHRTypography
              dataCy="label-upload-headsot"
              variant="label"
              error={!!errors?.profileImage}
              style={{ marginBottom: '0.5rem' }}
            >
              Upload Headshot
            </EHRTypography>
            <input
              accept="image/*"
              id="profile-photo-upload"
              type="file"
              name="profilePicture"
              style={{ display: 'none' }}
            />
            <label htmlFor="profile-photo-upload" className={classes.uploadProfileImageContainer}>
              {profilePreview ? (
                <Box display="flex" alignItems="center">
                  <img src={profilePreview} alt="Profile preview" className={classes.profileImage} />
                </Box>
              ) : (
                <UploadProfileIcon className={classes.profileImage} />
              )}
              <DragAndDropComponent
                hideDocumentIcon
                height="80px"
                hideRemoveFile
                acceptedFileType="image/png,image/jpg,image/jpeg"
                onFileChange={handleProfilePicChange}
                error={!!errors?.profileImage}
              />
            </label>
            <FormHelperText error>{errors?.profileImage?.message || ' '}</FormHelperText>
          </Box>

          <Box display="flex" flexDirection="row" gridGap="1rem">
            <Box flex={1}>
              <Controller
                control={control}
                name="firstName"
                rules={{ required: 'First name is required' }}
                render={({ onChange, value }) => (
                  <EHRTextField
                    dataCy="input-firstName"
                    data-cy="firstName"
                    name="firstName"
                    onChange={onChange}
                    value={value}
                    label="First Name"
                    error={!!errors?.firstName}
                    helperText={errors?.firstName?.message || ' '}
                  />
                )}
              />
            </Box>

            <Box flex={1}>
              <Controller
                control={control}
                name="lastName"
                rules={{ required: 'Last name is required' }}
                render={({ onChange, value }) => (
                  <EHRTextField
                    dataCy="input-lastName"
                    data-cy="lastName"
                    name="lastName"
                    onChange={onChange}
                    value={value}
                    label="Last Name"
                    error={!!errors?.lastName}
                    helperText={errors?.lastName?.message || ' '}
                  />
                )}
              />
            </Box>
          </Box>

          <Box>
            <Controller
              control={control}
              name="email"
              rules={{ required: 'Email is required' }}
              render={({ onChange, value }) => (
                <EHRTextField
                  dataCy="input-email"
                  data-cy="email"
                  name="email"
                  onChange={onChange}
                  value={value}
                  label="Email"
                  error={!!errors?.email}
                  helperText={errors?.email?.message || ' '}
                />
              )}
            />
          </Box>

          <Box>
            <Controller
              control={control}
              name="phone"
              rules={{ required: 'Phone number is required' }}
              render={({ onChange, value }) => (
                <EHRTextField
                  dataCy="input-phone"
                  data-cy="phone"
                  name="phone"
                  onChange={onChange}
                  value={value}
                  label="Phone Number"
                  error={!!errors?.phone}
                  helperText={errors?.phone?.message || ' '}
                />
              )}
            />
          </Box>

          <Box className={classes.medspaInfoInput}>
            <EHRTypography dataCy="label-upload-signature" variant="label">
              Status
            </EHRTypography>
            <Box className={classes.medspaActiveStatusContainer}>
              <Button
                className={userActive ? classes.medspaActiveButton : classes.medspaInactiveButton}
                type="button"
                onClick={() => setUserActive(true)}
              >
                Active
              </Button>
              <Button
                className={userActive ? classes.medspaInactiveButton : classes.medspaActiveButton}
                type="button"
                onClick={() => setUserActive(false)}
              >
                Inactive
              </Button>
            </Box>
          </Box>
          <Box className={classes.operatingProviderContainer}>
            <Controller
              name="isOperatingProvider"
              control={control}
              defaultValue={false}
              render={() => (
                <EHRCheckbox
                  dataCy="label-is-operating-provider"
                  label="Operating Provider"
                  onChange={(e) => {
                    setIsOperatingProvider(e.target.checked);
                    setValue('isOperatingProvider', e.target.checked);
                  }}
                  checked={isOperatingProvider}
                  className={classes.operatingProviderCheckbox}
                  disabled={!!linkedPractitioner}
                >
                  <Typography variant="body2" className={classes.operatingProviderSubtext}>
                    If this is checked the MedSpa Admin account will be created with a Provider dashboard experienced
                    linked to it
                  </Typography>
                </EHRCheckbox>
              )}
            />
          </Box>
          {isOperatingProvider && (
            <>
              <Box display="flex" flexDirection="row" gridGap="1rem">
                <Box flex={1}>
                  <Controller
                    control={control}
                    name="credentials"
                    render={({ onChange, value }) => (
                      <CredentialsDropdown onChange={onChange} value={value} hasErrors={!!errors?.credentials} />
                    )}
                  />
                </Box>
              </Box>

              <Box flex={1}>
                <Controller
                  control={control}
                  name="position"
                  render={({ onChange, value }) => (
                    <EHRTextField
                      dataCy="input-position"
                      name="position"
                      onChange={onChange}
                      value={value}
                      label="Position"
                      error={!!errors?.position}
                      helperText={errors?.position?.message || ' '}
                    />
                  )}
                />
              </Box>

              <Box
                className={classNames(
                  classes.signatureImageContainer,
                  errors?.signatureImage ? 'ehrUploadContainerError' : null
                )}
              >
                <Box className={classes.signatureImageHeader}>
                  <EHRTypography
                    dataCy="label-upload-signature"
                    variant="label"
                    error={!!errors?.signatureImage}
                    style={{ marginBottom: '0.5rem' }}
                  >
                    Upload Signature
                  </EHRTypography>

                  {signaturePreview && (
                    <Button
                      type="button"
                      onClick={() => {
                        setSignaturePreview('');
                        setValue('signatureImage', undefined, { shouldValidate: true });
                      }}
                      className={classes.signatureUpdate}
                    >
                      Update
                    </Button>
                  )}
                </Box>

                {signaturePreview ? (
                  <img src={signaturePreview} alt="Signature preview" className={classes.signatureImage} />
                ) : (
                  <DragAndDropComponent
                    hideDocumentIcon
                    height="80px"
                    hideRemoveFile
                    acceptedFileType="image/png,image/jpg,image/jpeg"
                    onFileChange={handleSignatureChange}
                    error={!!errors?.signatureImage}
                  />
                )}
                <FormHelperText error>{errors?.signatureImage?.message || ' '}</FormHelperText>
              </Box>
            </>
          )}
        </Box>
      </EHRCRUDModal.Body>

      <EHRCRUDModal.Footer dataCy="modal-footer-new-medspa">
        <Box display="flex" width="100%">
          <EHRButton
            dataCy="btn-add-modal-new-medspa-user"
            onClick={handleSubmit(onSubmit)}
            disabled={isLoading}
            color="primary"
            text={isLoading ? 'Updating...' : buttonText}
            fullWidth
          />
        </Box>
      </EHRCRUDModal.Footer>
    </EHRCRUDModal>
  );
};
