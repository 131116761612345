// Description: This file contains the constants for the feature flags

// CONSTANTS BELOW SHOULD MAP TO THE KEYS IN THE FEATURES COLUMN IN THE IDENTITY TABLE
export const FEATURE_SERVICE_VISIT_V3 = 'SERVICE_VISIT_V3';
export const FEATURE_MEDSPA_INTERFACE = 'MEDSPA_INTERFACE';
export const FEATURE_BEAUTIBANK = 'BEAUTIBANK';
export const FEATURE_NEW_PHYSICIAN_DASHBOARD = 'NEW_PHYSICIAN_DASHBOARD';
export const FEATURE_INVENTORY_BUDGET = 'INVENTORY_BUDGET';
export const FEATURE_INVENTORY_VALUE = 'INVENTORY_VALUE';
export const FEATURE_ENABLE_CHARTING = 'ENABLE_CHARTING';
export const FEATURE_MEDSPA_MARKETING = 'MEDSPA_MARKETING';
export const FEATURE_CONFIRM_DELIVERY = 'CONFIRM_DELIVERY';
export const FEATURE_INVENTORY_ORDERING_FLEX_LITE = 'INVENTORY_ORDERING_FLEX_LITE';

export const DEPRECATED_FEATURES = [FEATURE_SERVICE_VISIT_V3];
export const DEFAULT_FLEX_LUXE_FEATURES = [FEATURE_BEAUTIBANK];
export const DEFAULT_LEGACY_FEATURES = [FEATURE_BEAUTIBANK];
export const EDITABLE_FLEX_LUXE_FEATURES = [...DEFAULT_FLEX_LUXE_FEATURES, FEATURE_INVENTORY_ORDERING_FLEX_LITE];
export const USER_GROUP_FEATURES = [...DEFAULT_FLEX_LUXE_FEATURES, FEATURE_INVENTORY_ORDERING_FLEX_LITE];
