import React, { useContext, useEffect } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { sumBy } from 'lodash';
import { IOrderStep, InventoryOrderFull } from 'src/interfaces/IInventoryOrder';
import { useSelector } from 'react-redux';
import { RootState } from 'src/rematch';
import { PORTRAIT_LEGACY, PORTRAIT_LEGACY_PLUS, USER_TYPES } from 'src/constants/general.constants';
import { formatCurrency } from 'src/utils/formatNumber';
import { drawerClosedWidth, drawerWidth, drawerMinWidth } from 'src/components/Sidebar/Sidebar.styles';
import { useStyle } from './inventoryOrder.styles';
import { OrderNotifyContext } from '.';

const OrderNotify = ({ notification }: { notification: string }) => {
  const notificationSetter = useContext(OrderNotifyContext);

  const classes = useStyle();

  useEffect(() => {
    notification &&
      setTimeout(() => {
        notificationSetter?.(undefined);
      }, 3500);
  }, [notification]);

  return !notification ? null : <Typography className={classes.orderNotification}>{notification}</Typography>;
};

type Props = {
  step: number;
  notification: string | undefined;
  setStep: (step: number) => void;
  setDialogueOpen: (open: boolean) => void;
  currentStep: IOrderStep;
  setNotification: (notification: string) => void;
  orderData: InventoryOrderFull | null | undefined;
  totalOrderValue: number;
  totalSaveValue: number;
  blockProgress: boolean;
};

export const InventoryFooter = ({
  step,
  notification,
  setStep,
  setDialogueOpen,
  currentStep,
  setNotification,
  orderData,
  totalOrderValue,
  blockProgress,
  totalSaveValue,
}: Props) => {
  const classes = useStyle();
  const { userType } = useSelector(({ auth }: RootState) => auth);
  const { sidebarOpened } = useSelector(({ page }: RootState) => page);

  const isLegacy = orderData?.roleName === PORTRAIT_LEGACY || orderData?.roleName === PORTRAIT_LEGACY_PLUS;

  const confirmModal = async (): Promise<void> => {
    setStep(1);
    setDialogueOpen(true);
  };
  const backClick = () => setStep(currentStep.orderSequence - 2);

  const continueClick = () => {
    if (currentStep.orderSequence === 2 && !orderData?.desiredDeliveryDate) {
      setNotification('Please enter a delivery date above');
    } else if (currentStep.orderSequence === 2) {
      confirmModal();
    } else {
      setStep(currentStep.orderSequence);
    }
  };

  const emptyOrder =
    sumBy(orderData?.services, 'quantity') === 0 &&
    sumBy(orderData?.specials, 'quantity') === 0 &&
    sumBy(orderData?.customItems, 'quantity') === 0;

  const totalLabel = !isLegacy ? ' Subtotal:' : 'Total:';

  // Default - closed sidebard;
  let backButtonPaddingLeft = drawerClosedWidth;
  if (userType === USER_TYPES.PRACTITIONER) {
    // Practitioner Dashboard - no sidebar
    backButtonPaddingLeft = '25px';
  } else if (sidebarOpened) {
    backButtonPaddingLeft = `max(${drawerWidth}, ${drawerMinWidth})`;
  }

  return !orderData ? null : (
    <Box
      paddingLeft={`calc(${backButtonPaddingLeft} + 1rem)`} // sidebarWidth + internal padding
      paddingY={0}
      className={classes.footerButtons}
    >
      <Button disabled={!step} data-cy="view" onClick={backClick} variant="outlined" className={classes.actionButton}>
        Back
      </Button>
      {isLegacy && <OrderNotify notification={notification ?? ''} />}
      <Box className={classes.buttonsContainer}>
        {isLegacy || currentStep.orderSequence < 2 ? (
          <>
            <Box className={classes.orderTotalFooter}>
              {totalLabel} {formatCurrency(totalOrderValue)}
            </Box>
            {!isLegacy && (
              <Box className={`${classes.orderTotalFooter} ${classes.save}`}>
                You Save: {formatCurrency(totalSaveValue)}
              </Box>
            )}
            <Button
              data-cy="view"
              onClick={continueClick}
              disabled={emptyOrder || blockProgress}
              variant="outlined"
              className={classes.actionButton}
              data-testid="order next"
            >
              {!currentStep || currentStep.orderSequence < 2 ? 'Next' : 'Submit Order'}
            </Button>
          </>
        ) : null}
      </Box>
    </Box>
  );
};
