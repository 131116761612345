import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Grid,
  InputLabel,
  Modal,
  TextField,
  Typography,
  CircularProgress,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import useSuppliers from 'src/hooks/queries/useSuppliers';
import { useCreateSpecialOffer, useUpdateSpecialOffer } from 'src/hooks/queries/useSpecialOffers';
import { EHRSwitchWithText } from 'src/components/ui/v1';

import { yupResolver } from '@hookform/resolvers';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import OrderingTooltip from 'src/components/DashboardPractitioner/Tabs/OrderingTab/OrderingTooltip';
import moment from 'moment';

import { ISpecialOffer } from 'src/services/SpecialOffers';
import { NewTextField, useStyles } from './specialsModal.styles';
import { specialOffersSchemaForm } from './schemaForm';
import { dateFormat } from './utils';
import { dispatch } from '../../rematch';
import compile from '../../utils/toastMessagesCompiler';

const CustomFromDateTextField = (props: any) => (
  <NewTextField {...props} name="fromDate" style={{ marginBottom: '10px' }} fullWidth />
);

interface Props {
  openModal: boolean;
  onClose: () => void;
  selectedSpecial: ISpecialOffer | null;
  setSelectedSpecial: Dispatch<SetStateAction<null>>;
  editMode: boolean;
}

interface FormData {
  name: string;
  supplierId: number | null;
  expirationDate: moment.Moment | null;
  note: string;
  flexMarketPrice: number | null;
  flexDiscount: number | null;
  flexFinalPrice: number | null;
  launchMarketPrice: number | null;
  launchDiscount: number | null;
  launchFinalPrice: number | null;
  ascendMarketPrice: number | null;
  ascendDiscount: number | null;
  ascendFinalPrice: number | null;
  specialDescription: string | null;
  enabled: boolean;
}

const NewSpecialModal = ({ openModal, onClose, selectedSpecial, setSelectedSpecial, editMode }: Props) => {
  const classes = useStyles();
  const { data: suppliers, isLoading: isLoadingSuppliers } = useSuppliers();
  const { mutateAsync: updateSpecial } = useUpdateSpecialOffer();
  const [showFlexPrices, setShowFlexPrices] = useState(false);
  const [showLaunchPrices, setShowLaunchPrices] = useState(false);
  const [showAscendPrices, setShowAscendPrices] = useState(false);

  const methods = useForm<FormData>({
    resolver: yupResolver(specialOffersSchemaForm),
    shouldFocusError: true,
    defaultValues: {
      name: '',
      specialDescription: '',
      supplierId: null,
      flexMarketPrice: null,
      flexDiscount: null,
      flexFinalPrice: null,
      launchMarketPrice: null,
      launchDiscount: null,
      launchFinalPrice: null,
      note: '',
      expirationDate: null,
      enabled: false,
    },
  });

  const {
    handleSubmit,
    register,
    control,
    setValue,
    watch,
    reset,
    trigger,
    formState: { errors },
  } = methods;

  const watchStart = watch('expirationDate');

  const mutationCreate = useCreateSpecialOffer();
  const loadingCreate = mutationCreate.isLoading;

  const resetForm = () => {
    setSelectedSpecial(null);
    setShowFlexPrices(false);
    setShowLaunchPrices(false);
    setShowAscendPrices(false);
    reset({
      name: '',
      supplierId: null,
      expirationDate: null,
      note: '',
      flexMarketPrice: 0,
      flexDiscount: 0,
      flexFinalPrice: 0,
      launchMarketPrice: 0,
      launchDiscount: 0,
      launchFinalPrice: 0,
      ascendMarketPrice: 0,
      ascendDiscount: 0,
      ascendFinalPrice: 0,
      specialDescription: '',
      enabled: false,
    });
  };

  const onSubmit = async (dataForm: FormData) => {
    const flexFinalPrice = dataForm.flexFinalPrice || 0;
    const launchFinalPrice = dataForm.launchFinalPrice || 0;
    const ascendFinalPrice = dataForm.ascendFinalPrice || 0;

    if (flexFinalPrice <= 0 && launchFinalPrice <= 0 && ascendFinalPrice <= 0) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: 'At least one of Flex Final Price, Launch Final Price or Ascend Final Price must be greater than 0',
          type: 'error',
          duration: 2500,
        },
      });
      return;
    }

    // Validation 2: Market - Discount must equal Final Price
    const validatePricing = (marketPrice: number | null, discount: number | null, finalPrice: number | null) => {
      if (finalPrice && finalPrice > 0) {
        const calculatedPrice = (marketPrice || 0) - (discount || 0);
        return Math.abs(calculatedPrice - finalPrice) < 0.01;
      }
      return true;
    };

    const isFlexPricingValid = validatePricing(
      dataForm.flexMarketPrice,
      dataForm.flexDiscount,
      dataForm.flexFinalPrice
    );

    const isLaunchPricingValid = validatePricing(
      dataForm.launchMarketPrice,
      dataForm.launchDiscount,
      dataForm.launchFinalPrice
    );

    const isAscendPricingValid = validatePricing(
      dataForm.ascendMarketPrice,
      dataForm.ascendDiscount,
      dataForm.ascendFinalPrice
    );

    if (!isFlexPricingValid || !isLaunchPricingValid || !isAscendPricingValid) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: 'Final price must equal Market Price minus Discount',
          type: 'error',
          duration: 2500,
        },
      });
      return;
    }

    // Continue with existing submit logic
    const trimStringField = (field: string | undefined) => field?.trim() || '';
    const parseNumberField = (field: number | null) => field;
    const dataToCreate = {
      ...dataForm,
      id: selectedSpecial?.id,
      name: trimStringField(dataForm.name),
      specialDescription: dataForm.specialDescription,
      supplierId: dataForm.supplierId,
      expirationDate: dataForm.expirationDate,
      note: trimStringField(dataForm.note),
      flexMarketPrice: parseNumberField(dataForm.flexMarketPrice),
      flexDiscount: parseNumberField(dataForm.flexDiscount),
      flexFinalPrice: parseNumberField(dataForm.flexFinalPrice),
      launchMarketPrice: parseNumberField(dataForm.launchMarketPrice),
      launchDiscount: parseNumberField(dataForm.launchDiscount),
      launchFinalPrice: parseNumberField(dataForm.launchFinalPrice),
      ascendMarketPrice: parseNumberField(dataForm.ascendMarketPrice),
      ascendDiscount: parseNumberField(dataForm.ascendDiscount),
      ascendFinalPrice: parseNumberField(dataForm.ascendFinalPrice),
    };

    try {
      if (selectedSpecial) {
        await updateSpecial(dataToCreate);
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.success_message', {
              action: 'updated',
              element: 'Special offer',
            }),
            type: 'success',
            duration: 2500,
          },
        });
      } else {
        await mutationCreate.mutateAsync(dataToCreate);
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.success_message', {
              action: 'created',
              element: 'Special offer',
            }),
            type: 'success',
            duration: 2500,
          },
        });
      }
      resetForm();
      onClose();
    } catch (error: any) {
      let errorMessage = compile('generic.error_message', {
        action: 'saving',
        element: 'special offer',
      });

      if (error?.response?.data) {
        const responseData = error.response.data;
        if (responseData.error) {
          errorMessage = responseData.error;
        } else if (responseData.errors) {
          errorMessage = responseData.errors.join(', ');
        }
      }

      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: errorMessage,
          type: 'error',
          duration: 2500,
        },
      });
    }
  };

  useEffect(() => {
    if (selectedSpecial && editMode) {
      const {
        name,
        expirationDate,
        note,
        supplierId,
        enabled,
        specialDescription,
        flexMarketPrice,
        flexDiscount,
        flexFinalPrice,
        launchMarketPrice,
        launchDiscount,
        launchFinalPrice,
      } = selectedSpecial;

      reset({
        name,
        specialDescription,
        supplierId,
        flexMarketPrice,
        flexDiscount,
        flexFinalPrice,
        launchMarketPrice,
        launchDiscount,
        launchFinalPrice,
        note,
        expirationDate: expirationDate ? moment(expirationDate) : null,
        enabled,
      });
      setValue('expirationDate', expirationDate ? moment(expirationDate) : null, {
        shouldValidate: true,
      });
      setValue('flexFinalPrice', flexFinalPrice);
      setValue('launchFinalPrice', launchFinalPrice);
      if (flexFinalPrice > 0) {
        setShowFlexPrices(true);
      }
      if (launchFinalPrice > 0) {
        setShowLaunchPrices(true);
      }
    } else {
      resetForm();
    }
  }, [selectedSpecial]);

  const flexMarketPrice = watch('flexMarketPrice');
  const flexDiscount = watch('flexDiscount');
  const launchMarketPrice = watch('launchMarketPrice');
  const launchDiscount = watch('launchDiscount');
  const enabled = watch('enabled');

  useEffect(() => {
    if (!editMode) {
      const calculateFinalPrice = (marketPrice: any, discount: any) =>
        Math.max((marketPrice || 0) - (discount || 0), 0);

      setValue('launchFinalPrice', calculateFinalPrice(launchMarketPrice, launchDiscount));
      setValue('flexFinalPrice', calculateFinalPrice(flexMarketPrice, flexDiscount));
    }
  }, [launchMarketPrice, launchDiscount, flexMarketPrice, flexDiscount, setValue, editMode]);

  return (
    <Modal open={openModal} onClose={onClose} className={classes.formModal}>
      <Box className={classes.formModalContent}>
        {/* Modal Header */}
        <Box display="flex" justifyContent="space-between" alignItems="center" p="1.5rem">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" gutterBottom className={classes.title}>
              New Special
            </Typography>
          </Box>
          <Button onClick={onClose} className={classes.closeBtn}>
            <CloseIcon />
          </Button>
        </Box>

        {/* Modal Body */}
        <Box component="form" onSubmit={handleSubmit(onSubmit)} alignItems="center" p="1.5rem">
          <Grid container spacing={2} className={classes.formContainer}>
            <Grid item xs={12}>
              <InputLabel color="secondary" focused={false} className={classes.label}>
                Special Name
              </InputLabel>
              <NewTextField
                fullWidth
                name="name"
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name?.message || ''}
                onChange={(e) => setValue('name', e.target.value)}
                inputRef={register}
              />
            </Grid>
            <Grid spacing={2} item xs={12}>
              <InputLabel color="secondary" focused={false} className={classes.label}>
                Supplier
              </InputLabel>
              <Controller
                control={control}
                name="supplierId"
                render={({ onChange, value }) => (
                  <NewTextField
                    select
                    fullWidth
                    variant="outlined"
                    error={!!errors.supplierId}
                    helperText={errors.supplierId?.message || ''}
                    onChange={onChange}
                    value={value}
                    inputRef={register}
                    data-cy="supplier-select"
                    data-testid="supplier-field"
                    disabled={isLoadingSuppliers}
                  >
                    {suppliers?.map(({ id, name }) => (
                      <MenuItem data-cy="suppliers-select" value={id} key={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </NewTextField>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel color="secondary" focused={false} htmlFor="note" className={classes.label}>
                Special Description
              </InputLabel>
              <Controller
                control={control}
                defaultValue=""
                name="specialDescription"
                render={({ onChange, value }) => (
                  <TextField
                    data-cy="special-description"
                    fullWidth
                    multiline
                    onChange={onChange}
                    rows={8}
                    value={value}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Box display="flex" padding="12px" style={{ gap: '1rem' }}>
              <InputLabel color="secondary" focused={false} className={classes.label}>
                Which product offerings is this special available for?
              </InputLabel>
            </Box>
            <Box margin={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showFlexPrices}
                    onChange={(e) => {
                      setShowFlexPrices(e.target.checked);
                      if (!e.target.checked) {
                        setValue('flexFinalPrice', 0);
                      }
                    }}
                    color="primary"
                  />
                }
                label="Flex"
              />
            </Box>
            {showFlexPrices && (
              <>
                <Box display="flex" padding="12px" style={{ gap: '1rem' }}>
                  <Grid item xs={4}>
                    <InputLabel color="secondary" focused={false} className={classes.label}>
                      Market Price
                      <OrderingTooltip title="The price of the product outside of Portrait in the market." />
                    </InputLabel>
                    <Controller
                      control={control}
                      name="flexMarketPrice"
                      render={({ onChange, value }) => (
                        <NewTextField
                          data-cy="flexMarketPrice"
                          variant="outlined"
                          type="number"
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel color="secondary" focused={false} className={classes.label}>
                      Discount
                      <OrderingTooltip title="The discount applied to this special, calculated over the total price." />
                    </InputLabel>
                    <Controller
                      control={control}
                      name="flexDiscount"
                      render={({ onChange, value }) => (
                        <NewTextField
                          data-cy="flexDiscount"
                          variant="outlined"
                          type="number"
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel color="secondary" focused={false} className={classes.label}>
                      Final Price
                      <OrderingTooltip title="The final cost of the special that users will pay." />
                    </InputLabel>
                    <Controller
                      control={control}
                      name="flexFinalPrice"
                      render={({ onChange, value }) => (
                        <NewTextField
                          data-cy="flexFinalPrice"
                          variant="outlined"
                          type="number"
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  </Grid>
                </Box>
              </>
            )}
            <Box margin={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showLaunchPrices}
                    onChange={(e) => {
                      setShowLaunchPrices(e.target.checked);
                      if (!e.target.checked) {
                        setValue('launchFinalPrice', 0);
                      }
                    }}
                    color="primary"
                  />
                }
                label="Launch"
              />
            </Box>
            {showLaunchPrices && (
              <>
                <Box display="flex" padding="12px" style={{ gap: '1rem' }}>
                  <Grid item xs={4}>
                    <InputLabel color="secondary" focused={false} className={classes.label}>
                      Market Price
                      <OrderingTooltip title="The price of the product outside of Portrait in the market." />
                    </InputLabel>
                    <Controller
                      control={control}
                      name="launchMarketPrice"
                      render={({ onChange, value }) => (
                        <NewTextField
                          data-cy="launchMarketPrice"
                          variant="outlined"
                          type="number"
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel color="secondary" focused={false} className={classes.label}>
                      Discount
                      <OrderingTooltip title="The discount applied to this special, calculated over the total price." />
                    </InputLabel>
                    <Controller
                      control={control}
                      name="launchDiscount"
                      render={({ onChange, value }) => (
                        <NewTextField
                          data-cy="launchDiscount"
                          variant="outlined"
                          type="number"
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel color="secondary" focused={false} className={classes.label}>
                      Final Price
                      <OrderingTooltip title="The final cost of the special that users will pay." />
                    </InputLabel>
                    <Controller
                      control={control}
                      name="launchFinalPrice"
                      render={({ onChange, value }) => (
                        <NewTextField
                          data-cy="launchFinalPrice"
                          variant="outlined"
                          type="number"
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  </Grid>
                </Box>
              </>
            )}
            <Box margin={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showAscendPrices}
                    onChange={(e) => {
                      setShowAscendPrices(e.target.checked);
                      if (!e.target.checked) {
                        setValue('ascendFinalPrice', 0);
                      }
                    }}
                    color="primary"
                  />
                }
                label="Ascend"
              />
            </Box>
            {showAscendPrices && (
              <>
                <Box display="flex" padding="12px" style={{ gap: '1rem' }}>
                  <Grid item xs={4}>
                    <InputLabel color="secondary" focused={false} className={classes.label}>
                      Market Price
                      <OrderingTooltip title="The price of the product outside of Portrait in the market." />
                    </InputLabel>
                    <Controller
                      control={control}
                      name="ascendMarketPrice"
                      render={({ onChange, value }) => (
                        <NewTextField
                          data-cy="ascendMarketPrice"
                          variant="outlined"
                          type="number"
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel color="secondary" focused={false} className={classes.label}>
                      Discount
                      <OrderingTooltip title="The discount applied to this special, calculated over the total price." />
                    </InputLabel>
                    <Controller
                      control={control}
                      name="ascendDiscount"
                      render={({ onChange, value }) => (
                        <NewTextField
                          data-cy="ascendDiscount"
                          variant="outlined"
                          type="number"
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputLabel color="secondary" focused={false} className={classes.label}>
                      Final Price
                      <OrderingTooltip title="The final cost of the special that users will pay." />
                    </InputLabel>
                    <Controller
                      control={control}
                      name="ascendFinalPrice"
                      render={({ onChange, value }) => (
                        <NewTextField
                          data-cy="ascendFinalPrice"
                          variant="outlined"
                          type="number"
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  </Grid>
                </Box>
              </>
            )}
            <Grid item xs={12} />
            <Grid item xs={12}>
              <InputLabel color="secondary" focused={false} className={classes.label}>
                Expiration Date
              </InputLabel>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  variant="dialog"
                  inputVariant="outlined"
                  format={dateFormat}
                  minDate={moment().add(1, 'days')}
                  value={watchStart}
                  error={!!errors?.expirationDate}
                  helperText={errors?.expirationDate?.message}
                  onChange={(date: any) => {
                    setValue('expirationDate', date.format(dateFormat));
                    trigger('expirationDate');
                  }}
                  TextFieldComponent={CustomFromDateTextField}
                  autoOk
                  disableToolbar
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
              <InputLabel color="secondary" focused={false} htmlFor="note" className={classes.label}>
                Notes (Optional)
              </InputLabel>
              <Controller
                control={control}
                defaultValue=""
                name="note"
                render={({ onChange, value }) => (
                  <TextField
                    data-cy="note"
                    fullWidth
                    multiline
                    onChange={onChange}
                    rows={4}
                    value={value}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <EHRSwitchWithText
                name="enabled"
                data-cy="enabled"
                label="Special enabled"
                checked={enabled}
                onChange={() => setValue('enabled', !enabled)}
                text="Whether or not a special is enabled"
                dataCy="enabled-switch"
              />
            </Grid>
          </Grid>

          <Box height="2rem" />

          {/* Buttons actions */}
          <Box className={classes.footer}>
            <Box display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <Box width="100%">
                <Button type="submit" color="primary" variant="contained" className={classes.submitButton} fullWidth>
                  {loadingCreate ? <CircularProgress size={25} /> : `${editMode ? 'Edit' : 'Add'}`}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default NewSpecialModal;
