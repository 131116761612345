import { ColumnType } from 'src/types/TablePaginator';
import { formatCurrency } from 'src/utils/formatNumber';

export const PRODUCTS_TABLE_PAGE_SIZE = 25;
export const PRODUCT_COLUMNS: ColumnType[] = [
  { field: 'name', title: 'Name' },
  { field: 'supplierName', title: 'Supplier' },
  { field: 'description', title: 'Description' },
  {
    field: 'minimumOrderQuantity',
    title: 'Minimum Order Quantity',
    sortable: true,
    sortField: 'minimum_order_quantity',
  },
  {
    field: 'listPrice',
    title: 'List Price',
    render: (value: number) => formatCurrency(value),
    sortable: true,
    sortField: 'list_price',
  },
  {
    field: 'currentCost',
    title: 'Current Cost',
    render: (value: number) => formatCurrency(value),
    sortable: true,
    sortField: 'current_cost',
  },
  {
    field: 'currentCostWithGuaranteedRebates',
    title: 'Current Cost with Guaranteed Rebates',
    render: (value: number) => formatCurrency(value),
    sortable: true,
    sortField: 'current_cost_with_guaranteed_rebates',
  },
  {
    field: 'currentCostWithAllRebates',
    title: 'Current Cost with All Rebates',
    render: (value: number) => formatCurrency(value),
    sortable: true,
    sortField: 'current_cost_with_all_rebates',
  },
  {
    field: 'legacyPrice',
    title: 'Legacy Price',
    render: (value: number) => formatCurrency(value),
    sortable: true,
    sortField: 'legacy_price',
  },
  {
    field: 'legacyPlusPrice',
    title: 'Legacy Plus Price',
    render: (value: number) => formatCurrency(value),
    sortable: true,
    sortField: 'legacy_plus_price',
  },
  {
    field: 'flexPrice',
    title: 'Flex Price',
    render: (value: number) => formatCurrency(value),
    sortable: true,
    sortField: 'flex_price',
  },
  {
    field: 'launchPrice',
    title: 'Launch Price',
    render: (value: number) => formatCurrency(value),
    sortable: true,
    sortField: 'launch_price',
  },
  {
    field: 'ascendPrice',
    title: 'Ascend Price',
    render: (value: number) => formatCurrency(value),
    sortable: true,
    sortField: 'ascend_price',
  },
];
