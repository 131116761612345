import React from 'react';
import classNames from 'classnames';
import {
  Dashboard as DashboardIcon,
  SupervisorAccount as SupervisorAccountIcon,
  BubbleChart as BubbleChartIcon,
  PeopleAlt as PeopleAltIcon,
  VerifiedUser as VerifiedUserIcon,
  LocationOn as LocationOnIcon,
  Person as PersonIcon,
  ListAlt as ListAltIcon,
  PeopleOutline as PeopleOutlineIcon,
  SupervisedUserCircle as SupervisedUserCircleIcon,
  Flag as FlagIcon,
  ShowChart as ShowChartIcon,
  AttachMoney as MoneyIcon,
  DateRange as DateRangeIcon,
} from '@material-ui/icons';
import {
  MEDSPA_PROVIDER_CALENDAR,
  MEDSPA_PROVIDER_EARNINGS,
  MEDSPA_PROVIDER_PATIENTS,
  MEDSPA_PROVIDER_TODO_LIST,
} from 'src/routes/medspaPractitionerRoutes';
import {
  READ_ADMIN_USER,
  READ_ASSET,
  READ_CUSTOMER,
  READ_CLIENT_LEAD,
  READ_PRACTITIONER,
  READ_PHYSICIAN,
  READ_LOCATION,
  READ_PRACTITIONER_SALES_DASHBOARD,
  READ_PURCHASE_ORDER,
  READ_RECONCILIATION_REPORT,
  READ_SUPPLY_RECEIPT,
  CREATE_SERVICE,
  READ_USER_GROUP,
  IS_SUPPORT,
  DOCUMENT_CREATE,
  CREATE_AD_CAMPAIGN,
  READ_TODO_LIST,
  READ_INVENTORY_ORDER,
  RESOURCE_CREATE,
} from './actions.constants';

import {
  ADMIN_CLIENT_LEADS_REPORTS,
  ADMIN_CLIENT_LEADS,
  ADMIN_ORDER_APPROVAL,
  ADMIN_MEDSPA,
  ADMIN_CLIENT_LEADS_CAMPAIGNS,
  ADMIN_PRODUCT_UTILIZATION,
  ADMIN_ORDER_PRODUCTS,
} from '../routes/administratorRoutes';

export const MEDSPA_PROVIDER_TYPE = 'medspaPractitioner';

function getPractitionersMenuItem(classes: any): MenuOption[] {
  const shouldShowPractitioners = process.env.REACT_APP_HIDE_OLD_PRACTITIONERS_UI !== 'true';

  if (!shouldShowPractitioners) {
    return [];
  }

  return [
    {
      name: 'Practitioners',
      url: '/administrator/practitioners',
      permission: READ_PRACTITIONER,
      icon: (isActive: boolean) => (
        <PeopleAltIcon className={classNames(classes.iconColor, { [classes.iconActive]: isActive })} />
      ),
    },
  ];
}

export interface MenuOption {
  type?: string;
  name: string;
  disabled?: boolean;
  permission?: string | string[];
  url?: string;
  icon?: (isActive: boolean) => React.ReactElement;
  options?: MenuOption[];
}

export const MENU_OPTIONS = (classes: any, type: string = '') => {
  const options: MenuOption[] = [
    {
      type: MEDSPA_PROVIDER_TYPE,
      name: 'Earnings',
      permission: READ_CUSTOMER,
      url: MEDSPA_PROVIDER_EARNINGS,
      icon: (isActive: boolean) => (
        <MoneyIcon className={classNames(classes.iconColor, { [classes.iconActive]: isActive })} />
      ),
    },
    {
      type: MEDSPA_PROVIDER_TYPE,
      name: 'Patients',
      permission: READ_CUSTOMER,
      url: MEDSPA_PROVIDER_PATIENTS,
      icon: (isActive: boolean) => (
        <SupervisorAccountIcon className={classNames(classes.iconColor, { [classes.iconActive]: isActive })} />
      ),
    },
    {
      type: MEDSPA_PROVIDER_TYPE,
      name: 'To-Do List',
      permission: READ_TODO_LIST,
      url: MEDSPA_PROVIDER_TODO_LIST,
      icon: (isActive: boolean) => (
        <ListAltIcon className={classNames(classes.iconColor, { [classes.iconActive]: isActive })} />
      ),
    },
    {
      type: MEDSPA_PROVIDER_TYPE,
      name: 'Calendar',
      permission: READ_CUSTOMER,
      url: MEDSPA_PROVIDER_CALENDAR,
      icon: (isActive: boolean) => (
        <DateRangeIcon className={classNames(classes.iconColor, { [classes.iconActive]: isActive })} />
      ),
    },
    {
      name: 'Patients',
      permission: READ_CUSTOMER,
      url: '/administrator/patients',
      icon: (isActive: boolean) => (
        <SupervisorAccountIcon className={classNames(classes.iconColor, { [classes.iconActive]: isActive })} />
      ),
    },
    {
      name: 'Medspa',
      url: ADMIN_MEDSPA,
      permission: READ_USER_GROUP,
      icon: (isActive: boolean) => (
        <SupervisedUserCircleIcon className={classNames(classes.iconColor, { [classes.iconActive]: isActive })} />
      ),
    },
    {
      name: 'Admin Users',
      url: '/administrator/users',
      permission: READ_ADMIN_USER,
      icon: (isActive: boolean) => (
        <VerifiedUserIcon className={classNames(classes.iconColor, { [classes.iconActive]: isActive })} />
      ),
    },
    ...getPractitionersMenuItem(classes),
    {
      name: 'Physicians',
      url: '/administrator/physicians',
      permission: READ_PHYSICIAN,
      icon: (isActive: boolean) => (
        <PersonIcon className={classNames(classes.iconColor, { [classes.iconActive]: isActive })} />
      ),
    },
    {
      name: 'Locations',
      url: '/administrator/locations',
      permission: READ_LOCATION,
      icon: (isActive: boolean) => (
        <LocationOnIcon className={classNames(classes.iconColor, { [classes.iconActive]: isActive })} />
      ),
    },
    {
      name: 'Services',
      permission: [CREATE_SERVICE],
      options: [
        { name: 'Services', url: '/administrator/services', permission: CREATE_SERVICE },
        { name: 'Specials', url: '/administrator/specials', permission: CREATE_SERVICE },
      ],
      icon: (isActive: boolean) => (
        <BubbleChartIcon className={classNames(classes.iconColor, { [classes.iconActive]: isActive })} />
      ),
    },
    {
      name: 'Inventory',
      permission: [READ_PURCHASE_ORDER, READ_SUPPLY_RECEIPT, READ_RECONCILIATION_REPORT, READ_ASSET],
      options: [
        { name: 'Purchase Order', url: '/administrator/purchase-orders', permission: READ_PURCHASE_ORDER },
        { name: 'Supply Receipts', url: '/administrator/supply-receipts', permission: READ_SUPPLY_RECEIPT },
        {
          name: 'Reconciliation',
          url: '/administrator/reconciliation-reports',
          permission: READ_RECONCILIATION_REPORT,
        },
        { name: 'Assets', url: '/administrator/assets', permission: READ_ASSET },
        { name: 'Assets Overview', url: '/administrator/overview', permission: READ_ASSET },
        { name: 'Product Utilization', url: ADMIN_PRODUCT_UTILIZATION, permission: READ_ASSET },
        { name: 'Order Products', url: ADMIN_ORDER_PRODUCTS, permission: READ_INVENTORY_ORDER },
        { name: 'Order Approval', url: ADMIN_ORDER_APPROVAL, permission: READ_ASSET },
        // Hide for now
        // { name: 'SERVICE VISIT ASSETS', url: '/administrator/service-visit-assets', permission: READ_ASSET },
      ],
      icon: (isActive: boolean) => (
        <ListAltIcon className={classNames(classes.iconColor, { [classes.iconActive]: isActive })} />
      ),
    },
    {
      name: 'Client Leads',
      permission: READ_CLIENT_LEAD,
      options: [
        {
          name: 'Lead Management',
          url: ADMIN_CLIENT_LEADS,
          permission: READ_CLIENT_LEAD,
        },
        {
          name: 'Reports',
          url: ADMIN_CLIENT_LEADS_REPORTS,
          permission: READ_CLIENT_LEAD,
        },
        {
          name: 'Campaigns',
          url: ADMIN_CLIENT_LEADS_CAMPAIGNS,
          permission: CREATE_AD_CAMPAIGN,
        },
      ],
      icon: (isActive: boolean) => (
        <PeopleOutlineIcon className={classNames(classes.iconColor, { [classes.iconActive]: isActive })} />
      ),
    },
    {
      name: 'Reporting',
      icon: (isActive: boolean) => (
        <DashboardIcon className={classNames(classes.iconColor, { [classes.iconActive]: isActive })} />
      ),
      permission: READ_PRACTITIONER_SALES_DASHBOARD,
      options: [
        {
          name: 'Transactions',
          url: '/administrator/checkout-transactions',
          permission: READ_PRACTITIONER_SALES_DASHBOARD,
        },
        { name: 'Reports', url: '/administrator/reports', permission: READ_PRACTITIONER_SALES_DASHBOARD },
      ],
    },
    {
      name: 'Features',
      icon: (isActive: boolean) => (
        <FlagIcon className={classNames(classes.iconColor, { [classes.iconActive]: isActive })} />
      ),
      permission: IS_SUPPORT,
      options: [
        {
          name: 'Feature Flags',
          url: '/administrator/flags',
          permission: IS_SUPPORT,
        },
        {
          name: 'Users Flags',
          url: '/administrator/user-flags',
          permission: IS_SUPPORT,
        },
      ],
    },
    {
      name: 'Finance',
      url: '/administrator/documents',
      icon: (isActive: boolean) => (
        <ShowChartIcon className={classNames(classes.iconColor, { [classes.iconActive]: isActive })} />
      ),
      permission: DOCUMENT_CREATE,
    },
    {
      name: 'Resources',
      url: '/administrator/resources',
      icon: (isActive: boolean) => (
        <ListAltIcon className={classNames(classes.iconColor, { [classes.iconActive]: isActive })} />
      ),
      permission: RESOURCE_CREATE,
    },
  ];

  return options.filter((item) => {
    const isValidItem = !!item;
    const matchesType = type ? item.type === type : !item.type;
    return isValidItem && matchesType;
  });
};
