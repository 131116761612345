import { groupBy } from 'lodash';
import React, { useState, useEffect } from 'react';
import { OrderLookupParams } from 'src/interfaces/IInventoryOrder';
import { IRoles } from 'src/interfaces/IRoles.interfaces';
import { APPROVAL_FILTER_ROLE_BUTTONS, APPROVAL_STATUS_DEFAULT_STATE } from 'src/constants/inventory.constants';
import { filterUnusedParams } from 'src/utils/global';
import OrderApprovalDateFilters from './OrderApprovalDateFilter';
import OrderApprovalStatusFilter from './OrderApprovalStatusFilter';
import OrderApprovalRoleFilter from './OrderApprovalRoleFilter';

export type Props = {
  isLoading: boolean;
  roles: { [name: string]: IRoles[] };
  currentCount?: number;
  filterState?: OrderLookupParams;
  setFilterState: (filters: OrderLookupParams) => void;
};

export type PropsWithFullRole = Pick<Props, 'setFilterState' | 'isLoading' | 'currentCount'> & { roles: IRoles[] };

export const OrderApprovalFilters = ({
  isLoading,
  currentCount,
  roles = [],
  setFilterState: setter,
}: PropsWithFullRole) => {
  const [filterState, setFilterState] = useState<OrderLookupParams>();

  const rolesByName = groupBy(roles, 'name');

  useEffect(() => {
    if (roles.length) {
      if (!filterState) {
        setFilterState({
          ...APPROVAL_STATUS_DEFAULT_STATE,
          roleId: APPROVAL_FILTER_ROLE_BUTTONS['Non-Legacy'].map((role) => rolesByName[role]?.[0].id),
          startDate: undefined,
          endDate: undefined,
        });
      } else {
        setter(filterUnusedParams<OrderLookupParams, number | boolean | undefined>(filterState));
      }
    }
  }, [roles, filterState]);

  return (
    <>
      <OrderApprovalRoleFilter
        roles={rolesByName}
        filterState={filterState}
        setFilterState={setFilterState}
        isLoading={isLoading}
      />
      <OrderApprovalStatusFilter
        isLoading={isLoading}
        filterState={filterState}
        setFilterState={setFilterState}
        disabled={!roles.length}
        currentCount={currentCount}
      />
      <OrderApprovalDateFilters isLoading={isLoading} setFilterState={setFilterState} filterState={filterState} />
    </>
  );
};

export default OrderApprovalFilters;
