import React, { useState, useEffect, ReactElement, MouseEvent, ChangeEvent } from 'react';
import {
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  CircularProgress,
  MenuItem,
  Select,
} from '@material-ui/core';
import { Search as SearchIcon, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { RowItem } from 'src/components/DashboardPractitioner/Tabs/InsightsTab/RowItemReports';
import { useStyles, TableCellHeader } from 'src/components/DashboardPractitioner/Tabs/InsightsTab/reports.styles';
import { usePractitionerReport, usePractitionerReportColumns } from 'src/hooks/queries/usePractitionerReport';
import {
  IClientSummaryData,
  InputSearchProps,
  IReportParams,
  IPractitionerReport,
} from 'src/interfaces/IPractitionerReport';
import PractitionerReports from 'src/services/PractitionerReports';
import {
  DEFAULT_SORT_KEY,
  DEFAULT_SEARCH_TERMS,
  MIN_SEARCH_CHARS,
  DEFAULT_ROW_COUNT,
  TABLE_LIST,
  COLUMNS_ORDER,
} from 'src/constants/clientReports.constants';
import { useMedspaPractitioners } from 'src/hooks/queries/useMedspaAdmins';
import { SelectList as ReportList } from 'src/components/common/SelectList';
import { CSVLink, CSVLinkProps } from 'src/components/common/CSVLink';
import { MultipleSkeleton } from 'src/components/common/LoadingSkeleton';

const InputSearch: React.FC<InputSearchProps> = ({
  value,
  placeholder,
  onChangeValue,
  isLoading,
}: InputSearchProps): ReactElement => {
  const classes = useStyles();

  return (
    <FormControl size="small" variant="outlined" style={{ margin: '10px' }} className={classes.searchbox}>
      <InputLabel htmlFor="search" color="primary" className={classes.search}>
        Search
      </InputLabel>
      <OutlinedInput
        type="text"
        value={value}
        onChange={onChangeValue}
        placeholder={placeholder}
        endAdornment={
          <InputAdornment position="end">
            <IconButton style={{ padding: 0 }}>
              {isLoading ? (
                <CircularProgress style={{ color: 'black' }} size={20} />
              ) : (
                <SearchIcon style={{ color: 'black' }} />
              )}
            </IconButton>
          </InputAdornment>
        }
        labelWidth={70}
      />
    </FormControl>
  );
};

const TableReports: React.FC<{ practitionerView?: boolean; isMedspaAdmin?: boolean }> = ({
  practitionerView,
  isMedspaAdmin,
}: {
  practitionerView?: boolean;
  isMedspaAdmin?: boolean;
}): React.ReactElement => {
  const [selected, setSelected] = useState<string | null>(null);
  const [page, setPage] = useState<number>(0);
  const [searchTerms, setSearchTerms] = useState<string>('');
  const [sortKey, setSortKey] = useState<string>('');
  const [asc, setAsc] = useState<boolean>(true);
  const [rowTotal, setRowTotal] = useState<number>(0);
  const [practitionerId, setPractitionerId] = useState<number>();

  const DEFAULT_SELECTION = Object.entries(TABLE_LIST)[0][0];

  const { data: medspaPractitioners, isSuccess } = useMedspaPractitioners(isMedspaAdmin);

  const { data: reportColumns = {}, isLoading: colsLoading } = usePractitionerReportColumns(
    selected ?? DEFAULT_SELECTION,
    !isMedspaAdmin || isSuccess
  );

  const sortedColumns = Object.keys(reportColumns)
    .sort((a, b) => COLUMNS_ORDER.indexOf(a) - COLUMNS_ORDER.indexOf(b))
    .reduce((acc, key) => ({ ...acc, [key]: reportColumns[key] }), {});

  const headerColumns = Object.entries(sortedColumns)
    .slice(0, -1)
    .concat([['smsOptedIn', 'SMS Opted-In']]);

  const { isLoading, data: reportData } = usePractitionerReport(
    {
      practitionerId,
      reportLabel: selected || DEFAULT_SELECTION,
      searchTerms: searchTerms && searchTerms.length > MIN_SEARCH_CHARS ? searchTerms : DEFAULT_SEARCH_TERMS,
      sortKey: sortKey || DEFAULT_SORT_KEY,
      page,
      asc,
    },
    !isMedspaAdmin || isSuccess
  );

  useEffect(() => {
    setSelected(DEFAULT_SELECTION);
    setSortKey(DEFAULT_SORT_KEY);
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    sortedColumns && setSortKey(Object.keys(sortedColumns).indexOf(sortKey) > -1 ? sortKey : DEFAULT_SORT_KEY);
    if (!isLoading) {
      if (reportData?.length) {
        if (rowTotal !== reportData[0].count) {
          setRowTotal(reportData[0].count || 0);
        }
      } else {
        setRowTotal(0);
      }
    }
  }, [reportData, rowTotal, isLoading, sortedColumns, sortKey]);

  const classes = useStyles();

  const handleListSelect = (label: string | null): void => {
    setRowTotal(0);
    setPage(0);
    setSearchTerms('');
    setSelected(label);
  };

  const handleChangePage = (_: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    const newText: string = event.currentTarget?.value || '';
    if (newText !== searchTerms) {
      setSearchTerms(newText.toLowerCase());
      setPage(0);
    }
  };

  const handleHeaderClick = (event: MouseEvent<HTMLElement>): void => {
    const newKey: string = event.currentTarget?.getAttribute('data-colname') || '';
    if (newKey in sortedColumns) {
      if (newKey === sortKey) {
        setAsc(!asc);
      } else {
        setAsc(false);
        setSortKey(newKey);
      }
      setPage(0);
    }
  };

  const getCSVRows = async (): Promise<unknown[]> => {
    const csvReportData = await PractitionerReports.getReport({
      searchTerms: searchTerms && searchTerms.length > 2 ? searchTerms : DEFAULT_SEARCH_TERMS,
      reportLabel: selected,
      sortKey,
      asc,
    } as IReportParams);

    const csvData = (csvReportData || []).map((row: IPractitionerReport): IPractitionerReport => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { count, ...newRow } = row;

      return newRow;
    });

    return (csvData as unknown[]) || [];
  };

  const searchProps: InputSearchProps = {
    value: searchTerms,
    placeholder: 'search clients',
    onChangeValue: handleChangeSearch,
    isLoading: false,
  };

  const csvProps: CSVLinkProps = {
    getCSVRows,
    csvName: selected || '',
    csvCols: [...Object.keys(sortedColumns), 'email', 'smsOptedIn'],
    className: classes.topdownload,
  };

  return (
    <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
      <Box className={classes.navbar}>
        <ReportList
          title="report type"
          listItems={TABLE_LIST}
          selected={selected || DEFAULT_SELECTION}
          setSelected={handleListSelect}
        />
        {!practitionerView && isMedspaAdmin && (
          <Box margin="auto 0 auto auto" minWidth="250px">
            <Select
              data-cy="providerSelect"
              variant="outlined"
              className={classes.reportProvider}
              value={`${practitionerId || 0}`}
              onChange={(event) => setPractitionerId(+(event.target.value as string))}
              fullWidth
            >
              <MenuItem value="0">All Providers</MenuItem>
              {medspaPractitioners?.map((menuSupplier) => (
                <MenuItem key={menuSupplier.id} value={menuSupplier.id}>
                  {menuSupplier.firstName} {menuSupplier.lastName}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
        <InputSearch {...searchProps} />
        <CSVLink {...csvProps} />
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={rowTotal}
          rowsPerPage={DEFAULT_ROW_COUNT}
          page={page}
          onChangePage={handleChangePage}
          className={classes.pager}
        />
      </Box>
      <Table aria-label="collapsible table">
        {isLoading || colsLoading ? (
          <MultipleSkeleton length={8} />
        ) : (
          <>
            <TableHead>
              <TableRow>
                {headerColumns?.map(
                  ([key, header]): ReactElement => (
                    <TableCellHeader
                      className={classes.theader}
                      onClick={handleHeaderClick}
                      data-colname={key}
                      key={key}
                      data-testid={`sortable table header ${header}`}
                    >
                      <Box className={classes.headerBox}>
                        {header}
                        {asc ? (
                          <KeyboardArrowUp
                            viewBox="0 0 30 30"
                            style={{ color: sortKey === key ? '#000' : '#ccc' }}
                            className={classes.chevron}
                            data-testid={`sortable table header asc ${header}`}
                          />
                        ) : (
                          <KeyboardArrowDown
                            viewBox="0 0 30 30"
                            style={{ color: sortKey === key ? '#000' : '#ccc' }}
                            className={classes.chevron}
                            data-testid={`sortable table header desc ${header}`}
                          />
                        )}
                      </Box>
                    </TableCellHeader>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody data-cy="psmMetricsTableBody">
              {sortedColumns &&
                reportData?.map(
                  (row: IClientSummaryData): ReactElement => <RowItem key={row.mrn} row={row} cols={sortedColumns} />
                )}
            </TableBody>
          </>
        )}
      </Table>
      {isLoading || colsLoading ? null : (
        <Box className={classes.navbar}>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={rowTotal}
            rowsPerPage={DEFAULT_ROW_COUNT}
            page={page}
            onChangePage={handleChangePage}
            className={classes.pager2}
          />
        </Box>
      )}
    </TableContainer>
  );
};

export { InputSearch, TableReports, TableReports as default };
