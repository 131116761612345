import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    padding: '5px',
    borderRadius: '4px',
    background: '#F5F5F5',
    height: '34px',
  },
  checkboxContainerDisabled: {
    cursor: 'not-allowed',
  },
  hiddenCheckbox: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: '1px',
  },
  styledCheckbox: {
    display: 'inline-block',
    width: '20px',
    height: '20px',
    borderRadius: '4px',
    transition: 'all 150ms',
    border: '2px solid #C4C4C4',
    background: '#fff',
  },
  styledCheckboxChecked: {
    background: '#fbc580',
    border: '2px solid #fbc580',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labelText: {
    marginLeft: '10px',
    color: '#7A7A7A',
    fontSize: '16px',
    fontFamily: 'Roboto',
    transition: 'color 200ms',
    whiteSpace: 'nowrap',
    textTransform: 'none',
    border: 'none',
    padding: '0',
  },
  labelTextChecked: {
    color: '#874E00',
  },
}));
