import { useMutation, useQuery, useQueryClient } from 'react-query';
import { TenderType } from 'src/interfaces/ITransaction.interfaces';
import { CHECKOUT_TENDERS, SERVICE_VISIT_CHECKOUT } from '../../constants/reactQuery.keys';
import ServiceVisit from '../../services/ServiceVisit';
import { useReactQueryOnErrorHandler } from '../../utils/global';
import compile from '../../utils/toastMessagesCompiler';

const useTenders = (serviceVisitId: number) => {
  const response = useQuery([CHECKOUT_TENDERS, serviceVisitId], () => ServiceVisit.getCheckoutTenders(serviceVisitId), {
    refetchOnWindowFocus: true, // For multi-tab purposes
    keepPreviousData: true,
    onError: () =>
      useReactQueryOnErrorHandler(compile('generic.error_message', { action: 'fetching', element: 'tenders' })),
  });

  return {
    ...response,
    data: response.data || [],
  };
};

export interface TenderParams {
  value: number;
  voucher: string;
  tenderType: TenderType;
}

export const addCheckoutTender = (serviceVisitId: number) => {
  const queryClient = useQueryClient();

  return useMutation((params: TenderParams) => ServiceVisit.addCheckoutTender(serviceVisitId, params), {
    onSuccess: () => {
      queryClient.invalidateQueries([SERVICE_VISIT_CHECKOUT, serviceVisitId]);
      queryClient.invalidateQueries([CHECKOUT_TENDERS, serviceVisitId]);
    },
    onError: useReactQueryOnErrorHandler,
    retry: true,
  });
};

export default useTenders;
