import { AxiosError } from 'axios';
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from 'src/constants/general.constants';
import { ERROR_PREFIX } from 'src/constants/inventory.constants';
import { SnackMessageVariant } from 'src/types/SnackbarState';
import { showSnackbar } from 'src/utils/global';

const ALERT_STYLE = { color: '#FFF' };
const ALERT_OPTIONS = { variant: 'filled' as SnackMessageVariant };
const ERROR_OPTIONS = { ...ALERT_OPTIONS, style: { ...ALERT_STYLE, backgroundColor: '#BB0000' } };
const SUCCESS_OPTIONS = { ...ALERT_OPTIONS, style: { ...ALERT_STYLE, backgroundColor: '#009900' } };

const formatErrorMessage = (error: string): string => (error ? `: ${error}` : '');

const showOrderingError = (action: string, error: unknown) => {
  const message =
    typeof error === 'string' ? error : (error as AxiosError).response?.data?.errors ?? (error as Error).message;

  showSnackbar([ERROR_PREFIX, action, formatErrorMessage(message)].join(''), SNACKBAR_ERROR, ERROR_OPTIONS);
};

export const showOrderingMessage = (action: string, error?: unknown) => {
  if (error) {
    showOrderingError(action, error);
  } else {
    showSnackbar(action, SNACKBAR_SUCCESS, SUCCESS_OPTIONS);
  }
};

export const handleQueryError =
  (action: string, errorCallback?: (error: Error) => void | Promise<void>) => async (error: unknown) => {
    showOrderingError(action, error);
    await errorCallback?.(error as Error);
  };
