import React from 'react';
import { LandingPageProps } from 'src/interfaces/ILeadLandingPage';
import { Box, Typography } from '@material-ui/core';

export const BeforeAfter: React.FC<LandingPageProps> = ({
  serviceName,
  title,
  description,
  classes,
  mobile,
}: LandingPageProps): React.ReactElement => (
  <Box className={classes?.baSection} data-mobile={mobile}>
    <Box className={classes?.baDescription} data-mobile={mobile}>
      <Typography className={classes?.baText} data-mobile={mobile}>
        {title}
      </Typography>
      <Typography className={[classes?.baText, classes?.regular].join(' ')}>{description}</Typography>
    </Box>
    <Box className={classes?.baFeature} data-mobile={mobile}>
      <Box className={classes?.beforeImage} data-mobile={mobile} data-service={serviceName} />
      <Box className={classes?.afterImage} data-mobile={mobile} data-service={serviceName} />
    </Box>
  </Box>
);

export const BeforeAfterSection: React.FC<LandingPageProps> = ({
  classes,
  mobile,
}: LandingPageProps): React.ReactElement => (
  <>
    <BeforeAfter
      classes={classes}
      mobile={mobile}
      title="Before & After Treatment"
      description={
        '\
              Full service medical aesthetics boutiques for all your goals. \
              From chemical peels, microneedling, and skincare products, to \
              wrinkle relaxers, volumizers, body sculpting, and laser treatments.\
            '
      }
    />
    <BeforeAfter classes={classes} mobile={mobile} serviceName="relaxer" title="Wrinkle Relaxers" description="" />
    <BeforeAfter classes={classes} mobile={mobile} serviceName="volumizer" title="Volumizers" description="" />
    <BeforeAfter classes={classes} mobile={mobile} serviceName="contour" title="Facial Contouring" description="" />
    <BeforeAfter classes={classes} mobile={mobile} serviceName="sculpting" title="Body Sculpting" description="" />
  </>
);
