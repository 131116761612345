import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { FormControlLabel, Radio } from '@material-ui/core';
import { NavigationBar } from '../../components/PatientProfile/NavigationBar';
import { useStyles } from './GFEManagement.styles';
import { usePractitionerInfo } from '../../hooks/queries/usePractitioners';
import { dispatch, RootState } from '../../rematch';
import { Practitioners } from '../../services/Practitioners';
import {
  GFE_SETTING_MAP,
  GFE_SETTING_NO_PROVIDER_NAME,
  GFE_SETTING_OWN_PROVIDER_NAME,
  GFE_SETTING_PORTRAIT_PROVIDER_NAME,
} from '../../constants/general.constants';
import { MultipleSkeleton } from '../../components/common/LoadingSkeleton';
import compile from '../../utils/toastMessagesCompiler';

const GFE_SETTING_TEXT_MAP: Record<string, Record<string, string>> = {
  [GFE_SETTING_NO_PROVIDER_NAME]: {
    title: 'Do Not Route',
    description: 'You will have to request each GFE manually.',
  },
  [GFE_SETTING_OWN_PROVIDER_NAME]: {
    title: 'Own Clearing Provider',
    description:
      'All GFE requests will be automatically routed to your Clearing Provider. You will still have the' +
      ' ability to request an Urgent GFE from Portrait at the standard fee in the event your Clearing Provider cannot' +
      ' complete a GFE in time.',
  },
  [GFE_SETTING_PORTRAIT_PROVIDER_NAME]: {
    title: 'Portrait Clearing Services',
    description:
      'Portrait will take care of performing your GFEs. The patient will be required to schedule a ' +
      'clearance with our team upon registration. You will be charged $40 per Live GFE.',
  },
};

export const GFEManagement = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id: identityId, userId } = useSelector(({ auth }: RootState) => auth);
  // @ts-ignore
  const { practitionerInfo, isLoading } = usePractitionerInfo(userId, identityId);
  const [selectedGFESetting, setSelectedGFESetting] = useState<number>();

  useEffect(() => {
    if (practitionerInfo) {
      setSelectedGFESetting(GFE_SETTING_MAP[practitionerInfo.gfeSetting] || 0);
    }
  }, [practitionerInfo]);

  const { mutate: updatePractitioner } = useMutation(Practitioners.editPractitioner, {
    onError: () => {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: `Something went wrong, failed to update settings.`,
          duration: 5000,
          type: 'error',
        },
      });
    },
  });

  const navigateBack = () => {
    history.goBack();
  };

  const handleUpdateGFESetting = (setting: number) => {
    if (userId) {
      // @ts-ignore
      updatePractitioner({ id: userId, gfeSetting: setting, skipUpdateInfoParams: true });
      setSelectedGFESetting(setting);
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.success_message', {
            element: `Setting`,
            action: 'saved',
          }),
        },
        duration: 3000,
      });
    }
  };

  if (isLoading) {
    return <MultipleSkeleton addPosition={false} />;
  }

  return (
    <>
      <NavigationBar title="GFE Management" onBackButtonClick={navigateBack} />
      <div className={classes.gfeManagementPage}>
        <div className={classes.settingsContainer}>
          <div className={classes.settingsDescription}>
            <div className={classes.title}>GFE Auto-Routing to</div>
            <div className={classes.subTitle}>
              Choose where do you want to automatically send a patient profile for GFE.
            </div>
          </div>
          <div className={classes.settingsOptionsContainer}>
            {Object.keys(GFE_SETTING_TEXT_MAP).map((key) => {
              const textHash = GFE_SETTING_TEXT_MAP[key];
              const value = GFE_SETTING_MAP[key];
              return (
                <div className={selectedGFESetting === value ? classes.selectedSettingsOption : classes.settingsOption}>
                  <FormControlLabel
                    value={value}
                    control={
                      <Radio
                        className={classes.radioButton}
                        color="primary"
                        checked={selectedGFESetting === +value}
                        onClick={() => handleUpdateGFESetting(+value)}
                      />
                    }
                    label={
                      <>
                        <div>{textHash.title}</div>
                        <div className={classes.subTitle}>{textHash.description}</div>
                      </>
                    }
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
