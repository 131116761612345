import React, { FC, useEffect } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import {
  Grid,
  Box,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
  FormHelperText,
} from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import Page from 'src/components/common/Page';
import BreadcrumbsContainer from '../../common/Breadcrumb/BreadcrumbContainer';
import BreadcrumbText from '../../common/Breadcrumb/BreadcrumbText';
import { useStyles } from '../PurchaseOrders/purchaseOrder.styles';
import { AdminUser } from '../../../services/AdminUser';
import compile from '../../../utils/toastMessagesCompiler';
import { dispatch } from '../../../rematch';
import { useCustomAdminUserInfo, useCustomEmptyAdminUserInfo } from '../../../hooks/queries/useCustomAdminUsers';
import { useCustomRoles } from '../../../hooks/queries/useCustomRoles';
import { IAdminUser } from '../../../interfaces/IAdminUser';
import { schemaForm, schemaFormEdit } from './schemaForm';
import { MEDSPA_ADMIN_ROLE, USER_TYPES } from '../../../constants/general.constants';
import { ADMIN_USERS_LIST_PATH } from '../../../routes/administratorRoutes';

const SUCCESS_ADMIN_USER = compile('generic.success_message', {
  action: 'added',
  element: 'Admin user',
  duration: 3000,
});
const SUCCESS_ADMIN_USER_EDIT = compile('generic.success_message', {
  action: 'edited',
  element: 'Admin user',
  duration: 3000,
});
const ERROR_ADMIN_USER = compile('generic.error_message', {
  action: 'creating',
  element: 'the admin user',
});
const ERROR_ADMIN_USER_EDIT = compile('generic.error_message', {
  action: 'editing',
  element: 'the admin user',
});
const ERROR_GETTING_ADMIN_USER = compile('generic.error_message', {
  action: 'getting',
  element: 'admin user info',
});

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  status: boolean;
  roleId: number;
}

const NewAdministratorUser: FC = () => {
  const { userId }: any = useParams();
  const queryClient = useQueryClient();
  const classes = useStyles();

  const { data: roles, isFetching: isFetchingRoles } = useCustomRoles({ userType: USER_TYPES.ADMINISTRATOR });
  const mutationCreate = useMutation(AdminUser.createAdminUser);
  const mutationEdit = useMutation(AdminUser.editAdminUser);
  const loadingCreate = mutationCreate.isLoading;
  const loadingEdit = mutationEdit.isLoading;
  const editing: boolean = typeof userId !== 'undefined';

  const { adminUserInfo, isFetched, isError, ADMINS_LIST } = editing
    ? useCustomAdminUserInfo(userId)
    : useCustomEmptyAdminUserInfo();

  const { register, handleSubmit, errors, setValue, control } = useForm<FormData>({
    resolver: yupResolver(editing ? schemaFormEdit : schemaForm),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      status: true,
      roleId: undefined,
    },
  });
  const history = useHistory();
  const buttonTitle = editing ? 'SAVE' : 'ADD';
  const currentIdentity = localStorage.getItem('identity.token.uid');
  const currentEmail = editing && isFetched ? adminUserInfo.email : '';
  const { firstName, lastName, email, active, roleId } = adminUserInfo;
  const roleName = roles.find((role) => role.id === roleId)?.name;
  const isMedspaAdmin = roleName === MEDSPA_ADMIN_ROLE;

  if (editing && isError && !isFetched) {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message: ERROR_GETTING_ADMIN_USER,
        type: 'error',
        duration: 1000,
      },
    });
  }

  useEffect(() => {
    if (editing && isFetched && !isMedspaAdmin) {
      setValue('firstName', firstName);
      setValue('lastName', lastName);
      setValue('email', email);
      setValue('status', active);
      setValue('roleId', roleId);
    }
  }, [adminUserInfo, isMedspaAdmin]);

  useEffect(() => {
    if (editing && isMedspaAdmin) {
      history.push(ADMIN_USERS_LIST_PATH);
    }
  }, [isMedspaAdmin]);

  const createAdminUserAction = async (dataForm: FormData) => {
    const boolValue = String(dataForm.status) === 'true';
    const dataToCreate = {
      firstName: dataForm.firstName,
      lastName: dataForm.lastName,
      email: dataForm.email,
      active: boolValue,
      password: dataForm.password,
      roleId: dataForm.roleId,
    };

    try {
      await mutationCreate.mutateAsync(dataToCreate);
      queryClient.invalidateQueries([ADMINS_LIST, userId]);
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: SUCCESS_ADMIN_USER,
          type: 'success',
          duration: 3000,
        },
      });
      window.location.href = ADMIN_USERS_LIST_PATH;
    } catch (err) {
      let errorMessage = ERROR_ADMIN_USER;
      const error = err as Error;
      if (error.message) {
        errorMessage = `${errorMessage} ${error.message}`;
      }
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: errorMessage,
          type: 'error',
          duration: 5000,
        },
      });
    }
  };

  const editAdminUserAction = async (dataForm: FormData) => {
    const boolValue = String(dataForm.status) === 'true';
    const dataToEdit = {
      id: userId,
      firstName: dataForm.firstName,
      lastName: dataForm.lastName,
      email: dataForm.email,
      active: boolValue,
      roleId: dataForm.roleId,
    };
    const data: IAdminUser = {
      ...dataToEdit,
    };

    try {
      await mutationEdit.mutateAsync({ userAdminInfo: data });
      queryClient.invalidateQueries([ADMINS_LIST, userId]);
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: SUCCESS_ADMIN_USER_EDIT,
          type: 'success',
          duration: 3000,
        },
      });
    } catch (error) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: ERROR_ADMIN_USER_EDIT,
          type: 'error',
          duration: 1000,
        },
      });
    } finally {
      window.location.href = ADMIN_USERS_LIST_PATH;
    }
  };

  const onSubmit = (data: FormData) => {
    if (editing) {
      editAdminUserAction(data as FormData);
    } else {
      createAdminUserAction(data as FormData);
    }
  };

  const filteredRoles = roles.filter((role) => role.name !== MEDSPA_ADMIN_ROLE);
  const MyBreadcrumb = (
    <Box mb="17px" paddingRight={5} style={{ backgroundColor: '#f2f5f5' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <BreadcrumbsContainer>
          <BreadcrumbText text="Admin users" linkTo="/administrator/users" />
          <BreadcrumbText text="New admin user" isActive />
        </BreadcrumbsContainer>
      </Box>
    </Box>
  );

  return (
    <Page titleComponent={MyBreadcrumb}>
      <Box width="100%" display="flex" height="100%" flexDirection="column">
        <Box paddingX={2} component="form" onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" justifyContent="space-between" mb={4}>
            <Box>
              <Typography className={classes.title}>{editing ? 'Edit' : 'New'} admin user</Typography>
            </Box>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs>
              <TextField
                data-cy="firstNameInput"
                fullWidth
                name="firstName"
                id="firstName"
                error={!!errors.firstName}
                helperText={errors.firstName?.message || ' '}
                onChange={(event) => setValue('firstName', event.target.value)}
                inputRef={register}
                label="First name"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs>
              <TextField
                data-cy="lastNameInput"
                fullWidth
                name="lastName"
                id="lastName"
                error={!!errors.lastName}
                helperText={errors.lastName?.message || ' '}
                onChange={(event) => setValue('lastName', event.target.value)}
                inputRef={register}
                label="Last name"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs>
              <TextField
                data-cy="emailInput"
                fullWidth
                name="email"
                id="email"
                autoComplete="off"
                error={!!errors.email}
                helperText={errors.email?.message || ' '}
                onChange={(event) => setValue('email', event.target.value)}
                label="Email"
                type="email"
                inputRef={register}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs>
              <FormControl size="medium" variant="outlined" className={classes.formControl}>
                <InputLabel className={classes.formInput}>User status</InputLabel>
                <Controller
                  data-cy="userStatus"
                  as={
                    <Select fullWidth label="User status">
                      <MenuItem value="true">Active</MenuItem>
                      <MenuItem data-cy="inactive" value="false">
                        Inactive
                      </MenuItem>
                    </Select>
                  }
                  name="status"
                  control={control}
                  disabled={!editing || currentIdentity === currentEmail}
                  defaultValue="true"
                  onChange={(event: any) => setValue('status', event.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl data-cy="roleSelect" size="medium" variant="outlined" className={classes.formControl}>
                <InputLabel className={classes.formInput} htmlFor="role-select" error={!!errors.roleId}>
                  Role
                </InputLabel>
                <Controller
                  name="roleId"
                  control={control}
                  error={!!errors.roleId}
                  as={
                    <Select data-cy="select" id="role-select" fullWidth label="Role">
                      {filteredRoles.map(({ id, name }) => (
                        <MenuItem data-cy="menuItem" value={id} key={id}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                  disabled={isFetchingRoles}
                />
                {errors.roleId && <FormHelperText error>{errors.roleId.message}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs>
              <TextField
                data-cy="passInput"
                fullWidth
                name="password"
                autoComplete="new-password"
                onChange={(event) => setValue('password', event.target.value)}
                error={!editing ? !!errors.password : false}
                helperText={!editing ? errors.password?.message : ''}
                label="Password"
                disabled={editing}
                type="password"
                variant="outlined"
                inputRef={register}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Grid item xs={12} md={4}>
              <Button
                data-cy="submitBtn"
                disabled={loadingCreate || loadingEdit || isMedspaAdmin}
                variant="contained"
                className={classes.add}
                color="primary"
                type="submit"
              >
                {loadingCreate || loadingEdit ? <CircularProgress size={25} /> : buttonTitle}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Page>
  );
};

export default NewAdministratorUser;
