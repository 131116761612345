/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unreachable */
import React, { SyntheticEvent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useMutation, useQueryClient } from 'react-query';
import * as Sentry from '@sentry/react';
import { Badge, Box, CardContent, CircularProgress, FormControlLabel, Switch, Typography } from '@material-ui/core';
import { snakeCase } from 'lodash';
import { IGfeRequest } from 'src/types/Patient';
import { useStyles } from './header.styles';
import { Card, Title } from '../common/card';
import { Button } from '../common/Button';
import { GENDER } from '../../constants/medicalProfile.constants';
import { calculateAge, getUserTypePropertyId } from '../../utils/patient.util';
import { RootState, dispatch } from '../../rematch';
import { Pill } from '../common/Pill';
import { ShortMultipleSkeleton } from '../common/LoadingSkeleton';
import Patients from '../../services/Patients';
import compile from '../../utils/toastMessagesCompiler';
import { PATIENT_INFO } from '../../constants/reactQuery.keys';
import { PATIENT_CLEARED_STATUS, NEW_SERVICE_VISIT_TITLE } from '../../constants/newServiceVisit.constants';
import {
  ADMIN_ROLE,
  CLEARED_STATE,
  GFE_AND_NEW_SERVICE_VISIT_MODAL_CONFIRMATION,
  INCOMPLETE_STATE,
  PHYSICIAN_ROLE,
  PORTRAIT_LEGACY,
  PORTRAIT_FLEX,
  PRACTITIONER_ROLE,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  TO_CLEAR_STATE,
  USER_TYPES,
  NORMAL_REQUEST_NAME,
  PORTRAIT_LEGACY_PLUS,
} from '../../constants/general.constants';
import { FEATURE_BEAUTIBANK } from '../../constants/features.constants';
import { useUpdatePatientIgnoredStatus } from '../../hooks/mutations/useUpdatePatientIgnoredStatus';
import { useClaimPatient } from '../../hooks/mutations/useClaimPatient';

import { hasAccessTo, hasFeatureEnabled } from '../../utils/auth.utils';
import ConfirmModal from '../common/ConfirmModal';
import ConfirmGfeModal from '../common/ConfirmGfeModal';
import formatDate from '../../utils/formatDate';
import { BeautyBankBadge } from './BeautyBankSection/BeautyBankBadge';
import {
  useCreateNewServiceVisitVersionMutation,
  useOpenedServiceVisit,
} from '../../hooks/queries/useOpenedServiceVisit';
import { PRACTITIONER_PATIENT_CLEARED_PATH, PRACTITIONER_PATIENT_ALL_PATH } from '../../routes/practitionerRoutes';
import { PHYSICIAN_PATIENT_NEW_PATH } from '../../routes/physicianRoutes';
import { ADMIN_PATIENTS_LIST_PATH } from '../../routes/administratorRoutes';
import { SERVICE_VISIT_VERSIONS } from '../../constants/serviceVisits/serviceVisit.constants';
import { GFE_REQUEST, READ_BEAUTIBANK, READ_MESSAGE_THREAD } from '../../constants/actions.constants';
import { useUpdateCustomerGfeStatus } from '../../hooks/mutations/useUpdateCustomerStatus';
import { useCreateGfeRequest } from '../../hooks/mutations/useCreateGfeRequest';
import { useCustomRoles } from '../../hooks/queries/useCustomRoles';
import { usePractitionerInfo } from '../../hooks/queries/usePractitioners';
import { showSnackbar } from '../../utils/global';
import { useServiceGroups, useServices } from '../../hooks/queries/services/useServices';

const styles = {
  color: '#000000',
  backgroundColor: '#e7eeed',
  margin: '0 3px',
  fontSize: '12px',
  paddingRight: '8px',
  paddingLeft: '8px',
};

const isProduction = process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION';

interface IHeaderProps {
  patientId: string;
  handleShowConversations: (e: SyntheticEvent) => void;
  unreadMessagesCount?: number | undefined;
  newState?: string;
  practitionerId?: string;
  isMedspaAdmin?: boolean;
}

const Header = ({
  practitionerId,
  patientId,
  handleShowConversations,
  unreadMessagesCount = 0,
  newState = '',
  isMedspaAdmin = false,
}: IHeaderProps) => {
  const classes = useStyles();
  const history = useHistory();
  const ROUTE_TO_REDIRECT_ON_CLAIMED_PATIENT = `${PHYSICIAN_PATIENT_NEW_PATH}/${newState}`;

  const {
    id: identityId,
    user,
    userType,
    userId,
    permissions,
    featureList,
    isLoading: isLoadingUser,
  } = useSelector(({ auth }: RootState) => auth);
  const patientBasicInfo = useSelector(({ patient }: RootState) => patient.basicInfo);
  const { basicInfoLoadedAt, basicInfoError: error } = patientBasicInfo;
  const patientData = useSelector(({ patient }: RootState) => patient);
  const { photos, isLoadingBasicInfo } = patientData;

  const newStatus = newState || '';

  const [openDialog, setOpenDialog] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [openGfeDialog, setOpenGfeDialog] = React.useState(false);
  const [currentGfeRequest, setCurrentGfeRequest] = React.useState<IGfeRequest | null>(null);
  const [showMissingServiceOrGroupModal, setShowMissingServiceOrGroupModal] = React.useState<boolean>(false);

  const useUpdatePatientIgnoredStatusMutation = useUpdatePatientIgnoredStatus();
  const {
    data: { id: openedServiceVisitId, version: openedServiceVisitVersion, practitionerId: openedPractitionerId },
    isLoading: isLoadingOpenedServiceVisit,
    isFetching: isFetchingOpenedServiceVist,
  } = useOpenedServiceVisit({ patientId });
  const createNewServiceVisitVersionMutation = useCreateNewServiceVisitVersionMutation(+patientId);
  const { data: services, isLoading: isLoadingServices } = useServices();
  const { data: serviceGroups = [], isLoading: isLoadingServiceGroups } = useServiceGroups();

  const claimMutation = useClaimPatient();

  const queryClient = useQueryClient();

  const isPhysician = userType === PHYSICIAN_ROLE;

  const { mutateAsync, isLoading: isDeleting } = useMutation(Patients.delete);
  const [isSendingGFERequest, setIsSendingGFERequest] = React.useState(false);
  const updateCustomerGfeStatusMutation = useUpdateCustomerGfeStatus();
  const { mutateAsync: createNewGfeRequest } = useCreateGfeRequest();
  const { data: roles = [], isLoading: isLoadingRoles } = useCustomRoles({ userType: USER_TYPES.PRACTITIONER });
  // @ts-ignore
  const { practitionerInfo, isLoading: isPractitionerLoading } = usePractitionerInfo(+practitionerId || userId);
  const isFlex = roles.find((role) => +role.id === practitionerInfo?.roleId)?.name === PORTRAIT_FLEX;
  const isLegacyPlus = roles.find((role) => +role.id === practitionerInfo?.roleId)?.name === PORTRAIT_LEGACY_PLUS;
  const { gfeSetting } = practitionerInfo;

  const roleName = roles.find((role) => +role.id === practitionerInfo?.roleId)?.name;
  const isNonLegacyPractitioner = roleName !== PORTRAIT_LEGACY && roleName !== PORTRAIT_LEGACY_PLUS;

  const deletePatient = async () => {
    try {
      await mutateAsync(+patientId);
      history.push('/administrator/patients');
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.success_message', {
            element: 'Patient',
            action: 'removed',
          }),
        },
      });
    } catch (e) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            element: 'Patient',
            action: 'removed',
          }),
        },
      });
    }
  };

  const handleServiceVisitButton = async () => {
    if (!openedServiceVisitId) {
      await queryClient.invalidateQueries([PATIENT_INFO, patientId]);
      setOpenDialog(true);
    } else {
      onServiceVisitClick();
    }
  };

  // Create new service visit version
  const handleServiceVisitBETAButton = () => {
    if ((!services.length || !serviceGroups.length) && isNonLegacyPractitioner) {
      setShowMissingServiceOrGroupModal(true);
    } else {
      handleServiceVisitButton();
    }
  };

  const getPillTitle = (status: string | null) => {
    switch (status) {
      case 'to_clear':
      case 'profile_completed':
        return 'To Clear';
      case 'cleared':
        return 'Medical Exam Current';
      case 'incomplete':
        return 'Incomplete';
      case 'to_reclear':
        return 'To Reclear';
      case 'pending_reclear':
        return 'Requires Update';
      case 'rejected':
        return 'Rejected';
      case 'lead':
        return 'Lead';
      case 'no_show':
        return 'No Show';
      case 'to_live_clear':
        return 'To Live Clear';
      default:
        return 'Unknown Status';
    }
  };

  const handleIgnoreButton = async () => {
    try {
      await useUpdatePatientIgnoredStatusMutation.mutateAsync({
        customerId: patientId,
      });
      queryClient.invalidateQueries([PATIENT_INFO, patientId]);
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.success_message', {
            element: 'Status',
            action: 'updated',
          }),
        },
      });
    } catch (e) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'updating',
            element: 'the status',
          }),
          type: 'error',
        },
      });
    }
  };

  const handleClaim = async (claim: boolean) => {
    try {
      const action = claim ? 'claimed' : 'unclaimed';

      await claimMutation.mutateAsync({
        customerId: +patientId,
        identityId, // to set the optimistic update
        claim,
      });

      // Query invalidated automatically in the onSettle of the mutation

      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.success_message', {
            element: 'Patient',
            action,
          }),
        },
      });
    } catch (e) {
      const { message } = e?.response?.data;

      const errorAction = claim ? 'claiming' : 'unclaiming';

      const genericMessage = compile('generic.error_message', {
        action: errorAction,
        element: 'the patient',
      });

      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: message || genericMessage,
          type: 'error',
        },
      });
    }
  };

  const fetchLastPendingGfeRequest = async () => {
    const gfeRequest = await Patients.getLastPendingGfeRequest(patientId);

    if (gfeRequest) {
      setCurrentGfeRequest(gfeRequest);
    } else {
      setCurrentGfeRequest(null);
    }
  };

  useEffect(() => {
    fetchLastPendingGfeRequest();
  }, []);

  // TODO: remove the dispatch, it's used to hide the Complete button on the MainSection Component.
  useEffect(() => {
    if (error) {
      const { message } = error as Error;
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message,
          type: 'error',
        },
      });
      /* eslint-disable no-nested-ternary */
      const goTo =
        userType === ADMIN_ROLE
          ? ADMIN_PATIENTS_LIST_PATH
          : hasAccessTo(GFE_REQUEST, permissions)
            ? PRACTITIONER_PATIENT_CLEARED_PATH
            : PRACTITIONER_PATIENT_ALL_PATH;
      history.push(goTo);
      const sentryMessage = `${message} for patient ${patientId}`;
      Sentry.captureMessage(sentryMessage, 'debug' as Sentry.Severity);
    }
  }, [patientBasicInfo, photos, error]);

  // if the current user is a physician we should check if the patient is not claimed by another physician
  // if claimed by another physician we should redirect home and show warning message
  useEffect(() => {
    if (isLoadingBasicInfo || isLoadingUser) {
      return;
    }

    if (isPhysician && patientBasicInfo.claimed && patientBasicInfo.claimedBy !== identityId) {
      history.push(ROUTE_TO_REDIRECT_ON_CLAIMED_PATIENT);

      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('patient.claimed_by_another_physician', {
            claimedBy: patientBasicInfo.claimedByName,
          }),
          type: 'warning',
        },
      });
    }
  }, [patientBasicInfo, identityId, isLoadingBasicInfo, isLoadingUser]);

  const navigateToFullHistory = () => {
    history.push({
      pathname: `/history/${patientId}`,
      state: { newState: newStatus, from: history.location.pathname },
    });
  };

  const requestPhotos = () => {
    dispatch({
      type: 'patient/requestPhoto',
      payload: {
        patientId,
        userTypePropertyId: getUserTypePropertyId(userType),
      },
    });
  };

  const resetNewServiceVisitState = () => {
    dispatch({ type: 'newServiceVisit/cleanServiceVisitData' });
  };

  const onServiceVisitClick = (): void => {
    if (hasAccessTo(GFE_REQUEST, permissions) && roleName !== PORTRAIT_LEGACY_PLUS) {
      if (patientBasicInfo.customerStatus !== PATIENT_CLEARED_STATUS) {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('new_service_visit.patient_need_to_be_cleared'),
            type: 'error',
          },
        });
        return;
      }
    }

    if (!!openedServiceVisitId && +openedPractitionerId !== +userId) {
      showSnackbar(compile('new_service_visit.does_not_belong_to_practitioner'));
      return;
    }

    resetNewServiceVisitState();
    if (openedServiceVisitId) {
      if (openedServiceVisitVersion >= SERVICE_VISIT_VERSIONS.VERSION_JULY_2023) {
        history.push({
          pathname: `/patient/${patientId}/newServiceVisit/${openedServiceVisitId}`,
          state: { newState: newStatus, from: history.location.pathname },
        });
      } else {
        history.push({
          pathname: `/patient/${patientId}/serviceVisit/${openedServiceVisitId}`,
          state: { newState: newStatus, from: history.location.pathname },
        });
      }
    } else {
      createNewServiceVisitVersionMutation.mutate();
    }
  };

  const handleCreditCardNavigation = () => {
    history.push(`/patient/${patientId}/creditCards`);
  };

  const handleNavigateRetailCheckout = () => {
    history.push(`/patient/${patientId}/retailCheckout`);
  };

  const handleConsentNavigation = () => {
    history.push(`/patient/${patientId}/consents`);
  };

  const handleProgressNoteNavigation = () => {
    history.push(`/patient/${patientId}/progressNote`);
  };

  const fullyLoadedBasicInfo = basicInfoLoadedAt && !isLoadingBasicInfo;
  const fullyLoadedOpenedServiceVisit = !isLoadingOpenedServiceVisit && !isFetchingOpenedServiceVist;
  const fullyLoadedNewServiceVisitInfo =
    !fullyLoadedBasicInfo || !fullyLoadedOpenedServiceVisit || !isLoadingRoles || !isPractitionerLoading;

  if (!fullyLoadedBasicInfo) {
    return (
      <Card>
        <CardContent style={{ padding: '12px 15px' }}>
          <ShortMultipleSkeleton length={3} />
        </CardContent>
      </Card>
    );
  }

  const handleGFErequest = async () => {
    try {
      setIsSendingGFERequest(true);
      await updateCustomerGfeStatusMutation.mutateAsync({
        patientId,
        patientStatus: snakeCase(TO_CLEAR_STATE),
      });
      setIsSendingGFERequest(false);
      queryClient.invalidateQueries([PATIENT_INFO, patientId]);
      showSnackbar(compile('gfe_request.success'), SNACKBAR_SUCCESS);
    } catch (e) {
      showSnackbar(compile('gfe_request.error'), SNACKBAR_ERROR);
    }
  };

  const renderGFErequest = (status: string) => {
    const buttonStyles = { ...styles, backgroundColor: '#12574d', color: 'white' };
    const gfeInProgress = status === snakeCase(TO_CLEAR_STATE);

    if (
      roleName === PORTRAIT_LEGACY ||
      status === CLEARED_STATE ||
      status === INCOMPLETE_STATE ||
      isPractitionerLoading
    ) {
      return null;
    }

    if (isSendingGFERequest) {
      return (
        <Box className={classes.circularProgress}>
          Processing
          <CircularProgress size={20} color="secondary" />
        </Box>
      );
    }

    return (
      <Button
        style={buttonStyles}
        title={gfeInProgress ? 'GFE IN PROGRESS' : 'REQUEST GFE'}
        onClick={gfeInProgress ? null : handleGFErequest}
        disabled={gfeInProgress}
      />
    );
  };

  const onRequestNewGfe = async (requestType: number) => {
    try {
      const response = await createNewGfeRequest({ patientId, requestType });
      await dispatch.patient.fetchBasicInfo({ patientId: +patientId, useCache: false });
      setCurrentGfeRequest(response.gfeRequest);
    } catch (reqError) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: `Error processing GFE request: ${reqError}`,
          type: 'error',
        },
      });
    }
  };

  const renderNewUrgentGFErequest = () => {
    const buttonStyles = { ...styles, backgroundColor: '#12574d', color: 'white' };

    if (isLoadingServiceGroups) {
      return null;
    }

    return <Button style={buttonStyles} title="REQUEST GFE" onClick={(e) => setOpenGfeDialog(true)} />;
  };

  // new gfe buttons to show copy of BUTTONS_TO_SHOW function to render buttons by role
  const newGfeButtonsToShow = () => {
    const practitionerButtons = () => (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Button
            dataCy="serviceVisitHistoryBtn"
            onClick={navigateToFullHistory}
            style={{ ...styles, marginLeft: '0' }}
            title="FULL SERVICE HISTORY"
          />
          <Button style={styles} title="CONSENTS" onClick={handleConsentNavigation} />
          <Button style={styles} title="PROGRESS NOTE" onClick={handleProgressNoteNavigation} />
          {!!openedServiceVisitId && (
            <Button
              dataCy="patientProfileServiceVisitButton"
              disabled={!fullyLoadedBasicInfo || !fullyLoadedOpenedServiceVisit}
              style={{ ...styles, backgroundColor: '#acd2cc' }}
              title="Current Service Visit"
              onClick={handleServiceVisitButton}
            />
          )}
          {!openedServiceVisitId && !isLoadingServices && (
            <Button
              dataCy="patientProfileServiceVisitButton"
              disabled={!fullyLoadedNewServiceVisitInfo}
              style={{ ...styles, backgroundColor: '#acd2cc' }}
              title="New Service Visit"
              onClick={handleServiceVisitBETAButton}
            />
          )}
        </div>
        <div>
          {renderNewUrgentGFErequest()}
          <Button
            style={{ ...styles, backgroundColor: '#acd2cc' }}
            title="RETAIL CHECKOUT"
            onClick={handleNavigateRetailCheckout}
          />
        </div>
      </div>
    );

    const physicianButtons = () => (
      <div>
        <Button onClick={navigateToFullHistory} style={{ ...styles, marginLeft: '0' }} title="FULL SERVICE HISTORY" />
        {!user?.isAdvancedPractitioner &&
          (useUpdatePatientIgnoredStatusMutation.isLoading || !fullyLoadedBasicInfo ? (
            <CircularProgress style={{ color: 'black', alignItems: 'center', margin: 'auto 20px' }} size={20} />
          ) : (
            <Button
              onClick={handleIgnoreButton}
              style={{ ...styles }}
              title={patientBasicInfo.ignored ? 'UNIGNORE' : 'IGNORE'}
            />
          ))}
        <Button style={styles} title="CONSENTS" onClick={handleConsentNavigation} />
      </div>
    );

    const adminButtons = () => (
      <div>
        <Button onClick={navigateToFullHistory} style={{ ...styles, marginLeft: '0' }} title="FULL SERVICE HISTORY" />
        <Button style={styles} title="CONSENTS" onClick={handleConsentNavigation} />
        <Button style={styles} title="PROGRESS NOTE" onClick={handleProgressNoteNavigation} />

        {!isProduction && !isMedspaAdmin && (
          <Button
            disabled={isDeleting}
            style={{ ...styles, backgroundColor: '#FBDADA' }}
            title="DELETE"
            onClick={() => setShowDeleteModal(true)}
          />
        )}
      </div>
    );

    switch (userType) {
      case PRACTITIONER_ROLE:
        return practitionerButtons();
      case PHYSICIAN_ROLE:
        return physicianButtons();
      case ADMIN_ROLE:
        return adminButtons();
      default:
        return null;
    }
  };

  const BUTTONS_TO_SHOW = () => {
    const PRACTITIONER_BUTTONS = [
      <Button
        dataCy="serviceVisitHistoryBtn"
        onClick={navigateToFullHistory}
        style={{ ...styles, marginLeft: '0' }}
        title="FULL SERVICE HISTORY"
      />,
      <Button style={styles} title="CONSENTS" onClick={handleConsentNavigation} />,
      // hasAccessTo(CREATE_PHOTOS_REQUEST, permissions) && (
      //   <Button style={styles} title="REQUEST PHOTOS" onClick={requestPhotos} />
      // ),
      <Button style={styles} title="PROGRESS NOTE" onClick={handleProgressNoteNavigation} />,
      renderGFErequest(patientBasicInfo?.customerStatus as string),
      openedServiceVisitId ? (
        <Button
          dataCy="patientProfileServiceVisitButton"
          disabled={!fullyLoadedBasicInfo || !fullyLoadedOpenedServiceVisit}
          style={{ ...styles, backgroundColor: '#acd2cc' }}
          title="Current Service Visit"
          onClick={handleServiceVisitButton}
        />
      ) : (
        <>
          <Button
            dataCy="patientProfileServiceVisitButton"
            disabled={!fullyLoadedNewServiceVisitInfo}
            style={{ ...styles, backgroundColor: '#acd2cc' }}
            title="New Service Visit"
            onClick={handleServiceVisitBETAButton}
          />
        </>
      ),
      <Button
        style={{ ...styles, position: 'absolute', right: '24px', backgroundColor: '#acd2cc' }}
        title="RETAIL CHECKOUT"
        onClick={handleNavigateRetailCheckout}
      />,
    ];

    const PHYSICIAN_BUTTONS = [
      <Button onClick={navigateToFullHistory} style={{ ...styles, marginLeft: '0' }} title="FULL SERVICE HISTORY" />,
      !user?.isAdvancedPractitioner &&
        (useUpdatePatientIgnoredStatusMutation.isLoading || !fullyLoadedBasicInfo ? (
          <CircularProgress style={{ color: 'black', alignItems: 'center', margin: 'auto 20px' }} size={20} />
        ) : (
          <Button
            onClick={handleIgnoreButton}
            style={{ ...styles }}
            title={patientBasicInfo.ignored ? 'UNIGNORE' : 'IGNORE'}
          />
        )),
      <Button style={styles} title="CONSENTS" onClick={handleConsentNavigation} />,
      <Badge color="error" badgeContent={unreadMessagesCount}>
        <Button style={styles} title="MESSAGES" onClick={(e: SyntheticEvent) => handleShowConversations(e)} />
      </Badge>,
      // hasAccessTo(CREATE_PHOTOS_REQUEST, permissions) && (
      //   <Button style={styles} title="REQUEST PHOTOS" onClick={requestPhotos} />
      // ),
    ];

    const ADMIN_BUTTONS = [
      <Button onClick={navigateToFullHistory} style={{ ...styles, marginLeft: '0' }} title="FULL SERVICE HISTORY" />,
      <Button style={styles} title="CONSENTS" onClick={handleConsentNavigation} />,
      // hasAccessTo(CREATE_PHOTOS_REQUEST, permissions) && (
      //   <Button style={styles} title="REQUEST PHOTOS" onClick={requestPhotos} />
      // ),
      <Button style={styles} title="PROGRESS NOTE" onClick={handleProgressNoteNavigation} />,
    ];

    if (!isProduction) {
      ADMIN_BUTTONS.push(
        <Button
          disabled={isDeleting}
          style={{ backgroundColor: '#FBDADA' }}
          title="DELETE"
          onClick={() => setShowDeleteModal(true)}
        />
      );
    }

    switch (userType) {
      case PRACTITIONER_ROLE:
        return PRACTITIONER_BUTTONS;
      case PHYSICIAN_ROLE:
        return PHYSICIAN_BUTTONS;
      case ADMIN_ROLE:
        return ADMIN_BUTTONS;
      default:
        return [];
    }
  };

  const patientStatusColor = (status: string | null) => {
    switch (status) {
      case 'cleared':
        return 'green';
      case 'lead':
        return 'orange';
      case 'no_show':
        return 'darkOrange';
      default:
        return 'red';
    }
  };

  return (
    <>
      <Card className={classes.card}>
        <CardContent style={{ padding: '12px 15px' }}>
          <Box>
            {roleName !== PORTRAIT_LEGACY ? newGfeButtonsToShow() : BUTTONS_TO_SHOW()}
            {isPhysician && (
              <Box display="flex" flexDirection="column" style={{ marginLeft: 'auto', marginTop: 'auto' }}>
                <FormControlLabel
                  style={{ marginLeft: 'auto', marginRight: 0 }}
                  control={
                    <>
                      {claimMutation.isLoading && <CircularProgress size={20} />}
                      <Switch
                        color="primary"
                        disabled={claimMutation.isLoading}
                        checked={!!patientBasicInfo.claimed}
                        onChange={() => handleClaim(!patientBasicInfo.claimed)}
                        name="claim"
                      />
                    </>
                  }
                  label={patientBasicInfo.claimed ? 'Claimed' : 'Claim'}
                />

                {patientBasicInfo.claimedAt && (
                  <Typography variant="caption">Claimed at: {formatDate(patientBasicInfo.claimedAt, 'LLL')}</Typography>
                )}
              </Box>
            )}
          </Box>
        </CardContent>
        <CardContent style={{ padding: '12px 15px' }}>
          <div className={classes.nameContainer}>
            {fullyLoadedBasicInfo && (
              <Title
                title={`${patientBasicInfo.firstName} ${patientBasicInfo.lastName}`}
                style={{ marginTop: '0', marginBottom: '0', marginRight: '10px' }}
              />
            )}
            {!fullyLoadedBasicInfo ? (
              <CircularProgress style={{ color: 'black' }} size={20} />
            ) : (
              <Pill
                title={getPillTitle(patientBasicInfo.customerStatus)}
                color={patientStatusColor(patientBasicInfo.customerStatus)}
                style={{ marginRight: '5px' }}
              />
            )}
            {currentGfeRequest && (
              <Pill
                title={
                  currentGfeRequest.requestType === NORMAL_REQUEST_NAME
                    ? 'Standard GFE Requested'
                    : `Urgent GFE Requested - ${moment(currentGfeRequest.createdAt).fromNow(true)} ago`
                }
                color="teal"
                style={{ marginLeft: '5px' }}
              />
            )}
            {((patientId &&
              hasFeatureEnabled(FEATURE_BEAUTIBANK, featureList) &&
              hasAccessTo(READ_BEAUTIBANK, permissions)) ||
              userType === ADMIN_ROLE) && <BeautyBankBadge patientId={patientId} />}
          </div>
          <h5 className={classes.subtitle}>
            {fullyLoadedBasicInfo &&
              `${patientBasicInfo.sex !== null ? GENDER[patientBasicInfo.sex] : ''}${
                patientBasicInfo.sex ? ', ' : ''
              } ${(patientBasicInfo.dateOfBirth !== null && calculateAge(patientBasicInfo.dateOfBirth)) || ''} ${
                patientBasicInfo.dateOfBirth ? ' - ' : ''
              } Created at: ${moment(patientBasicInfo.createdAt).format('MM/DD/YYYY')}`}{' '}
            - MRN: <strong data-cy="mrn">{patientId}</strong>
          </h5>
          <hr className={classes.seperator} />
          <h5 className={classes.subtitle}>
            Credit Card on File:{' '}
            {patientBasicInfo?.creditCards?.length ? (
              <span className={`${classes.bold} ${classes.textGreen}`}>
                Yes (saved in {patientBasicInfo.creditCardOnFile})
              </span>
            ) : (
              <span className={`${classes.bold} ${classes.textRed}`}>No</span>
            )}
          </h5>
          {!isMedspaAdmin &&
            (!patientBasicInfo?.creditCards?.length ? (
              <Button
                className={classes.creditCardLinkButton}
                title="Add Credit Card"
                onClick={handleCreditCardNavigation}
              />
            ) : (
              <Button
                className={classes.manageCardLinkButton}
                title="Manage Cards"
                onClick={handleCreditCardNavigation}
              />
            ))}
        </CardContent>
      </Card>
      <ConfirmGfeModal
        isLegacyPlus={isLegacyPlus}
        isFlex={isFlex}
        gfeSetting={gfeSetting}
        currentGfeRequest={currentGfeRequest}
        openDialog={openGfeDialog}
        setOpenDialog={setOpenGfeDialog}
        onRequestNewGfe={onRequestNewGfe}
        hasServiceGroups={!!serviceGroups.length}
      />
      <ConfirmModal
        title={NEW_SERVICE_VISIT_TITLE}
        contentText={GFE_AND_NEW_SERVICE_VISIT_MODAL_CONFIRMATION}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        onClickConfirm={onServiceVisitClick}
      />
      <ConfirmModal
        title="Delete patient"
        contentText="Are you sure you want to delete this patient?"
        openDialog={showDeleteModal}
        setOpenDialog={setShowDeleteModal}
        onClickConfirm={deletePatient}
      />
      <ConfirmModal
        title="Action Required: Activate Service Groups and Services"
        contentText="Currently, your account does not have any active Service Groups or Services. To proceed with the
        Service Visit flow, you will need to activate them. Please click on the 'Add' button to do so."
        confirmText="Add"
        openDialog={showMissingServiceOrGroupModal}
        setOpenDialog={setShowMissingServiceOrGroupModal}
        onClickConfirm={() => history.push('/practitioner/inventory/count')}
      />
    </>
  );
};

export default Header;
