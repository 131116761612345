import { isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import { Box, IconButton, InputAdornment, Typography } from '@material-ui/core';
// eslint-disable-next-line max-len
import { OrderApprovalFilterStyles as useStyles } from 'src/components/DashboardPractitioner/Tabs/OrderingTab/inventoryOrder.styles';
import { PickersDate } from 'src/interfaces/IInventoryOrder';
import { DATEPICKER_FORMAT, INPUT_DATE_FORMAT } from 'src/constants/inventory.constants';
import { Close as CloseIcon, Today } from '@material-ui/icons';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { Props as FilterProps } from '.';

type Props = {
  isLoading: boolean;
  label: string;
  value?: string;
  setter: (date: string | undefined) => void;
};

export const OrderApprovalDateSelect = ({ value, setter, label, isLoading }: Props) => {
  const classes = useStyles();

  const handleChangeDate = (date: PickersDate) => {
    !!date && setter(date.format(INPUT_DATE_FORMAT));
  };

  const reset = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setter(undefined);
  };

  return (
    <Box display="flex" flexDirection="column" className={classes.box}>
      <Typography variant="overline" className={classes.filterTitle}>
        {label}
      </Typography>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          disabled={isLoading}
          placeholder="Select a date"
          value={value ? moment(value) : null}
          format={DATEPICKER_FORMAT}
          disableToolbar
          onChange={handleChangeDate}
          data-testid="orderApproval.datepicker"
          variant="inline"
          inputVariant="outlined"
          className={classes.statusSelect}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={reset} disabled={!value} className={classes.noHover}>
                  {!value ? <Today htmlColor="#777" /> : <CloseIcon />}
                </IconButton>
              </InputAdornment>
            ),
            className: classes.statusSelect,
          }}
          autoOk
        />
      </MuiPickersUtilsProvider>
    </Box>
  );
};

export const OrderApprovalDateFilters = ({
  isLoading,
  setFilterState,
  filterState = {},
}: Omit<FilterProps, 'roles' | 'currentCount'>) => {
  const classes = useStyles();

  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);

  useEffect(() => {
    !isEmpty(filterState) &&
      setFilterState({
        ...filterState,
        startDate,
        endDate,
      });
  }, [startDate, endDate]);

  return (
    <Box display="flex" flexDirection="row" className={classes.box}>
      <OrderApprovalDateSelect label="Start Date" setter={setStartDate} value={startDate} isLoading={isLoading} />
      <OrderApprovalDateSelect label="End Date" setter={setEndDate} value={endDate} isLoading={isLoading} />
    </Box>
  );
};

export default OrderApprovalDateFilters;
