import React from 'react';
import { Box } from '@material-ui/core';
import OrderApprovalDateModal from './OrderApprovalDateModal';
import OrderApprovalFilters, { PropsWithFullRole as FilterProps } from './OrderApprovalFIlters';

export const OrderApprovalHeader = ({ isLoading, currentCount, roles = [], setFilterState }: FilterProps) => (
  <Box display="flex" flexDirection="row" width="100%" marginBottom="35px" data-testid="order-approval-header">
    <OrderApprovalFilters
      isLoading={isLoading}
      currentCount={currentCount}
      setFilterState={setFilterState}
      roles={roles}
    />
    <OrderApprovalDateModal disabled={isLoading} />
  </Box>
);

export default OrderApprovalHeader;
