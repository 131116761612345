import React from 'react';
import { LandingPageProps } from 'src/interfaces/ILeadLandingPage';
import { Box, Typography } from '@material-ui/core';
import { GOOGLE, YELP } from 'src/constants/leadLandingPage.constants';
import { ReviewStars } from './ReviewStars';

const ReviewBox: React.FC<LandingPageProps> = ({
  reviewSource,
  description,
  classes,
  mobile,
}: LandingPageProps): React.ReactElement => (
  <Box className={classes?.leadSectionReview} data-mobile={mobile}>
    <Typography className={classes?.formSectionReview}>{description}</Typography>
    <ReviewStars score={5} classes={classes} />
    <Box className={classes?.reviewIcon} data-icon={reviewSource ?? GOOGLE} />
  </Box>
);

export const ReviewsSection: React.FC<LandingPageProps> = ({
  classes,
  mobile,
}: LandingPageProps): React.ReactElement => (
  <>
    <ReviewBox
      classes={classes}
      mobile={mobile}
      reviewSource={GOOGLE}
      description={
        '\
          “She did my jawline and cheeks and I have NEVER been happier or more \
          confident. She is seriously amazing!”\
        '
      }
    />
    <ReviewBox
      classes={classes}
      mobile={mobile}
      reviewSource={YELP}
      description="“I feel confident with my treatments because it feels like a collaboration.”"
    />
    <ReviewBox
      classes={classes}
      mobile={mobile}
      reviewSource={GOOGLE}
      description={
        "\
          “What a great experience. I've had botox and fillers in the past and never \
          felt I received good results. I felt I was just a number, not a patient with \
          other providers … a true artist”\
        "
      }
    />
    <ReviewBox
      classes={classes}
      mobile={mobile}
      reviewSource={GOOGLE}
      description="“Excellent injector. I will definitely be going back.”"
    />
    <ReviewBox
      classes={classes}
      mobile={mobile}
      reviewSource={YELP}
      description="“Thank you for making me feel confident and beautiful.”"
    />
    <ReviewBox
      classes={classes}
      mobile={mobile}
      reviewSource={GOOGLE}
      description="“You can't ask or pay for a better experience.”"
    />
    <ReviewBox
      classes={classes}
      mobile={mobile}
      reviewSource={YELP}
      description="“So much knowledge with everything he does”"
    />
    <ReviewBox
      classes={classes}
      mobile={mobile}
      reviewSource={GOOGLE}
      description={
        '\
          “She was so thorough and walked me through every \
          step of the way and took her time!”\
        '
      }
    />
  </>
);
