import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { MEDSPA_ADMIN_ROLE, PORTRAIT_LEGACY, PORTRAIT_LEGACY_PLUS, USER_TYPES } from 'src/constants/general.constants';
import { RootState } from 'src/rematch';
import { FEATURE_ENABLE_CHARTING, FEATURE_MEDSPA_MARKETING } from 'src/constants/features.constants';
import { useMedspaRole } from 'src/hooks/queries/useMedspaAdmins';
import { MEDSPA_PROVIDER_TYPE, MENU_OPTIONS, MenuOption } from 'src/constants/menu.constants';
import useMedspaAdminOnboarding from 'src/hooks/useMedspaAdminOnboarding';
import { getMedspaOptions } from 'src/utils/menu.utils';
import { RecursiveMenu } from 'src/components/Sidebar/RecursiveMenu';
import { MenuListStyles as useStyles } from 'src/components/Sidebar/Sidebar.styles';

type Props = {
  collapsed?: boolean;
  setCollapsed?: (isCollapsed: boolean) => void;
};

export const SidebarItems = ({ collapsed, setCollapsed }: Props) => {
  const classes = useStyles();
  const { permissions, roleName, featureList, user, userType } = useSelector(({ auth }: RootState) => auth);
  const { data: medspaRoleName } = useMedspaRole(roleName === MEDSPA_ADMIN_ROLE);

  const [subMenus, setSubMenus] = useState<string[]>([]);

  const isMedspaActive = process.env.REACT_APP_MEDSPA_FIELDS === 'true';
  const isMedspaAdmin = roleName === MEDSPA_ADMIN_ROLE;
  const showMedspaItems = isMedspaActive && isMedspaAdmin;

  const hasOptedInToDoList = featureList?.some((feature: any) => feature.key === FEATURE_ENABLE_CHARTING);
  const hasOptedInMarketing = featureList?.some((feature: any) => feature.key === FEATURE_MEDSPA_MARKETING);
  const isMedspaLegacyOrLegacyPlus = medspaRoleName === PORTRAIT_LEGACY || medspaRoleName === PORTRAIT_LEGACY_PLUS;
  const optionsType = userType === USER_TYPES.PRACTITIONER && Boolean(user.userGroupId) ? MEDSPA_PROVIDER_TYPE : '';

  const { onboardingEnabled } = useMedspaAdminOnboarding();

  const medspaOptions: MenuOption[] = useMemo(
    () =>
      !showMedspaItems
        ? []
        : getMedspaOptions({
          onboardingEnabled: onboardingEnabled ?? false,
          hasOptedInMarketing,
          hasOptedInToDoList,
          isMedspaLegacyOrLegacyPlus,
          classes,
        }),
    [
      featureList,
      isMedspaLegacyOrLegacyPlus,
      hasOptedInToDoList,
      hasOptedInMarketing,
      onboardingEnabled,
      showMedspaItems,
    ]
  );

  const menuItems = useMemo(
    () => (
      <RecursiveMenu
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        permissions={permissions}
        subMenus={subMenus}
        setSubMenus={setSubMenus}
        classes={classes}
        optionsMenu={showMedspaItems ? medspaOptions : MENU_OPTIONS(classes, optionsType)}
      />
    ),
    [collapsed, setCollapsed, permissions, subMenus, classes]
  );

  return <div>{menuItems}</div>;
};

export default SidebarItems;
