import React from 'react';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { EHRTextField, EHRTextFieldProps } from './EHRTextField';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  ehrTimePicker: {
    height: '100%',
  },
}));

interface EHRTimePickerProps extends EHRTextFieldProps {}

export default function EHRTimePicker(props: EHRTimePickerProps) {
  const classes = useStyles();
  const { dataCy, className, ...restProps } = props;

  return (
    <EHRTextField
      {...restProps}
      dataCy={dataCy}
      type="time"
      className={classNames(classes.ehrTimePicker, className)}
      inputProps={{
        step: 300, // 5 min
      }}
    />
  );
}
