import { createModel } from '@rematch/core';
import { RootModel } from './rootModel';

interface HealthCheckData {
  status: string;
  database: boolean;
  rubyVersion: string;
  railsVersion: string;
  backendVersion: string;
  frontendVersion: string;
  redis: boolean;
}

export const health = createModel<RootModel>()({
  state: {
    healthData: null as HealthCheckData | null,
    error: null as string | null,
  },
  reducers: {
    setHealthData(state, payload: HealthCheckData) {
      return { ...state, healthData: payload, error: null };
    },
    setError(state, payload: string) {
      return { ...state, error: payload, healthData: null };
    },
  },
  effects: (dispatch) => ({
    async fetchHealthData() {
      try {
        const apiUrl = process.env.REACT_APP_API_RAILS_URL;
        if (!apiUrl) {
          throw new Error('API URL is not defined');
        }

        const response = await fetch(`${apiUrl}/health`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        dispatch.health.setHealthData({
          status: data.status,
          database: data.database,
          rubyVersion: data.ruby_version,
          railsVersion: data.rails_version,
          backendVersion: data.version,
          frontendVersion: process.env.REACT_APP_GIT_SHA || 'unknown',
          redis: data.redis,
        });
      } catch (err) {
        dispatch.health.setError('Unable to fetch health data');
      }
    },
  }),
});
