import FemaleAcneIcon from '../assets/icons/skinConcerns/female/acne.svg';
import FemaleAcneActiveIcon from '../assets/icons/skinConcerns/female/acneActive.svg';
import FemaleDrynessIcon from '../assets/icons/skinConcerns/female/dryness.svg';
import FemaleDrynessActiveIcon from '../assets/icons/skinConcerns/female/drynessActive.svg';
import FemaleRednessIcon from '../assets/icons/skinConcerns/female/redness.svg';
import FemaleRednessActiveIcon from '../assets/icons/skinConcerns/female/rednessActive.svg';
import FemaleRoughnessIcon from '../assets/icons/skinConcerns/female/roughness.svg';
import FemaleRoughnessActiveIcon from '../assets/icons/skinConcerns/female/roughnessActive.svg';
import FemaleScarringIcon from '../assets/icons/skinConcerns/female/scarring.svg';
import FemaleScarringActiveIcon from '../assets/icons/skinConcerns/female/scarringActive.svg';
import FemaleSunspotsIcon from '../assets/icons/skinConcerns/female/sunspots.svg';
import FemaleSunspotsActiveIcon from '../assets/icons/skinConcerns/female/sunspotsActive.svg';
import FemaleUndereyeDarknessIcon from '../assets/icons/skinConcerns/female/undereyeDarkness.svg';
import FemaleUndereyeDarknessActiveIcon from '../assets/icons/skinConcerns/female/undereyeDarknessActive.svg';
import FemaleUndereyePuffinessIcon from '../assets/icons/skinConcerns/female/undereyePuffiness.svg';
import FemaleUndereyePuffinessActiveIcon from '../assets/icons/skinConcerns/female/undereyePuffinessActive.svg';
import FemaleUnevenComplexionIcon from '../assets/icons/skinConcerns/female/unevenComplexion.svg';
import FemaleUnevenComplexionActiveIcon from '../assets/icons/skinConcerns/female/unevenComplexionActive.svg';
import FemaleWrinklesIcon from '../assets/icons/skinConcerns/female/wrinkles.svg';
import FemaleWrinklesActiveIcon from '../assets/icons/skinConcerns/female/wrinklesActive.svg';
import MaleAcneIcon from '../assets/icons/skinConcerns/male/acne.svg';
import MaleAcneActiveIcon from '../assets/icons/skinConcerns/male/acneActive.svg';
import MaleDrynessIcon from '../assets/icons/skinConcerns/male/dryness.svg';
import MaleDrynessActiveIcon from '../assets/icons/skinConcerns/male/drynessActive.svg';
import MaleRednessIcon from '../assets/icons/skinConcerns/male/redness.svg';
import MaleRednessActiveIcon from '../assets/icons/skinConcerns/male/rednessActive.svg';
import MaleRoughnessIcon from '../assets/icons/skinConcerns/male/roughness.svg';
import MaleRoughnessActiveIcon from '../assets/icons/skinConcerns/male/roughnessActive.svg';
import MaleScarringIcon from '../assets/icons/skinConcerns/male/scarring.svg';
import MaleScarringActiveIcon from '../assets/icons/skinConcerns/male/scarringActive.svg';
import MaleSunspotsIcon from '../assets/icons/skinConcerns/male/sunspots.svg';
import MaleSunspotsActiveIcon from '../assets/icons/skinConcerns/male/sunspotsActive.svg';
import MaleUndereyeDarknessIcon from '../assets/icons/skinConcerns/male/undereyeDarkness.svg';
import MaleUndereyeDarknessActiveIcon from '../assets/icons/skinConcerns/male/undereyeDarknessActive.svg';
import MaleUndereyePuffinessIcon from '../assets/icons/skinConcerns/male/undereyePuffiness.svg';
import MaleUndereyePuffinessActiveIcon from '../assets/icons/skinConcerns/male/undereyePuffinessActive.svg';
import MaleUnevenComplexionIcon from '../assets/icons/skinConcerns/male/unevenComplexion.svg';
import MaleUnevenComplexionActiveIcon from '../assets/icons/skinConcerns/male/unevenComplexionActive.svg';
import MaleWrinklesIcon from '../assets/icons/skinConcerns/male/wrinkles.svg';
import MaleWrinklesActiveIcon from '../assets/icons/skinConcerns/male/wrinklesActive.svg';

export const FREQUENCIES_VALUES = ['Weekly', 'Every Other Day', 'Daily', 'Twice Daily'];

export const GENDER: any = {
  female: 'Female',
  male: 'Male',
};

export const SKIN_TYPE_LABELS: any = {
  very_light: 'I Very light',
  light: 'II Light',
  light_to_medium: 'III Light to medium',
  medium: 'IV Medium',
  medium_to_dark: 'V Medium to dark',
  dark: 'VI Dark',
};

export const FREQUENCY_LABELS: any = {
  daily: 'Daily',
  few_times_week: 'Few times a week',
  once_week: 'Once a week',
  rarely: 'Rarely',
  never: 'Never',
  'every morning': 'Every morning',
  'most days': 'Most days',
  'prior to outdoor activities': 'Prior to outdoor activities',
};

export const STATES = [
  'Alabama',
  'Alaska',
  'American Samoa',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District Of Columbia',
  'Federated States Of Micronesia',
  'Florida',
  'Georgia',
  'Guam',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Marshall Islands',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Northern Mariana Islands',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Palau',
  'Pennsylvania',
  'Puerto Rico',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virgin Islands',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const PHOTO_REQUEST_MESSAGE = 'Additional photos requested';

export const SKIN_TYPES = {
  skinType1: '#F4E6DE',
  skinType2: '#F0DFCE',
  skinType3: '#E2C9A5',
  skinType4: '#D0B082',
  skinType5: '#56220D',
  skinType6: '#381600',
};

interface SkinTypeModel {
  backgroundColor: string;
  borderColor: string;
  type: string;
  title: string;
  description: string;
}

export interface SkinTypeKind {
  [key: string]: SkinTypeModel;
}

export enum SkinType {
  VeryLight = 'very_light',
  Light = 'light',
  LightToMedium = 'light_to_medium',
  Medium = 'medium',
  MediumToDark = 'medium_to_dark',
  Dark = 'dark',
}

export const SKIN_TYPE_KIND: SkinTypeKind = {
  [SkinType.VeryLight]: {
    backgroundColor: SKIN_TYPES.skinType1,
    borderColor: 'rgba(244, 230, 222, 0.2)',
    type: 'I',
    title: 'I Very Light',
    description: 'Always burns, never tans',
  },
  [SkinType.Light]: {
    backgroundColor: SKIN_TYPES.skinType2,
    borderColor: 'rgba(240, 223, 206, 0.2)',
    type: 'II',
    title: 'II Light',
    description: 'Usually burns, tans minimally',
  },
  [SkinType.LightToMedium]: {
    backgroundColor: SKIN_TYPES.skinType3,
    borderColor: 'rgba(226, 201, 165, 0.2)',
    type: 'III',
    title: 'III Light to medium',
    description: 'Sometimes burns mildly, tans uniformly',
  },
  [SkinType.Medium]: {
    backgroundColor: SKIN_TYPES.skinType4,
    borderColor: 'rgba(208, 176, 130, 0.2)',
    type: 'IV',
    title: 'IV Medium',
    description: 'Burns minimally, always tans well',
  },
  [SkinType.MediumToDark]: {
    backgroundColor: SKIN_TYPES.skinType5,
    borderColor: 'rgba(86, 34, 13, 0.2)',
    type: 'V',
    title: 'V Medium to dark',
    description: 'Very rarely burns, tans very easily',
  },
  [SkinType.Dark]: {
    backgroundColor: SKIN_TYPES.skinType6,
    borderColor: 'rgba(56, 22, 0, 0.2)',
    type: 'VI',
    title: 'VI Dark',
    description: 'Never burns, always tans',
  },
};

export interface SkinConcernIcon {
  normal: string;
  active: string;
}

export interface SkinConcern {
  label: string;
  male: SkinConcernIcon;
  female: SkinConcernIcon;
}

export interface SkinConcernKinds {
  [key: string]: SkinConcern;
}

export const SKIN_CONCERN_KINDS: SkinConcernKinds = {
  acne: {
    label: 'Acne',
    male: { normal: MaleAcneIcon, active: MaleAcneActiveIcon },
    female: { normal: FemaleAcneIcon, active: FemaleAcneActiveIcon },
  },
  scarring: {
    label: 'Scarring',
    male: { normal: MaleScarringIcon, active: MaleScarringActiveIcon },
    female: { normal: FemaleScarringIcon, active: FemaleScarringActiveIcon },
  },
  'sun damage/sunspots': {
    label: 'Sun Damage, Sunspots',
    male: { normal: MaleSunspotsIcon, active: MaleSunspotsActiveIcon },
    female: { normal: FemaleSunspotsIcon, active: FemaleSunspotsActiveIcon },
  },
  'fine lines/wrinkles': {
    label: 'Fine Lines, Wrinkles',
    male: { normal: MaleWrinklesIcon, active: MaleWrinklesActiveIcon },
    female: { normal: FemaleWrinklesIcon, active: FemaleWrinklesActiveIcon },
  },
  'eye puffiness': {
    label: 'Eye Puffiness',
    male: { normal: MaleUndereyePuffinessIcon, active: MaleUndereyePuffinessActiveIcon },
    female: {
      normal: FemaleUndereyePuffinessIcon,
      active: FemaleUndereyePuffinessActiveIcon,
    },
  },
  'rough skin': {
    label: 'Rough Skin',
    male: { normal: MaleRoughnessIcon, active: MaleRoughnessActiveIcon },
    female: { normal: FemaleRoughnessIcon, active: FemaleRoughnessActiveIcon },
  },
  dryness: {
    label: 'Dryness',
    male: { normal: MaleDrynessIcon, active: MaleDrynessActiveIcon },
    female: { normal: FemaleDrynessIcon, active: FemaleDrynessActiveIcon },
  },
  redness: {
    label: 'Redness',
    male: { normal: MaleRednessIcon, active: MaleRednessActiveIcon },
    female: { normal: FemaleRednessIcon, active: FemaleRednessActiveIcon },
  },
  'uneven complexion': {
    label: 'Uneven Complexion',
    male: { normal: MaleUnevenComplexionIcon, active: MaleUnevenComplexionActiveIcon },
    female: { normal: FemaleUnevenComplexionIcon, active: FemaleUnevenComplexionActiveIcon },
  },
  'undereye darkening': {
    label: 'Undereye Darkening',
    male: { normal: MaleUndereyeDarknessIcon, active: MaleUndereyeDarknessActiveIcon },
    female: { normal: FemaleUndereyeDarknessIcon, active: FemaleUndereyeDarknessActiveIcon },
  },
};

export const SUNSCREEN_OPTIONS = [
  { value: 'daily', label: 'Daily' },
  { value: 'every morning', label: 'Every morning' },
  { value: 'most days', label: 'Most days' },
  { value: 'few_times_week', label: 'Few times a week' },
  { value: 'prior to outdoor activities', label: 'Prior to outdoor activities' },
  { value: 'once_week', label: 'Once a week' },
  { value: 'rarely', label: 'Rarely' },
  { value: 'never', label: 'Never' },
];

export const UNITS: any = {
  mL: 'MLs',
  oz: 'Oz',
  pad: 'Pad',
  region: 'Regions',
  threads: 'Threads',
  tube: 'Tube',
  unit: 'Units',
  vial: 'Vials',
  mg: 'Milligrams',
};

export const REQUEST_UPDATED_GFE_TITLE = 'Request updated GFE confirmation';

export const STATES_REQUIRING_LIVE_GFE = [
  'Arizona',
  'Delaware',
  'District Of Columbia',
  'Idaho',
  'Indiana',
  'Kansas',
  'Kentucky',
  'Massachusetts',
  'Maryland',
  'Minnesota',
  'Missouri',
  'Mississippi',
  'New Hampshire',
  'New Mexico',
  'New York',
  'Oregon',
  'South Dakota',
  'Tennessee',
  'Utah',
  'Washington',
  'West Virginia',
];
