/* eslint-disable */

export interface IToastMessage {
  [key: string]: string; // <-- "id" | "filter1" | "filter2"
}
const ToastMessage: IToastMessage = {
  'account_charge.reached_asset_limit': "You've already reached the units chosen in step 3 for <%= serviceName %>",
  'admin_dashboard.item_not_in_warehouse': "This item is not in <%= name %>'s warehouse.",
  'admin_dashboard.select_from_warehouse': 'Select the from warehouse.',
  'checkout.discounts_removed_care_credit':
    'Discounts are not applicable when using CareCredit. All applied discounts have been removed.',
  'checkout.discounts_removed_future_credit':
    'Discounts are not applicable when future credit is used for payment. The applied discounts have been removed. ',
  'checkout.discounts_removed_other_tenders':
    'Other tenders have been removed because they exceed the amount remaining to pay.',
  'checkout.galderma_points_redeemed': 'ASPIRE Points redeemed successfully!',
  'dashboard_practitioner.asset_already_scanned':
    'WARNING: This asset has already been scanned. This action will override the previous scan.',
  'dashboard_practitioner.asset_doesnt_belong_remainig_asset':
    'This asset is not in the remaining assets and is not expected to be scanned.',
  'dashboard_practitioner.pricing.new_value_error': 'The new value must be greater than default price.',
  'dashboard_practitioner.pricing.negative_value_error': 'The new value cannot be less than 0.',
  'galderma.generic_error': 'ASPIRE: <%= errors %>',
  'galderma.validation_error': 'ASPIRE validation error.',
  'galderma.reset_services': 'ASPIRE services selected restarted',
  'generic.added': 'Added successfully!',
  'generic.copy_to_clipboard': 'Copied to clipboard!',
  'generic.element_not_exists': '<%= element %> does not exist.',
  'generic.element_not_found': '<%= element %> was not found.',
  'generic.error_message': 'An error was encountered - <%= action %> <%= element %>.',
  'generic.error_message_without_params': 'An error was encountered.',
  'generic.invalid_field': '<%= element %> field is invalid.',
  'generic.error_unexpected': 'There was an unexpected error, please reload the page.',
  'generic.limit_exceeded': 'Limit exceeded!',
  'generic.product_added': 'Product added!',
  'generic.product_removed': 'Product removed!',
  'generic.product_rescanned': 'Product replaced!',
  'generic.product_expired': 'The product is expired.',
  'generic.server_error': 'A server error was encountered.',
  'generic.no_permission_to_access': 'You do not have permission to access this page.',
  'generic.sms_code_sended': 'SMS verification code sent.',
  'generic.success_message': '<%= element %> <%= action %> successfully.',
  'appointment.completed_message':
    'Congratulations! You have successfully completed your setup. Your booking link for <%= medspaName %> is now ready: <%= bookingLink %>. You can find it anytime in your "Booking Link" button.',
  'generic.form_updated': 'Form updated.',
  'identity.password_set': 'The password was set successfully.',
  'locations.no_new_locations_imported': 'No new locations were imported.',
  'locations.new_locations_imported': '<%= count %> new locations were imported.',
  'locations.some_locations_could_not_be_imported': '<%= count %> locations could not be imported.',
  'new_service_visit.does_not_belong_to_practitioner': 'This service visit belongs to another provider',
  'new_service_visit.patient_need_to_be_cleared': 'This patient does not have a current GFE',
  'new_service_visit.product_not_same_service': 'The scanned product does not belong to the same service.',
  'new_service_visit.rescan_same_serial_number': 'The scanned product is the same as before.',
  'new_service_visit.asset_not_in_service_selected':
    'Asset is not in the services selected in step 1 and is not expected to be scanned.',
  'new_service_visit.product_already_added': 'This product has already been added.',
  'new_service_visit.remove_asssets_by_units':
    'Scanned assets for the following services: <%= serviceName %>, have been removed.',
  'new_service_visit.remove_discount':
    'All previous discounts applied to <%= serviceName %> in the checkout have been removed due to a change in the services provided.',
  'new_service_visit.remove_future_credits': 'Future credits applied to <%= serviceName %> have been removed.',
  'new_service_visit.different_provider': 'The patient is assigned to a different provider',
  'new_service_visit.no_units_selected': 'The service has no units selected',
  'new_service_visit.asset_for_future_use':
    'Not available in this service visit, the service you have selected is marked as a future use service',
  'new_service_visit.no_assets_to_scan': 'No assets pending to be scanned',
  'new_service_visit.reached_asset_limit': "You've already reached the units chosen in step 2 for <%= serviceName %>",
  'new_service_visit.account_credit_exceed': `You can't apply more than the Total Credit Available.`,
  'new_service_visit.to_pay_exceed': `You can't apply more than the Remaining to pay.`,
  'new_supply_receipt.invalid_form_create': 'Invalid form.',
  'notifications.no_notifications': 'There are no notifications at the moment.',
  'patient.claimed_by_another_physician': 'Patient being evaluated by <%= claimedBy %>',
  'patient_profile.aspire_user_not_found':
    "We cannot locate the user's account in Aspire please make sure this user has an existing account",
  'patient_profile.create_account.fields_error':
    'Please complete your profile to create an ASPIRE Account (<%= fields %>).',
  'patient_profile.skin_profile_form.skin_conditions': 'You may only choose five skin concerns.',
  'patient_profile.dont_have_permission_to_view_the_patient':
    'You do not have permission to view this patient because it is assigned to another practitioner',
  'product_identifier.marked_empty': 'Asset has been marked as empty.',
  'product_identifier.undo_marked_empty': 'Asset has been recovered.',
  'product_identifier.active_product': 'The asset is an active product.',
  'practitioner.creating': 'Please wait a moment while we configure the Provider.',
  'practitioner.creatingFlex': 'Please wait a moment while we configure your account.',
  'practitioner.created': 'Practitioner added successfully with Square location id: <%= squareId %>',
  'practitioner_email.error': 'Practitioner email already exists',
  'patient.created': 'Patient Created',
  'provider.created': 'Provider Created',
  'provider.updated': 'Provider Updated',
  'medspa_admin.created': 'MedSpa Admin Created',
  'medspa_admin.updated': 'MedSpa Admin Updated',
  'purchase_order.item_delete': 'Purchase order item successfully deleted.',
  'purchase_order.invalid_item': 'Items in purchase order need a unit cost if it is not a sample.',
  'purchase_order.not_service_selected': 'No service selected.',
  'purchase_order.not_purchase_order_type_selected': 'No purchase order type selected.',
  'supply_receipts.saved': 'Supply receipt saved.',
  'supply_receipts.warehouse_error': 'Error: The FROM warehouse can not be the same as the TO warehouse.',
  'services.service_acknowledgment_accepted_error': 'There was an error selecting the service, please try again.',
  create_thread_error: 'There is an open conversation. You must close it before open a new one.',
  'generic.no_connection_message': `Couldn't connect to the internet. Check your connection and try again.`,
  'generic.back_online_message': `Your internet connection has been restored.`,
  'appointment_booking.success': `Appointment has been scheduled successfully.`,
  'appointment_booking.error': `We were not able to book this appointment, please try again later.`,
  'appointment_cancelling.success': `Appointment has been cancelled successfully.`,
  'appointment_cancelling.error': `We are not able to cancel this appointment at the moment, please try again later.`,
  'gfe_request.error': `We could not perform a GFE Request at the moment, please try again later.`,
  'gfe_request.success': `GFE Request has been sent!`,
  'form.populated_from_template': '<%= element %> populated from template',
  'csv.file.error.generic': 'Unable to read file',
  'csv.file.error.format': 'File is not in CSV format',
  'csv.file.error.size': 'CSV must contain a header row and at least 1 entry row',
  'csv.file.error.columns': 'CSV is missing required column(s): <%= columns %>',
};

export default ToastMessage;
