import { Box, Button, CircularProgress, IconButton, InputAdornment, Typography, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DATEPICKER_FORMAT, INPUT_DATE_FORMAT } from 'src/constants/inventory.constants';
import moment from 'moment';
import { IDeliveryBlackoutDates, PickersDate } from 'src/interfaces/IInventoryOrder';
import { useBlackoutDates, useCreateBlackoutDate, useDestroyBlackoutDate } from 'src/hooks/queries/useInventoryOrders';
import { Close as CloseIcon, Today as TodayIcon } from '@material-ui/icons';
import { ClassNameMap } from 'src/types/Dom';

type Props = {
  closeDialog?: () => void;
};

const BlackoutDate: React.FC<IDeliveryBlackoutDates & ClassNameMap> = ({ id, blackoutDate, classes }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { mutateAsync: destroyBlackoutDate } = useDestroyBlackoutDate(id);

  const handleClick = async () => {
    setIsLoading(true);
    try {
      await destroyBlackoutDate();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box data-loading={isLoading} className={classes?.blackoutDate}>
      <Typography>{moment.utc(blackoutDate).format(DATEPICKER_FORMAT)}</Typography>
      <IconButton disabled={isLoading} className={classes?.destroyDate} onClick={handleClick}>
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

const BlackoutDates: React.FC<ClassNameMap & Props> = ({ classes, closeDialog }) => {
  const [blackoutDate, setBlackoutDate] = useState<string | null>(null);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const { data: blackoutDates, isLoading: isLoadingBlackoutDates } = useBlackoutDates();
  const { mutateAsync: createBlackoutDate } = useCreateBlackoutDate();

  const disableCalendarDays = (date: PickersDate): boolean => {
    if (!date) {
      return true;
    }

    const dateStrings =
      blackoutDates?.map((dateObject: IDeliveryBlackoutDates) =>
        moment.utc(dateObject.blackoutDate).format(INPUT_DATE_FORMAT)
      ) ?? [];
    const isAlreadyBlocked = dateStrings.indexOf(date.format(INPUT_DATE_FORMAT)) > -1;
    const isWeekendOrMonday = [0, 1, 6].indexOf(date.weekday()) > -1;

    return isWeekendOrMonday || isAlreadyBlocked;
  };

  const handleChangeDate = (date: PickersDate) => {
    !!date && setBlackoutDate(date.format(INPUT_DATE_FORMAT));
  };

  const saveDate = async () => {
    if (blackoutDate) {
      setIsUpdating(true);
      try {
        await createBlackoutDate(blackoutDate);
      } finally {
        setBlackoutDate(null);
        setIsUpdating(false);
      }
    }
  };

  return (
    <>
      <Typography className={classes?.title} variant="h5">
        Holiday Dates
      </Typography>
      <Box className={classes?.datePickerContainer}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            placeholder="Select a date"
            className={classes?.datePicker}
            value={blackoutDate ? moment(blackoutDate) : null}
            format={DATEPICKER_FORMAT}
            autoOk
            disablePast
            disableToolbar
            shouldDisableDate={disableCalendarDays}
            onChange={handleChangeDate}
            disabled={isLoadingBlackoutDates}
            data-testid="blackoutDates.datepicker"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <TodayIcon />
                </InputAdornment>
              ),
            }}
          />
        </MuiPickersUtilsProvider>
      </Box>
      <Box className={classes?.blackoutDatesContainer}>
        <>
          {isLoadingBlackoutDates ? (
            <CircularProgress className={classes?.progress} size={22} />
          ) : (
            blackoutDates?.map(
              (date: IDeliveryBlackoutDates): React.ReactElement => (
                <BlackoutDate {...date} classes={classes} key={date.id} />
              )
            )
          )}
        </>
      </Box>
      <Box marginTop="15px">
        <Button disabled={isUpdating} onClick={closeDialog}>
          back
        </Button>
        <Button disabled={!blackoutDate || isUpdating} onClick={saveDate}>
          {isUpdating ? <CircularProgress size={15} /> : 'add'}
        </Button>
      </Box>
    </>
  );
};

export default withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 'unset',
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: 90,
    borderBottom: '1px solid #eee',
  },
  '@keyframes pulse': {
    '0%': {
      opacity: 1,
    },
    '30%': {
      opacity: 0.26,
    },
    '100%': {
      opacity: 1,
    },
  },
  title: {
    textAligh: 'center',
  },
  datePicker: {
    margin: 'auto 25px auto 0',
    width: 150,
  },
  datePickerContainer: {
    margin: 0,
    width: 420,
    display: 'flex',
    flexDirection: 'row',
    '& > p': {
      margin: 'auto 30px auto 0',
      width: 105,
    },
  },
  blackoutDatesContainer: {
    display: 'flex',
    height: 130,
    maxHeight: 130,
    overflowY: 'scroll',
    width: 500,
    maxWidth: 500,
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: 0,
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  blackoutDate: {
    backgroundColor: 'rgb(213,194,231)',
    color: '#000',
    borderRadius: 8,
    width: 150,
    maxWidth: 150,
    maxHeight: 33,
    padding: '4px 12px',
    margin: '10px 10px 0 0',
    display: 'flex',
    '&:last-child': {
      marginRight: 'auto',
    },
    '& > p': {
      margin: 'auto',
      fontSize: 16,
      paddingRight: 10,
    },
    '& > button': {
      width: 15,
      height: 15,
    },
    '& svg': {
      fontSize: 20,
    },
    '&[data-loading="true"]': {
      animation: '$pulse 2s ease-in-out infinite',
    },
  },
  paper: {
    width: 300,
    padding: 25,
    '& > p': {
      margin: 'auto auto 20px auto',
      fontSize: 18,
    },
    '& > div': {
      display: 'flex',
    },
    '& button': {
      margin: 'auto',
    },
  },
  destroyDate: {
    backgroundColor: 'rgb(170,155,185)',
  },
  progress: {
    color: '#bbb',
    margin: 'auto',
    marginLeft: 15,
  },
})(BlackoutDates);
