import React, { useCallback as _useCallback } from 'react';
import { Box, Button, FormHelperText } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import classNames from 'classnames';

import DragAndDropComponent from 'src/components/DragAndDrop';
import { EHRTextField, EHRTypography } from 'src/components/ui/v1';
import CredentialsDropdown from 'src/components/common/CredentialsDropdown';
import { useStyles } from './MedspaModal.styles';

type Props = {
  methods: any;
  signaturePreview: string | null;
  setSignaturePreview: React.Dispatch<React.SetStateAction<string | null>>;
  setSignatureImageFile: React.Dispatch<React.SetStateAction<File | undefined>>;
};

export const OperatingProviderForm = ({
  methods,
  setSignaturePreview,
  setSignatureImageFile,
  signaturePreview,
}: Props) => {
  const classes = useStyles();

  const { control, errors, setValue } = methods;

  const handleSignatureChange = (fileData: File[]) => {
    const file = fileData?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSignaturePreview((reader.result || '').toString());
        // @ts-ignore
        setValue('signatureImage', file, { shouldValidate: true });
        setSignatureImageFile(file);
      };
      reader.readAsDataURL(file);
    } else {
      setValue('signatureImage', null, { shouldValidate: true });
    }
  };

  return (
    <Box>
      <Box display="flex" flexDirection="row" gridGap="1rem">
        <Box flex={1}>
          <Controller
            control={control}
            name="licenses"
            render={({ onChange, value }) => (
              <CredentialsDropdown value={value} onChange={onChange} hasErrors={!!errors?.licenses} />
            )}
          />
        </Box>

        <Box flex={1}>
          <Controller
            control={control}
            name="position"
            render={({ onChange, value }) => (
              <EHRTextField
                dataCy="input-position"
                data-testid="input-position"
                name="position"
                onChange={onChange}
                value={value}
                label="Position"
                error={!!errors?.position}
                helperText={errors?.position?.message || ' '}
              />
            )}
          />
        </Box>
      </Box>

      <Box
        className={classNames(
          classes.signatureImageContainer,
          errors?.signatureImage ? 'ehrUploadContainerError' : null
        )}
      >
        <Box className={classes.signatureImageHeader}>
          <EHRTypography
            dataCy="label-upload-signature"
            variant="label"
            error={!!errors?.signatureImage}
            style={{ marginBottom: '0.5rem' }}
          >
            Upload Signature
          </EHRTypography>

          {signaturePreview && (
            <Button
              type="button"
              onClick={() => {
                setSignaturePreview(null);
                setValue('signatureImage', null, { shouldValidate: true });
              }}
              className={classes.signatureUpdate}
            >
              Update
            </Button>
          )}
        </Box>

        {signaturePreview ? (
          <img src={signaturePreview} alt="Signature preview" className={classes.signatureImage} />
        ) : (
          <DragAndDropComponent
            hideDocumentIcon
            height="80px"
            hideRemoveFile
            acceptedFileType="image/png,image/jpg,image/jpeg"
            onFileChange={handleSignatureChange}
            error={!!errors?.signatureImage}
          />
        )}
        <FormHelperText error>{errors?.signatureImage?.message || ' '}</FormHelperText>
      </Box>
    </Box>
  );
};
