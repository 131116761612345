import React from 'react';
import { MedspaCalendarGFEStatusEnum } from 'src/constants/general.constants';
import { Error as ErrorIcon, CheckCircle as CheckCircleIcon, Help as HelpIcon } from '@material-ui/icons';
import { snakeToCamelCase } from 'src/utils/global';

const {
  TO_CLEAR,
  TO_RECLEAR,
  INCOMPLETE,
  CLEARED,
  LEAD,
  PENDING_RECLEAR,
  REJECTED,
  PROFILE_COMPLETED,
  NO_SHOW,
  TO_LIVE_CLEAR,
} = MedspaCalendarGFEStatusEnum;

export const gfeStatusColors = {
  [CLEARED]: {
    iconBGColor: '#000000',
  },
  [TO_RECLEAR]: {
    iconBGColor: '#FF971C',
    appointmentBGColor: '#FFECD6',
  },
  [TO_CLEAR]: {
    iconBGColor: '#FF1C1C',
    appointmentBGColor: '#FFDBDB',
  },
  [NO_SHOW]: {
    iconBGColor: '#FF1C1C',
    appointmentBGColor: '#FFDBDB',
  },
  [INCOMPLETE]: {
    iconBGColor: '#000000',
    appointmentBGColor: '',
  },
};

export const getGFEStatusIconComponent = (rawGfeStatus: MedspaCalendarGFEStatusEnum) => {
  let IconComponent = HelpIcon;

  const gfeStatus = snakeToCamelCase<MedspaCalendarGFEStatusEnum>(rawGfeStatus);

  if (gfeStatus === CLEARED) {
    IconComponent = CheckCircleIcon;
  } else if ([TO_CLEAR, TO_RECLEAR, INCOMPLETE, NO_SHOW].includes(gfeStatus)) {
    IconComponent = ErrorIcon;
  }

  return () => <IconComponent style={{ fill: gfeStatusColors[gfeStatus]?.iconBGColor || '#c3c3c3' }} />;
};

export const getGFEStatusTooltipText = (gfeStatus: MedspaCalendarGFEStatusEnum | null) => {
  let tooltipText = '';

  switch (snakeToCamelCase(gfeStatus || '')) {
    case TO_CLEAR: {
      tooltipText = 'To clear';
      break;
    }
    case TO_RECLEAR: {
      tooltipText = 'To reclear';
      break;
    }
    case INCOMPLETE: {
      tooltipText = 'Incomplete';
      break;
    }
    case CLEARED: {
      tooltipText = 'Cleared';
      break;
    }
    case LEAD: {
      tooltipText = 'Lead';
      break;
    }
    case PENDING_RECLEAR: {
      tooltipText = 'Pending reclear';
      break;
    }
    case REJECTED: {
      tooltipText = 'Rejected';
      break;
    }
    case PROFILE_COMPLETED: {
      tooltipText = 'Profile completed';
      break;
    }
    case TO_LIVE_CLEAR: {
      tooltipText = 'To live clear';
      break;
    }
    case NO_SHOW: {
      tooltipText = 'No show';
      break;
    }
    default: {
      tooltipText = String(gfeStatus);
      break;
    }
  }

  return tooltipText;
};
