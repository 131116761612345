/* eslint-disable max-len */
// eslint-disable-next-line import/no-unresolved
import kebabcase from 'kebabcase-keys';
import { QueryFunctionContext } from 'react-query';
import compile from '../utils/toastMessagesCompiler';
import { dispatch } from '../rematch';
import {
  IGfeRequest,
  IMedicalProfile,
  IPatient,
  IPatientBasicInfo,
  IPatientContactInfo,
  IActivityLogRes,
  INextAppointment,
  IClaimPatientParams,
  ICustomerMembershipConsent,
  IMembershipConsent,
  ICreateCustomerMembershipConsent,
} from '../types/Patient';
import { IMHANote, IMHANotePost } from '../interfaces/IMedicalHANote';
import Api from './Api';
import { CustomerParams } from '../interfaces/CustomerParams';
import { Dashboard } from '../utils/types';
import ICustomer, { ICustomersResponse, ICustomerCount, ICustomerPost } from '../interfaces/ICustomer';
import {
  INITIAL_PRACTITIONER_FILTER,
  ADMIN_DASHBOARD_KEY,
  PHYSICIAN_DASHBOARD_KEY,
  STARRED_STATE,
  ALL_PATIENTS_STATE,
} from '../constants/general.constants';
import IServiceVisit from '../interfaces/IServiceVisits';
import API_ROUTES from '../constants/apiRoutes.constants';
import { IReferralCredits } from '../types/ReferralCredits';

const BASE_PATH = '/v3/medical_history_acknowledgements';
const PATIENT_PROFILE_PATH = '/patient-profile';
const PATIENT_BASE_PATH = '/v3/customers';

const ERROR_GETTING_MHA = compile('generic.error_message', {
  action: 'getting',
  element: 'medical history acknowledgement notes',
  duration: 1000,
});

const shouldAddPractitionerFilter = ({
  practitionerId,
  role,
}: {
  practitionerId: string;
  role: string | undefined;
}) => {
  const isPractitionerFilterDefined = practitionerId !== INITIAL_PRACTITIONER_FILTER;

  const isQueryingForAdmins = role === ADMIN_DASHBOARD_KEY;

  return isPractitionerFilterDefined && !isQueryingForAdmins;
};

const Patients = {
  async createGfeRequest(customerId: string, requestType: number): Promise<any> {
    const response = await Api.post(`/v3/customers/${customerId}/gfe_request`, {
      request_type: requestType,
    });
    return response;
  },
  async getLastPendingGfeRequest(customerId: string): Promise<IGfeRequest | null> {
    const { gfeRequest } = await Api.get(`/v3/customers/${customerId}/last_pending_gfe_request`);
    return gfeRequest;
  },
  async getContactInfo(patientId: string | number): Promise<IPatientContactInfo> {
    const { contactInfo } = await Api.get(`patient-profile/${patientId}/contact-info`);
    return contactInfo;
  },
  async getMedicalProfile(patientId: string): Promise<IMedicalProfile> {
    const { medicalProfile } = await Api.get(`v3/medical_profile/${patientId}`);
    return medicalProfile;
  },
  async getActivityLogs(patientId: string, pageParam: QueryFunctionContext, limit: number): Promise<IActivityLogRes[]> {
    const { data } = await Api.get(`/customers/${patientId}/activity_logs?limit=${limit}&page=${pageParam}`);
    return data;
  },
  async getBasicInfo(patientId: string): Promise<IPatientBasicInfo> {
    const { basic } = await Api.get(`patient-profile/${patientId}/basic`);
    return basic;
  },
  async getCurrentBalance(patientId: string): Promise<number> {
    const { currentBalance } = await Api.get(`patient-profile/${patientId}/current_balance`);
    return currentBalance;
  },
  async getServiceVisits(patientId: string): Promise<IServiceVisit[]> {
    const { serviceVisits } = await Api.get(`/service-visits/${patientId}/full-service-history`);
    return serviceVisits;
  },
  async savePatient(patientId: string, body: any): Promise<IPatient> {
    return Api.post(`/customers/${patientId}/update`, {
      ...body,
      medicalProfile: { dateOfBirth: body.dateOfBirth },
    });
  },
  async getPatientInfo(patientId: string): Promise<{
    basicInfo: IPatientBasicInfo;
    contactInfo: IPatientContactInfo;
  }> {
    const [basicInfo, contactInfo] = await Promise.all([
      Patients.getBasicInfo(patientId),
      Patients.getContactInfo(patientId),
    ]);

    const errors = [];
    if (basicInfo === undefined) {
      errors.push('the patient basic info');
    }
    if (contactInfo === undefined) {
      errors.push('the patient contact info');
    }
    if (errors.length > 0) {
      throw Error(
        compile('generic.error_message', {
          action: 'fetching',
          element: errors.join(' & '),
        })
      );
    }

    return { basicInfo, contactInfo };
  },
  async updateMedicalProfile(data: {
    customerId: number;
    medicalProfile: IMedicalProfile;
  }): Promise<{ medicalProfile: IMedicalProfile }> {
    const { success, medicalProfile } = await Api.put('v3/medical_profile', kebabcase(data, { deep: true }));

    if (success) {
      dispatch({
        type: 'patient/updateMedicalProfile',
        payload: {
          medicalProfile,
        },
      });
    } else {
      throw new Error('Error updating medical profile');
    }

    return { medicalProfile };
  },
  async incomingMedicalHistoryAcknowledgement(patientId: string): Promise<IMHANote> {
    const response = await Api.get(`${BASE_PATH}/incoming/${patientId}`);

    return response.medicalHistoryAcknowledgement;
  },
  async medicalHistoryAcknowledgement(patientId: string): Promise<IMHANote[]> {
    const { medicalHistoryAcknowledgements, error } = await Api.get(`${BASE_PATH}?customer_id=${patientId}`);
    if (error) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: ERROR_GETTING_MHA,
          type: 'error',
        },
      });
    }
    return medicalHistoryAcknowledgements;
  },
  async markMedicalHistoryAcknowledgementAsViewed(
    medicalHistoryAckId: number,
    serviceVisitId: number
  ): Promise<{ success: boolean }> {
    const response = await Api.put(
      `${BASE_PATH}/${medicalHistoryAckId}/mark_as_viewed`,
      kebabcase({ serviceVisitId }, { deep: true })
    );
    if (!response.success) {
      throw Error(
        compile('generic.error_message', {
          action: 'fetching',
          element: 'Medical history acknowledgements note',
        })
      );
    }
    return response.success;
  },
  async createMHANote({ customerId, physicianId, text }: IMHANotePost): Promise<IMHANotePost> {
    const { results, error } = await Api.post(`${BASE_PATH}`, {
      customerId,
      physicianId,
      text,
    });
    if (error) {
      throw error;
    }
    return results;
  },
  async removeMHANote({ id }: any): Promise<number | undefined> {
    if (typeof id === 'undefined' && id === '') {
      throw new Error('Something went wrong');
    }
    const { data, error } = await Api.delete(`${BASE_PATH}/${id}`);
    if (error) {
      throw error;
    }
    return data;
  },
  async lastVisitByDoctor(patientId: number): Promise<any> {
    const { data, error } = await Api.post(`${PATIENT_PROFILE_PATH}/${patientId}/view`, {});
    if (error) {
      throw error;
    }
    return data;
  },
  async getNextAppointment(patientId: number): Promise<INextAppointment> {
    const offset = new Date().getTimezoneOffset() / -60;
    const { nextAppointment, error } = await Api.get(`/v3/appointments/${patientId}/next_appointment`, {
      offset,
    });
    if (error) {
      throw error;
    }
    return nextAppointment;
  },
  async delete(patientId: number): Promise<any> {
    return Api.delete(`/v3/customers/${patientId}`);
  },
  async getAllPatients(
    role: Dashboard,
    pageParam: number,
    {
      group,
      view,
      filter,
      version,
      search = '',
      sortDirection,
      sortBy,
      exclude,
      practitionerId,
      license,
      state,
      appointmentDate,
      appointmentDateOperator,
      summaryStatus,
      roleId,
      requestType,
      excludedStatus,
      marked,
      includeRequiredLiveGfe,
      scheduledClearingAppointments,
    }: CustomerParams | any
  ): Promise<ICustomersResponse> {
    const basePath = API_ROUTES.DASHBOARD_CUSTOMERS_BY_ROLE[role.toLowerCase()];

    const queryParams: any = {
      search,
      sortDirection,
    };

    if (license) {
      queryParams.license = license;
    }

    if (pageParam) {
      queryParams.page = pageParam;
    }

    if (view) {
      queryParams.list = view;
    }

    if (filter) {
      queryParams.filter = group === STARRED_STATE ? STARRED_STATE : filter;
    }

    if (group) {
      queryParams.group = group === STARRED_STATE ? ALL_PATIENTS_STATE : group;
    }

    if (version) {
      queryParams.version = version;
    }

    if (sortBy) {
      queryParams.sort = sortBy;
    }

    if (exclude) {
      queryParams.exclude = exclude;
    }

    if (role === PHYSICIAN_DASHBOARD_KEY) {
      queryParams.from_new_ehr = true;
    }

    if (state) {
      queryParams.state = state;
    }

    if (summaryStatus) {
      queryParams.summary_status = summaryStatus;
    }

    if (roleId) {
      queryParams.role_id = roleId;
    }

    if (excludedStatus) {
      queryParams.excluded_status = excludedStatus;
    }

    if (appointmentDate && appointmentDateOperator) {
      queryParams.appointment_date = appointmentDate;
      queryParams.appointment_date_operator = appointmentDateOperator;
    }

    if (shouldAddPractitionerFilter({ practitionerId, role })) {
      queryParams.practitioner_id = practitionerId;
    }

    if (requestType) {
      queryParams.requested_gfe_type = requestType;
    }

    if (marked) {
      queryParams.marked = marked;
    }

    if (includeRequiredLiveGfe === true || includeRequiredLiveGfe === false) {
      queryParams.include_required_live_gfe = includeRequiredLiveGfe;
    }

    if (scheduledClearingAppointments) {
      queryParams.scheduled_clearing_appointments = scheduledClearingAppointments;
    }

    return Api.get(basePath, queryParams);
  },

  async getPatientsCounts(role: Dashboard | undefined, query: any): Promise<ICustomerCount> {
    const {
      search,
      practitionerId,
      state,
      license,
      sortDirection,
      group,
      sortBy,
      exclude,
      roleId,
      excludedStatus,
      appointmentDate,
      appointmentDateOperator,
      scheduledClearingAppointments,
    } = query;

    const queryParams: any = {
      search,
      sortDirection,
    };

    if (license) {
      queryParams.license = license;
    }

    if (group) {
      queryParams.group = group === STARRED_STATE ? ALL_PATIENTS_STATE : group;
    }

    if (sortBy) {
      queryParams.sort = sortBy;
    }

    if (exclude) {
      queryParams.exclude = exclude;
    }

    if (roleId) {
      queryParams.roleId = roleId;
    }

    if (excludedStatus) {
      queryParams.excludedStatus = excludedStatus;
    }

    if (appointmentDate && appointmentDateOperator) {
      queryParams.appointment_date = appointmentDate;
      queryParams.appointment_date_operator = appointmentDateOperator;
    }

    if (shouldAddPractitionerFilter({ practitionerId, role })) {
      queryParams.practitionerId = practitionerId;
    }

    if (state) {
      queryParams.state = state;
    }

    if (scheduledClearingAppointments) {
      queryParams.scheduled_clearing_appointments = scheduledClearingAppointments;
    }

    return Api.get(`${PATIENT_BASE_PATH}/count_for_${role}`, queryParams);
  },
  async createPatient(data: ICustomerPost): Promise<{ customer: number }> {
    return Api.post(`${PATIENT_BASE_PATH}`, data);
  },
  async createMedspaPatient(userGroupId: string, data: FormData): Promise<any> {
    return Api.post(`v4/medspa/${userGroupId}/customers`, data);
  },
  async createMedspaProviderPatient(providerId: string | number, data: FormData): Promise<any> {
    return Api.post(`v4/practitioners/${providerId}/customers`, data);
  },
  async getBasicPatientInfo(id: number): Promise<ICustomer> {
    const { patient, error } = await Api.get(`${PATIENT_BASE_PATH}/${id}`);
    if (error) {
      throw error;
    }
    return patient;
  },

  async getCustomerMembershipConsent(customerId: number): Promise<ICustomerMembershipConsent> {
    const { customerMembershipConsent, error } = await Api.get(
      `${API_ROUTES.CUSTOMER_MEMBERSHIP_CONSENTS}/${customerId}`
    );
    if (error) {
      throw error;
    }
    return customerMembershipConsent;
  },

  async createCustomerMembershipConsent({
    customerId,
    planId,
    signature,
    patientName,
  }: ICreateCustomerMembershipConsent): Promise<IMembershipConsent> {
    const { customerMembershipConsent, error } = await Api.post(`${API_ROUTES.CUSTOMER_MEMBERSHIP_CONSENTS}`, {
      customerId,
      planId,
      signature,
      patientName,
    });
    if (error) {
      throw error;
    }
    return customerMembershipConsent;
  },

  async getOpenedServiceVisit({ patientId }: { patientId: string }): Promise<IServiceVisit> {
    return Api.get(`/service-visits/${patientId}/opened-service-visit`);
  },
  async getReferrals(id: number): Promise<IReferralCredits[]> {
    const { referralCredits } = await Api.get(`/referrals/customers/${id}`);
    return referralCredits;
  },
  async bulkMedspaPatientProfilesUpload(userGroupId: string, practitionerId: string, data: any): Promise<any> {
    const payload = {
      patient_profiles: data,
      practitioner_id: practitionerId,
    };
    const res = await Api.post(`v4/medspa/${userGroupId}/bulk_customer_upload`, payload);
    if (res.error) {
      throw res.error;
    }
    return res;
  },
  async bulkPatientProfilesUpload(data: any): Promise<any> {
    const res = await Api.post(`${PATIENT_BASE_PATH}/bulk_patient_profiles_upload`, data);
    if (res.error) {
      throw res.error;
    }
    return res;
  },
  async updateCustomerGfeStatus({ customerId }: any): Promise<any> {
    const { success } = await Api.post(API_ROUTES.CUSTOMER_GFE_STATUS_TO_CLEAR(customerId), {});
    return success;
  },
};

export const ClaimPatient = async ({ claim, customerId }: IClaimPatientParams) => {
  const endpoint = claim ? 'claim' : 'unclaim';

  const { data } = await Api.post(`${PATIENT_BASE_PATH}/${customerId}/${endpoint}`, {});

  return data;
};

export default Patients;
