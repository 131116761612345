import { isEqual } from 'lodash';
import React from 'react';
import classnames from 'classnames';
import { Box, Typography } from '@material-ui/core';
// eslint-disable-next-line max-len
import { OrderApprovalFilterStyles as useStyles } from 'src/components/DashboardPractitioner/Tabs/OrderingTab/inventoryOrder.styles';
import { APPROVAL_FILTER_ROLE_BUTTONS } from 'src/constants/inventory.constants';
import { OrderApprovalFiltersButton } from './OrderApprovalFiltersButton';
import { Props } from '.';

export const OrderApprovalRoleFilter = ({
  roles,
  isLoading,
  setFilterState,
  filterState = {},
}: Omit<Props, 'currentCount'>) => {
  const classes = useStyles();
  const hasLoadedRoles = !!Object.keys(roles).length;

  const handleClick = (value: number[]) => {
    setFilterState({
      ...filterState,
      roleId: value,
    });
  };

  const isActive = (value: number[]) => !!filterState.roleId && isEqual(value, filterState.roleId);

  return (
    <Box display="flex" flexDirection="column" className={classes.box} data-testid="order-approval-role-filter">
      <Typography variant="overline" className={classes.filterTitle}>
        Product Type
      </Typography>
      <Box className={classnames(classes.buttonGroup, classes.buttonGroupRole)}>
        {Object.entries(APPROVAL_FILTER_ROLE_BUTTONS).map(([key, value]) => {
          const roleIds = value.map((role) => roles[role]?.[0].id);

          return (
            <OrderApprovalFiltersButton
              key={key}
              isLoading={isLoading}
              disabled={isLoading || !hasLoadedRoles}
              isActive={hasLoadedRoles && isActive(roleIds)}
              onClick={() => handleClick(roleIds)}
              text={key}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default OrderApprovalRoleFilter;
