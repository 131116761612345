/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@material-ui/icons';
import moment from 'moment';
import { Button } from '@material-ui/core';
import AppointmentStatusDropdown from 'src/components/common/Appointments/AppointmentStatusDropdown';
import BlockOffHoursModal from 'src/pages/Configuration/Calendar/BlockOffHoursModal';
import { useStyles } from 'src/components/common/Appointments/Appointments.styles';
import AppointmentsDropdown from 'src/components/common/Appointments/AppointmentsDropdown';
import { GFE_REQUEST } from 'src/constants/actions.constants';
import { hasAccessTo } from 'src/utils/auth.utils';
import { useSelector } from 'react-redux';
import { USER_TYPES } from 'src/constants/general.constants';
import { copyToClipboard } from 'src/utils/clipboard.utils';
import { SIGN_UP_ROUTES } from 'src/constants/routes.constants';

interface Props {
  currentDate: any;
  view: string;
  status: string;
  appointmentStatus?: string;
  handleChangeView: (view: string) => void;
  handleStatus: (status: string) => void;
  setAppointmentStatus?: (status: string) => void;
  handleNavigatorChange: (action: string) => void;
  permissions: string[];
  practitionerId?: number;
}

const CustomToolbar = ({
  currentDate,
  view,
  status,
  appointmentStatus,
  handleChangeView,
  handleStatus,
  setAppointmentStatus,
  handleNavigatorChange,
  permissions,
  practitionerId,
}: Props) => {
  const classes = useStyles();
  const [openBlockOffHoursModal, setOpenBlockOffHoursModal] = useState(false);
  const {
    userType,
    user: { slug, activeForScheduling },
  } = useSelector(({ auth }: any) => auth);
  const isPractitioner = userType === USER_TYPES.PRACTITIONER;

  return (
    <div className={classes.toolbar}>
      <div className={classes.currentDate} data-cy="currentMonthYear">
        <b style={{ fontSize: '115%' }}>{moment(currentDate).format('MMMM')}</b>
        {` ${moment(currentDate).format('YYYY')}`}
      </div>
      {/* Dropdown filter only for providers' view [ER-5192] */}
      {userType === USER_TYPES.PRACTITIONER && (
        <>
          {hasAccessTo(GFE_REQUEST, permissions) ? (
            <AppointmentsDropdown status={status} handleStatus={handleStatus} />
          ) : (
            <AppointmentStatusDropdown
              status={appointmentStatus}
              handleStatus={(option: string) => setAppointmentStatus?.(option)}
            />
          )}
        </>
      )}

      <div className={classes.navigationButtons}>
        <div
          className={classes.navigationButton}
          onClick={() => handleNavigatorChange('back')}
          data-cy="calendarNavBack"
        >
          <KeyboardArrowLeftIcon />
        </div>
        <div
          className={classes.todayNavigation}
          onClick={() => handleNavigatorChange('today')}
          data-cy="calendarNavToday"
        >
          Today
        </div>
        <div
          className={classes.navigationButton}
          onClick={() => handleNavigatorChange('forward')}
          data-cy="calendarNavFoward"
        >
          <KeyboardArrowRightIcon />
        </div>
      </div>

      {isPractitioner && !!slug && activeForScheduling && (
        <div style={{ margin: '8px' }}>
          <Button
            onClick={() => copyToClipboard(`${SIGN_UP_ROUTES.booking}/${slug}`)}
            style={{ color: 'white', width: 'auto', border: '1px solid #5F8E87' }}
          >
            Booking link
          </Button>
        </div>
      )}

      <div className={classes.viewsSelectors}>
        <div
          className={`${classes.viewSelector} ${view === 'Day' && classes.viewSelectorActive}`}
          onClick={() => handleChangeView('Day')}
          data-cy="dayToggle"
        >
          Day
        </div>
        <div
          className={`${classes.viewSelector} ${view === 'Week' && classes.viewSelectorActive}`}
          onClick={() => handleChangeView('Week')}
          data-cy="weekToggle"
        >
          Week
        </div>
        <div
          className={`${classes.viewSelector} ${view === 'Month' && classes.viewSelectorActive}`}
          onClick={() => handleChangeView('Month')}
          data-cy="monthToggle"
        >
          Month
        </div>
      </div>

      <BlockOffHoursModal
        openDialog={openBlockOffHoursModal}
        setOpenDialog={setOpenBlockOffHoursModal}
        practitionerId={practitionerId}
      />
    </div>
  );
};

export default CustomToolbar;
