import React from 'react';
import { Box, Typography, useMediaQuery, withStyles } from '@material-ui/core';
import { FULLSCREEN } from 'src/constants/inventory.constants';
import { PORTRAIT_EVANGELIST_PROGRAM_LINK } from 'src/constants/general.constants';
import { NEW_WINDOW } from 'src/constants/clientLead.constants';
import { ClassNameMap } from 'src/types/Dom';
import { OpenInNew as OpenInNewIcon } from '@material-ui/icons';

export const PortraitEvangelistBanner: React.FC<ClassNameMap> = ({ classes }): React.ReactElement | null => {
  const matches = useMediaQuery(FULLSCREEN);

  return (
    <Box className={classes?.bannerContainer} data-mobile={!matches} data-testid="portraitEvangelistBanner">
      <Typography className={classes?.bannerLabel}>
        <a target={NEW_WINDOW} href={PORTRAIT_EVANGELIST_PROGRAM_LINK} data-testid="PortraitEvangelistBanner.link">
          Become a #PoweredByPortrait Evangelist
          <span>
            <OpenInNewIcon fontSize="small" style={{ height: '15px', marginLeft: '2px' }} />
          </span>
        </a>
        <span>to save $1,000 or more on your orders every month!</span>
      </Typography>
    </Box>
  );
};

export default withStyles({
  bannerContainer: {
    width: '100vw',
    margin: '0 0 25px -39px',
    display: 'flex',
    flexDirection: 'row',
    minHeight: '60px',
    maxHeight: '100px',
    backgroundColor: 'rgb(175,225,175)',
    padding: '6px 0',
    '&[data-mobile="true"]': {
      maxHeight: '77px',
    },
    '& a': {
      color: '#1e5b96',
      margin: 5,
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      boxShadow: 'inset 0 -1px 0 0 #1e5b96',
    },
    '& > button': {
      margin: 'auto 0 auto 15px',
      backgroundColor: 'transparent',
      border: '1px solid #fff',
      color: '#fff',
      display: 'flex',
      fontSize: 12,
      padding: '6px 25px',
      borderColor: '#fff0',
    },
    '& > button:last-child': {
      marginRight: 'auto',
    },
  },
  bannerLabel: {
    margin: 'auto',
    color: '#000',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
})(PortraitEvangelistBanner);
