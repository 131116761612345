import React from 'react';

import { FormControl, MenuItem, InputLabel, Select, CardContent } from '@material-ui/core';
import { sortBy } from 'lodash';
import { ILocationPatientId } from 'src/interfaces/ILocation';
import { useStyles } from './LocationsStep.styles';

interface Props {
  locations: ILocationPatientId[];
  isLoading: boolean;
  selectedLocation?: ILocationPatientId | null;
  setSelectedLocation: React.Dispatch<React.SetStateAction<ILocationPatientId | null>>;
  showErrorMessage: boolean;
}

export const LocationsStep = ({
  locations,
  isLoading,
  selectedLocation,
  setSelectedLocation,
  showErrorMessage,
}: Props) => {
  const classes = useStyles();

  const handleLocationChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const locationId = event.target.value as number;
    const location = locations.find((loc) => loc.id === locationId);
    if (location) {
      setSelectedLocation(location);
    }
  };

  return (
    <CardContent className={classes.card}>
      <div className={classes.title}>Location</div>
      <FormControl className={classes.formControl} size="medium" variant="outlined" disabled={isLoading}>
        <InputLabel id="location">Locations available</InputLabel>
        <Select
          label="Locations available"
          onChange={handleLocationChange}
          id="location"
          value={selectedLocation?.id ?? ''}
          fullWidth
        >
          <MenuItem value="" disabled>
            Select a location
          </MenuItem>
          {sortBy(locations, (location) => location.name).map((location) => (
            <MenuItem key={location.id} value={location.id}>
              {location.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {showErrorMessage && !selectedLocation?.id && (
        <div className={classes.errorMessage}>Selecting the Location is a requirement to proceed</div>
      )}
    </CardContent>
  );
};
