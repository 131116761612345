import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    backgroundColor: '#fff',
    borderRadius: theme.spacing(1),
  },
  heading: {
    width: '100%',
    borderBottom: '1px solid #eee',
    textAlign: 'left',
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  subHeading: {
    fontSize: 18,
    fontWeight: 500,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: '#555',
  },
  labelTitleMid: {
    marginBottom: 10,
  },
  legacyDatePicker: {
    '& input': {
      fontSize: 14,
    },
  },
  shadowedLabel: {
    fontSize: '14px',
    opacity: 0.4,
  },
  editInstructions: {
    margin: '10px 0',
    padding: 15,
    backgroundColor: 'rgb(254,242,226)',
    color: 'rgb(133,76,0)',
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '8px',
    border: '1px solid rgb(237,225,209)',
    '& p': {
      margin: 'auto auto auto 15px',
      fontSize: '0.6rem',
      fontFamily: 'Messina Sans Regular',
    },
    '& p > span': {
      fontFamily: 'Roboto',
    },
    '& svg': {
      fontSize: 22,
      color: 'rgb(247,147,13)',
    },
  },
  dateCaption: {
    lineHeight: 1.3,
    fontSize: 11,
  },
}));
