import { Box, CircularProgress, Grid, MenuItem } from '@material-ui/core';
import React, { useState, useEffect } from 'react';

import { EHRButton, EHRSelect } from 'src/components/ui/v1';
import Page from 'src/components/common/Page';
import { useSpecialOffers } from 'src/hooks/queries/useSpecialOffers';
import { ShortMultipleSkeleton } from 'src/components/common/LoadingSkeleton';
import NewSpecialModal from './NewSpecialModal';
import { SpecialOfferRow } from './SpecialOfferRow';

const SpecialsListPage = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedSpecial, setSelectedSpecial] = useState(null);
  const [editMode, setEditMode] = useState(true);

  const [specialsStatus, setSpecialsStatus] = useState('');
  const [specialsType, setSpecialsType] = useState('');

  const { data: specials, isLoading: isLoadingSpecial, refetch } = useSpecialOffers(specialsStatus, specialsType);

  useEffect(() => {
    refetch();
  }, [specialsStatus, specialsType, refetch]);

  const handleOpenModal = () => {
    setEditMode(false);
    setSelectedSpecial(null);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setEditMode(true);
    setSelectedSpecial(null);
    setModalOpen(false);
  };

  if (isLoadingSpecial) {
    return (
      <Page title="Specials">
        <Grid container spacing={2} style={{ padding: '12px 15px' }}>
          <Grid item xs={12}>
            <ShortMultipleSkeleton length={10} />
          </Grid>
        </Grid>
      </Page>
    );
  }

  return (
    <Page title="Specials">
      <Box margin={2} padding={2} width="100%" display="flex" flexDirection="column" alignItems="flex-end">
        <Box flexDirection="row" display="flex" gridGap="1rem" marginBottom={2}>
          <Box data-cy="specialsStatusSelect" className="test">
            <EHRSelect
              style={{ width: '200px' }}
              displayEmpty
              dataCy="select-specials-status"
              onChange={(value: string | number) => setSpecialsStatus(value as string)}
              value={specialsStatus}
              fullWidth
            >
              <MenuItem value="">All Status</MenuItem>
              <MenuItem value="true">Active</MenuItem>
              <MenuItem value="false">Inactive</MenuItem>
            </EHRSelect>
          </Box>
          <Box data-cy="specialsTypeSelect" className="test">
            <EHRSelect
              style={{ width: '200px' }}
              displayEmpty
              dataCy="select-specials-type"
              onChange={(value: string | number) => setSpecialsType(value as string)}
              value={specialsType}
              fullWidth
            >
              <MenuItem value="">All Offerings</MenuItem>
              <MenuItem value="PortraitFlex">Flex</MenuItem>
              <MenuItem value="PortraitLaunch">Launch</MenuItem>
            </EHRSelect>
          </Box>
          <EHRButton dataCy="new-special" text="New Special" color="primary" onClick={handleOpenModal} />
          <Box height="2rem" />
        </Box>

        {isLoadingSpecial ? (
          <CircularProgress size={22} />
        ) : (
          specials?.map((special, index: number) => (
            <SpecialOfferRow
              key={special.id}
              special={special}
              autoOpen={index === 0}
              setShowLoadingSpinner={() => {}}
              toggleEditModal={() => setModalOpen(true)}
              setSelectedSpecial={setSelectedSpecial}
            />
          ))
        )}

        <NewSpecialModal
          openModal={isModalOpen}
          onClose={handleCloseModal}
          selectedSpecial={selectedSpecial}
          setSelectedSpecial={setSelectedSpecial}
          editMode={editMode}
        />
      </Box>
    </Page>
  );
};

export default SpecialsListPage;
