import React, { useRef } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import FeatureFlagsPage from 'src/pages/FeatureFlags';
import UsersFlagsPage from 'src/pages/UsersFlags';
import { ADMIN_ROLE } from 'src/constants/general.constants';

import Documents from 'src/components/DashboardAdministrator/Documents';
import LeadCampaigns from 'src/components/DashboardPractitioner/Tabs/InsightsTab/LeadCampaigns';
import ProductUtilization from 'src/components/DashboardPractitioner/Tabs/Inventory/ProductUtilization';
import SpecialsListPage from 'src/pages/DashboardAdministrator/SpecialsListPage';
import OrderProducts from 'src/components/DashboardAdministrator/OrderProducts';
import Resources from 'src/components/DashboardAdministrator/Resources';
import Wrapper from '../components/common/Wrapper';
import Work from '../components/DashboardAdministrator/Work/Work';
import Forecasts from '../components/DashboardAdministrator/Dashboard/Forecasts';
import SupplyReceiptsPage from '../components/DashboardAdministrator/Inventory/SupplyReceipts';
import ReconciliationReportsPage from '../components/DashboardAdministrator/Reconciliation/ReconciliationReports';
import PurchaseOrdersPage from '../components/DashboardAdministrator/PurchaseOrders/PurchaseOrders';
import OrderApproval from '../components/DashboardAdministrator/OrderApproval';
import ClientLeads from '../components/DashboardPractitioner/Tabs/InsightsTab/TableLeads';
import LeadReports from '../components/DashboardPractitioner/Tabs/InsightsTab/LeadReports';
import MedSpas from '../components/DashboardAdministrator/Medspas';
import NewSupplyReceiptsPageStep1 from '../components/DashboardAdministrator/Inventory/NewSupplyReceiptsStep1';
import NewSupplyReceiptsPageStep2 from '../components/DashboardAdministrator/Inventory/NewSupplyReceiptsStep2';
import ViewReport from '../pages/ViewReport';
import ReconciliationAssets from '../components/DashboardAdministrator/Reconciliation/ReconciliationAssets';
import PdfDatamatrixGeneration from '../pages/PdfDatamatrixGeneration';
import NewPurchaseOrder from '../components/DashboardAdministrator/PurchaseOrders/NewPurchaseOrder';
import PurchaseOrderAssets from '../components/DashboardAdministrator/PurchaseOrders/PurchaseOrderAssets';
import AdminAssets from '../components/DashboardAdministrator/Assets/AdminAssets';
import PractitionersPage from '../pages/Practitioners';
import PhysiciansPage from '../pages/Physicians';
import LocationsPage from '../pages/Locations';
import PatientsPage from '../pages/DashboardAdministrator/Patients';
import ServiceVisitAssets from '../pages/DashboardAdministrator/ServiceVisitAssets';
import PatientsUpcomingPage from '../pages/PatientsUpcomingPage';
import OverviewPage from '../pages/OverviewPage';
import TransactionsPage from '../components/DashboardAdministrator/Transactions/Transactions';
import CheckoutTransactionsPage from '../components/DashboardAdministrator/Transactions/CheckoutTransactions';
import ReportsPage from '../components/DashboardAdministrator/Dashboard/Reports/Reports';
import Users from '../components/DashboardAdministrator/Users/AdministratorsTable';
import UserGroups from '../components/DashboardAdministrator/UserGroups';
import NewAdministratorUser from '../components/DashboardAdministrator/Users/NewAdministratorUser';
import NewPatient from '../pages/NewPatient/NewPatient';
import NewPhysician from '../components/DashboardAdministrator/Physicians/NewPhysician';
import NewPrivateRoute, { NewPrivateRouteWithoutRolesProps } from '../utils/NewPrivateRoute';
import {
  CREATE_ADMIN_USER,
  CREATE_PRACTITIONER,
  CREATE_CUSTOMER,
  CREATE_LOCATION,
  CREATE_PHYSICIAN,
  READ_PRACTITIONER,
  READ_LOCATION,
  READ_PHYSICIAN,
  READ_ASSET,
  READ_CUSTOMER,
  READ_PRACTITIONER_SALES_DASHBOARD,
  READ_PURCHASE_ORDER,
  READ_RECONCILIATION_REPORT,
  READ_SUPPLY_RECEIPT,
  READ_ADMIN_USER,
  CREATE_PURCHASE_ORDER,
  CREATE_SERVICE,
  READ_CLIENT_LEAD,
  READ_USER_GROUP,
  IS_SUPPORT,
  DOCUMENT_CREATE,
  READ_AD_CAMPAIGN,
  UPDATE_INVENTORY_ORDER_PERIOD,
  READ_INVENTORY_ORDER,
  RESOURCE_CREATE,
} from '../constants/actions.constants';
import NewLocation from '../components/DashboardAdministrator/Locations/NewLocation';
import { ServiceForm } from '../components/DashboardAdministrator/Services/ServiceForm/ServiceForm';
import BulkUpload from '../pages/BulkUpload/BulkUpload';
import ServicesListPage from '../pages/DashboardAdministrator/ServicesListPage';
import CreateOrEditPractitioners from '../components/DashboardAdministrator/Practitioners/CreateOrEditPractitioners';

export const ADMIN_BASE_PATH = '/administrator';
export const ADMIN_INVENTORY_HOME_PATH = `${ADMIN_BASE_PATH}/purchase-orders`;
export const ADMIN_PATIENTS_LIST_PATH = `${ADMIN_BASE_PATH}/patients`;
export const ADMIN_UPCOMING_PATIENTS_LIST = `${ADMIN_BASE_PATH}/patientsUpcoming`;
export const ADMIN_USERS_LIST_PATH = `${ADMIN_BASE_PATH}/users`;
export const ADMIN_PRACTITIONER_LIST_PATH = `${ADMIN_BASE_PATH}/practitioners`;
export const ADMIN_PHYSICIANS_LIST_PATH = `${ADMIN_BASE_PATH}/physicians`;
export const ADMIN_LOCATION_LIST_PATH = `${ADMIN_BASE_PATH}/locations`;
export const ADMIN_NEW_PATIENT_PATH = `${ADMIN_BASE_PATH}/new-patient`;
export const ADMIN_NEW_BULK_UPLOAD_PATH = `${ADMIN_BASE_PATH}/new-bulk-upload`;
export const ADMIN_FEATURE_FLAGS = `${ADMIN_BASE_PATH}/flags`;
export const ADMIN_USERS_FLAGS = `${ADMIN_BASE_PATH}/user-flags`;
export const EDIT_PRACTITIONER = (practitionerId: string) => `${ADMIN_BASE_PATH}/edit-practitioner/${practitionerId}`;
const ADMIN_FORECASTS_PATH = `${ADMIN_BASE_PATH}/forecasts`;
const ADMIN_WORK_PATH = `${ADMIN_BASE_PATH}/work`;
export const ADMIN_ORDER_APPROVAL = `${ADMIN_BASE_PATH}/order-approval`;
export const ADMIN_PRODUCT_UTILIZATION = `${ADMIN_BASE_PATH}/product-utiliztion`;
export const ADMIN_CLIENT_LEADS = `${ADMIN_BASE_PATH}/client-leads`;
export const ADMIN_CLIENT_LEADS_REPORTS = `${ADMIN_BASE_PATH}/client-leads-reports`;
export const ADMIN_CLIENT_LEADS_CAMPAIGNS = `${ADMIN_BASE_PATH}/client-leads-campaigns`;
export const ADMIN_MEDSPA = `${ADMIN_BASE_PATH}/medspas`;
export const ADMIN_ORDER_PRODUCTS = `${ADMIN_BASE_PATH}/products`;
const ADMIN_SUPPLY_RECEIPTS_PATH = `${ADMIN_BASE_PATH}/supply-receipts`;
const ADMIN_NEW_SUPPLY_STEP_1_PATH = `${ADMIN_BASE_PATH}/new-supply-receipt-step-1/:supplyReceiptId?`;
const ADMIN_NEW_SUPPLY_STEP_2_PATH = `${ADMIN_BASE_PATH}/new-supply-receipt-step-2/:supplyReceiptId/:serviceId`;
export const ADMIN_SERVICES_LIST_PATH = `${ADMIN_BASE_PATH}/services`;
export const ADMIN_SPECIALS_LIST_PATH = `${ADMIN_BASE_PATH}/specials`;
export const EDIT_SERVICE = (serviceId: string) => `${ADMIN_BASE_PATH}/edit-service/${serviceId}`;
export const ADMIN_SERVICE_PATH = (action: string) => `${ADMIN_BASE_PATH}/${action}-service/:serviceId?`;
// eslint-disable-next-line max-len
const ADMIN_RECONCILIATION_REPORT_PRODUCTS_PATH = `${ADMIN_BASE_PATH}/reconciliation-reports/:reportId/:serviceId/products`;
const ADMIN_RECONCILIATION_REPORT_BY_ID_PATH = `${ADMIN_BASE_PATH}/reconciliation-reports/:reportId`;
const ADMIN_RECONCILIATION_REPORTS_PATH = `${ADMIN_BASE_PATH}/reconciliation-reports`;
// eslint-disable-next-line max-len
const ADMIN_PURCHASE_ORDER_BY_SERVICE_PATH = `${ADMIN_BASE_PATH}/purchase-order/:purchaseId/product-identifiers/:id/:serviceId`;
const ADMIN_PDF_GENERATION_BY_ID_PATH = `${ADMIN_BASE_PATH}/pdf-generation/:id`;
const ADMIN_ASSETS_PATH = `${ADMIN_BASE_PATH}/assets`;
const ADMIN_OVERVIEW_PATH = `${ADMIN_BASE_PATH}/overview`;
const ADMIN_SERVICE_VISIT_ASSETS_PATH = `${ADMIN_BASE_PATH}/service-visit-assets`;
const ADMIN_NEW_PURSHASE_ORDER_PATH = `${ADMIN_BASE_PATH}/new-purchase-order/:purchaseId?`;
const ADMIN_TRANSACTIONS_PATH = `${ADMIN_BASE_PATH}/transactions`;
const ADMIN_CHECKOUT_TRANSACTIONS_PATH = `${ADMIN_BASE_PATH}/checkout-transactions`;
const ADMIN_REPORTS_PATH = `${ADMIN_BASE_PATH}/reports`;
const ADMIN_NEW_USER_BY_ID_PATH = `${ADMIN_BASE_PATH}/new-user/:userId?`;
const ADMIN_PRACTITIONER_PATH = (action: string) => `${ADMIN_BASE_PATH}/${action}-practitioner/:practitionerId?`;
const ADMIN_NEW_LOCATION_PATH = `${ADMIN_BASE_PATH}/new-location/:locationId?`;
const ADMIN_NEW_PHYSICIAN_PATH = `${ADMIN_BASE_PATH}/new-physician/:physicianId?`;
const ADMIN_DOCUMENTS = `${ADMIN_BASE_PATH}/documents`;
export const ADMIN_RESOURCES = `${ADMIN_BASE_PATH}/resources`;

const AdministratorPrivateRoute = (props: NewPrivateRouteWithoutRolesProps) => (
  <NewPrivateRoute {...props} roles={ADMIN_ROLE} />
);

const AdministratorRoutes = () => {
  const scrollMainRef = useRef(null);

  return (
    <Wrapper scrollMainRef={scrollMainRef}>
      <Switch>
        <AdministratorPrivateRoute
          path={ADMIN_PRACTITIONER_LIST_PATH}
          permission={READ_PRACTITIONER}
          component={PractitionersPage}
          exact
        />
        <AdministratorPrivateRoute
          path={ADMIN_FORECASTS_PATH}
          permission={READ_PRACTITIONER_SALES_DASHBOARD}
          component={Forecasts}
          exact
        />
        <AdministratorPrivateRoute
          path={ADMIN_WORK_PATH}
          permission={READ_PRACTITIONER_SALES_DASHBOARD}
          component={Work}
          exact
        />
        <AdministratorPrivateRoute
          path={ADMIN_SUPPLY_RECEIPTS_PATH}
          permission={READ_SUPPLY_RECEIPT}
          component={SupplyReceiptsPage}
          exact
        />
        <AdministratorPrivateRoute
          path={ADMIN_NEW_SUPPLY_STEP_1_PATH}
          permission={READ_SUPPLY_RECEIPT}
          component={NewSupplyReceiptsPageStep1}
          exact
        />
        <AdministratorPrivateRoute
          path={ADMIN_NEW_SUPPLY_STEP_2_PATH}
          permission={READ_SUPPLY_RECEIPT}
          component={NewSupplyReceiptsPageStep2}
          exact
        />
        <AdministratorPrivateRoute
          path={ADMIN_SERVICES_LIST_PATH}
          permission={CREATE_SERVICE}
          component={ServicesListPage}
        />
        <AdministratorPrivateRoute
          path={ADMIN_SPECIALS_LIST_PATH}
          permission={CREATE_SERVICE}
          component={SpecialsListPage}
        />
        <AdministratorPrivateRoute
          path={ADMIN_RECONCILIATION_REPORT_PRODUCTS_PATH}
          permission={READ_RECONCILIATION_REPORT}
          component={ReconciliationAssets}
        />
        <AdministratorPrivateRoute
          path={ADMIN_RECONCILIATION_REPORT_BY_ID_PATH}
          permission={READ_RECONCILIATION_REPORT}
          component={ViewReport}
        />
        <AdministratorPrivateRoute
          path={ADMIN_RECONCILIATION_REPORTS_PATH}
          permission={READ_RECONCILIATION_REPORT}
          component={ReconciliationReportsPage}
        />
        <AdministratorPrivateRoute
          path={ADMIN_PURCHASE_ORDER_BY_SERVICE_PATH}
          permission={READ_PURCHASE_ORDER}
          component={PurchaseOrderAssets}
        />
        <AdministratorPrivateRoute
          path={ADMIN_INVENTORY_HOME_PATH}
          permission={READ_PURCHASE_ORDER}
          component={PurchaseOrdersPage}
        />
        <AdministratorPrivateRoute
          path={ADMIN_PDF_GENERATION_BY_ID_PATH}
          permission={READ_PURCHASE_ORDER}
          component={PdfDatamatrixGeneration}
        />
        <AdministratorPrivateRoute path={ADMIN_ASSETS_PATH} permission={READ_ASSET} component={AdminAssets} />
        <AdministratorPrivateRoute path={ADMIN_OVERVIEW_PATH} permission={READ_ASSET} component={OverviewPage} />
        <AdministratorPrivateRoute
          path={ADMIN_SERVICE_VISIT_ASSETS_PATH}
          permission={READ_ASSET}
          component={ServiceVisitAssets}
        />
        <AdministratorPrivateRoute
          path={ADMIN_NEW_PURSHASE_ORDER_PATH}
          permission={CREATE_PURCHASE_ORDER}
          component={NewPurchaseOrder}
        />
        <AdministratorPrivateRoute
          path={ADMIN_LOCATION_LIST_PATH}
          permission={READ_LOCATION}
          component={LocationsPage}
        />
        <AdministratorPrivateRoute
          path={ADMIN_TRANSACTIONS_PATH}
          permission={READ_PRACTITIONER_SALES_DASHBOARD}
          component={TransactionsPage}
        />
        <AdministratorPrivateRoute
          path={ADMIN_CHECKOUT_TRANSACTIONS_PATH}
          permission={READ_PRACTITIONER_SALES_DASHBOARD}
          component={CheckoutTransactionsPage}
        />
        <AdministratorPrivateRoute
          path={ADMIN_REPORTS_PATH}
          permission={READ_PRACTITIONER_SALES_DASHBOARD}
          component={ReportsPage}
        />
        <AdministratorPrivateRoute path={ADMIN_USERS_LIST_PATH} permission={READ_ADMIN_USER} component={Users} />
        <AdministratorPrivateRoute
          path={ADMIN_NEW_USER_BY_ID_PATH}
          permission={CREATE_ADMIN_USER}
          component={NewAdministratorUser}
        />
        <AdministratorPrivateRoute
          path={[ADMIN_PRACTITIONER_PATH('new'), ADMIN_PRACTITIONER_PATH('edit')]}
          permission={CREATE_PRACTITIONER}
          component={CreateOrEditPractitioners}
        />
        <AdministratorPrivateRoute
          path={ADMIN_NEW_LOCATION_PATH}
          permission={CREATE_LOCATION}
          component={NewLocation}
        />
        <AdministratorPrivateRoute
          path={ADMIN_PHYSICIANS_LIST_PATH}
          permission={READ_PHYSICIAN}
          component={PhysiciansPage}
        />
        <AdministratorPrivateRoute
          path={ADMIN_NEW_PHYSICIAN_PATH}
          permission={CREATE_PHYSICIAN}
          component={NewPhysician}
        />
        <AdministratorPrivateRoute path={ADMIN_NEW_PATIENT_PATH} permission={CREATE_CUSTOMER} component={NewPatient} />
        <AdministratorPrivateRoute
          path={ADMIN_NEW_BULK_UPLOAD_PATH}
          permission={CREATE_CUSTOMER}
          component={BulkUpload}
        />
        <AdministratorPrivateRoute
          path={[ADMIN_SERVICE_PATH('new'), ADMIN_SERVICE_PATH('edit')]}
          permission={CREATE_SERVICE}
          component={ServiceForm}
        />
        <AdministratorPrivateRoute
          path={ADMIN_PATIENTS_LIST_PATH}
          permission={READ_CUSTOMER}
          component={PatientsPage}
        />
        <AdministratorPrivateRoute
          path={ADMIN_UPCOMING_PATIENTS_LIST}
          permission={READ_CUSTOMER}
          component={PatientsUpcomingPage}
        />
        <AdministratorPrivateRoute
          path={ADMIN_ORDER_APPROVAL}
          permission={UPDATE_INVENTORY_ORDER_PERIOD}
          component={OrderApproval}
        />
        <AdministratorPrivateRoute
          path={ADMIN_PRODUCT_UTILIZATION}
          permission={READ_INVENTORY_ORDER}
          component={ProductUtilization}
        />
        <AdministratorPrivateRoute
          path={ADMIN_ORDER_PRODUCTS}
          permission={READ_INVENTORY_ORDER}
          component={OrderProducts}
        />
        <AdministratorPrivateRoute path={ADMIN_CLIENT_LEADS} permission={READ_CLIENT_LEAD} component={ClientLeads} />
        <AdministratorPrivateRoute
          path={ADMIN_CLIENT_LEADS_REPORTS}
          permission={READ_CLIENT_LEAD}
          component={LeadReports}
        />
        <AdministratorPrivateRoute
          path={ADMIN_CLIENT_LEADS_CAMPAIGNS}
          permission={READ_AD_CAMPAIGN}
          component={LeadCampaigns}
        />
        <AdministratorPrivateRoute
          path={ADMIN_MEDSPA}
          permission={READ_USER_GROUP}
          component={process.env.REACT_APP_NEW_MEDSPA_ADMIN_EXPERIENCE === 'true' ? MedSpas : UserGroups}
        />
        <AdministratorPrivateRoute path={ADMIN_FEATURE_FLAGS} permission={IS_SUPPORT} component={FeatureFlagsPage} />
        <AdministratorPrivateRoute path={ADMIN_USERS_FLAGS} permission={IS_SUPPORT} component={UsersFlagsPage} />
        <AdministratorPrivateRoute path={ADMIN_DOCUMENTS} permission={DOCUMENT_CREATE} component={Documents} />

        <AdministratorPrivateRoute path={ADMIN_RESOURCES} permission={RESOURCE_CREATE} component={Resources} />

        <AdministratorPrivateRoute path="/" permission={READ_CUSTOMER}>
          <Redirect to={ADMIN_PATIENTS_LIST_PATH} />
        </AdministratorPrivateRoute>
      </Switch>
    </Wrapper>
  );
};

export default AdministratorRoutes;
