import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Container, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MEDSPA_ADMIN_BASE_PATH, MEDSPA_ADMIN_INVENTORY } from 'src/routes/medspaAdminRoutes';
import { MEDSPA_PROVIDER_PATIENTS } from 'src/routes/medspaPractitionerRoutes';
import { EHRButton, EHRTextField } from 'src/components/ui/v1';
import { Alert } from '@material-ui/lab';
import Banner from 'src/components/common/Banner';
import Footer from 'src/components/Footer';
import { ReactComponent as PortraitLogo } from '../assets/images/portrait-logo.svg';
import { RootState, dispatch } from '../rematch';
import {
  PRACTITIONER_ROLE,
  PHYSICIAN_ROLE,
  ADMIN_ROLE,
  MEDSPA_ADMIN_ROLE,
  SALES_ROUND_ROBIN,
} from '../constants/general.constants';
import { hasAccessTo } from '../utils/auth.utils';
import { READ_PRACTITIONER_SALES_DASHBOARD } from '../constants/actions.constants';
import { PRACTITIONER_HOME_PATH } from '../routes/practitionerRoutes';
import { PHYSICIAN_PROVIDERS_PATH } from '../routes/physicianRoutes';
import { ADMIN_PATIENTS_LIST_PATH, ADMIN_INVENTORY_HOME_PATH } from '../routes/administratorRoutes';
import { Practitioners } from '../services/Practitioners';

export const INACTIVE_LITE_MEDSPA = 'inactive_lite_medspa';

const mapState = (state: any) => ({
  isLoading: state.loading.effects.auth.login,
  isAuthenticate: state.auth.isAuthenticate,
  isSuccess: state.auth.isSuccess,
  messageError: state.auth.messageError,
  userType: state.auth.userType,
  permissions: state.auth.permissions,
  roleName: state.auth.roleName,
  featureList: state.auth.featureList,
  isFirstLogin: state.auth.isFirstLogin,
});

const mapDispatch = ({ auth: { login } }: any) => ({
  login: () => login(),
});

const credentialInitialState = {
  email: '',
  password: '',
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '100vh',
    justifyContent: 'center',
    paddingBottom: '2rem',
  },
  paper: {
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    minWidth: '473px',
    [theme.breakpoints.down('xs')]: {
      minWidth: '90vw',
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logoContainer: {
    width: '75%',
  },
}));

interface LoginProps {
  isLoading: RootState['auth']['isLoading'];
  isSuccess: RootState['auth']['isSuccess'];
  messageError: RootState['auth']['messageError'];
  userType: RootState['auth']['userType'];
  isAuthenticate: RootState['auth']['isAuthenticate'];
  permissions: RootState['auth']['permissions'];
  roleName: RootState['auth']['roleName'];
  isFirstLogin: RootState['auth']['isFirstLogin'];
}

const Login: React.FC<LoginProps> = (props) => {
  const { isLoading, isSuccess, messageError, userType, isAuthenticate, permissions, roleName, isFirstLogin } = props;
  const classes = useStyles();
  const history = useHistory();
  const [credential, setCredentials] = useState(credentialInitialState);
  const isMedspaActive = process.env.REACT_APP_MEDSPA_FIELDS === 'true';
  const isMedspaRole = roleName === MEDSPA_ADMIN_ROLE;
  const isMedspaProvider = Practitioners.isMedspaProvider();

  const adminRoutePerRole = () => {
    if (isMedspaActive && isMedspaRole) {
      if (isFirstLogin) {
        history.push(MEDSPA_ADMIN_INVENTORY);
      } else {
        history.push(MEDSPA_ADMIN_BASE_PATH);
      }
    } else if (hasAccessTo(READ_PRACTITIONER_SALES_DASHBOARD, permissions)) {
      history.push(ADMIN_PATIENTS_LIST_PATH);
    } else {
      history.push(ADMIN_INVENTORY_HOME_PATH);
    }
  };

  const onChangeInput = (event: any) => {
    const { name, value }: any = event.target;
    setCredentials((prevState) => ({ ...prevState, [name]: value }));
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();
    const { email, password } = credential;
    dispatch.auth.login({ email, password });
  };

  useEffect(() => {
    if (isSuccess && isAuthenticate) {
      setCredentials(credentialInitialState);

      const locationURL = new URL(window.location.href);
      let routeToUrl;
      const nextURL = locationURL.searchParams.get('nextURL');
      if (nextURL) {
        routeToUrl = nextURL;
      } else if (isMedspaProvider) {
        routeToUrl = MEDSPA_PROVIDER_PATIENTS;
      }
      if (routeToUrl) {
        history.push(routeToUrl);
        return;
      }

      switch (userType) {
        case PRACTITIONER_ROLE:
          history.push(PRACTITIONER_HOME_PATH);
          break;
        case ADMIN_ROLE:
          adminRoutePerRole();
          break;
        case PHYSICIAN_ROLE:
          history.push(PHYSICIAN_PROVIDERS_PATH);
          break;
        default:
          localStorage.clear();
          sessionStorage.clear();
          window.location.href = '/login';
          break;
      }
    }
  }, [isSuccess, userType, isMedspaProvider, isFirstLogin]);

  const getErrorMessage = (message: string) => {
    if (message === INACTIVE_LITE_MEDSPA) {
      return (
        <Banner
          content={
            <Box maxWidth="30rem">
              Your account is being reviewed and a sales representative will get in touch with you shortly to activate
              it. If you prefer to set up a call with our sales team directly please{' '}
              <a href={SALES_ROUND_ROBIN}>CLICK HERE</a>.
            </Box>
          }
          type="warning"
        />
      );
    }
    return <Alert severity="error">{message}</Alert>;
  };

  return (
    <>
      <Container component="main" className={classes.container}>
        <div className={classes.paper}>
          <div className={classes.logoContainer}>
            <PortraitLogo />
          </div>
          <form className={classes.form} onSubmit={onSubmit} autoComplete="off">
            <EHRTextField
              dataCy="input-emailAddress"
              value={credential.email}
              onChange={onChangeInput}
              required
              fullWidth
              label="Email Address"
              placeholder="Your email"
              name="email"
              autoFocus
              data-cy="loginEmailInput"
            />
            <EHRTextField
              dataCy="input-password"
              value={credential.password}
              onChange={onChangeInput}
              required
              fullWidth
              name="password"
              label="Password"
              placeholder="Your password"
              type="password"
              data-cy="loginPasswordInput"
            />
            <EHRButton
              dataCy="btn-login"
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              data-cy="loginSubmitButton"
              text={isLoading ? 'Loading...' : 'Sign In'}
            />
          </form>
          {isSuccess === false && getErrorMessage(messageError)}
          <Link href="/reset-password">Forgot password?</Link>
        </div>
      </Container>
      {process.env.REACT_APP_ENVIRONMENT !== 'PRODUCTION' && <Footer />}
    </>
  );
};

export default connect(mapState, mapDispatch)(Login);
