import {
  Box,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Slide,
  Switch,
  withStyles,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { IOrderProductAdmin } from 'src/services/Products';
import { EHRButton, EHRSelect, EHRTextField } from 'src/components/ui/v1';
import { ClassNameMap } from 'src/types/Dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import useSuppliers from 'src/hooks/queries/useSuppliers';
import ISuppliers from 'src/interfaces/ISuppliers';
import { useCreateProduct, useDestroyProduct, useUpdateProduct } from 'src/hooks/queries/useProducts';
import OrderLoading from 'src/components/DashboardPractitioner/Tabs/OrderingTab/OrderLoading';
import { SUPPLIER } from 'src/constants/schemaForm.constants';
import DialogModal from 'src/components/common/DialogModal';
import { schemaForm } from './schemaForm';

const CreateOrEditProductForm: React.FC<ClassNameMap & { row?: IOrderProductAdmin; onSave?: () => void }> = ({
  classes,
  row,
  onSave,
}) => {
  const [saving, setSaving] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const editing = !!row?.id;

  const { register, control, handleSubmit, errors, setValue } = useForm<Partial<IOrderProductAdmin>>({
    resolver: yupResolver(schemaForm),
    defaultValues: {
      name: undefined,
      description: '',
      listPrice: 0,
      minimumOrderQuantity: 1,
      itemCount: 1,
      unitsPerItem: 1,
      supplierId: undefined,
      currentCost: 0,
      currentCostWithGuaranteedRebates: 0,
      currentCostWithAllRebates: 0,
      legacyPrice: 0,
      legacyPlusPrice: 0,
      flexPrice: 0,
      launchPrice: 0,
      ascendPrice: 0,
      active: true,
    },
  });

  const { data: suppliers, isLoading: isLoadingSuppliers } = useSuppliers();
  const { mutateAsync: createProduct } = useCreateProduct();
  const { mutateAsync: updateProduct } = useUpdateProduct(row?.id ?? 0);
  const { mutateAsync: destroyProduct } = useDestroyProduct(row?.id ?? 0);

  const handleSave = async (product: IOrderProductAdmin) => {
    setSaving(true);
    try {
      if (editing) {
        await updateProduct(product);
      } else {
        await createProduct(product);
      }
    } finally {
      setSaving(false);
      onSave?.();
    }
  };

  const handleDestroy = async () => {
    setSaving(true);
    try {
      destroyProduct();
    } finally {
      setSaving(false);
      setDialogOpen(false);
      onSave?.();
    }
  };

  useEffect(() => {
    if (editing) {
      const {
        name,
        description,
        listPrice,
        minimumOrderQuantity,
        itemCount,
        unitsPerItem,
        supplierId,
        currentCost,
        currentCostWithGuaranteedRebates,
        currentCostWithAllRebates,
        legacyPrice,
        legacyPlusPrice,
        flexPrice,
        launchPrice,
        ascendPrice,
        active,
      } = row ?? {};

      setValue('name', name);
      setValue('description', description);
      setValue('listPrice', listPrice);
      setValue('minimumOrderQuantity', minimumOrderQuantity);
      setValue('itemCount', itemCount);
      setValue('unitsPerItem', unitsPerItem);
      setValue('supplierId', supplierId);
      setValue('currentCost', currentCost);
      setValue('currentCostWithGuaranteedRebates', currentCostWithGuaranteedRebates);
      setValue('currentCostWithAllRebates', currentCostWithAllRebates);
      setValue('legacyPrice', legacyPrice);
      setValue('legacyPlusPrice', legacyPlusPrice);
      setValue('flexPrice', flexPrice);
      setValue('launchPrice', launchPrice);
      setValue('ascendPrice', ascendPrice);
      setValue('active', active);
    }
  }, [row]);

  const numericInputProps = {
    type: 'number',
    inputProps: { step: 0.01 },
  };

  return (
    <Box className={classes?.form} component="form" onSubmit={handleSubmit(handleSave)}>
      <Grid item xs={12} lg={12}>
        <Grid item xs={12} lg={12}>
          <EHRTextField
            dataCy="nameInput"
            fullWidth
            name="name"
            id="name"
            error={!!errors.name}
            helperText={errors.name?.message || ''}
            onChange={(event) => setValue('name', event.target.value)}
            inputRef={register}
            label="Name*"
          />
        </Grid>
        <Box display="flex" flexDirection="row" marginTop="20px">
          <Grid item xs={12} lg={12}>
            <EHRTextField
              dataCy="descriptionInput"
              fullWidth
              name="description"
              id="description"
              error={!!errors.description}
              helperText={errors.description?.message || ''}
              onChange={(event) => setValue('description', event.target.value)}
              inputRef={register}
              label="Description*"
            />
          </Grid>
        </Box>
        <Grid item xs={12} lg={12}>
          <FormControl variant="outlined" className={classes?.formControl} error={!!errors.supplierId}>
            <Controller
              name="supplierId"
              control={control}
              defaultValue=""
              render={({ onChange, value }) => (
                <EHRSelect
                  dataCy="supplierId"
                  labelId="supplier-id"
                  label="Supplier*"
                  id="supplier-id"
                  value={value}
                  className={classes?.select}
                  disabled={isLoadingSuppliers}
                  onChange={onChange}
                >
                  {suppliers?.map(({ id: supplierId, name }: ISuppliers) => (
                    <MenuItem data-cy="supplier-item" key={supplierId} value={supplierId}>
                      {name}
                    </MenuItem>
                  ))}
                </EHRSelect>
              )}
            />
            {errors?.supplierId?.message && <FormHelperText color="red">{SUPPLIER}</FormHelperText>}
          </FormControl>
        </Grid>

        <Divider className={classes?.divider} />
        <Box display="flex" flexDirection="row" marginTop="10px" data-shrinklabel>
          <Grid item xs={4} lg={4}>
            <EHRTextField
              dataCy="minimumOrderQuantityInput"
              fullWidth
              name="minimumOrderQuantity"
              id="minimumOrderQuantity"
              error={!!errors.minimumOrderQuantity}
              helperText={errors.minimumOrderQuantity?.message || ''}
              onChange={(event) => setValue('minimumOrderQuantity', +event.target.value)}
              inputRef={register}
              label="Minimum Order Quantity*"
              type="number"
            />
          </Grid>
          <Box display="flex" flexDirection="row" margin="auto 0" data-shrinklabel>
            <Grid item xs={4} lg={4}>
              <FormControlLabel
                control={
                  <Controller
                    control={control}
                    inputRef={register}
                    name="active"
                    render={({ onChange, value }: any) => (
                      <Switch
                        data-cy="activeCheckbox"
                        onChange={(e) => onChange(e.target.checked)}
                        checked={value}
                        className={classes?.checkbox}
                        color="primary"
                      />
                    )}
                  />
                }
                label="Active"
              />
            </Grid>
          </Box>
        </Box>
        <Divider className={classes?.divider} />
        <Box display="flex" flexDirection="row" marginTop="10px" data-shrinklabel>
          <Grid item xs={4} lg={4}>
            <EHRTextField
              dataCy="currentCostInput"
              fullWidth
              name="currentCost"
              id="currentCost"
              error={!!errors.currentCost}
              helperText={errors.currentCost?.message || ''}
              onChange={(event) => setValue('currentCost', +event.target.value)}
              inputRef={register}
              label="Cost*"
              {...numericInputProps}
            />
          </Grid>
          <Grid item xs={4} lg={4}>
            <EHRTextField
              dataCy="currentCostWithGuaranteedRebatesInput"
              fullWidth
              name="currentCostWithGuaranteedRebates"
              id="currentCostWithGuaranteedRebates"
              error={!!errors.currentCostWithGuaranteedRebates}
              helperText={errors.currentCostWithGuaranteedRebates?.message || ''}
              onChange={(event) => setValue('currentCostWithGuaranteedRebates', +event.target.value)}
              inputRef={register}
              label="Cost w/ Guaranteed Rebates*"
              {...numericInputProps}
            />
          </Grid>
          <Grid item xs={4} lg={4}>
            <EHRTextField
              dataCy="currentCostWithAllRebatesInput"
              fullWidth
              name="currentCostWithAllRebates"
              id="currentCostWithAllRebates"
              error={!!errors.currentCostWithAllRebates}
              helperText={errors.currentCostWithAllRebates?.message || ''}
              onChange={(event) => setValue('currentCostWithAllRebates', +event.target.value)}
              inputRef={register}
              label="Cost w/ All Rebates*"
              {...numericInputProps}
            />
          </Grid>
        </Box>
        <Divider className={classes?.divider} />
        <Box display="flex" flexDirection="row" marginTop="10px" data-shrinklabel>
          <Grid item xs={4} lg={4}>
            <EHRTextField
              dataCy="listPriceInput"
              fullWidth
              name="listPrice"
              id="listPrice"
              error={!!errors.listPrice}
              helperText={errors.listPrice?.message || ''}
              onChange={(event) => setValue('listPrice', +event.target.value)}
              inputRef={register}
              label="List Price*"
              {...numericInputProps}
            />
          </Grid>
          <Grid item xs={4} lg={4}>
            <EHRTextField
              dataCy="legacyPriceInput"
              fullWidth
              name="legacyPrice"
              id="legacyPrice"
              error={!!errors.legacyPrice}
              helperText={errors.legacyPrice?.message || ''}
              onChange={(event) => setValue('legacyPrice', +event.target.value)}
              inputRef={register}
              label="Legacy Price*"
              {...numericInputProps}
            />
          </Grid>
          <Grid item xs={4} lg={4}>
            <EHRTextField
              dataCy="legacyPlusPriceInput"
              fullWidth
              name="legacyPlusPrice"
              id="legacyPlusPrice"
              error={!!errors.legacyPlusPrice}
              helperText={errors.legacyPlusPrice?.message || ''}
              onChange={(event) => setValue('legacyPlusPrice', +event.target.value)}
              inputRef={register}
              label="Legacy Plus Price*"
              {...numericInputProps}
            />
          </Grid>
        </Box>
        <Box display="flex" flexDirection="row" marginTop="10px" data-shrinklabel>
          <Grid item xs={4} lg={4}>
            <EHRTextField
              dataCy="flexPriceInput"
              fullWidth
              name="flexPrice"
              id="flexPrice"
              error={!!errors.flexPrice}
              helperText={errors.flexPrice?.message || ''}
              onChange={(event) => setValue('flexPrice', +event.target.value)}
              inputRef={register}
              label="Flex Price*"
              {...numericInputProps}
            />
          </Grid>
          <Grid item xs={4} lg={4}>
            <EHRTextField
              dataCy="launchPriceInput"
              fullWidth
              name="launchPrice"
              id="launchPrice"
              error={!!errors.launchPrice}
              helperText={errors.launchPrice?.message || ''}
              onChange={(event) => setValue('launchPrice', +event.target.value)}
              inputRef={register}
              label="Launch Price*"
              {...numericInputProps}
            />
          </Grid>
          <Grid item xs={4} lg={4}>
            <EHRTextField
              dataCy="ascendPriceInput"
              fullWidth
              name="ascendPrice"
              id="ascendPrice"
              error={!!errors.ascendPrice}
              helperText={errors.ascendPrice?.message || ''}
              onChange={(event) => setValue('ascendPrice', +event.target.value)}
              inputRef={register}
              label="Ascend Price*"
              {...numericInputProps}
            />
          </Grid>
        </Box>
      </Grid>
      <Box display="flex" flexDirection="column" margin={0} paddingTop={5}>
        <EHRButton
          dataCy="createOrEditProduct.form.cancel"
          text="Cancel"
          color="default"
          className={classes?.button}
          data-testid="createOrEditProduct.form.cancel"
          disabled={saving}
          onClick={onSave}
        />
        <EHRButton
          dataCy="createOrEditProduct.form.save"
          text="Save"
          color="primary"
          className={classes?.button}
          data-testid="createOrEditProduct.form.save"
          disabled={isLoadingSuppliers || saving}
          type="submit"
        />
        {editing && (
          <EHRButton
            dataCy="createOrEditProduct.form.delete"
            text="Delete"
            color="danger"
            className={classes?.button}
            data-testid="createOrEditProduct.form.delete"
            disabled={isLoadingSuppliers || saving}
            onClick={() => setDialogOpen(true)}
          />
        )}
        <DialogModal
          open={dialogOpen}
          confirmationText={`Are you sure you want to delete ${row?.name ?? 'this product'}`}
          closeModal={() => setDialogOpen(false)}
          confirm={handleDestroy}
        >
          <></>
        </DialogModal>
        {saving && <OrderLoading />}
      </Box>
    </Box>
  );
};

const CreateOrEditProduct: React.FC<
ClassNameMap & { row?: IOrderProductAdmin; open?: boolean; dismiss?: () => void }
> = ({ classes, row, dismiss, open = true }) => (
  <Dialog
    onBackdropClick={dismiss}
    open={open}
    classes={classes}
    PaperProps={{ className: classes?.paper, elevation: 0 }}
  >
    <Slide direction="left" enter in={open} exit timeout={{ enter: 500, exit: 100 }}>
      <Box className={classes?.box}>
        <CreateOrEditProductForm classes={classes} row={row} onSave={dismiss} />
      </Box>
    </Slide>
  </Dialog>
);

export default withStyles({
  paper: {
    position: 'absolute',
    right: 0,
    top: 10,
    width: '33%',
    minWidth: 450,
    backgroundColor: 'transparent',
  },
  box: {
    width: '100%',
    backgroundColor: '#fff',
    padding: '35px 25px',
    '& input[type="number"]': {
      maxWidth: '50%',
    },
    '& input[type="number"]:last-child': {
      marginLeft: '5%',
    },
    '& div[data-shrinklabel="true"] label': {
      fontSize: '9px !important',
    },
  },
  formControl: {
    width: '100%',
    marginTop: 25,
    marginBottom: 25,
  },
  select: {
    width: '100%',
  },
  button: {
    width: '100%',
    margin: 'auto',
    marginTop: 15,
  },
  divider: {
    marginTop: 15,
    marginBottom: 20,
  },
})(CreateOrEditProduct);
