/* eslint-disable max-len */

export const MEDICAL_DIRECTOR_AGREEMENT = `
    <h3 style="text-align: center">Medical Director Controlled Substances Ordering Authorization and Attestation</h3>
    <p>Counterparty and Portrait hereby mutually agree to the following terms regarding the ordering of any controlled substances.</p>
    <ol>
        <li>I serve as the Medical Director for Counterparty and have an active collaborative and oversight relationship with any licensed medical providers who will be administering or distributing controlled substances used in aesthetics, wellness, and weight loss medical practices such as injectable hyaluronic acid and neuromodulators. I am the Medical Director responsible for overseeing the procurement and usage of these substances.</li>
        
        <li>Portrait&apos;s affiliated medical entities that procure controlled substances are: DermDocs PC, Portrait Health Care PLLC, and Portrait Health Care New Jersey PC. As a Medical Director collaborating with one or more of these entities to procure substances and oversee a medical practice utilizing Portrait’s platform, I attest that I have an active medical license in good standing with all applicable medical boards and regulatory agencies, and that all patient care will be administered in accordance with any applicable federal, state, or local laws, rules, and regulations. In the event I cease to operate in this capacity for Counterparty, Amendment will automatically terminate, and Counterparty will immediately notify Portrait. Counterparty will not order any controlled substances from Portrait unless there is an active Medical Director Controlled Substances Ordering Authorization and Attestation Amendment on file.</li>
        
        <li>Portrait procures controlled substances that are used in typical aesthetics, wellness, and weight loss medical practices, such as neuromodulators and injectable hyaluronic acid. If there are any restrictions on the type of products that Counterparty is authorized to procure, please list those restrictions here:</li>
        
        <li>Medical Director License Number for Each Applicable State</li>
    </ol>
`;

export const REAL_ESTATE_MANAGEMENT_AGREEMENT = `
    <h3 style="text-align: center">REAL ESTATE BUSINESS MANAGEMENT AMENDMENT TO PORTRAIT MASTER SERVICES AGREEMENT</h3>
    <p>This amendment (the “Amendment”) is made as of the date included below (“Effective Date”) by and among Portrait Health Inc. (“Portrait”) and the professional practice entity identified in the signature block below (“Practice”).</p>
    <p>Practice and Portrait hereby mutually agree to the following terms regarding the physical location or locations that Practice will occupy:</p>
    <ol>
        <li>
          <p>Portrait will have business management responsibility, as defined in the Master Services Agreement, for all physical locations utilized by Practice to provide medical services during the term of, and pursuant to, the Master Services Agreement, subject to the limitations described herein.</p>
        </li>
        <li>
          <p>The physical location to be utilized by Practice to provide medical services is:</p>
          <p>"Address": ____________________________________________________________</p>
          <p>"Monthly Rent": ____________________________________________________________</p>
          <p>"Lease Start Date": ____________________________________________________________</p>
          <p>"Lease Term Length": ____________________________________________________________</p>
        </li>
        
        <li>In the event the lease of the physical location is modified or renewed in any way, or additional physical locations are leased to be utilized by Practice or subsequently modified or renewed in any way, “Location” will be updated to include all physical locations utilized by Practice, and the “Rent” amount will be updated to include the sum of the monthly rents for all physical locations utilized by Practice.</li>
        
        <li>Practice agrees that it will provide services to patients within Location pursuant to, and in accordance with, the terms set forth in the Master Services Agreement.</li>
        
        <li>
          In the event Practice terminates this Agreement and or the Master Services Agreement, this amendment will be terminated, and Practice shall complete the following within ten (10) days:
          <ol>
            <li>Complete any necessary steps required by landlord to fully release Portrait from all financial liability related to any Location lease; and</li>
            <li>Transition all Business Management responsibilities from Portrait to Practice; and</li>
            <li>refund Portrait for any payments or loans related to Location that Portrait incurred on its behalf and that have not yet been repaid.</li>
          </ol>
        </li>
    </ol>
`;

export const PAYMENT_AUTHORIZATION_AGREEMENT = `I authorize Portrait Health, Inc. to charge or fund my credit card, and initiate debit and credit entries to the bank accounts, that I enter. I represent that I have all the necessary authorities to authorize transactions to the credit card and bank account above and I acknowledge that all transactions initiated to the credit card or bank account must comply with all provisions of United States law. If the credit card or bank account is associated with an organization, I represent that I have authority to bind the organization that owns the credit card or bank account, and to authorize all transactions to the credit card or bank account above. I understand that my information will be saved to file for future transactions on my account.`;
