import { Box, Button, FormHelperText, MenuItem, TextField } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { IPhysician } from 'src/interfaces/IPhysician';
import { PORTRAIT_LEGACY, PORTRAIT_LEGACY_PLUS, USER_TYPES } from 'src/constants/general.constants';
import ICommission from 'src/interfaces/ICommission';
import { usePhysicians } from 'src/hooks/queries/usePhysicians';
import { useUpdateUserGroup } from 'src/hooks/queries/useUserGroups';
import IMedspa from 'src/interfaces/IMedspa.interface';
import { SERVICE_COMMISSION } from 'src/constants/schemaForm.constants';
import {
  EHRAutocompleteSelect,
  EHRButton,
  EHRCRUDModal,
  EHRSelect,
  EHRTextField,
  EHRTypography,
  IAutocompleteSelectOption,
} from 'src/components/ui/v1';
import { useSelector } from 'react-redux';
import { useStyles } from './MedspaModal.styles';
import { hasAccessTo } from '../../../utils/auth.utils';
import { IS_SUPPORT } from '../../../constants/actions.constants';
import { IRoles } from '../../../interfaces/IRoles.interfaces';
import { useCustomRoles } from '../../../hooks/queries/useCustomRoles';

const editMedspaSchema = yup.object().shape({
  medspaName: yup.string().required('MedSpa name is required field'),
  commissionId: yup.string().when('isLegacy', (value: any) => {
    if (value) {
      return yup.string().required(SERVICE_COMMISSION);
    }
    return yup.string().nullable();
  }),
  medicalDirectorId: yup
    .string()
    .when(
      ['hasMedicalDirector', 'customMedicalDirector'],
      (hasMedicalDirector: boolean, customMedicalDirector: string) => {
        if (hasMedicalDirector && !customMedicalDirector) {
          return yup.string().required('Medical director is a required field');
        }
        return yup.string().nullable();
      }
    ),
  customMedicalDirector: yup.string(),
  googleReviewLink: yup.string().nullable(),
});

export const EditMedspaModal = ({
  open,
  onClose,
  medspa,
  commissions,
}: {
  open: boolean;
  onClose: () => void;
  medspa?: IMedspa;
  commissions: ICommission[];
}) => {
  const classes = useStyles();
  const permissions = useSelector(({ auth }: any) => auth.permissions);
  const isLegacy = medspa?.roleName === PORTRAIT_LEGACY || medspa?.roleName === PORTRAIT_LEGACY_PLUS;
  const [hasMedicalDirector, setHasMedicalDirector] = useState<boolean>(
    !!medspa?.medicalDirectorId || !!medspa?.customMedicalDirector
  );
  const { control, errors, handleSubmit, setValue, getValues, watch } = useForm({
    resolver: yupResolver(editMedspaSchema),
    defaultValues: {
      id: medspa?.id,
      medspaName: medspa?.name,
      roleName: medspa?.roleName,
      roleId: medspa?.roleId,
      googleReviewLink: medspa?.googleReviewLink,
      commissionId: medspa?.servicesCommission,
      medicalDirectorId: medspa?.medicalDirectorId || '',
      hasMedicalDirector: !!medspa?.medicalDirectorId,
      customMedicalDirector: medspa?.customMedicalDirector,
      isLegacy,
      squareLocationId: medspa?.squareLocationId,
    },
  });
  const [medicalDirectorSearch, setMedicalDirectorSearch] = useState<string>('');
  const [medspaMedicalDirectorId, setMedspaMedicalDirectorId] = useState<string | number | undefined>(
    medspa?.medicalDirectorId
  );
  const [physicianParams, setPhysicianParams] = useState<Record<string, string | number | undefined>>({
    search: medicalDirectorSearch,
    ids: medspaMedicalDirectorId,
  });
  const { data: medicalDirectors = [], refetch, isLoading: isLoadingMedicalDirectors } = usePhysicians(physicianParams);
  const [selectedMedicalDirector, setSelectedMedicalDirector] = useState<IPhysician | undefined | null>();
  const { mutateAsync: updateGroup, isLoading: isUpdating } = useUpdateUserGroup();
  const currentMedicalDirector = useMemo(
    () => medicalDirectors?.find((medicalDirector) => medicalDirector.id === medspaMedicalDirectorId),
    [medspaMedicalDirectorId, medicalDirectors]
  );
  const watchCustomMedicalDirector = watch(['customMedicalDirector']);
  const canEditMedspaRole = hasAccessTo(IS_SUPPORT, permissions);
  const { data: roles = [] as IRoles[] } = useCustomRoles({ userType: USER_TYPES.PRACTITIONER });

  useEffect(() => {
    if (medspaMedicalDirectorId) {
      setPhysicianParams({ ids: medspaMedicalDirectorId });
    } else {
      setPhysicianParams({ search: medicalDirectorSearch });
    }
  }, [medicalDirectorSearch, medspaMedicalDirectorId]);

  useEffect(() => {
    if (medspa && getValues('id') !== medspa.id) {
      setValue('id', medspa.id);
      setValue('medspaName', medspa.name);
      setValue('roleName', medspa.roleName);
      setValue('roleId', medspa.roleId);
      setValue('googleReviewLink', medspa.googleReviewLink);
      setValue('commissionId', medspa.servicesCommission);
      setValue('customMedicalDirector', medspa.customMedicalDirector ?? '');
    }
    setMedspaMedicalDirectorId(medspa?.medicalDirectorId);
    setHasMedicalDirector(!!medspa?.customMedicalDirector);
  }, [medspa, open]);

  useEffect(() => {
    if (currentMedicalDirector && !medspa?.customMedicalDirector) {
      setSelectedMedicalDirector(currentMedicalDirector);
      setHasMedicalDirector(!!currentMedicalDirector);
      setValue('medicalDirectorId', currentMedicalDirector.id);
      setValue('hasMedicalDirector', !!currentMedicalDirector);
    } else if (!medspa?.customMedicalDirector) {
      setSelectedMedicalDirector(null);
      setHasMedicalDirector(false);
      setValue('medicalDirectorId', '');
      setValue('hasMedicalDirector', false);
    }
  }, [currentMedicalDirector, medspa?.customMedicalDirector, open]);

  const handleClose = () => {
    setMedicalDirectorSearch('');
    setMedspaMedicalDirectorId(undefined);
    onClose();
    refetch();
  };

  const handleSelectMedicalDirector = (medicalDirectorOption: IAutocompleteSelectOption | null) => {
    if (!medicalDirectorOption) {
      setMedspaMedicalDirectorId(undefined);
      setSelectedMedicalDirector(null);
      setPhysicianParams({});
      setValue('medicalDirectorId', undefined);
      return;
    }
    const medicalDirectorIndex = medicalDirectors.findIndex((e: IPhysician) => e.id === medicalDirectorOption.value);
    if (medicalDirectorIndex < 0) {
      return;
    }
    const medicalDirector = medicalDirectors[medicalDirectorIndex];
    setMedspaMedicalDirectorId(medicalDirector.id);
    setSelectedMedicalDirector(medicalDirector);
    setMedicalDirectorSearch('');
    setPhysicianParams({});
    setValue('medicalDirectorId', String(medicalDirector.id));
    setValue('customMedicalDirector', '');
  };

  const onSubmit = async (formData: any) => {
    const data = {
      id: medspa?.id,
      name: formData.medspaName,
      googleReviewLink: formData.googleReviewLink,
      medicalDirectorId:
        hasMedicalDirector && !formData.customMedicalDirector ? formData.medicalDirectorId || null : null,
      commissionId: formData.commissionId,
      roleId: formData.roleId,
      customMedicalDirector: formData.customMedicalDirector,
    };
    await updateGroup(data);
    handleClose();
  };

  if (!medspa) {
    return null;
  }

  return (
    <EHRCRUDModal dataCy="modal-edit-medspa" open={open} onClose={handleClose}>
      <EHRCRUDModal.Title dataCy="modal-edit-medspa-modal-title" title="Edit MedSpa" handleClose={handleClose} />
      <EHRCRUDModal.Body dataCy="modal-edit-medspa-modal-body">
        <Box display="flex" justifyContent="space-between" flexDirection="column" gridGap="0.25rem" width="100%">
          <Box>
            <Controller
              control={control}
              name="roleId"
              rules={{ required: 'Product type is required' }}
              render={({ onChange, value }) => (
                <EHRSelect
                  dataCy="select-commissionId"
                  name="commissionId"
                  fullWidth
                  value={value}
                  onChange={onChange}
                  disabled={!canEditMedspaRole}
                  label="Product Type"
                  error={!!errors?.roleId}
                  helperText={errors?.roleId?.message || ' '}
                >
                  {roles
                    .filter(
                      (role) =>
                        (role.name !== PORTRAIT_LEGACY && role.name !== PORTRAIT_LEGACY_PLUS) ||
                        role.id === medspa.roleId
                    )
                    .map((role: IRoles) => (
                      <MenuItem data-cy="role-item" key={role.id} value={role.id}>
                        {role.name}
                      </MenuItem>
                    ))}
                </EHRSelect>
              )}
            />
          </Box>

          <Box>
            <Controller
              control={control}
              name="medspaName"
              rules={{ required: 'Name is required' }}
              render={({ onChange, value }) => (
                <EHRTextField
                  dataCy="input-medspaName"
                  name="medspaName"
                  onChange={onChange}
                  value={value}
                  label="MedSpa Name"
                  error={!!errors?.medspaName}
                  helperText={errors?.medspaName?.message || ' '}
                />
              )}
            />
          </Box>

          {isLegacy && (
            <Box>
              <Controller
                control={control}
                name="commissionId"
                rules={{ required: 'Commission is required' }}
                render={({ onChange, value }) => (
                  <EHRSelect
                    dataCy="select-commissionId"
                    name="commissionId"
                    fullWidth
                    value={value}
                    onChange={onChange}
                    label="Commission"
                    error={!!errors?.commissionId}
                    helperText={errors?.commissionId?.message || ' '}
                  >
                    {commissions && commissions.length ? (
                      commissions
                        .sort((a, b) => {
                          const commissionRateA = +a.shortDescription.replace('%', '');
                          const commissionRateB = +b.shortDescription.replace('%', '');
                          if (commissionRateB <= commissionRateA) {
                            return 1;
                          }
                          return -1;
                        })
                        .map(({ id, shortDescription }) => <MenuItem value={id}>{shortDescription}</MenuItem>)
                    ) : (
                      <MenuItem value="--">No items found</MenuItem>
                    )}
                  </EHRSelect>
                )}
              />
            </Box>
          )}
          <Box display="flex" flexDirection="row" gridGap="1rem">
            <Box flex={1}>
              <Controller
                control={control}
                name="googleReviewLink"
                render={({ onChange, value }) => (
                  <EHRTextField
                    dataCy="input-googleReviewLink"
                    name="googleReviewLink"
                    onChange={onChange}
                    value={value}
                    label="Google Review Link"
                    error={!!errors?.googleReviewLink}
                    helperText={errors?.googleReviewLink?.message || ' '}
                  />
                )}
              />
            </Box>

            <Box flex={1}>
              <Controller
                control={control}
                name="squareLocationId"
                render={({ onChange, value }) => (
                  <EHRTextField
                    dataCy="input-squareLocationId"
                    name="squareLocationId"
                    onChange={onChange}
                    value={value}
                    label="Square Location ID"
                    disabled
                  />
                )}
              />
            </Box>
          </Box>

          <Box>
            <Box className={classes.medspaInfoInput}>
              <EHRTypography dataCy="label-medspa-have-medical-director" variant="label">
                Have Medical Director?
              </EHRTypography>
              <Box className={classes.medspaActiveStatusContainer}>
                <Button
                  className={hasMedicalDirector ? classes.medspaActiveButton : classes.medspaInactiveButton}
                  type="button"
                  onClick={() => {
                    setHasMedicalDirector(true);
                    setValue('hasMedicalDirector', true);
                  }}
                >
                  Yes
                </Button>
                <Button
                  className={hasMedicalDirector ? classes.medspaInactiveButton : classes.medspaActiveButton}
                  type="button"
                  onClick={() => {
                    setHasMedicalDirector(false);
                    setValue('hasMedicalDirector', false);
                    setValue('customMedicalDirector', '');
                  }}
                >
                  No
                </Button>
              </Box>
              <FormHelperText error>{errors?.medicalDirectorId?.message || ' '}</FormHelperText>
            </Box>
          </Box>

          {hasMedicalDirector && (
            <Box>
              <EHRAutocompleteSelect
                dataCy="autocompleteselect-medical-director"
                options={medicalDirectors.map((p) => ({
                  name: p.professionalName || '-',
                  value: p.id,
                }))}
                openOnFocus
                clearOnEscape
                label="Medical Director"
                onChange={handleSelectMedicalDirector}
                inputValue={medicalDirectorSearch}
                onChangeDebounceSearch={(newInputValue) => {
                  setMedicalDirectorSearch(newInputValue);
                }}
                loading={isLoadingMedicalDirectors}
                getOptionLabel={(option: IAutocompleteSelectOption) => `${option.name}`}
                fullWidth
                renderInput={(params: any) => (
                  <TextField {...params} label="Search Medical Director" variant="outlined" />
                )}
                selectedOption={
                  selectedMedicalDirector
                    ? {
                      name: selectedMedicalDirector.professionalName || '-',
                      value: selectedMedicalDirector.id,
                    }
                    : undefined
                }
              />

              <Box>
                <h4>OR</h4>
              </Box>

              <Controller
                control={control}
                name="customMedicalDirector"
                render={() => (
                  <EHRTextField
                    dataCy="input-customMedicalDirector"
                    name="customMedicalDirector"
                    onChange={(e: any) => {
                      setValue('medicalDirectorId', '');
                      setSelectedMedicalDirector(null);
                      setValue('customMedicalDirector', e.target.value);
                    }}
                    value={watchCustomMedicalDirector.customMedicalDirector}
                    error={!!errors?.customMedicalDirector}
                    helperText={errors?.customMedicalDirector?.message || ' '}
                  />
                )}
              />
            </Box>
          )}
        </Box>
      </EHRCRUDModal.Body>

      <EHRCRUDModal.Footer dataCy="modal-footer-new-medspa">
        <Box display="flex" width="100%">
          <EHRButton
            dataCy="btn-add-modal-edit-medspa"
            onClick={handleSubmit(onSubmit)}
            disabled={isUpdating}
            color="primary"
            text={isUpdating ? 'Updating...' : 'Update'}
            fullWidth
          />
        </Box>
      </EHRCRUDModal.Footer>
    </EHRCRUDModal>
  );
};
