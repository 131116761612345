/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import { Box, IconButton, TableRow, TableCell, TextField } from '@material-ui/core';
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons';
import { formatCurrency } from 'src/utils/formatNumber';
import PercentChip from 'src/components/common/PercentChip';
import formatDate from 'src/utils/formatDate';
import {
  useUpdateInventoryOrderSpecialItem,
  useDeleteInventoryOrderSpecialItem,
} from 'src/hooks/queries/useInventoryOrders';
import EditIcon from 'src/components/common/icons/EditIcon';
import compile from 'src/utils/toastMessagesCompiler';
import { dispatch } from 'src/rematch';
import InventoryOrderSpecialCreateModal from './InventoryOrderSpecialCreateModal';
import { useStyle } from './inventoryOrder.styles';
import { OrderContext } from '.';

const InventorySpecialOfferRow = ({
  specialOfferId,
  inventoryOrderSpecialId,
  inventoryOrderSpecialProductList,
  specialDescription,
  specialName,
  marketPriceLabel,
  youSaveLabel,
  youPayLabel,
  expirationDate,
  inventoryOrderId,
  enabled,
  edit = true,
}: {
  enabled: boolean;
  specialOfferId: number;
  specialName: string;
  inventoryOrderSpecialId: number | null;
  inventoryOrderSpecialProductList: string;
  specialDescription: string;
  marketPriceLabel: number;
  youSaveLabel: number;
  youPayLabel: number;
  expirationDate: Date | string;
  inventoryOrderId: number;
  edit?: boolean;
}) => {
  const classes = useStyle();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [userQuantity, setUserQuantity] = useState<number>(0);
  const [isLoadingRow, setIsLoadingRow] = useState<boolean>(false);

  const { isFetching } = useContext(OrderContext) || {};

  const { mutateAsync: updateOrderItem } = useUpdateInventoryOrderSpecialItem({
    inventoryOrderId,
    specialOfferId,
  });
  const deleteInventoryOrderSpecial = useDeleteInventoryOrderSpecialItem(inventoryOrderSpecialId ?? 0);

  const isUpdating = (isLoadingRow || isFetching) && userQuantity !== undefined;

  const handleUpdateSpecialOffer = async (productList: string) => {
    try {
      await updateOrderItem({
        discount: youSaveLabel,
        price: youPayLabel,
        productList,
      });
      setOpenModal(false);
    } catch (error) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: compile('generic.error_message', {
            action: 'updating',
            element: 'inventory order special',
          }),
          type: 'error',
        },
      });
    }
  };

  const addItem = async () => {
    if (!inventoryOrderSpecialId && enabled) {
      setOpenModal(true);
    }
  };

  const removeItem = async () => {
    setIsLoadingRow(true);
    await deleteInventoryOrderSpecial.mutate();

    setUserQuantity(0);
    setIsLoadingRow(false);
  };

  useEffect(() => {
    setUserQuantity(inventoryOrderSpecialId ? 1 : 0);
  }, [inventoryOrderSpecialId]);

  const cells = [
    {
      className: classes.nameCol,
      testId: 'description',
      content: <>{specialDescription}</>,
    },
    {
      className: classes.centerCell,
      testId: `market price`,
      content: formatCurrency(marketPriceLabel),
    },
    {
      className: classes.centerCell,
      testId: `you save`,
      content: (
        <>
          {formatCurrency(youSaveLabel)}
          <PercentChip number={Math.round((youSaveLabel * 100) / marketPriceLabel)} />
        </>
      ),
    },
    {
      className: classes.centerCell,
      testId: `pay price`,
      content: formatCurrency(youPayLabel),
    },
    {
      className: classes.centerCell,
      testId: `expiration date`,
      content: formatDate(expirationDate),
    },
  ];

  return (
    <>
      <TableRow className={[classes.recRow, classes.pulse2].join(' ')} data-loading={isUpdating}>
        {cells.map(({ className, testId, content }) => (
          <TableCell style={{ verticalAlign: 'top' }} key={testId} className={className}>
            {content}
          </TableCell>
        ))}

        <TableCell style={{ verticalAlign: 'top' }}>
          <Box className={classes.inputCell} width="min-content">
            {edit && (
              <IconButton
                disabled={!inventoryOrderSpecialId || isLoadingRow}
                onClick={removeItem}
                style={{
                  borderRadius: '6px',
                  width: '32px',
                  height: '32px',
                  backgroundColor: '#E8EEED',
                  color: '#1D584D',
                }}
              >
                <RemoveIcon />
              </IconButton>
            )}

            <TextField
              value={userQuantity}
              InputProps={{
                readOnly: true,
              }}
              className={classes.orderInput}
              variant="outlined"
              type="number"
              disabled={!edit}
            />
            {edit && (
              <IconButton
                disabled={isLoadingRow}
                onClick={addItem}
                style={{
                  borderRadius: '6px',
                  width: '32px',
                  height: '32px',
                  backgroundColor: '#E8EEED',
                  color: '#1D584D',
                }}
              >
                <AddIcon />
              </IconButton>
            )}
          </Box>
        </TableCell>
      </TableRow>
      {inventoryOrderSpecialProductList && (
        <TableRow>
          <TableCell colSpan={cells.length + 1}>
            <Box width="100%" display="flex" flexDirection="column">
              <Box data-large="true">Your Chosen Products:</Box>
              <Box display="flex" justifyContent="space-between" alignItems="flex-start" width="100%">
                <Box className={classes.nameCol} flexGrow={1}>
                  {inventoryOrderSpecialProductList}
                </Box>
                <Box display="flex" alignItems="center" onClick={() => setOpenModal(true)}>
                  <IconButton
                    style={{
                      borderRadius: '6px',
                      width: '32px',
                      height: '32px',
                      backgroundColor: '#E8EEED',
                      color: '#1D584D',
                      marginRight: '8px',
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  Edit Items
                </Box>
              </Box>
            </Box>
          </TableCell>
        </TableRow>
      )}
      <InventoryOrderSpecialCreateModal
        onUpdateSpecialOffer={handleUpdateSpecialOffer}
        inventoryOrderSpecialProductList={inventoryOrderSpecialProductList}
        specialName={specialName}
        specialDescription={specialDescription}
        inventoryOrderId={inventoryOrderId}
        specialOfferId={specialOfferId}
        youPayLabel={youPayLabel}
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
};

export default InventorySpecialOfferRow;
