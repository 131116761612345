import React, { useState } from 'react';
import { object, string } from 'yup';

import { Box, Modal } from '@material-ui/core';
import classNames from 'classnames';
import { copyToClipboardWithCallbacks } from 'src/utils/clipboard.utils';
import { IRoles } from '../../interfaces/IRoles.interfaces';
import { useStyles } from '../DashboardAdministrator/Practitioners/Practitioner.styles';
import { EHRTextField } from '../ui/v1';
import { PRODUCT_TYPE_NAME_MAP } from '../../constants/general.constants';

interface InvitationModalProps {
  open: boolean;
  onClose: () => void;
  invitationToken?: string;
  invitationUrl: string;
  generateInvitationLink: (value: string) => void;
  selectedLinkRole?: IRoles;
  setSelectedLinkRole?: (role: IRoles | undefined) => void;
  roles?: IRoles[];
  showEmailInput?: boolean;
}

const emailSchema = object({
  email: string().email().required(),
});

const InvitationModal: React.FC<InvitationModalProps> = ({
  open,
  onClose,
  invitationToken,
  invitationUrl,
  generateInvitationLink,
  selectedLinkRole,
  setSelectedLinkRole,
  roles = [],
  showEmailInput = false,
}) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState<boolean>(false);

  const showRoleSelection = roles.length && setSelectedLinkRole;

  const onCloseCallback = () => {
    setEmail('');
    setEmailError(false);
    onClose();
  };

  const copyURLToClipboard = () => copyToClipboardWithCallbacks(invitationUrl, onCloseCallback);
  const handleGenerateLink = () => generateInvitationLink(showEmailInput ? email : '');

  const validateEmail = async (v: string) => {
    try {
      await emailSchema.validate({ email: v });
      setEmailError(false);
    } catch (error) {
      setEmailError(true);
    }
  };

  const handleEmailChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailError(false);
    const newEmail = e.target.value;
    setEmail(newEmail);
    await validateEmail(newEmail);
  };

  return (
    <Modal open={open} onClose={onCloseCallback} className={classes.modal}>
      <Box className={classes.modalContent}>
        <Box>
          {invitationToken ? (
            <>
              <h2>Invitation URL</h2>
              <p>{invitationUrl}</p>
              <div>
                <button className={classes.actionButton} type="button" onClick={copyURLToClipboard}>
                  Copy
                </button>
              </div>
            </>
          ) : (
            <>
              <h2>Create Invitation URL</h2>

              {showEmailInput && (
                <Box marginBottom="2rem">
                  <EHRTextField
                    label="Email"
                    value={email}
                    name="email"
                    dataCy="input-email"
                    data-cy="email"
                    onChange={handleEmailChange}
                    error={emailError}
                    helperText={emailError ? 'Invalid email' : ''}
                  />
                </Box>
              )}

              {showRoleSelection && (
                <div className={classes.generateLinkButtonsContainer}>
                  {roles.map((role) => (
                    <button
                      key={role.name}
                      type="button"
                      className={classNames(classes.generateLinkOption, {
                        [classes.activeLinkOption]: selectedLinkRole === role,
                      })}
                      onClick={() => setSelectedLinkRole?.(role)}
                    >
                      {PRODUCT_TYPE_NAME_MAP[role.name]}
                    </button>
                  ))}
                </div>
              )}
              <button
                type="button"
                className={classes.createLinkButton}
                disabled={(showRoleSelection && !selectedLinkRole) || (showEmailInput && (!email || emailError))}
                onClick={handleGenerateLink}
              >
                Generate Invitation Link
              </button>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default InvitationModal;
