import React, { useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../rematch';

const useStyles = makeStyles((theme) => ({
  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderTop: `1px solid ${theme.palette.divider}`,
    fontSize: '0.8rem',
    textAlign: 'center',
  },
}));

const Footer: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch<Dispatch>();
  const { healthData, error } = useSelector((state: RootState) => state.health);

  useEffect(() => {
    dispatch.health.fetchHealthData();
  }, [dispatch.health]);

  if (error) {
    return (
      <Box className={classes.footer}>
        <Typography variant="body2" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  if (!healthData) {
    return (
      <Box className={classes.footer}>
        <Typography variant="body2">Loading health data...</Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.footer}>
      <Typography variant="body2">
        Status: {healthData.status} | Redis: {healthData.redis ? 'Connected 🟢' : 'Error 🔴'} | Database:{' '}
        {healthData.database ? 'Connected 🟢' : 'Error 🔴'} | Backend Version:{' '}
        <a
          href={`https://github.com/ellamd/ellamd-api/commit/${healthData.backendVersion}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {healthData.backendVersion}
        </a>{' '}
        | Frontend Version:{' '}
        <a
          href={`https://github.com/ellamd/portraitspa-ui/commit/${healthData.frontendVersion}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {healthData.frontendVersion}
        </a>{' '}
        | Ruby: {healthData.rubyVersion} | Rails: {healthData.railsVersion}
      </Typography>
    </Box>
  );
};

export default Footer;
