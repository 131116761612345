import React, { useState, useEffect } from 'react';
import { Box, Button, ButtonGroup } from '@material-ui/core';
import Page from 'src/components/common/Page';
import Message from 'src/components/common/Message/Message';
import { useSuppliers } from 'src/hooks/queries/useSuppliers';
import { USER_TYPES } from 'src/constants/general.constants';
import { DEFAULT_SUPPLIERS } from 'src/constants/inventory.constants';
import { OrderLookupParams } from 'src/interfaces/IInventoryOrder';
import { useCustomRoles } from 'src/hooks/queries/useCustomRoles';
import { useInventoryOrdersIndex } from '../../../hooks/queries/useInventoryOrders';
import { ShortMultipleSkeleton } from '../../common/LoadingSkeleton';
import { OrderApprovalRow } from './OrderApprovalRow';
import Breadcrumb from '../common/Breadcrumb';
import OrderApprovalHeader from './OrderApprovalHeader';

const OrderApproval = () => {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<OrderLookupParams>({});

  const { data: roles = [], isLoading: isLoadingRoles } = useCustomRoles({ userType: USER_TYPES.PRACTITIONER });
  const { data: suppliers = [], isLoading: isLoadingSuppliers } = useSuppliers({ kind: DEFAULT_SUPPLIERS });
  const { data: ordersData, isLoading: isLoadingOrders } = useInventoryOrdersIndex({
    ...filters,
    limit: 25,
    page,
  });

  const isLoading = isLoadingSuppliers || isLoadingOrders || isLoadingRoles;
  const orders = ordersData?.data?.inventoryOrders || [];

  const totalPages = ordersData?.meta?.totalPages || 1;
  const totalItems = ordersData?.meta?.total || 0;

  const handlePreviousPage = () => {
    setPage((old) => Math.max(old - 1, 1));
  };

  const handleNextPage = () => {
    setPage((old) => Math.min(old + 1, totalPages));
  };

  const handleFirstPage = () => {
    setPage(1);
  };

  const handleLastPage = () => {
    setPage(totalPages);
  };

  useEffect(() => {
    setPage(1);
  }, [filters]);

  const ResultsComponent =
    orders.length > 0 ? (
      <>
        {orders.map((order: any) => (
          <OrderApprovalRow inventoryOrder={order} suppliers={suppliers} key={order.id} />
        ))}
        <Box display="flex" justifyContent="center" alignItems="center" gap={2} mt={2} mb={2}>
          <ButtonGroup variant="contained" color="primary">
            <Button onClick={handleFirstPage} disabled={page === 1}>
              First
            </Button>
            <Button onClick={handlePreviousPage} disabled={page === 1}>
              Previous
            </Button>
            <Button disabled>
              Page {page} of {totalPages} ({totalItems} total)
            </Button>
            <Button onClick={handleNextPage} disabled={page === totalPages}>
              Next
            </Button>
            <Button onClick={handleLastPage} disabled={page === totalPages}>
              Last
            </Button>
          </ButtonGroup>
        </Box>
      </>
    ) : (
      <Message text="No data to show" colSize={12} />
    );

  return (
    <Page title="Order Approval">
      <Box width="100%" data-testid="order approval">
        <Breadcrumb prependHeading="Inventory" />
        <OrderApprovalHeader
          isLoading={isLoading}
          setFilterState={setFilters}
          roles={roles}
          currentCount={totalItems}
        />
        {isLoading ? <ShortMultipleSkeleton key={0} /> : ResultsComponent}
      </Box>
    </Page>
  );
};

export default OrderApproval;
