import { Box, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { InventoryOrder, InventoryOrderStatus } from 'src/interfaces/IInventoryOrder';
import { formatCurrency } from 'src/utils/formatNumber';
import { DISPLAY_DATE_FORMAT_VARIANT } from 'src/constants/inventory.constants';
import OrderStatus from 'src/components/DashboardPractitioner/Tabs/OrderingTab/OrderStatus';
import moment from 'moment';
import ProductCountBadge from './OrderApprovalSupplier/ProductCountBadge';

type Props = {
  inventoryOrder: InventoryOrder;
  supplierCount: number;
  status: InventoryOrderStatus;
};

export const OrderDetailStub = ({ inventoryOrder, supplierCount, status }: Props) => {
  const classes = useStyle();

  return (
    <Box className={classes.accordionSummary} data-testid="order history">
      <Box>
        <Typography>{inventoryOrder.warehouse}</Typography>
        <ProductCountBadge total={supplierCount} color="dark" border />
      </Box>
      <Box>
        <Typography component="span">
          <span>Placed on:</span>{' '}
          {moment(inventoryOrder.finalizedAt ?? inventoryOrder.createdAt).format(DISPLAY_DATE_FORMAT_VARIANT)}
        </Typography>
      </Box>
      <Box>
        <Typography component="span">
          <span>Total:</span> {formatCurrency(inventoryOrder.total ?? 0)}
        </Typography>
      </Box>
      <Box paddingRight="21px">
        <OrderStatus status={status} />
      </Box>
    </Box>
  );
};

const useStyle = makeStyles(() => ({
  accordionSummary: {
    display: 'grid',
    gridTemplateColumns: '4fr 1fr 1fr 1fr',
    gap: '10px',
    '& > div': {
      display: 'flex',
    },
    '& span > span': {
      color: '#888',
    },
  },
}));
