import * as yup from 'yup';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { Box, MenuItem } from '@material-ui/core';
import { CreateResourceFilePayload, ResourceFile } from 'src/services/resources/resourcesFiles';
import { useCreateResourcesFile, useUpdateResourcesFile } from 'src/hooks/useResourceFiles';
import { useQueryClient } from 'react-query';
import { EHRButton, EHRCRUDModal, EHRSelect, EHRTextField } from '../../ui/v1';

const CATEGORY_OPTIONS = [
  { label: 'Resources', value: 0 },
  { label: 'Tutorials', value: 1 },
];
const DOCUMENT_TYPE_OPTIONS = [
  { label: 'Download document', value: 0 },
  { label: 'Open link in new tab', value: 1 },
  { label: 'Video', value: 2 },
];

const ResourceSchema = yup.object().shape({
  title: yup.string().required('Title is required'),
  documentLink: yup.string().url('Please enter a valid URL').required('URL is required'),
  category: yup.number().nullable().required('Category is required'),
  section: yup.string().required('Section is required'),
  documentType: yup.number().nullable().required('Document type is required'),
});

export const UpsertResourcesFilesModal = ({
  open,
  onClose,
  resource,
}: {
  open: boolean;
  onClose: () => void;
  resource?: ResourceFile | null;
}) => {
  const queryClient = useQueryClient();
  const { mutateAsync: updateResource, isLoading: isUpdatingResource } = useUpdateResourcesFile();
  const { mutateAsync: createResource, isLoading: isCreatingResource } = useCreateResourcesFile();

  const defaultValues = {
    id: resource?.id ?? null,
    title: resource?.title ?? '',
    documentLink: resource?.documentLink ?? '',
    category: resource?.category ?? null,
    section: resource?.section ?? '',
    documentType: resource?.documentType ?? null,
  };

  const { control, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(ResourceSchema),
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [resource, reset]);

  const handleClose = () => {
    reset();
    onClose();
  };

  const isLoading = isUpdatingResource || isCreatingResource;
  const editMode = resource;
  const buttonText = editMode ? 'Edit' : 'Add';

  const onSubmit = async (formData: CreateResourceFilePayload) => {
    let res;

    if (editMode) {
      res = await updateResource({
        id: resource.id,
        data: formData,
      });
    } else {
      res = await createResource({
        data: formData,
      });
    }

    if (res?.success) {
      queryClient.invalidateQueries(['RESOURCES_FILES', formData.category]);
    }
    handleClose();
  };

  return (
    <EHRCRUDModal dataCy="modal-resources-file" open={open} onClose={handleClose}>
      <EHRCRUDModal.Title
        dataCy="modal-resources-file-modal-title"
        title={editMode ? 'Edit Resource' : 'New Resource'}
        handleClose={handleClose}
      />

      <EHRCRUDModal.Body dataCy="modal-resources-file-body">
        <Box display="flex" justifyContent="space-between" flexDirection="column" gridGap="0.25rem" width="100%">
          <Box display="flex" flexDirection="row" gridGap="1rem">
            <Box flex={1}>
              <Controller
                control={control}
                name="title"
                render={({ onChange, value }) => (
                  <EHRTextField
                    dataCy="input-title"
                    data-cy="title"
                    name="title"
                    onChange={onChange}
                    value={value}
                    label="Title"
                    error={!!errors?.title}
                    helperText={errors?.title?.message || ' '}
                  />
                )}
              />
            </Box>
          </Box>

          <Box flex={1}>
            <Controller
              control={control}
              name="section"
              render={({ onChange, value }) => (
                <EHRTextField
                  dataCy="input-section"
                  data-cy="section"
                  name="section"
                  onChange={onChange}
                  value={value}
                  label="Section"
                  error={!!errors?.section}
                  helperText={errors?.section?.message || ' '}
                />
              )}
            />
          </Box>

          <Box>
            <Controller
              control={control}
              name="documentLink"
              render={({ onChange, value }) => (
                <EHRTextField
                  dataCy="input-url"
                  data-cy="document-link"
                  name="documentLink"
                  onChange={onChange}
                  value={value}
                  label="Document Link"
                  error={!!errors?.documentLink}
                  helperText={errors?.documentLink?.message || ' '}
                />
              )}
            />
          </Box>

          <Box display="flex" flexDirection="row" gridGap="1rem">
            <Box flex={1}>
              <Controller
                control={control}
                name="category"
                render={({ onChange, value }) => (
                  <EHRSelect
                    dataCy="select-category"
                    name="category"
                    value={value}
                    onChange={onChange}
                    label="Category"
                    fullWidth
                    error={!!errors?.category}
                    helperText={errors?.category?.message || ' '}
                  >
                    {CATEGORY_OPTIONS.map((option) => (
                      <MenuItem value={option.value} key={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </EHRSelect>
                )}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" gridGap="1rem">
            <Box flex={1}>
              <Controller
                control={control}
                name="documentType"
                render={({ onChange, value }) => (
                  <EHRSelect
                    dataCy="select-document-type"
                    name="documentType"
                    value={value}
                    onChange={onChange}
                    label="Document Type"
                    fullWidth
                    error={!!errors?.documentType}
                    helperText={errors?.documentType?.message || ' '}
                  >
                    {DOCUMENT_TYPE_OPTIONS.map((option) => (
                      <MenuItem value={option.value} key={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </EHRSelect>
                )}
              />
            </Box>
          </Box>
        </Box>
      </EHRCRUDModal.Body>

      <EHRCRUDModal.Footer dataCy="modal-footer-new-resource">
        <Box display="flex" width="100%">
          <EHRButton
            dataCy="btn-add-modal-resources-file"
            onClick={handleSubmit(onSubmit)}
            disabled={isLoading}
            color="primary"
            text={isLoading ? 'Processing...' : buttonText}
            fullWidth
          />
        </Box>
      </EHRCRUDModal.Footer>
    </EHRCRUDModal>
  );
};
