import React, { useState } from 'react';
import { Box, ButtonBase, CircularProgress, Divider } from '@material-ui/core';
import { EHRTypography } from 'src/components/ui/v1';
import { IPhysician } from 'src/interfaces/IPhysician';
import { IUserGroup } from 'src/interfaces/IUserGroup';
import { linkWrapper } from 'src/components/common/SignDocumentButton';
import useStyles, { COMPLETED_STEP_COLOR } from 'src/components/StepsTracker/StepsTracker.styles';
import { AccessTimeRounded, CheckRounded } from '@material-ui/icons';
import { copyToClipboard } from 'src/utils/clipboard.utils';
import { CURRENT_USER_GROUP } from 'src/constants/reactQuery.keys';
import { useQueryClient } from 'react-query';
import { useCreateMedicalDirectorInfo } from 'src/hooks/queries/useAccountSettings';
import DragAndDropComponent from 'src/components/DragAndDrop';
import useStylesMedspaSettings from '../index.styles';
import { UserInfoRow } from '../common/UserInfoRow';
import UpdateMedicalDirectorModal from './UpdateMedicalDirectorModal';

interface MedicalDirectorSectionProps {
  medicalDirector: IPhysician;
  userGroup: IUserGroup;
  onboardingCompleted?: boolean;
}

export const pdfLinkStyle = { color: '#12574D', textDecoration: 'none' };

const MedicalDirectorSection = (props: MedicalDirectorSectionProps) => {
  const classes = useStyles();
  const classesMedspaSettings = useStylesMedspaSettings();
  const [modalOpen, setModalOpen] = useState(false);
  const queryClient = useQueryClient();

  const { medicalDirector, userGroup, onboardingCompleted = false } = props;
  const { owner } = userGroup;
  const { medicalDirectorId, externalMedicalDirector, hasNoMedicalDirector, ownMedicalDirector } =
    userGroup.medicalDirector || {};
  const { mutateAsync: updateMedicalDirector, isLoading } = useCreateMedicalDirectorInfo();

  const medicalDirectorModal = <UpdateMedicalDirectorModal isOpen={modalOpen} onClose={() => setModalOpen(false)} />;

  const handleAgreementFile = (fileData: File[]) => {
    const file = fileData?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const response = await updateMedicalDirector({
          medicalDirectorInfo: {
            externalMedicalDirector: { agreementPdf: (reader.result || '').toString() },
          },
        });
        if (response?.success) {
          queryClient.invalidateQueries([CURRENT_USER_GROUP]);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const updateButton = (text: string) => (
    <>
      {onboardingCompleted && (
        <ButtonBase onClick={() => setModalOpen(true)} style={{ textDecoration: 'underline' }}>
          <EHRTypography variant="body2" dataCy="sign-document-pdf">
            {text}
          </EHRTypography>
        </ButtonBase>
      )}
    </>
  );

  if (medicalDirectorId && medicalDirector) {
    return (
      <>
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <EHRTypography variant="h6" dataCy="medspa-tab-title">
            Medical Director
          </EHRTypography>
        </Box>
        <Box className={classesMedspaSettings.medspaSettingsContainer}>
          <UserInfoRow label="Full Name" userData={medicalDirector.professionalName} />
          <Divider />
          <UserInfoRow label="Email" userData={medicalDirector.identity.email} />
          <Divider />
          <UserInfoRow label="Phone" userData={medicalDirector.phone} />
        </Box>
      </>
    );
  }

  if (hasNoMedicalDirector) {
    if (onboardingCompleted) {
      return (
        <>
          <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <EHRTypography variant="h6" dataCy="medspa-tab-title">
              Medical Director
            </EHRTypography>
            {updateButton('Add')}
          </Box>
          <p>Medspa Admin has no medical director.</p>
          {medicalDirectorModal}
        </>
      );
    }
    return null;
  }

  if (ownMedicalDirector?.isOwnMedicalDirector && owner) {
    return (
      <>
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <EHRTypography variant="h6" dataCy="medspa-tab-title">
            Medical Director
          </EHRTypography>
          {updateButton('Update')}
        </Box>
        <Box className={classesMedspaSettings.medspaSettingsContainer}>
          <UserInfoRow label="Full Name" userData={owner.fullName} />
          <Divider />
          <UserInfoRow label="Email" userData={owner.email} />
          <Divider />
          <UserInfoRow label="Phone" userData={owner.phone} />

          {ownMedicalDirector?.ownMedicalDirectorAgreementPdf && (
            <>
              <Divider />
              <UserInfoRow
                label="Medical Director Authorization Agreement"
                content={
                  <Box display="flex" alignItems="center" gridGap="0.5rem">
                    <div className={classes.filledGreenCircle}>
                      <CheckRounded className={classes.roundedCheck} />
                    </div>

                    <EHRTypography
                      dataCy="sign-document-sign-on"
                      variant="readonly"
                      style={{ color: COMPLETED_STEP_COLOR }}
                    >
                      Signed on {ownMedicalDirector?.pdfCreatedAt}
                    </EHRTypography>
                  </Box>
                }
                third={
                  <EHRTypography variant="body2" dataCy="sign-document-pdf">
                    {linkWrapper('View', ownMedicalDirector?.ownMedicalDirectorAgreementPdf, pdfLinkStyle)}
                  </EHRTypography>
                }
              />
            </>
          )}
        </Box>
        {medicalDirectorModal}
      </>
    );
  }

  if (externalMedicalDirector?.id) {
    return (
      <>
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <EHRTypography variant="h6" dataCy="medspa-tab-title">
            Medical Director
          </EHRTypography>
          {updateButton('Update')}
        </Box>
        <Box className={classesMedspaSettings.medspaSettingsContainer}>
          <UserInfoRow
            label="Full Name"
            userData={`${externalMedicalDirector.firstName} ${externalMedicalDirector.lastName}`}
          />
          <Divider />
          <UserInfoRow label="Email" userData={externalMedicalDirector.email as string} />
          <Divider />
          <UserInfoRow label="Phone" userData={externalMedicalDirector.phone as string} />
          {!!externalMedicalDirector.signFormUrl && !externalMedicalDirector.agreementPdf && (
            <>
              <Divider />
              <UserInfoRow
                label="Sign Agreement URL"
                content=""
                third={
                  <ButtonBase
                    onClick={() => copyToClipboard(externalMedicalDirector.signFormUrl || '')}
                    style={{ textDecoration: 'underline' }}
                  >
                    <EHRTypography variant="body2" dataCy="sign-document-pdf">
                      Copy to clipboard
                    </EHRTypography>
                  </ButtonBase>
                }
              />
            </>
          )}

          <Divider />
          {!externalMedicalDirector?.agreementPdf ? (
            <>
              <UserInfoRow
                label="Medical Director Agreement"
                content={
                  <Box display="flex" alignItems="center" gridGap="0.5rem">
                    <div>
                      <AccessTimeRounded style={{ color: '#FFA726' }} />
                    </div>
                    <EHRTypography dataCy="sign-document-pending" variant="readonly" style={{ color: '#FFA726' }}>
                      Pending signature
                    </EHRTypography>
                  </Box>
                }
              />
              <Divider />
              {isLoading ? (
                <Box display="flex" alignItems="center" justifyContent="center" p={2} my={2}>
                  <CircularProgress size={24} style={{ marginRight: '10px' }} />
                  <EHRTypography variant="body2" dataCy="">
                    Uploading agreement...
                  </EHRTypography>
                </Box>
              ) : (
                <Box mt={2} py={2}>
                  <EHRTypography variant="body2" style={{ marginBottom: '8px' }} dataCy="upload-agreement">
                    Upload signed Medical Director Agreement
                  </EHRTypography>
                  <DragAndDropComponent
                    hideRemoveFile
                    acceptedFileType="application/pdf"
                    onFileChange={handleAgreementFile}
                  />
                </Box>
              )}
            </>
          ) : (
            <UserInfoRow
              label="Medical Director Agreement"
              content={
                <Box display="flex" alignItems="center" gridGap="0.5rem">
                  <div className={classes.filledGreenCircle}>
                    <CheckRounded className={classes.roundedCheck} />
                  </div>
                  <EHRTypography
                    dataCy="sign-document-sign-on"
                    variant="readonly"
                    style={{ color: COMPLETED_STEP_COLOR }}
                  >
                    Signed on {externalMedicalDirector?.agreementCreatedAt}
                  </EHRTypography>
                </Box>
              }
              third={
                <EHRTypography variant="body2" dataCy="sign-document-pdf">
                  {linkWrapper('View', externalMedicalDirector.agreementPdf, pdfLinkStyle)}
                </EHRTypography>
              }
            />
          )}
          {medicalDirectorModal}
        </Box>
      </>
    );
  }

  return null;
};

export default MedicalDirectorSection;
