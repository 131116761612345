/* eslint-disable react/no-danger */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Grid,
  TextField,
  CircularProgress,
  TextareaAutosize,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Button,
  Link,
  Snackbar,
} from '@material-ui/core';
import { Visibility as VisibilityIcon } from '@material-ui/icons';
import MuiAlert from '@material-ui/lab/Alert';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import SideModal from 'src/components/common/NewModal';
import { useSelector } from 'react-redux';
import { useStyles } from 'src/pages/Configuration/Calendar/styles';
import { TextAreaStyles } from 'src/components/FullServiceHistory/FullServiceHistoryOpen/CustomerItem.styles';
import { useServiceGroups, useServices } from 'src/hooks/queries/services/useServices';
import { Pill } from 'src/components/common/Pill';
import ConfirmModal from 'src/components/common/ConfirmModal';
import { IAppointmentType } from '../../../interfaces/acuity/acuity.interface';
import { copyToClipboard } from '../../../utils/clipboard.utils';
import { useCareMessagesSuggestion } from '../../../hooks/acuity/useAcuityAccount';
import {
  useMedspaCreateAppointmentTypeMutation,
  useUpdateMedspaAppointmentTypeMutation,
} from '../../../hooks/queries/medspaAdmins/useMedspaAppointmentTypes';
import {
  medspaAppointmentTypeDefaultValues,
  medspaAppointmentTypeSchema,
} from '../../../pages/Configuration/Calendar/schemas';
import { IMedspaLocation } from '../../../interfaces/ILocation';
import { EHRMultipleSelect, EHRSwitch } from '../../ui/v1';
import IServices from '../../../interfaces/IServices';
import { MEDSPA_ADMIN_SERVICES_MANAGEMENT } from '../../../routes/medspaAdminRoutes';
import IServiceGroup from '../../../interfaces/IServiceGroup';

interface MedspaAppointmentTypeProps {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  appointmentType?: IAppointmentType | null;
  medspaLocations: IMedspaLocation[];
  onSuccessCallback?: () => void | Promise<void>;
}

const MedspaAppointmentTypeForm = ({
  openDialog,
  appointmentType,
  setOpenDialog,
  medspaLocations,
  onSuccessCallback,
}: MedspaAppointmentTypeProps) => {
  const { userGroupId } = useSelector(({ auth }: any) => auth);
  const classes = useStyles();
  const [showSuggestion, setShowSuggestion] = useState<string>('');
  const { data: serviceGroups, isFetching: isFetchingServiceGroups } = useServiceGroups();
  const { data: services, isFetching: isFetchingServices } = useServices();
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });
  const [selectedMedspaLocationIds, setSelectedMedspaLocationIds] = useState<number[]>([]);
  const medspaCreateMutation = useMedspaCreateAppointmentTypeMutation(userGroupId);
  const medspaUpdateMutation = useUpdateMedspaAppointmentTypeMutation(userGroupId);

  const loading =
    medspaUpdateMutation.isLoading || medspaCreateMutation.isLoading || isFetchingServiceGroups || isFetchingServices;
  const disabled = !!appointmentType?.default || loading;

  // @ts-ignore
  const { register, handleSubmit, errors, setValue, control, watch, getValues, trigger, reset } = useForm<FormData>({
    resolver: yupResolver(medspaAppointmentTypeSchema),
    defaultValues: medspaAppointmentTypeDefaultValues,
  });
  const watchName = watch('name');
  const watchDuration = watch('duration');
  const watchEnablePreCareMessage = watch('enablePreCareMessage');
  const watchPreCareMessage = watch('preCareMessage');
  const watchPostCareMessage = watch('postCareMessage');
  const watchServiceGroups = watch('serviceGroups');
  const watchServices = watch('services');
  const watchStatus = watch('hidden');
  const watchMedspaLocations = watch('medspaLocations');
  const watchLocations = watch('locations');
  const { data: careMessageSuggestions, isFetching: isFetchingSuggestions } = useCareMessagesSuggestion({
    serviceGroupIds: watchEnablePreCareMessage ? watchServiceGroups : [],
  });

  const providerOptions = useMemo(
    () =>
      medspaLocations
        .filter((medspaLocation) => selectedMedspaLocationIds.includes(medspaLocation.id))
        .map((medspaLocation) =>
          medspaLocation.practitioners.map((provider) => ({
            ...provider,
            medspaName: medspaLocation.name,
            medspaLocationId: medspaLocation.id,
          }))
        )
        .flat(),
    [medspaLocations, selectedMedspaLocationIds]
  );

  const handleDialogCloseCancel = () => {
    setOpenDialog(false);
  };

  const defaultServiceGroupMessages = useMemo(() => {
    const serviceGroupId = appointmentType?.serviceGroups?.[0];
    if (appointmentType?.default && serviceGroupId) {
      const matchingGroup = serviceGroups.find((serviceGroup: IServiceGroup) => serviceGroup.id === serviceGroupId);
      return {
        preCareMessage: matchingGroup?.careMessages?.preMandatoryMessage,
        postCareMessage: matchingGroup?.careMessages?.postMandatoryMessage,
      };
    }
    return null;
  }, [appointmentType, serviceGroups]);

  useEffect(() => {
    if (!loading && openDialog && appointmentType?.id) {
      setValue('name', appointmentType?.name);
      setValue('duration', appointmentType?.duration);
      setValue('description', appointmentType?.description);
      setValue('enablePreCareMessage', !!appointmentType?.preCareMessage || !!appointmentType?.postCareMessage);
      setValue('preCareMessage', appointmentType?.preCareMessage || '');
      setValue('postCareMessage', appointmentType?.postCareMessage || '');
      setValue('services', appointmentType?.services || []);
      setValue('serviceGroups', appointmentType?.serviceGroups || []);
      setValue('hidden', appointmentType?.hidden);
      setValue('medspaLocations', appointmentType?.medspaLocations);
      setValue('locations', appointmentType?.locations);
      setSelectedMedspaLocationIds(appointmentType?.medspaLocations || []);
    }
  }, [appointmentType, loading, openDialog, appointmentType?.duration]);

  useEffect(() => {
    if (defaultServiceGroupMessages && openDialog) {
      setValue(
        'enablePreCareMessage',
        !!defaultServiceGroupMessages.preCareMessage || !!defaultServiceGroupMessages.postCareMessage
      );
      setValue('preCareMessage', defaultServiceGroupMessages.preCareMessage);
      setValue('postCareMessage', defaultServiceGroupMessages.postCareMessage);
    }
  }, [defaultServiceGroupMessages, setValue, openDialog]);

  useEffect(() => {
    if (!openDialog) {
      reset(medspaAppointmentTypeDefaultValues, {
        keepErrors: false,
        keepDirty: false,
      });
    }
  }, [openDialog]);

  const toggleStatus = async () => {
    if (appointmentType) {
      const result = await trigger(['medspaLocations', 'locations']);
      if (result && validateProviders()) {
        await medspaUpdateMutation.mutateAsync({
          ...appointmentType,
          ...{
            hidden: getValues('hidden'),
            locations: getValues('locations'),
          },
        });
        setOpenDialog(false);
      }
    }
  };
  const handleMedspaLocationChange = (newVal: number[]) => {
    setSelectedMedspaLocationIds(newVal);
    setValue('medspaLocations', newVal);
  };

  const validateProviders = () => {
    const newValidationErrors: string[] = [];
    watchMedspaLocations.forEach((locationId: number) => {
      const locationProviders = providerOptions.filter((provider) => provider.medspaLocationId === locationId);
      const selectedLocationProviders = watchLocations.filter((providerId: number) =>
        locationProviders.some((provider) => provider.locationId === providerId)
      );
      if (selectedLocationProviders.length === 0) {
        const locationName = medspaLocations.find((loc) => loc.id === locationId)?.name;
        newValidationErrors.push(`At least one provider must be selected for ${locationName}`);
      }
    });
    setValidationErrors(newValidationErrors);
    return newValidationErrors.length === 0;
  };

  const onSubmit = async (data: any) => {
    if (validateProviders()) {
      await onSuccessCallback?.();
      if (appointmentType?.id) {
        await medspaUpdateMutation.mutateAsync({ ...data, id: appointmentType?.id });
      } else {
        await medspaCreateMutation.mutateAsync(data);
      }
      setOpenDialog(false);
    } else {
      setSnackbar({
        open: true,
        message: 'Please select at least one provider for each selected location.',
      });
    }
  };

  const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <SideModal openModal={openDialog} onClose={handleDialogCloseCancel}>
      <SideModal.Header onClose={handleDialogCloseCancel}>
        <SideModal.Title>{appointmentType?.id ? 'Edit' : 'New'} Appointment Type</SideModal.Title>
      </SideModal.Header>
      <SideModal.Body>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Grid item className={classes.formItem}>
            <div className={classes.divLabel}>Name</div>
            <TextField
              data-cy="nameInput"
              id="nameInput"
              fullWidth
              name="name"
              value={watchName}
              error={!!errors.name}
              helperText={errors.name?.message || ''}
              disabled={disabled}
              onChange={(event) => setValue('name', event.target.value)}
              variant="outlined"
              labelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item className={classes.formItem}>
            <div className={classes.divLabel}>Duration (minutes)</div>
            <TextField
              data-cy="durationInput"
              id="durationInput"
              fullWidth
              name="duration"
              value={watchDuration}
              disabled={disabled}
              inputRef={disabled ? undefined : register}
              error={!!errors.duration}
              helperText={errors.duration?.message || ''}
              onChange={(event) => setValue('duration', event.target.value)}
              type="number"
              variant="outlined"
              labelProps={{
                shrink: true,
              }}
              InputProps={{ inputProps: { min: 0, step: 1 } }}
            />
          </Grid>
          <Grid item className={classes.formItem}>
            <div className={classes.divLabel}>Locations</div>
            <EHRMultipleSelect
              className={classes.appointmentTypeFormInputSelect}
              dataCy="select-medspa-location"
              id="medspaLocationSelect"
              name="medspaLocations"
              disabled={loading}
              value={watchMedspaLocations}
              onChange={handleMedspaLocationChange}
              error={!!errors.medspaLocations}
              helperText={errors.medspaLocations?.message}
              options={medspaLocations.map(({ name, id }) => ({ name, value: id }))}
            />
          </Grid>
          {!!watchMedspaLocations?.length && (
            <Grid item className={classes.formItem}>
              <div className={classes.divLabel}>Providers</div>
              <EHRMultipleSelect
                className={classes.appointmentTypeFormInputSelect}
                dataCy="select-locations"
                id="locationSelect"
                name="locations"
                disabled={loading}
                value={watchLocations}
                onChange={(newVal: any) => {
                  setValue('locations', newVal);
                }}
                error={!!errors.locations || validationErrors.length > 0}
                helperText={errors.locations?.message || validationErrors.join(', ')}
                options={providerOptions
                  .filter(({ medspaLocationId }) => watchMedspaLocations.includes(medspaLocationId))
                  .map(({ fullName, locationId, medspaName }) => ({
                    name: `${fullName} - ${medspaName}`,
                    value: locationId,
                  }))}
              />
            </Grid>
          )}
          {serviceGroups.length ? (
            <Grid item className={classes.formItem}>
              <div className={classes.divLabel}>Service Groups</div>
              <EHRMultipleSelect
                className={classes.appointmentTypeFormInputSelect}
                disabled={disabled}
                name="serviceGroups"
                control={control}
                value={watchServiceGroups}
                onChange={(newVal: any) => setValue('serviceGroups', newVal)}
                errors={errors}
                options={serviceGroups.map(({ name, id }) => ({ name, value: id }))}
              />
            </Grid>
          ) : (
            <Grid item className={classes.formItem}>
              <div className={classes.divLabel}>Service Groups</div>
              <div>
                No Services have been created click <Link href={MEDSPA_ADMIN_SERVICES_MANAGEMENT}>here</Link> to add
                Services to the MedSpa
              </div>
            </Grid>
          )}
          {!!watchServiceGroups?.length && (
            <Grid item className={classes.formItem}>
              <div className={classes.divLabel}>Services</div>
              <EHRMultipleSelect
                className={classes.appointmentTypeFormInputSelect}
                name="services"
                control={control}
                value={watchServices}
                disabled={disabled}
                onChange={(newVal: any) => setValue('services', newVal)}
                errors={errors}
                options={services
                  .filter(({ serviceGroupId }: IServices) => watchServiceGroups.includes(serviceGroupId))
                  .map(({ name, id }: IServices) => ({ name, value: id }))}
              />
            </Grid>
          )}
          <Grid item className={classes.formItem}>
            <div className={classes.divLabel}>Description</div>
            <TextareaAutosize
              id="descriptionInput"
              placeholder="Description"
              disabled={disabled}
              defaultValue={appointmentType?.description}
              onChange={(event) => setValue('description', event.target.value)}
              name="description"
              style={TextAreaStyles}
            />
            {!!errors?.description && <FormHelperText error>{errors.description?.message}</FormHelperText>}
          </Grid>
          <Grid item className={classes.formItem}>
            <FormControlLabel
              style={{
                width: '100%',
              }}
              control={
                <Controller
                  control={control}
                  inputRef={register}
                  name="enablePreCareMessage"
                  render={({ onChange, value }: any) => (
                    <Checkbox
                      disabled={disabled}
                      onChange={(e) => {
                        onChange(e.target.checked);
                        setValue('preCareMessage', '');
                        setValue('postCareMessage', '');
                      }}
                      ref={register}
                      checked={value}
                      color="default"
                    />
                  )}
                />
              }
              label="Care instructions"
            />
            {isFetchingSuggestions && <CircularProgress size={20} />}
            {watchEnablePreCareMessage &&
              !appointmentType?.default &&
              !!careMessageSuggestions?.preCareMessages?.length && <Box>Suggestions:</Box>}
            {watchEnablePreCareMessage &&
              !appointmentType?.default &&
              careMessageSuggestions?.preCareMessages?.map((message: string) => (
                <Box className={classes.careMessageSuggestion} style={{ cursor: 'pointer' }}>
                  <VisibilityIcon onClick={() => setShowSuggestion(message)} />
                  <Box onClick={() => copyToClipboard(message)} style={{ marginLeft: '5px' }}>
                    <Pill title="Copy to clipboard" color="gray" style={{ cursor: 'pointer' }} />
                  </Box>
                </Box>
              ))}
            <ConfirmModal
              title="Care message suggestion"
              contentText={<div dangerouslySetInnerHTML={{ __html: showSuggestion }} />}
              openDialog={!!showSuggestion}
              setOpenDialog={() => setShowSuggestion('')}
              confirmText="Copy to clipboard"
              onClickConfirm={() => {
                copyToClipboard(showSuggestion);
                setShowSuggestion('');
              }}
            />
            <div className={classes.divLabel}>Pre Care Message</div>
            <TextareaAutosize
              placeholder="Pre care message"
              disabled={!watchEnablePreCareMessage || disabled}
              value={watchPreCareMessage}
              onChange={(event) => setValue('preCareMessage', event.target.value)}
              name="preCareMessage"
              style={TextAreaStyles}
            />
          </Grid>
          <Grid item className={classes.formItem}>
            <div className={classes.divLabel}>Post Care Message</div>
            <TextareaAutosize
              placeholder="Post care message"
              disabled={!watchEnablePreCareMessage || disabled}
              value={watchPostCareMessage}
              onChange={(event) => setValue('postCareMessage', event.target.value)}
              name="postCareMessage"
              style={TextAreaStyles}
            />
          </Grid>
          <Grid item className={classes.formItem}>
            <div className={classes.divLabel}>Status</div>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Controller
                control={control}
                name="hidden"
                error={!!errors.hidden}
                fullWidth
                render={() => (
                  <EHRSwitch
                    id="statusInput"
                    dataCy="statusInput"
                    onChange={(event: any) => setValue('hidden', !event.target.checked)}
                    defaultChecked={!appointmentType?.hidden}
                    disabled={loading}
                  />
                )}
              />
              <Box>
                <Box>{watchStatus ? 'Disabled' : 'Enabled'}</Box>
                <Box style={{ fontSize: '12px' }}>Appointment Type is {watchStatus ? 'inactive' : 'active'}</Box>
              </Box>
            </Box>
            {errors.hidden && <FormHelperText error>{errors.hidden?.message}</FormHelperText>}
          </Grid>
        </Box>
      </SideModal.Body>
      <SideModal.Footer>
        <Button
          data-cy="confirmModal"
          onClick={appointmentType?.default ? toggleStatus : handleSubmit(onSubmit)}
          disabled={loading}
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
        >
          Save Changes
          {loading && <CircularProgress size={25} />}
        </Button>
      </SideModal.Footer>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <MuiAlert elevation={6} variant="filled" onClose={handleSnackbarClose} severity="error">
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
    </SideModal>
  );
};

export default MedspaAppointmentTypeForm;
