/* eslint-disable no-param-reassign */
import { useQuery, UseQueryResult } from 'react-query';
import { PRACTITIONER_REPORTS, PRACTITIONER_REPORT_COLS } from 'src/constants/reactQuery.keys';
import { IClientSummaryData, TableHeaderSet, IReportParams } from 'src/interfaces/IPractitionerReport';
import PractitionerReports from 'src/services/PractitionerReports';

function usePractitionerReport(params: IReportParams, enabled = true): UseQueryResult<IClientSummaryData[] | null> {
  return useQuery({
    queryKey: [PRACTITIONER_REPORTS, ...Object.values(params)],
    queryFn: (): Promise<IClientSummaryData[] | null> => PractitionerReports.getReport(params),
    enabled,
  });
}

function usePractitionerReportColumns(reportLabel: string, enabled = true): UseQueryResult<TableHeaderSet | undefined> {
  return useQuery({
    queryKey: [PRACTITIONER_REPORT_COLS, reportLabel],
    queryFn: (): Promise<TableHeaderSet | undefined> => PractitionerReports.getColumns(reportLabel),
    enabled,
  });
}

export { usePractitionerReportColumns, usePractitionerReport, usePractitionerReport as default };
