import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import * as Sentry from '@sentry/react';
import { Stepper, Step, StepContent, StepLabel, CardContent } from '@material-ui/core';
import { ILocationPatientId } from 'src/interfaces/ILocation';
import { useMedspaLocations } from 'src/hooks/queries/medspaAdmins/useMedspaLocations';
import { LocationsStep } from './components/LocationsStep';
import { NavigationBar } from '../../components/PatientProfile/NavigationBar';
import { PATIENT_INFO } from '../../constants/reactQuery.keys';
import Patients from '../../services/Patients';
import { ADMIN_ROLE } from '../../constants/general.constants';
import { ROUTES } from '../../constants/routes.constants';
import { dispatch } from '../../rematch';
import { useStyles } from './index.styles';
import ChargeCardStep, { PRODUCT } from './components/ChargeCardStep';
import { Card } from '../../components/common/card';
import { MultipleSkeleton } from '../../components/common/LoadingSkeleton';
import ProductUnitsStep from './components/ProductUnitsStep';
import { ProductsScanStep } from './components/ProductsScanStep';
import { PRACTITIONER_PATIENT_ALL_PATH, PRACTITIONER_PATIENT_CLEARED_PATH } from '../../routes/practitionerRoutes';
import { ADMIN_PATIENTS_LIST_PATH } from '../../routes/administratorRoutes';
import { hasAccessTo } from '../../utils/auth.utils';
import { GFE_REQUEST } from '../../constants/actions.constants';
import { ILineItem } from '../../interfaces/IServiceVisits';

const PatientChargeCard = () => {
  const { patientId }: { patientId: string } = useParams();
  const { userType, permissions } = useSelector(({ auth }: any) => auth);
  const classes = useStyles();
  const history = useHistory();
  const {
    data: patient,
    error,
    isLoading,
    refetch,
  } = useQuery([PATIENT_INFO, patientId], () => Patients.getPatientInfo(patientId), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  const [chargeType, setChargeType] = useState<string>(PRODUCT);

  // workaround to add ability to show a third/fourth step without refactoring everything.
  const [selectedServices, setSelectedServices] = useState<number[]>([]);
  const [lineItems, setLineItems] = useState<ILineItem[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<ILocationPatientId | null>(null);
  const [showLocationError, setShowLocationError] = useState(false);
  const {
    data: { userGroup, medspaLocations = [] },
    isLoading: isLoadingMedspaLocations,
  } = useMedspaLocations();

  const handleLocationError = () => {
    if (!selectedLocation?.id) {
      setShowLocationError(true);
    }
  };

  useEffect(() => {
    if (error) {
      const { message } = error as Error;

      /* eslint-disable no-nested-ternary */
      const goTo =
        userType === ADMIN_ROLE
          ? ADMIN_PATIENTS_LIST_PATH
          : hasAccessTo(GFE_REQUEST, permissions)
            ? PRACTITIONER_PATIENT_CLEARED_PATH
            : PRACTITIONER_PATIENT_ALL_PATH;
      history.push(goTo);
      const sentryMessage = `${message} for patient ${patientId}`;
      // @ts-ignore
      Sentry.captureMessage(sentryMessage, 'debug' as Sentry.Severity);
    }

    if (patient?.basicInfo.customerStatus === 'incomplete') {
      refetch();
      dispatch.patient.fetchBasicInfo({ patientId: parseInt(patientId, 10) });
    }
  }, [patient, error]);

  useEffect(() => {
    dispatch.patient.fetchServiceRequests(Number(patientId));
  }, [patientId]);

  const navigateBack = () => {
    history.push(ROUTES.PATIENT_ID(patientId));
  };

  useEffect(() => {
    if (Array.isArray(medspaLocations) && medspaLocations.length === 1 && !selectedLocation) {
      setSelectedLocation(medspaLocations[0]);
    }
  }, [medspaLocations, selectedLocation]);

  if (isLoading) {
    return (
      <Card>
        <CardContent className={classes.card}>
          <MultipleSkeleton addPosition={false} />
        </CardContent>
      </Card>
    );
  }

  return (
    <div className={classes.mainContainer}>
      <div className={classes.navigationContainer}>
        <NavigationBar title="Retail Checkout" hideHomeButton onBackButtonClick={navigateBack} />
      </div>
      <div className={classes.stepsSection}>
        <Stepper orientation="vertical" className={classes.stepper} connector={<></>}>
          {!!userGroup?.active && Array.isArray(medspaLocations) && medspaLocations.length > 1 && (
            <Step active className={classes.stepContainer}>
              <StepLabel classes={{ root: `${classes.stepRoot}` }} />
              <StepContent className={classes.stepContent}>
                <LocationsStep
                  locations={medspaLocations}
                  isLoading={isLoadingMedspaLocations}
                  selectedLocation={selectedLocation}
                  setSelectedLocation={setSelectedLocation}
                  showErrorMessage={showLocationError}
                />
              </StepContent>
            </Step>
          )}
          <Step active className={classes.stepContainer}>
            <StepLabel classes={{ root: `${classes.stepRoot}` }} />
            <StepContent className={classes.stepContent}>
              <ChargeCardStep
                patientId={patientId}
                chargeType={chargeType}
                setChargeType={setChargeType}
                selectedServices={selectedServices}
                setSelectedServices={setSelectedServices}
                medspaLocationId={selectedLocation?.id}
                onInteraction={handleLocationError}
              />
            </StepContent>
          </Step>
          {
            // unit amount selection step for product charge type
            chargeType === PRODUCT && (
              <Step active className={classes.stepContainer}>
                <StepLabel classes={{ root: `${classes.stepRoot}` }} />
                <StepContent className={classes.stepContent}>
                  <ProductUnitsStep
                    selectedServices={selectedServices}
                    lineItems={lineItems}
                    setLineItems={setLineItems}
                  />
                </StepContent>
              </Step>
            )
          }
          {
            // product scan step for for product charge type
            chargeType === PRODUCT && (
              <Step active className={classes.stepContainer}>
                <StepLabel classes={{ root: `${classes.stepRoot}` }} />
                <StepContent className={classes.stepContent}>
                  <ProductsScanStep
                    patientId={patientId}
                    selectedServices={selectedServices}
                    lineItems={lineItems}
                    medspaLocationId={selectedLocation?.id}
                  />
                </StepContent>
              </Step>
            )
          }
        </Stepper>
      </div>
    </div>
  );
};

export default PatientChargeCard;
