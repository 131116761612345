export const ACUITY_TIMEZONE_OPTIONS: string[] = [
  'America/Los_Angeles',
  'America/New_York',
  'America/Chicago',
  'America/Denver',
  'America/Phoenix',
  'America/Sao_Paulo',
  'Europe/London',
  'Europe/Paris',
  'Europe/Berlin',
  'Europe/Rome',
  'Europe/Moscow',
  'Asia/Tokyo',
  'Asia/Hong_Kong',
  'Asia/Singapore',
  'Australia/Sydney',
  'Australia/Brisbane',
  'Australia/Melbourne',
  'Australia/Perth',
];

export const DELETE_APPOINTMENT_TYPE = 'Delete appointment type';
export const ARE_YOU_SURE_DELETE_APPOINTMENT_TYPE = 'Are you sure that you want to delete the appointment type?';
export const BLOCK_OFF_HOURS = 'Block off hours';
