import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  outerBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  innerBox: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    '& svg': {
      width: 45,
      marginLeft: 8,
    },
    '&[data-loading="true"]': {
      opacity: 0.3,
    },
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    fontSize: 14,
    marginBottom: 10,
    '& > *': {
      margin: 'auto auto auto 0',
    },
    '& > *:last-child': {
      margin: 'auto 0 auto auto',
    },
    '& > p > span': {
      color: '#999',
    },
  },
  radioGroup: {
    width: '100%',
  },
  radioItem: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    backgroundColor: '#f7f7f7',
    padding: '10px 20px',
    borderRadius: '10px',
    border: '1px solid #ddd',
    margin: '5px 0',
    '& > span:first-child': {
      width: 30,
      marginRight: 5,
    },
  },
  radioItemEmpty: {
    border: '1px dashed #ccc',
    padding: '15px 20px',
  },
  cardName: {
    fontWeight: 600,
    textTransform: 'capitalize',
    '& > span': {
      fontWeight: 100,
      textTransform: 'lowercase',
    },
  },
  cardRow: {
    paddingLeft: 10,
    '& > p': {
      margin: 'auto',
      fontSize: '0.85rem',
    },
  },
  addCardButton: {
    width: '30%',
    margin: 'auto 0 auto auto',
    textTransform: 'capitalize',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  addCardDialogPaper: {
    width: '25%',
    minWidth: 430,
  },
}));
