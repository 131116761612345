import {
  Accordion,
  AccordionDetails,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Chip,
} from '@material-ui/core';
import { Close as CloseIcon, MoreVert as MoreVertIcon } from '@material-ui/icons';
import {
  IconLeftAccordionSummary,
  TableCellHeader,
  useStyles,
} from 'src/pages/DashboardAdministrator/servicesListPage.styles';
import React, { useEffect, useState } from 'react';

import { ActiveChip } from 'src/components/common/Global/CustomChip';
import IconCross from 'src/components/common/IconCross';
import formatDate from 'src/utils/formatDate';
import { useDeleteSpecialOffer, useUpdateSpecialOfferStatus } from 'src/hooks/queries/useSpecialOffers';
import { ISpecialOffer } from 'src/services/SpecialOffers';
import { formatCurrency } from 'src/utils/formatNumber';
import PercentChip from 'src/components/common/PercentChip';
import { DATETIME_FORMAT } from './utils';

type Props = {
  autoOpen: boolean;
  special: ISpecialOffer;
  setSelectedSpecial: (special: any) => void;
  setShowLoadingSpinner: (show: boolean) => void;
  toggleEditModal: () => void;
};

export const SpecialOfferRow = ({
  autoOpen,
  special,
  setSelectedSpecial,
  setShowLoadingSpinner,
  toggleEditModal,
}: Props) => {
  const classes = useStyles();

  const {
    name,
    enabled,
    supplierName,
    specialDescription,
    flexMarketPrice,
    flexDiscount,
    flexFinalPrice,
    launchMarketPrice,
    launchDiscount,
    launchFinalPrice,
    expirationDate,
    note,
    createdAt,
  } = special;

  const hasFlexSpecialPrice = special.flexFinalPrice > 0;
  const hasLaunchSpecialPrice = special.launchFinalPrice > 0;

  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
  const [expanded, setExpanded] = useState<boolean>(false);

  const { mutateAsync: deleteSpecial } = useDeleteSpecialOffer();
  const { mutateAsync: disableSpecial } = useUpdateSpecialOfferStatus();

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setMenuAnchor(menuAnchor ? null : event.currentTarget);

  const handleDeleteSpecialOrder = async (): Promise<void> => {
    setMenuAnchor(null);
    setShowLoadingSpinner(true);
    await deleteSpecial({ specialOfferId: special.id });
    setShowLoadingSpinner(false);
  };

  const handleDisableSpecialOrder = async (): Promise<void> => {
    setMenuAnchor(null);
    setShowLoadingSpinner(true);
    await disableSpecial(special.id);
    setShowLoadingSpinner(false);
  };

  const handleEditSpecialOrder = async (): Promise<void> => {
    setMenuAnchor(null);
    setShowLoadingSpinner(true);
    toggleEditModal();
    setSelectedSpecial(special);
  };

  useEffect(() => {
    setExpanded(autoOpen);
  }, []);

  const expandIcon = (
    <IconButton size="small" onClick={() => setExpanded(!expanded)} data-testid={`special offer expand ${special.id}`}>
      <IconCross open={expanded} />
    </IconButton>
  );

  return (
    <Accordion expanded={expanded} style={{ width: '100%', margin: '0 0 1rem 0' }}>
      <IconLeftAccordionSummary expandIcon={expandIcon}>
        <Box className={classes.accordionSummary}>
          <Box display="flex" flexDirection="row" width="456px">
            <Typography style={{ marginLeft: '8px' }} className={classes.orderDate}>
              {name}
            </Typography>
            {hasFlexSpecialPrice && <Chip className={classes.flexChip} label="Flex" color="primary" size="small" />}
            {hasLaunchSpecialPrice && (
              <Chip className={classes.launchChip} label="Launch" color="secondary" size="small" />
            )}
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography className={classes.accordionTag}>Creation Date</Typography>
            <Typography className={classes.orderDate}>{formatDate(createdAt, DATETIME_FORMAT)}</Typography>
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography className={classes.accordionTag}>Expiration Date</Typography>
            <Typography className={classes.orderDate}>{formatDate(expirationDate, DATETIME_FORMAT)}</Typography>
          </Box>
          <ActiveChip active={enabled} />
          <IconButton onClick={handleMenuClick} className={classes.orderMenu}>
            {menuAnchor ? <CloseIcon /> : <MoreVertIcon />}
          </IconButton>
          <Menu
            open={Boolean(menuAnchor)}
            anchorEl={menuAnchor}
            onClose={handleMenuClick}
            PaperProps={{ className: classes.orderMenuOpen }}
          >
            <MenuItem className={classes.orderMenuItem} onClick={handleEditSpecialOrder}>
              Edit
            </MenuItem>
            <MenuItem className={classes.orderMenuItem} onClick={handleDisableSpecialOrder}>
              {enabled ? 'Disable' : 'Enable'}
            </MenuItem>
            <MenuItem className={`${classes.orderMenuItem} ${classes.delete}`} onClick={handleDeleteSpecialOrder}>
              Delete
            </MenuItem>
          </Menu>
        </Box>
      </IconLeftAccordionSummary>

      <AccordionDetails style={{ flexDirection: 'column' }}>
        <TableContainer data-testid={`order details ${special.id}`}>
          <Table>
            <TableRow className={classes.grayHeader}>
              <TableCellHeader width="60%">Special Description</TableCellHeader>
            </TableRow>
            <TableBody className={classes.historyRow}>
              <TableRow>
                <TableCell className={classes.specialDescription}>{specialDescription}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table>
            <TableHead>
              <TableRow className={classes.grayHeader}>
                <TableCellHeader>Supplier</TableCellHeader>
                <TableCellHeader>Product Offering</TableCellHeader>
                <TableCellHeader>Market Price</TableCellHeader>
                <TableCellHeader>Discount</TableCellHeader>
                <TableCellHeader>Final Price</TableCellHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Flex Row */}
              {flexFinalPrice > 0 && (
                <TableRow>
                  <TableCell>{supplierName}</TableCell>
                  <TableCell>Flex</TableCell>
                  <TableCell>{formatCurrency(flexMarketPrice)}</TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      {formatCurrency(flexDiscount)}
                      <Box ml={1}>
                        <PercentChip dividend={flexMarketPrice - flexDiscount} divisor={flexMarketPrice} />
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>{formatCurrency(flexFinalPrice)}</TableCell>
                </TableRow>
              )}
              {/* Launch row */}
              {launchFinalPrice > 0 && (
                <TableRow>
                  <TableCell>{supplierName}</TableCell>
                  <TableCell>Launch</TableCell>
                  <TableCell>{formatCurrency(launchMarketPrice)}</TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      {formatCurrency(launchDiscount)}
                      <Box ml={1}>
                        <PercentChip dividend={launchMarketPrice - launchDiscount} divisor={launchMarketPrice} />
                      </Box>
                    </Box>
                  </TableCell>{' '}
                  <TableCell>{formatCurrency(launchFinalPrice)}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {note && (
          <Box>
            <Paper className={`${classes.orderNotes} ${classes.noBorder}`} variant="outlined">
              <Typography>{note}</Typography>
            </Paper>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
