import React, { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useSelector } from 'react-redux';
import {
  Container,
  Button,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { SQUARE_STATUS } from '../../constants/checkout.constants';
import Analytics from '../../utils/analytics';

import { ReviewModal } from '../Checkout/Footer/ReviewModal';
import { useRefundMutation, useRefundStagingMutation } from '../../hooks/serviceVisit/useCheckout';
import { userInteractionProps, USER_INTERACTION_EVENT } from '../../constants/analytics.constants';
import { CustomButton, RefundButton, secondaryButtonStyles, useStyles } from '../Checkout/Footer/footer.styles';
import { IPatientBasicInfo } from '../../types/Patient';
import { PORTRAIT_LEGACY, PORTRAIT_LEGACY_PLUS } from '../../constants/general.constants';

interface Props {
  transactionStatus: string;
  isServiceVisitOpen: boolean;
  processedInSquare: boolean;
  isAccountChargeServiceVisit?: boolean;
  pollCheckout: () => void;
  refundInProgress: boolean;
  setRefundInProgress: (state: boolean) => void;
  patient: IPatientBasicInfo;
}

export const Footer = (props: Props) => {
  const classes = useStyles();
  const practitioner = useSelector(({ auth }: any) => auth);
  const { serviceVisitId, patientId }: { serviceVisitId: string; patientId: string } = useParams();
  const { transactionStatus, processedInSquare, pollCheckout, refundInProgress, setRefundInProgress, patient } = props;

  const { isServiceVisitOpen, isAccountChargeServiceVisit } = props;
  const history = useHistory();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isDialogPositiveReviewOpen, setIsDialogPositiveReviewOpen] = useState<boolean>(false);
  const refundStagingMutation = useRefundStagingMutation(+serviceVisitId);
  const refundMutation = useRefundMutation(+serviceVisitId);

  const initiateRefundUndo = async () => {
    setRefundInProgress(true);
    if (processedInSquare) {
      await refundMutation.mutateAsync({
        patientId,
        serviceVisitId,
        processedInSquare,
        isAccountChargeServiceVisit,
      });
    } else {
      await refundStagingMutation.mutateAsync();
    }
    pollCheckout();
    setIsDialogOpen(false);
  };

  const openAcuityScheduler = () => {
    // eslint-disable-next-line max-len
    const url = `https://portrait.as.me/?appointmentType=category:${encodeURIComponent(
      practitioner.user.acuityCategory
    )}&firstName=${encodeURIComponent(patient.firstName)}&lastName=${encodeURIComponent(
      patient.lastName
    )}&email=${encodeURIComponent(patient.email)}&phone=${encodeURIComponent(patient.phone)}`;

    try {
      // @ts-ignore
      window.open(url, '_blank').focus();
    } catch (error) {
      // console.log(error);
    }
  };

  const onAskForReview = () => {
    setIsDialogPositiveReviewOpen(true);

    const segmentAnonymousId = localStorage.ajs_anonymous_id?.split('"').join('') || '';

    Analytics.identify(segmentAnonymousId, {
      type: 'provider',
      name: practitioner.name,
      email: practitioner.email,
      provider_id: practitioner.id,
    });

    Analytics.track(USER_INTERACTION_EVENT, {
      ...userInteractionProps,
      url: window.location.href,
      initiator: practitioner.id,
      event_details: { cta: true },
    });
  };

  const buttonLabel = (): string => {
    if (refundInProgress) {
      return 'IN PROGRESS';
    }
    if (processedInSquare) {
      return 'REFUND';
    }
    return 'UNDO';
  };

  const navigateBack = () => {
    history.push(
      isAccountChargeServiceVisit ? `/patient/${patientId}` : `/patient/${patientId}/newServiceVisit/${serviceVisitId}`
    );
  };

  return (
    <>
      <Divider />
      <Container className={classes.footer}>
        <div>
          {transactionStatus === SQUARE_STATUS.OK && (
            <CustomButton data-cy="back" onClick={navigateBack} style={secondaryButtonStyles}>
              Back to {isAccountChargeServiceVisit ? 'Profile' : 'Service'}
            </CustomButton>
          )}
          {transactionStatus === SQUARE_STATUS.OK && (
            <RefundButton
              data-cy="refundBtn"
              onClick={() => setIsDialogOpen(true)}
              disabled={refundInProgress || (!isServiceVisitOpen && !isAccountChargeServiceVisit)}
            >
              {buttonLabel()}
            </RefundButton>
          )}
        </div>

        <div>
          {transactionStatus === SQUARE_STATUS.OK && !refundInProgress && (
            <CustomButton data-cy="reviewBtn" onClick={onAskForReview} style={secondaryButtonStyles}>
              Ask For Review
            </CustomButton>
          )}
          {practitioner?.user?.acuityCategory &&
            (practitioner?.roleName === PORTRAIT_LEGACY || practitioner?.roleName === PORTRAIT_LEGACY_PLUS) &&
            transactionStatus === SQUARE_STATUS.OK && (
            <CustomButton onClick={openAcuityScheduler}>Schedule Next Appointment</CustomButton>
          )}
        </div>
      </Container>

      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <div className={classes.containerDialog}>
          <DialogTitle className={classes.title}>{processedInSquare ? 'Refund' : 'Undo'}</DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.content}>
              {processedInSquare ? 'Are you sure you want to make a refund?' : 'Are you sure you want to undo?'}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button className={`${classes.buttonLeft}`} onClick={() => setIsDialogOpen(false)}>
              No
            </Button>
            <Button className={`${classes.buttonRight}`} onClick={initiateRefundUndo}>
              Yes, {processedInSquare ? 'Refund' : 'Undo'}
            </Button>
          </DialogActions>
        </div>
      </Dialog>

      <ReviewModal
        isDialogPositiveReviewOpen={isDialogPositiveReviewOpen}
        setIsDialogPositiveReviewOpen={setIsDialogPositiveReviewOpen}
      />
    </>
  );
};
