import { useQuery, useInfiniteQuery, InfiniteData, useQueryClient } from 'react-query';
import { useEffect, useState } from 'react';
import {
  TRANSACTIONS_DASHBOARD,
  EARNINGS_DASHBOARD,
  GRAPHICS_DASHBOARD,
  POSSIBLE_TRANSACTIONS_DASHBOARD,
} from '../../constants/reactQuery.keys';
import {
  getTransactionsPractitioners,
  getEarningsPractitioners,
  getGraphDataPractitioners,
  getPossibleTransactionsPractitioners,
} from '../../services/PractitionersDashboard';
import { IPossibleTransaction } from '../../interfaces/ITransaction.interfaces';

export function useTransactionsDashboard(query: any, enabled: boolean = true) {
  return useInfiniteQuery(
    [TRANSACTIONS_DASHBOARD, query, enabled],
    ({ pageParam = 1 }) => getTransactionsPractitioners({ ...query, page: pageParam }),
    {
      staleTime: 60000,
      // to avoid lost the front-end order
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage: any) => {
        if (lastPage?.meta?.currentPage < lastPage?.meta?.totalPages) {
          return lastPage.meta.currentPage + 1;
        }
        return false;
      },
      enabled,
    }
  );
}

export interface PossibleTransactionParams {
  search?: string;
  location?: string;
  status?: string;
  month?: string;
  year?: string;
  page?: number;
  per?: number;
  sort?: string;
  sortDirection?: string;
}

export interface PossibleTransactionResponse {
  checkouts: IPossibleTransaction[];
  meta: {
    currentPage: number;
    totalPages: number;
    totalCount: number;
    hasMorePages: boolean;
  };
}

export function usePossibleTransactionsDashboard(query: PossibleTransactionParams) {
  const queryClient = useQueryClient();
  const [results, setResults] = useState<InfiniteData<PossibleTransactionResponse>>();

  useEffect(() => {
    queryClient.invalidateQueries([POSSIBLE_TRANSACTIONS_DASHBOARD, query]);
  }, [JSON.stringify(query)]);

  const { data, isLoading, fetchNextPage, hasNextPage, isFetching, isError, refetch } = useInfiniteQuery(
    [POSSIBLE_TRANSACTIONS_DASHBOARD, JSON.stringify(query)],
    ({ pageParam = 1 }) => getPossibleTransactionsPractitioners({ ...query, page: pageParam }),
    {
      staleTime: 60000,
      getNextPageParam: (lastPage: PossibleTransactionResponse) => {
        if (lastPage?.meta?.currentPage < lastPage?.meta?.totalPages) {
          return lastPage.meta.currentPage + 1;
        }
        return false;
      },
    }
  );

  useEffect(() => {
    if (!isLoading && data) {
      setResults(data);
    }
  }, [data, isLoading, query]);

  return {
    refetch,
    results,
    isLoading,
    isFetching,
    isError,
    fetchNextPage,
    hasNextPage,
  };
}

export function useEarningsDashboard(month: string) {
  return useQuery([EARNINGS_DASHBOARD, month], () => getEarningsPractitioners(month), {
    retry: false,
    refetchOnWindowFocus: false,
  });
}

export function useGraphsDashboard(period: string, enabled: boolean = true) {
  return useQuery([GRAPHICS_DASHBOARD, period], () => getGraphDataPractitioners(period), {
    enabled,
  });
}
