import React, { useCallback as _useCallback, useEffect, useState } from 'react';
import { Box, Button, Modal, Divider, FormHelperText, Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import classNames from 'classnames';
import {
  PORTRAIT_FLEX,
  PORTRAIT_LEGACY,
  PORTRAIT_LEGACY_PLUS,
  PORTRAIT_LAUNCH,
  PORTRAIT_ASCEND,
} from 'src/constants/general.constants';
import { IRoles } from 'src/interfaces/IRoles.interfaces';
import {
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  LICENSE,
  POSITION,
  PHONE,
  PROFILE_IMAGE,
  SERVICE_COMMISSION,
  SIGNATURE_IMAGE,
  VALID_EMAIL,
  VALID_PHONE,
  VALID_PHONE_REGEX,
} from 'src/constants/schemaForm.constants';
import ICommission from 'src/interfaces/ICommission';
import { ReactComponent as UploadProfileIcon } from 'src/assets/images/upload-profile-image.svg';
import DragAndDropComponent from 'src/components/DragAndDrop';
import { useCreateV4UserGroup } from 'src/hooks/queries/useUserGroups';
import { usePhysicians } from 'src/hooks/queries/usePhysicians';
import { IPhysician } from 'src/interfaces/IPhysician';
import {
  EHRAutocompleteSelect,
  EHRButton,
  EHRCRUDModal,
  EHRCheckbox,
  EHRSelect,
  EHRTextField,
  EHRTypography,
  IAutocompleteSelectOption,
} from 'src/components/ui/v1';
// import { debounce } from 'lodash';
import { useStyles } from './MedspaModal.styles';
import { OperatingProviderForm } from './OperatingProviderForm';

export const medspaSchema = yup.object().shape({
  medspaName: yup.string().required('MedSpa name is required field'),
  roleId: yup.string().required('Product type is required field'),
  firstName: yup.string().required(FIRST_NAME),
  lastName: yup.string().required(LAST_NAME),
  email: yup.string().required(EMAIL).email(VALID_EMAIL),
  phone: yup.string().required(PHONE).matches(VALID_PHONE_REGEX, VALID_PHONE),
  licenses: yup.string().when('isOperatingProvider', (value: any) => {
    if (value) {
      return yup.string().required(LICENSE).nullable();
    }
    return yup.string().nullable();
  }),
  commissionId: yup.string().when('isLegacy', (value: any) => {
    if (value) {
      return yup.string().required(SERVICE_COMMISSION);
    }
    return yup.string().nullable();
  }),
  signatureImage: yup.string().when('isOperatingProvider', (value: any) => {
    if (value) {
      return yup.string().required(SIGNATURE_IMAGE).nullable();
    }
    return yup.string().nullable();
  }),
  profileImage: yup.string().required(PROFILE_IMAGE),
  position: yup.string().when('isOperatingProvider', (value: any) => {
    if (value) {
      return yup.string().required(POSITION).nullable();
    }
    return yup.string().nullable();
  }),
  medicalDirectorId: yup
    .string()
    .when(
      ['hasMedicalDirector', 'customMedicalDirector'],
      (hasMedicalDirector: boolean, customMedicalDirector: string) => {
        if (hasMedicalDirector && !customMedicalDirector) {
          return yup.string().required('Medical director is a required field');
        }
        return yup.string().nullable();
      }
    ),
  customMedicalDirector: yup.string(),
});

type Props = {
  open: boolean;
  onClose: () => void;
  roles: IRoles[];
  commissions: ICommission[];
};

export const NewMedSpaModal = ({ open, onClose, roles, commissions }: Props) => {
  const classes = useStyles();

  const [selectedProductType, setSelectedProductType] = useState<string>();
  const [medspaActive, setMedspaActive] = useState<boolean>(true);
  const [hasMedicalDirector, setHasMedicalDirector] = useState<boolean>(true);
  const [profilePreview, setProfilePreview] = useState<string | null>(null);
  const [profileImageFile, setProfileImageFile] = useState<File>();
  const [medicalDirectorSearch, setMedicalDirectorSearch] = useState<string>('');
  const [selectedMedicalDirector, setSelectedMedicalDirector] = useState<IAutocompleteSelectOption | null>(null);

  const [isOperatingProvider, setIsOperatingProvider] = useState<boolean>(false);
  const [signaturePreview, setSignaturePreview] = useState<string | null>(null);
  const [signatureImageFile, setSignatureImageFile] = useState<File>();

  const [physicianParams, _setPhysicianParams] = useState<Record<string, string>>({ search: medicalDirectorSearch });

  const isLegacy = selectedProductType === PORTRAIT_LEGACY || selectedProductType === PORTRAIT_LEGACY_PLUS;

  const methods = useForm({
    resolver: yupResolver(medspaSchema),
    defaultValues: {
      medspaName: '',
      roleName: '',
      googleReviewLink: '',
      squareLocationId: '',
      active: true,
      firstName: '',
      lastName: '',
      profileImage: null,
      signatureImage: null,
      email: '',
      phone: '',
      commissionId: '',
      position: '',
      licenses: '',
      medicalDirectorId: '',
      hasMedicalDirector: true,
      isOperatingProvider: false,
      customMedicalDirector: '',
      isLegacy,
    },
  });
  const { control, reset, errors, handleSubmit, setValue, watch } = methods;

  const handleClose = () => {
    setSelectedProductType('');
    setIsOperatingProvider(false);
    setMedspaActive(true);
    setHasMedicalDirector(true);
    setProfilePreview(null);
    setSignaturePreview(null);
    reset();
    onClose();
  };

  const { mutateAsync: createGroup, isLoading: isCreating } = useCreateV4UserGroup(handleClose);
  const { data: medicalDirectors = [], isLoading: isLoadingMedicalDirectors } = usePhysicians(physicianParams, open);

  const watchCustomMedicalDirector = watch(['customMedicalDirector']);

  const handleSelectMedicalDirector = (medicalDirectorOption: IAutocompleteSelectOption | null) => {
    if (!medicalDirectorOption) {
      setSelectedMedicalDirector(null);
      setValue('medicalDirectorId', undefined);
      setValue('customMedicalDirector', '');
      return;
    }
    const medicalDirectorIndex = medicalDirectors.findIndex((e: IPhysician) => e.id === medicalDirectorOption.value);
    if (medicalDirectorIndex < 0) {
      return;
    }
    const medicalDirector = medicalDirectors[medicalDirectorIndex];
    setSelectedMedicalDirector(medicalDirectorOption);
    setValue('medicalDirectorId', String(medicalDirector.id));
    setValue('customMedicalDirector', '');
  };

  const onSubmit = async (formData: any) => {
    const data = {
      signatureImage: signatureImageFile,
      profileImage: profileImageFile,
      firstName: formData.firstName,
      lastName: formData.lastName,
      active: medspaActive,
      name: formData.medspaName,
      googleReviewLink: formData.googleReviewLink,
      squareLocationId: formData.squareLocationId,
      email: formData.email,
      phone: formData.phone,
      position: formData.position,
      licenses: formData.licenses,
      medicalDirectorId: formData.medicalDirectorId,
      provisionProviderAccount: isOperatingProvider,
      commissionId: formData.commissionId,
      roleId: formData.roleId,
      activeForScheduling: false,
      customMedicalDirector: formData.customMedicalDirector,
    };
    await createGroup(data);
  };

  const handleProfilePicChange = (fileData: File[]) => {
    const file = fileData?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePreview((reader.result || '').toString());
        // @ts-ignore
        setValue('profileImage', file, { shouldValidate: true });
        setProfileImageFile(file);
      };
      reader.readAsDataURL(file);
    } else {
      setValue('profileImage', null, { shouldValidate: true });
    }
  };

  useEffect(() => {
    if (commissions?.length > 0) {
      if (isLegacy) {
        const commissionId = (commissions?.find((commission) => commission.defaultCommission)?.id || '').toString();
        setValue('commissionId', commissionId);
      } else {
        const commissionId = (
          commissions?.find((commission) => commission.shortDescription === '100.0%')?.id || ''
        ).toString();
        setValue('commissionId', commissionId);
      }
    }
  }, [commissions, setValue, open, isLegacy]);

  // const doSearch = useCallback(
  //   debounce((payload: string) => {
  //     setPhysicianParams({ search: payload });
  //   }, 500),
  //   []
  // );

  // useEffect(() => {
  //   doSearch(medicalDirectorSearch)
  // }, [medicalDirectorSearch]);

  useEffect(() => {
    setValue('isLegacy', isLegacy);
    setValue('isOperatingProvider', isLegacy || isOperatingProvider);
    setIsOperatingProvider(isLegacy || isOperatingProvider);
  }, [isLegacy, isOperatingProvider]);

  useEffect(() => {
    const matchingProduct = roles?.find((role) => role.name === selectedProductType);
    if (matchingProduct) {
      setValue('roleId', matchingProduct.id);
    }
  }, [selectedProductType, roles]);

  return !selectedProductType ? (
    <Modal open={open} onClose={handleClose}>
      <Box className={classes.modalProductSelectModal}>
        <Box className={classes.modalProductSelectContainer}>
          <Box className={classes.modalProductSelectBody}>
            <Typography variant="h6">New MedSpa</Typography>
            <Typography className={classes.subTitle}>Please select a product type</Typography>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={6}>
                <EHRButton
                  dataCy="btn-product-portrait-launch"
                  color="default"
                  className={classes.productOption}
                  onClick={() => setSelectedProductType(PORTRAIT_LAUNCH)}
                  text="Portrait Launch"
                />
              </Grid>
              <Grid item xs={6}>
                <EHRButton
                  dataCy="btn-product-portrait-ascend"
                  color="default"
                  className={classes.productOption}
                  onClick={() => setSelectedProductType(PORTRAIT_ASCEND)}
                  text="Portrait Ascend"
                />
              </Grid>
              <Grid item xs={6}>
                <EHRButton
                  dataCy="btn-product-portrait-legacy"
                  color="default"
                  className={classes.productOption}
                  onClick={() => setSelectedProductType(PORTRAIT_LEGACY)}
                  text="Portrait Legacy"
                />
              </Grid>
              <Grid item xs={6}>
                <EHRButton
                  dataCy="btn-product-portrait-flex"
                  color="default"
                  className={classes.productOption}
                  onClick={() => setSelectedProductType(PORTRAIT_FLEX)}
                  text="Portrait Flex"
                />
              </Grid>
              <Grid item xs={6}>
                <EHRButton
                  dataCy="btn-product-portrait-legacy-plus"
                  color="default"
                  className={classes.productOption}
                  onClick={() => setSelectedProductType(PORTRAIT_LEGACY_PLUS)}
                  text="Portrait Legacy Plus"
                />
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box className={classes.buttonContainer}>
            <EHRButton dataCy="btn-cancel-new-medspa-modal" color="default" onClick={handleClose} text="Cancel" />
          </Box>
        </Box>
      </Box>
    </Modal>
  ) : (
    <EHRCRUDModal dataCy="modal-new-medspa" open={open} onClose={handleClose}>
      <EHRCRUDModal.Title dataCy="modal-new-medspa-modal-title" title="New MedSpa" handleClose={handleClose} />

      <EHRCRUDModal.Body dataCy="modal-new-medspa-modal-body">
        <Box display="flex" justifyContent="space-between" flexDirection="column" gridGap="0.25rem" width="100%">
          <Box>
            <EHRTextField
              dataCy="input-productType"
              name="roleName"
              value={selectedProductType}
              label="Product Type"
              helperText=" "
              disabled
            />
          </Box>

          <Box>
            <Controller
              control={control}
              name="medspaName"
              rules={{ required: 'Name is required' }}
              render={({ onChange, value }) => (
                <EHRTextField
                  dataCy="input-medspaName"
                  name="medspaName"
                  onChange={onChange}
                  value={value}
                  label="MedSpa Name"
                  error={!!errors?.medspaName}
                  helperText={errors?.medspaName?.message || ' '}
                />
              )}
            />
          </Box>

          {isLegacy && (
            <Box display="flex" flexDirection="row" gridGap="1rem">
              <Controller
                control={control}
                name="commissionId"
                rules={{ required: 'Commission is required' }}
                render={({ onChange, value }) => (
                  <EHRSelect
                    dataCy="select-commissionId"
                    name="commissionId"
                    fullWidth
                    value={value}
                    onChange={onChange}
                    label="Commission"
                    error={!!errors?.commissionId}
                    helperText={errors?.commissionId?.message || ' '}
                  >
                    {commissions && commissions.length ? (
                      commissions
                        .sort((a, b) => {
                          const commissionRateA = +a.shortDescription.replace('%', '');
                          const commissionRateB = +b.shortDescription.replace('%', '');
                          if (commissionRateB <= commissionRateA) {
                            return 1;
                          }
                          return -1;
                        })
                        .map(({ id, shortDescription }) => <MenuItem value={id}>{shortDescription}</MenuItem>)
                    ) : (
                      <MenuItem value="--">No items found</MenuItem>
                    )}
                  </EHRSelect>
                )}
              />
            </Box>
          )}
          <Box display="flex" flexDirection="row" gridGap="1rem">
            <Box flex={1}>
              <Controller
                control={control}
                name="googleReviewLink"
                render={({ onChange, value }) => (
                  <EHRTextField
                    dataCy="input-googleReviewLink"
                    name="googleReviewLink"
                    onChange={onChange}
                    value={value}
                    label="Google Review Link"
                    error={!!errors?.googleReviewLink}
                    helperText={errors?.googleReviewLink?.message || ' '}
                  />
                )}
              />
            </Box>

            <Box flex={1}>
              <Controller
                control={control}
                name="squareLocationId"
                render={({ onChange, value }) => (
                  <EHRTextField
                    dataCy="input-squareLocationId"
                    name="squareLocationId"
                    onChange={onChange}
                    value={value}
                    label="Square Location ID"
                    tooltip={
                      'Note: If this MedSpa does not have an existing Square account, leave this field empty. ' +
                      'The system will automatically create a new Square location and assign it to this MedSpa.'
                    }
                    error={!!errors?.squareLocationId}
                    helperText={errors?.squareLocationId?.message || ' '}
                  />
                )}
              />
            </Box>
          </Box>
          <Box>
            <Box>
              <EHRTypography dataCy="label-medspa-status" variant="label">
                MedSpa Status
              </EHRTypography>
              <Box className={classes.medspaActiveStatusContainer}>
                <Button
                  className={medspaActive ? classes.medspaActiveButton : classes.medspaInactiveButton}
                  type="button"
                  onClick={() => setMedspaActive(true)}
                >
                  Active
                </Button>
                <Button
                  className={medspaActive ? classes.medspaInactiveButton : classes.medspaActiveButton}
                  type="button"
                  onClick={() => setMedspaActive(false)}
                >
                  Inactive
                </Button>
              </Box>
              <FormHelperText error>{errors?.active?.message || ' '}</FormHelperText>
            </Box>
          </Box>
          <Box>
            <Box>
              <EHRTypography dataCy="label-medspa-have-medical-director" variant="label">
                Have Medical Director?
              </EHRTypography>
              <Box className={classes.medspaActiveStatusContainer}>
                <Button
                  className={hasMedicalDirector ? classes.medspaActiveButton : classes.medspaInactiveButton}
                  type="button"
                  onClick={() => {
                    setHasMedicalDirector(true);
                    setValue('hasMedicalDirector', true);
                  }}
                >
                  Yes
                </Button>
                <Button
                  className={hasMedicalDirector ? classes.medspaInactiveButton : classes.medspaActiveButton}
                  type="button"
                  onClick={() => {
                    setHasMedicalDirector(false);
                    setValue('hasMedicalDirector', false);
                  }}
                >
                  No
                </Button>
              </Box>
              <FormHelperText error>{errors?.medicalDirectorId?.message || ' '}</FormHelperText>
            </Box>
          </Box>
          {hasMedicalDirector && (
            <Box>
              <EHRAutocompleteSelect
                dataCy="autocompleteselect-medical-director"
                options={medicalDirectors.map((p) => ({
                  name: p.professionalName || '-',
                  value: p.id,
                }))}
                openOnFocus
                clearOnEscape
                label="Medical Director"
                onChange={handleSelectMedicalDirector}
                inputValue={medicalDirectorSearch}
                onInputChange={(_event, newInputValue) => {
                  setMedicalDirectorSearch(newInputValue);
                }}
                value={selectedMedicalDirector}
                loading={isLoadingMedicalDirectors}
                getOptionLabel={(option: IAutocompleteSelectOption) => `${option.name}`}
                fullWidth
                renderInput={(params: any) => (
                  <TextField {...params} label="Search Medical Director" variant="outlined" />
                )}
              />

              <Box>
                <h4>OR</h4>
              </Box>

              <Box>
                <Controller
                  control={control}
                  name="customMedicalDirector"
                  render={() => (
                    <EHRTextField
                      dataCy="input-customMedicalDirector"
                      name="customMedicalDirector"
                      onChange={(e: any) => {
                        setValue('medicalDirectorId', '');
                        setValue('customMedicalDirector', e.target.value);
                      }}
                      value={watchCustomMedicalDirector.customMedicalDirector}
                      label="Own Medical Director"
                      error={!!errors?.customMedicalDirector}
                      helperText={errors?.customMedicalDirector?.message || ' '}
                    />
                  )}
                />
              </Box>
            </Box>
          )}
          <Divider
            style={{
              position: 'relative',
              left: '-2rem',
              width: 'calc(100% + 4rem)',
            }}
          />
          <Box>
            <h3>Admin Details</h3>
          </Box>
          <Box
            className={classNames(classes.headShotContainer, errors?.profileImage ? 'ehrUploadContainerError' : null)}
          >
            <EHRTypography
              dataCy="label-upload-headsot"
              variant="label"
              error={!!errors?.profileImage}
              style={{ marginBottom: '0.5rem' }}
            >
              Upload Headshot
            </EHRTypography>
            <input
              accept="image/*"
              id="profile-photo-upload"
              type="file"
              name="profilePicture"
              style={{ display: 'none' }}
            />
            <label htmlFor="profile-photo-upload" className={classes.uploadProfileImageContainer}>
              {profilePreview ? (
                <Box display="flex" alignItems="center">
                  <img src={profilePreview} alt="Profile preview" className={classes.profileImage} />
                </Box>
              ) : (
                <UploadProfileIcon className={classes.profileImage} />
              )}
              <DragAndDropComponent
                hideDocumentIcon
                height="80px"
                hideRemoveFile
                acceptedFileType="image/png,image/jpg,image/jpeg"
                onFileChange={handleProfilePicChange}
                error={!!errors?.profileImage}
              />
            </label>
            <FormHelperText error>{errors?.profileImage?.message || ' '}</FormHelperText>
          </Box>
          <Box display="flex" flexDirection="row" gridGap="1rem">
            <Box flex={1}>
              <Controller
                control={control}
                name="firstName"
                rules={{ required: 'First name is required' }}
                render={({ onChange, value }) => (
                  <EHRTextField
                    dataCy="input-firstName"
                    name="firstName"
                    onChange={onChange}
                    value={value}
                    label="First Name"
                    error={!!errors?.firstName}
                    helperText={errors?.firstName?.message || ' '}
                  />
                )}
              />
            </Box>
            <Box flex={1}>
              <Controller
                control={control}
                name="lastName"
                rules={{ required: 'Last name is required' }}
                render={({ onChange, value }) => (
                  <EHRTextField
                    dataCy="input-lastName"
                    name="lastName"
                    onChange={onChange}
                    value={value}
                    label="Last Name"
                    error={!!errors?.lastName}
                    helperText={errors?.lastName?.message || ' '}
                  />
                )}
              />
            </Box>
          </Box>
          <Box>
            <Controller
              control={control}
              name="email"
              rules={{ required: 'Email is required' }}
              render={({ onChange, value }) => (
                <EHRTextField
                  dataCy="input-email"
                  name="email"
                  onChange={onChange}
                  value={value}
                  label="Email"
                  error={!!errors?.email}
                  helperText={errors?.email?.message || ' '}
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              control={control}
              name="phone"
              rules={{ required: 'Phone number is required' }}
              render={({ onChange, value }) => (
                <EHRTextField
                  dataCy="input-phone"
                  name="phone"
                  onChange={onChange}
                  value={value}
                  label="Phone Number"
                  error={!!errors?.phone}
                  helperText={errors?.phone?.message || ' '}
                />
              )}
            />
          </Box>
          <Box className={classes.operatingProviderContainer}>
            <Controller
              name="isOperatingProvider"
              control={control}
              defaultValue={false}
              render={() => (
                <EHRCheckbox
                  disabled={isLegacy}
                  dataCy="label-is-operating-provider"
                  label="Operating Provider"
                  checked={isOperatingProvider}
                  onChange={(e) => setIsOperatingProvider(e.target.checked)}
                  className={classes.operatingProviderCheckbox}
                >
                  <Typography variant="body2" className={classes.operatingProviderSubtext}>
                    If this is checked the MedSpa Admin account will be created with a Provider dashboard experienced
                    linked to it
                  </Typography>
                </EHRCheckbox>
              )}
            />
          </Box>

          {isOperatingProvider && (
            <OperatingProviderForm
              methods={methods}
              signaturePreview={signaturePreview}
              setSignaturePreview={setSignaturePreview}
              setSignatureImageFile={setSignatureImageFile}
            />
          )}
        </Box>
      </EHRCRUDModal.Body>

      <EHRCRUDModal.Footer dataCy="modal-footer-new-medspa">
        <Box display="flex" width="100%">
          <EHRButton
            dataCy="btn-add-modal-new-medspa"
            onClick={handleSubmit(onSubmit)}
            disabled={isCreating}
            color="primary"
            text={isCreating ? 'Processing...' : 'Add'}
            fullWidth
          />
        </Box>
      </EHRCRUDModal.Footer>
    </EHRCRUDModal>
  );
};
