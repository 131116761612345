import { pickBy, snakeCase } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { ShowSnackbarOptions } from 'src/types/SnackbarState';
import { Duration, IErrorHandlerRequiredError, IErrorHandlerRequiredParams } from '../interfaces/global.interfaces';
import { dispatch } from '../rematch';
import compile from './toastMessagesCompiler';
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from '../constants/general.constants';

export const toSnakeCase = (obj: any) => {
  const newObj: any = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(obj)) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      newObj[snakeCase(key)] = obj[key];
    }
  }

  return newObj;
};

export function snakeToCamelCase<T extends string>(str: string): T {
  return str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', '')) as T;
}

export const requiredOptionsAreIncluded = (requiredOptions: number[], optionsSelected: number[]): boolean =>
  !requiredOptions.some((serviceId) => !optionsSelected.includes(serviceId));

// return true if param date + duration > today
export const hasMoreThanDate = (date: string, amount: number, duration: Duration) =>
  moment().isSameOrAfter(moment(date).add(amount, duration));

export const filterByUniqueKey = (array: any, key: string) =>
  array.filter((v: any, i: any, a: any[]) => a.findIndex((t: any) => t[key] === v[key]) === i);

export const useOnScreen = (ref: any) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));
  useEffect(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
};

export const showGlobalErrorSnackbar = ({
  error,
  params,
  errorPriority = false,
}: IErrorHandlerRequiredError | IErrorHandlerRequiredParams) => {
  /* This function is used to show the error message snackbar
   * error: example => 'Something went wrong'
   * params: example => { action: 'fetching', element: 'services' }
   * errorPriority: example => true means the error param is shown before params if present
   */
  const message = errorPriority
    ? error || compile('generic.error_message', params)
    : compile('generic.error_message', params) || error;

  dispatch({
    type: 'snackbar/enqueueSnackBar',
    payload: {
      message,
      type: 'error',
    },
  });
};

export const showSnackbar = (message: string, type: string = SNACKBAR_ERROR, options?: ShowSnackbarOptions) => {
  dispatch({
    type: 'snackbar/enqueueSnackBar',
    payload: {
      message,
      type,
      ...options,
    },
  });
};

export const reactQueryOnSuccessHandler = (response: any, successCallback?: () => void) => {
  let errorMessages = [];
  if (!response?.success) {
    if (response?.message || response?.error) {
      errorMessages.push(response.message || response.error);
    }
    if (response?.messages || response?.errors) {
      errorMessages = response?.messages || response?.errors;
    }
    if (errorMessages && errorMessages.length > 0) {
      errorMessages.forEach((error: string) => showSnackbar(error));
      return;
    }
  }
  let successMessages = [];
  if (successCallback) {
    successCallback();
    return;
  }

  if (response?.message) {
    successMessages.push(response.message);
  }
  if (response?.messages && response.messages.length > 0) {
    successMessages = response.messages;
  }
  successMessages.forEach((message: string) => showSnackbar(message, SNACKBAR_SUCCESS));
};

export const useReactQueryOnErrorHandler = (newError: any) => {
  const response = newError?.response?.data || newError;

  let errorMessage = '';
  try {
    if (typeof response === 'string') {
      errorMessage = response || compile('generic.server_error');
    } else if (typeof response?.error === 'string') {
      errorMessage = response.error;
    } else if (typeof response?.errors === 'string') {
      errorMessage = response.errors;
    } else if (typeof response?.errors === 'object') {
      errorMessage = response.errors.join(', ');
    } else {
      const {
        response: {
          data: { message, error },
        },
      } = response;

      errorMessage = message || error || compile('generic.server_error');
    }
  } catch (error) {
    errorMessage = compile('generic.server_error');
  }
  showSnackbar(errorMessage);
};

export const onClassChangeEvent = (element: any, callback: (node: any) => void) => {
  const observer = new MutationObserver((mutations: any) => {
    mutations.forEach((mutation: any) => {
      if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
        callback(mutation.target);
      }
    });
  });
  observer.observe(element, { attributes: true });
  return observer.disconnect;
};

export const filterUnusedParams = <ObjectType, ParamTypes>(params: ObjectType): ObjectType =>
  pickBy(params as object, (param: ParamTypes): boolean => !!param || param === 0) as ObjectType;

export const upperCaseKeys = (obj: Record<string, any>) => {
  const entries = Object.entries(obj);
  const capsEntries = entries.map((entry) => [entry[0][0].toUpperCase() + entry[0].slice(1), entry[1]]);
  return Object.fromEntries(capsEntries);
};
