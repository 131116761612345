import React, { ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Color } from '@material-ui/lab';
import { VariantType, useSnackbar, SnackbarKey } from 'notistack';

import { dispatch } from '../../../rematch';
import { SnackbarState, SnackMessage } from '../../../types/SnackbarState';

import NotifierCustomComponent from './NotifierCustomComponent';
import useOnlineStatus from '../../../hooks/useOnlineStatus';
import ToastMessage from '../../../constants/toast.messages.templates';

let displayed: any[] = [];

const Notifier: any = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { notifications = [] } = useSelector(({ snackbar }: any) => snackbar as SnackbarState);
  const { onlineStatus } = useOnlineStatus();

  useEffect(() => {
    // Ensure that the message only shows up once to avoid spamming the user with it
    if (!onlineStatus) {
      const errorMessageIndex = notifications.findIndex(
        (element) => element.message === ToastMessage['generic.no_connection_message']
      );

      if (errorMessageIndex !== -1) {
        notifications.splice(0, errorMessageIndex);
        notifications.splice(1);
      }
    }
  }, [notifications]);

  const storeDisplayed = (id: any) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: any) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  const onClickDismissAction = (key: any) => {
    closeSnackbar(key);
    dispatch({
      type: 'snackbar/closeSnackbar',
      payload: {
        key,
      },
    });
  };

  const renderCustomContent = (
    key: SnackbarKey,
    type: Color,
    message: ReactNode,
    action: any,
    style?: React.CSSProperties,
    variant?: 'filled' | 'outlined'
  ) => (
    <NotifierCustomComponent
      style={style}
      variant={variant}
      type={type}
      message={message}
      action={action}
      onClose={() => onClickDismissAction(key)}
    />
  );

  useEffect(() => {
    notifications.forEach(({ key, message, dismissed = false, type, style, variant, ...options }: SnackMessage) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (key && displayed.includes(key)) {
        return;
      }

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        variant: type as VariantType,
        ...options,
        content: (snackKey) => renderCustomContent(snackKey, type as Color, message, options.action, style, variant),
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => {
          // remove this snackbar from redux store
          dispatch({
            type: 'snackbar/removeSnackbar',
            payload: {
              key: myKey,
            },
          });
          removeDisplayed(myKey);
        },
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [notifications]);

  return null;
};

export default Notifier;
