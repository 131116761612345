import React from 'react';
import { CardContent, Chip } from '@material-ui/core';
import { useStyles } from './ChargeCardStep.styles';
import AccountCredit from './AccountCredit';
import SelectProducts from './SelectProducts';
import CancellationFee from './CancellationFee';
import OtherFee from './OtherFee';

export const PRODUCT = 'Products';
export const ACCOUNT_CREDIT = 'Account Credit';
const CANCELLATION_FEE = 'Cancellation Fee';
const OTHER_FEE = 'Other';
const ChargeCardStep = ({
  patientId,
  setChargeType,
  chargeType,
  setSelectedServices,
  selectedServices,
  medspaLocationId,
  onInteraction,
}: {
  patientId: string;
  chargeType: string;
  setChargeType: (chargeType: string) => void;
  setSelectedServices: (services: number[]) => void;
  selectedServices: number[];
  medspaLocationId?: number | null;
  onInteraction: () => void;
}) => {
  const classes = useStyles();
  const chargeActions = [PRODUCT, ACCOUNT_CREDIT, CANCELLATION_FEE, OTHER_FEE];

  const renderActionComponent = () => {
    switch (chargeType) {
      case PRODUCT:
        return <SelectProducts setSelectedServices={setSelectedServices} selectedServices={selectedServices} />;
      case CANCELLATION_FEE:
        return <CancellationFee patientId={patientId} medspaLocationId={medspaLocationId} />;
      case OTHER_FEE:
        return <OtherFee patientId={patientId} medspaLocationId={medspaLocationId} />;
      case ACCOUNT_CREDIT:
      default:
        return <AccountCredit patientId={patientId} medspaLocationId={medspaLocationId} />;
    }
  };

  return (
    <CardContent className={classes.card}>
      <div className={classes.title}>Choose the type of charge</div>
      {chargeActions.map((action) => (
        <Chip
          key={action}
          label={action}
          className={action === chargeType ? classes.activePill : classes.inactivePill}
          onClick={() => {
            setChargeType(action);
            onInteraction();
          }}
        />
      ))}
      <hr />
      <div className={classes.subComponent} onFocus={() => onInteraction()}>
        {renderActionComponent()}
      </div>
    </CardContent>
  );
};

export default ChargeCardStep;
