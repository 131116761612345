import { Box, Typography, withStyles, Chip } from '@material-ui/core';
import React, { useContext, useState, useEffect } from 'react';
import { formatCurrency } from 'src/utils/formatNumber';
import { InventoryOrderItem, InventoryOrderSpecial } from 'src/interfaces/IInventoryOrder';
import { ClassNameMap } from 'src/types/Dom';
import { InventoryOrderCustomItem } from 'src/services/InventoryOrderCustomItems';
import moment from 'moment';
import { OrderContext } from '..';
import OrderConfirmPanel from './OrderConfirmPanel';

const calculateTimeLeft = (expirationDate: Date | string): string => {
  const duration = moment.duration(moment(expirationDate).diff(moment()));

  if (duration.asSeconds() <= 0) {
    return 'Expired';
  }

  const days = Math.floor(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  if (days > 0) {
    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  }
  if (hours > 0) {
    return `${hours}h ${minutes}m ${seconds}s`;
  }
  if (minutes > 0) {
    return `${minutes}m ${seconds}s`;
  }
  return `${seconds}s`;
};

const OrderOverview = ({ classes }: ClassNameMap): React.ReactElement => {
  const { orderData: { services = [], specials = [], customItems = [] } = {} } = useContext(OrderContext) || {};

  const filteredSpecials = specials.filter((special) => special.quantity > 0);

  return (
    <OrderConfirmPanel title="Order Overview">
      <Box className={classes?.root}>
        <Box>
          <Typography>Product</Typography>
          <Typography>Qty</Typography>
          <Typography>Total</Typography>
        </Box>
        {customItems
          .filter(({ quantity }: InventoryOrderCustomItem): boolean => !!quantity)
          .map(
            ({ name, quantity, subtotal }: InventoryOrderCustomItem): React.ReactElement => (
              <Box key={name}>
                <Typography>{name}</Typography>
                <Typography>{quantity}</Typography>
                <Typography>{subtotal > 0 ? formatCurrency(subtotal) : 'awaiting review'}</Typography>
              </Box>
            )
          )}
        {services
          .filter(({ quantity }: InventoryOrderItem): boolean => !!quantity)
          .map(
            ({ name, quantity, subtotal }: InventoryOrderItem): React.ReactElement => (
              <Box key={name}>
                <Typography>{name}</Typography>
                <Typography>{quantity}</Typography>
                <Typography>{formatCurrency(subtotal)}</Typography>
              </Box>
            )
          )}

        <SpecialsComponent filteredSpecials={filteredSpecials} />
      </Box>
    </OrderConfirmPanel>
  );
};

type Props = {
  filteredSpecials: InventoryOrderSpecial[];
};

const SpecialsComponent = ({ filteredSpecials }: Props) =>
  filteredSpecials.length === 0 ? null : (
    <>
      <Box style={{ margin: '20px 0 0 0' }}>
        <Typography>Special</Typography>
        <Typography>Qty</Typography>
        <Typography>Total</Typography>
      </Box>
      {filteredSpecials?.map((special) => (
        <Box key={special.id} style={{ marginBottom: '10px', color: '#777' }}>
          <Box>
            <Box display="flex" alignItems="center" flexWrap="wrap">
              <Typography>{special.specialOffer.name}</Typography>
              <CountdownChip expirationDate={special.specialOffer.expirationDate} />
            </Box>
            <Typography
              variant="body2"
              style={{
                fontSize: '0.8em',
                color: '#999',
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-word',
              }}
            >
              {special.productList}
            </Typography>
          </Box>{' '}
          <Typography style={{ borderBottom: 'none' }}>{special.quantity}</Typography>
          <Typography style={{ borderBottom: 'none' }}>{formatCurrency(special.price)}</Typography>
        </Box>
      ))}
    </>
  );

interface CountdownChipProps {
  expirationDate: Date | string;
}

const CountdownChip: React.FC<CountdownChipProps> = ({ expirationDate }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(expirationDate));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(expirationDate));
    }, 1000);

    return () => clearInterval(timer);
  }, [expirationDate]);

  return (
    <Chip
      label={`Expires in: ${timeLeft}`}
      size="small"
      style={{
        backgroundColor: '#CBEAC9',
        color: '#256E20',
        marginLeft: '8px',
        fontSize: '0.8em',
      }}
    />
  );
};

export default withStyles((theme) => ({
  root: {
    maxHeight: 450,
    backgroundColor: '#fff',
    overflowY: 'scroll',
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& > div': {
      display: 'grid',
      gridTemplateColumns: '2fr 1fr 1fr',
    },
    '& > div > p': {
      fontSize: 13,
      margin: '10px 0 5px 0',
      paddingBottom: 5,
      borderBottom: '1px solid #eee',
    },
    '& > div:nth-child(2) > p': {
      margin: '20px 0 0 0',
      color: '#777',
      borderBottom: 'none',
    },
  },
  heading: {
    width: '100%',
    borderBottom: '1px solid #eee',
    textAlign: 'left',
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
}))(OrderOverview);
