import { makeStyles } from '@material-ui/core/styles';

export const useResourcesStyles = makeStyles(() => ({
  resourcesContainer: {
    paddingTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  resourcesToolbar: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
  resourcesListContainer: {
    backgroundColor: '#fff',
    borderRadius: '0.625rem',
    border: '1px solid #DFDFDF',
    padding: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem',
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.75rem',
    width: '100%',
    '& *': {
      margin: 0,
    },
  },
  resourceLink: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  tag: {
    backgroundColor: '#FFD585',
    borderRadius: '4px',
    padding: '4px 8px',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    color: '#6B4000',
  },
}));
