/* eslint-disable @typescript-eslint/indent */
/* eslint-disable max-len */
import React, { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  makeStyles,
  Tooltip,
  Checkbox,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  FormControlLabel,
} from '@material-ui/core';
import { debounce, sortBy } from 'lodash';
import ButtonAhref from '../../common/ButtonAhref';
import { hasAccessTo } from '../../../utils/auth.utils';
import { CREATE_PURCHASE_ORDER } from '../../../constants/actions.constants';
import InputSearch from '../../common/InputSearch';
import { DEBOUNCE_SEARCH_TIME } from '../../../constants/general.constants';
import { useMedspaPractitioners } from '../../../hooks/queries/usePractitioners';
import useSuppliers from '../../../hooks/queries/useSuppliers';

type IOnChangeFilters =
  | HTMLInputElement
  | {
      name?: string | undefined;
      value: unknown;
    };

export interface PurchaseOrdersHeaderProps {
  toggleModal: () => void;
  data: { assets: number; pages: number; percentageUsed: number };
  onChangeFilters: (event: React.ChangeEvent<IOnChangeFilters>) => void;
  onChangeSearch: (newSearch: string) => void;
  isFetching: boolean;
  waitingToReceive?: boolean;
  provider?: string;
  supplier?: string;
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'left',
    gap: '30px',
  },
  message: {
    height: '40px',
    background: '#F2D1AB',
    lineHeight: '40px',
    padding: '0px 5px',
    marginTop: '10px',
  },
  dropdown: {
    fontFamily: 'Messina Sans Regular',
    height: '40px',
    minWidth: '200px',
  },
  formDropdown: {
    display: 'flex',
    gap: '30px',
    flexDirection: 'row',
  },
  formInputSelect: {
    top: '-7px',
    color: '#000',
  },
  formControlLabel: {
    minWidth: 'fit-content',
  },
}));

const PurchaseOrdersHeader: FC<PurchaseOrdersHeaderProps> = ({
  toggleModal,
  data,
  onChangeFilters,
  onChangeSearch,
  isFetching,
  waitingToReceive,
  provider,
  supplier,
}) => {
  const classes = useStyles();
  const permissions = useSelector(({ auth }: any) => auth.permissions);
  const [bulkActions, setBulkAction] = useState<string | number>('');
  const [search, setSearch] = useState<string>('');
  const { data: providers = [], isLoading: isFetchingProviders } = useMedspaPractitioners(
    {},
    true,
    () => 'v3/practitioners'
  );
  const { data: suppliers = [], isFetching: isFetchingSuppliers } = useSuppliers();

  const doSearch = useMemo(
    () =>
      debounce((newSearch: string) => {
        onChangeSearch(newSearch);
      }, DEBOUNCE_SEARCH_TIME),
    []
  );

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value as string);
    doSearch(e.target.value as string);
  };

  const handleBulkSelect = (item: any) => {
    setBulkAction(item.target.value);
    switch (item.target.value) {
      case 'print':
        toggleModal();
        setBulkAction('');
        break;

      default:
        break;
    }
  };

  return (
    <Box padding={2}>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px 0 20px 0' }}>
        {hasAccessTo(CREATE_PURCHASE_ORDER, permissions) && (
          <ButtonAhref
            styles={{ marginLeft: 'auto' }}
            buttonStyle="big"
            text="NEW PURCHASE ORDER"
            href="/administrator/new-purchase-order"
          />
        )}
      </div>
      <Box className={classes.container}>
        <FormControl size="medium" variant="outlined" className={classes.formDropdown}>
          <InputLabel id="bulk-actions" className={classes.formInputSelect}>
            Bulk actions
          </InputLabel>
          <Select
            data-cy="bulkActions"
            labelId="bulk-actions"
            className={classes.dropdown}
            value={bulkActions}
            onChange={handleBulkSelect}
            fullWidth
            label="Bulk actions"
          >
            <MenuItem data-cy="action" value="print">
              Group & Print
              {data.assets !== 0 && ` (${data.pages} pages / ${data.percentageUsed.toFixed()}% total usage)`}
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl size="medium" variant="outlined" className={classes.formDropdown}>
          <InputLabel id="provider" className={classes.formInputSelect}>
            Provider
          </InputLabel>
          <Select
            data-cy="provider"
            labelId="provider"
            name="provider"
            className={classes.dropdown}
            value={provider}
            onChange={onChangeFilters}
            fullWidth
            label="Provider"
            disabled={isFetchingProviders}
          >
            <MenuItem value="">None</MenuItem>
            {sortBy(providers, (prac) => prac.firstName.toLowerCase()).map(({ id, firstName, lastName }) => (
              <MenuItem value={id}>{`${firstName} ${lastName}`}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl size="medium" variant="outlined" className={classes.formDropdown}>
          <InputLabel id="supplier" className={classes.formInputSelect}>
            Supplier
          </InputLabel>
          <Select
            data-cy="supplier"
            labelId="supplier"
            name="supplier"
            className={classes.dropdown}
            value={supplier}
            onChange={onChangeFilters}
            fullWidth
            label="Supplier"
            disabled={isFetchingSuppliers}
          >
            <MenuItem value="">None</MenuItem>
            {suppliers.map(({ id, name }) => (
              <MenuItem value={id}>{name}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <InputSearch
          width={260}
          placeholder="Search by PO or Reference ID"
          onChangeValue={onChangeValue}
          value={search}
          isLoading={isFetching}
          name="search"
        />

        <Tooltip title="When this checkbox is marked, only Purchase Orders that are waiting to be received will be displayed. Use this filter to easily identify and follow up on orders that require action.">
          <FormControlLabel
            className={classes.formControlLabel}
            control={
              <Checkbox
                style={{ color: '#12574d' }}
                onChange={onChangeFilters}
                checked={waitingToReceive}
                name="waitingToReceive"
              />
            }
            label="Show only WTR"
          />
        </Tooltip>
      </Box>

      {data.assets !== 0 && (
        <Box data-cy="infoMsg" display="flex" justifyContent="space-between" className={classes.message}>
          You have selected {data.assets} labels and need {data.pages} pages to print them using{' '}
          {data.percentageUsed.toFixed()}% of the printable area.
        </Box>
      )}
    </Box>
  );
};

export default PurchaseOrdersHeader;
