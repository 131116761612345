import React from 'react';
import { Button, TableCell, TableRow } from '@material-ui/core';
import { useStyles } from '../../PhysicianPatientsList.styles';
import { usePhysicianNavigate } from '../../../../hooks/customer/usePhysicianNavigate';
import LiveGfeAudioIcon from '../../../../assets/images/live-gfe-audio.svg';
import LiveGfeVideoIcon from '../../../../assets/images/live-gfe-video.svg';
import NoShowAction from './NoShowAction';
import { nextAppointment } from '../../ProviderPatientsList';

export const PatientRow = ({ patient, showGfeDate = false }: { patient: any; showGfeDate?: boolean }) => {
  const classes = useStyles();
  const navigateToPatient = usePhysicianNavigate(patient.id);

  return (
    <TableRow key={patient.id}>
      <TableCell>
        {patient.clearingAppointment?.meetingType === 'audio' ? (
          <img src={LiveGfeAudioIcon} alt="Live GFE Audio" />
        ) : (
          <img src={LiveGfeVideoIcon} alt="Live GFE Video" />
        )}
      </TableCell>
      <TableCell>
        {patient.firstName} {patient.lastName}
      </TableCell>
      <TableCell>{patient.id}</TableCell>
      {showGfeDate && (
        <TableCell>
          {patient.clearingAppointment.startTime ? (
            nextAppointment(patient.clearingAppointment.startTime)
          ) : (
            <>&mdash;</>
          )}
        </TableCell>
      )}
      <TableCell>{nextAppointment(patient.nextAppointment)}</TableCell>
      <TableCell>{patient.clearingAppointment?.appointment?.name || 'N/A'}</TableCell>
      <TableCell>
        <div className={classes.rowActionsContainer}>
          <NoShowAction key={patient.id} customerId={patient.id} defaultNoShow={patient.status === 'no_show'} />
          <Button disableElevation variant="contained" className={classes.rowActionButton} onClick={navigateToPatient}>
            Perform
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
};
