import { DefaultPrivacyLevel, datadogRum } from '@datadog/browser-rum';

export const initializeDataDog = () => {
  try {
    datadogRum.init({
      applicationId: process.env.REACT_APP_DD_APPLICATION_ID || '',
      clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN || '',
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: 'datadoghq.com',
      service: process.env.REACT_APP_DD_SERVICE || '',
      env: process.env.REACT_APP_DD_ENV || '',
      // Specify a version number to identify the deployed version of your application in Datadog
      version: process.env.REACT_APP_GIT_SHA || '',
      sessionSampleRate: 100,
      sessionReplaySampleRate: process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION' ? 100 : 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: DefaultPrivacyLevel.MASK_USER_INPUT,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};
