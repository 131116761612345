import { Box, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { InventoryOrderStatus } from 'src/interfaces/IInventoryOrder';
import { formatCurrency } from 'src/utils/formatNumber';
import OrderStatus from 'src/components/DashboardPractitioner/Tabs/OrderingTab/OrderStatus';
import ProductCountBadge from './ProductCountBadge';

type Props = {
  supplierName: string;
  inventoryOrderId: number;
  productCount: number;
  subtotal: number;
  status: InventoryOrderStatus;
};

export const SupplierDetailStub = ({ supplierName, inventoryOrderId, productCount, subtotal, status }: Props) => {
  const classes = useStyle();

  return (
    <Box
      className={classes.accordionSummary}
      data-testid={`order history supplier ${inventoryOrderId} ${supplierName}`}
    >
      <Box>
        <Typography>{supplierName}</Typography>
        <ProductCountBadge total={productCount} color="dark" border />
      </Box>
      <Box paddingLeft="12px">
        <Typography component="span">
          <span>Total:</span> {formatCurrency(subtotal)}
        </Typography>
      </Box>
      <Box>
        <OrderStatus status={status} />
      </Box>
    </Box>
  );
};

const useStyle = makeStyles(() => ({
  accordionSummary: {
    display: 'grid',
    gridTemplateColumns: '5fr 1fr 1fr',
    gap: '10px',
    '& > div': {
      display: 'flex',
    },
    '& span > span': {
      color: '#888',
    },
  },
}));
