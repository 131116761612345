import React, { ReactElement, ReactNode, ChangeEvent, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useStyles } from '../DashboardPractitioner/Tabs/InsightsTab/reports.styles';

export type SelectListVariants = 'standard' | 'outlined' | 'filled';

export interface SelectListItems {
  [value: string]: string;
}

export interface SelectListProps {
  listItems: SelectListItems;
  className?: string;
  title?: string;
  variant?: SelectListVariants;
  selected?: string;
  setSelected?: (selection: string | null) => void;
  loadSelected?: boolean;
  customStyles?: {
    selectForm?: React.CSSProperties;
    select?: React.CSSProperties;
  };
  children?: ReactNode;
  displayEmpty?: boolean;
}

export const SelectList: React.FC<SelectListProps> = ({
  listItems,
  className,
  title,
  variant,
  selected,
  setSelected,
  loadSelected = false,
  customStyles,
  children,
  displayEmpty = false,
}: SelectListProps): ReactElement => {
  const classes = useStyles();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChange = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>, child: ReactNode): void => {
    const newVal = event.target.value as string;
    setSelected?.(newVal);
  };

  useEffect(() => {
    loadSelected && !!selected && setSelected?.(selected);
  }, [loadSelected, selected]);

  return (
    <FormControl
      style={customStyles?.selectForm}
      data-testid={`${title} form`}
      className={[classes.selectform, className].join(' ')}
      variant={variant}
    >
      <InputLabel>{title}</InputLabel>
      <Select
        style={customStyles?.select}
        value={selected}
        label={title}
        onChange={handleChange}
        data-testid={title}
        displayEmpty={displayEmpty}
      >
        {children}
        {Object.entries(listItems).map(
          (item: [string, string]): ReactElement => (
            <MenuItem key={item[0]} value={item[0]}>
              {item[1]}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
};
