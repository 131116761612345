import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ViewState, SchedulerDateTime } from '@devexpress/dx-react-scheduler';
import { Paper } from '@material-ui/core';
import {
  Scheduler,
  DayView,
  Appointments,
  Resources,
  WeekView,
  MonthView,
  Toolbar,
  AppointmentTooltip,
  CurrentTimeIndicator,
} from '@devexpress/dx-react-scheduler-material-ui';
import moment from 'moment';
import { useBlockOffHours } from 'src/hooks/acuity/useAcuityAccount';
import AppointmentsNotClearedTab from 'src/components/common/Appointments/AppointmentsNotClearedTab';
import AppointmentsBox from 'src/components/common/Appointments/AppointmentsBox';
import AppointmentsTimeIndicator from 'src/components/common/Appointments/AppointmentsTimeIndicator';
import AppointmentsCardHeader from 'src/components/common/Appointments/AppointmentsCardHeader';
import { PATIENT_SCHEDULER_STATUSES } from '../../../constants/scheduler.constants';
import AppointmentsCard from './AppointmentsCard';
import { dispatch } from '../../../rematch';
import { getViewStringType, applyCalendarStyles, filterNewAppointments } from '../../../utils/scheduler.utils';
import { hasAccessTo } from '../../../utils/auth.utils';
import { GFE_REQUEST, PRACTITIONER_BOOK_APPOINTMENT_UPDATE } from '../../../constants/actions.constants';
import { USER_TYPES } from '../../../constants/general.constants';
import CustomToolbar from './AppointmentsToolBar';
import Page from '../Page';

const resources = [
  {
    fieldName: 'statusId',
    title: 'Status',
    instances: PATIENT_SCHEDULER_STATUSES,
  },
];

const schedulerHeaderHeight = 28; // static value
const schedulerHeight = 750; // value can be calculated after first render

const TimeTableCell = (props: any) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <MonthView.TimeTableCell {...props} style={{ height: `${(schedulerHeight - schedulerHeaderHeight) / 6}px` }} />
);

const getDateRangeForView = (currentDate: SchedulerDateTime, viewType: string) => {
  const momentView = getViewStringType(viewType);
  let dateRange = {
    start: moment(currentDate).startOf('days').subtract(1, 'days'),
    end: moment(currentDate).endOf('days').add(1, 'days'),
  };

  switch (momentView) {
    case 'month':
      dateRange = {
        start: moment(currentDate).startOf('month').subtract(8, 'days'),
        end: moment(currentDate).endOf('month').add(14, 'days'),
      };
      break;
    case 'week':
      dateRange = {
        start: moment(currentDate).startOf('week').subtract(1, 'days'),
        end: moment(currentDate).endOf('week').add(1, 'days'),
      };
      break;
    default:
      break;
  }

  return dateRange;
};

type Props = {
  practitionerIds?: number[];
  appointmentStatus?: string;
  setAppointmentStatus?: (value: string) => void;
};

const AppointmentsCalendar = ({ practitionerIds = [], appointmentStatus, setAppointmentStatus }: Props) => {
  // The currentDate represents the day we are on when we navigate the calendar.
  const [currentDate, setCurrentDate] = useState<SchedulerDateTime>(moment().format('YYYY-MM-DD'));
  const [view, setView] = useState<string>('Week');
  const [status, setStatus] = useState<string>('all');
  const [filteredAppointments, setFilteredAppointments] = useState<any>([]);

  const [refetch, setRefetch] = useState(false);
  const { appointments, newAppointments } = useSelector((state: any) => state.schedulers);
  const permissions = useSelector(({ auth }: any) => auth.permissions);
  const { userType } = useSelector(({ auth }: any) => auth);
  const isMedspaAdmin =
    userType === USER_TYPES.ADMINISTRATOR && hasAccessTo(PRACTITIONER_BOOK_APPOINTMENT_UPDATE, permissions);

  const dateRangeForView = useMemo(() => getDateRangeForView(currentDate, view), [currentDate, view]);
  const enableBlockOffRequest =
    hasAccessTo(PRACTITIONER_BOOK_APPOINTMENT_UPDATE, permissions) &&
    !!dateRangeForView.start &&
    !!dateRangeForView.end;
  const { blocksForCalendar } = useBlockOffHours(
    {
      start: dateRangeForView.start.format('YYYY-MM-DD'),
      end: dateRangeForView.end.format('YYYY-MM-DD'),
      practitionerIds,
    },
    enableBlockOffRequest
  );

  const handleChangeView = (viewName: string) => {
    setView(viewName);
  };

  const handleStatus = (statusName: string) => {
    setStatus(statusName);
  };

  const handleNavigatorChange = (action: string) => {
    dispatch.schedulers.setIsLoading(true);
    const date = moment(currentDate);
    const momentView = getViewStringType(view);

    switch (action) {
      case 'back':
        setCurrentDate(date.subtract(1, momentView).format('YYYY-MM-DD'));
        break;
      case 'today':
        setCurrentDate(moment().format('YYYY-MM-DD'));
        break;
      case 'forward':
        setCurrentDate(date.add(1, momentView).format('YYYY-MM-DD'));
        break;
      default:
        break;
    }
    dispatch.schedulers.setIsLoading(false);
  };

  // Unmount empty appointments
  useEffect(
    () => () => {
      resetAppointments();
    },
    []
  );

  useEffect(() => {
    /* When the new appointments request is performed, let's add it to the model
     * to avoid override when the request is overlapped by others
     */
    if (newAppointments?.length) {
      if (refetch) {
        dispatch.schedulers.setAppointments([...newAppointments]);
        setRefetch(false);
      } else {
        dispatch.schedulers.setAppointments([...filterNewAppointments(newAppointments, appointments), ...appointments]);
      }
    }
  }, [newAppointments]);

  const fetchAppointments = () => {
    const { start, end } = getDateRangeForView(currentDate, view);
    const params = { start: start.format('YYYY-MM-DD'), end: end.format('YYYY-MM-DD'), practitionerIds };

    // gets the appointments from the API
    dispatch.schedulers.getAppointments(params);
  };

  const resetAppointments = () => {
    setFilteredAppointments([]);
    dispatch.schedulers.setAppointments([]);
  };

  useEffect(() => {
    // clean previous practitioner appointments
    resetAppointments();
    fetchAppointments();
  }, [JSON.stringify(practitionerIds)]);

  useEffect(() => {
    fetchAppointments();
  }, [currentDate, view, refetch]);

  useEffect(() => {
    setTimeout(applyCalendarStyles, 100);
  }, [currentDate, view]);

  useEffect(() => {
    if (!appointments) {
      return;
    }

    if (hasAccessTo(GFE_REQUEST, permissions)) {
      setFilteredAppointments(
        status === 'all'
          ? appointments.filter((appointment: any) => !appointment.canceled)
          : appointments.filter(({ statusId }: any) => statusId === status.toLowerCase())
      );
    } else {
      let filtered = appointments; // show_all canceled and active

      if (appointmentStatus === 'all') {
        filtered = appointments.filter(({ canceled }: any) => !canceled);
      } else if (appointmentStatus === 'cancelled') {
        filtered = appointments.filter(({ canceled }: any) => canceled);
      } else if (appointmentStatus === 'no_show_appointment') {
        filtered = appointments.filter(
          ({ serviceVisitId, startDate }: any) =>
            !serviceVisitId && !moment(startDate).add(72, 'hours').isSameOrAfter(moment())
        );
      }
      setFilteredAppointments(filtered);
    }
  }, [appointments, status, appointmentStatus]);

  return (
    <Page title="Calendar">
      <Paper>
        {hasAccessTo(GFE_REQUEST, permissions) && (
          <AppointmentsNotClearedTab
            appointments={[...filteredAppointments, ...blocksForCalendar]}
            view={view}
            handleChangeView={handleChangeView}
            currentDate={currentDate}
            setCurrentDate={setCurrentDate}
            handleNavigatorChange={handleNavigatorChange}
          />
        )}

        <div id="calendar">
          <Scheduler data={[...filteredAppointments, ...blocksForCalendar]}>
            <ViewState currentDate={currentDate} currentViewName={view} />
            <WeekView startDayHour={3} endDayHour={24} />
            <DayView startDayHour={3} endDayHour={24} />
            <MonthView timeTableCellComponent={TimeTableCell} />

            <Toolbar
              rootComponent={() =>
                CustomToolbar({
                  currentDate,
                  view,
                  status,
                  handleChangeView,
                  handleStatus,
                  appointmentStatus,
                  setAppointmentStatus,
                  handleNavigatorChange,
                  permissions,
                  practitionerId: isMedspaAdmin && practitionerIds.length === 1 ? practitionerIds[0] : undefined,
                })
              }
            />

            <Appointments appointmentContentComponent={AppointmentsBox} />

            {/* red line */}
            <CurrentTimeIndicator indicatorComponent={AppointmentsTimeIndicator} />

            <AppointmentTooltip
              showOpenButton
              contentComponent={(props: any) => (
                <AppointmentsCard
                  refetch={refetch}
                  shouldRefetch={() => setRefetch(true)}
                  isAppointmentEdit={false}
                  {...props}
                />
              )}
              headerComponent={(props: any) => AppointmentsCardHeader(props)}
            />
            <Resources data={resources} />
          </Scheduler>
        </div>
      </Paper>
    </Page>
  );
};

export default AppointmentsCalendar;
