import React, { useEffect, useState } from 'react';
import { CircularProgress, TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { ErrorOutline as ErrorOutlineIcon } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { Skeleton } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import isNumber from 'src/utils/isNumber';
import { useStyles } from './AccountCredit.styles';
import { Button } from '../../../components/common/Button';
import { dispatch } from '../../../rematch';
import { usePractitionerInfo } from '../../../hooks/queries/usePractitioners';
import { PATIENT_INFO } from '../../../constants/reactQuery.keys';
import Patients from '../../../services/Patients';
import { CANCELLATION_FEE_CHARGE_TYPE } from '../../../constants/checkout.constants';
import { formatNumber } from '../../../utils/checkout.utils';
import { PORTRAIT_LEGACY, PORTRAIT_LEGACY_PLUS, USER_TYPES } from '../../../constants/general.constants';
import { useCustomRoles } from '../../../hooks/queries/useCustomRoles';
import IServices from '../../../interfaces/IServices';

const CancellationFee = ({ patientId, medspaLocationId }: { patientId: string; medspaLocationId?: number | null }) => {
  const classes = useStyles();
  const history = useHistory();
  const { data: patient } = useQuery([PATIENT_INFO, patientId], () => Patients.getPatientInfo(patientId), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  const { practitionerInfo, isLoading } = usePractitionerInfo(patient?.contactInfo?.practitioner?.id);
  const [chargeAmount, setChargeAmount] = useState<string | null>(null);
  const [processing, setProcessing] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const { isProgressQueueWorking } = useSelector(({ newServiceVisit }: any) => newServiceVisit);
  const { data: roles = [] } = useCustomRoles({ userType: USER_TYPES.PRACTITIONER });

  const roleName = roles.find((role) => +role.id === practitionerInfo?.roleId)?.name;
  const isNonLegacyPractitioner = roleName !== PORTRAIT_LEGACY && roleName !== PORTRAIT_LEGACY_PLUS;
  const maxChargeAmount = practitionerInfo?.cancelationFee;

  useEffect(() => {
    if (maxChargeAmount) {
      setChargeAmount(maxChargeAmount.toString());
    }
  }, [maxChargeAmount]);

  const createAndNavigateToCheckout = async () => {
    try {
      setProcessing(true);
      dispatch({
        type: 'patient/prepareCheckoutForFee',
        payload: {
          patientId,
          chargeAmount,
          chargeType: CANCELLATION_FEE_CHARGE_TYPE,
          medspaLocationId,
          successCallback: (serviceVisit: IServices) => {
            setProcessing(false);
            history.push(`/patient/${patientId}/newServiceVisit/${serviceVisit.id}/checkout`);
          },
        },
      });
    } finally {
      setProcessing(false);
    }
  };

  const validChargeAmount = () => {
    let isValid = chargeAmount && parseFloat(chargeAmount) > 0;
    if (!isNonLegacyPractitioner) {
      isValid = isValid && chargeAmount && parseFloat(chargeAmount) <= maxChargeAmount;
    }

    return isValid;
  };

  const getHelperText = () => {
    if (!maxChargeAmount && !isNonLegacyPractitioner) {
      return `Cancellation fee has not been set.`;
    }

    if (chargeAmount && !validChargeAmount()) {
      return parseFloat(chargeAmount) > maxChargeAmount
        ? `Cancellation fee cannot be greater than $${maxChargeAmount}`
        : 'Cancellation fee must be greater than 0';
    }
    return null;
  };

  if (isLoading) {
    return <Skeleton height={200} width="100%" />;
  }

  const isCheckoutDisabled =
    !validChargeAmount() ||
    processing ||
    isProgressQueueWorking ||
    (medspaLocationId != null && !isNumber(medspaLocationId));

  return (
    <div className={classes.container}>
      Your default cancellation fee is shown below. Because the client was notified of this fee, you can reduce this
      fee, but you cannot charge more.
      <div className={classes.creditAmountContainer}>
        <NumberFormat
          className={classes.creditAmountInput}
          decimalScale={2}
          fixedDecimalScale
          customInput={TextField}
          variant="outlined"
          label="$"
          // @ts-ignore
          value={chargeAmount}
          autoComplete="off"
          name="credit_amount"
          onChange={(e) => {
            setShowErrorMessage(false);
            setChargeAmount(e.target.value);
          }}
          disabled={processing}
          error={chargeAmount && !validChargeAmount()}
          helperText={getHelperText()}
        />
      </div>
      <p>
        By processing this payment I confirm the patient has provided verbal or written consent to charge the credit
        card on file for the amount charged.
      </p>
      <Button
        disabled={isCheckoutDisabled}
        className={classes.button}
        // @ts-ignore
        title={
          // eslint-disable-next-line no-nested-ternary
          processing ? (
            <>
              Processing
              <CircularProgress size={20} className={classes.processing} />
            </>
          ) : validChargeAmount() ? (
            `Checkout: $${formatNumber(+(chargeAmount || 0))}`
          ) : (
            'Checkout'
          )
        }
        onClick={createAndNavigateToCheckout}
      />
      {showErrorMessage && (
        <div className={classes.errorMsg}>
          <ErrorOutlineIcon className={classes.errorMsgIcon} /> Error processing payment. Please try again.
        </div>
      )}
    </div>
  );
};

export default CancellationFee;
