import React, { useState, useEffect } from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { Box, Button, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DATEPICKER_FORMAT, INPUT_DATE_FORMAT } from 'src/constants/inventory.constants';
import { PickersDate } from 'src/interfaces/IInventoryOrder';
import { useInventoryOrderPeriod, useUpdateInventoryOrderPeriod } from 'src/hooks/queries/useInventoryOrders';
import { useStyle } from '../../../../DashboardPractitioner/Tabs/OrderingTab/inventoryOrder.styles';

type Props = {
  closeDialog?: () => void;
};

export const BannerDates = ({ closeDialog }: Props) => {
  const classes = useStyle();
  const [triggerDate, setTriggerDate] = useState<string | undefined>();
  const [cutoffDate, setCutoffDate] = useState<string | undefined>();
  const [isUpdating, setIsUpdating] = useState<boolean | undefined>(false);

  const { data, isLoading: isLoadingPeriod } = useInventoryOrderPeriod();
  const { startDate, endDate } = data || {};

  const { mutateAsync: updateOrderPeriod } = useUpdateInventoryOrderPeriod();

  const isLoading = isLoadingPeriod || isUpdating;
  const isActive = !!startDate && !!endDate && moment(startDate) < moment() && moment(endDate) > moment();

  const updateTriggerDate = (date: PickersDate) => !!date && setTriggerDate(date.format(INPUT_DATE_FORMAT));
  const updateCutoffDate = (date: PickersDate) => !!date && setCutoffDate(date.format(INPUT_DATE_FORMAT));

  const saveBannerDate = async () => {
    if (triggerDate && cutoffDate) {
      setIsUpdating(true);
      try {
        await updateOrderPeriod({
          startDate: triggerDate,
          endDate: cutoffDate,
        });
        closeDialog?.();
      } finally {
        setIsUpdating(false);
      }
    }
  };

  useEffect(() => {
    startDate && setTriggerDate(moment(startDate).format(INPUT_DATE_FORMAT));
    endDate && setCutoffDate(moment(endDate).format(INPUT_DATE_FORMAT));
  }, [startDate, endDate]);

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Typography variant="h5" className={classes.adminDateTitle}>
          Legacy Ordering Banner Dates
        </Typography>
        <Typography className={classes.dateWarning}>
          {isActive ? 'Updating this value will close the active order period.' : ''}
        </Typography>
      </Box>
      <Box data-testid="order period">
        <Box className={classes.adminDate} data-testid="order period">
          <Typography className={classes.adminDateLabel}>Trigger Date</Typography>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            {isLoading ? (
              <Skeleton
                data-testid="loading start date"
                className={classes.singleSkeleton}
                variant="rect"
                width={150}
                height={40}
              />
            ) : (
              <KeyboardDatePicker
                format={DATEPICKER_FORMAT}
                autoOk
                disableToolbar
                value={moment(triggerDate, INPUT_DATE_FORMAT).format(DATEPICKER_FORMAT)}
                defaultValue={moment(startDate).format(DATEPICKER_FORMAT)}
                onChange={updateTriggerDate}
                className={classes.adminDateInput}
                data-testid="period start date"
              />
            )}
          </MuiPickersUtilsProvider>
        </Box>
        <Box className={classes.adminDate} data-testid="order period">
          <Typography className={classes.adminDateLabel}>Cut-Off Date</Typography>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            {isLoading ? (
              <Skeleton
                data-testid="loading end date"
                className={classes.singleSkeleton}
                variant="rect"
                width={150}
                height={40}
              />
            ) : (
              <KeyboardDatePicker
                format={DATEPICKER_FORMAT}
                autoOk
                disableToolbar
                value={moment(cutoffDate, INPUT_DATE_FORMAT).format(DATEPICKER_FORMAT)}
                defaultValue={moment(endDate).format(DATEPICKER_FORMAT)}
                onChange={updateCutoffDate}
                className={classes.adminDateInput}
                data-testid="period end date"
              />
            )}
          </MuiPickersUtilsProvider>
        </Box>
      </Box>
      <Box className={classes.periodConfirmDialog} data-testid="save period dialog">
        <Box className={classes.periodConfirmButtons}>
          <Button
            data-testid="cancel save period"
            onClick={closeDialog}
            variant="outlined"
            className={classes.actionButton}
          >
            {' '}
            Cancel{' '}
          </Button>
          <Button
            data-testid="confirm save period"
            onClick={saveBannerDate}
            variant="outlined"
            className={classes.actionButton}
            disabled={isLoading || !triggerDate || !cutoffDate}
          >
            {isActive ? 'End and Update' : 'Update'}
          </Button>
        </Box>
      </Box>
    </>
  );
};
