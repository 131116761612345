import React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Banner from 'src/components/common/Banner';
import { PENDING_STEPS_FOR_INVENTORY_WARNING } from 'src/constants/onboarding.constants';
import { ACCOUNT_SETTINGS_LABELS, ACCOUNT_SETTINGS_STEPS } from 'src/pages/Onboarding/AccountSettings/constants';
import { MEDSPA_ADMIN_SETTINGS } from 'src/routes/medspaAdminRoutes';
import { ONBOARDING_ACCOUNT_SETTINGS } from 'src/routes/medspaOnboardingRoutes';

interface PendingStepsBannerProps {
  missingFields: string[];
  redirectToOnboarding?: boolean;
  isOwner?: boolean;
}

const PendingStepsBanner: React.FC<PendingStepsBannerProps> = ({
  missingFields,
  redirectToOnboarding = false,
  isOwner = false,
}) => {
  const history = useHistory();

  const handleClick = (field: string) => {
    if (!isOwner && field === ACCOUNT_SETTINGS_STEPS.INVENTORY_AGREEMENT) {
      toast.error('Only the owner of this MedSpa can complete this step.');
      return;
    }

    history.push({
      pathname: redirectToOnboarding ? ONBOARDING_ACCOUNT_SETTINGS : MEDSPA_ADMIN_SETTINGS,
      state: { field },
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent, field: string) => {
    if (event.key === 'Enter') {
      handleClick(field);
    }
  };

  const renderContent = (field: string) => (
    <p>
      {`${ACCOUNT_SETTINGS_LABELS[field as keyof typeof ACCOUNT_SETTINGS_LABELS]}, click `}
      <span
        role="button"
        tabIndex={0}
        style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
        onClick={() => handleClick(field)}
        onKeyDown={(event) => handleKeyDown(event, field)}
        aria-label={`Click to fill out ${field}`}
      >
        HERE
      </span>
      {' to fill out.'}
    </p>
  );

  return (
    <div style={{ padding: '2rem' }}>
      <Banner
        content={
          <>
            {PENDING_STEPS_FOR_INVENTORY_WARNING}
            <ul>
              {missingFields.map((field) => (
                <li key={field}>{renderContent(field)}</li>
              ))}
            </ul>
          </>
        }
        type="warning"
      />
    </div>
  );
};

export default PendingStepsBanner;
