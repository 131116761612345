import { Box, Dialog, Divider, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import ImportCsv from 'src/components/common/ImportCsv';
import { EHRButton } from 'src/components/ui/v1';
import { useCreateProducts } from 'src/hooks/queries/useProducts';
import { IOrderProductAdmin } from 'src/services/Products';
import { ClassNameMap } from 'src/types/Dom';

export const ImportProducts: React.FC<ClassNameMap & { open: boolean; dismiss?: () => void; upsert?: boolean }> = ({
  open,
  classes,
  dismiss,
  upsert = false,
}) => {
  const { mutateAsync: importProducts } = useCreateProducts(upsert);
  const handleImportProducts = async (products: Partial<IOrderProductAdmin>[]) => importProducts({ products });

  return (
    <Dialog open={open} onBackdropClick={dismiss} PaperProps={{ className: classes?.paper }}>
      <Box display="flex" margin={0}>
        <Typography variant="h5" className={classes?.typography}>
          Import from CSV
        </Typography>
      </Box>
      <Divider className={classes?.divider} />
      <ImportCsv<Partial<IOrderProductAdmin>>
        onSave={handleImportProducts}
        variant="box"
        classes={classes}
        requiredKeys={['supplierId', 'name']}
        numericalValues={[
          'supplierId',
          'currentCost',
          'currentCostWithGuaranteedRebates',
          'currentCostWithAllRebates',
          'listPrice',
          'legacyPrice',
          'legacyPlusPrice',
          'flexPrice',
          'launchPrice',
          'ascendPrice',
          'minimumOrderQuantity',
          'itemCount',
          'unitsPerItem',
        ]}
        showLoadingModal
      />
      <EHRButton
        text="Done"
        color="default"
        dataCy="importProducts.back"
        className={classes?.backButton}
        onClick={dismiss}
      />
    </Dialog>
  );
};

export default withStyles({
  paper: {
    width: '33%',
    minWidth: 400,
    padding: 25,
  },
  backButton: {
    width: '100%',
    marginTop: 10,
  },
  uploadButton: {
    width: '100%',
  },
  typography: {
    margin: 'auto',
  },
  divider: {
    marginTop: 15,
    marginBottom: 20,
  },
})(ImportProducts);
