import { Box } from '@material-ui/core';
import React from 'react';
import { CheckCircleOutline as ConfirmIcon } from '@material-ui/icons';
import { EHRButton, EHRModal } from 'src/components/ui/v1';
import { useConfirmMedspaAppointment } from 'src/hooks/medspaCalendar/useAppointments';

interface MedspaCalendarAppointmentConfirmModalProps {
  open: boolean;
  appointment: any;
  onClose: () => void;
  setAction: (value: string | null) => void;
}

export const MedspaCalendarAppointmentConfirmModal = ({
  open,
  setAction,
  onClose,
  appointment,
}: MedspaCalendarAppointmentConfirmModalProps) => {
  const { mutate: confirmAppointmentMutation, isLoading: isConfirmingAppointment } = useConfirmMedspaAppointment(
    appointment?.id as number,
    () => onClose()
  );

  const onConfirmAppointment = () => {
    confirmAppointmentMutation();
  };

  return (
    <EHRModal open={open} dataCy="modal-confirm-appointment">
      <EHRModal.Body dataCy="modal-confirm-body">
        <Box display="flex" gridGap="1rem" flexDirection="column">
          <Box display="flex" width="100%" justifyContent="center">
            <ConfirmIcon
              style={{
                fill: '#4CAF50',
                width: '6rem',
                height: '6rem',
              }}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            gridGap="2rem"
            style={{
              fontSize: '1rem',
              lineHeight: '1.5rem',
            }}
          >
            Are you sure you want to manually confirm this appointment?
          </Box>
        </Box>
      </EHRModal.Body>

      <EHRModal.Footer dataCy="modal-confirm-footer">
        <Box display="flex" width="100%" justifyContent="center" gridGap="1rem">
          <EHRButton
            dataCy="btn-cancel-confirm-appointment"
            color="default"
            variant="outlined"
            onClick={() => {
              setAction(null);
            }}
            text="No"
            disabled={isConfirmingAppointment}
          />

          <EHRButton
            dataCy="btn-confirm-confirm-appointment"
            color="primary"
            onClick={onConfirmAppointment}
            text="Yes"
            disabled={isConfirmingAppointment}
          />
        </Box>
      </EHRModal.Footer>
    </EHRModal>
  );
};
