import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TableRow, TableCell, withStyles, makeStyles, Tooltip } from '@material-ui/core';
import moment from 'moment';
import { isArray } from 'lodash';
import { FlipCameraIosOutlined, InsertPhotoOutlined } from '@material-ui/icons';
import { dispatch } from '../../../rematch';
import { PATIENT_BASE } from '../../../constants/routes.constants';
import { REQUEST_STATUS_OPTIONS } from '../../../constants/newPhotoRequest.constants';

const useStyles = makeStyles(() => ({
  email: {
    maxWidth: '150px !important',
    padding: 10,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  paddingClass: {
    padding: '0 0 0 14px',
  },
  cellName: {
    width: '160px',
    paddingLeft: '20px',
    paddingRight: 0,
    color: '#12574d',
    textDecoration: 'underline',
    textTransform: 'capitalize',
  },
  CreatedClass: {
    fontWeight: 600,
    fontFamily: 'Messina Sans SemiBold',
    fontSize: '14px',
    width: '150px',
    padding: 0,
  },
  itemTab: {
    border: 'solid 2px #f2f5f5',
    cursor: 'pointer',
  },
}));

export const PhotoRequested = () => (
  <Tooltip title="Photos have been requested">
    <FlipCameraIosOutlined data-cy="FlipCameraIosOutlined" />
  </Tooltip>
);

const PhotoSubmitted = () => (
  <Tooltip title="New photos were submitted by patient">
    <InsertPhotoOutlined data-cy="InsertPhotoOutlined" />
  </Tooltip>
);

const TableRowStriped = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f2f5f5',
      height: '56px',
    },
  },
}))(TableRow);

const RowItem = (props: any) => {
  const { row, state } = props;
  const history = useHistory();
  const status = state;
  const patientId = useSelector(({ patient }: any) => patient.id);

  const navigateToPatient = () => {
    if (row.id !== patientId) {
      dispatch.patient.clearPatientData();
    }
    history.push({
      pathname: `${PATIENT_BASE}/${row.id}`,
      state: { newState: status, from: history.location.pathname },
    });
  };
  const classes = useStyles();
  const hasUploadedPhoto = row.photoRequestStatus === REQUEST_STATUS_OPTIONS.PHOTO_SUBMITTED;
  const hasPhotoRequested =
    row.photoRequestStatus === REQUEST_STATUS_OPTIONS.REQUESTED ||
    row.photoRequestStatus === REQUEST_STATUS_OPTIONS.REJECTED;

  const updates = () => {
    const cellComponents = [];
    if (hasUploadedPhoto) {
      cellComponents.push(<PhotoSubmitted />);
    }
    if (hasPhotoRequested) {
      cellComponents.push(<PhotoRequested />);
    }
    return <TableCell style={{ paddingRight: 0 }}>{cellComponents}</TableCell>;
  };

  return (
    <>
      <TableRowStriped className={classes.itemTab} hover key={row.id} onClick={navigateToPatient}>
        <TableCell className={classes.cellName}>
          <span data-cy="patientRowName">
            {row.firstName} {row.lastName}
          </span>
        </TableCell>
        <TableCell className={classes.paddingClass}>{row.id}</TableCell>
        <TableCell className={classes.email}>{row.email}</TableCell>
        <TableCell className={classes.CreatedClass}>{moment(row.createdAt).format('MM/DD/YYYY')}</TableCell>
        <TableCell style={{ paddingRight: 0 }}>
          {isArray(row.serviceVisits) ? row.serviceVisits.length : row.serviceVisits}
        </TableCell>
        {updates()}
      </TableRowStriped>
    </>
  );
};

export default RowItem;
