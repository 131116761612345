import React, { useRef } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  ALL_PATIENTS_STATE,
  NEW_STATE,
  PATIENT_IGNORED_STATE,
  PHYSICIAN_ROLE,
  STARRED_STATE,
  TO_RECLEAR_STATE,
  UPCOMING_PATIENTS_STATE,
} from '../constants/general.constants';
import PrivateRoute from '../utils/PrivateRoute';
import Wrapper from '../components/common/Wrapper';
import { PhysicianAssignedProvidersList } from '../pages/DashboardPhysician/PhysicianAssignedProvidersList';
import { PhysicianAssignedProviderDetail } from '../pages/DashboardPhysician/PhysicianAssignedProviderDetail';
import { PhysicianPatientsList } from '../pages/DashboardPhysician/PhysicianPatientsList';
import { PhysicianPendingGFEList } from '../pages/DashboardPhysician/PhysicianPendingGFEList';
import { PhysicianGFEReport } from '../pages/DashboardPhysician/PhysicianGFEReport';

export const PHYSICIAN_BASE_PATH = '/physician';
export const PHYSICIAN_PATIENT_NEW_PATH = `${PHYSICIAN_BASE_PATH}/patients/${NEW_STATE}`; // also called PHYSICIAN_HOME
export const PHYSICIAN_PATIENT_TO_RECLEAR_PATH = `${PHYSICIAN_BASE_PATH}/patients/${TO_RECLEAR_STATE}`;
export const PHYSICIAN_PATIENT_ALL_PATH = `${PHYSICIAN_BASE_PATH}/patients/${ALL_PATIENTS_STATE}`;
export const PHYSICIAN_UPCOMING_PATIENT_PATH = `${PHYSICIAN_BASE_PATH}/patients/${UPCOMING_PATIENTS_STATE}`;
export const PHYSICIAN_PATIENT_IGNORED_PATH = `${PHYSICIAN_BASE_PATH}/patients/${PATIENT_IGNORED_STATE}`;
export const PHYSICIAN_PATIENT_STARRED_PATH = `${PHYSICIAN_BASE_PATH}/patients/${STARRED_STATE}`;
export const PHYSICIAN_PROVIDERS_PATH = `${PHYSICIAN_BASE_PATH}/providers`;
export const PHYSICIAN_NEW_PATIENTS_PATH = `${PHYSICIAN_BASE_PATH}/patients`;
export const PHYSICIAN_PENDING_GFE_PATH = `${PHYSICIAN_BASE_PATH}/pending_gfe`;
export const PHYSICIAN_PENDING_LIVE_GFE_PATH = `${PHYSICIAN_BASE_PATH}/pending_live_gfe`;
export const PHYSICIAN_GFE_REPORT_PATH = `${PHYSICIAN_BASE_PATH}/gfe_report`;

const PhysicianRoutes: React.FC = () => {
  const scrollMainRef = useRef(null);
  return (
    <Wrapper scrollMainRef={scrollMainRef}>
      <Switch>
        <PrivateRoute path={`${PHYSICIAN_PROVIDERS_PATH}/:providerId`} role={PHYSICIAN_ROLE}>
          <Route component={PhysicianAssignedProviderDetail} />
        </PrivateRoute>
        <PrivateRoute path={PHYSICIAN_PROVIDERS_PATH} role={PHYSICIAN_ROLE}>
          <Route component={PhysicianAssignedProvidersList} />
        </PrivateRoute>
        <PrivateRoute path={PHYSICIAN_GFE_REPORT_PATH} role={PHYSICIAN_ROLE}>
          <Route component={PhysicianGFEReport} />
        </PrivateRoute>
        <PrivateRoute path={PHYSICIAN_NEW_PATIENTS_PATH} role={PHYSICIAN_ROLE}>
          <Route component={PhysicianPatientsList} />
        </PrivateRoute>
        <PrivateRoute path={PHYSICIAN_PENDING_GFE_PATH} role={PHYSICIAN_ROLE}>
          <Route component={PhysicianPendingGFEList} />
        </PrivateRoute>
        <PrivateRoute path={PHYSICIAN_PENDING_LIVE_GFE_PATH} role={PHYSICIAN_ROLE}>
          <Route component={PhysicianPendingGFEList} />
        </PrivateRoute>
      </Switch>
    </Wrapper>
  );
};

export default PhysicianRoutes;
