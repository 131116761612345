import { useQuery } from 'react-query';
import MedspaServices from 'src/services/medspaAdmins/Services';
import { SUPPLIERS_LIST } from '../../constants/reactQuery.keys';
import Services from '../../services/Services';

export const useSuppliers = (params?: any) =>
  useQuery([SUPPLIERS_LIST, JSON.stringify(params)], () => Services.getSuppliers(params), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !params?.disabled,
  });

export const useMedspaSuppliers = (params: { userGroupId: number; kind: string }) =>
  useQuery([SUPPLIERS_LIST, JSON.stringify(params)], () => MedspaServices.getMedspaSuppliers(params), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

export default useSuppliers;
