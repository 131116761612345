/* eslint-disable no-undef */
import { useState, useEffect } from 'react';
import { useInfiniteQuery, InfiniteData, useQuery } from 'react-query';
import Patients from '../../services/Patients';
import { CustomerParams } from '../../interfaces/CustomerParams';
import { ICustomersResponse } from '../../interfaces/ICustomer';
import { CUSTOMERS_LIST, COUNT_CUSTOMER_LIST, PATIENT_INFO } from '../../constants/reactQuery.keys';
import { Dashboard } from '../../utils/types';

export const useGetCountResultByRole = (role: Dashboard, query: CustomerParams, adminCondition: boolean) =>
  useQuery([COUNT_CUSTOMER_LIST, JSON.stringify(query)], () => Patients.getPatientsCounts(role, query), {
    enabled: adminCondition,
  });

export function useCustomPatients(role: Dashboard, paramsQuery: CustomerParams) {
  // const queryClient = useQueryClient();
  const adminCondition = role !== 'administrator';
  const [results, setResults] = useState<InfiniteData<ICustomersResponse>>();

  const { data: countResults } = useGetCountResultByRole(role, paramsQuery, adminCondition);

  const { data, isLoading, fetchNextPage, hasNextPage, isFetching, isError, refetch } = useInfiniteQuery(
    [CUSTOMERS_LIST, role, JSON.stringify(paramsQuery)],
    ({ pageParam = 1 }) => Patients.getAllPatients(role, pageParam, paramsQuery),
    {
      staleTime: 60000,
      getNextPageParam: (lastPage: ICustomersResponse) => {
        if (lastPage?.meta?.currentPage < lastPage?.meta?.totalPages) {
          return lastPage.meta.currentPage + 1;
        }
        return false;
      },
    }
  );

  useEffect(() => {
    if (!isLoading && data) {
      setResults(data);
    }
  }, [data, isLoading]);

  return {
    refetch,
    results,
    countResults,
    isLoading,
    isFetching,
    isError,
    fetchNextPage,
    hasNextPage,
  };
}

export function usePatientInfo(id: number) {
  const {
    data: patientInfo = {
      id: 0,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
    isLoading,
    isError,
    isFetched,
  } = useQuery([PATIENT_INFO], () => Patients.getBasicPatientInfo(id));

  return {
    patientInfo,
    isLoading,
    isError,
    isFetched,
  };
}

export { CUSTOMERS_LIST, PATIENTS } from '../../constants/reactQuery.keys';
