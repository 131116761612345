import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CardContent, TextField, Grid } from '@material-ui/core';

import { isEqual } from 'lodash';
import { Card, SmallTitle } from '../common/card';
import { dispatch } from '../../rematch';
import { ActiveStepWrapper } from './ActiveStepWrapper';
import { getServiceLabel } from '../../utils/newServiceVisit.util';
import { SQUARE_STATUS } from '../../constants/checkout.constants';

import compile from '../../utils/toastMessagesCompiler';

import { useStyles } from './servicesUnits.styles';
import IServices from '../../interfaces/IServices';
import IVariant, { IVariantItem } from '../../interfaces/IVariants';

export const ServicesUnits = () => {
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const newServiceVisit = useSelector(({ newServiceVisit }: any) => newServiceVisit);
  const {
    services,
    selectedServices,
    productsSelected,
    checkout,
    servicesUnits,
    creditServicesUnits,
    futureCreditUsed,
    futureVariantCreditUsed,
    variantsUnits,
    creditVariantsUnits,
    totalServicesUnits,
    totalCreditServicesUnits,
    serviceVisit: { opened },
  } = newServiceVisit;
  const { transactionStatus, servicesDiscounts, variantsDiscounts } = checkout;

  const displayTopToaster = (message: string): void => {
    dispatch({
      type: 'snackbar/enqueueSnackBar',
      payload: {
        message,
        type: 'info',
        duration: 10000,
      },
    });
  };

  const removeDiscount = (serviceName: string, id: number, isVariantItem?: boolean): void => {
    if (isVariantItem && variantsDiscounts[id]) {
      dispatch({ type: 'newServiceVisit/removeVariantDiscount', payload: id });
      displayTopToaster(compile('new_service_visit.remove_discount', { serviceName }));
    } else if (servicesDiscounts[id]) {
      dispatch({ type: 'newServiceVisit/removeServiceDiscount', payload: id });
      displayTopToaster(compile('new_service_visit.remove_discount', { serviceName }));
    }
  };

  const removeScannedAssets = (currentProductsSelected: any, id: number): any => {
    /*
     * This function remove the scaned asset from product list if needed
     * and return the new productsSelected format to be saved
     */
    const serviceName = services.find((service: any) => service.id === id).name;
    const product = currentProductsSelected.find(({ id: serviceId }: { id: number }) => serviceId === id);

    if (product?.assets?.length > 0) {
      const newProductsSelected = currentProductsSelected.map((item: any) =>
        item.id === id ? { ...item, assets: [] } : item
      );

      displayTopToaster(compile('new_service_visit.remove_asssets_by_units', { serviceName }));
      return newProductsSelected;
    }
    return currentProductsSelected;
  };

  const removeCredits = (serviceName: string, id: number, isVariantItem?: boolean): void => {
    if (isVariantItem && futureVariantCreditUsed.find(({ variantItemId }: any) => variantItemId === id)) {
      dispatch({ type: 'newServiceVisit/removeVariantCredit', payload: id });
      displayTopToaster(compile('new_service_visit.remove_future_credits', { serviceName }));
    } else if (futureCreditUsed.find(({ serviceId }: any) => serviceId === id)) {
      dispatch({ type: 'newServiceVisit/removeCredit', payload: id });
      displayTopToaster(compile('new_service_visit.remove_future_credits', { serviceName }));
    }
  };

  const onChangeUnit = (units: string, id: number, variantItemId?: number): void => {
    const serviceName = services.find((service: any) => service.id === id).name;
    const newServicesUnits = { ...servicesUnits };
    const newVariantsUnits = { ...variantsUnits };

    if (variantItemId) {
      const variantItemName = services
        .find((s: IServices) => s.id === +id)
        ?.variants.map((variant: IVariant) =>
          variant.items.find((item: IVariantItem) => item.id === +variantItemId)
        )[0]?.name;
      newVariantsUnits[variantItemId] = units;
      dispatch({
        type: 'newServiceVisit/updateVariantsUnits',
        payload: { variantsUnits: newVariantsUnits },
      });
      removeDiscount(variantItemName, variantItemId, true);
      removeCredits(variantItemName, variantItemId, true);
    } else {
      newServicesUnits[id] = units;

      dispatch({
        type: 'newServiceVisit/updateServicesUnits',
        payload: { servicesUnits: newServicesUnits },
      });
      removeDiscount(serviceName, id);
      removeCredits(serviceName, id);
    }
    // the new productsSelected object will be handled by totalServiceUnits effect, to keep the data consistency
  };

  const onChangeCreditUnits = (units: string, id: number, variantItemId?: number | null): void => {
    const serviceName = services.find((service: any) => service.id === id).name;
    const newCreditServicesUnits = { ...creditServicesUnits };
    const newCreditVariantUnits = { ...creditVariantsUnits };
    if (variantItemId) {
      const variantItemName = services
        .find((s: IServices) => s.id === +id)
        ?.variants.map((variant: IVariant) =>
          variant.items.find((item: IVariantItem) => item.id === variantItemId)
        )[0]?.name;
      newCreditVariantUnits[variantItemId] = units;
      dispatch({
        type: 'newServiceVisit/updateCreditVariantsUnits',
        payload: { creditVariantsUnits: newCreditVariantUnits },
      });
      removeDiscount(variantItemName, variantItemId, true);
    } else {
      newCreditServicesUnits[id] = units;
      dispatch({
        type: 'newServiceVisit/updateCreditServicesUnits',
        payload: { creditServicesUnits: newCreditServicesUnits },
      });
      removeDiscount(serviceName, id);
    }
  };

  useEffect(() => {
    const newTotalServicesUnits = { ...totalServicesUnits };
    const newTotalCreditServicesUnits = { ...totalCreditServicesUnits };
    let newProductsSelected = [...productsSelected];

    // Update product selected from units
    Object.keys(servicesUnits).forEach((serviceId) => {
      const units = servicesUnits[serviceId];
      const exists = productsSelected.find(({ id: productId }: { id: number }) => +productId === +serviceId);

      if (!exists && +units > 0) {
        const service = services.find(({ id: sId }: { id: number }) => sId === +serviceId);
        newProductsSelected.push({ ...service, assets: [] });
      } else if (exists && +units === 0) {
        // remove the item from product list
        newProductsSelected = newProductsSelected.filter((product: any) => product.id !== +serviceId);
      }
    });

    // Calculate new total service units
    if (services.length !== 0) {
      Object.keys(servicesUnits).forEach((serviceId) => {
        newTotalServicesUnits[serviceId] =
          +servicesUnits[serviceId] +
          services
            .find((s: IServices) => s.id === +serviceId)
            ?.variants.reduce(
              (total: number, { items }: IVariant) =>
                total +
                items.reduce((itemTotal: number, { id }: IVariantItem) => itemTotal + (+variantsUnits[id] || 0), 0),
              0
            );
      });

      // Calculate new total service credits units
      Object.keys(creditServicesUnits).forEach((serviceId) => {
        newTotalCreditServicesUnits[serviceId] =
          +creditServicesUnits[serviceId] +
          services
            .find((s: IServices) => s.id === +serviceId)
            ?.variants.reduce(
              (total: number, { items }: IVariant) =>
                total +
                items.reduce(
                  (itemTotal: number, { id }: IVariantItem) => itemTotal + (+creditVariantsUnits[id] || 0),
                  0
                ),
              0
            );
      });

      // Validate products selected for each service
      Object.keys(totalServicesUnits).forEach((serviceId) => {
        if (+totalServicesUnits[serviceId] !== +newTotalServicesUnits[serviceId]) {
          // Remove scanned assets from products services if something changed
          // in units for step 2 (preserving the current and expected flow)
          newProductsSelected = removeScannedAssets(newProductsSelected, +serviceId);
        }
      });

      if (
        !isEqual(newTotalServicesUnits, totalServicesUnits) ||
        !isEqual(newTotalCreditServicesUnits, totalCreditServicesUnits)
      ) {
        dispatch({
          type: 'newServiceVisit/updateTotalUnits',
          payload: {
            totalServicesUnits: newTotalServicesUnits,
            totalCreditServicesUnits: newTotalCreditServicesUnits,
            productsSelected: newProductsSelected,
          },
        });
      }
    }
  }, [
    services,
    servicesUnits,
    variantsUnits,
    creditServicesUnits,
    creditVariantsUnits,
    totalServicesUnits,
    totalCreditServicesUnits,
  ]);

  const updateCurrentStep = (): void => {
    dispatch({ type: 'newServiceVisit/updateCurrentStep', payload: 2 });
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    if (event.key === '.' || event.key === ',') {
      event.preventDefault();
    }
  };

  const renderServicesUnits = (): React.ReactNode[] =>
    selectedServices.map((serviceId: number) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { name, id, unitLabel, allowPartialSale, assetLabel, variants } = services.find(
        (service: any) => serviceId === service.id
      );

      const shouldDisableInputs = transactionStatus === SQUARE_STATUS.OK || !opened;

      return (
        <>
          <Grid key={id} item sm={6} md={6} data-cy="unitsForService">
            <div className={classes.serviceName}>{name}</div>
            <div className={classes.inputsSection}>
              <TextField
                data-cy="currentUseUnitsForService"
                inputProps={{
                  min: '0',
                  max: '500',
                  onKeyDown: (e) => {
                    onKeyDown(e);
                  },
                }}
                label={getServiceLabel(allowPartialSale, unitLabel, assetLabel)}
                value={servicesUnits[id]}
                type="number"
                variant="outlined"
                onChange={(e) => {
                  if (+e.target.value <= 500) {
                    onChangeUnit(e.target.value, id);
                  }
                }}
                className={classes.textField}
                error={+servicesUnits[id] < 0}
                helperText={+servicesUnits[id] < 0 ? '0 or more' : ' '}
                disabled={shouldDisableInputs}
                fullWidth
                style={{ marginRight: '10px' }}
              />
              <TextField
                data-cy="futureUseUnitsForService"
                inputProps={{
                  min: 0,
                  onKeyDown: (e) => {
                    onKeyDown(e);
                  },
                }}
                label="Future use"
                value={creditServicesUnits[id]}
                type="number"
                variant="outlined"
                onChange={(e) => {
                  onChangeCreditUnits(e.target.value, id);
                }}
                className={classes.textField}
                error={+creditServicesUnits[id] < 0}
                helperText={+creditServicesUnits[id] < 0 ? 'Minimum 0' : ' '}
                disabled={shouldDisableInputs}
                fullWidth
                style={{ marginLeft: '10px' }}
              />
            </div>
          </Grid>
          {variants.length > 0 &&
            variants.map(({ items }: IVariant) =>
              items.map(({ name: variantName, minAmount, id: variantItemId, futureUsedAllowed }: any) => (
                <Grid item sm={6} md={6} data-cy="unitsForService">
                  <div className={classes.serviceName}>
                    {variantName} (min {minAmount} - max {minAmount})
                  </div>
                  <div className={classes.inputsSection}>
                    <TextField
                      inputProps={{
                        min: '0',
                        step: minAmount,
                        max: '500',
                        onKeyDown: (e) => {
                          onKeyDown(e);
                        },
                      }}
                      label={getServiceLabel(allowPartialSale, unitLabel, assetLabel)}
                      value={variantsUnits[+variantItemId]}
                      type="number"
                      variant="outlined"
                      onChange={(e) => {
                        if (+e.target.value <= minAmount) {
                          onChangeUnit(e.target.value, id, variantItemId);
                        }
                      }}
                      className={classes.textField}
                      error={+variantsUnits[variantItemId] > 0 && +variantsUnits[variantItemId] < minAmount}
                      helperText={
                        +variantsUnits[variantItemId] > 0 && +variantsUnits[variantItemId] < minAmount
                          ? `Minimum ${minAmount}`
                          : ' '
                      }
                      disabled={transactionStatus === SQUARE_STATUS.OK}
                      fullWidth
                      style={{ marginRight: '10px' }}
                    />
                    <TextField
                      inputProps={{
                        min: 0,
                        step: minAmount,
                        onKeyDown: (e) => {
                          onKeyDown(e);
                        },
                      }}
                      label="Future use"
                      value={creditVariantsUnits[+variantItemId]}
                      type="number"
                      variant="outlined"
                      onChange={(e) => {
                        if (+e.target.value <= minAmount) {
                          onChangeCreditUnits(e.target.value, id, variantItemId);
                        }
                      }}
                      className={classes.textField}
                      error={+creditVariantsUnits[variantItemId] > 0 && +creditVariantsUnits[variantItemId] < minAmount}
                      helperText={
                        +creditVariantsUnits[variantItemId] > 0 && +creditVariantsUnits[variantItemId] < minAmount
                          ? `Minimum ${minAmount}`
                          : ' '
                      }
                      disabled={transactionStatus === SQUARE_STATUS.OK || !futureUsedAllowed}
                      fullWidth
                      style={{ marginLeft: '10px' }}
                    />
                  </div>
                </Grid>
              ))
            )}
        </>
      );
    });

  return (
    <ActiveStepWrapper step={2} onClick={updateCurrentStep}>
      <Card style={{ marginBottom: '0', minHeight: '148px' }}>
        <CardContent className={classes.cardContent}>
          <SmallTitle title="Units for the chosen services" className={classes.smallTitle} />
          <div className={classes.unitsSection}>
            <Grid container spacing={2}>
              {!!services.length && renderServicesUnits()}
            </Grid>
          </div>
        </CardContent>
      </Card>
    </ActiveStepWrapper>
  );
};
