import React from 'react';
import { Grid, Button, Box } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { EHRTextarea } from 'src/components/ui/v1';
import LicenseNumberDetails from 'src/components/common/LicensesNumberDetails';
import { useStates } from 'src/hooks/queries/useStates';
import { EHRSignatureCanvas } from 'src/components/ui/v1/EHRSignatureCanvas';
import { MEDICAL_DIRECTOR_AGREEMENT } from 'src/constants/onboardingAgreements.constant';
import { useSignAgreement } from 'src/hooks/api/useExternalMedicalDirector';
import { useLocation } from 'react-router-dom';
import { MedicalLicenseDetails } from 'src/interfaces/onboarding.interface';
import { ReactComponent as PortraitLogo } from 'src/assets/images/portrait-logo.svg';
import AccessDenied from 'src/components/common/AccessDenied';
import SuccessSubmission from 'src/components/common/SuccessSubmission';
import * as yup from 'yup';
import { formDefaultValues } from './Onboarding/AccountSettings/StepsCards/MedicalDirector/SignAuthorization';

const schema = yup.object().shape({
  orderingRestrictions: yup.string().nullable(),
  medicalDirectorLicenses: yup
    .array()
    .of(
      yup.object().shape({
        licenseNumber: yup.string(),
        state: yup.string(),
      })
    )
    .required('Medical Director Licenses is a required field'),
  signature: yup.string().nullable().required('Signature is a required field'),
});

const ExternalMedicalDirectorForm = () => {
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');
  const { data: states = [] } = useStates();
  const { mutate: signAgreement, isLoading, isSuccess } = useSignAgreement();

  const { control, handleSubmit, errors, setValue } = useForm<Partial<MedicalLicenseDetails>>({
    resolver: yupResolver(schema),
    defaultValues: formDefaultValues,
  });

  const onSubmit = (data: MedicalLicenseDetails) => {
    signAgreement({
      ...data,
      signature: data.signature || '',
      secureMagicLink: token || '',
      medicalDirectorLicenses: data.medicalDirectorLicenses || [],
    });
  };

  if (!token) {
    return <AccessDenied />;
  }

  if (isSuccess) {
    return <SuccessSubmission message="Thank you for submitting your Medical Director form." />;
  }

  return (
    <Grid
      container
      spacing={3}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      style={{ maxWidth: '800px', margin: '50px auto', width: '100%' }}
    >
      <Grid item xs={12}>
        <Box width="300px" margin="auto">
          <PortraitLogo fontSize="large" />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box
          margin="40px 0"
          padding="20px"
          bgcolor="white"
          dangerouslySetInnerHTML={{ __html: MEDICAL_DIRECTOR_AGREEMENT }}
        />
        <hr />
      </Grid>

      <Grid xs={12} item style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <LicenseNumberDetails
          states={states}
          onUpdate={(licenses) => {
            setValue('medicalDirectorLicenses', licenses, { shouldValidate: true });
          }}
          errors={errors?.medicalDirectorLicenses?.message}
        />
      </Grid>

      <Grid xs={12} item>
        <Controller
          id="orderingRestrictions"
          control={control}
          name="orderingRestrictions"
          error={!!errors?.orderingRestrictions}
          render={({ onChange, value }) => (
            <EHRTextarea
              onChange={onChange}
              value={value}
              dataCy="ordering-restrictions"
              label="Ordering Restrictions"
              helperText={errors?.orderingRestrictions?.message}
              error={!!errors?.orderingRestrictions}
            />
          )}
        />
      </Grid>

      <Grid xs={12} item>
        <EHRSignatureCanvas
          errors={!!errors?.signature}
          helperText={errors?.signature?.message}
          onSign={(signature: string) => {
            control.setValue('signature', signature);
          }}
          onClear={() => {
            control.setValue('signature', '');
          }}
        />
      </Grid>

      <Grid xs={12} item style={{ marginTop: '24px' }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={isLoading}
          data-cy="submit-medical-director-form"
        >
          {isLoading ? 'Submitting...' : 'Submit'}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ExternalMedicalDirectorForm;
