import moment from 'moment';

import * as Sentry from '@sentry/react';
import { AppointmentModel } from '@devexpress/dx-react-scheduler';
import { createModel } from '@rematch/core';

import ROUTES from 'src/constants/apiRoutes.constants';
import { toSnakeCase } from 'src/utils/global';
import { DEFAULT_CHIP_COLOR, MULTIPLE_SELECTOR_OPTION_COLORS } from 'src/components/common/Custom/MultipleSelector';
import axiosInstance from '../utils/axios';
import { RootModel } from './rootModel';
import { SchedulerState } from '../types/SchedulerState';

export const schedulers = createModel<RootModel>()({
  state: {
    appointments: [],
    newAppointments: [],
    isLoading: false,
    currentAppointment: {},
  } as SchedulerState,
  reducers: {
    setIsLoading(state: any, isLoading: boolean) {
      return { ...state, isLoading };
    },
    setAppointments(state: any, payload: AppointmentModel[]) {
      return { ...state, appointments: payload };
    },
    setNewAppointments(state: any, payload: AppointmentModel[]) {
      return { ...state, newAppointments: payload };
    },
    setCurrentAppointment(state: any, payload: AppointmentModel) {
      return { ...state, currentAppointment: payload };
    },
    setRemoveCurrentAppointment(state: any) {
      return { ...state, currentAppointment: {} };
    },
  },
  effects: (dispatch: any) => ({
    async getAppointments(payload: { start: any; end: any; practitionerIds?: number[] }) {
      dispatch.schedulers.setIsLoading(true);
      try {
        let appointments = [];

        const v4Response = await axiosInstance.get(ROUTES.APPOINTMENT_INDEX, {
          params: toSnakeCase(payload),
        });
        appointments = v4Response.data.appointments;

        const appointmentsList = appointments.length > 0 ? appointments : [];

        const data: AppointmentModel[] = appointmentsList.map((appointment: any) => {
          const fullName = appointment.customerName || appointment.patient || appointment.category;
          let practitionerColor = null;

          if (payload.practitionerIds?.length) {
            // Hexadecimal for AppointmentBox resources
            const practitionerIdIndex = payload.practitionerIds?.length
              ? payload.practitionerIds?.findIndex((id) => id === appointment?.practitionerId)
              : -1;
            practitionerColor = MULTIPLE_SELECTOR_OPTION_COLORS[practitionerIdIndex] || DEFAULT_CHIP_COLOR;
          }

          return {
            id: appointment.appointmentId || appointment.id,
            startDate: moment(appointment.datetime).format('YYYY-MM-DDTHH:mm'),
            endDate: moment(appointment.datetime).add(appointment.duration, 'minutes').format('YYYY-MM-DDTHH:mm'),
            practitionerName: appointment.practitionerName,
            patient: fullName,
            type: appointment.name || appointment.type,
            statusId: appointment.customerStatus || appointment.status,
            email: appointment.email,
            phone: appointment.phone,
            customerId: appointment.customerId,
            location: appointment.location,
            notes: appointment.notes,
            category: appointment.category,
            canceled: appointment.canceled,
            isFirstAppointment: appointment?.isFirstAppointment,
            calendarId: appointment.calendarId,
            practitionerId: appointment.practitionerId,
            appointmentTypeId: appointment.acuityAppointmentTypeId || appointment.appointmentTypeId,
            practitionerColor,
          };
        });

        dispatch.schedulers.setNewAppointments(data);
      } catch (error) {
        Sentry.captureMessage(`${error}`, 'debug' as Sentry.Severity);
      } finally {
        dispatch.schedulers.setIsLoading(false);
      }
    },
  }),
});
