import React, { useState } from 'react';
import { groupBy, sortBy } from 'lodash';
import { AccordionDetails, Box, Dialog, IconButton, Table, TableBody } from '@material-ui/core';
import IconCross from 'src/components/common/IconCross';
import { assignOrderStatus } from 'src/utils/inventoryOrdering';
// eslint-disable-next-line max-len
import { PORTRAIT_LEGACY, PORTRAIT_LEGACY_PLUS } from 'src/constants/general.constants';
import ISuppliers from 'src/interfaces/ISuppliers';
import { InventoryOrder, InventoryOrderWithId } from 'src/interfaces/IInventoryOrder';
import { useApproveOrder, useRejectOrder, useAdminNote } from 'src/hooks/queries/useInventoryOrders';
import {
  IconLeftAccordionSummary,
  OrderAccordion,
} from '../../../DashboardPractitioner/Tabs/OrderingTab/inventoryOrder.styles';
import { ButtonsFooter } from './ButtonsFooter';
import { OrderApprovalModal, OrderRejectModal, AdminNoteModal } from './OrderApprovalModal';
import { OrderCharges } from '../../../DashboardPractitioner/Tabs/OrderingTab/OrderCharges';
import { OrderApprovalProductRow } from './OrderApprovalProductRow';
import OrderApprovalSupplier from './OrderApprovalSupplier';
import OrderDetails from './OrderApprovalSupplier/OrderDetails';
import { OrderDetailStub } from './OrderDetailStub';

export const OrderApprovalRow = ({
  inventoryOrder,
  suppliers,
}: {
  inventoryOrder: InventoryOrderWithId;
  suppliers: ISuppliers[];
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [approving, setApproving] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogAdminNoteOpen, setDialogAdminNoteOpen] = useState<boolean>(false);
  const [dialogRejectOpen, setDialogRejectOpen] = useState<boolean>(false);
  const [adminNote, setAdminNote] = useState<string | null>(inventoryOrder.adminNote);

  const isLegacy = inventoryOrder.roleName === PORTRAIT_LEGACY || inventoryOrder.roleName === PORTRAIT_LEGACY_PLUS;

  const { mutateAsync: approveOrder } = useApproveOrder(inventoryOrder.id, () => {
    setApproving(false);
  });
  const { mutateAsync: rejectOrder } = useRejectOrder(inventoryOrder.id, () => {
    setApproving(false);
  });
  const { mutateAsync: AddNote } = useAdminNote(inventoryOrder.id);

  const status = assignOrderStatus(inventoryOrder);

  const productsBySupplier = groupBy(inventoryOrder.services, 'supplierName');
  const customItemsBySupplier = groupBy(inventoryOrder.customItems, 'supplierName');

  const orderSuppliers = Object.keys({ ...productsBySupplier, ...customItemsBySupplier });
  const filteredSuppliers = sortBy(
    suppliers.filter((supplier) => orderSuppliers.indexOf(supplier.name) > -1),
    'name'
  );

  const formattedSpecials = inventoryOrder.specials.map((s) => ({ ...s, name: s.specialOffer.name }));

  const approve = async () => {
    setDialogOpen(false);
    setApproving(true);
    try {
      await approveOrder();
    } catch {
      setApproving(false);
    }
  };

  const handleAdminNote = async () => {
    if (!adminNote || adminNote === '') {
      return;
    }
    setDialogAdminNoteOpen(false);
    await AddNote(adminNote);
  };

  const handleRejectOrder = async () => {
    if (!adminNote || adminNote === '') {
      return;
    }
    setDialogRejectOpen(false);
    setApproving(true);
    try {
      await rejectOrder(adminNote);
    } catch {
      setApproving(false);
    }
  };

  return (
    <OrderAccordion expanded={expanded} elevation={0} data-loading={approving && inventoryOrder.cancelable}>
      <IconLeftAccordionSummary
        expandIcon={
          <IconButton
            size="small"
            onClick={() => setExpanded(!expanded)}
            data-testid={`order expand ${inventoryOrder.id}`}
          >
            <IconCross open={expanded} />
          </IconButton>
        }
      >
        <OrderDetailStub
          inventoryOrder={inventoryOrder as InventoryOrder}
          supplierCount={filteredSuppliers.length}
          status={status}
        />
      </IconLeftAccordionSummary>

      <AccordionDetails style={{ flexDirection: 'column' }}>
        <OrderDetails inventoryOrder={inventoryOrder as InventoryOrder} />
        {!isLegacy && (
          <Table>
            <TableBody>
              {formattedSpecials.map((special) => (
                <OrderApprovalProductRow
                  cancelable={!!inventoryOrder.cancelable}
                  key={special.id}
                  status={status}
                  specialOffer={special.specialOffer}
                  {...special}
                />
              ))}
            </TableBody>
          </Table>
        )}
        {filteredSuppliers.map((supplier) => (
          <OrderApprovalSupplier
            supplier={supplier}
            items={productsBySupplier[supplier.name]}
            customItems={customItemsBySupplier[supplier.name]}
            inventoryOrder={inventoryOrder as InventoryOrder}
            key={supplier.id}
            isLegacy={isLegacy}
          />
        ))}
        <OrderCharges
          charges={inventoryOrder.charges.filter((charge) => !charge.supplierId)}
          readOnly={!inventoryOrder.cancelable}
          transactionFeePct={inventoryOrder.transactionFeePct}
          subtotal={inventoryOrder.subtotal}
        />
        <Box height="1rem" />
        <ButtonsFooter
          inventoryOrder={inventoryOrder as InventoryOrder}
          approve={() => setDialogOpen(true)}
          handleAdminNote={() => setDialogAdminNoteOpen(true)}
          handleRejectOrder={() => setDialogRejectOpen(true)}
          isLegacy={isLegacy}
        />
      </AccordionDetails>

      <Dialog open={dialogOpen} maxWidth="lg">
        <OrderApprovalModal
          dismiss={() => setDialogOpen(false)}
          action={approve}
          orderReferenceNumber={inventoryOrder.referenceNumber}
          total={inventoryOrder.total ?? 0}
        />
      </Dialog>
      <Dialog open={dialogAdminNoteOpen} maxWidth="lg">
        <AdminNoteModal
          id={inventoryOrder.id}
          dismiss={() => setDialogAdminNoteOpen(false)}
          action={handleAdminNote}
          adminNote={adminNote ?? ''}
          setAdminNote={setAdminNote}
          status={status}
        />
      </Dialog>
      <Dialog open={dialogRejectOpen} maxWidth="lg">
        <OrderRejectModal
          id={inventoryOrder.id}
          dismiss={() => setDialogRejectOpen(false)}
          action={handleRejectOrder}
          orderReferenceNumber={inventoryOrder.referenceNumber}
          adminNote={adminNote ?? ''}
          setAdminNote={setAdminNote}
          status={status}
        />
      </Dialog>
    </OrderAccordion>
  );
};

export default OrderApprovalRow;
