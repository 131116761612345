import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useQueryClient } from 'react-query';
import { CustomerParams } from 'src/interfaces/CustomerParams';
import { useStyles } from '../../PhysicianPatientsList.styles';
import { PHYSICIAN_DASHBOARD_KEY } from '../../../../constants/general.constants';
import { CUSTOMERS_LIST, useCustomPatients } from '../../../../hooks/queries/useCustomPatients';
import { PortraitTable } from '../../../../components/common/TableNew/Table';
import compile from '../../../../utils/toastMessagesCompiler';
import { PATIENT_COLUMNS, PatientRow } from '../../PhysicianPatientsList';
import { sharedGFEInitialFilters } from '../filters';

interface PhysicianPendingGFEListProps {
  defaultFilters: Record<string, any>;
  currentTabFilters: Record<string, any>;
  doSortBy: (column: string, direction: 'desc' | 'asc' | undefined) => void;
}

export const DefaultGFEsList: React.FC<PhysicianPendingGFEListProps> = ({
  defaultFilters,
  currentTabFilters,
  doSortBy,
}) => {
  const classes = useStyles();
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries([CUSTOMERS_LIST]);
  }, []);

  const filters: CustomerParams = Object.keys(sharedGFEInitialFilters).reduce((acc, key) => {
    if (key in defaultFilters || key in currentTabFilters) {
      acc[key] = defaultFilters[key] || currentTabFilters[key];
    }
    return acc;
  }, {});

  const { results, isLoading, isError, fetchNextPage, hasNextPage, isFetching } = useCustomPatients(
    PHYSICIAN_DASHBOARD_KEY,
    filters
  );
  const patients = results?.pages.map(({ customers = [] }) => customers).flat() || [];

  return (
    <PortraitTable
      columns={PATIENT_COLUMNS}
      sortBy={filters.sortBy}
      sortDirection={filters.sortDirection}
      onSortChange={doSortBy}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
      data={patients}
      rowComponent={(row) => <PatientRow patient={row} />}
      errorMessage={compile('generic.error_message', { action: 'fetching', element: 'patients' })}
      infiScroll
      isLoading={isLoading}
      isFetching={isFetching}
      isError={isError}
      tableStyles={classnames(classes.tableGeneral, classes.infiScrollContainer, classes.patientListContainer)}
    />
  );
};
