import React, { forwardRef } from 'react';
import { makeStyles, IconButton } from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';
import { Close } from '@material-ui/icons';

export type Props = {
  style?: React.CSSProperties;
  variant?: 'filled' | 'outlined';
  message: string | React.ReactNode;
  type: Color;
  action: React.ReactNode;
  onClose: () => void;
};

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '344px !important',
    },
  },
}));

const NotifierCustomComponent = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const classes = useStyles();
  const { message, action, type, style, variant, onClose: onCloseAction } = props;

  const renderActionsButtons = () => {
    const closeButton = (
      <IconButton data-cy="closeAlertBtn" onClick={() => onCloseAction()} aria-label="dimiss">
        <Close htmlColor={style?.color} />
      </IconButton>
    );
    return action ? (
      <>
        {action}
        {closeButton}
      </>
    ) : (
      closeButton
    );
  };

  return (
    <Alert
      data-cy="alert"
      ref={ref}
      className={classes.root}
      style={style}
      severity={type}
      action={renderActionsButtons()}
      variant={variant}
    >
      {message}
    </Alert>
  );
});

export default NotifierCustomComponent;
