import { sumBy } from 'lodash';
import React, { useState } from 'react';
import { AccordionDetails, IconButton, Table, TableBody } from '@material-ui/core';
import IconCross from 'src/components/common/IconCross';
import ISuppliers from 'src/interfaces/ISuppliers';
import { InventoryOrderCustomItem } from 'src/services/InventoryOrderCustomItems';
import OrderTrackingNumbers from 'src/components/DashboardPractitioner/Tabs/OrderingTab/OrderTrackingNumbers';
import OrderConfirmationNumbers from 'src/components/DashboardPractitioner/Tabs/OrderingTab/OrderConfirmationNumbers';
import { OrderConfirmationNumber } from 'src/services/OrderConfirmationNumbers';
import { ShipmentTrackingNumber } from 'src/services/ShipmentTrackingNumbers';
import { assignSupplierStatus, orderHasShipped } from 'src/utils/inventoryOrdering/status.utils';
import { InventoryOrder, InventoryOrderItem, InventoryOrderService } from 'src/interfaces/IInventoryOrder';
import { useApproveSupplier } from 'src/hooks/queries/useInventoryOrders';
import OrderCharges from 'src/components/DashboardPractitioner/Tabs/OrderingTab/OrderCharges';
import { OrderApprovalSubRow, TableHeader } from '../OrderApprovalRowAux';
import {
  SupplierAccordion,
  SupplierAccordionSummary,
} from '../../../../DashboardPractitioner/Tabs/OrderingTab/inventoryOrder.styles';
import { OrderApprovalProductRow } from '../OrderApprovalProductRow';
import { ButtonsFooterSupplier } from '../ButtonsFooterSuppliers';
import { SupplierDetailStub } from './SupplierDetailStub';

type Props = {
  inventoryOrder: InventoryOrder;
  supplier: ISuppliers;
  items: InventoryOrderItem[];
  customItems?: InventoryOrderCustomItem[];
  isLegacy?: boolean;
};
export const OrderApprovalSupplier = ({
  supplier,
  inventoryOrder,
  items = [],
  customItems = [],
  isLegacy = false,
}: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [approving, setApproving] = useState<boolean>(false);

  const { mutateAsync: approveSupplier } = useApproveSupplier(inventoryOrder.id);

  const approve = async () => {
    setApproving(true);
    try {
      await approveSupplier(supplier.id);
    } finally {
      setApproving(false);
    }
  };

  const charges = inventoryOrder.charges.filter((charge) => charge.supplierId === supplier.id);
  const supplierStatus = assignSupplierStatus(inventoryOrder, supplier.id, items[0]);
  const supplierSubtotal = sumBy(items, 'subtotal') + sumBy(customItems, 'subtotal') + sumBy(charges, 'amount');
  const approved = inventoryOrder.approvedSupplierIds.indexOf(supplier.id) > -1;

  return (
    <SupplierAccordion expanded={expanded} data-loading={approving && !approved}>
      <SupplierAccordionSummary
        expandIcon={
          <IconButton
            size="small"
            onClick={() => setExpanded(!expanded)}
            data-testid={`order expand ${inventoryOrder.id} ${supplier.id}`}
          >
            <IconCross open={expanded} />
          </IconButton>
        }
      >
        <SupplierDetailStub
          supplierName={supplier.name}
          inventoryOrderId={inventoryOrder.id}
          productCount={customItems.length + items.length}
          status={supplierStatus}
          subtotal={supplierSubtotal}
        />
      </SupplierAccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>
        <Table>
          <TableHeader
            shipped={orderHasShipped(supplierStatus)}
            approved={approved}
            hasCustomItems={!!customItems.length}
            isLegacy={isLegacy}
          />
          <TableBody>
            {customItems.map((product) => (
              <OrderApprovalProductRow
                {...{ ...product, notes: product.notes ?? '' }}
                cancelable={inventoryOrder.cancelable}
                supplierApproved={approved}
                key={product.id}
              />
            ))}
            {items.map((product) =>
              isLegacy ? (
                <OrderApprovalSubRow
                  {...(product as InventoryOrderService)}
                  finalizedAt={inventoryOrder.finalizedAt}
                  approved={approved}
                  status={supplierStatus}
                  inventoryOrderId={inventoryOrder.id}
                  key={product.id}
                  isLegacy={isLegacy}
                />
              ) : (
                <OrderApprovalProductRow
                  {...product}
                  cancelable={inventoryOrder.cancelable}
                  supplierApproved={approved}
                  key={product.id}
                />
              )
            )}
          </TableBody>
        </Table>
        <OrderCharges readOnly={approved || !inventoryOrder.cancelable} charges={charges} />
        <OrderConfirmationNumbers
          confirmationNumbers={inventoryOrder.orderConfirmationNumbers.filter(
            (confirmation: OrderConfirmationNumber) => confirmation.supplierId === supplier.id
          )}
        />
        <OrderTrackingNumbers
          trackingNumbers={inventoryOrder.shipmentTrackingNumbers.filter(
            (tracking: ShipmentTrackingNumber) => tracking.supplierId === supplier.id
          )}
        />
        <ButtonsFooterSupplier inventoryOrder={inventoryOrder} approve={approve} supplier={supplier} />
      </AccordionDetails>
    </SupplierAccordion>
  );
};

export default OrderApprovalSupplier;
