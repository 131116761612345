import {
  useQuery,
  useMutation,
  useQueryClient,
  UseQueryResult,
  UseMutationResult,
  useInfiniteQuery,
} from 'react-query';
import { useEffect, useMemo } from 'react';
import { DEFAULT_LIMIT, SNACKBAR_SUCCESS } from 'src/constants/general.constants';
import { reactQueryOnSuccessHandler, showSnackbar } from 'src/utils/global';
import {
  CURRENT_USER_GROUP,
  MEDSPA_ADMINS,
  USER_GROUPS,
  USER_GROUPS_INFINITY,
  USER_GROUPS_INFINITY_COUNT,
} from '../../constants/reactQuery.keys';
import {
  IUserGroup,
  IUserGroupList,
  IUserGroupListParams,
  IUserGroupOption,
  IUserGroupOptionParams,
  IUserGroupUpdateParams,
} from '../../interfaces/IUserGroup';
import UserGroups from '../../services/UserGroups';
import compile from '../../utils/toastMessagesCompiler';

export function useUserGroupInfinity(paramsQuery: IUserGroupListParams, total: number = 0, enabled: boolean = true) {
  const limit = DEFAULT_LIMIT;

  const query = useInfiniteQuery<any>(
    [USER_GROUPS_INFINITY, JSON.stringify(paramsQuery)],
    ({ pageParam = 1 }) => UserGroups.getUserGroupInfinity({ ...paramsQuery, page: pageParam }),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      enabled,
      getNextPageParam: (lastPage, pages) => {
        const hasMore = total ? pages.length * limit < total : lastPage.length === limit;
        if (hasMore) {
          return pages.length + 1;
        }
        // No more pages to display
        return undefined;
      },
    }
  );

  const userGroupList = useMemo(
    () =>
      query?.data?.pages
        ?.map((items) => items)
        .flat()
        .filter(Boolean) || [],
    [query?.data?.pages]
  );

  return { ...query, data: { ...query.data, userGroupList, currentPage: query?.data?.pages.length } };
}

export function useUserGroupInfinityCount(paramsQuery: any) {
  return useQuery(
    [USER_GROUPS_INFINITY_COUNT, JSON.stringify(paramsQuery)],
    () => UserGroups.getUserGroupInfinityCount(paramsQuery),
    {
      initialData: 0,
      refetchOnWindowFocus: false,
      retry: 1,
      keepPreviousData: true,
    }
  );
}

const useUserGroupsByFilter = (params: IUserGroupListParams): UseQueryResult<IUserGroupList | null> => {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries([USER_GROUPS, params]);
  }, [queryClient, params]);

  return useQuery({
    queryKey: [USER_GROUPS, params],
    queryFn: (): Promise<IUserGroup[] | IUserGroupList | null> => UserGroups.getUserGroups(params),
    enabled: !params?.disabled,
  });
};

const useUserGroups = (params: IUserGroupListParams): UseQueryResult<IUserGroupList | null> =>
  useQuery({
    queryKey: [USER_GROUPS, JSON.stringify(params)],
    queryFn: (): Promise<IUserGroup[] | IUserGroupList | null> => UserGroups.getUserGroups(params),
    enabled: !params?.disabled,
    refetchOnWindowFocus: false,
  });

const useUserGroupOptions = (params: IUserGroupOptionParams, enabled?: boolean): UseQueryResult<IUserGroupOption[]> =>
  useQuery({
    queryKey: [USER_GROUPS, params?.search],
    queryFn: (): Promise<IUserGroupOption[]> => UserGroups.getUserGroupOptions(params),
    initialData: [] as IUserGroupOption[],
    enabled,
    refetchOnWindowFocus: false,
  });

const useCurrentUserGroup = (enabled: boolean = true): UseQueryResult<IUserGroup | null> =>
  useQuery<IUserGroup | null>({
    queryKey: [CURRENT_USER_GROUP],
    queryFn: (): Promise<IUserGroup | null> => UserGroups.getCurrentUserGroup(),
    enabled,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 2,
  });

const useUpdateUserGroup = (props: {
  successCallback?: () => void;
}): UseMutationResult<void, unknown, IUserGroupUpdateParams, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: IUserGroupUpdateParams): Promise<void> => {
      await UserGroups.updateUserGroup(params);
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [USER_GROUPS] });
      await queryClient.invalidateQueries({ queryKey: [MEDSPA_ADMINS] });
      await queryClient.invalidateQueries({ queryKey: [USER_GROUPS_INFINITY] });
      await queryClient.invalidateQueries({ queryKey: [USER_GROUPS_INFINITY_COUNT] });
      props?.successCallback?.();
    },
  });
};

const useCreateUserGroup = (): UseMutationResult<void, unknown, IUserGroupUpdateParams, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: IUserGroupUpdateParams): Promise<void> => {
      await UserGroups.createUserGroup(params);
    },
    onSuccess: async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [USER_GROUPS] });
      await queryClient.invalidateQueries({ queryKey: [USER_GROUPS_INFINITY] });
      await queryClient.invalidateQueries({ queryKey: [USER_GROUPS_INFINITY_COUNT] });
    },
  });
};

const useCreateV4UserGroup = (successCallback: () => void): UseMutationResult<void, unknown, any, unknown> => {
  const queryClient = useQueryClient();

  return useMutation((params: any) => UserGroups.createV4UserGroup(params), {
    onSuccess: (response: any) =>
      reactQueryOnSuccessHandler(response, async () => {
        queryClient.invalidateQueries({ queryKey: [USER_GROUPS] });
        queryClient.invalidateQueries({ queryKey: [USER_GROUPS_INFINITY] });
        queryClient.invalidateQueries({ queryKey: [USER_GROUPS_INFINITY_COUNT] });
        successCallback?.();
        showSnackbar(
          compile('generic.success_message', {
            element: 'Medspa',
            action: 'created',
          }),
          SNACKBAR_SUCCESS
        );
      }),
    onError: reactQueryOnSuccessHandler,
  });
};

export {
  useUpdateUserGroup,
  useCreateUserGroup,
  useUserGroups,
  useUserGroupsByFilter,
  useUserGroupOptions,
  useCreateV4UserGroup,
  useCurrentUserGroup,
  useCurrentUserGroup as default,
};
