import API_ROUTES from 'src/constants/apiRoutes.constants';
import Api from './Api';

export type InventoryOrderCharge = {
  id: number;
  description: string;
  amount: number;
  inventoryOrderId: number;
  supplierId?: number | null;
  supplierName?: string | null;
};

export default {
  async create(params: Partial<InventoryOrderCharge>): Promise<InventoryOrderCharge> {
    const { data }: { data: InventoryOrderCharge } = await Api.post(API_ROUTES.INVENTORY_ORDER_CHARGES(), params);
    return data;
  },

  async destroy(id: number): Promise<void> {
    await Api.delete(API_ROUTES.INVENTORY_ORDER_CHARGES(id));
  },
};
