import React from 'react';
import { Box, Button } from '@material-ui/core';
import { useMedspaRole } from 'src/hooks/queries/useMedspaAdmins';
import { PORTRAIT_FLEX, PORTRAIT_LAUNCH, SUBMIT_SUPPORT_LINK } from 'src/constants/general.constants';
import {
  PORTRAIT_PRICING_TEMP_URI_FLEX,
  PORTRAIT_PRICING_TEMP_URI_FLEX_LITE,
  PORTRAIT_PRICING_TEMP_URI_LAUNCH,
} from 'src/constants/inventory.constants';
import { NEW_WINDOW } from 'src/constants/clientLead.constants';
import { useSelector } from 'react-redux';
import { RootState } from 'src/rematch';
import Page from 'src/components/common/Page';
import { useStyles } from './inventory.styles';
import { Banner } from './Banner';

const PricingPdf: React.FC<{ shouldRestrictAccess?: boolean; restrictionComponent?: React.ReactNode }> = ({
  shouldRestrictAccess = false,
  restrictionComponent = null,
}): React.ReactElement => {
  const { data: medspaRoleName } = useMedspaRole();
  const { roleName } = useSelector(({ auth }: RootState) => auth);

  let iframeUri = PORTRAIT_PRICING_TEMP_URI_FLEX_LITE;
  // Ascend Pricing PDFs is not available to non medspa user since that product type is medspa only.
  if (medspaRoleName === PORTRAIT_LAUNCH) {
    iframeUri = PORTRAIT_PRICING_TEMP_URI_LAUNCH;
  } else if (medspaRoleName === PORTRAIT_FLEX) {
    iframeUri = PORTRAIT_PRICING_TEMP_URI_FLEX;
  }
  const showBanner = medspaRoleName === PORTRAIT_LAUNCH || roleName === PORTRAIT_LAUNCH;

  const openInNewWindow = (): Window | null => window.open(SUBMIT_SUPPORT_LINK, NEW_WINDOW);

  const classes = useStyles();

  return (
    <Page title="Portrait Pricing">
      <Box className={classes.pricingIframeContainer}>
        <Box>
          <Button className={classes.buttonStyles} onClick={openInNewWindow} disabled={shouldRestrictAccess}>
            Submit Order
          </Button>
        </Box>

        {shouldRestrictAccess ? restrictionComponent : showBanner && <Banner />}
        <iframe
          title="Portrait Pricing"
          src={`https://docs.google.com/viewer?url=${iframeUri}&embedded=true`}
          frameBorder="0"
          width="100%"
          height="600px"
        />
      </Box>
    </Page>
  );
};

export default PricingPdf;
