import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  card: {
    margin: '15px',
    paddingBottom: '24px !important',
    padding: '24px 30px',
    background: '#F5F5F5',
    marginTop: '-68px',
    marginLeft: '10px',
    border: '5px solid transparent',
    borderRadius: '15px',
    marginBottom: '5px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '30px',
  },
  formControl: {
    width: '400px',
    marginRight: '1rem',
  },
  errorMessage: {
    marginTop: '1rem',
    color: '#f44336',
    fontSize: '12px',
  },
}));
