import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, MenuItem } from '@material-ui/core';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { useForm, Controller } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { EHRButton, EHRCRUDModal, EHRMultipleSelect, EHRSelect, EHRTextField } from 'src/components/ui/v1';
import { logSentryException } from 'src/services/Sentry';
import { logDatadogException } from 'src/services/Datadog';
import { useCreateMedSpaPatient, useCreateMedSpaProviderPatient } from '../../../hooks/queries/useMedspaPatients';
import { useMedspaPractitioners } from '../../../hooks/queries/useMedspaAdmins';
import {
  usStates,
  MEDSPA_ADMIN_ROLE,
  PORTRAIT_LEGACY,
  PORTRAIT_LEGACY_PLUS,
} from '../../../constants/general.constants';
import { MEDSPA_PATIENTS_VIEW, MEDSPA_PROVIDER_PATIENTS_VIEW } from '../../../constants/reactQuery.keys';
import useCurrentUserGroup from '../../../hooks/queries/useUserGroups';

const providerSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().email('Enter a valid email').required('Email is required'),
  phone: yup.string().matches(/^\d+$/, 'Phone must contain only numbers').required('Phone is required'),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  address: yup.string().required('Address is required'),
  zip: yup.string().required('Zip is required'),
  providers: yup.array().of(yup.string()).required('Provider is required'),
});

interface Props {
  showNewPatientModal: boolean;
  setShowNewPatientModal: (show: boolean) => void;
  onSuccessCallback?: () => void;
  currentProviderId?: string | number;
}

const NewPatientForm: FC<Props> = ({
  showNewPatientModal,
  setShowNewPatientModal,
  onSuccessCallback,
  currentProviderId,
}) => {
  const { userGroupId, roleName } = useSelector(({ auth }: any) => auth);
  const [saving, setSaving] = useState(false);
  const isSpaAdmin = roleName === MEDSPA_ADMIN_ROLE;
  const { data: userGroupData } = useCurrentUserGroup(isSpaAdmin);
  const isLegacy = userGroupData?.roleName === PORTRAIT_LEGACY || userGroupData?.roleName === PORTRAIT_LEGACY_PLUS;
  const { data: assignedPractitioners = [] } = useMedspaPractitioners(isSpaAdmin);
  const queryClient = useQueryClient();

  const { control, handleSubmit, errors, getValues, reset, setValue } = useForm({
    resolver: yupResolver(providerSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      city: '',
      state: '',
      address: '',
      zip: '',
      providers: [],
    },
  });

  useEffect(() => {
    if (isLegacy && userGroupData) {
      // @ts-ignore
      setValue('providers', userGroupData.practitionerIds);
    } else if (currentProviderId) {
      // @ts-ignore
      setValue('providers', [currentProviderId]);
    }
  }, [isLegacy, userGroupData]);

  const successCallback = () => {
    onSuccessCallback?.();
    queryClient.invalidateQueries([MEDSPA_PATIENTS_VIEW, userGroupId]);
    queryClient.invalidateQueries([MEDSPA_PROVIDER_PATIENTS_VIEW, currentProviderId]);
    setShowNewPatientModal(false);
    reset();
  };
  const { mutateAsync: createMedSpaPatient } = useCreateMedSpaPatient(userGroupId, successCallback);
  const { mutateAsync: createMedSpaProviderPatient } = useCreateMedSpaProviderPatient(
    currentProviderId || 0,
    successCallback
  );

  const onSubmit = async () => {
    setSaving(true);

    const formValues = getValues();
    const formData = new FormData();
    formData.append('first_name', formValues.firstName);
    formData.append('last_name', formValues.lastName);
    formData.append('email', formValues.email);
    formData.append('phone', formValues.phone);
    formData.append('city', formValues.city);
    formData.append('state', formValues.state);
    formData.append('address_line_1', formValues.address);
    formData.append('zip', formValues.zip);

    if (currentProviderId) {
      try {
        await createMedSpaProviderPatient(formData);
      } catch (err) {
        const error = err as Error;
        logSentryException(error as Error);
        logDatadogException(error);
      }
    } else {
      const practitionerIds = Array.isArray(formValues.providers) ? formValues.providers : [formValues.providers];
      practitionerIds.forEach((id) => {
        formData.append('practitioner_ids[]', id);
      });

      try {
        await createMedSpaPatient(formData);
      } catch (err) {
        const error = err as Error;
        logSentryException(error as Error);
        logDatadogException(error);
      }
    }
    setSaving(false);
  };

  const handleClose = () => {
    reset();
    setShowNewPatientModal(false);
  };

  const submitButtonText = () => {
    let buttonText = 'Add';

    if (saving) {
      buttonText = 'Saving...';
    }

    return buttonText;
  };

  return (
    <Box>
      <EHRCRUDModal
        dataCy="modal-new-patient"
        // disableBackdropClick
        open={showNewPatientModal}
      >
        <EHRCRUDModal.Title dataCy="modal-new-patent-modal-title" title="New patient" handleClose={handleClose} />
        <EHRCRUDModal.Body dataCy="modal-new-patient-modal-body">
          <Box display="flex" justifyContent="space-between" flexDirection="column" gridGap="0.25rem" width="100%">
            <Box display="flex" flexDirection="row" gridGap="1rem">
              <Box flex={1}>
                <Controller
                  control={control}
                  name="firstName"
                  render={({ onChange, value }) => (
                    <EHRTextField
                      dataCy="input-firstName"
                      onChange={onChange}
                      value={value}
                      label="First Name"
                      placeholder="Your first name"
                      error={!!errors?.firstName}
                      helperText={errors?.firstName?.message ?? ' '}
                      fullWidth
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <Controller
                  name="lastName"
                  control={control}
                  render={({ onChange, value }) => (
                    <EHRTextField
                      dataCy="input-lastName"
                      onChange={onChange}
                      value={value}
                      error={!!errors?.lastName}
                      helperText={errors?.lastName?.message ?? ' '}
                      label="Last Name"
                      placeholder="Your last name"
                      fullWidth
                    />
                  )}
                />
              </Box>
            </Box>
            <Box>
              <Controller
                name="email"
                control={control}
                render={({ onChange, value }) => (
                  <EHRTextField
                    dataCy="input-email"
                    onChange={onChange}
                    value={value}
                    error={!!errors?.email}
                    helperText={errors?.email?.message ?? ' '}
                    label="Email"
                    placeholder="Your email"
                    fullWidth
                  />
                )}
              />
            </Box>
            <Box>
              <Controller
                name="phone"
                control={control}
                render={({ onChange, value }) => (
                  <EHRTextField
                    dataCy="input-phone"
                    onChange={onChange}
                    value={value}
                    error={!!errors?.phone}
                    helperText={errors?.phone?.message ?? ' '}
                    label="Phone"
                    placeholder="Your phone"
                    fullWidth
                  />
                )}
              />
            </Box>
            <Box display="flex" flexDirection="row" gridGap="1rem">
              <Box flex={1}>
                <Controller
                  control={control}
                  name="address"
                  render={({ onChange, value }) => (
                    <EHRTextField
                      dataCy="input-address"
                      onChange={onChange}
                      value={value}
                      label="Address"
                      placeholder="Your address"
                      error={!!errors?.address}
                      helperText={errors?.address?.message ?? ' '}
                      fullWidth
                    />
                  )}
                />
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" gridGap="1rem">
              <Box flex={1}>
                <Controller
                  name="state"
                  control={control}
                  render={({ onChange }) => (
                    <EHRSelect
                      dataCy="select-state"
                      error={!!errors?.state}
                      helperText={errors?.state?.message ?? ' '}
                      id="select-state"
                      label="State"
                      displayEmpty
                      onChange={onChange}
                      fullWidth
                      renderValue={(v) => {
                        if (!v) {
                          return <span className="ehrSelectPlaceholder">Select a State</span>;
                        }
                        return <span>{v}</span>;
                      }}
                    >
                      {Object.entries(usStates).map(([code, name]) => (
                        <MenuItem key={code} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </EHRSelect>
                  )}
                />
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" gridGap="1rem">
              <Box flex={1}>
                <Controller
                  control={control}
                  name="city"
                  render={({ onChange, value }) => (
                    <EHRTextField
                      dataCy="input-city"
                      onChange={onChange}
                      value={value}
                      label="City"
                      placeholder="Your city"
                      error={!!errors?.city}
                      helperText={errors?.city?.message ?? ' '}
                      fullWidth
                    />
                  )}
                />
              </Box>
              <Box flex={1}>
                <Controller
                  name="zip"
                  control={control}
                  render={({ onChange, value }) => (
                    <EHRTextField
                      dataCy="input-zip"
                      onChange={onChange}
                      value={value}
                      error={!!errors?.zip}
                      helperText={errors?.zip?.message ?? ' '}
                      label="ZIP Code"
                      placeholder="Your zip code"
                      fullWidth
                    />
                  )}
                />
              </Box>
            </Box>
            {!isLegacy && !currentProviderId && (
              <Box>
                <Box style={{ color: '#706F6F', fontSize: '0.75rem', marginBottom: '0.5rem' }}>Providers</Box>
                <Controller
                  name="providers"
                  control={control}
                  render={({ onChange, value }) => (
                    <EHRMultipleSelect
                      dataCy="select-providers"
                      name="providers"
                      options={assignedPractitioners
                        ?.slice()
                        .sort((a, b) => a.firstName.localeCompare(b.firstName))
                        .map((provider) => ({
                          name: `${provider.firstName} ${provider.lastName}`,
                          value: provider.id,
                        }))}
                      value={value}
                      onChange={onChange}
                      error={!!errors?.providers}
                      // @ts-expect-error
                      helperText={errors?.providers?.message ?? ' '}
                      placeholder="Select providers"
                    />
                  )}
                />
              </Box>
            )}
          </Box>
        </EHRCRUDModal.Body>
        <EHRCRUDModal.Footer dataCy="modal-new-patient-modal-footer">
          <EHRButton
            dataCy="btn-add"
            disabled={saving}
            color="primary"
            text={submitButtonText()}
            onClick={handleSubmit(onSubmit)}
            fullWidth
            type="submit"
          />
        </EHRCRUDModal.Footer>
      </EHRCRUDModal>
    </Box>
  );
};

export default NewPatientForm;
