import React from 'react';
import { Box, MenuItem, Select, Typography } from '@material-ui/core';
// eslint-disable-next-line max-len
import { OrderApprovalFilterStyles as useStyles } from 'src/components/DashboardPractitioner/Tabs/OrderingTab/inventoryOrder.styles';
import { InventoryOrderStatus, OrderLookupStatus } from 'src/interfaces/IInventoryOrder';
import { APPROVAL_FILTER_STATUS_BUTTONS } from 'src/constants/inventory.constants';
import { Skeleton } from '@material-ui/lab';
import { Props } from '.';
import ResultCountBadge from './ResultCountBadge';

export const OrderApprovalStatusFilter = ({
  disabled,
  isLoading,
  currentCount,
  filterState = {},
  setFilterState,
}: { disabled?: boolean } & Omit<Props, 'roles'>) => {
  const classes = useStyles();

  const isActive = (value?: OrderLookupStatus) => !disabled && filterState.status === value;
  const showCount = isLoading || currentCount === 0 || (!!currentCount && currentCount > 0);

  return (
    <Box display="flex" flexDirection="column" className={classes.box} data-testid="order-approval-status-filter">
      <Typography variant="overline" className={classes.filterTitle}>
        Status
      </Typography>
      <Box minWidth={180} maxHeight={40}>
        {disabled ? (
          <Skeleton variant="rect" width={180} height={40} className={classes.skeleton} />
        ) : (
          <Select
            value={filterState.status ?? ''}
            onChange={(event) => setFilterState({ ...filterState, status: event.target.value as OrderLookupStatus })}
            disabled={isLoading || disabled}
            className={classes.statusSelect}
            variant="outlined"
            fullWidth
            displayEmpty
            data-testid="order-approval-status-filter-select"
          >
            {Object.entries(APPROVAL_FILTER_STATUS_BUTTONS).map(([key, value]) => {
              const isSelected = isActive(value);
              return (
                <MenuItem key={value} value={value} selected={isSelected} className={classes.capitalize}>
                  <Box display="flex" flexDirection="row">
                    {showCount && isSelected ? (
                      <ResultCountBadge
                        isLoading={isLoading}
                        total={currentCount}
                        status={value as InventoryOrderStatus}
                      />
                    ) : null}
                    <Box margin="auto 0">{key}</Box>
                  </Box>
                </MenuItem>
              );
            })}
          </Select>
        )}
      </Box>
    </Box>
  );
};

export default OrderApprovalStatusFilter;
