import React, { ChangeEvent, useState } from 'react';
import { Box, Button, CircularProgress, MenuItem, Typography } from '@material-ui/core';
import { useAddConfirmationNumber } from 'src/hooks/queries/useInventoryOrders';
import { EHRSelect, EHRSelectOnChange, EHRTextField } from 'src/components/ui/v1';
import useSuppliers from 'src/hooks/queries/useSuppliers';
import { dispatch } from 'src/rematch';
import classNames from 'classnames';
import { OrderConfirmationNumber } from 'src/services/OrderConfirmationNumbers';
import ISuppliers from 'src/interfaces/ISuppliers';
import { DEFAULT_SUPPLIERS } from 'src/constants/inventory.constants';
import { modalStyles } from './OrderApprovalRow.styles';

type Props = {
  id: number;
  supplier?: ISuppliers;
  dismiss: () => void;
};

export const AddConfirmationNumberModal = ({ id, dismiss, supplier }: Props) => {
  const classes = modalStyles();

  const [saving, setSaving] = useState<boolean>(false);
  const [supplierId, setSupplierId] = useState<number | null>(null);
  const [confirmationNumber, setConfirmationNumber] = useState<string | null>(null);

  const formData = {
    supplierId: supplier?.id ?? supplierId,
    confirmationNumber,
  } as Partial<OrderConfirmationNumber>;

  const { mutateAsync: addConfirmationNumber } = useAddConfirmationNumber(id);
  const { data: suppliers = [], isLoading: isLoadingSuppliers } = useSuppliers({
    disabled: !!supplier,
    kind: DEFAULT_SUPPLIERS,
  });

  const handleChangeSupplier: EHRSelectOnChange = (value) => setSupplierId(+value);
  const handleChangeConfirmationNumber = (event: ChangeEvent<HTMLInputElement>) =>
    setConfirmationNumber(event.currentTarget.value);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const invalidFields = Object.keys(formData).filter((key) => !formData[key as keyof OrderConfirmationNumber]);

    if (!invalidFields.length) {
      try {
        setSaving(true);
        await addConfirmationNumber(formData);
        setSaving(false);
        dismiss();
      } catch {
        setSaving(false);
      }
    } else {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: `Invalid Field(s): ${invalidFields.join(', ')}`,
          type: 'error',
        },
      });
    }
  };

  return (
    <Box className={classes.confirmDialog} data-testid="addConfirmation">
      <form onSubmit={onSubmit} className={classes.trackingDialogForm}>
        <Box className={classes.confirmContent}>
          <Typography variant="h5">New Confirmation # {supplier?.name}</Typography>
        </Box>
        <Box className={classes.confirmContent}>
          {!supplier && (
            <EHRSelect
              label="Supplier"
              onChange={handleChangeSupplier}
              value={supplierId ?? ''}
              dataCy="addConfirmation.supplierId"
              data-testid="addConfirmation.supplierId"
              style={{ opacity: isLoadingSuppliers ? 0.5 : 1 }}
              required
              fullWidth
              displayEmpty
            >
              <MenuItem value="" disabled>
                Select a Supplier
              </MenuItem>
              {suppliers.map((menuSupplier) => (
                <MenuItem key={menuSupplier.id} value={menuSupplier.id}>
                  {menuSupplier.name}
                </MenuItem>
              ))}
            </EHRSelect>
          )}
        </Box>
        <Box className={classes.confirmContent}>
          <EHRTextField
            label="Confirmation #"
            onChange={handleChangeConfirmationNumber}
            value={confirmationNumber}
            dataCy="addConfirmation.confirmationNumber"
            data-testid="addConfirmation.confirmationNumber"
            required
          />
        </Box>
        <Box className={classNames(classes.confirmButtons, classes.smallButtons)}>
          <Button
            data-testid="addConfirmation.cancel"
            data-cy="addConfirmation.cancel"
            onClick={dismiss}
            variant="outlined"
            className={classes.actionButton}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            data-testid="addConfirmation.save"
            data-cy="addConfirmation.save"
            variant="outlined"
            className={classes.actionButton}
          >
            {saving ? <CircularProgress style={{ color: 'white' }} size={30} /> : 'Add'}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default AddConfirmationNumberModal;
