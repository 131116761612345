import React, { useState } from 'react';
import classNames from 'classnames';
import LiveGfeCheckmarkIcon from 'src/assets/images/live-ge-checkmark.svg';

import { useCustomerNoShow } from 'src/hooks/mutations/useCustomerNoShow';
import { Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, Divider } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useStyles } from './NoShowAction.styles';

const NoShowAction = ({ customerId, defaultNoShow }: { customerId: number; defaultNoShow: boolean }) => {
  const classes = useStyles();
  const [isNoShow, setIsNoShow] = useState(defaultNoShow);
  const [open, setOpen] = React.useState(false);
  const { mutate: noShow } = useCustomerNoShow();

  const handleChange = () => {
    setIsNoShow(true);
    noShow(customerId);
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const checkboxContainerClasses = classNames(classes.checkboxContainer, {
    [classes.checkboxContainerDisabled]: isNoShow,
  });

  const checkboxClasses = classNames(classes.styledCheckbox, {
    [classes.styledCheckboxChecked]: isNoShow,
  });

  const labelTextClasses = classNames(classes.labelText, {
    [classes.labelTextChecked]: isNoShow,
  });

  return (
    <>
      <label htmlFor={`no-show-action-${customerId}`} className={checkboxContainerClasses}>
        <input
          type="checkbox"
          id={`no-show-action-${customerId}`}
          className={classes.hiddenCheckbox}
          onChange={handleClickOpen}
          checked={isNoShow}
          disabled={isNoShow}
        />

        <span className={checkboxClasses}>
          {isNoShow && <img src={LiveGfeCheckmarkIcon} alt="Live GFE Checkmark" />}
        </span>
        <span className={labelTextClasses}>No Show</span>
      </label>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box display="flex" justifyContent="flex-end" alignItems="right" p="1.5rem">
          <Button onClick={handleClose} style={{ minWidth: 'auto', border: '1px solid #CFCFCF' }}>
            <CloseIcon />
          </Button>
        </Box>
        <Divider />
        <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
          Are you sure you want to mark this patient as a &apos;no-show&apos;?
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <DialogContentText id="alert-dialog-description">
            This action will trigger an email notification to both the patient and provider. The patient will then
            require urgent clearance through a Live GFE just 5 minutes before their treatment appointment.
          </DialogContentText>
        </DialogContent>
        <Box display="flex" justifyContent="space-evenly" style={{ marginBottom: '2rem' }}>
          <Box>
            <Button onClick={handleClose} style={{ border: '1px solid #D4D4D4' }}>
              Cancel action
            </Button>
            <Button
              onClick={handleChange}
              style={{ background: '#FFA500', color: 'white', marginLeft: '1.5rem' }}
              variant="contained"
            >
              Mark as no-show
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default NoShowAction;
