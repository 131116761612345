import React, { useEffect } from 'react';
import { Typography, Box, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../rematch';

const useStyles = makeStyles((theme) => ({
  healthInfo: {
    fontFamily: 'Messina Sans Regular',
    color: '#b8b9b9',
    padding: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.divider}`,
    fontSize: '0.7rem',
    textAlign: 'center',
  },
  link: {
    color: 'inherit',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

const HealthInfo: React.FC<{ isOpen: boolean }> = ({ isOpen }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { healthData, error } = useSelector((state: RootState) => state.health);

  useEffect(() => {
    dispatch.health.fetchHealthData();
  }, [dispatch.health]);

  if (error) {
    return (
      <Box className={classes.healthInfo}>
        <Typography variant="body2" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  if (!healthData || !isOpen) {
    return null;
  }

  return (
    <Box className={classes.healthInfo}>
      <Typography variant="body2">
        Backend Version:{' '}
        <Link
          href={`https://github.com/ellamd/ellamd-api/commit/${healthData.backendVersion}`}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          {healthData.backendVersion}
        </Link>
      </Typography>
      <Typography variant="body2">
        Frontend Version:{' '}
        <Link
          href={`https://github.com/ellamd/portraitspa-ui/commit/${healthData.frontendVersion}`}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          {healthData.frontendVersion}
        </Link>
      </Typography>
    </Box>
  );
};

export default HealthInfo;
