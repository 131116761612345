import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { CheckCircle as CheckCircleIcon } from '@material-ui/icons';

const SuccessSubmission: React.FC<{ message?: string }> = ({
  message = 'Your form has been successfully submitted.',
}) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    minHeight="400px"
    textAlign="center"
    padding={3}
  >
    <CheckCircleIcon style={{ fontSize: 64, color: '#4CAF50', marginBottom: 16 }} />
    <Typography variant="h5" gutterBottom>
        Thank you!
    </Typography>
    <Typography variant="body1" color="textSecondary">
      {message}
    </Typography>
  </Box>
);

export default SuccessSubmission;
