import { Box, CircularProgress, IconButton } from '@material-ui/core';
import React, { useState } from 'react';
import { useDestroyResourcesFile, useResourcesFiles } from 'src/hooks/useResourceFiles';
import { ResourceFile } from 'src/services/resources/resourcesFiles';
import TrashCan from 'src/components/common/icons/TrashCan';
import EditIcon from 'src/components/common/icons/EditIcon';
import { hasAccessTo } from 'src/utils/auth.utils';
import { RESOURCE_CREATE, RESOURCE_DESTROY, RESOURCE_UPDATE } from 'src/constants/actions.constants';
import { useSelector } from 'react-redux';
import { EHRTypography } from 'src/components/ui/v1';
import { UpsertResourcesFilesModal } from './UpsertResourcesFilesModal';
import { useResourcesStyles } from './resources.styles';
import { ResourcesToolbar } from '.';

interface ResourcesTabProps {}

const CATEGORY_ID = 1;

const TutorialsTab = (_props: ResourcesTabProps) => {
  const permissions = useSelector(({ auth }: any) => auth.permissions);
  const classes = useResourcesStyles();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [selectedResource, setSelectedResource] = useState<ResourceFile | null>(null);
  const { resources, isLoading } = useResourcesFiles(CATEGORY_ID);
  const { mutate: deleteResource } = useDestroyResourcesFile();

  if (isLoading) {
    return <CircularProgress />;
  }

  if (resources?.length === 0) {
    return (
      <Box className={classes.resourcesContainer}>
        {hasAccessTo(RESOURCE_CREATE, permissions) && (
          <ResourcesToolbar
            isLoading={isLoading}
            onClick={() => {
              setSelectedResource(null);
              setDialogOpen(true);
            }}
          />
        )}

        <Box className={classes.emptyState}>
          <h3>Welcome to Tutorials Tab</h3>
          <p>It looks like you haven&apos;t added any tutorial yet. </p>
          <p>
            Click on <strong>Add Content</strong> button above to add your first content.
          </p>
        </Box>
        <UpsertResourcesFilesModal onClose={() => setDialogOpen(false)} open={dialogOpen} />
      </Box>
    );
  }

  return (
    <Box className={classes.resourcesContainer}>
      {hasAccessTo(RESOURCE_CREATE, permissions) && (
        <ResourcesToolbar
          isLoading={isLoading}
          onClick={() => {
            setSelectedResource(null);
            setDialogOpen(true);
          }}
        />
      )}

      <Box display="flex" flexWrap="wrap" gridGap="1rem">
        {resources.map((resource: ResourceFile) => (
          <Box className={classes.resourcesListContainer}>
            <Box width="300px" key={resource.id}>
              <iframe src={resource.documentLink} allowFullScreen title={`Tutorial: ${resource.title}`} />

              <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="space-between">
                <Box display="flex" alignItems="center" gridGap="1rem">
                  <p>{resource.title}</p>
                </Box>

                <Box
                  data-testid={`resource ${resource.id} action items`}
                  display="flex"
                  width="100%"
                  justifyContent="end"
                >
                  {!hasAccessTo(RESOURCE_CREATE, permissions) && resource.new && (
                    <Box className={classes.tag}>
                      <EHRTypography
                        variant="label"
                        dataCy={`resource-new-${resource.id}`}
                        style={{ color: '#6B4000' }}
                      >
                        NEW
                      </EHRTypography>
                    </Box>
                  )}
                  {hasAccessTo(RESOURCE_UPDATE, permissions) && (
                    <IconButton
                      style={{ padding: '8px' }}
                      onClick={() => {
                        setSelectedResource(resource);
                        setDialogOpen(true);
                      }}
                      data-testid={`edit resource ${resource.id}`}
                    >
                      <EditIcon />
                    </IconButton>
                  )}

                  {hasAccessTo(RESOURCE_DESTROY, permissions) && (
                    <IconButton
                      style={{ padding: '8px' }}
                      onClick={() => deleteResource(resource.id)}
                      data-testid={`delete resource ${resource.id}`}
                    >
                      <TrashCan />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>

      <UpsertResourcesFilesModal onClose={() => setDialogOpen(false)} open={dialogOpen} resource={selectedResource} />
    </Box>
  );
};

export default TutorialsTab;
