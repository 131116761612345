import React, { useState } from 'react';
import { Box, Button, LinearProgress, Input, Typography } from '@material-ui/core';
import { showOrderingMessage } from 'src/utils/inventoryOrdering';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { LandingPageProps, LeadFormProps, ILeadUpdateParams } from 'src/interfaces/ILeadLandingPage';
import {
  PHONE_NUM_LENGTH,
  PHONE_REGEX,
  PHONE_FORMAT,
  DIGIT_REGEX,
  PHONE,
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  PHONE_TYPE,
  SECURITY_NOTE,
  META_PIXEL_TRACK,
  META_PIXEL_LEAD,
} from 'src/constants/leadLandingPage.constants';
import { useUpdateExternalClientLead } from 'src/hooks/mutations/useUpdateExternalClientLead';
import { Done as DoneIcon } from '@material-ui/icons';
import { schemaForm } from './schemaForm';

declare global {
  interface Window {
    fbq?: (action: string, eventName: string, data?: any, eventId?: { eventId?: string | null }) => void;
  }
}

const FormLoader: React.FC<LandingPageProps> = ({
  submitted,
  classes,
  mobile,
}: LandingPageProps): React.ReactElement => (
  <Box className={classes?.formLoader} data-mobile={mobile}>
    {!submitted ? (
      <>
        <Typography className={classes?.justAMoment}>Just a moment...</Typography>
        <LinearProgress className={classes?.formLoaderProgress} />
      </>
    ) : (
      <>
        <DoneIcon htmlColor="#009900" className={classes?.statusIcon} />
        <Typography className={classes?.allSet}>All Set!</Typography>
      </>
    )}
  </Box>
);

export const LeadForm: React.FC<LeadFormProps> = ({
  classes,
  mobile,
  leadUtms,
  token,
  isLoading,
}: LeadFormProps): React.ReactElement => {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { handleSubmit, errors, control } = useForm<Partial<ILeadUpdateParams>>({
    resolver: yupResolver(schemaForm),
    mode: 'onChange',
  });

  const { mutateAsync } = useUpdateExternalClientLead(token ?? '');

  const { campaign: _, ...formUtms } = { ...leadUtms, adCampaignKey: leadUtms?.campaign };

  const formatPhone = (value: string) => {
    const rawPhone = value.match(DIGIT_REGEX);
    const rawVal = rawPhone ? rawPhone.join('').slice(0, PHONE_NUM_LENGTH) : '';

    return rawVal?.replace(PHONE_REGEX, PHONE_FORMAT);
  };

  const handleSave = async (clientLead: ILeadUpdateParams): Promise<void> => {
    const { fbq: submitMetaEvent } = window as Window;

    setSubmitting(true);
    try {
      submitMetaEvent?.(META_PIXEL_TRACK, META_PIXEL_LEAD, {}, { eventId: formUtms?.eventId });
      await mutateAsync({ ...(formUtms || {}), ...clientLead });
      setSubmitted(true);
    } catch (error) {
      showOrderingMessage('submit', error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box className={classes?.leadForm} data-mobile={mobile} data-submitted={submitted || submitting}>
      <Typography variant="h4" className={classes?.leadFormTitle} data-mobile={mobile}>
        Book a Consultation
      </Typography>
      {submitting || submitted || isLoading ? (
        <FormLoader submitted={submitted} classes={classes} mobile={mobile} />
      ) : (
        <form onSubmit={handleSubmit(handleSave)}>
          <Controller
            name={EMAIL}
            control={control}
            defaultValue=""
            render={({ onChange, value }) => (
              <>
                <Input
                  onChange={onChange}
                  value={value}
                  error={!!errors.email}
                  className={classes?.emailInput}
                  placeholder={EMAIL}
                  type={EMAIL}
                  name={EMAIL}
                  id={EMAIL}
                  disableUnderline
                  required
                  fullWidth
                />
                {errors.email && (
                  <Typography color="error" variant="caption" className={classes?.errorCaption}>
                    {errors.email.message}
                  </Typography>
                )}
              </>
            )}
          />
          <Box className={classes?.nameInputs} data-mobile={mobile}>
            <Controller
              name={FIRST_NAME}
              control={control}
              defaultValue=""
              render={({ onChange, value }) => (
                <Input
                  onChange={onChange}
                  value={value}
                  error={!!errors.firstName}
                  className={classes?.nameInput}
                  placeholder="first name"
                  disableUnderline
                  required
                  name={FIRST_NAME}
                  id={FIRST_NAME}
                />
              )}
            />
            <Controller
              name={LAST_NAME}
              control={control}
              defaultValue=""
              render={({ onChange, value }) => (
                <Input
                  onChange={onChange}
                  value={value}
                  error={!!errors.lastName}
                  className={classes?.nameInput}
                  placeholder="last name"
                  disableUnderline
                  required
                  name={LAST_NAME}
                  id={LAST_NAME}
                />
              )}
            />
          </Box>
          <Controller
            name={PHONE}
            control={control}
            defaultValue=""
            render={({ onChange, value }) => (
              <>
                <Input
                  onChange={(event) => onChange(formatPhone(event.target.value))}
                  value={value}
                  error={!!errors.phone}
                  className={classes?.phoneInput}
                  placeholder={PHONE}
                  type={PHONE_TYPE}
                  disableUnderline
                  required
                  name={PHONE}
                  id={PHONE}
                  fullWidth
                />
                {errors.phone && (
                  <Typography color="error" variant="caption" className={classes?.errorCaption}>
                    {errors.phone.message}
                  </Typography>
                )}
              </>
            )}
          />
          <Box width="100%" display="flex" justifyContent="center">
            <Button type="submit" className={classes?.submitLeadButton}>
              GO TO STEP 2!
            </Button>
          </Box>
        </form>
      )}
      <Typography className={classes?.securityNote}>{SECURITY_NOTE}</Typography>
    </Box>
  );
};
