import React, { useState } from 'react';
import { CircularProgress, TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { ErrorOutline as ErrorOutlineIcon } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import isNumber from 'src/utils/isNumber';
import { useStyles } from './AccountCredit.styles';
import { Button } from '../../../components/common/Button';
import { dispatch } from '../../../rematch';
import { OTHER_FEE_CHARGE_TYPE } from '../../../constants/checkout.constants';
import IServices from '../../../interfaces/IServices';

const OtherFee = ({ patientId, medspaLocationId }: { patientId: string; medspaLocationId?: number | null }) => {
  const classes = useStyles();
  const history = useHistory();
  const [chargeAmount, setChargeAmount] = useState<string | null>(null);
  const [processing, setProcessing] = useState<boolean>(false);
  const [chargeNote, setChargeNote] = useState<string>('');
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const { isProgressQueueWorking } = useSelector(({ newServiceVisit }: any) => newServiceVisit);

  const createAndNavigateToCheckout = async () => {
    try {
      setProcessing(true);
      dispatch({
        type: 'patient/prepareCheckoutForFee',
        payload: {
          patientId,
          chargeAmount,
          chargeType: OTHER_FEE_CHARGE_TYPE,
          chargeNote,
          medspaLocationId,
          successCallback: (serviceVisit: IServices) => {
            setProcessing(false);
            history.push(`/patient/${patientId}/newServiceVisit/${serviceVisit.id}/checkout`);
          },
        },
      });
    } finally {
      setProcessing(false);
    }
  };

  const validChargeAmount = () => chargeAmount && parseFloat(chargeAmount) > 0;
  const validChargeNote = () => !isEmpty(chargeNote);

  const isCheckoutDisabled =
    !validChargeAmount() ||
    !validChargeNote() ||
    processing ||
    isProgressQueueWorking ||
    (medspaLocationId != null && !isNumber(medspaLocationId));

  return (
    <div className={classes.container}>
      <div>
        Enter a note to describe what this charge is for:
        <div className={classes.chargeNoteContainer}>
          <TextField
            error={!validChargeNote}
            helperText={!validChargeNote && 'Note cannot be empty'}
            value={chargeNote}
            onChange={(e) => {
              setChargeNote(e.target.value);
            }}
            fullWidth
            multiline
            variant="outlined"
            margin="normal"
            name="chargeNote"
            label="Note"
          />
        </div>
      </div>
      <div>
        Enter Amount
        <div className={classes.creditAmountContainer}>
          <NumberFormat
            className={classes.creditAmountInput}
            decimalScale={2}
            fixedDecimalScale
            customInput={TextField}
            variant="outlined"
            label="$"
            // @ts-ignore
            value={chargeAmount}
            autoComplete="off"
            name="credit_amount"
            onChange={(e) => {
              setShowErrorMessage(false);
              setChargeAmount(e.target.value);
            }}
            disabled={processing}
            error={chargeAmount && !validChargeAmount()}
            helperText={chargeAmount && !validChargeAmount() && 'Charge amount must be greater than 0'}
          />
        </div>
      </div>
      <p>
        By processing this payment I confirm the patient has provided verbal or written consent to charge the credit
        card on file for the amount charged.
      </p>
      <Button
        disabled={isCheckoutDisabled}
        className={classes.button}
        // @ts-ignore
        title={
          // eslint-disable-next-line no-nested-ternary
          processing ? (
            <>
              Processing
              <CircularProgress size={20} className={classes.processing} />
            </>
          ) : validChargeAmount() ? (
            `Checkout: $${chargeAmount}`
          ) : (
            'Checkout'
          )
        }
        onClick={createAndNavigateToCheckout}
      />
      {showErrorMessage && (
        <div className={classes.errorMsg}>
          <ErrorOutlineIcon className={classes.errorMsgIcon} /> Error processing payment. Please try again.
        </div>
      )}
    </div>
  );
};

export default OtherFee;
