import React, { ReactElement } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { useDestroyTrackingNumber } from 'src/hooks/queries/useInventoryOrders';
import TrashCan from 'src/components/common/icons/TrashCan';
import { ShipmentTrackingNumber } from 'src/services/ShipmentTrackingNumbers';
import { useStyle, TableCellHeader } from './inventoryOrder.styles';

type Props = {
  trackingNumbers: ShipmentTrackingNumber[];
  colFill?: number;
  readOnly?: boolean;
};

type RowProps = Omit<Props, 'trackingNumbers'> & {
  trackingNumber: ShipmentTrackingNumber;
  colFill?: number;
  children?: ReactElement | null;
  isLoading?: boolean;
};

const TrackingNumberRow: React.FC<RowProps> = ({ children, trackingNumber, isLoading, colFill = 0 }) => {
  const classes = useStyle();

  return (
    <TableRow className={classes.pulseOnly} data-loading={isLoading}>
      <TableCell className={classes.approvalRowName}>{trackingNumber.supplierName}</TableCell>
      <TableCell className={classes.approvalRowName}>{trackingNumber.companyName}</TableCell>
      {!!colFill && <TableCell colSpan={colFill - (children ? 1 : 0)} />}
      <TableCell className={colFill ? classes.approvalRowPrice : undefined}>{trackingNumber.trackingNumber}</TableCell>
      {!!children && <TableCell className={classes.removeItem}>{children}</TableCell>}
    </TableRow>
  );
};

const TrackingNumberRowEdit: React.FC<Omit<RowProps, 'children' | 'isLoading'>> = ({ trackingNumber, colFill = 0 }) => {
  const { mutateAsync: removeTrackingNumber, isLoading } = useDestroyTrackingNumber(trackingNumber.id);

  return (
    <TrackingNumberRow trackingNumber={trackingNumber} isLoading={isLoading} colFill={colFill}>
      {isLoading ? null : (
        <Box
          onClick={async () => {
            await removeTrackingNumber();
          }}
        >
          <TrashCan />
          Remove
        </Box>
      )}
    </TrackingNumberRow>
  );
};

export const OrderTrackingNumbers: React.FC<Props> = ({ trackingNumbers = [], colFill = 0, readOnly = false }) => {
  const classes = useStyle();

  return !trackingNumbers.length ? null : (
    <Box>
      <Box padding="1rem">
        <Typography variant="h6" className={classes.supplierSubsectionHeader}>
          Tracking #
        </Typography>
      </Box>
      <TableContainer data-testid="orderSummary.tracking">
        <Table>
          <TableHead>
            <TableRow>
              <TableCellHeader>Supplier</TableCellHeader>
              <TableCellHeader>Shipping Company</TableCellHeader>
              {!!colFill && <TableCellHeader colSpan={colFill - (!readOnly ? 1 : 0)} />}
              <TableCellHeader>Tracking Number</TableCellHeader>
              {!readOnly && <TableCellHeader className={classes.functional} />}
            </TableRow>
          </TableHead>
          <TableBody>
            {trackingNumbers.map((trackingNumber) =>
              !readOnly ? (
                <TrackingNumberRowEdit trackingNumber={trackingNumber} key={trackingNumber.id} colFill={colFill} />
              ) : (
                <TrackingNumberRow trackingNumber={trackingNumber} key={trackingNumber.id} colFill={colFill} />
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default OrderTrackingNumbers;
