import React, { useEffect, useState } from 'react';
import { Box, Button, FormHelperText, Grid, Modal, TextField, Typography, Divider } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import classNames from 'classnames';
import { snakeCase } from 'lodash';
import { useUserGroupInfinity } from 'src/hooks/queries/useUserGroups';
import DragAndDropComponent from 'src/components/DragAndDrop';
import { ReactComponent as UploadProfileIcon } from 'src/assets/images/upload-profile-image.svg';
import IMedspa from 'src/interfaces/IMedspa.interface';
import useCreateMedspaAdmin from 'src/hooks/queries/useMedspaAdmins';
import { useCreateMedspaProvider } from 'src/hooks/practitioner/usePractitioner';
import {
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  PHONE,
  PROFILE_IMAGE,
  SIGNATURE_IMAGE,
  VALID_EMAIL,
  VALID_PHONE,
  VALID_PHONE_REGEX,
} from 'src/constants/schemaForm.constants';
import {
  EHRAutocompleteSelect,
  EHRButton,
  EHRCRUDModal,
  EHRCheckbox,
  EHRTextField,
  EHRTypography,
  IAutocompleteSelectOption,
} from 'src/components/ui/v1';
import CredentialsDropdown from 'src/components/common/CredentialsDropdown';
import { useStyles } from './MedspaModal.styles';
import { PORTRAIT_ASCEND, PORTRAIT_FLEX, PORTRAIT_LAUNCH } from '../../../constants/general.constants';

const userSchema = yup.object().shape({
  userGroupId: yup.string().required('MedSpa is a required field').nullable(),
  firstName: yup.string().required(FIRST_NAME),
  lastName: yup.string().required(LAST_NAME),
  email: yup.string().required(EMAIL).email(VALID_EMAIL),
  phone: yup.string().required(PHONE).matches(VALID_PHONE_REGEX, VALID_PHONE),
  licenses: yup.string(),
  position: yup.string(),
  profileImage: yup.string().required(PROFILE_IMAGE),
  signatureImage: yup.string().when('requireSignature', (value: any) => {
    if (value) {
      return yup.string().required(SIGNATURE_IMAGE).nullable();
    }
    return yup.string().nullable();
  }),
});

export const NewMedspaUserModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const classes = useStyles();
  const [selectedUserType, setSelectedUserType] = useState<string>('');
  const [medspaSearch, setMedspaSearch] = useState<string>('');
  const [medspaParams, setMedspaParams] = useState<Record<string, any>>({
    search: '',
    sort: 'created_at',
    direction: 'desc',
    roleName: [PORTRAIT_ASCEND, PORTRAIT_FLEX, PORTRAIT_LAUNCH],
    page: 1,
    limit: 30,
  });
  const {
    data: { userGroupList: medspas = [] },
    isLoading: isLoadingMedspas,
  } = useUserGroupInfinity(medspaParams, 0, medspaSearch.length > 2);
  const [selectedMedspa, setSelectedMedspa] = useState<IMedspa | null>(null);
  const isAdminUser = selectedUserType === 'Admin';
  const [isOperatingProvider, setIsOperatingProvider] = useState<boolean>(false);
  const [profileImageFile, setProfileImageFile] = useState<File>();
  const [signatureImageFile, setSignatureImageFile] = useState<File>();
  const [signaturePreview, setSignaturePreview] = useState<string | null>(null);
  const [profilePreview, setProfilePreview] = useState<string | null>(null);

  const { mutateAsync: createMedspaAdmin, isLoading: isCreatingMedspaAdmin } = useCreateMedspaAdmin();
  const { mutateAsync: createMedspaProvider, isLoading: isCreatingMedspaProvider } = useCreateMedspaProvider(
    selectedMedspa?.id || 0
  );

  const isCreating = isCreatingMedspaAdmin || isCreatingMedspaProvider;

  useEffect(() => {
    setMedspaParams({ ...medspaParams, ...{ search: medspaSearch, sort: 'name', direction: 'asc' } });
  }, [medspaSearch]);

  useEffect(() => {
    setValue('requireSignature', isOperatingProvider || !isAdminUser);
  }, [isOperatingProvider, isAdminUser]);

  const { handleSubmit, errors, setValue, control, reset } = useForm({
    resolver: yupResolver(userSchema),
    defaultValues: {
      userGroupId: null,
      profileImage: null,
      signatureImage: null,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      isOperatingProvider: false,
      position: '',
      licenses: '',
      requireSignature: isOperatingProvider || !isAdminUser,
    },
  });

  const onSubmit = async (formData: any) => {
    const createData = {
      signatureImage: signatureImageFile,
      profileImage: profileImageFile,
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: formData.phone,
      position: formData.position,
      licenses: formData.licenses,
      provisionProviderAccount: isOperatingProvider,
      active: true,
    };

    if (selectedUserType === 'Admin') {
      await createMedspaAdmin({
        userGroupId: formData.userGroupId,
        params: createData,
      });
    } else {
      const createFormData = new FormData();
      Object.entries(createData).forEach(([key, value]) => {
        createFormData.append(snakeCase(key), value);
      });
      await createMedspaProvider(createFormData);
    }
    handleClose();
  };

  const handleClose = () => {
    setSelectedUserType('');
    setIsOperatingProvider(false);
    setSelectedMedspa(null);
    setProfilePreview(null);
    setSignaturePreview(null);
    reset();
    onClose();
  };

  const handleSelectMedspa = (medspaOption: IAutocompleteSelectOption | null) => {
    if (!medspaOption) {
      setSelectedMedspa(null);
      setValue('userGroupId', null);
      return;
    }
    const medspaIndex = medspas.findIndex((e: IMedspa) => e.id === medspaOption.value);
    if (medspaIndex < 0) {
      return;
    }
    const medspa = medspas[medspaIndex];
    setSelectedMedspa(medspa);
    setValue('userGroupId', medspa.id);
  };

  const handleProfilePicChange = (fileData: File[]) => {
    const file = fileData?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePreview((reader.result || '').toString());
        // @ts-ignore
        setValue('profileImage', file, { shouldValidate: true });
        setProfileImageFile(file);
      };
      reader.readAsDataURL(file);
    } else {
      setValue('profileImage', null, { shouldValidate: true });
    }
  };

  const handleSignatureChange = (fileData: File[]) => {
    const file = fileData?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSignaturePreview((reader.result || '').toString());
        // @ts-ignore
        setValue('signatureImage', file, { shouldValidate: true });
        setSignatureImageFile(file);
      };
      reader.readAsDataURL(file);
    } else {
      setValue('signatureImage', null, { shouldValidate: true });
    }
  };

  return !selectedUserType ? (
    <Modal open={open} onClose={handleClose}>
      <Box className={classes.modalProductSelectModal}>
        <Box className={classes.modalProductSelectContainer}>
          <Box className={classes.modalProductSelectBody}>
            <Typography variant="h6">New User</Typography>
            <Typography className={classes.subTitle}>Please select a user role</Typography>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={6}>
                <EHRButton
                  dataCy="btn-user-role-admin"
                  color="default"
                  className={classes.productOption}
                  type="button"
                  onClick={() => setSelectedUserType('Admin')}
                  text="Admin"
                />
              </Grid>
              <Grid item xs={6}>
                <EHRButton
                  dataCy="btn-user-role-operating-provider"
                  color="default"
                  className={classes.productOption}
                  onClick={() => setSelectedUserType('Operating Provider')}
                  text="Operating Provider"
                />
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box className={classes.buttonContainer}>
            <EHRButton dataCy="btn-cancel-new-user-modal" color="default" onClick={handleClose} text="Cancel" />
          </Box>
        </Box>
      </Box>
    </Modal>
  ) : (
    <EHRCRUDModal dataCy="modal-new-medspa-user" open={open} onClose={handleClose}>
      <EHRCRUDModal.Title dataCy="modal-new-medspa-user-modal-title" title="User Role" handleClose={handleClose} />

      <EHRCRUDModal.Body dataCy="modal-new-medspa-modal-body">
        <Box display="flex" justifyContent="space-between" flexDirection="column" gridGap="0.25rem" width="100%">
          <Box>
            <EHRTextField
              dataCy="input-productType"
              name="roleName"
              value={selectedUserType}
              label="Product Type"
              helperText=" "
              disabled
            />
          </Box>

          <Box>
            <EHRAutocompleteSelect
              dataCy="autocompleteselect-medspa"
              options={
                medspaSearch.length > 2
                  ? medspas.map((m: IMedspa) => ({
                    name: m.name,
                    value: m.id,
                  }))
                  : []
              }
              disableCloseOnSelect={false}
              openOnFocus
              clearOnEscape
              label="Medspa"
              onChange={handleSelectMedspa}
              inputValue={medspaSearch}
              onChangeDebounceSearch={(newInputValue) => {
                setMedspaSearch(newInputValue);
              }}
              loading={isLoadingMedspas}
              getOptionLabel={(option: IAutocompleteSelectOption) => `${option.name}`}
              fullWidth
              renderInput={(params) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <TextField {...params} placeholder="Select MedSpa" variant="outlined" />
              )}
              noOptionsText={medspaSearch.length > 2 ? 'No Options' : 'Type at least 3 characters to search'}
              selectedOption={
                selectedMedspa
                  ? {
                    name: selectedMedspa.name,
                    value: selectedMedspa.id,
                  }
                  : undefined
              }
              error={!!errors?.userGroupId}
              helperText={errors?.userGroupId?.message || ' '}
            />
          </Box>

          <Box
            className={classNames(classes.headShotContainer, errors?.profileImage ? 'ehrUploadContainerError' : null)}
          >
            <EHRTypography
              dataCy="label-upload-headsot"
              variant="label"
              error={!!errors?.profileImage}
              style={{ marginBottom: '0.5rem' }}
            >
              Upload Headshot
            </EHRTypography>
            <input
              accept="image/*"
              id="profile-photo-upload"
              type="file"
              name="profilePicture"
              style={{ display: 'none' }}
            />
            <label htmlFor="profile-photo-upload" className={classes.uploadProfileImageContainer}>
              {profilePreview ? (
                <Box display="flex" alignItems="center">
                  <img src={profilePreview} alt="Profile preview" className={classes.profileImage} />
                </Box>
              ) : (
                <UploadProfileIcon className={classes.profileImage} />
              )}
              <DragAndDropComponent
                hideDocumentIcon
                height="80px"
                hideRemoveFile
                acceptedFileType="image/png,image/jpg,image/jpeg"
                onFileChange={handleProfilePicChange}
                error={!!errors?.profileImage}
              />
            </label>
            <FormHelperText error>{errors?.profileImage?.message || ' '}</FormHelperText>
          </Box>

          <Box display="flex" flexDirection="row" gridGap="1rem">
            <Box flex={1}>
              <Controller
                control={control}
                name="firstName"
                rules={{ required: 'First name is required' }}
                render={({ onChange, value }) => (
                  <EHRTextField
                    dataCy="input-firstName"
                    data-cy="firstName"
                    name="firstName"
                    onChange={onChange}
                    value={value}
                    label="First Name"
                    error={!!errors?.firstName}
                    helperText={errors?.firstName?.message || ' '}
                  />
                )}
              />
            </Box>

            <Box flex={1}>
              <Controller
                control={control}
                name="lastName"
                rules={{ required: 'Last name is required' }}
                render={({ onChange, value }) => (
                  <EHRTextField
                    dataCy="input-lastName"
                    data-cy="lastName"
                    name="lastName"
                    onChange={onChange}
                    value={value}
                    label="Last Name"
                    error={!!errors?.lastName}
                    helperText={errors?.lastName?.message || ' '}
                  />
                )}
              />
            </Box>
          </Box>

          <Box>
            <Controller
              control={control}
              name="email"
              rules={{ required: 'Email is required' }}
              render={({ onChange, value }) => (
                <EHRTextField
                  dataCy="input-email"
                  data-cy="email"
                  name="email"
                  onChange={onChange}
                  value={value}
                  label="Email"
                  error={!!errors?.email}
                  helperText={errors?.email?.message || ' '}
                />
              )}
            />
          </Box>

          <Box>
            <Controller
              control={control}
              name="phone"
              rules={{ required: 'Phone number is required' }}
              render={({ onChange, value }) => (
                <EHRTextField
                  dataCy="input-phone"
                  data-cy="phone"
                  name="phone"
                  onChange={onChange}
                  value={value}
                  label="Phone Number"
                  error={!!errors?.phone}
                  helperText={errors?.phone?.message || ' '}
                />
              )}
            />
          </Box>

          {isAdminUser && (
            <Box className={classes.operatingProviderContainer}>
              <Controller
                name="isOperatingProvider"
                control={control}
                defaultValue={false}
                render={() => (
                  <EHRCheckbox
                    dataCy="label-is-operating-provider"
                    label="Operating Provider"
                    onChange={(e) => setIsOperatingProvider(e.target.checked)}
                    className={classes.operatingProviderCheckbox}
                  >
                    <Typography variant="body2" className={classes.operatingProviderSubtext}>
                      If this is checked the MedSpa Admin account will be created with a Provider dashboard experienced
                      linked to it
                    </Typography>
                  </EHRCheckbox>
                )}
              />
            </Box>
          )}

          {(!isAdminUser || isOperatingProvider) && (
            <>
              <Box display="flex" flexDirection="row" gridGap="1rem">
                <Box flex={1}>
                  <Controller
                    control={control}
                    name="licenses"
                    render={({ onChange, value }) => (
                      <CredentialsDropdown value={value} onChange={onChange} hasErrors={!!errors?.licenses} />
                    )}
                  />
                </Box>

                <Box flex={1}>
                  <Controller
                    control={control}
                    name="position"
                    render={({ onChange, value }) => (
                      <EHRTextField
                        dataCy="input-position"
                        name="position"
                        onChange={onChange}
                        value={value}
                        label="Position"
                        error={!!errors?.position}
                        helperText={errors?.position?.message || ' '}
                      />
                    )}
                  />
                </Box>
              </Box>

              <Box
                className={classNames(
                  classes.signatureImageContainer,
                  errors?.signatureImage ? 'ehrUploadContainerError' : null
                )}
              >
                <Box className={classes.signatureImageHeader}>
                  <EHRTypography
                    dataCy="label-upload-signature"
                    variant="label"
                    error={!!errors?.signatureImage}
                    style={{ marginBottom: '0.5rem' }}
                  >
                    Upload Signature
                  </EHRTypography>

                  {signaturePreview && (
                    <Button
                      type="button"
                      onClick={() => {
                        setSignaturePreview(null);
                        setValue('signatureImage', null, { shouldValidate: true });
                      }}
                      className={classes.signatureUpdate}
                    >
                      Update
                    </Button>
                  )}
                </Box>

                {signaturePreview ? (
                  <img src={signaturePreview} alt="Signature preview" className={classes.signatureImage} />
                ) : (
                  <DragAndDropComponent
                    hideDocumentIcon
                    height="80px"
                    hideRemoveFile
                    acceptedFileType="image/png,image/jpg,image/jpeg"
                    onFileChange={handleSignatureChange}
                    error={!!errors?.signatureImage}
                  />
                )}
                <FormHelperText error>{errors?.signatureImage?.message || ' '}</FormHelperText>
              </Box>
            </>
          )}
        </Box>
      </EHRCRUDModal.Body>

      <EHRCRUDModal.Footer dataCy="modal-footer-new-medspa">
        <Box display="flex" width="100%">
          <EHRButton
            dataCy="btn-add-modal-new-medspa-user"
            onClick={handleSubmit(onSubmit)}
            disabled={isCreating}
            color="primary"
            text={isCreating ? 'Processing...' : 'Add'}
            fullWidth
          />
        </Box>
      </EHRCRUDModal.Footer>
    </EHRCRUDModal>
  );
};
