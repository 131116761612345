import React from 'react';
import { Button, CircularProgress, Box } from '@material-ui/core';
import classnames from 'classnames';
// eslint-disable-next-line max-len
import { OrderApprovalFilterStyles as useStyles } from 'src/components/DashboardPractitioner/Tabs/OrderingTab/inventoryOrder.styles';
import ProductCountBadge from '../../OrderApprovalRow/OrderApprovalSupplier/ProductCountBadge';

type Props = {
  disabled: boolean;
  isLoading: boolean;
  isActive: boolean;
  onClick: () => void;
  text: string;
  total?: number;
};

export const OrderApprovalFiltersButton = ({ disabled, isActive, isLoading, onClick, text, total = -1 }: Props) => {
  const classes = useStyles();

  let buttonContent;
  if (total > -1) {
    buttonContent =
      isActive && isLoading ? (
        <Box margin="0 5px" width={18} height={18}>
          <CircularProgress size={15} />
        </Box>
      ) : (
        <ProductCountBadge total={total} color={isActive ? 'active' : 'dark'} size="small" />
      );
  }

  return (
    <Button
      disabled={disabled}
      className={classnames(classes.button, isActive ? classes.buttonActive : undefined)}
      onClick={onClick}
      data-testid={`order-approval-filter-button-${text}`}
    >
      {text}
      {buttonContent}
    </Button>
  );
};
