import React, { useState, useEffect } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  Chip,
  makeStyles,
} from '@material-ui/core';
import { useStyle as useInventoryOrderStyles } from './inventoryOrder.styles';

const useProductSelectionStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '80%',
      maxWidth: '800px',
      maxHeight: '90vh',
      margin: '20px',
      borderRadius: '8px',
    },
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(2),
  },
  dialogContent: {
    padding: theme.spacing(3),
  },
  dialogActions: {
    padding: theme.spacing(2),
  },
  chip: {
    marginLeft: theme.spacing(1),
  },
}));

function InventoryOrderSpecialCreateModal({
  inventoryOrderSpecialProductList,
  open,
  onClose,
  specialDescription,
  specialName,
  onUpdateSpecialOffer,
}: {
  inventoryOrderSpecialProductList: string;
  open: boolean;
  onClose: () => void;
  specialDescription: string;
  specialName: string;
  onUpdateSpecialOffer: (productList: string) => Promise<void>;
}) {
  const inventoryOrderClasses = useInventoryOrderStyles();
  const productSelectionClasses = useProductSelectionStyles();

  const [productList, setProductList] = useState(inventoryOrderSpecialProductList || '');

  useEffect(() => {
    if (open) {
      setProductList(inventoryOrderSpecialProductList || '');
    }
  }, [open, inventoryOrderSpecialProductList]);

  const handleUpdate = async () => {
    try {
      await onUpdateSpecialOffer(productList);
      onClose();
    } catch (error) {
      console.log('Failed to update special offer:', error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={productSelectionClasses.dialog}
      BackdropProps={{
        style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
      }}
    >
      <DialogTitle className={productSelectionClasses.dialogTitle}>Select products</DialogTitle>
      <DialogContent className={productSelectionClasses.dialogContent}>
        <Box display="flex" flexDirection="row">
          <Typography variant="h6" gutterBottom>
            {specialName}
          </Typography>
          <Box marginLeft="15px" marginTop="5px">
            <Chip label="Limited time" color="secondary" size="small" className={inventoryOrderClasses.chip} />
          </Box>
        </Box>
        <Typography style={{ maxWidth: 'none' }} className={inventoryOrderClasses.nameCol} variant="body2" paragraph>
          {specialDescription}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Customize your Inventory
        </Typography>
        <TextField
          name="productList"
          onChange={(e) => setProductList(e.target.value)}
          fullWidth
          multiline
          rows={4}
          value={productList}
          variant="outlined"
          // eslint-disable-next-line max-len
          placeholder={`Select the products and quantities you'd like to purchase from the special offer. Refer to the description above for details on the included products and quantities.`}
          helperText={`${productList.length}/500`}
          inputProps={{ maxLength: 500 }}
        />
      </DialogContent>
      <DialogActions className={productSelectionClasses.dialogActions}>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleUpdate}
          color="primary"
          variant="contained"
          disabled={productList === inventoryOrderSpecialProductList}
        >
          {inventoryOrderSpecialProductList ? 'Update' : 'Confirm and Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default InventoryOrderSpecialCreateModal;
