import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { map, filter } from 'lodash';
import { useLocation } from 'react-router-dom';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { ILeadUtmParams } from 'src/interfaces/ILeadLandingPage';
import {
  FULLSCREEN,
  DATEYEAR,
  DISCLAIMER_TEXT,
  ALLOWED_UTMS,
  META_PIXEL_COOKIE,
  META_CLICK_COOKIE,
  META_CLICK_PREFIX,
  META_TAG_CONTENT,
} from 'src/constants/leadLandingPage.constants';
import { useContactDetails } from 'src/hooks/mutations/useUpdateExternalClientLead';
import { useIdempotencyKey } from 'src/hooks/queries/useIdempotencyKey';
import { LeadForm } from 'src/components/LeadLandingPage/LeadForm';
import { Hero } from 'src/components/LeadLandingPage/Hero';
import { ContactBar } from 'src/components/LeadLandingPage/ContactBar';
import { BeforeAfterSection } from 'src/components/LeadLandingPage/BeforeAfterSection';
import { ReviewsSection } from 'src/components/LeadLandingPage/ReviewsSection';
import { ExpectationsSection } from 'src/components/LeadLandingPage/ExpectationsSection';
import { landingPageStyles } from './leadLandingPage.styles';

const LeadLandingPage: React.FC = (): React.ReactElement => {
  const classes = landingPageStyles();
  const mobile = useMediaQuery(FULLSCREEN);
  const pageStyles = { classes, mobile };

  const [leadUtms, setLeadUtms] = useState<ILeadUtmParams>({});

  const { data: contactDetails, isLoading: isLoadingContactDetails } = useContactDetails(leadUtms.campaign ?? '');
  const { data: eventId, isLoading: isLoadingEventId } = useIdempotencyKey(leadUtms.eventId ?? undefined);

  const { token, ...providerContactDetails } = contactDetails || {};
  const { search: locationSearch } = useLocation();

  const cleanParam = (data: string[]): string =>
    !!data.length && data[0].indexOf('=') > -1 ? data[0].split('=')[1] : '';

  const getCookie = (cookieName: string): string =>
    cleanParam(
      document.cookie
        .split(';')
        .filter((e) => e.indexOf(cookieName) > -1)
        .slice(-1)
    );

  const getUTMParams = (): void => {
    const uriInput: string[] = decodeURIComponent(locationSearch.slice(1)).split('&');
    const utms: ILeadUtmParams = Object.fromEntries(
      filter(
        map(uriInput, (param: string): string[] => param.split('=')),
        (param: string[]) => ALLOWED_UTMS.indexOf(param[0]) > -1
      )
    );
    setLeadUtms(utms);
  };

  const updateMetaParams = (): void => {
    setLeadUtms((utms: ILeadUtmParams) => ({
      ...utms,
      sourceData: getCookie(META_PIXEL_COOKIE),
      clickData: getCookie(META_CLICK_COOKIE) || createMetaClick(utms.fb_clid),
    }));
  };

  const addMetaPixel = (): void => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = META_TAG_CONTENT;

    document.head.appendChild(script);
  };

  const createMetaClick = (fbClid: string | undefined | null): string =>
    `${META_CLICK_PREFIX}.${new Date().valueOf()}.${fbClid || ''}`;

  useEffect(() => {
    addMetaPixel();
    getUTMParams();
  }, []);

  useEffect(() => {
    eventId && !leadUtms.eventId && setLeadUtms(() => ({ ...leadUtms, eventId }));
  }, [eventId]);

  useEffect(() => {
    if (!leadUtms.clickData || (getCookie(META_PIXEL_COOKIE) && !leadUtms.sourceData)) {
      updateMetaParams();
    }
  }, [leadUtms, document.cookie]);

  return (
    <main>
      <Box className={classes.rootContainer}>
        <Hero {...pageStyles} />

        <Box className={classes.formSection} data-mobile={mobile}>
          <LeadForm
            {...pageStyles}
            leadUtms={leadUtms}
            token={token}
            isLoading={isLoadingContactDetails || isLoadingEventId}
          />
          <Typography className={classes.formSectionTag}>Your care is our dream work</Typography>
          <Typography className={classes.formSectionTag}>
            World-class aesthetic services to help you look and feel your best
          </Typography>
          <ReviewsSection {...pageStyles} />
        </Box>

        <Box className={classes.boutiqueSection}>
          <Typography className={classes.boutiqueText} data-mobile={mobile}>
            Aesthetics boutiques,
          </Typography>
          <Typography className={classes.boutiqueText} data-mobile={mobile}>
            not Corporate MedSpas.
          </Typography>
        </Box>

        <BeforeAfterSection {...pageStyles} />
        <ExpectationsSection {...pageStyles} />

        <Box className={classes.footerSection}>
          {!leadUtms.campaign ? null : <ContactBar {...pageStyles} {...providerContactDetails} />}
          <Typography className={classes.footerTag}>© {moment().format(DATEYEAR)} All Rights Reserved</Typography>
          <Typography className={classes.footerDisclaimer} data-mobile={mobile}>
            {DISCLAIMER_TEXT}
          </Typography>
        </Box>
      </Box>
    </main>
  );
};

export default LeadLandingPage;
