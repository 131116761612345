export const FIRST_NAME = 'First name is a required field';
export const FIRST_NAME_MAX = 'First name must be at most 64 characters';
export const LAST_NAME = 'Last name is a required field';
export const LAST_NAME_MAX = 'Last name must be at most 64 characters';
export const EMAIL = 'Email is a required field';
export const VALID_EMAIL = 'Must be a valid email';
export const VALID_PHONE = 'Must be a valid phone number';
export const PASSWORD = 'Password is a required field';
export const PASSWORD_CONFIRMATION = 'Password confirmation is a required field';
export const PASSWORD_MIN = 'Password length should be at least 4 characters';
export const PASSWORD_NOT_MATCH = 'Password length should be at least 4 characters';
export const ROLE_ID = 'Role is a required field';
export const PHONE = 'Phone is a required field';
export const PHYSICIAN = 'Physician is required field';
export const PRACTITIONER = 'Practitioner is required field';
export const ADDRESS = 'Address is a required field';
export const CITY = 'City is a required field';
export const NAME = 'Name is a required field';
export const NAME_MAX = 'Name must be at most 28 characters';
export const STATE = 'State is a required field';
export const PROVIDER_ID = 'Provider Id is a required field';
export const LATITUDE = 'Latitude is a required field';
export const LONGITUDE = 'Longitude is a required field';
export const CALENDAR_ID = 'Calendar ID is a required field';
export const ACUITY_LOCATION = 'Acuity location is a required field';
export const SQUARE_LOCATION = 'Square Location is a required field';
export const SIGNATURE_IMAGE = 'The signature image is a required field';
export const PROFILE_IMAGE = 'The profile image is a required field';
export const SERVICE_COMMISSION = 'Services commission is a required field';
export const PROFESSIONAL_NAME = 'Professional name is a required field';
export const MEDICAL_MEMBER = 'Medical member type is a required field';
export const MEDICAL_GROUP = 'Medical group is a required field';
export const ROLE = 'Role is a required field';
export const ACUITY_TIMEZONE = 'Time zone is a required field';
export const ACUITY_ADDRESS = 'Suite address is a required field';
export const ACUITY_NAME = 'Business name is a required field';
export const ACUITY_DURATION = 'Duration is a required field';
export const ACUITY_APPOINTMENT_TYPE_NAME = 'Name is a required field';
export const ACUITY_APPOINTMENT_TYPE_DESCRIPTION = 'Description is a required field';
export const ACUITY_AVAILABILITY_START = 'Start date is a required field';
export const ACUITY_AVAILABILITY_END = 'End date is a required field';
export const ACUITY_AVAILABILITY_HOURS = 'Invalid format';
export const FEE_AMOUNT = 'Fee amount must be greater than 0';
export const POSITION = 'Position is a required field';
export const LICENSE = 'Credentials is a required field';
export const SUPPLIER = 'Supplier is a required field';

export const VALID_PHONE_REGEX = /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
