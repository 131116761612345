import React from 'react';
import { LandingPageProps } from 'src/interfaces/ILeadLandingPage';
import { Box, Typography } from '@material-ui/core';
import { ThumbUp, Brush as BrushIcon, Call as CallIcon, Home as HomeIcon } from '@material-ui/icons';

export const IconPanel: React.FC<LandingPageProps> = ({
  iconElement,
  title,
  description,
  order,
  classes,
  mobile,
}: LandingPageProps): React.ReactElement => (
  <Box className={classes?.iconPanel} data-order={order ?? 1} data-mobile={mobile}>
    {iconElement}
    <Typography className={classes?.iconPanelTitle}>{title}</Typography>
    <Typography className={classes?.iconPanelText}>{description}</Typography>
  </Box>
);

export const ExpectationsSection = ({ classes, mobile }: LandingPageProps): React.ReactElement => (
  <Box className={classes?.expectSection}>
    <Typography className={classes?.expectText}>What to expect</Typography>
    <Typography className={[classes?.expectText, classes?.regular].join(' ')}>
      We emphasize education when it comes to your anti-aging treatment plan. Stay informed and feel confident about
      your next steps to getting natural-locking results:
    </Typography>
    <Box className={classes?.iconPanelGroup} data-mobile={mobile}>
      <IconPanel
        order={1}
        iconElement={<CallIcon />}
        title="Book Your Intro Phone Call"
        description="Our helpful staff will answer your questions & schedule your upcoming visit."
        classes={classes}
        mobile={mobile}
      />
      <IconPanel
        order={2}
        iconElement={<HomeIcon />}
        title="Attend Your In-Person Discovery Session"
        description="Get a custom facial analysis from your injector to determine exactly what your skin needs."
        classes={classes}
        mobile={mobile}
      />
    </Box>
    <Box className={classes?.iconPanelGroup} data-mobile={mobile}>
      <IconPanel
        order={3}
        iconElement={<BrushIcon />}
        title="Kickstart Your Results"
        description="Create science-backed plan that's perfect for YOU--and get same-day treatment."
        classes={classes}
        mobile={mobile}
      />
      <IconPanel
        order={4}
        iconElement={<ThumbUp />}
        title="Look and Feel Your Best"
        description="Enjoy immediate, natural and transformative results to look rested & refreshed."
        classes={classes}
        mobile={mobile}
      />
    </Box>
  </Box>
);
