export const ACCOUNT_SETTINGS_STEPS = {
  HEADSHOT: 'headshot',
  PHOTO_ID: 'photoId',
  SIGNATURE: 'signature',
  CREDENTIALS: 'credentials',
  POSITION: 'position',
  SHIPPING_ADDRESS: 'shippingAddress',
  PROVIDER_ENTITY: 'providerEntity',
  MEDICAL_DIRECTOR: 'medicalDirector',
  PAYMENT_METHOD: 'paymentMethod',
  BANK_ACCOUNT: 'bankAccount',
  INVENTORY_AGREEMENT: 'inventoryAgreement',
} as const;

export const ACCOUNT_SETTINGS_LABELS = {
  headshot: 'Headshot',
  photoId: 'Photo ID',
  signature: 'Signature',
  credentials: 'Credentials',
  position: 'Position',
  shippingAddress: 'Commercial Shipping Address',
  providerEntity: 'Provider Entity',
  medicalDirector: 'Medical Director',
  paymentMethod: 'Payment Method',
  bankAccount: 'Bank Account',
  inventoryAgreement: 'Inventory Agreement',
} as const;
