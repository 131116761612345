//
// IMPORTANT: This is the new checkout step 9, using react-query.
//

import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { CardContent, makeStyles, Typography } from '@material-ui/core';

import Checkout from '../../../services/Checkout';
import IServices from '../../../interfaces/IServices';
import { ActiveStepWrapper } from '../ActiveStepWrapper';
import { ADMIN_ROLE, PRACTITIONER_ROLE } from '../../../constants/general.constants';
import { Button } from '../../common/Button';
import { Card } from '../../common/card';
import { CHECKOUT } from '../../../constants/reactQuery.keys';
import { dispatch } from '../../../rematch';
import { IServicesUnits } from '../../../interfaces/serviceVisit.interfaces';
import { SQUARE_STATUS } from '../../../constants/checkout.constants';
import IVariant, { IVariantItem } from '../../../interfaces/IVariants';
import PaymentTag from '../../ServiceVisitCheckout/PaymentTag';

const useStyles = makeStyles(() => ({
  card: {
    margin: '0',
  },
  smallCard: {
    margin: '0',
    height: '160px',
  },
  cardContent: {
    padding: '11px 20px 9px !important',
  },
  smallTitle: {
    margin: '0',
  },
  contentButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '20px',
  },
  button: {
    backgroundColor: '#12574d',
    color: 'white !important',
    width: '170px',
    height: '47px',
    marginRight: 0,
  },
  contentCheckout: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  contentTotal: {
    marginTop: '10px',
    textAlign: 'right',
  },
}));

function formatNumber(value: number | string): string {
  if (typeof value === 'string') {
    return value;
  }
  if (!value) {
    return (0.0).toFixed(2);
  }
  return value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export const CheckoutStep = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const [subtotal, setSubtotal] = useState<number>(0.0);
  const { services, selectedServices } = useSelector(({ newServiceVisit }: any) => newServiceVisit);
  const servicesUnits: IServicesUnits = useSelector(({ newServiceVisit }: any) => newServiceVisit.servicesUnits);
  const creditServicesUnits: IServicesUnits = useSelector(
    ({ newServiceVisit }: any) => newServiceVisit.creditServicesUnits
  );
  const variantsUnits: IServicesUnits = useSelector(({ newServiceVisit }: any) => newServiceVisit.variantsUnits);
  const creditVariantsUnits: IServicesUnits = useSelector(
    ({ newServiceVisit }: any) => newServiceVisit.creditVariantsUnits
  );
  const totalServicesUnits: IServicesUnits = useSelector(
    ({ newServiceVisit }: any) => newServiceVisit.totalServicesUnits
  );
  const totalCreditServicesUnits: IServicesUnits = useSelector(
    ({ newServiceVisit }: any) => newServiceVisit.totalCreditServicesUnits
  );
  const { userType } = useSelector(({ auth }: any) => auth);

  const { serviceVisitId, patientId }: any = useParams();

  const { data } = useQuery([CHECKOUT, serviceVisitId], () => Checkout.getCheckout(serviceVisitId), {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
  });

  const navigateToCheckout = () => {
    history.push(`/patient/${patientId}/serviceVisit/${serviceVisitId}/checkout`);
  };

  useEffect(() => {
    // Effect that calculate the checkout sub total
    if (services.length) {
      let subTotal = 0;

      Object.entries(servicesUnits).forEach(([serviceId, unit]) => {
        const service = services.find((serv: IServices) => serv.id === +serviceId);
        subTotal += service.price * +unit;
        if (service.variants) {
          Object.entries(variantsUnits).forEach(([variantItemId, varianUnit]) => {
            const variant = service.variants.map(({ items }: IVariant) =>
              items.find((variantItem: IVariantItem) => variantItem.id === +variantItemId)
            )[0];
            if (variant && +varianUnit > 0) {
              subTotal += variant.price;
            }
          });
        }
      });

      Object.entries(creditServicesUnits).forEach(([serviceId, unit]) => {
        const service = services.find((serv: IServices) => serv.id === +serviceId);
        subTotal += service.price * +unit;
        if (service.variants) {
          Object.entries(creditVariantsUnits).forEach(([variantItemId, varianUnit]) => {
            const variant = service.variants.map(({ items }: IVariant) =>
              items.find((variantItem: IVariantItem) => variantItem.id === +variantItemId)
            )[0];
            if (variant && +varianUnit > 0) {
              subTotal += variant.price;
            }
          });
        }
      });

      setSubtotal(subTotal);
    }
  }, [
    Object.entries(servicesUnits),
    Object.entries(creditServicesUnits),
    services,
    variantsUnits,
    creditVariantsUnits,
  ]);

  const isDisabled = (): boolean => {
    if (userType !== PRACTITIONER_ROLE && userType !== ADMIN_ROLE) {
      return true;
    }

    const shouldDisable = selectedServices.some((serviceId: any) => {
      const noFuture = +totalCreditServicesUnits[serviceId] === 0;
      const noCurrent = +totalServicesUnits[serviceId] === 0;

      return noFuture && noCurrent;
    });

    return selectedServices && selectedServices.length ? shouldDisable : true;
  };

  const updateSelectedStep = () => {
    dispatch({ type: 'newServiceVisit/updateCurrentStep', payload: 9 });
  };

  return (
    <ActiveStepWrapper step={9} onClick={updateSelectedStep}>
      <Card
        className={
          data?.transactionStatus === 'pending' || typeof data?.transactionStatus === 'undefined'
            ? classes.smallCard
            : classes.card
        }
      >
        <CardContent className={classes.cardContent}>
          <div className={classes.contentCheckout}>
            <Typography>Checkout</Typography>
            {data?.transactionStatus !== 'pending' && typeof data?.transactionStatus !== 'undefined' ? (
              <PaymentTag
                transactionStatus={data.transactionStatus}
                squareErrorCode={data.squareErrorCode || ''}
                processedInSquare={data.processedInSquare}
                toPay={data.toPay}
                amountPaid={data.amountPaid}
                hasPartialRefund={data.hasPartialRefund}
              />
            ) : null}
          </div>
          <div className={classes.contentTotal}>
            {data?.transactionStatus !== SQUARE_STATUS.OK ? (
              <Typography>Subtotal: $ {formatNumber(subtotal)} </Typography>
            ) : (
              <div>
                <Typography>Subtotal: $ {formatNumber(data?.subtotal)} </Typography>
                {/* Future implementation */}
                {/* <Typography color="textSecondary">Taxes: $ {formatNumber(0.00)}</Typography> */}
                {!!data?.discounts && (
                  <Typography color="textSecondary">Discount: -$ {formatNumber(data?.discounts)}</Typography>
                )}
                <Typography>Total: $ {formatNumber(data?.total)}</Typography>
                {!!data?.brilliantValue && (
                  <Typography
                    style={{
                      fontSize: '15px',
                      fontFamily: 'Arial',
                    }}
                    color="textSecondary"
                  >
                    All&#x0113;: -$ {formatNumber(data?.brilliantValue)}
                  </Typography>
                )}
                {!!data?.alleCherryValue && (
                  <Typography
                    style={{
                      fontSize: '15px',
                      fontFamily: 'Arial',
                    }}
                    color="textSecondary"
                  >
                    All&#x0113; Payment with Cherry: -$ {formatNumber(data?.alleCherryValue)}
                  </Typography>
                )}
                {!!data?.aspireValue && (
                  <Typography color="textSecondary">
                    ASPIRE: -${' '}
                    {formatNumber(
                      Array.isArray(data?.aspireValue)
                        ? data?.aspireValue.reduce((acc: number, val: number) => acc + val)
                        : data?.aspireValue
                    )}
                  </Typography>
                )}
                {!!data?.careCreditValue && (
                  <Typography color="textSecondary">CareCredit: -$ {formatNumber(data?.careCreditValue)}</Typography>
                )}
                {data?.referralValue > 0 && (
                  <Typography color="textSecondary">Referral: -$ {formatNumber(data?.referralValue)}</Typography>
                )}
                {!!data?.xperienceValue && (
                  <Typography color="textSecondary">Xperience: -$ {formatNumber(data?.xperienceValue)}</Typography>
                )}

                <Typography>Account credit: $ {formatNumber(data.accountCreditUsed)}</Typography>

                {!!data.amountPaid && data.amountPaid < data.toPay ? (
                  <>
                    <Typography>Total paid: $ {formatNumber(data.amountPaid)}</Typography>
                    <Typography>Amount due: $ {formatNumber(data.toPay - data.amountPaid)}</Typography>
                  </>
                ) : (
                  <Typography>Total paid: $ {formatNumber(data.toPay)}</Typography>
                )}
                {data?.tips > 0 && <Typography>Tips: $ {formatNumber(data?.tips)}</Typography>}
              </div>
            )}
          </div>
          <div className={classes.contentButton}>
            <Button
              dataCy="checkoutBtn"
              className={classes.button}
              title={
                data?.transactionStatus === 'pending' || typeof data?.transactionStatus === 'undefined'
                  ? 'CHARGE'
                  : 'VIEW TRANSACTION'
              }
              onClick={navigateToCheckout}
              // eslint-disable-next-line react/destructuring-assignment
              disabled={isDisabled() || props.disabled}
            />
          </div>
        </CardContent>
      </Card>
    </ActiveStepWrapper>
  );
};
