import React from 'react';
import _ from 'lodash';
import { useQueryClient, useMutation } from 'react-query';
import { Button as BtnMaterial } from '@material-ui/core';
import { useStyles } from '../mainSection.styles';
import { dispatch } from '../../../../rematch';
import StickyCard from '../../../common/StickyCard';
import compile from '../../../../utils/toastMessagesCompiler';
import { ICustomerNote } from '../../../../interfaces/ICustomerNote';
import CustomerNotes from '../../../../services/CustomerNotes';
import { CUSTOMER_NOTES } from '../../../../constants/reactQuery.keys';
import { ACTIVITY_LOGS } from '../../../../hooks/queries/useActivityLogs';

const SUCCESS_MARK_VIEWED_NOTE = compile('generic.success_message', {
  action: 'added',
  element: 'Sticky note',
});
const SUCCESS_REMOVING_NOTE = compile('generic.success_message', {
  action: 'removed',
  element: 'Sticky note',
});
const ERROR_REMOVING_NOTE = compile('generic.error_message', {
  action: 'removing',
  element: 'the sticky note',
});

const ERROR_MARK_VIEWED_NOTE = compile('generic.error_message', {
  action: 'adding',
  element: 'sticky note',
});

const { removeCustomerNote, saveCustomerNote } = CustomerNotes;

type Props = {
  patientId: string;
  customerNotesCollection: ICustomerNote[];
};

const NoViewedStickyNotes: React.FC<Props> = ({ patientId, customerNotesCollection }: Props) => {
  const classes = useStyles();
  const queryClient = useQueryClient();

  const mutationDelete = useMutation(removeCustomerNote);
  const mutationMark = useMutation(saveCustomerNote);

  const stickyNotesDataNoViewed = _.orderBy(customerNotesCollection, ['updatedAt'], ['desc']).filter(
    (sticky) => !sticky.viewed
  );

  const markViewNoteAction = async (noteInformation: ICustomerNote) => {
    const data: ICustomerNote = {
      ...noteInformation,
    };
    try {
      await mutationMark.mutateAsync({ customerNoteInformation: data });
      queryClient.invalidateQueries([CUSTOMER_NOTES, +patientId]);
      queryClient.invalidateQueries([ACTIVITY_LOGS, patientId]);
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: SUCCESS_MARK_VIEWED_NOTE,
          type: 'success',
        },
      });
    } catch (error) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: ERROR_MARK_VIEWED_NOTE,
          type: 'error',
        },
      });
    }
  };

  const deleteCustomerNote = async (noteId: number) => {
    try {
      await mutationDelete.mutateAsync({
        id: noteId,
      });
      queryClient.invalidateQueries([CUSTOMER_NOTES, patientId]);
      queryClient.invalidateQueries([ACTIVITY_LOGS, patientId]);
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: SUCCESS_REMOVING_NOTE,
          type: 'success',
        },
      });
    } catch (error) {
      dispatch({
        type: 'snackbar/enqueueSnackBar',
        payload: {
          message: ERROR_REMOVING_NOTE,
          type: 'error',
        },
      });
    }
  };

  const footerBtn = (noteInformation: ICustomerNote) => (
    <>
      <BtnMaterial onClick={() => deleteCustomerNote(noteInformation.id)} className={classes.btnSecond}>
        Discard
      </BtnMaterial>
      <BtnMaterial onClick={() => markViewNoteAction(noteInformation)} className={classes.btn}>
        Save
      </BtnMaterial>
    </>
  );

  return (
    <div className={classes.containerAbsolute}>
      {stickyNotesDataNoViewed &&
        stickyNotesDataNoViewed.map((stickyNoteNoViewed: ICustomerNote) => (
          <StickyCard
            title={`${stickyNoteNoViewed.createdBy ? stickyNoteNoViewed.createdBy : ''}`}
            backgroundColorNote="#f3f4f5"
            colorText="#000"
            footer={footerBtn(stickyNoteNoViewed)}
          >
            {stickyNoteNoViewed.note}
          </StickyCard>
        ))}
    </div>
  );
};

export default NoViewedStickyNotes;
