export enum AppointmentStatusEnum {
  RESERVED = 'reserved',
  CREATED_FROM_ACUITY = 'created_from_acuity',
  CREATED = 'created',
  CANCELLED = 'cancelled',
  CONFIRMED = 'confirmed',
  RESCHEDULED = 'rescheduled',
  COMPLETED = 'completed',
  PENDING_CONFIRMATION = 'pending_confirmation',
}
