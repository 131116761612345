import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import classnames from 'classnames';
import { CustomerParams } from 'src/interfaces/CustomerParams';
import { useStyles } from '../../PhysicianPatientsList.styles';
import { PHYSICIAN_DASHBOARD_KEY } from '../../../../constants/general.constants';
import { CUSTOMERS_LIST, useCustomPatients } from '../../../../hooks/queries/useCustomPatients';
import { PortraitTable } from '../../../../components/common/TableNew/Table';
import compile from '../../../../utils/toastMessagesCompiler';
import { PatientRow } from './PatientRow';
import { SCHEDULED_LIVE_GFE_TAB, sharedLiveGFEInitialFilters } from '../filters';

export const SCHEDULED_COLUMNS = [
  { id: 'call_type', title: 'Call Type' },
  { id: 'name', title: 'Name' },
  { id: 'mrn', title: 'MRN' },
  { id: 'startTime', title: 'Live GFE Appt. Date' },
  { id: 'nextAppointment', title: 'Appointment Date' },
  { id: 'apptType', title: 'Appt. Type' },
  { id: 'actions', title: '' },
];

export const URGENT_COLUMNS = [
  { id: 'call_type', title: 'Call Type' },
  { id: 'name', title: 'Name' },
  { id: 'mrn', title: 'MRN' },
  { id: 'nextAppointment', title: 'Appointment Date' },
  { id: 'apptType', title: 'Appt. Type' },
  { id: 'actions', title: '' },
];

interface LiveGFEListProps {
  defaultFilters: Record<string, any>;
  currentTabFilters: Record<string, any>;
  doSortBy: (column: string, direction: 'desc' | 'asc' | undefined) => void;
  tab: string;
}

export const LiveGFEList: React.FC<LiveGFEListProps> = ({ defaultFilters, currentTabFilters, doSortBy, tab }) => {
  const classes = useStyles();
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries([CUSTOMERS_LIST]);
  }, []);

  const filters: CustomerParams = Object.keys(sharedLiveGFEInitialFilters).reduce((acc, key) => {
    if (key in defaultFilters || key in currentTabFilters) {
      acc[key] = defaultFilters[key] || currentTabFilters[key];
    }
    return acc;
  }, {});

  const { results, isLoading, isError, fetchNextPage, hasNextPage, isFetching } = useCustomPatients(
    PHYSICIAN_DASHBOARD_KEY,
    filters
  );
  const patients = results?.pages.map(({ customers = [] }) => customers).flat() || [];

  return (
    <PortraitTable
      columns={tab === SCHEDULED_LIVE_GFE_TAB ? SCHEDULED_COLUMNS : URGENT_COLUMNS}
      sortBy={filters.sortBy}
      sortDirection={filters.sortDirection}
      onSortChange={doSortBy}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
      data={patients}
      rowComponent={(row) => <PatientRow patient={row} showGfeDate={tab === SCHEDULED_LIVE_GFE_TAB} />}
      errorMessage={compile('generic.error_message', { action: 'fetching', element: 'patients' })}
      infiScroll
      isLoading={isLoading}
      isFetching={isFetching}
      isError={isError}
      tableStyles={classnames(classes.tableGeneral, classes.infiScrollContainer, classes.patientListContainer)}
    />
  );
};
