import React, { useMemo, ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Collapse } from '@material-ui/core';
import { ClassNameMap } from 'src/types/Dom';
import { MenuOption } from 'src/constants/menu.constants';
import RecursiveMenuSubOption from './RecursiveMenuSubOption';
import RecursiveMenu, { Props as RecursiveMenuProps } from '.';

export type Props = Omit<RecursiveMenuProps, 'optionsMenu'> & {
  id: number;
  option: MenuOption;
};

export const RecursiveMenuItem = ({
  id,
  option,
  classes,
  permissions,
  setCollapsed,
  collapsed,
  subMenus,
  setSubMenus,
  list = [],
}: Props & ClassNameMap): ReactElement => {
  const history = useHistory();
  const subOptions = option.options?.map((subOption: MenuOption) => subOption.url) ?? [];
  const isActiveSubOption =
    !!subOptions.length &&
    (subOptions.includes(history.location.pathname) || subMenus.includes(`${id}-${option.name}`));
  const isActive = isActiveSubOption || (!!option.url && history.location.pathname.includes(option.url));

  useEffect(() => {
    // If user leaves sub menu by navigating to a different page, reset sub menus.
    if (isActive && subMenus.length > 1 && !subOptions.includes(history.location.pathname)) {
      setSubMenus([]);
    }
  }, [history.location.pathname, isActive, subMenus]);

  const menuItem = useMemo(
    () => (
      <RecursiveMenuSubOption
        id={id}
        subMenus={subMenus}
        setSubMenus={setSubMenus}
        option={option}
        list={list}
        classes={classes}
        setCollapsed={setCollapsed}
        collapsed={collapsed}
        isActive={isActive}
      />
    ),
    [id, collapsed, list, option, isActive, classes]
  );
  const childItems = useMemo(
    () =>
      !option.options ? undefined : (
        <RecursiveMenu
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          permissions={permissions}
          subMenus={subMenus}
          setSubMenus={setSubMenus}
          classes={classes}
          optionsMenu={option.options ?? []}
          list={[...list, `${id}-${option.name}`]}
        />
      ),
    [id, list, permissions, collapsed, option, isActive, classes]
  );

  return (
    <>
      {menuItem}
      {!!childItems && (
        <Collapse in={isActive && !collapsed} timeout="auto" unmountOnExit className={classes?.subContainer}>
          {childItems}
        </Collapse>
      )}
    </>
  );
};

export default RecursiveMenuItem;
