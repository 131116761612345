import React from 'react';

const DocumentIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="31" height="31" rx="5.5" stroke="#D9D9D9" />
    <path
      // eslint-disable-next-line max-len
      d="M21 13.3333V21C21 21.5523 20.5523 22 20 22H12C11.4477 22 11 21.5523 11 21V11C11 10.4477 11.4477 10 12 10L17.875 10M21 13.3333L17.875 10M21 13.3333H17.875V10"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DocumentIcon;
