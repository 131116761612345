/* eslint-disable no-nested-ternary */

import { Box, CircularProgress, debounce, FormHelperText, Grid } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import DragAndDropComponent from 'src/components/DragAndDrop';
import { STEP_COMPLETED, STEP_EMPTY, STEP_LOADING } from 'src/components/StepsTracker';
import { EHRButton } from 'src/components/ui/v1';
import { EHRSignatureCanvas } from 'src/components/ui/v1/EHRSignatureCanvas';
import { useCreateSignature } from 'src/hooks/queries/useAccountSettings';
import { useUpdateMedspaAdminOnboardingProgress } from 'src/hooks/queries/useMedspaAdmins';
import { FileDetails, StepStatus } from 'src/interfaces/onboarding.interface';
import { ONBOARDING_STEPS } from 'src/pages/MedspaAdmin/Onboarding/constants';
import { RadioButtons } from 'src/ui/RadioButtons/RadioButtons';

interface PhotoIDProps {
  stepId: number;
  onStepUpdate: (stepId: number, status: StepStatus) => void;
  signature?: FileDetails;
  progressPercentage?: number;
  disabled?: boolean;
}

const Signature = ({ stepId, onStepUpdate, signature, progressPercentage, disabled = false }: PhotoIDProps) => {
  const [selection, setSelection] = useState(0);
  const [fileErrorMessage, setFileErrorMessage] = useState<string>('');
  const [signatureFile, setSignatureFile] = useState<string | null>(null);

  const prevState = useRef(signature?.fileName);

  const { mutateAsync: createSignature, isError, isLoading, isSuccess } = useCreateSignature();
  const { mutateAsync: updateProgress } = useUpdateMedspaAdminOnboardingProgress();

  const updateNote = useCallback(debounce(createSignature, 2500), []);

  const onClearSignature = () => {
    setSignatureFile('');
  };

  const onSign = async (sign: string) => {
    updateNote({ signature: sign });
  };

  const handleSelection = (index: number) => {
    if (selection !== index) {
      setSelection(index);
      onStepUpdate(stepId, STEP_EMPTY);
    }
  };

  const handleFileInput = (fileData: File[]) => {
    const file = fileData?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        createSignature({
          signature: (reader.result || '').toString(),
        });
      };
      reader.onerror = () => {
        setSignatureFile('');
        setFileErrorMessage('There was an error. Please try again.');
      };
      reader.readAsDataURL(file);
    } else {
      setSignatureFile('');
      setFileErrorMessage('');
    }
  };

  useEffect(() => {
    if (signatureFile && !fileErrorMessage) {
      onStepUpdate(stepId, STEP_COMPLETED);
    } else {
      onStepUpdate(stepId, STEP_EMPTY);
    }
  }, [signatureFile, fileErrorMessage]);

  useEffect(() => {
    setSignatureFile(signature?.fileName ? signature?.url : '');
    onStepUpdate(stepId, signature?.fileName ? STEP_COMPLETED : STEP_EMPTY);
  }, [signature?.url, isSuccess]);

  useEffect(() => {
    if (isLoading) {
      onStepUpdate(stepId, STEP_LOADING);
    } else if (isError) {
      onStepUpdate(stepId, STEP_EMPTY);
    }
  }, [isLoading, isError, signatureFile]);

  useEffect(() => {
    if (prevState.current === null && signature?.fileName) {
      updateProgress({
        stepName: ONBOARDING_STEPS.ACCOUNT_SETTINGS_NAME,
        percentage: progressPercentage as number,
      });
    }

    prevState.current = signature?.fileName;
  }, [signature?.fileName]);

  const uploadSignatureElement = (
    <Box>
      <DragAndDropComponent
        acceptedFileType="image/png,image/jpg,image/jpeg"
        onFileChange={handleFileInput}
        error={!!fileErrorMessage}
        hideRemoveFile={disabled}
      />
    </Box>
  );

  return (
    <Grid container>
      {isLoading ? (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress size={50} style={{ marginRight: '10px' }} />
          <p>Loading...</p>
        </div>
      ) : signatureFile ? (
        <Box width="100%" display="flex" justifyContent="center" flexDirection="column" gridGap="10px">
          <img alt="Signature file" src={signatureFile} />
          {!disabled && (
            <EHRButton
              fullWidth
              onClick={() => {
                setSignatureFile('');
                onStepUpdate(stepId, STEP_EMPTY);
                handleSelection(0);
              }}
              dataCy=""
              text="Create a new signature"
              color="primary"
              variant="outlined"
            />
          )}
        </Box>
      ) : (
        <>
          <Grid item xs={12}>
            <Box marginBottom="24px">
              <RadioButtons
                selectedOption="0"
                onChange={(value: string) => handleSelection(parseInt(value || '0', 10))}
                options={[
                  { value: '0', label: 'Draw signature' },
                  { value: '1', label: 'Upload signature' },
                ]}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            {selection === 0 ? (
              <EHRSignatureCanvas onSign={onSign} onClear={onClearSignature} />
            ) : (
              uploadSignatureElement
            )}
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <FormHelperText error={isError}>
          {isError && 'There was an error submitting signature. Please try again.'}
        </FormHelperText>
      </Grid>
    </Grid>
  );
};

export default Signature;
