/* eslint-disable max-len */
export const INPUT_TOTAL = 4;
export const MAX_REVIEW_STARS = 5;
export const FULLSCREEN = '(max-width:900px)';
export const XL_SCREEN = '(min-width:1801px)';
export const PHONE_NUM_LENGTH = 10;
export const FORMATTED_PHONE_LENGTH = 14;
export const PHONE_REGEX = /^(\d{3})(\d{1,3})?(\d{0,4})?$/;
export const PHONE_FORMAT = '($1) $2-$3';
export const DATEMONTH = 'MMMM';
export const DATEYEAR = 'YYYY';
export const DIGIT_REGEX = /\d+/g;
export const PHONE_TYPE = 'tel';
export const PHONE = 'phone';
export const EMAIL = 'email';
export const FIRST_NAME = 'firstName';
export const LAST_NAME = 'lastName';
export const BUSINESS_HOURS = 'Mon - Sat: 8.30am To 9.00pm';
export const SECURITY_NOTE = '100% Secure. We never share your information.';
export const DISCLAIMER_TEXT =
  'A fully-licensed medical aesthetics services provider that providers clinical services in a professional settings in accordance with all medical and nursing boards, medspa industry standards, and applicable laws and regulations.';
export const ALLOWED_UTMS = ['campaign', 'source', 'provider'];
export const GOOGLE = 'google';
export const YELP = 'yelp';
export const BEFORE_PHOTOS = [
  'https://assets-global.website-files.com/62ed9817d640656c8aa5d2d0/6328c85e267a21107f489f99_Jennifer%20-%20Before.jpg',
  'https://uploads-ssl.webflow.com/62ed9817d640656c8aa5d2d0/6307ceb1a385565d394d9560_Wrinkle%20Relaxers%20-%20Xeomin%20-%20Before.jpg',
  'https://uploads-ssl.webflow.com/62ed9817d640656c8aa5d2d0/6306ac3492cca56d409ddf79_625f6329058a74154d62bf08_xsmall_01_radiesse_before_007421f2fd.jpeg',
  'https://uploads-ssl.webflow.com/62ed9817d640656c8aa5d2d0/6306ac7590a2240ad0b0669c_6260df6b7389bbbe60e50095_xsmall_01_sculptra_before_24a2aa5596.jpeg',
  'https://uploads-ssl.webflow.com/62ed9817d640656c8aa5d2d0/6306ac6a1b179091c951357c_625a681215ab7f6825e6a19c_3%20-%20Before.jpeg',
];
export const AFTER_PHOTOS = [
  'https://assets-global.website-files.com/62ed9817d640656c8aa5d2d0/6328c85e281d5ec56b7b829e_Jennifer%20-%20After.jpg',
  'https://uploads-ssl.webflow.com/62ed9817d640656c8aa5d2d0/6307ceb0a3855621264d9557_Wrinkle%20Relaxers%20-%20Xeomin%20-%20After.jpg',
  'https://uploads-ssl.webflow.com/62ed9817d640656c8aa5d2d0/6306ac3492cca526b89ddf7b_625f63295890586261359044_xsmall_01_radiesse_after_276b0f548c.jpeg',
  'https://uploads-ssl.webflow.com/62ed9817d640656c8aa5d2d0/6306ac7590a2243195b0669a_6260df6b039bd1de09bda01d_xsmall_01_sculptra_after_16f75b1e22%20(1).jpeg',
  'https://uploads-ssl.webflow.com/62ed9817d640656c8aa5d2d0/6306ac6a1b179028d151357e_625a681215ab7f7125e6a19d_3%20-%20After.jpeg',
];
export const CUSTOM_ICONS = [
  'https://uploads-ssl.webflow.com/62ed9817d640656c8aa5d2d0/65349c8865a382945f306ee8_google.png',
  'https://uploads-ssl.webflow.com/62ed9817d640656c8aa5d2d0/65349c883268205b9f0acfb9_yelp.png',
];
export const HERO_IMAGE =
  'https://assets-global.website-files.com/62ed9817d640656c8aa5d2d0/62fecc4cda7ab13d8942b059_Home-Hero-Wb-Desktop.jpg';
export const LOGO_IMAGE =
  'https://uploads-ssl.webflow.com/62ed9817d640656c8aa5d2d0/6374282dacd57a65708c2f5d_Portrait%20Logo%20-%20Black.svg';

export const META_PIXEL_COOKIE = '_fbp';
export const META_CLICK_COOKIE = '_fbc';
export const META_CLICK_PREFIX = 'fb.1';
export const META_PIXEL_TRACK = 'track';
export const META_PIXEL_LEAD = 'Lead';
export const META_TAG_CONTENT =
  "!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '1760436980966411');fbq('track', 'PageView');";

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const FORMATTED_PHONE_REGEX = /^\(\d{3}\)\s\d{3}-\d{4}$/;
