import React, { useRef, useState } from 'react';
import { Card, CardContent, Modal } from '@material-ui/core';
import { Launch } from '@material-ui/icons';
import { ReactComponent as UploadIcon } from '../../assets/images/upload.svg';
import { Button as ModalButton } from './Button';
import { IconButton } from './IconButton';
import { useStyles } from './UploadImage.styles';

const UploadImageV2 = ({
  imageUrl,
  field,
  onChange,
  canViewOriginal = false,
  width,
}: {
  field: string;
  imageUrl: string | undefined;
  onChange: (file: string | null) => void;
  canViewOriginal: boolean;
  width?: number;
}) => {
  const classes = useStyles();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [changed, setChanged] = useState(false);
  const fileRef = useRef<HTMLInputElement | null>(null);

  const handleUploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files) {
      const file = files[0];
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          onChange(String(reader.result));
          setChanged(true);
        };
      }
    }
  };

  const viewOriginal = () => {
    window.open(imageUrl, '_blank');
  };

  const clickOnFileUpload = () => {
    fileRef.current?.click();
  };

  return (
    <>
      {imageUrl ? (
        <div className={classes.photoContainer} style={width ? { width } : {}}>
          <div onClick={() => setIsOpenModal(true)} aria-hidden>
            <img src={imageUrl} alt={field} className={classes.image} />
          </div>
          <div className={classes.changePhotoContainer}>
            <button className={classes.changePhotoButton} type="button" onClick={clickOnFileUpload}>
              Change
            </button>
          </div>
        </div>
      ) : (
        <UploadIcon onClick={clickOnFileUpload} className={classes.uploadIcon} />
      )}
      <input
        // @ts-ignore
        ref={fileRef}
        name={field}
        accept="image/png, image/jpg, image/jpeg"
        id="uploadPhoto"
        type="file"
        hidden
        onChange={handleUploadImage}
      />
      <Modal open={isOpenModal} onClose={() => setIsOpenModal(false)} className={classes.modal}>
        <Card style={{ width: 'fit-content' }}>
          <CardContent className={classes.cardContentPhoto}>
            <img className={classes.photo} src={imageUrl} alt="item" />
          </CardContent>
          <CardContent className={classes.cardContentButtons}>
            <div className={classes.buttons}>
              <ModalButton title="Close" className={classes.closeButton} onClick={() => setIsOpenModal(false)} />
              {canViewOriginal && !changed && (
                <IconButton
                  title="View Original"
                  onClick={viewOriginal}
                  className={classes.ViewOriginalButton}
                  Icon={<Launch />}
                />
              )}
            </div>
          </CardContent>
        </Card>
      </Modal>
    </>
  );
};

export default UploadImageV2;
