import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles, AppBar, Typography } from '@material-ui/core';
import { useQuery } from 'react-query';
import { connect, useSelector } from 'react-redux';
import TabItem from 'src/components/common/TabItem';
import TableTodo from './TableTodo';
import { dispatch } from '../../../../rematch';
import axiosInstance from '../../../../utils/axios';
import { hasAccessTo } from '../../../../utils/auth.utils';
import { READ_TODO_LIST } from '../../../../constants/actions.constants';

const useStyle = makeStyles(() => ({
  todoTabClass: {
    backgroundColor: 'white',
    color: '#949494',
    width: '200px',
    paddingLeft: '20px',
    paddingBottom: '10px',
    fontFamily: 'Messina Sans SemiBold',
    fontSize: '18px',
  },
  tabUnique: {
    backgroundColor: 'white',
    borderTop: 'solid 1px fff',
  },
}));

const mapState = (state: any) => ({
  isLoading: state.loading.effects.todo.loadTodos,
  todos: state.todo.todos,
  services: state.common.services,
});

const mapDispatch = ({ todo: { loadTodos } }: any) => ({
  loadTodos: () => loadTodos(),
});

const tabsObj = new Map([
  ['all', 0],
  ['unmatched-invoices', 1],
  ['charting', 2],
  ['threads', 3],
  ['uncounted-inventory', 4],
]);

const TodoTab = (props: any) => {
  const { services } = props;
  const location = useLocation();
  const [value, setValue] = React.useState(0);
  const permissions = useSelector(({ auth }: any) => auth.permissions);

  const classes = useStyle();

  useEffect(() => {
    const urlTab = location.pathname.split('/').slice(-1)[0];
    setValue(tabsObj.get(urlTab) || 0);
  }, [location.pathname]);

  useEffect(() => {
    dispatch.patient.resetPatientProfile();
    dispatch.common.fetchServices();
  }, []);

  const { isLoading, data: todos = [] } = useQuery({
    queryKey: ['todos'],
    queryFn: async () => {
      const {
        data: { orders },
      } = await axiosInstance.get(`/square/todo-list`);
      return orders;
    },
  });

  const charting = todos?.filter((todo: any) => todo.matched === null);

  return (
    <>
      <AppBar position="static" />
      <TabItem value={value} index={0} className={classes.tabUnique}>
        <Typography variant="h6" style={{ width: '250px' }} className={classes.todoTabClass} data-cy="todoTitle">
          {hasAccessTo(READ_TODO_LIST, permissions) ? 'All To-dos' : 'Opened Service Visits'}
        </Typography>
        <TableTodo data={charting} isLoading={isLoading} services={services} />
      </TabItem>
    </>
  );
};

export default connect(mapState, mapDispatch)(TodoTab);
