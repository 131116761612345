import React, { useState, useRef, useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';
import Page from 'src/components/common/Page';
import { EHRButton, EHRTypography } from 'src/components/ui/v1';
import { ArrowBackIos as ArrowBack } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import StepsTracker, { STEP_COMPLETED } from 'src/components/StepsTracker';
import { Step, StepStatus } from 'src/interfaces/onboarding.interface';
import FormCard from 'src/components/common/card/FormCard';
import {
  ADDRESS_FORM_DESCRIPTION,
  BANK_ACCOUNT_DESCRIPTION,
  DOCUMENT_SIGNATURE_FORM_DESCRIPTION,
  INVENTORY_AGREEMENT_DESCRIPTION,
  PAYMENT_METHOD_FORM_DESCRIPTION,
  PHOTO_ID_FORM_DESCRIPTION,
  POSITION_DESCRIPTION,
  PROVIDER_ENTITY_FORM_DESCRIPTION,
} from 'src/constants/onboarding.constants';
import { dispatch, RootState } from 'src/rematch';
import { useAccountSettings } from 'src/hooks/queries/useAccountSettings';
import { useSelector } from 'react-redux';
import { useUpdateMedspaAdminOnboardingProgress } from 'src/hooks/queries/useMedspaAdmins';
import { ONBOARDING_STEPS } from 'src/pages/MedspaAdmin/Onboarding/constants';
import { ONBOARDING_BASE_PATH } from 'src/routes/medspaOnboardingRoutes';
import useGetCards from 'src/hooks/queries/medspaAdmins/useMedspaPaymentMethods';
import useMedspaAdminOnboarding from 'src/hooks/useMedspaAdminOnboarding';
import {
  Headshot,
  Licenses,
  MedicalDirector,
  PhotoID,
  Positions,
  ShippingAddress,
  Signature,
  ProviderEntity,
  PaymentMethod,
} from './StepsCards';
import InventoryOrderingAgreement from './StepsCards/InventoryOrderingAgreement';
import BankAccount from './StepsCards/BankAccount';
import { ACCOUNT_SETTINGS_STEPS } from './constants';

const TitleComponent = ({ onBackClick }: { onBackClick: () => void }) => (
  <Grid container alignItems="center">
    <Box component={ArrowBack} data-cy="pageBackArrow" onClick={onBackClick} style={{ cursor: 'pointer' }} />
    <EHRTypography variant="h6" dataCy="medspa-admin-onboarding-account-settings">
      Account settings
    </EHRTypography>
  </Grid>
);

const AccountSettings = () => {
  const { linkedAccounts, userGroupId } = useSelector(({ auth }: RootState) => auth);
  const { data: accountSettings, isLoading: isLoadingAccountSettings } = useAccountSettings();
  const { data: creditCards = [], isLoading: isLoadingCcs, refetch } = useGetCards(userGroupId ?? 0);

  const { mutateAsync: updateProgress } = useUpdateMedspaAdminOnboardingProgress();

  const { onboardingCompleted, isLoadingMedspaAdminInfo } = useMedspaAdminOnboarding();

  const {
    profileImage,
    governmentIssuePhotoId,
    signatureImage,
    licenses,
    position,
    commercialShippingAddress,
    medicalDirector,
    entityName,
    hasAcknowledgedInventoryDisclaimer,
  } = accountSettings || {};

  const history = useHistory();
  const headshotRef = useRef<HTMLDivElement>(null);
  const photoIDRef = useRef<HTMLDivElement>(null);
  const signatureRef = useRef<HTMLDivElement>(null);
  const licensesRef = useRef<HTMLDivElement>(null);
  const positionRef = useRef<HTMLDivElement>(null);
  const shippingAddressRef = useRef<HTMLDivElement>(null);
  const providerEntityRef = useRef<HTMLDivElement>(null);
  const medicalDirectorRef = useRef<HTMLDivElement>(null);
  const paymentMethodRef = useRef<HTMLDivElement>(null);
  const inventoryAgreementRef = useRef<HTMLDivElement>(null);
  const bankAccountRef = useRef<HTMLDivElement>(null);

  const steps = [
    {
      name: ACCOUNT_SETTINGS_STEPS.HEADSHOT,
      required: true,
      ref: headshotRef,
      headline: 'Upload your headshot',
      description: '',
    },
    {
      name: ACCOUNT_SETTINGS_STEPS.PHOTO_ID,
      required: true,
      ref: photoIDRef,
      headline: 'Upload a government-issued photo ID',
      description: PHOTO_ID_FORM_DESCRIPTION,
    },
    {
      name: ACCOUNT_SETTINGS_STEPS.SIGNATURE,
      required: true,
      ref: signatureRef,
      headline: 'Add your signature',
      description: DOCUMENT_SIGNATURE_FORM_DESCRIPTION,
      isProviderSpecific: true,
    },
    {
      name: ACCOUNT_SETTINGS_STEPS.CREDENTIALS,
      required: true,
      ref: licensesRef,
      headline: 'Credentials',
      description: '',
      isProviderSpecific: true,
    },
    {
      name: ACCOUNT_SETTINGS_STEPS.POSITION,
      required: true,
      ref: positionRef,
      headline: 'Position',
      description: POSITION_DESCRIPTION,
      isProviderSpecific: true,
    },
    {
      name: ACCOUNT_SETTINGS_STEPS.SHIPPING_ADDRESS,
      required: true,
      ref: shippingAddressRef,
      headline: 'Commercial Shipping Address',
      description: ADDRESS_FORM_DESCRIPTION,
    },
    {
      name: ACCOUNT_SETTINGS_STEPS.PROVIDER_ENTITY,
      required: false,
      ref: providerEntityRef,
      headline: 'Business Name (DBA)',
      description: PROVIDER_ENTITY_FORM_DESCRIPTION,
    },
    {
      name: ACCOUNT_SETTINGS_STEPS.MEDICAL_DIRECTOR,
      required: true,
      ref: medicalDirectorRef,
      headline: 'Medical Director',
      description: '',
    },
    {
      name: ACCOUNT_SETTINGS_STEPS.PAYMENT_METHOD,
      required: true,
      ref: paymentMethodRef,
      headline: 'Add Card on File',
      description: PAYMENT_METHOD_FORM_DESCRIPTION,
    },
    {
      name: ACCOUNT_SETTINGS_STEPS.BANK_ACCOUNT,
      required: true,
      ref: bankAccountRef,
      headline: 'Bank Account',
      description: BANK_ACCOUNT_DESCRIPTION,
    },
    {
      name: ACCOUNT_SETTINGS_STEPS.INVENTORY_AGREEMENT,
      required: true,
      ref: inventoryAgreementRef,
      headline: 'Inventory Ordering',
      description: INVENTORY_AGREEMENT_DESCRIPTION,
    },
  ];

  const [stepsProgress, setStepsProgress] = useState<Step[]>(
    steps
      .map((step, index) => ({ ...step, id: index, status: 'empty' as StepStatus }))
      .filter((step) => (linkedAccounts?.length ? step : !step?.isProviderSpecific))
  );

  const isDoneButtonEnabled = stepsProgress.every((step) =>
    step.required ? step.status !== 'empty' && step.status !== 'loading' : true
  );

  const handleStepClick = (step: Step) => {
    if (step.ref.current) {
      step.ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const onStepUpdateStatus = (stepId: number, stepStatus: StepStatus) => {
    setStepsProgress((prevState) =>
      prevState.map((item) => {
        if (item.id === stepId) {
          return { ...item, status: stepStatus };
        }
        return item;
      })
    );
  };

  const handleSubmit = async () => {
    await updateProgress({
      stepName: ONBOARDING_STEPS.ACCOUNT_SETTINGS_NAME,
      percentage: 100,
      override: true,
    });

    dispatch.auth.currentUser();

    if (history.length > 1) {
      history.goBack();
    } else {
      history.push(ONBOARDING_BASE_PATH);
    }
  };

  const renderStepContent = (stepName: string, stepId: number) => {
    const progressPercent = 100 / (stepsProgress.length || 1);

    const currentStep = stepsProgress.find((step) => step.id === stepId);
    const isStepCompleted = onboardingCompleted && currentStep?.status === STEP_COMPLETED;

    const isStepLoading = isLoadingAccountSettings || isLoadingMedspaAdminInfo;
    const isStepDisabled = isStepLoading || isStepCompleted;

    const componentToRender = {
      headshot: () => (
        <Headshot
          stepId={stepId}
          onStepUpdate={onStepUpdateStatus}
          headshot={profileImage}
          progressPercentage={progressPercent}
          disabled={isStepDisabled}
        />
      ),
      photoId: () => (
        <PhotoID
          stepId={stepId}
          onStepUpdate={onStepUpdateStatus}
          photoId={governmentIssuePhotoId}
          progressPercentage={progressPercent}
          disabled={isStepDisabled}
        />
      ),
      signature: () => (
        <Signature
          stepId={stepId}
          onStepUpdate={onStepUpdateStatus}
          signature={signatureImage}
          progressPercentage={progressPercent}
          disabled={isStepDisabled}
        />
      ),
      credentials: () => (
        <Licenses
          stepId={stepId}
          onStepUpdate={onStepUpdateStatus}
          license={licenses}
          progressPercentage={progressPercent}
          disabled={isStepDisabled}
        />
      ),
      position: () => (
        <Positions
          stepId={stepId}
          onStepUpdate={onStepUpdateStatus}
          position={position}
          progressPercentage={progressPercent}
          disabled={isStepDisabled}
        />
      ),
      shippingAddress: () => (
        <ShippingAddress
          stepId={stepId}
          onStepUpdate={onStepUpdateStatus}
          commercialShippingAddress={commercialShippingAddress}
          progressPercentage={progressPercent}
        />
      ),
      providerEntity: () => (
        <ProviderEntity
          stepId={stepId}
          onStepUpdate={onStepUpdateStatus}
          entityName={entityName}
          progressPercentage={progressPercent}
          disabled={isStepDisabled}
        />
      ),
      medicalDirector: () => (
        <MedicalDirector
          stepId={stepId}
          onStepUpdate={onStepUpdateStatus}
          medicalDirectorInfo={medicalDirector}
          progressPercentage={progressPercent}
        />
      ),
      paymentMethod: () => (
        <PaymentMethod
          stepId={stepId}
          onStepUpdate={onStepUpdateStatus}
          progressPercentage={progressPercent}
          isLoadingCcs={isLoadingCcs}
          creditCards={creditCards}
          refetchData={() => refetch()}
        />
      ),
      bankAccount: () => (
        <BankAccount
          stepId={stepId}
          onStepUpdate={onStepUpdateStatus}
          progressPercentage={progressPercent}
          disabled={isStepDisabled}
        />
      ),
      inventoryAgreement: () => (
        <InventoryOrderingAgreement
          stepId={stepId}
          onStepUpdate={onStepUpdateStatus}
          progressPercentage={progressPercent}
          hasAcknowledgedInventoryDisclaimer={hasAcknowledgedInventoryDisclaimer ?? false}
        />
      ),
    };

    return componentToRender[stepName as keyof typeof componentToRender]() || null;
  };

  useEffect(() => {
    const field = (history.location.state && (history.location.state as any)?.field) || undefined;
    if (field) {
      setTimeout(() => {
        steps.find((step) => step.name === field)?.ref.current?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  }, [history.location.state]);

  return (
    <>
      <Page titleComponent={<TitleComponent onBackClick={() => history.goBack()} />}>
        <Grid container>
          <Grid item sm={12} md={3} lg={4}>
            <EHRTypography style={{ margin: '24px' }} variant="body2" dataCy="account-settings-headline">
              Please provide the requested information in order to proceed...
            </EHRTypography>
            <StepsTracker
              steps={stepsProgress}
              onStepClick={handleStepClick}
              isLoading={isLoadingAccountSettings || isLoadingCcs || isLoadingMedspaAdminInfo}
            />
          </Grid>

          <Grid item sm={12} md={8} lg={6} style={{ marginBottom: '65px' }}>
            {stepsProgress.map(({ name, ref, id, headline, required, status, description }) => (
              <div ref={ref} key={`onboarding-step-${id}`} style={{ margin: '24px 0' }}>
                <FormCard required={required} headline={headline} status={status} description={description}>
                  {renderStepContent(name || '', id)}
                </FormCard>
              </div>
            ))}
          </Grid>
          <Grid item sm={12} md={1} lg={2} />
        </Grid>

        <Box
          style={{
            padding: '12px 31px',
            backgroundColor: '#E8EEED',
            color: 'black',
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: `100%`,
            height: '64px',
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
          }}
        >
          <EHRButton
            dataCy="btn-add-modal-edit-medspa"
            onClick={() => handleSubmit()}
            disabled={!isDoneButtonEnabled}
            color="primary"
            text="Done"
          />
        </Box>
      </Page>
    </>
  );
};

export default AccountSettings;
