import { makeStyles } from '@material-ui/core/styles';

export const usePatientMedspaStyles = makeStyles((theme) => ({
  patientHeaderFormControlContainer: {
    [theme.breakpoints.down('xs')]: {
      flex: '100%',
    },
  },
  patientHeaderActionButtonsContainer: {
    [theme.breakpoints.down('xs')]: {
      flex: '100%',
      justifyContent: 'space-between',
    },
  },
  marginTop: {
    marginTop: '10px',
  },
  title: {
    fontSize: '28px',
    fontFamily: 'Roboto',
    fontWeight: 600,
    color: '#000000',
  },
  infiScrollContainer: {
    width: '100%',
    marginTop: '35px',
  },
  checkbox: {
    color: '#12574d',
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  formControl: {
    margin: theme.spacing(2, 0),
    minWidth: 120,
    width: '100%',
  },
  halfWidthField: {
    width: `calc(50% - ${theme.spacing(1)}px)`,
  },
  centerModal: {
    position: 'fixed',
    height: '100vh',
    padding: theme.spacing(2),
    overflowY: 'auto',
  },
  centerModalContent: {
    position: 'fixed',
    right: '25%',
    top: '25%',
    height: '502px',
    borderRadius: '8px',
    backgroundColor: '#fff',
    width: '675px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '15px',
    paddingLeft: '25px',
    paddingRight: '25px',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  infoBox: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    width: '600px',
  },
  textBox: {
    marginLeft: '10px',
    marginRight: '10px',
    width: '600px',
  },
  subtitle: {
    fontSize: '15px',
  },
  bodyText: {
    fontSize: '13px',
    color: 'darkGrey',
  },
  associateBox: {
    padding: theme.spacing(2),
    paddingLeft: '10px',
    margin: theme.spacing(1, 0),
    marginBottom: '15px',
    backgroundColor: '#f0f0f0',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '90px',
  },
  providerSelect: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
  },
  buttonContainer: {
    position: 'absolute',
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
  },
  button: {
    height: '35px',
    marginRight: '15px',
    marginBottom: '10px',
  },
  csvFieldList: {
    color: 'darkGrey',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(1),
    listStyle: 'none',
    padding: 0,
  },
  uploadStatusBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
  },
}));
