import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import { makeCSV, CSVRow } from '../../utils/makeCSV';

export const CSV_MIME_TYPE = 'text/csv';

export interface CSVLinkProps {
  getCSVRows: () => Promise<unknown[]>;
  csvName: string;
  csvCols?: string[];
  className?: string;
  offset?: number;
  customButton?: React.ReactElement;
}

export const CSVLink: React.FC<CSVLinkProps> = ({
  getCSVRows,
  csvName,
  csvCols,
  className,
  offset,
  customButton,
}: CSVLinkProps): React.ReactElement => {
  const sendCSV = async (text: string): Promise<void> => {
    const csvFile = new Blob([text], { type: CSV_MIME_TYPE });
    const csvLink: HTMLAnchorElement = document.createElement('a');

    csvLink.href = URL.createObjectURL(csvFile);
    csvLink.download = `${csvName}.csv`;
    csvLink.setAttribute('data-testid', 'csv download anchor');

    document.body.appendChild(csvLink);
    csvLink.click();
  };

  const handleClick = async (event: React.MouseEvent<HTMLElement>): Promise<void> => {
    event.preventDefault();

    const csvRows = (await getCSVRows()) as CSVRow[];
    const headers = csvCols || Object.keys(csvRows[0]);
    const rowText = makeCSV(csvRows, offset);

    await sendCSV(`${headers.join(',')}\n${rowText}`);
  };

  return (
    <Box className={className || ''} margin="auto 0 auto auto">
      {customButton ? (
        React.cloneElement(customButton, {
          onClick: handleClick,
          'data-testid': 'csv download button',
        })
      ) : (
        <IconButton data-testid="csv download button" onClick={handleClick}>
          <CloudDownload />
        </IconButton>
      )}
    </Box>
  );
};
