import * as yup from 'yup';
import { EMAIL_REGEX, FORMATTED_PHONE_REGEX } from 'src/constants/leadLandingPage.constants';

export const schemaForm = yup.object().shape({
  email: yup.string().required('Email is required').matches(EMAIL_REGEX, 'Please enter a valid email address'),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  phone: yup
    .string()
    .required('Phone number is required')
    .trim()
    .matches(FORMATTED_PHONE_REGEX, 'Phone number must be 10 digits'),
});
