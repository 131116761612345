import { Box, CircularProgress, Dialog, makeStyles } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { capitalize } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { cardOnFileStyles } from 'src/components/common/CreditCardDetails';
import AddCreditCard from 'src/components/DashboardPractitioner/Tabs/OrderingTab/OrderConfirm/CardOnFile/AddCreditCard';
import CreditCardIcon from 'src/components/PatientProfile/CreditCards/CreditCardIcon';
import { EHRButton, EHRTypography } from 'src/components/ui/v1';
import { useMedspaAdminDeleteCC } from 'src/hooks/mutations/medspaAdmins/useMedspaAdminDeleteCC';
import { useMedspaAdminSetDefaultCC } from 'src/hooks/mutations/medspaAdmins/useMedspaAdminSetDefaultCC';
import useGetCards from 'src/hooks/queries/medspaAdmins/useMedspaPaymentMethods';
import { IPaymentCard } from 'src/interfaces/IMedspaAdminList';
import { RootState } from 'src/rematch';
import { showSnackbar } from 'src/utils/global';

const useStyles = makeStyles(() => ({
  paymentSettingsContainer: {
    paddingTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  paymentSettingsToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  paymentMethodsListContainer: {
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: '0.625rem',
    border: '1px solid #DFDFDF',
    padding: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    position: 'relative',
  },
  ccActions: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  ccDefault: {
    backgroundColor: 'rgba(18, 87, 77, 0.15)',
    padding: '0.125rem 0.5rem',
    color: 'rgba(18, 87, 77, 1)',
    borderRadius: '0.25rem',
  },
  ccNotDefault: {
    color: 'rgba(18, 87, 77, 1)',
    cursor: 'pointer',
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.75rem',
    width: '100%',
    '& *': {
      margin: 0,
    },
  },
}));

interface MedspaAdminPaymentMethodsSettingsProps {}

const MedspaAdminPaymentMethodsSettings = (_props: MedspaAdminPaymentMethodsSettingsProps) => {
  const ccStyles = cardOnFileStyles();
  const classes = useStyles();
  const [canDeleteDefaultCard, setCanDeleteDefaultCard] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const {
    user: { userGroupId },
  } = useSelector(({ auth }: RootState) => auth);

  const { data: creditCards = [], isLoading: isLoadingCards, refetch } = useGetCards(userGroupId ?? 0);
  const { mutate: mutateSetMedspaAdminDefaultCC, isLoading: isSettingDefaultCC } = useMedspaAdminSetDefaultCC(
    userGroupId,
    () => refetch()
  );
  const { mutate: mutateDeleteMedspaAdminCC, isLoading: isDeletingCC } = useMedspaAdminDeleteCC(userGroupId, () =>
    refetch()
  );

  useEffect(() => {
    setCanDeleteDefaultCard(creditCards.length > 1);
  }, [creditCards]);

  const onDelete = useCallback(
    (card: IPaymentCard) => {
      if (card.defaultCard && !canDeleteDefaultCard) {
        showSnackbar('Please add another payment method prior to removing the only one remaining in your account');
        return;
      }

      mutateDeleteMedspaAdminCC(card.id);
    },
    [canDeleteDefaultCard]
  );

  const setAsDefault = (cardId: string) => mutateSetMedspaAdminDefaultCC(cardId);

  const isLoading = isSettingDefaultCC || isDeletingCC || isLoadingCards;

  let BodyComponent = <CircularProgress />;

  if (!isLoadingCards && creditCards.length < 1) {
    BodyComponent = (
      <Box className={classes.emptyState}>
        <h3>Welcome to your Payment Methods</h3>
        <p>It looks like you haven&apos;t added your first payment method yet. </p>
        <p>
          Click on <strong>Add Card</strong> button above to add your first card.
        </p>
      </Box>
    );
  } else if (!isLoadingCards && creditCards.length > 0) {
    BodyComponent = (
      <Box className={classes.paymentMethodsListContainer}>
        {creditCards.map((card: IPaymentCard) => {
          const { cardBrand, last4, id, defaultCard, expMonth, expYear } = card;
          const expiresIn = `${expMonth}/${expYear.toString().slice(-2)}`;

          return (
            <Box className={ccStyles.paymentMethodsListItem}>
              <Box className={ccStyles.paymentMethodInfo}>
                <Box className={ccStyles.ccBrand}>
                  <CreditCardIcon cardBrand={cardBrand} noFormat />
                </Box>

                <Box>
                  <Box>
                    {capitalize(cardBrand?.split('_').join(' '))} ending in {last4}
                  </Box>
                  <Box>
                    <EHRTypography
                      dataCy="caption-card-expires-in"
                      variant="caption"
                      style={{ color: 'rgba(0,0,0,0.5)' }}
                    >
                      Expires in {expiresIn}
                    </EHRTypography>
                  </Box>
                </Box>
              </Box>

              <Box className={classes.ccActions}>
                {defaultCard && <Box className={classes.ccDefault}>Default</Box>}
                {!defaultCard && (
                  <Box className={classes.ccNotDefault} onClick={() => setAsDefault(id)}>
                    Set as Default
                  </Box>
                )}
                {((defaultCard && canDeleteDefaultCard) || !defaultCard) && (
                  <Box display="flex" alignItems="center" style={{ cursor: 'pointer' }}>
                    <Delete fontSize="small" onClick={() => onDelete(card)} />
                  </Box>
                )}
              </Box>
            </Box>
          );
        })}
        {isLoading && (
          <Box
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
            bgcolor="rgba(0,0,0,.5)"
            width="100%"
            height="100%"
            style={{
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Box className={classes.paymentSettingsContainer}>
      <Box className={classes.paymentSettingsToolbar}>
        <Box>
          <EHRTypography dataCy="title-cards-on-file" variant="h6">
            Cards on File
          </EHRTypography>
        </Box>
        <Box>
          <EHRButton
            dataCy="btn-add-card"
            color="primary"
            text="Add Card"
            onClick={() => setDialogOpen(true)}
            disabled={isLoadingCards}
          />
        </Box>
      </Box>

      {BodyComponent}

      <Dialog open={dialogOpen}>
        <AddCreditCard onSave={refetch} closeDialog={() => setDialogOpen(false)} />
      </Dialog>
    </Box>
  );
};

export default MedspaAdminPaymentMethodsSettings;
