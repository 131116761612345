import React from 'react';
import { Box, makeStyles, Tooltip } from '@material-ui/core';
import moment from 'moment';
import classnames from 'classnames';
import { CALENDAR_DATETIME_DESCRIPTIVE } from 'src/constants/inventory.constants';

const useStyle = makeStyles(() => ({
  dateTimeChip: {
    cursor: 'pointer',
    width: '9.5rem',
    fontSize: '0.8rem',
    borderRadius: '0.3rem',
    paddingRight: '0.25rem',
    paddingLeft: '0.25rem',
    height: '100%',
  },
  showBackground: {
    backgroundColor: '#f5f5f5',
    color: '#000000',
  },
}));

export const DateTimeChip = ({
  dateTime,
  height,
  showBackground = false,
}: {
  dateTime: string;
  height?: string;
  showBackground?: boolean;
}) => {
  const classes = useStyle();
  return (
    <Box className={classnames(classes.dateTimeChip, { [classes.showBackground]: showBackground })} style={{ height }}>
      <Tooltip title={moment(dateTime).calendar()}>
        <span>{moment(dateTime).format(CALENDAR_DATETIME_DESCRIPTIVE)}</span>
      </Tooltip>
    </Box>
  );
};
