/* eslint-disable max-len */
import { useMutation, UseMutationResult } from 'react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { MedicalLicenseDetails } from 'src/interfaces/onboarding.interface';
import { ExternalMedicalDirectorApi, SignAgreementResponse } from '../../api/externalMedicalDirector';

export const useSignAgreement = (): UseMutationResult<SignAgreementResponse, AxiosError, MedicalLicenseDetails> => useMutation(
  (payload: MedicalLicenseDetails) =>
    ExternalMedicalDirectorApi.signAgreement(payload).then((response) => response.data),
  {
    onSuccess: () => {
      toast.success('Agreement signed successfully');
    },
    onError: (error: AxiosError) => {
      const errorMessage = error.response?.data?.errors || 'Failed to sign agreement';
      toast.error(errorMessage);
    },
  }
);
