import { URGENT_REQUEST } from 'src/constants/general.constants';
import { CustomerParams } from 'src/interfaces/CustomerParams';

const NEXT_48_HOURS = 48;
const NEXT_72_HOURS = 72;

const upcomingAppointmentWindowDate = (hours: number) => {
  const date = new Date();
  date.setHours(date.getHours() + hours);
  return date;
};

export const sharedInitialFilters: CustomerParams = {
  search: '',
  state: '',
  group: 'notCleared',
  sortBy: '',
  sortDirection: undefined,
  appointmentDate: '',
  appointmentDateOperator: '',
  scheduledClearingAppointments: '',
  practitionerId: '',
};

export const sharedLiveGFEInitialFilters: CustomerParams = {
  ...sharedInitialFilters,
  includeRequiredLiveGfe: true,
};

export const sharedGFEInitialFilters: CustomerParams = {
  ...sharedInitialFilters,
  requestType: '',
  includeRequiredLiveGfe: false,
  license: '',
  excludedStatus: '',
  roleId: '',
  marked: '',
};

const scheduledLiveGFEFilters: CustomerParams = {
  ...sharedLiveGFEInitialFilters,
  scheduledClearingAppointments: 'scheduled',
  excludedStatus: 'no_show',
};

const urgentLiveGFEFilters: CustomerParams = {
  ...sharedLiveGFEInitialFilters,
  scheduledClearingAppointments: 'unscheduled',
  appointmentDate: upcomingAppointmentWindowDate(NEXT_48_HOURS).toISOString(),
  appointmentDateOperator: '<',
};

const urgentGFEFilters: CustomerParams = {
  ...sharedGFEInitialFilters,
  requestType: `${URGENT_REQUEST}`,
};

const upcomingAppointmentFilters: CustomerParams = {
  ...sharedGFEInitialFilters,
  appointmentDate: upcomingAppointmentWindowDate(NEXT_72_HOURS).toISOString(),
  appointmentDateOperator: '<',
};

const missingGFEsFilters: CustomerParams = {
  ...sharedGFEInitialFilters,
};

export const SCHEDULED_LIVE_GFE_TAB = 'scheduledLiveGfe';
export const URGENT_LIVE_GFE_TAB = 'urgentLiveGfe';
export const URGENT_GFE_TAB = 'urgentGfe';
export const UPCOMING_APPOINTMENT_TAB = 'upcomingAppointment';
export const MISSING_GFES_TAB = 'missingGfe';

export interface Filter {
  isLive?: boolean;
  label: string;
  filters: CustomerParams;
}

export const allFilters: Record<string, Filter> = {
  [SCHEDULED_LIVE_GFE_TAB]: {
    isLive: true,
    label: 'Scheduled Live GFEs',
    filters: scheduledLiveGFEFilters,
  },
  [URGENT_LIVE_GFE_TAB]: {
    isLive: true,
    label: 'Urgent Live GFEs within 48h',
    filters: urgentLiveGFEFilters,
  },
  [URGENT_GFE_TAB]: {
    label: 'Urgent GFE within 24h',
    filters: urgentGFEFilters,
  },
  [UPCOMING_APPOINTMENT_TAB]: {
    label: 'Upcoming appt. within next 72hs',
    filters: upcomingAppointmentFilters,
  },
  [MISSING_GFES_TAB]: {
    label: 'Missing GFEs',
    filters: missingGFEsFilters,
  },
};
