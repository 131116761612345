import { Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import { dispatch } from 'src/rematch';
import { useStyles } from './Page.styles';
import BookingLinkButton from './BookingLinkButton';

interface PageProps {
  title?: string;
  titleComponent?: React.ReactElement;
  children?: React.ReactNode;
}

const Page = ({ title, titleComponent, children }: PageProps) => {
  const classes = useStyles();

  const header = titleComponent ?? (
    <Box className={classes.titleContainer}>
      {title}
      <BookingLinkButton />
    </Box>
  );

  useEffect(() => {
    dispatch.page.setTitleComponent(header);

    return () => {
      dispatch.page.setTitle(null);
    };
  }, [title, titleComponent]);

  return <Box className={classes.pageContent}>{children}</Box>;
};

export default Page;
