import React from 'react';
import { Box, Typography, Dialog } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { ONBOARDING_ACCOUNT_SETTINGS } from 'src/routes/medspaOnboardingRoutes';
import { ACCOUNT_SETTINGS_LABELS } from 'src/pages/Onboarding/AccountSettings/constants';

interface IncompleteProfileDialogProps {
  open: boolean;
  hasIncompleteSignature: boolean;
  hasIncompleteCredentials: boolean;
  onClose: () => void;
}

const IncompleteProfileDialog: React.FC<IncompleteProfileDialogProps> = ({
  open,
  hasIncompleteSignature,
  hasIncompleteCredentials,
  onClose,
}) => {
  const history = useHistory();

  const handleRouting = (field: keyof typeof ACCOUNT_SETTINGS_LABELS) => {
    onClose();
    history.push({
      pathname: ONBOARDING_ACCOUNT_SETTINGS,
      state: { field },
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box p={3}>
        <Typography>
          Please complete your account settings (
          {hasIncompleteSignature && (
            <span
              role="button"
              tabIndex={0}
              style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => handleRouting('signature')}
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                  handleRouting('signature');
                }
              }}
              aria-label="Click to add signature"
            >
              Signature
            </span>
          )}
          {hasIncompleteSignature && hasIncompleteCredentials && ' and '}
          {hasIncompleteCredentials && (
            <span
              role="button"
              tabIndex={0}
              style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => handleRouting('credentials')}
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                  handleRouting('credentials');
                }
              }}
              aria-label="Click to add credentials"
            >
              Credentials
            </span>
          )}
          ) to access your Provider Dashboard and start treating patients.
        </Typography>
      </Box>
    </Dialog>
  );
};

export default IncompleteProfileDialog;
