import React, { useState } from 'react';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { useAddCharges } from 'src/hooks/queries/useInventoryOrders';
import { modalStyles } from './OrderApprovalRow.styles';

type AddChargesModalProps = {
  id: number;
  supplierId?: number;
  dismiss: () => void;
  discountMode?: boolean;
};

export const OrderAddChargesModal = ({ id, supplierId, dismiss, discountMode }: AddChargesModalProps) => {
  const classes = modalStyles();
  const [note, setNote] = useState<string>('');
  const [price, setPrice] = useState<number | null>(null);
  const [saving, setSaving] = useState<boolean>(false);

  const { mutateAsync: updateCharges } = useAddCharges({
    inventoryOrderId: id,
    supplierId,
  });

  const back = () => dismiss();

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value);
  };
  const handleChangePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrice(Math.abs(Number(e.target.value)));
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();
    setSaving(true);
    await updateCharges({
      description: note,
      amount: (discountMode ? -1 : 1) * (price || 0),
    });
    setSaving(false);
    dismiss();
  };

  return (
    <Box className={classes.confirmDialog} data-testid="reject dialog">
      <form onSubmit={onSubmit} className={classes.dialogForm}>
        <Box className={classes.confirmContent}>
          <Typography variant="h5">Add {discountMode ? 'Discount' : 'Charges'}</Typography>
        </Box>

        <Box className={classes.confirmContent}>
          <p style={{ margin: 0 }}>Note</p>
          <Box className={classes.orderNotes}>
            <textarea onChange={(e) => handleChange(e)} placeholder="Your note" value={note} required />
          </Box>
        </Box>

        <Box className={classes.confirmContent}>
          <p style={{ margin: 0 }}>Price</p>
          <Box className={classes.orderNotes}>
            <input
              onChange={(e) => handleChangePrice(e)}
              placeholder="Add amount here"
              type="number"
              value={price as number}
              step={0.01}
              required
            />
          </Box>
        </Box>

        <Box className={classes.confirmButtons}>
          <Button data-testid="cancel add charges" onClick={back} variant="outlined" className={classes.actionButton}>
            Cancel
          </Button>
          <Button type="submit" data-testid="add charges" variant="outlined" className={classes.actionButton}>
            {saving && <CircularProgress style={{ color: 'white' }} size={30} />}
            Add
          </Button>
        </Box>
      </form>
    </Box>
  );
};
