/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement } from 'react';
import formatDate, { DAY_MONTH_FORMAT as BIRTHDAY_DATE_FORMAT } from 'src/utils/formatDate';
import { formatCurrency } from 'src/utils/formatNumber';
import { ROUTES } from 'src/constants/routes.constants';
import { TableHeaderSet, IClientSummaryData } from 'src/interfaces/IPractitionerReport';
import { TableCellCustom, TableRowStriped, useStyle as tableStyle } from '../TodoListTabs/rowItem.styles';

interface ClientSummaryDataProps {
  row: IClientSummaryData;
  cols: TableHeaderSet;
  key?: string | number;
}

interface TableCellLinkProps {
  href: string;
  text: string;
}

interface Props {
  row: IClientSummaryData;
  cols: TableHeaderSet;
}

export const TableCellLink: React.FC<TableCellLinkProps> = ({ href, text }): ReactElement => (
  <TableCellCustom data-cy={`practReportItem${text.split('_').join('') || '_'}`}>
    <a href={href} style={{ color: '#12574d' }} target="_blank" rel="noreferrer">
      {' '}
      {text}{' '}
    </a>
  </TableCellCustom>
);

const RowItem: React.FC<ClientSummaryDataProps> = ({ row, cols }: Props): ReactElement => {
  const css = tableStyle();

  const dollarsUS = (n: number): string => formatCurrency(Math.floor(n), { trailingZeroDisplay: 'stripIfInteger' });

  const profileLink: string = ROUTES.PATIENT_ID(row.mrn);
  const dynamicColumn: string = Object.keys(cols)[4] || '';

  return (
    <>
      <TableRowStriped className={css.ItemTable}>
        <TableCellLink href={profileLink} text={String(row.mrn) || '_'} />

        <TableCellLink href={profileLink} text={row.firstName} />

        <TableCellLink href={profileLink} text={row.lastName} />

        <TableCellCustom data-cy="psmMetricsLineItemVC">{row.visitCount}</TableCellCustom>

        <TableCellCustom data-cy="psmMetricsLineItemLV">
          {formatDate(row[dynamicColumn], dynamicColumn === 'dateOfBirth' ? BIRTHDAY_DATE_FORMAT : undefined)}
        </TableCellCustom>

        <TableCellCustom data-cy="psmMetricsLineItemLS">{dollarsUS(Math.floor(row.lifetimeSpend))}</TableCellCustom>

        <TableCellCustom data-cy="psmMetricsLineItemAS">{dollarsUS(Math.floor(row.avgSpend))}</TableCellCustom>

        <TableCellCustom data-cy="psmMetricsLineItemAT">{`${Math.floor(row.avgTox)} units`}</TableCellCustom>

        <TableCellCustom data-cy="psmMetricsLineItemUA">
          {row.upcomingAppt ? formatDate(row.upcomingAppt) : 'none'}
        </TableCellCustom>

        <TableCellCustom data-cy="psmMetricsLineItemSMOI">{String(!!row.smsMarketingOptedIn)}</TableCellCustom>
      </TableRowStriped>
    </>
  );
};

export { RowItem, RowItem as default };
