import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import classnames from 'classnames';
import { CheckCircleOutline, Schedule, HighlightOff } from '@material-ui/icons';
import {
  PENDING_TAG_BACKGROUND_COLOR,
  PENDING_TAG_FONT_COLOR,
} from 'src/pages/Onboarding/AccountSettings/AccountSettings.styles';
import { AppointmentStatusEnum } from './MedspaCalendar/AppointmentStatusEnum';

const useStyle = makeStyles(() => ({
  root: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    height: '1.8rem',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    gridGap: '0.25rem',
    borderRadius: '0.25rem',
    padding: '0.25rem 0.5rem',
    backgroundColor: '#D3D3D3',
    '&.small': {
      height: '1.2rem',
      fontSize: '0.75rem',
    },
    '&.created': {
      backgroundColor: '#DFF8CD',
      color: '#2C6109',
    },
    '&.completed': {
      backgroundColor: '#DFF8CD',
      color: '#2C6109',
    },
    '&.confirmed': {
      backgroundColor: '#DFF8CD',
      color: '#2C6109',
    },
    '&.rescheduled': {
      backgroundColor: PENDING_TAG_BACKGROUND_COLOR,
      color: PENDING_TAG_FONT_COLOR,
    },
    '&.cancelled, &.canceled': {
      backgroundColor: '#FFE1E1',
      color: '#B83F3F',
    },
    '&.pending_confirmation': {
      maxWidth: '10.5rem',
    },
  },
  completed: {
    color: '#2C6109',
    backgroundColor: '#DFF8CD',
  },
  historyStatusIcon: {
    width: '1rem',
    height: '1rem',
  },
}));

const ACTION_ICONS: { [key in AppointmentStatusEnum]: any } = {
  [AppointmentStatusEnum.RESERVED]: Schedule,
  [AppointmentStatusEnum.PENDING_CONFIRMATION]: CheckCircleOutline,
  [AppointmentStatusEnum.COMPLETED]: CheckCircleOutline,
  [AppointmentStatusEnum.CONFIRMED]: CheckCircleOutline,
  [AppointmentStatusEnum.CREATED]: CheckCircleOutline,
  [AppointmentStatusEnum.CREATED_FROM_ACUITY]: CheckCircleOutline,
  [AppointmentStatusEnum.RESCHEDULED]: Schedule,
  [AppointmentStatusEnum.CANCELLED]: HighlightOff,
};
const ACTION_TEXT: { [key in AppointmentStatusEnum]: any } = {
  [AppointmentStatusEnum.RESERVED]: 'Reserved',
  [AppointmentStatusEnum.PENDING_CONFIRMATION]: 'Pending Confirmation',
  [AppointmentStatusEnum.COMPLETED]: 'Completed',
  [AppointmentStatusEnum.CONFIRMED]: 'Confirmed',
  [AppointmentStatusEnum.CREATED]: 'Created',
  [AppointmentStatusEnum.CREATED_FROM_ACUITY]: 'Created',
  [AppointmentStatusEnum.RESCHEDULED]: 'Rescheduled',
  [AppointmentStatusEnum.CANCELLED]: 'Cancelled',
};

export const AppointmentStatusTag: React.FC<{ action: AppointmentStatusEnum; size?: string }> = ({
  action = AppointmentStatusEnum.CANCELLED,
  size,
}) => {
  const classes = useStyle();
  const AppointmentIconComponent = ACTION_ICONS[action];
  return (
    <Box className={classnames(classes.root, action, size)}>
      <AppointmentIconComponent className={classes.historyStatusIcon} />
      {/* action with first word capitalized: */}
      {ACTION_TEXT[action]}
    </Box>
  );
};
