import { useMutation, useQuery, useQueryClient } from 'react-query';
import UserGroups from 'src/services/UserGroups';
import { CURRENT_USER_GROUP, FEATURES } from '../../constants/reactQuery.keys';
import { getFeatures } from '../../services/Features';

export function useFeatures() {
  return useQuery([FEATURES], () => getFeatures(), { refetchOnWindowFocus: false });
}

export const useRemoveFeature = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ key, medspaId }: { key: string; medspaId: number }) => UserGroups.removeFeatureFlagFromUserGroup(key, medspaId),
    { onSuccess: () => queryClient.invalidateQueries([CURRENT_USER_GROUP]) }
  );
};

export const useAddFeature = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ key, medspaId }: { key: string; medspaId: number }) => UserGroups.addFeatureFlagToUserGroup(key, medspaId),
    { onSuccess: () => queryClient.invalidateQueries([CURRENT_USER_GROUP]) }
  );
};
