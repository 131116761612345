import React, { Fragment, useState } from 'react';
import { withStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { ILineItem, ILineItemDiscount } from '../../interfaces/IServiceVisits';
import { LineItemRow } from '../../pages/Checkout/LineItems/LineItemRow';
import AppliedDiscounts from './AppliedDiscount';
import IServices from '../../interfaces/IServices';
import IVariant, { IVariantItem } from '../../interfaces/IVariants';
import { IDiscount } from '../../interfaces/checkout.interfaces';
import DiscountsModal from './DiscountsModal';
import { IPatientBasicInfo } from '../../types/Patient';
import { CustomDiscountData, useApplyDiscounts, useRemoveDiscounts } from '../../hooks/serviceVisit/useDiscounts';

const CustomTableCell = withStyles(() => ({
  root: {
    fontSize: '15px',
  },
}))(TableCell);

const LineItemTable = ({
  lineItems,
  serviceUsingDynamicPricingMap,
  lineItemIdToDiscountsMap,
  patient,
  serviceVisitId,
  handleRefetch,
  resetTenderAndCredit,
  servicesIdMap,
  handleCheckTenders,
  showEditUnitPrice,
  disableDiscounts,
}: {
  lineItems: ILineItem[];
  serviceUsingDynamicPricingMap: Record<string, boolean>;
  lineItemIdToDiscountsMap: Record<number, IDiscount[]>;
  patient: IPatientBasicInfo;
  serviceVisitId: number;
  handleRefetch: () => void;
  resetTenderAndCredit: () => void;
  handleCheckTenders: () => void;
  servicesIdMap: Record<number, IServices>;
  showEditUnitPrice: boolean;
  disableDiscounts: boolean;
}) => {
  const [selectedLineItem, setSelectedLineItem] = useState<ILineItem>();
  const [selectedVariantServiceId, setSelectedVariantServiceId] = useState<number>();
  const [openDiscountsModal, setOpenDiscountsModal] = useState<boolean>(false);
  const { mutateAsync: applyDiscountToLineItem } = useApplyDiscounts(+serviceVisitId);
  const { mutateAsync: removeDiscountToLineItem } = useRemoveDiscounts(+serviceVisitId);

  const handleOpenDiscountModal = (id: number, variantServiceId?: number) => {
    setSelectedLineItem(lineItems.find((lineItem) => lineItem.id === id));
    setSelectedVariantServiceId(variantServiceId);
    setOpenDiscountsModal(true);
  };

  const showFutureColumn = lineItems.some((lineItem) => lineItem.futureUseQuantity > 0);

  const handleApplyDiscount = async ({
    discountId,
    customData,
  }: {
    discountId?: number;
    customData?: CustomDiscountData;
  }) => {
    if (selectedLineItem) {
      const lineItemDiscountId = selectedLineItem.checkoutLineItemDiscounts[0]?.id;
      const currentDiscountId = selectedLineItem.checkoutLineItemDiscounts[0]?.discountsId;
      if ((!customData && !discountId) || customData || (discountId && currentDiscountId !== discountId)) {
        await removeDiscountToLineItem({
          checkoutLineItemId: selectedLineItem.id,
          discountId: lineItemDiscountId,
        });
      }
      if (customData || (discountId && (!currentDiscountId || currentDiscountId !== discountId))) {
        await applyDiscountToLineItem({
          checkoutLineItemId: selectedLineItem.id,
          discountId,
          customData,
        });
        await handleCheckTenders();
      }
      await handleRefetch();
    }
  };

  return (
    <>
      <TableContainer style={{ border: '1px solid #E4E7EB', borderBottom: 'none' }}>
        <Table>
          <TableHead>
            <TableRow>
              <CustomTableCell>Quantity</CustomTableCell>
              {showFutureColumn && <CustomTableCell>Future Use</CustomTableCell>}
              <CustomTableCell width="25%">Product</CustomTableCell>
              <CustomTableCell>Unit Price</CustomTableCell>
              <CustomTableCell>Discount</CustomTableCell>
              <CustomTableCell align="right">Total to pay</CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lineItems.map((lineItem) => {
              const service = servicesIdMap[lineItem.serviceId];
              const variantItems = service?.variants?.flatMap(({ items }: IVariant) => items);
              const appliedDiscount = lineItem.checkoutLineItemDiscounts.length > 0;
              const discounts: Array<IDiscount | ILineItemDiscount> =
                lineItemIdToDiscountsMap[lineItem.id]?.filter((discount) =>
                  lineItem.checkoutLineItemDiscounts
                    .map((item: ILineItemDiscount) => item.discountsId)
                    .includes(discount.id)
                ) || [];
              const customDiscount = lineItem.checkoutLineItemDiscounts.find((discount) => !discount.discountsId);
              if (customDiscount) {
                discounts.push(customDiscount);
              }
              const units = 0;

              if (lineItem.variantItemId) {
                return <Fragment key={`line-${lineItem.id}`} />;
              }

              return serviceUsingDynamicPricingMap[lineItem.serviceId] ? (
                <Fragment key={`line-${lineItem.id}`}>
                  <LineItemRow
                    showEditUnitPrice={showEditUnitPrice}
                    lineItemId={lineItem.id}
                    handleRefetch={handleRefetch}
                    resetTenderAndCredit={resetTenderAndCredit}
                    serviceVisitId={+serviceVisitId}
                    quantity={lineItem.currentUseQuantity}
                    priceOverride={lineItem.totalWithoutDiscount}
                    unitPrice={lineItem.unitPrice}
                    unitPriceOverride={lineItem.unitPriceOverride}
                    credit={0}
                    service={service}
                    appliedDiscount={appliedDiscount}
                    discounts={discounts}
                    openDiscountsModal={() => handleOpenDiscountModal(lineItem.id)}
                    showFutureColumn={false}
                    disableDiscounts={disableDiscounts}
                  />
                  <AppliedDiscounts
                    discounts={discounts}
                    openDiscountsModal={() => handleOpenDiscountModal(lineItem.id)}
                    service={service}
                    units={units}
                    totalPrice={lineItem.totalWithoutDiscount}
                  />
                </Fragment>
              ) : (
                <Fragment key={`line-${lineItem.id}`}>
                  {lineItem.quantity > 0 ? (
                    <>
                      <LineItemRow
                        showEditUnitPrice={showEditUnitPrice}
                        lineItemId={lineItem.id}
                        serviceVisitId={+serviceVisitId}
                        handleRefetch={handleRefetch}
                        resetTenderAndCredit={resetTenderAndCredit}
                        quantity={lineItem.currentUseQuantity}
                        unitPrice={lineItem.unitPrice}
                        unitPriceOverride={lineItem.unitPriceOverride}
                        priceOverride={lineItem.totalWithoutDiscount}
                        credit={lineItem.futureUseQuantity || 0}
                        service={service}
                        appliedDiscount={appliedDiscount}
                        openDiscountsModal={() => handleOpenDiscountModal(lineItem.id)}
                        showFutureColumn={showFutureColumn}
                        discounts={discounts}
                        disableDiscounts={disableDiscounts}
                      />
                      <AppliedDiscounts
                        discounts={discounts}
                        openDiscountsModal={() => handleOpenDiscountModal(lineItem.id)}
                        service={service}
                        units={units}
                        totalPrice={lineItem.totalWithoutDiscount}
                      />
                    </>
                  ) : null}
                  {variantItems &&
                    variantItems.length > 0 &&
                    variantItems.map((variantItem: IVariantItem) => {
                      const variantLineItem = lineItems.find(
                        ({ variantItemId }: ILineItem) => variantItemId === variantItem.id
                      );

                      if (!variantLineItem) {
                        return null;
                      }

                      if (variantLineItem.quantity === 0) {
                        return null;
                      }

                      const variantAppliedDiscount = variantLineItem.checkoutLineItemDiscounts.length > 0;
                      const variantDiscounts: Array<IDiscount | ILineItemDiscount> = lineItemIdToDiscountsMap[
                        variantLineItem.id
                      ]?.filter((discount) =>
                        variantLineItem.checkoutLineItemDiscounts
                          .map((item: ILineItemDiscount) => item.discountsId)
                          .includes(discount.id)
                      );

                      const variantCustomDiscount = variantLineItem.checkoutLineItemDiscounts.find(
                        (discount) => !discount.discountsId
                      );
                      if (variantCustomDiscount) {
                        variantDiscounts.push(variantCustomDiscount);
                      }

                      return (
                        <Fragment key={`variant-${variantLineItem.id}`}>
                          <LineItemRow
                            showEditUnitPrice={showEditUnitPrice}
                            lineItemId={variantLineItem.id}
                            handleRefetch={handleRefetch}
                            resetTenderAndCredit={resetTenderAndCredit}
                            serviceVisitId={+serviceVisitId}
                            discounts={variantDiscounts}
                            quantity={variantLineItem.currentUseQuantity}
                            priceOverride={variantLineItem.totalWithoutDiscount}
                            unitPriceOverride={variantLineItem.unitPriceOverride}
                            unitPrice={variantLineItem.unitPrice}
                            credit={variantLineItem.futureUseQuantity || 0}
                            service={variantItem}
                            appliedDiscount={variantAppliedDiscount}
                            openDiscountsModal={() => handleOpenDiscountModal(variantLineItem.id, service.id)}
                            showFutureColumn={showFutureColumn}
                            isVariantItem
                            disableDiscounts={disableDiscounts}
                          />
                          <AppliedDiscounts
                            discounts={variantDiscounts}
                            openDiscountsModal={() => handleOpenDiscountModal(variantLineItem.id, service.id)}
                            service={variantItem}
                            units={units}
                            isVariantItem
                            totalPrice={variantLineItem.totalWithoutDiscount}
                          />
                        </Fragment>
                      );
                    })}
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <DiscountsModal
        open={openDiscountsModal}
        lineItem={selectedLineItem}
        servicesIdMap={servicesIdMap}
        variantServiceId={selectedVariantServiceId}
        patient={patient}
        // @ts-ignore
        discounts={lineItemIdToDiscountsMap[selectedLineItem?.id] || []}
        handleClose={() => setOpenDiscountsModal(false)}
        applyCallback={handleApplyDiscount}
      />
    </>
  );
};

export default LineItemTable;
