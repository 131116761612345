import { AxiosResponse } from 'axios';
import { EXTERNAL_MEDICAL_DIRECTOR_SIGN_AGREEMENT } from 'src/constants/apiRoutes.constants';
import { MedicalLicenseDetails } from 'src/interfaces/onboarding.interface';
import Api from 'src/services/Api';

export interface SignAgreementResponse {
  success: boolean;
}

export const ExternalMedicalDirectorApi = {
  signAgreement: (payload: MedicalLicenseDetails): Promise<AxiosResponse<SignAgreementResponse>> =>
    Api.post(EXTERNAL_MEDICAL_DIRECTOR_SIGN_AGREEMENT, payload),
};
