import React, { useMemo, ReactElement } from 'react';
import { flatten } from 'lodash';
import { ClassNameMap } from 'src/types/Dom';
import { MenuOption } from 'src/constants/menu.constants';
import { hasAccessTo } from 'src/utils/auth.utils';
import RecursiveMenuItem from './RecursiveMenuItem';

export type Props = {
  collapsed?: boolean;
  setCollapsed?: (isCollapsed: boolean) => void;
  optionsMenu: MenuOption[];
  list?: string[];
  permissions: string[];
  subMenus: string[];
  setSubMenus: (menu: string[]) => void;
};

export const RecursiveMenu = ({
  classes,
  permissions,
  setCollapsed,
  collapsed,
  subMenus,
  setSubMenus,
  list = [],
  optionsMenu = [],
}: Props & ClassNameMap): ReactElement => {
  const hasPermission = (option: MenuOption) =>
    !option.permission ||
    flatten([option.permission]).some((permission: string) => hasAccessTo(permission, permissions));

  return useMemo(
    () => (
      <>
        {optionsMenu.map(
          (option: MenuOption, id: number) =>
            hasPermission(option) && (
              <RecursiveMenuItem
                option={option}
                id={id}
                key={option.name}
                subMenus={subMenus}
                setSubMenus={setSubMenus}
                list={list}
                classes={classes}
                setCollapsed={setCollapsed}
                collapsed={collapsed}
                permissions={permissions}
              />
            )
        )}
      </>
    ),
    [list, permissions, subMenus, collapsed, optionsMenu, classes]
  );
};

export default RecursiveMenu;
