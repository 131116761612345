import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Paper,
  Slide,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useState, useRef } from 'react';
import { ConfirmDeliveryParams } from 'src/interfaces/IInventoryOrder';
import { useConfirmDelivery } from 'src/hooks/queries/useInventoryOrders';
import { Close } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { RootState, dispatch } from 'src/rematch';
import { usePractitionerInfo } from 'src/hooks/queries/usePractitioners';
import { formatCurrency } from 'src/utils/formatNumber';
import { USER_TYPES } from 'src/constants/general.constants';
import { useMedspaPractitioners } from 'src/hooks/queries/useMedspaAdmins';
import { INPUT_DATE_FORMAT } from 'src/constants/inventory.constants';
import moment from 'moment';
import DateComponent from 'src/components/common/DateComponent';
import { useStyle } from '../inventoryOrder.styles';

const ConfirmDelivery: React.FC<ConfirmDeliveryParams> = ({
  open,
  toggleView,
  quantity,
  supplierName,
  startingUnits,
  unitPrice,
  orderMultiplier,
  purchaseOrderItemId,
  name,
}) => {
  const [lot, setLot] = useState<string | undefined>();
  const [expireAt, setExpireAt] = useState<string | undefined>();

  const { mutateAsync: confirmDelivery } = useConfirmDelivery(purchaseOrderItemId);

  const { userId, userType } = useSelector(({ auth }: RootState) => auth);

  const isMedspaAdmin = userType !== USER_TYPES.PRACTITIONER;

  const { data: medspaPractitioners } = useMedspaPractitioners(isMedspaAdmin);

  const practitionerId = isMedspaAdmin ? (medspaPractitioners ?? [])[0]?.id ?? 0 : userId;

  const {
    practitionerInfo: { signatureImageUrl },
  } = usePractitionerInfo(practitionerId ?? 0);

  const lotInputRef = useRef<HTMLFormElement | null>(null);

  const expireDateGreaterThanAYear = moment(expireAt).isAfter(moment().add(1, 'year'));
  const inputValid = !!lot && !!expireAt && expireDateGreaterThanAYear;

  const resetForm = () => {
    setLot(undefined);
    setExpireAt(undefined);
    lotInputRef?.current?.reset();
  };

  const handleChangeDate = (date: string | Date) => setExpireAt(moment(date).format(INPUT_DATE_FORMAT));

  const handleSave = async (): Promise<void> => {
    if (inputValid) {
      await confirmDelivery({
        lot,
        expireAt,
      });

      !isMedspaAdmin &&
        dispatch({
          type: 'inventoryDefault/getDashboardLists',
          payload: practitionerId,
        });

      resetForm();

      toggleView();
    }
  };

  const classes = useStyle();

  return (
    <Slide direction="left" enter in={open} exit timeout={{ enter: 500, exit: 100 }}>
      <Paper className={classes.editInsert} variant="elevation" elevation={2}>
        <Box className={classes.confirmTitle}>
          <Typography variant="h4">Confirm your order</Typography>
          <IconButton
            className={classes.closeButton}
            size="small"
            onClick={() => {
              resetForm();
              toggleView();
            }}
            data-testid="confirmDelivery.close"
          >
            <Close />
          </IconButton>
        </Box>

        <Box className={classes.productDetail}>
          <Box>
            <Typography>Product Name</Typography>
            <Typography>Boxes</Typography>
            <Typography>Quantity</Typography>
            <Typography>Total</Typography>
          </Box>
          <Box>
            <Typography>
              {name} ({supplierName})
            </Typography>
            <Typography>{Math.floor(quantity / (orderMultiplier || 1))}</Typography>
            <Typography>{quantity}</Typography>
            <Typography>
              {formatCurrency((startingUnits || 1) * (unitPrice ?? 0) * (orderMultiplier || 1) * quantity)}
            </Typography>
          </Box>
        </Box>
        <Box>
          <FormControl component="form" ref={lotInputRef} className={classes.confirmQtyInput}>
            <InputLabel shrink htmlFor="lot">
              Lot #
            </InputLabel>
            <TextField
              value={lot}
              className={classes.lotTextInput}
              onChange={(event: React.ChangeEvent) => setLot((event.currentTarget as HTMLInputElement)?.value)}
              name="lot"
              fullWidth
              data-testid="confirmDelivery.lot"
            />
          </FormControl>
          <Box className={classes.lotInput} width="100%" paddingLeft="25px" minHeight="101px">
            <InputLabel shrink htmlFor="expireAt">
              Expiration Date
            </InputLabel>
            <DateComponent
              isBack={false}
              currentDate={expireAt && new Date(expireAt)}
              getDateValue={handleChangeDate}
            />
            {!!expireAt && !expireDateGreaterThanAYear && (
              <Typography className={classes.expireDateError}>
                Expiration date must be greater than a year from today
              </Typography>
            )}
          </Box>
        </Box>
        <Typography className={classes.signAndConfirm}>
          Please sign and confirm all items are entered correctly and the package was received
        </Typography>
        {signatureImageUrl && (
          <img
            alt="signature"
            src={signatureImageUrl}
            className={classes.signature}
            data-testid="confirmDelivery.signature"
          />
        )}
        <Button
          disabled={!inputValid}
          variant="outlined"
          onClick={handleSave}
          className={classes.saveDelivery}
          data-testid="confirmDelivery.save"
        >
          I confirm I received this item
        </Button>
      </Paper>
    </Slide>
  );
};

export { ConfirmDelivery, ConfirmDelivery as default };
