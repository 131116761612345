import React from 'react';
import { IconButton, Drawer, List, ClickAwayListener } from '@material-ui/core';
import { Menu as MenuIcon, ArrowBackIos } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { RootState, dispatch } from 'src/rematch';
import { ReactComponent as PortraitLogo } from '../../assets/images/portrait-logo.svg';
import SidebarStyles from './Sidebar.styles';
import HealthInfo from './HealthInfo';
import SidebarItems from './SidebarItems';

const Sidebar = () => {
  const classes = SidebarStyles();
  const { sidebarOpened } = useSelector(({ page }: RootState) => page);

  const handleDrawerOpen = () => {
    dispatch.page.setSidebarOpened(true);
  };

  const handleDrawerClose = () => {
    dispatch.page.setSidebarOpened(false);
  };

  const classnamePaper = sidebarOpened ? classes.drawerPaper : classes.drawerPaperClose;
  const classnameButton = sidebarOpened ? classes.menuButtonHidden : classes.menuButton;

  return (
    <ClickAwayListener onClickAway={handleDrawerClose}>
      <Drawer
        variant="permanent"
        className={classes.drawer}
        classes={{ paper: classnamePaper }}
        open={sidebarOpened}
        data-testid="sidebar"
      >
        <div>
          <div className={classes.toolbarIcon}>
            {sidebarOpened ? (
              <div className={classes.logoContainer}>
                <PortraitLogo className={classes.logo} />
              </div>
            ) : (
              <div className={classes.expandButtonContainer}>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  className={classnameButton}
                >
                  <MenuIcon className={classes.iconColor} />
                </IconButton>
              </div>
            )}
          </div>
          <List className={sidebarOpened ? classes.sidebarList : classes.sidebarListClosed}>
            <SidebarItems
              collapsed={!sidebarOpened}
              setCollapsed={(isCollapsed: boolean) => dispatch.page.setSidebarOpened(!isCollapsed)}
            />
          </List>
        </div>
        {sidebarOpened && (
          <div className={classes.closeNuttonContainer}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerClose}
              className={classes.menuButton}
            >
              <ArrowBackIos className={classes.iconColor} style={{ transform: 'translateX(0.35rem)' }} />
            </IconButton>
          </div>
        )}
        {process.env.REACT_APP_ENVIRONMENT !== 'PRODUCTION' && <HealthInfo isOpen={sidebarOpened} />}
      </Drawer>
    </ClickAwayListener>
  );
};

export default Sidebar;
