import { createModel } from '@rematch/core';
import { PhotoRequestTypes, REQUEST_STATUS_OPTIONS } from '../constants/newPhotoRequest.constants';
import NewPhotoRequest from '../services/PhotoRequests';
import Id from '../types/Id';
import { RootModel } from './rootModel';
import compile from '../utils/toastMessagesCompiler';
import { queryClient } from '../initializers/queryClient';
import { MEDSPA_CALENDAR } from '../constants/reactQuery.keys';

const NEW_PHOTO_REQUEST_INITIAL_STATE = {
  isLoading: false,
  requestStatus: null,
};

interface INewPhotoRequest {
  isLoading: boolean;
  requestStatus: string[] | null;
}

export const newPhotoRequest = createModel<RootModel>()({
  state: { ...NEW_PHOTO_REQUEST_INITIAL_STATE } as INewPhotoRequest,
  reducers: {
    setRequestStatus(state: INewPhotoRequest, payload: string[]) {
      return { ...state, requestStatus: payload };
    },
    setIsLoading(state: INewPhotoRequest, payload: boolean) {
      return { ...state, isLoading: payload };
    },
    setCleanNewPhotoRequestState() {
      return { ...NEW_PHOTO_REQUEST_INITIAL_STATE };
    },
  },
  effects: (dispatch) => ({
    async getPhotoRequestStatus(patientId: Id) {
      const status = await NewPhotoRequest.getNewPhotoRequestStatus(patientId);
      dispatch.newPhotoRequest.setRequestStatus(status);
    },
    async updatePhotoRequestStatus({
      patientId,
      photoRequestStatus,
      message,
    }: {
      patientId: Id;
      photoRequestStatus: PhotoRequestTypes;
      message?: string;
    }) {
      dispatch.newPhotoRequest.setIsLoading(true);
      const payload = {
        status: photoRequestStatus,
      } as {
        status: string;
        rejectionMessage?: string;
      };

      if (photoRequestStatus === REQUEST_STATUS_OPTIONS.REJECTED) {
        payload.rejectionMessage = message;
      }

      const response = await NewPhotoRequest.updatePhotoRequestStatus(patientId, payload);
      if (response) {
        dispatch.newPhotoRequest.getPhotoRequestStatus(patientId);
        queryClient.invalidateQueries([MEDSPA_CALENDAR]);
      } else {
        dispatch({
          type: 'snackbar/enqueueSnackBar',
          payload: {
            message: compile('generic.error_message', {
              action: 'updating',
              element: 'photo request',
            }),
            type: 'error',
          },
        });
      }
      dispatch.newPhotoRequest.setIsLoading(false);
    },
  }),
});
