import React from 'react';
import { ContactBarProps, LandingPageProps } from 'src/interfaces/ILeadLandingPage';
import { Box, Typography } from '@material-ui/core';
import { Schedule as ScheduleIcon, Call as CallIcon, Room as PinIcon } from '@material-ui/icons';
import { BUSINESS_HOURS } from 'src/constants/leadLandingPage.constants';

export const ContactBarPanel: React.FC<LandingPageProps> = ({
  iconElement,
  description,
  classes,
  mobile,
}: LandingPageProps): React.ReactElement => (
  <Box className={classes?.contactBarPanel} data-mobile={mobile}>
    {iconElement}
    <Typography className={classes?.contactPanelText}>{description}</Typography>
  </Box>
);

export const ContactBar: React.FC<ContactBarProps> = ({
  classes,
  mobile,
  address,
  phone,
}: ContactBarProps): React.ReactElement => (
  <Box className={classes?.contactBar} data-mobile={mobile}>
    <ContactBarPanel iconElement={<ScheduleIcon />} classes={classes} mobile={mobile} description={BUSINESS_HOURS} />
    <ContactBarPanel iconElement={<CallIcon />} classes={classes} mobile={mobile} description={phone ?? ''} />
    <ContactBarPanel iconElement={<PinIcon />} classes={classes} mobile={mobile} description={address ?? ''} />
  </Box>
);
