import { useMutation, useQuery, useQueryClient } from 'react-query';
import { showSnackbar } from 'src/utils/global';
import SpecialOffers, { ISpecialOffer } from 'src/services/SpecialOffers';
import { SPECIAL_OFFER, SPECIAL_OFFERS } from '../../constants/reactQuery.keys';
import compile from '../../utils/toastMessagesCompiler';

export function useSpecialOffer(specialOfferId: number) {
  const response = useQuery([SPECIAL_OFFER, specialOfferId], () => SpecialOffers.getSpecial(specialOfferId), {
    enabled: !!specialOfferId,
  });
  return {
    ...response,
    data: response.data || ({} as ISpecialOffer),
  };
}

export function useSpecialOffers(active?: boolean, specialsType?: string) {
  const response = useQuery([SPECIAL_OFFERS], () => SpecialOffers.getAllSpecials(active, specialsType), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  return {
    ...response,
    data: response.data || [],
  };
}
export const useCreateSpecialOffer = () => {
  const queryClient = useQueryClient();
  return useMutation((data: any) => SpecialOffers.createSpecialOffer(data), {
    onSuccess: () => {
      queryClient.invalidateQueries([SPECIAL_OFFERS]);
    },
  });
};
export const useUpdateSpecialOffer = () => {
  const queryClient = useQueryClient();
  return useMutation((specialOffer: any) => SpecialOffers.updateSpecialOffer(specialOffer.id as number, specialOffer), {
    onSuccess: () => {
      queryClient.invalidateQueries([SPECIAL_OFFERS]);
    },
  });
};

export const useUpdateSpecialOfferStatus = () => {
  const queryClient = useQueryClient();
  return useMutation((specialOfferId: number) => SpecialOffers.updateSpecialOfferStatus(specialOfferId), {
    onSuccess: () => {
      queryClient.invalidateQueries([SPECIAL_OFFERS]);
    },
  });
};

export const useDeleteSpecialOffer = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ specialOfferId }: { specialOfferId: number }) => SpecialOffers.deleteSpecialOffer(specialOfferId),
    {
      onSuccess: (response: any) => {
        if (!response.success) {
          const message = response?.message ? response?.message : compile('generic.error_message_without_params');
          showSnackbar(message);
          return;
        }

        queryClient.invalidateQueries([SPECIAL_OFFERS]);
      },
    }
  );
};
