import { useMutation, useQuery, useQueryClient } from 'react-query';
import { SNACKBAR_SUCCESS } from '../../../constants/general.constants';
import { CUSTOMER_PRACTITIONER_ASSIGNED } from '../../../constants/reactQuery.keys';
import Customers from '../../../services/Customers';
import { showSnackbar, useReactQueryOnErrorHandler } from '../../../utils/global';
import compile from '../../../utils/toastMessagesCompiler';

/* List of all Practitioners assigned to a customer */
export function useCustomerPractitionersAssigned(id: number) {
  const response = useQuery([CUSTOMER_PRACTITIONER_ASSIGNED, id], () => Customers.getPractitionerAssigned(id));
  return {
    ...response,
    data: response.data || ([] as number[]),
  };
}

/* Update list of practitioners assigned to a customer */
export const useUpdateCustomerPractitionersAssignedMutation = (id: number) => {
  const queryClient = useQueryClient();
  return useMutation((params: any) => Customers.updatePractitionerAssigned({ ...params, customerId: id }), {
    onSuccess: () => {
      queryClient.invalidateQueries([CUSTOMER_PRACTITIONER_ASSIGNED, id]);
      showSnackbar(
        compile('generic.success_message', {
          action: 'updated',
          element: 'Assigned Practitioners',
        }),
        SNACKBAR_SUCCESS
      );
    },
    onError: useReactQueryOnErrorHandler,
  });
};
