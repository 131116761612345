import React, { PropsWithChildren } from 'react';
import {
  Checkbox,
  CheckboxProps,
  FormGroup,
  FormControlLabel,
  makeStyles,
  Box,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { Controller } from 'react-hook-form';

const useStyles = makeStyles({
  ehrCheckboxContainer: {
    border: '1px solid #C8C8C8',
    borderRadius: '0.375rem',
    minHeight: '2.5rem',
    padding: '0.625rem',
    backgroundColor: '#FFFFFF',
    textWrap: 'nowrap',
    '&:hover': {
      borderColor: '#3a3b3f',
    },
  },
  ehrCheckboxLabel: {
    marginRight: 0,
    '& .MuiFormControlLabel-label': {
      fontSize: '0.875rem',
      fontWeight: '400',
      color: '#6F6F6F',
    },
  },
  ehrCheckbox: {
    color: '#12574D',
  },
  ehrCheckboxError: {
    '& .MuiIconButton-root': {
      color: 'red',
    },
  },
  ehrCheckboxTextContainer: {
    display: 'flex',
    height: '100% !important',
    padding: '0rem 0.75rem 0.75rem !important',
    '& .MuiFormControlLabel-label': {
      color: '#6F6F6F',
      fontWeight: '500',
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  },
  checkboxTextHelper: {
    fontSize: '14px',
    opacity: 0.6,
    marginLeft: '32px',
    textWrap: 'pretty',
  },
});

export interface EHRCheckboxProps extends CheckboxProps {
  dataCy: string;
  label?: string;
  error?: boolean;
  helperText?: string;
}

const EHRCheckbox = React.memo(
  (props: PropsWithChildren<EHRCheckboxProps>) => {
    const classes = useStyles();
    const { className, dataCy, label, children, error, helperText, ...restProps } = props;

    return (
      <Box display="flex" alignItems="center" className={classNames([classes.ehrCheckboxContainer, className])}>
        <Box display="flex" flexDirection="column">
          <FormGroup>
            <FormControlLabel
              className={classNames([classes.ehrCheckboxLabel, error && classes.ehrCheckboxError])}
              control={<Checkbox data-cy={dataCy} {...restProps} color="default" className={classes.ehrCheckbox} />}
              label={label}
            />
            {children}
          </FormGroup>
          {error && <FormHelperText error>{helperText}</FormHelperText>}
        </Box>
      </Box>
    );
  },
  (oldProps, newProps) => isEqual(oldProps, newProps)
);

interface EHRCheckboxWithTextProps extends EHRCheckboxProps {
  text: string;
  name: string;
  label: string;
  control?: any;
  checked: boolean;
}

interface BoxWrapperProps {
  render: any;
  name: string;
  // eslint-disable-next-line react/no-unused-prop-types
  control?: any;
  // eslint-disable-next-line react/no-unused-prop-types
  defaultValue?: any;
}

const BoxWrapper = ({ render, name }: BoxWrapperProps) => <Box data-testid={name}>{render}</Box>;

const EHRCheckboxWithText = React.memo(
  ({ control, name, text, defaultValue, ...restProps }: PropsWithChildren<EHRCheckboxWithTextProps>) => {
    const classes = useStyles();
    let Wrapper: any = BoxWrapper;
    if (control) {
      Wrapper = Controller;
    }
    return (
      <Wrapper
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={() => (
          <EHRCheckbox disabled={false} className={classes.ehrCheckboxTextContainer} {...restProps}>
            <Typography variant="body2" className={classes.checkboxTextHelper}>
              {text}
            </Typography>
          </EHRCheckbox>
        )}
      />
    );
  },
  (oldProps, newProps) => isEqual(oldProps, newProps)
);

export { EHRCheckbox, EHRCheckboxWithText };
