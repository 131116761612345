import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, makeStyles, Tooltip } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { useMedspaAppointmentHistory } from 'src/hooks/medspaCalendar/useAppointments';
import { Skeleton } from '@material-ui/lab';
import { DateTimeChip } from 'src/components/common/Appointments/DateTimeChip';
import { AppointmentStatusTag } from '../appointmentAction';
import { AppointmentStatusEnum } from './AppointmentStatusEnum';

const useStyle = makeStyles((theme) => ({
  root: {
    width: '100%',
    boxShadow: 'none',
    backgroundColor: '#ffffff',
    color: theme.palette.text.primary,
  },
  detailItem: {
    lineHeight: '1.8rem',
    display: 'flex',
    paddingBottom: '0.3rem',
    justifyContent: 'space-between',
  },
  createdBy: {
    fontWeight: 'normal',
  },
  fullName: {
    cursor: 'pointer',
    width: '11.5rem',
    fontWeight: 'bold',
    height: '100%',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    marginRight: 'auto',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

interface AppointmentHistoryProps {
  appointmentId: number;
}

const AppointmentHistory: React.FC<AppointmentHistoryProps> = ({ appointmentId }) => {
  const classes = useStyle();
  const [showDetails, setShowDetails] = useState(false);
  const { data: appointmentHistory, isFetching } = useMedspaAppointmentHistory(appointmentId, showDetails);
  return (
    <Accordion expanded={showDetails} className={classes.root}>
      <AccordionSummary
        onClick={() => setShowDetails(!showDetails)}
        expandIcon={<ExpandMoreIcon />}
        style={{ padding: 0 }}
      >
        Appointment History
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          {isFetching && <Skeleton style={{ width: '100%', height: '15px' }} />}
          {!appointmentHistory?.length && !isFetching && 'No history found.'}
          {appointmentHistory?.map((history) => (
            <Box key={history.id} className={classes.detailItem}>
              <DateTimeChip dateTime={history.createdAt} />
              <Box className={classes.fullName}>
                <Tooltip title={history.triggeredByFullName}>
                  <span>
                    <span className={classes.createdBy}>By: </span>
                    {history.triggeredByFullName}
                  </span>
                </Tooltip>
              </Box>
              <Box marginTop="auto" marginBottom="auto">
                <AppointmentStatusTag action={history.action as AppointmentStatusEnum} size="small" />
              </Box>
            </Box>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default AppointmentHistory;
