import { useMutation } from 'react-query';
import { MedspaAdminInvitations } from 'src/services/medspaAdmins/Invitations';

export const useCreateMedspaAdminInvitation = () =>
  useMutation(({ email, roleId }: { email: string; roleId: number }) =>
    MedspaAdminInvitations.createMedspaAdminInvitation(email, roleId)
  );

export const useValidMedspaAdminInvitation = () =>
  useMutation((code: string) => MedspaAdminInvitations.validMedspaAdminInvitation(code));
